export enum Department {
  administrative = 'Administrative',
  agentSupport = 'Agent Support',
  underwriting = 'Underwriting',
  logistics = 'Logistics',
  finance = 'Finance',
  risk = 'Risk',
  marketing = 'Marketing',
  sales = 'Sales',
  support = 'Support',
}
