import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {JobFrequency} from '@ozark/common';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (data?: JobFrequency) => Promise<void>;
  message: string;
  showSave: boolean;
};

export const ScheduleJobSimpleDialog = ({
  open,
  onClose,
  onSubmit,
  message,
  showSave,
}: Props): JSX.Element => {
  return (
    <Dialog open={open}>
      <DialogTitle>Schedule Settings</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        {showSave && (
          <Button
            onClick={async () => {
              await onSubmit();
            }}
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
