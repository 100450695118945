import FilterListIcon from '@mui/icons-material/FilterList';
import {DatePicker} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {Button, Checkbox, Grid, InputAdornment, MenuItem, Popover, TextField} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import {SelectChangeEvent} from '@mui/material/Select/SelectInput';
import {DefaultTheme} from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import {endOfDay, lastDayOfMonth, startOfDay, subDays} from 'date-fns';
import React, {Fragment, useEffect, useState} from 'react';
import {ListOption} from '../../models';

const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';
const DEFAULT_MONTH_FORMAT = 'MMMM yyyy';

const useStyles = makeStyles<DefaultTheme>(theme => ({
  popover: {
    marginTop: theme.spacing(1),
  },
  popoverContent: {
    width: 320,
    padding: theme.spacing(1),
    paddingTop: 0,
  },
}));

export type Operator = {id: string; label: string};

export const MtdDefaultDateRange = 'MTD';

export type FilterOption = {
  id: string;
  column: string;
  type:
    | 'text'
    | 'number'
    | 'minorUnits'
    | 'currency'
    | 'currencyRange'
    | 'date'
    | 'dateRange'
    | 'dateOnly'
    | 'dateOnlyRange'
    | 'month'
    | 'monthRange'
    | 'list'
    | 'multiSelect'
    | 'boolean';
  label: string;
  operators: Operator[];
  options?: ListOption[];
  dateFormat?: string;
  force?: boolean;
  hidden?: boolean;
  autoSelect?: boolean;
  defaultDateRange?: string;
};

export type FilterValueType =
  | string
  | string[]
  | [string, string]
  | [number, number]
  | [Date, Date]
  | null;

export type ActiveFilter = {
  option: FilterOption;
  operator: Operator;
  value: FilterValueType;
};
export type ActiveFilterSet = {
  [key: string]: ActiveFilter;
};

interface Props {
  options: FilterOption[];
  onApplyFilter: (filter: ActiveFilter) => void;
  alignPopup?: 'center' | 'left';
}

export const Filters = ({options, onApplyFilter, alignPopup = 'center'}: Props) => {
  const classes = useStyles();
  const [option, setOption] = useState<FilterOption | null>();
  const [operator, setOperator] = useState<Operator | null>();
  const [value, setValue] = useState<FilterValueType | null>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleButtonClick: React.MouseEventHandler<HTMLButtonElement> = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFieldChange = (value: string) => {
    const _option = options.find(e => e.id === value);
    setOption(_option);
    setValue(null);

    if (!_option) {
      return;
    }

    if (_option.type === 'date') {
      const _operator = _option.operators.find(e => e.id === '__between');
      setOperator(_operator);
      setValue([startOfDay(new Date()), endOfDay(new Date())]);
    }
    if (_option.type === 'dateRange') {
      const _operator = _option.operators.find(e => e.id === '__between');
      setOperator(_operator);
      if (_option.defaultDateRange === MtdDefaultDateRange) {
        const currentDate = new Date();
        setValue([
          startOfDay(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)),
          endOfDay(new Date()),
        ]);
      } else {
        setValue([startOfDay(subDays(new Date(), 6)), endOfDay(new Date())]);
      }
    }
    if (_option.type === 'dateOnly') {
      const _operator = _option.operators.find(e => e.id === '__eq');
      setOperator(_operator);
      setValue([new Date(), new Date()]);
    }
    if (_option.type === 'dateOnlyRange') {
      const _operator = _option.operators.find(e => e.id === '__between');
      setOperator(_operator);
      if (_option.defaultDateRange === MtdDefaultDateRange) {
        const currentDate = new Date();
        setValue([
          startOfDay(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)),
          new Date(),
        ]);
      } else {
        setValue([new Date(), new Date()]);
      }
    }
    if (_option.type === 'month' || _option.type === 'monthRange') {
      const _operator = _option.operators.find(e => e.id === '__between');
      setOperator(_operator);
      const currentDate = new Date();
      setValue([
        startOfDay(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)),
        endOfDay(lastDayOfMonth(currentDate)),
      ]);
    }
    if (_option.type === 'currencyRange') {
      const _operator = _option.operators.find(e => e.id === '__between');
      setOperator(_operator);
      setValue([0, 0]);
    }
    if (_option.type === 'text') {
      const _operator = _option.operators.find(e => e.id === '__eq');
      setOperator(_operator);
      setValue('');
    }
    // Fixes uncontrolled to controlled error in React
    if (_option.type === 'currency') {
      setValue('');
    }
    if (_option?.type === 'minorUnits') {
      setValue('');
    }
    if (_option.type === 'list') {
      setOperator(_option.operators[0]);
      setValue(_option.options?.[0].value);
    }
    if (_option.type === 'multiSelect') {
      setOperator(_option.operators[0]);
      setValue([]);
    }
  };

  const handleOperatorChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const _operator = option?.operators.find(e => e.id === event.target.value);
    setOperator(_operator);

    if (option?.type === 'currency' && _operator?.id === '__between') {
      setValue([0, 0]);
    }

    if (
      (option?.type === 'currency' || option?.type === 'minorUnits') &&
      _operator?.id === '__eq'
    ) {
      setValue('');
    }
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleMultiSelectValueChange = (event: SelectChangeEvent<unknown>) => {
    setValue(event.target.value as string | string[]);
  };

  const renderMultiSelectValue = (selected: unknown) => {
    if (!option?.options) {
      return '';
    }
    return (selected as string[])
      .map(x => option!.options!.find(y => y.key === x)?.value)
      .join(', ');
  };

  const handleMinorUnitsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((Number(event.target.value) * 100).toString());
  };

  const handleDateRangeSelect = (index: 0 | 1) => (date: Date | null) => {
    if (!date) return;
    const _value: [Date, Date] = [...(value as [Date, Date])];
    _value[index] = date;
    setValue(_value);
  };

  const handleMonthRangeSelect = (index: 0 | 1) => (date: Date | null) => {
    if (!date) return;
    const _value: [Date, Date] = [...(value as [Date, Date])];

    if (index === 0) {
      _value[0] = startOfDay(new Date(date.getFullYear(), date.getMonth(), 1));
    } else {
      _value[index] = endOfDay(lastDayOfMonth(date));
    }
    setValue(_value);
  };

  const handleMonthSelect = (date: Date | null) => {
    if (!date) return;
    setValue([
      startOfDay(new Date(date.getFullYear(), date.getMonth(), 1)),
      endOfDay(lastDayOfMonth(date)),
    ]);
  };

  const handleCurrencyRangeSelect =
    (index: 0 | 1) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const _value: [number, number] = [...(value as [number, number])];
      _value[index] = parseFloat(event.target.value);
      setValue(_value);
    };

  const handleDateSelect = (date: Date | null) => {
    if (!date) return;
    setValue([startOfDay(date), endOfDay(date)]);
  };

  const handleDateOnlySelect = (date: Date | null) => {
    if (!date) return;
    setValue([date, date]);
  };

  const reset = () => {
    setOption(null);
    setOperator(null);
    setValue(null);
  };

  const apply = () => {
    if (!(option && operator && value)) return;

    const isCurrencyRange =
      option?.type === 'currencyRange' ||
      (option?.type === 'currency' && operator.id === '__between');
    const isMultiSelect = option?.type === 'multiSelect';

    // allow a currency range from 0 to 0 to be applied
    if (Array.isArray(value) && !isMultiSelect && !((value[0] && value[1]) || isCurrencyRange))
      return;
    onApplyFilter({option, operator, value: value ?? null});
    handleClose();
  };

  useEffect(() => {
    const autoSelectOption = options.find(o => o.autoSelect);
    if (autoSelectOption) {
      handleFieldChange(autoSelectOption.id);
    }
  }, []);

  return (
    <>
      <Button color="primary" endIcon={<FilterListIcon />} onClick={handleButtonClick}>
        Filter
      </Button>
      <Popover
        className={classes.popover}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: alignPopup,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: alignPopup,
        }}
      >
        <div className={classes.popoverContent}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Field"
                id="field"
                name="field"
                value={option?.id || ''}
                onChange={e => handleFieldChange(e.target.value)}
                select
              >
                {options
                  .filter(e => !e.force && !e.hidden)
                  .sortAndMap(
                    e => {
                      return (
                        <MenuItem key={e.id} value={e.id}>
                          {e.label}
                        </MenuItem>
                      );
                    },
                    e => e.label
                  )}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              {option && (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Operator"
                  id="operator"
                  name="operator"
                  defaultValue={option.operators[0].id}
                  value={operator?.id || ''}
                  onChange={handleOperatorChange}
                  select
                >
                  {option.operators.sortAndMap(
                    e => {
                      return (
                        <MenuItem key={e.id} value={e.id}>
                          {e.label}
                        </MenuItem>
                      );
                    },
                    e => e.label
                  )}
                </TextField>
              )}
            </Grid>

            {options && option?.type === 'text' && (
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  label={option.label}
                  id="value"
                  name="value"
                  value={value}
                  onChange={handleValueChange}
                />
              </Grid>
            )}

            {options && option?.type === 'number' && (
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  type="number"
                  label={option.label}
                  id="value"
                  name="value"
                  value={value}
                  onChange={handleValueChange}
                />
              </Grid>
            )}
            {options && option?.type === 'minorUnits' && operator?.id !== '__between' && (
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  type="number"
                  label={option.label}
                  id="value"
                  name="value"
                  value={Number(value) / 100}
                  onChange={handleMinorUnitsChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
            )}
            {options && option?.type === 'currency' && operator?.id !== '__between' && (
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  type="number"
                  label={option.label}
                  id="value"
                  name="value"
                  value={value}
                  onChange={handleValueChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
            )}
            {options &&
              (option?.type === 'currencyRange' ||
                (option?.type === 'currency' && operator?.id === '__between')) && (
                <Fragment>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      type="number"
                      label={'From'}
                      id="value"
                      name="value"
                      value={value?.[0]}
                      onChange={handleCurrencyRangeSelect(0)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      type="number"
                      label={'To'}
                      id="value"
                      name="value"
                      value={value?.[1]}
                      onChange={handleCurrencyRangeSelect(1)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  </Grid>
                </Fragment>
              )}
            {options && option?.type === 'list' && option.options && (
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label={option.label}
                  id="value"
                  name="value"
                  value={value || ''}
                  onChange={handleValueChange}
                  select
                >
                  {option.options
                    .sort((a: ListOption, b: ListOption) => a.value.localeCompare(b.value))
                    .map(item => (
                      <MenuItem key={item.key} value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            )}
            {options && option?.type === 'multiSelect' && option.options && (
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label={option.label}
                  id="value"
                  name="value"
                  SelectProps={{
                    multiple: true,
                    value: value || [],
                    onChange: handleMultiSelectValueChange,
                    renderValue: renderMultiSelectValue,
                  }}
                  select
                >
                  {option.options
                    .sort((a: ListOption, b: ListOption) => a.value.localeCompare(b.value))
                    .map(item => (
                      <MenuItem key={item.key} value={item.key}>
                        <Checkbox checked={((value as string[]) || []).indexOf(item.key) > -1} />
                        <ListItemText primary={item.value} />
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            )}

            {options &&
              (option?.type === 'dateRange' || option?.type === 'dateOnlyRange') &&
              value && (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid item xs={12}>
                    <DatePicker
                      label={'From'}
                      value={value[0]}
                      onChange={handleDateRangeSelect(0)}
                      inputFormat={
                        option?.dateFormat != null ? option.dateFormat : DEFAULT_DATE_FORMAT
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          id="value"
                          name="value"
                          margin="normal"
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DatePicker
                      label={'To'}
                      value={value[1]}
                      onChange={handleDateRangeSelect(1)}
                      inputFormat={
                        option?.dateFormat != null ? option.dateFormat : DEFAULT_DATE_FORMAT
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          id="value"
                          name="value"
                          margin="normal"
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid>
                </LocalizationProvider>
              )}
            {options && option?.type === 'date' && value && (
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={'Date'}
                    value={value[0]}
                    inputFormat={
                      option?.dateFormat != null ? option.dateFormat : DEFAULT_DATE_FORMAT
                    }
                    onChange={handleDateSelect}
                    renderInput={params => (
                      <TextField
                        {...params}
                        id="value"
                        name="value"
                        margin="normal"
                        fullWidth
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            )}
            {options && option?.type === 'dateOnly' && value && (
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={'Date'}
                    value={value[0]}
                    inputFormat={
                      option?.dateFormat != null ? option.dateFormat : DEFAULT_DATE_FORMAT
                    }
                    onChange={handleDateOnlySelect}
                    renderInput={params => (
                      <TextField
                        {...params}
                        id="value"
                        name="value"
                        margin="normal"
                        fullWidth
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            )}
            {options && option?.type === 'month' && value && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid item xs={12}>
                  <DatePicker
                    label={'Month'}
                    value={value[0]}
                    onChange={handleMonthSelect}
                    inputFormat={
                      option?.dateFormat != null ? option.dateFormat : DEFAULT_MONTH_FORMAT
                    }
                    views={['year', 'month']}
                    renderInput={params => (
                      <TextField
                        {...params}
                        id="value"
                        name="value"
                        margin="normal"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
              </LocalizationProvider>
            )}
            {options && option?.type === 'monthRange' && value && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid item xs={12}>
                  <DatePicker
                    label={'From'}
                    value={value[0]}
                    onChange={handleMonthRangeSelect(0)}
                    inputFormat={
                      option?.dateFormat != null ? option.dateFormat : DEFAULT_MONTH_FORMAT
                    }
                    views={['year', 'month']}
                    renderInput={params => (
                      <TextField
                        {...params}
                        id="value"
                        name="value"
                        margin="normal"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    label={'To'}
                    value={value[1]}
                    onChange={handleMonthRangeSelect(1)}
                    inputFormat={
                      option?.dateFormat != null ? option.dateFormat : DEFAULT_MONTH_FORMAT
                    }
                    views={['year', 'month']}
                    renderInput={params => (
                      <TextField
                        {...params}
                        id="value"
                        name="value"
                        margin="normal"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
              </LocalizationProvider>
            )}
            <Grid item xs={6}>
              <Button variant="contained" onClick={reset} fullWidth>
                Reset
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" color="primary" onClick={apply} fullWidth>
                Apply Filter
              </Button>
            </Grid>
          </Grid>
        </div>
      </Popover>
    </>
  );
};
