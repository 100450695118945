import ClearIcon from '@mui/icons-material/Clear';
import {Autocomplete, Box, IconButton, TextField} from '@mui/material';
import {SyntheticEvent, useState} from 'react';
import {useAgentsOptions} from '../hooks/useAgentsOptions';
import {useTicketsFiltersContainer} from '../hooks/useTicketsFiltersContainer';
import {Option} from '../types';

export function TicketsListFilterAgent() {
  const {agentIdFilter, setAgentIdFilter} = useTicketsFiltersContainer();
  const {agentOptions, defaultValue} = useAgentsOptions();
  const [value, setValue] = useState<Option | undefined>(defaultValue);

  if (!agentOptions?.length) {
    return null;
  }

  return (
    <Box ml={2} display="flex" alignItems="flex-end">
      <Autocomplete
        disablePortal
        disableClearable
        options={agentOptions}
        value={value}
        onChange={(_: SyntheticEvent<Element, Event>, newValue: Option | null) => {
          setValue(newValue ?? undefined);
          setAgentIdFilter(newValue?.value || undefined);
        }}
        isOptionEqualToValue={(option, value) => option?.label === value?.label}
        renderInput={params => (
          <TextField
            {...params}
            sx={{
              minWidth: 250,
              '& .MuiInput-root': {
                fontStyle: agentIdFilter ? 'inherit' : 'italic',
                color: agentIdFilter ? 'inherit' : 'gray',
              },
              '& .MuiInput-root:hover:before': {border: 'none'},
              '& .MuiInput-root:before': {border: 'none'},
              '& .Mui-disabled:before': {border: 'none', borderBottomStyle: 'none !important'},
            }}
            label="Agents"
            variant="standard"
          />
        )}
      />
      {agentIdFilter && (
        <IconButton
          size="small"
          sx={{alignSelf: 'flex-end', marginBottom: '-1px'}}
          onClick={() => {
            setAgentIdFilter(undefined);
            setValue(defaultValue);
          }}
        >
          <ClearIcon />
        </IconButton>
      )}
    </Box>
  );
}
