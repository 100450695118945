export const UnderwritingIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g fill={'inherit'}>
        <path
          d="M64,400c0,8.8,7.2,16,16,16h12.4c18.6,0,35.1-11.9,41-29.5l10.6-31.9l16.8,50.5c4.4,13.5,23.1,14.9,29.5,2.1
		l7.7-15.3c1.8-3.8,5.7-6.3,10-6.2c4.4,0,8.4,2.5,10.2,6.5c7.2,14.4,21.8,23.6,37.8,23.9h101l27-27.2v99.3c0,13.2-10.8,23.9-24,23.9
		c0,0,0,0,0,0H23.9C10.7,512,0,501.2,0,488c0,0,0,0,0,0V23.9C0,10.7,10.8,0,24,0c0,0,0,0,0,0h232v112c0,8.8,7.2,16,16,16h112v123.7
		L288,347v37h-32c-4-0.4-7.6-2.9-9.4-6.5c-11.9-23.9-46.2-30.4-66-14.2l-13.9-41.6c-4.2-12.6-17.8-19.4-30.3-15.2
		c-7.2,2.4-12.8,8-15.2,15.2L103,376.3c-1.5,4.6-5.8,7.7-10.6,7.7H80C71.2,384,64,391.2,64,400z"
          opacity={0.4}
        />
        <path
          d="M384,121.9c0-6.3-2.5-12.4-7-16.9L279.1,7c-4.5-4.5-10.6-7-17-7H256v112c0,8.8,7.2,16,16,16h112V121.9z
		 M288,347v69h69l161.7-162.8l-67.9-67.9L288,347z M568.6,167.4l-31.9-31.9c-9.9-9.9-26.1-9.9-36,0l-27.2,27.2l67.9,67.9l27.2-27.2
		C578.5,193.4,578.5,177.3,568.6,167.4C568.6,167.4,568.6,167.4,568.6,167.4L568.6,167.4z"
        />
      </g>
    </svg>
  );
};
