import DownloadIcon from '@mui/icons-material/Download';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import {useCallback, useState} from 'react';
import {UniversalTimestamp} from '../..';
import {DialogConfirmation} from './DialogConfirmation';
import {StackAttachment} from './types';
import {getDownloadLinkFromPath, startDownload} from './utils';

type Props = {
  attachments: StackAttachment[];
  removeAttachment?: (attachmentId?: string) => Promise<void>;
  flexDirection?: 'row-reverse' | 'row';
};

export const StackOfAttachments = ({
  attachments,
  removeAttachment,
  flexDirection = 'row-reverse',
}: Props) => {
  const [loading, setLoading] = useState('');
  const [attachmentIdToDelete, setAttachmentIdToDelete] = useState<string | null>(null);

  const onAttachmentClick = useCallback(async (fileCloudPath: string, fileName: string) => {
    setLoading(fileCloudPath);
    try {
      const fileDownloadUrl = await getDownloadLinkFromPath(fileCloudPath);
      startDownload(fileDownloadUrl, fileName);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading('');
    }
  }, []);

  const closeConfirmation = useCallback(() => {
    setAttachmentIdToDelete(null);
  }, []);

  const onAttachmentDelete = useCallback(async () => {
    if (removeAttachment && attachmentIdToDelete) {
      await removeAttachment(attachmentIdToDelete);
      closeConfirmation();
    }
  }, [removeAttachment, attachmentIdToDelete]);

  const onDeleteClick = useCallback((id: string | null) => {
    setAttachmentIdToDelete(id);
  }, []);

  return (
    <Box flexDirection={flexDirection} display="flex" flexWrap="wrap" p={0}>
      {attachments.map(a => (
        <Chip
          icon={
            loading === a.fileCloudPath ? (
              <CircularProgress size={18} sx={{ml: 0.2}} />
            ) : (
              <DownloadIcon />
            )
          }
          key={a.id}
          onDelete={() => onDeleteClick(a.id)}
          onClick={() => onAttachmentClick(a.fileCloudPath, a.fileName)}
          label={a.fileName}
          title={(a.createdAt as UniversalTimestamp)?.toDate().toDateString()}
          variant="outlined"
          disabled={loading === a.fileCloudPath}
          sx={{
            mt: 1,
            mr: 1,
            ml: 0,
          }}
        />
      ))}

      {attachmentIdToDelete && (
        <DialogConfirmation
          open
          title="Delete Attachment?"
          text="This attachment will be permanently deleted.  "
          onClose={closeConfirmation}
          onOk={onAttachmentDelete}
        />
      )}
    </Box>
  );
};
