import {orderBy} from 'lodash';
import {useEffect, useState} from 'react';
import {TicketStatus} from '../../..';
import {stateEmpty, statePromised} from '../constants/constants';
import {FilterKey} from '../types';
import {whenAllTickets} from '../utils';
import {filterTicketsByKey} from '../utils/filters';
import {useTicketsMyGroup} from './ticketsSlices/useTicketsMyGroup';
import {useTicketsFiltersContainer} from './useTicketsFiltersContainer';
import {useTicketsQuery} from './useTicketsQuery';

export function useTicketsAllAgentAdmin(statusSet: TicketStatus[], filterKey?: FilterKey) {
  const {sortBy, sortDir} = useTicketsQuery();
  const {agentIdFilter} = useTicketsFiltersContainer();
  const [tickets, setTickets] = useState(statePromised);

  const {ticketsMyGroup} = useTicketsMyGroup(statusSet);

  useEffect(() => {
    !tickets.promised && setTickets(statePromised);
    whenAllTickets([ticketsMyGroup], mergedTickets => {
      if (!mergedTickets.length) {
        setTickets(stateEmpty);
        return;
      }
      const ticketsSorted = orderBy(mergedTickets, [sortBy], [sortDir]);
      let ticketsFiltered = agentIdFilter
        ? ticketsSorted.filter(t => t.associatedAgents?.includes(agentIdFilter))
        : ticketsSorted;
      ticketsFiltered = filterTicketsByKey(ticketsFiltered, filterKey);
      setTickets({promised: false, data: ticketsFiltered});
    });
  }, [ticketsMyGroup, sortBy, sortDir, agentIdFilter]);

  return {
    tickets,
    counter: tickets.data?.length ?? 0,
  };
}
