import {format} from 'date-fns';

const defaultMinutes = [0, 15, 30, 45];

export const populateTimeSlots = (
  timeslots: any[],
  getTime: (hours: number, minutes: number) => any,
  minutes: number[] = defaultMinutes
) => {
  for (let hour = 0; hour < 24; hour++) {
    for (let minute of minutes) {
      timeslots.push(getTime(hour, minute));
    }
  }
};

export const timeList = (timeFormat: string = 'h:mm a', minutes: number[] = defaultMinutes) => {
  const timeslots: any[] = [];
  const getTime = (hours: number, minutes: number) => {
    const now = new Date();
    now.setHours(hours);
    now.setMinutes(minutes);
    return format(now, timeFormat);
  };
  populateTimeSlots(timeslots, getTime, minutes);
  return timeslots;
};
