import {
  SortDirection,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  Tooltip,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Column} from '@ozark/common/api/Column';
import clsx from 'clsx';
import React from 'react';
import {TooltipPlacement} from '../..';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    clickable: {
      color: `${theme.palette.primary.main} !important`,
    },
    nowrap: {
      whiteSpace: 'nowrap',
    },
    root: {
      pointerEvents: 'auto !important' as 'auto',
      '&.Mui-disabled:hover': {
        color: 'initial',
      },
    },
  })
);

interface Props {
  columns: Column<any>[];
  columnLabelNoWrap?: boolean;
  order: SortDirection;
  orderBy: string;
  actions: any;
  historyColumn: any;
  onRequestSort: any;
  actionsCaption?: string;
}

export const Head = ({
  columns,
  columnLabelNoWrap,
  order,
  orderBy,
  actions,
  historyColumn,
  onRequestSort,
  actionsCaption,
}: Props) => {
  const classes = useStyles();

  const getSortHandler = (property: string) => (event: React.MouseEvent<HTMLSpanElement>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column: Column<any>) => {
          const disabled = !column.sortable;
          return (
            <TableCell
              key={column.id}
              align={column.align || (column.numeric ? 'right' : 'left')}
              padding={column.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === column.id ? order : false}
              className={column.className}
              style={column.width ? {minWidth: column.width} : undefined}
            >
              <Tooltip
                title={column.headingTooltip ?? (column.sortable ? 'Sort' : '')}
                placement={
                  column.numeric ? TooltipPlacement.BottomEnd : TooltipPlacement.BottomStart
                }
                enterDelay={300}
              >
                <TableSortLabel
                  color="primary"
                  active={orderBy === column.id}
                  direction={order || undefined}
                  disabled={!column.sortable}
                  className={clsx(
                    column.sortable && classes.clickable,
                    columnLabelNoWrap && classes.nowrap
                  )}
                  classes={{
                    root: classes.root,
                  }}
                  onClick={disabled ? undefined : getSortHandler(column.id)}
                  hideSortIcon={disabled}
                >
                  {column.label}
                </TableSortLabel>
              </Tooltip>
              {column.info && column.info}
            </TableCell>
          );
        })}
        {actions && <TableCell>{!!actionsCaption ? actionsCaption : ''}</TableCell>}
        {historyColumn && <TableCell></TableCell>}
      </TableRow>
    </TableHead>
  );
};
