import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useCallback} from 'react';

type Props = {
  title: string;
  text: string;
  open: boolean;
  onClose: () => void;
  onOk: () => void;
};
export function DialogConfirmation({title, text, open, onClose, onOk}: Props) {
  const handleOk = useCallback(() => {
    onClose();
    onOk();
  }, [onClose, onOk]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{px: 3, pb: 3, display: 'flex'}}>
          <Button onClick={handleOk} autoFocus variant="contained" color="error" fullWidth>
            Delete
          </Button>
          <Button onClick={onClose} variant="text" color="secondary" fullWidth>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
