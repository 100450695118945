import {
  Agent,
  AgentView,
  Announcement,
  AnnouncementView,
  AutoAssignLeadsConfig,
  AutoAssignLeadsConfigView,
  AUTO_ASSIGN_LEADS_DOC_ID,
  Collections,
  createAuditNonce,
  DocumentTypes,
  Firebase,
  Group,
  GroupView,
  Lead,
  LeadView,
  Merchant,
  MerchantView,
  Precalculation,
  PrecalculationView,
  Residual,
  ResidualView,
  selectAgentView,
  selectAnnouncementView,
  selectAutoAssignLeadsConfigView,
  selectGroupView,
  selectLeadView,
  selectMerchantView,
  selectPrecalculationView,
  selectResidualView,
  selectSubscriptionConfigView,
  SubscriptionConfig,
  SubscriptionConfigView,
  UniversalSnapshot,
  ViewSelector,
  ViewTypes,
} from '@ozark/common';
import {useCallback, useEffect, useState} from 'react';
import {AsyncState} from '../models';

export const useDocumentSnapshot = <
  TDocumentView extends ViewTypes,
  TDocument extends DocumentTypes
>(
  collection: Collections,
  id: string | undefined | null,
  viewSelector: ViewSelector<TDocumentView>,
  subcollection?: {collection: Collections; id: string}
) => {
  const [document, setDocument] = useState<AsyncState<TDocumentView>>({promised: true});

  useEffect(() => {
    if (!id) {
      return;
    }

    let query = Firebase.firestore.collection(collection).doc(id);
    if (subcollection) {
      query = query.collection(subcollection.collection).doc(subcollection.id);
    }

    const unsubscribe = query.onSnapshot(
      snapshot => {
        if (!snapshot.exists) {
          setDocument({promised: false});
          return;
        }

        const data = viewSelector(snapshot as UniversalSnapshot<TDocument>);

        if (!data) {
          return;
        }

        setDocument({promised: false, data});
      },
      err => {
        console.error(err);
        setDocument({promised: false, error: err});
      }
    );
    return () => {
      unsubscribe();
    };
  }, [id]);

  const set = useCallback(
    async (partialDocument: Partial<TDocument>, auditable?: boolean) => {
      if (!id) {
        return;
      }

      try {
        let data = {...partialDocument};
        if (auditable) {
          const auditNonce = createAuditNonce(Firebase.auth.currentUser!.uid);
          data = {...data, auditNonce};
        }

        let query = Firebase.firestore.collection(collection).doc(id);

        if (subcollection) {
          query = query.collection(subcollection.collection).doc(subcollection.id);
        }

        await query.set(data, {merge: true});
      } catch (err: any) {
        console.error('failed to set document', id, document, err);
        throw Error(err);
      }
    },
    [id]
  );

  return {document, set};
};

export const useGroup = (id: string) =>
  useDocumentSnapshot<GroupView, Group>(Collections.groups, id, selectGroupView);

export const useAgent = (id?: string) =>
  useDocumentSnapshot<AgentView, Agent>(Collections.agents, id, selectAgentView);

export const useMerchant = (id?: string) =>
  useDocumentSnapshot<MerchantView, Merchant>(Collections.merchants, id, selectMerchantView);

export const useResidual = (id: string) =>
  useDocumentSnapshot<ResidualView, Residual>(Collections.residuals, id, selectResidualView);

export const useAnnouncement = (id: string) =>
  useDocumentSnapshot<AnnouncementView, Announcement>(
    Collections.announcements,
    id,
    selectAnnouncementView
  );

export const useLead = (leadId: string) =>
  useDocumentSnapshot<LeadView, Lead>(Collections.leads, leadId, selectLeadView);

export const useSubscriptionConfig = (uid: string | undefined | null) =>
  useDocumentSnapshot<SubscriptionConfigView, SubscriptionConfig>(
    Collections.subscriptionConfigs,
    uid,
    selectSubscriptionConfigView
  );

export const usePrecalculations = (precalculationName: string | undefined) => {
  const {document} = useDocumentSnapshot<PrecalculationView, Precalculation>(
    Collections.precalculations,
    precalculationName,
    selectPrecalculationView
  );

  const value = Array.isArray(document.data?.value) ? document.data!.value : [];

  return {
    value: value.length,
  };
};

export const useAutoAssignLeadsConfig = () => {
  const {document, set} = useDocumentSnapshot<AutoAssignLeadsConfigView, AutoAssignLeadsConfig>(
    Collections.salesCampaigns,
    AUTO_ASSIGN_LEADS_DOC_ID,
    selectAutoAssignLeadsConfigView
  );

  /**
   * @throws Error
   */
  const toggleUid = useCallback(
    async (uid: string) => {
      try {
        const currentUids = document.data?.uids ?? [];

        const nextUids = currentUids.includes(uid)
          ? currentUids.filter(x => x !== uid)
          : [...currentUids, uid];

        await set({
          uids: nextUids,
        });
      } catch (err: any) {
        console.error('Error toggle auto assign leads', err);
        throw new Error(err);
      }
    },
    [document, set]
  );

  return {document, set, toggleUid} as const;
};
