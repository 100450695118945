import {AUTOMATION_TICKET_COMMENT_AUTHOR, AUTOMATION_TICKET_COMMENT_UID} from '@ozark/common';
import {useCallback} from 'react';
import {useUserInfo} from '../../../hooks';
import {useUserProfilesContainer} from '../../../store/containers/UserProfilesContainer';
import {CommentInfo} from './useComments';

export const useCommentAuthorName = () => {
  const {authUser} = useUserInfo();
  const {userProfiles} = useUserProfilesContainer();

  const getCommentAuthorName = useCallback(
    (comment: CommentInfo): string => {
      const {uid, firstName, lastName, department, role, email, type} = comment.author ?? {};

      if (uid === AUTOMATION_TICKET_COMMENT_UID) {
        return AUTOMATION_TICKET_COMMENT_AUTHOR;
      }
      if (firstName || lastName) {
        return [firstName, lastName].filter(Boolean).join(' ');
      }

      if (department) {
        return `${department} department`;
      }

      if (role) {
        return role;
      }
      if (uid && uid === authUser.data?.uid) {
        return authUser.data?.displayName!;
      }

      if (email) {
        if (type) {
          return `${type} ${email}`;
        }
        return email;
      }

      const profilesAreLoaded = !userProfiles.promised;

      return profilesAreLoaded ? 'Not Available' : 'Loading author info...';
    },
    [authUser.data?.uid, userProfiles.promised]
  );

  return {
    getCommentAuthorName,
  };
};
