import {Box, Divider, Stack} from '@mui/material';
import {
  AgentDeclines,
  AgentDeclinesRecord,
} from '@ozark/functions/src/functions/express/private/types/AgentStatistics';
import {add, endOfDay, startOfDay} from 'date-fns';
import {isEmpty, orderBy} from 'lodash';
import {useEffect, useState} from 'react';
import {
  BoxParentHeight,
  Filter,
  FilterOption,
  forceActiveFilter,
  InfoLoading,
  InfoNoData,
  MonthYearPicker,
  Square,
  Table,
} from '../..';
import {
  AgentView,
  formatAsPercent,
  formatterNumber,
  SearchCriteria,
  useApiContainer,
} from '../../..';
import {Column} from '../../../api/Column';
import {useAgents} from '../../../hooks/useAgents';
import {useQueryMonthYear} from '../../Analytics/common/useQueryMonthYear';
import {AgentSelect} from '../../Filters/AgentSelect';

export const AgentStatisticsDeclines = () => {
  const api = useApiContainer();
  const [selectedAgent, setSelectedAgent] = useState<AgentView>();
  const {agents} = useAgents();
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState<AgentDeclines | null>();

  const {year, month} = useQueryMonthYear();
  const [filters, setFilters] = useState<Filter>({}); // defines date scope
  const [pageConfig, setPageConfig] = useState<SearchCriteria>({
    order: 'asc',
    orderBy: 'agentName',
    offset: 1,
    limit: 100,
  });
  const hasData = Boolean(reportData?.data.length);

  useEffect(() => {
    if (isEmpty(filters)) return;

    let isMounted: boolean = true;

    const loadReportData = async (): Promise<void> => {
      try {
        const result = await api?.agentStatistics.getAgentsDeclinesStatistics(
          pageConfig,
          Object.values(filters),
          selectedAgent?.id
        );
        const sortedReportData = applySorting(result, pageConfig);

        if (isMounted) {
          setReportData(sortedReportData ?? undefined);
        }
      } catch (error) {
        console.error('Failed at loading Agent declines statistics', error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    setLoading(true);
    loadReportData();

    return () => {
      isMounted = false;
    };
  }, [filters, selectedAgent?.id]);

  useEffect(() => {
    const startingDate = startOfDay(new Date(year, month - 1, 1));
    const transactionDate = forceActiveFilter(
      AgentDeclinesFilters,
      'transactionDate',
      '__between',
      [startingDate, endOfDay(add(startingDate, {months: 1}))]
    );
    setFilters(previous => ({...previous, transactionDate}));
  }, [year, month]);

  const onRetrieveData = (newPageConfig: SearchCriteria) => {
    setPageConfig(newPageConfig);
    const sortedReportData = applySorting(reportData, newPageConfig);

    setReportData(sortedReportData);
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <Box flex={1} />
        <AgentSelect
          agents={agents}
          onAgentSelect={setSelectedAgent}
          selectedAgent={selectedAgent}
        />
        <Divider orientation="vertical" flexItem light sx={{mx: 2}} />
        <MonthYearPicker />
      </Box>
      {loading && <InfoLoading />}
      {!loading && !hasData && <InfoNoData />}
      {!loading && hasData && (
        <>
          <Stack mt={2} direction="row" spacing={2}>
            <Box flex={1}>
              <Square
                lines={{
                  'Total Auth Decline Ratio': formatAsPercent(
                    Number(reportData?.totalDeclineRatio ?? 0)
                  ),
                }}
                center
              />
            </Box>
            <Box flex={1}>
              <Square
                lines={{
                  'Total Declines Count': formatterNumber.format(
                    Number(reportData?.totalDeclinesCount ?? 0)
                  ),
                }}
                center
              />
            </Box>
            <Box flex={1}>
              <Square
                lines={{
                  'Total Authorizations Count': formatterNumber.format(
                    Number(reportData?.totalAuthorizationsCount ?? 0)
                  ),
                }}
                center
              />
            </Box>
          </Stack>
          <BoxParentHeight my={2}>
            <Table
              columns={columns}
              data={{
                sort: [[pageConfig.orderBy, pageConfig.order as 'DESC' | 'ASC']],
                limit: pageConfig.limit,
                offset: pageConfig.offset,
                totalCount: reportData?.data.length || 0,
                data: reportData?.data || [],
              }}
              onRetrieveData={onRetrieveData}
              stickyHeader
            />
          </BoxParentHeight>
        </>
      )}
    </>
  );
};

const applySorting = (
  reportData: AgentDeclines | null | undefined,
  searchCriteria: SearchCriteria
): AgentDeclines | null | undefined => {
  if (!reportData) {
    return reportData;
  }

  const sortedAgentDeclines = orderBy(
    reportData?.data,
    [row => row[searchCriteria.orderBy as keyof typeof row] ?? ''],
    [searchCriteria.order.toLowerCase() as 'desc' | 'asc']
  );

  return {
    ...reportData,
    data: sortedAgentDeclines,
  };
};

const columns: Column<AgentDeclinesRecord>[] = [
  {
    id: 'agentName',
    label: 'Agent Name',
    sortable: true,
  },
  {
    id: 'totalDeclinesCount',
    label: 'Total Declines Count',
    sortable: true,
    numeric: true,
  },
  {
    id: 'totalAuthorizationsCount',
    label: 'Total Authorizations Count',
    sortable: true,
    numeric: true,
  },
  {
    id: 'authDeclineCountRatio',
    label: 'Auth Decline Ratio',
    sortable: true,
    numeric: true,
    selector: row => formatAsPercent(row.authDeclineCountRatio),
  },
  {
    id: 'portfolioCountRatio',
    label: '% of Total Portfolio',
    sortable: true,
    numeric: true,
    selector: row => formatAsPercent(row.portfolioCountRatio),
  },
];

export const AgentDeclinesFilters: FilterOption[] = [
  {
    id: 'transactionDate',
    column: 'transactionDate',
    label: 'Date Range',
    type: 'dateRange',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
];
