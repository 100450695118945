import {Box, CircularProgress, styled, Typography} from '@mui/material';

export const InfoLoading = () => {
  return (
    <BoxStyled>
      <Box>
        <TypographyStyled>Please wait. Reporting data is being loaded...</TypographyStyled>
        <Box textAlign="center" pt={2}>
          <CircularProgress color="primary" />
        </Box>
      </Box>
    </BoxStyled>
  );
};

export const TypographyStyled = styled(Typography)`
  position: relative;
  text-align: center;
`;

export const BoxStyled = styled(Box)({
  display: 'flex',
  minHeight: 616,
  justifyContent: 'center',
  alignItems: 'center',
});
