import {Box, Divider} from '@mui/material';
import {
  LargeChargeback,
  LargeChargebackResult,
} from '@ozark/functions/src/functions/express/private/types/Chargebacks';
import {format, utcToZonedTime} from 'date-fns-tz';
import {omit} from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import {generatePath, Link} from 'react-router-dom';
import {
  BoxFlexed,
  BoxParentHeight,
  ButtonExportCsv,
  Filter,
  FilterOption,
  getCardNumber,
  getCaseType,
  getItemType,
  MERCHANT_PORTFOLIO_DETAILS,
  MidSelect,
  Table,
} from '../..';

import {
  AllMIDs,
  formatAsPercent,
  SearchCriteria,
  useApiContainer,
  useMidsContainer,
  useNotification,
} from '../../..';
import {Column} from '../../../api/Column';
import {LoadingStatus} from '../../Analytics/common/LoadingStatus';
import {CardImage, CardImagesDictionaryType} from '../../CardImage';
import {accountStatusFilter} from '../../Filters/common';
import {FiltersAddButton} from '../../Filters/FiltersAddButton';
import {FiltersApplied} from '../../Filters/FiltersApplied';

var currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
});

const hasMidPreselected = (mid: string) => mid && mid !== AllMIDs;
const getMidFilter = (mid: string): Filter => {
  const filterOption = filtersConfig.find(fo => fo.id === 'mid');
  if (!filterOption) throw new Error('filter option not found');
  return {
    mid: {
      option: filterOption,
      operator: filterOption.operators[0],
      value: mid,
    },
  };
};

export const FraudAnalysisLargeCb = () => {
  const api = useApiContainer();
  const [pageConfig, setPageConfig] = useState<SearchCriteria>(pageConfigDefault);
  const {mids, handleSelectMid: setSelectedMid, selectedMid} = useMidsContainer();
  const [filters, setFilters] = useState<Filter>(
    hasMidPreselected(selectedMid) ? {...getMidFilter(selectedMid)} : {}
  );
  const [data, setData] = useState<LargeChargebackResult | null>(null);
  const [loading, setLoading] = useState(true);

  const showNotification = useNotification();

  const getAllDataForExport = useCallback(async () => {
    if (!api) {
      return [];
    }

    try {
      const pageConfigFull: SearchCriteria = {...pageConfigDefault, offset: 0, limit: 0};

      const result = await api.fraud.getFraudLargeChargebacks(pageConfigFull, []);

      return result?.data ?? [];
    } catch (error) {
      console.error('getFraudLargeChargebacks error', error);

      showNotification('error', 'Error while loading report occurred.');

      return [];
    }
  }, [api]);

  const handleSelectMid = useCallback(
    (mid: string) => {
      setSelectedMid(mid);
      if (mid === AllMIDs) {
        setFilters(omit(filters, 'mid'));
      } else {
        setFilters({
          ...filters,
          ...getMidFilter(mid),
        });
      }
    },
    [filters, setSelectedMid]
  );

  useEffect(() => {
    if (!api) return;
    setLoading(true);
    let running: boolean | undefined = true;
    api.fraud
      .getFraudLargeChargebacks(pageConfig, Object.values(filters))
      .then(result => running && setData(result))
      .catch(err => {
        console.error(err);
        running && setData(null);
      })
      .finally(() => running && setLoading(false));
    return () => (running = undefined);
  }, [pageConfig, filters]);

  const hasData = Boolean(data?.data.length);

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: '1'}}>
      <BoxFlexed py={2} justifyContent="flex-end" alignItems="center">
        <FiltersApplied filters={filters} setFilters={setFilters} />
        <Box flex={1} />
        <FiltersAddButton filters={filters} setFilters={setFilters} filtersConfig={filtersConfig} />
        <Divider orientation="vertical" flexItem sx={{mx: 2}} />
        <MidSelect mids={mids} handleSelectMid={handleSelectMid} selectedMid={selectedMid} />
        <Divider orientation="vertical" flexItem sx={{mx: 2}} />
        <ButtonExportCsv
          useSelectorMapping
          columnsConfig={columnsConfig}
          filename="large-chargeback-report"
          getRows={getAllDataForExport}
          rows={data?.data}
        />
      </BoxFlexed>
      <LoadingStatus loading={loading} hasData={hasData} />

      {!loading && hasData && (
        <BoxParentHeight my={2} sx={{'& table': {backgroundColor: '#fff'}}}>
          <Table
            columns={columnsConfig}
            data={{
              sort: [[pageConfig.orderBy, pageConfig.order as 'DESC' | 'ASC']],
              limit: pageConfig.limit,
              offset: pageConfig.offset,
              totalCount: data?.totalCount ?? 0,
              data: data?.data ?? [],
            }}
            onRetrieveData={setPageConfig}
            paginate
            useOffsetAsPage
            stickyHeader
          />
        </BoxParentHeight>
      )}
    </Box>
  );
};

const pageConfigDefault: SearchCriteria = {
  limit: 20, // pageSize
  offset: 1, // page (offset = page * pageSize 1 based) 0 will produce negative offset
  order: 'desc',
  orderBy: 'averageRatio',
};
const filtersConfig: FilterOption[] = [
  {
    id: 'dateLoaded',
    column: 'dateLoaded',
    autoSelect: true,
    label: 'Date Range',
    type: 'dateRange',
    dateFormat: 'MMM dd, yyyy',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'agent',
    column: 'agent',
    label: 'Agent Name',
    type: 'text',
    operators: [
      {
        id: '__like',
        label: 'contains',
      },
    ],
  },
  {
    id: 'group',
    column: 'group',
    label: 'Group Name',
    type: 'text',
    operators: [
      {
        id: '__like',
        label: 'contains',
      },
    ],
  },
  {
    id: 'amount',
    column: 'amount',
    autoSelect: true,
    label: 'Amount range',
    type: 'currencyRange',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'mid',
    column: 'mid',
    label: 'Merchant MID',
    type: 'text',
    hidden: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  accountStatusFilter,
];

const columnsConfig: Column<LargeChargeback>[] = [
  {
    id: 'card',
    numeric: false,
    sortable: false,
    label: 'Card Type',
    width: 88,
    selector: row => {
      const firstDigitAccountNumber = row.cardholderAccountNumber.substring(0, 1);

      return (
        <CardImage
          cardType={firstDigitAccountNumber}
          dictionaryType={CardImagesDictionaryType.Chargebacks}
        />
      );
    },
    export: false,
  },
  {
    id: 'cardholderAccountNumber',
    numeric: false,
    sortable: false,
    export: true,
    label: 'Card Number',
    selector: row => getCardNumber(row.cardholderAccountNumber),
  },
  {
    id: 'mid',
    numeric: false,
    sortable: false,
    export: true,
    label: 'MID',
  },
  {
    id: 'dba',
    numeric: false,
    sortable: false,
    export: row => row.dba,
    label: 'DBA',
    selector: row => {
      const dba = row.dba;
      if (row.applicationId) {
        const route = generatePath(MERCHANT_PORTFOLIO_DETAILS, {id: row.applicationId});
        return <Link to={route}>{dba}</Link>;
      }

      return dba;
    },
  },
  {
    id: 'agent',
    numeric: false,
    width: 120,
    sortable: false,
    export: true,
    label: 'Agent',
  },
  {
    id: 'amount',
    numeric: true,
    width: 140,
    sortable: true,
    export: true,
    label: 'Chargeback Amount',
    selector: row => currencyFormatter.format(Number(row.amount)),
  },
  {
    id: 'reasonCode',
    numeric: true,
    width: 100,
    sortable: true,
    export: true,
    label: 'Reason Code',
  },
  {
    id: 'reasonDesc',
    numeric: false,
    width: 140,
    sortable: true,
    export: true,
    label: 'Reason Desc',
  },
  {
    id: 'itemType',
    numeric: false,
    width: 160,
    sortable: false,
    export: false,
    label: 'Item Type',
    selector: row => (!row.itemType ? 'N/A' : getItemType(row.itemType)),
  },
  {
    id: 'caseType',
    numeric: false,
    width: 160,
    sortable: false,
    export: false,
    label: 'Case Type',
    selector: row => getCaseType(row.caseType),
  },
  {
    id: 'dateLoaded',
    numeric: false,
    sortable: true,
    export: true,
    label: 'Date Loaded',
    selector: row => {
      const date = new Date(row.dateLoaded);
      const localTime = utcToZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
      return format(localTime, 'P');
    },
  },
  {
    id: 'dateTransaction',
    numeric: false,
    width: 140,
    sortable: true,
    export: true,
    label: 'Transaction Date',
    selector: row => {
      if (!row.dateTransaction) {
        return 'N/A';
      }
      const date = new Date(row.dateTransaction);
      const localTime = utcToZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
      return format(localTime, 'P');
    },
  },
  {
    id: 'averageRatio',
    numeric: true,
    width: 110,
    sortable: true,
    export: true,
    label: '% of Average Ticket',
    selector: row => formatAsPercent(Number(row.averageRatio)),
  },
  {
    id: 'averageTicket',
    numeric: true,
    width: 110,
    sortable: true,
    export: true,
    label: 'Average Ticket',
    selector: row => currencyFormatter.format(Number(row.averageTicket)),
  },
];
