import {TextField, Typography} from '@mui/material';
import {DefaultComponentProps, OverridableTypeMap} from '@mui/material/OverridableComponent';
import {CreateDisputeReasonProps, ReasonInputType} from '@ozark/common/';
import camelcase from 'lodash/camelCase';
import {DisputeReason} from './index';

export const createTextInputReason = ({
  name,
  description,
}: CreateDisputeReasonProps): DisputeReason => ({
  name: camelcase(name),
  title: name,
  inputType: ReasonInputType.Text,
  descriptionHtml: description,
  Description: (props: DefaultComponentProps<OverridableTypeMap>) => (
    <Typography {...props}>
      <span dangerouslySetInnerHTML={{__html: description}} />
    </Typography>
  ),
  FieldInput: props => (
    <TextField variant="outlined" fullWidth margin="normal" multiline maxRows={3} {...props} />
  ),
});
