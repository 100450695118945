import {Dispositions, useCallable, useDisputeHistory, useNotification} from '@ozark/common';
import {useState} from 'react';
import {getAgentByUid} from './useAgents';
import {getMerchantAsync} from './useMerchant';

export const useDocumentsUploadLink = () => {
  const {getDocumentsUploadToken} = useCallable();
  const {getDisputeReasons} = useDisputeHistory();
  const showNotification = useNotification();
  const [loading, setLoading] = useState(false);

  const documentsUploadLink = async (
    appId: string,
    userId: string,
    email: string,
    name: string,
    user: 'merchant' | 'agent' | 'erp',
    documents: string[]
  ) => {
    setLoading(true);
    const result = await getDocumentsUploadToken({
      appId: appId,
      userId,
      email,
      name,
      user,
      documents,
    });
    if (result.status !== 'ok') {
      console.error(
        `Failed to get documents upload token for appId ${appId} with an error`,
        result
      );
      showNotification('error', 'Failed to get documents upload token');
      setLoading(false);
      return;
    }

    setLoading(false);

    return result;
  };

  const copyUploadLink = async (
    appId: string,
    disposition: Dispositions,
    user: 'agent' | 'merchant',
    userId?: string
  ) => {
    let email = '';
    let name = '';
    if (user === 'agent' && userId) {
      const agent = await getAgentByUid(userId);
      if (agent) {
        email = agent.email;
        name = `${agent.firstName ?? ''} ${agent.lastName ?? ''}`.trim();
      }
    }
    if (user === 'merchant' && userId) {
      const merchant = await getMerchantAsync(userId);
      if (merchant) {
        email = merchant.email;
        name = `${merchant.firstName ?? ''} ${merchant.lastName ?? ''}`.trim();
      }
    }

    const reasons = (await getDisputeReasons(appId, disposition)).map(r => r.key);
    const linkData = await documentsUploadLink(
      appId,
      userId ?? '',
      email ?? user,
      name,
      user ?? 'erp',
      reasons
    );

    return linkData?.portalUrl;
  };

  return {
    documentsUploadLink,
    copyUploadLink,
    loading,
  };
};
