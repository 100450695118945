import {Merchant, ViewBase} from '@ozark/common';
import {Table} from '@ozark/common/components';
import {format} from 'date-fns';
import {orderBy} from 'lodash';
import {useEffect, useState} from 'react';
import {generatePath, useHistory} from 'react-router';
import {Column} from '../../api/Column';
import {SearchCriteria} from '../../api/SearchCriteria';
import * as ROUTES from '../../constants/routes';
import {useUserInfo} from '../../hooks/useUserInfo';

interface Props {
  merchants: ViewBase<Merchant>[];
}
export const MerchantGrid = ({merchants}: Props) => {
  const {isErpUser} = useUserInfo();
  const history = useHistory();
  const [filteredMerchants, setFilteredMerchants] = useState<ViewBase<Merchant>[]>([]);
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>({
    limit: 20, // page size
    offset: 1, // page
    orderBy: 'firstName',
    order: 'asc',
  });
  const handleRetrieveData = (searchCriteria: SearchCriteria) => {
    setSearchCriteria(searchCriteria);
  };
  const handleRowClick = (row: ViewBase<Merchant>) => {
    history.push(generatePath(ROUTES.MERCHANT_DETAILS_ROUTE, {id: row.id}));
  };
  useEffect(() => {
    if (merchants) {
      const filteredAndOrdered = orderBy(
        merchants,
        searchCriteria.orderBy,
        searchCriteria.order as 'desc' | 'asc'
      );
      setFilteredMerchants(filteredAndOrdered);
    }
  }, [merchants, searchCriteria]);
  return (
    <Table
      columns={getColumns(isErpUser)}
      rows={filteredMerchants}
      sort={[[searchCriteria.orderBy, searchCriteria.order as 'ASC' | 'DESC']]}
      onRowClick={(row: ViewBase<Merchant>) => handleRowClick(row)}
      onRetrieveData={handleRetrieveData}
      stickyHeader
    />
  );
};

const getColumns = (isErpUser: boolean): Column<ViewBase<Merchant>>[] => {
  const result = [
    {
      id: 'email',
      label: 'Username',
      sortable: true,
    },
    {
      id: 'firstName',
      label: 'First Name',
      sortable: true,
    },
    {
      id: 'lastName',
      label: 'Last Name',
      sortable: true,
    },
    {
      id: 'groupName',
      label: 'Group',
      sortable: true,
    },
    {
      id: 'lastLoginAt',
      label: 'Last Login',
      sortable: true,
      selector: (row: ViewBase<Merchant>) =>
        row.lastLoginAt ? format(row.lastLoginAt.toDate(), 'MM/dd/yyyy h:mm a') : '',
    },
  ];
  if (isErpUser) {
    result.push({
      id: 'owner',
      label: 'Owner',
      sortable: true,
      selector: (row: ViewBase<Merchant>) => (row.masterUid ? 'Sub-Merchant' : 'Owner'),
    });
  }
  return result;
};
