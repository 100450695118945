import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {AllMIDs} from '@ozark/common';
import {
  ButtonExportCsv,
  midsByProcessingColumnsConfig,
  MidsByProcessingPie,
  MidsByProcessingTable,
  MidSelect,
} from '@ozark/common/components';
import {ReactNode, useMemo} from 'react';
import {useMidsContainer} from '../../../store';
import {LoadingStatus} from '../common/LoadingStatus';
import {ReportingMonthTitle} from '../common/ReportingMonthTitle';
import {ReportingPaper} from '../common/ReportingPaper';
import {useQueryMonthYear} from '../common/useQueryMonthYear';
import {useMidsByProcessingData} from './useMidsByProcessingData';

const MidsByProcessingPage = () => {
  const {year, month} = useQueryMonthYear();
  const {selectedMid, handleSelectMid, mids} = useMidsContainer();

  const {allData, isLoading, top15Data} = useMidsByProcessingData({month, year});

  const breadcrumbs: ReactNode[] = [<Typography variant="body1">MIDS by Processing</Typography>];

  const allDataToDisplay = useMemo(() => {
    if (!allData.length || selectedMid === AllMIDs || !selectedMid) {
      return allData;
    }

    return allData.filter(x => x.mid === selectedMid);
  }, [allData, selectedMid]);

  const hasData = allDataToDisplay.length > 0;

  return (
    <>
      <ReportingMonthTitle breadcrumbs={breadcrumbs}>
        <MidSelect selectedMid={selectedMid} handleSelectMid={handleSelectMid} mids={mids} />

        <ButtonExportCsv
          columnsConfig={midsByProcessingColumnsConfig}
          disabled={isLoading}
          filename="mids-by-processing"
          rows={allData}
        />
      </ReportingMonthTitle>

      {(isLoading || !hasData) && <LoadingStatus loading={isLoading} hasData={hasData} />}

      {!isLoading && hasData && (
        <ReportingPaper>
          <Grid item container>
            <Grid item lg={12} xl={7}>
              <MidsByProcessingTable data={allDataToDisplay} />
            </Grid>
            <Grid item lg={12} xl={5}>
              <Box my={1} ml={3} height={550}>
                <Typography variant="h5" textAlign="center">
                  Top 15 MIDS by Processing
                </Typography>
                <MidsByProcessingPie data={top15Data} />
              </Box>
            </Grid>
          </Grid>
        </ReportingPaper>
      )}
    </>
  );
};

export {MidsByProcessingPage};
