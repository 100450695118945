import firebase from 'firebase/compat';
import {CalendarEvent, Collections} from '../../..';
import {Firebase, TimestampType} from '../../../firebase';

export function getWeekDatesFromDate(date: Date) {
  return Array.from(Array(7).keys()).map(idx => {
    const d = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    d.setDate(d.getDate() - d.getDay() + idx);
    if (idx === 6) {
      d.setHours(23);
      d.setMinutes(59);
      d.setSeconds(59);
    }
    return d;
  });
}

export function getHourFormat(hour: number) {
  let l = 'AM',
    h = hour;
  if (h > 12) {
    h = h - 12;
  }
  if (hour >= 12) {
    l = 'PM';
  } else {
    l = 'AM';
  }
  return `${h} ${l}`;
}

export const getNewCalendarEventDateTime = (): Date => {
  const calendarEventNewDate = new Date();
  calendarEventNewDate.setMinutes(calendarEventNewDate.getMinutes() + 59);
  calendarEventNewDate.setMinutes(0);
  return calendarEventNewDate;
};

export function mapCalendarEvents(
  snap: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) {
  return snap.docs.map(doc => mapCalendarEvent(doc));
}

export function mapCalendarEvent(
  doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
) {
  const data = doc.data() as CalendarEvent;
  data.id = doc.id;
  data.dateTime = (data.dateTime as unknown as TimestampType)?.toDate();
  data.createdAt = (data.createdAt as unknown as TimestampType)?.toDate();
  return data;
}

export const calendarEventAdd = async (calendarEvent: CalendarEvent) => {
  const eventRef = await Firebase.firestore
    .collection(Collections.calendar)
    .add({...calendarEvent});
  return eventRef.id;
};

export const calendarEventUpdate = async (calendarEvent: CalendarEvent) => {
  try {
    await Firebase.firestore
      .collection(Collections.calendar)
      .doc(calendarEvent.id)
      .set(calendarEvent, {merge: true});
  } catch (error) {
    console.error(error);
  }
};
