import {yupResolver} from '@hookform/resolvers/yup';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FiberManualRecordSharpIcon from '@mui/icons-material/FiberManualRecordSharp';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  Switch,
  Typography,
} from '@mui/material';
import {SxProps, Theme} from '@mui/material/styles';
import {
  ApplicationType,
  Benefits,
  Collections,
  copyWithoutRef,
  DiscountDuration,
  Firebase,
  getEnumKeyByValue,
  PCIFee,
  PCIFeeDefaultValues,
  PCIFeeTypes,
  ProcessingTypes,
  ProcessingTypesKeys,
  RateProgram,
  useNotification,
} from '@ozark/common';
import {EditBenefits, Select, TextField} from '@ozark/common/components';
import {ProcessingTypeIcons} from '@ozark/common/components/Application/processingTypeIcons';
import {emptyStringToNull} from '@ozark/common/helpers';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {useStore} from '../../store/helpers';

const rateSetSchema = {
  featuredText: yup.string().required(),
  featuredCaption: yup.string().required(),
  applicationType: yup.string().required(),
  discountDuration: yup.string().required(),
  qualifiedDiscountRateVisaMastercardDiscover: yup.number().transform(emptyStringToNull).nullable(),
  midQualifiedDiscountRateVisaMastercardDiscover: yup
    .number()
    .transform(emptyStringToNull)
    .nullable(),
  nonQualifiedDiscountRateVisaMastercardDiscover: yup
    .number()
    .transform(emptyStringToNull)
    .nullable(),
  qualifiedTransactionFeeVisaMastercardDiscover: yup
    .number()
    .transform(emptyStringToNull)
    .nullable(),
  midQualifiedTransactionFeeVisaMastercardDiscover: yup
    .number()
    .transform(emptyStringToNull)
    .nullable(),
  nonQualifiedTransactionFeeVisaMastercardDiscover: yup
    .number()
    .transform(emptyStringToNull)
    .nullable(),
  qualifiedDiscountRateAmex: yup.number().transform(emptyStringToNull).nullable(),
  midQualifiedDiscountRateAmex: yup.number().transform(emptyStringToNull).nullable(),
  nonQualifiedDiscountRateAmex: yup.number().transform(emptyStringToNull).nullable(),
  qualifiedTransactionFeeAmex: yup.number().transform(emptyStringToNull).nullable(),
  midQualifiedTransactionFeeAmex: yup.number().transform(emptyStringToNull).nullable(),
  nonQualifiedTransactionFeeAmex: yup.number().transform(emptyStringToNull).nullable(),
  qualifiedDebitPINRate: yup.number().transform(emptyStringToNull).nullable(),
  qualifiedDebitPINFee: yup.number().transform(emptyStringToNull).nullable(),
  qualifiedDebitNonPINRate: yup.number().transform(emptyStringToNull).nullable(),
  qualifiedDebitNonPINFee: yup.number().transform(emptyStringToNull).nullable(),
  otherVolumeRate: yup.number().transform(emptyStringToNull).nullable(),
  otherItemFee: yup.number().transform(emptyStringToNull).nullable(),
  accountOnFileFee: yup.number().transform(emptyStringToNull).nullable(),
  chargebackFee: yup.number().transform(emptyStringToNull).nullable(),
  retrievalFee: yup.number().transform(emptyStringToNull).nullable(),
  monthlyMinimumFee: yup.number().transform(emptyStringToNull).nullable(),
  avsFee: yup.number().transform(emptyStringToNull).nullable(),
  batchFee: yup.number().transform(emptyStringToNull).nullable(),
  debitAccessFee: yup.number().transform(emptyStringToNull).nullable(),
  additionalServicesFee: yup.number().transform(emptyStringToNull).nullable(),
  pciFee: yup.string(),
  pciFeeValue: yup
    .number()
    .transform(emptyStringToNull)
    .nullable()
    .when('pciFee', (pciFee: PCIFee, schema: any) => {
      if (pciFee !== PCIFee.waived) {
        return schema
          .min(0, 'PCI Fee must be a number 0 or greater')
          .required('PCI Fee Value is required')
          .typeError('PCI Fee Value is required');
      }
    }),
  earlyDeconversionFee: yup.number().transform(emptyStringToNull).nullable(),
  annualFee: yup.number().transform(emptyStringToNull).nullable(),
  regulatoryFee: yup.number().transform(emptyStringToNull).nullable(),
  ebtDiscountRate: yup.number().transform(emptyStringToNull).nullable(),
  ebtTransactionFee: yup.number().transform(emptyStringToNull).nullable(),
};

const schema = yup.object().shape({
  name: yup.string().required(),
  tooltip: yup.string().required(),
  processingTypes: yup.object({
    cardPresent: yup.object(rateSetSchema),
    cardNotPresent: yup.object(rateSetSchema),
    eCommerce: yup.object(rateSetSchema),
  }),
});

export const styles: Record<string, SxProps<Theme>> = {
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  title: {
    marginLeft: theme => theme.spacing(2),
    marginBottom: theme => theme.spacing(-1.5),
    fontSize: '1.2em',
    sm: {
      fontSize: '1em',
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'row',
    flexBasis: '20%',
    alignItems: 'center',
  },
  toggle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: theme => theme.spacing(2),
  },
  grow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: theme => theme.spacing(2),
    flexGrow: 1,
    '& > *': {
      margin: theme => theme.spacing(0, 0.5),
    },
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  processingTypeTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    align: 'left',
    alignItems: 'center',
    paddingLeft: theme => theme.spacing(1),
    '& > *': {
      marginRight: theme => theme.spacing(2),
    },
  },
};

export const CustomProgram = ({
  rateProgram,
  whichExpanded,
  onChange,
  onSubmit,
}: {
  rateProgram: RateProgram;
  whichExpanded: string | false;
  onChange: (panel: string) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  onSubmit: () => void;
}) => {
  const {authProfile, group, isUserGroupAdmin} = useStore();
  const showNotification = useNotification();

  const [benefitsState, setBenefitsState] = useState<Benefits>(rateProgram.benefits);
  const [initialBenefitsList, setInitialBenefitsList] = useState<string[]>([]);

  const [saving, setSaving] = useState(false);

  const isGroupAdminOrOwner = isUserGroupAdmin() || authProfile.data?.isGroupOwner;

  const formMethods = useForm<RateProgram>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...copyWithoutRef(rateProgram),
    },
    shouldUnregister: true,
  });

  const nonQualifiedRateTitle =
    rateProgram.name === 'ERR Program'
      ? 'Non Qualified Surcharge - Visa/MC/Discover'
      : 'Non Qualified Rate - Visa/MC/Discover';

  const {isDirty} = formMethods.formState;

  useEffect(() => {
    // save benefits list to have ability to reset later:
    const benefitsList = Object.assign([], rateProgram.benefits.list);
    setInitialBenefitsList(benefitsList);
  }, [rateProgram]);

  const handleResetClick = () => {
    setBenefitsState({...benefitsState, list: initialBenefitsList});
    formMethods.reset();
  };

  const handleToggleIsActive = async (
    _event: ChangeEvent<HTMLInputElement>,
    isChecked: boolean
  ) => {
    setSaving(true);

    // check if at least one program is active (for disabling only)
    if (!isChecked) {
      const checkActiveRestrictions = await checkActiveRestrictionsForPrograms();
      if (!checkActiveRestrictions) {
        setSaving(false);
        return;
      }
    }

    // toggle active status of the program
    await Firebase.firestore
      .collection(Collections.groups)
      .doc(authProfile?.data?.group.id)
      .collection(Collections.programs)
      .doc(rateProgram.id)
      .set({active: isChecked}, {merge: true});

    setSaving(false);
  };

  const handleToggleIsActiveProcessingType = async (isChecked: boolean, processingType: string) => {
    setSaving(true);

    // check if we have at least one active program for each Processing Type (for disabling only)
    if (!isChecked) {
      const checkActiveRestrictions = await checkActiveRestrictionsForPrograms(processingType);
      if (!checkActiveRestrictions) {
        setSaving(false);
        return;
      }
    }

    // toggle active status of the program
    const programSnapshot = await Firebase.firestore
      .collection(Collections.groups)
      .doc(authProfile?.data?.group.id)
      .collection(Collections.programs)
      .doc(rateProgram.id)
      .get();

    if (programSnapshot.exists) {
      const program = programSnapshot.data() as RateProgram;

      await programSnapshot.ref.set(
        {
          processingTypes: {
            cardPresent: {
              ...program.processingTypes.cardPresent,
              active:
                processingType === 'cardPresent'
                  ? isChecked
                  : program.processingTypes.cardPresent.active,
            },
            cardNotPresent: {
              ...program.processingTypes.cardNotPresent,
              active:
                processingType === 'cardNotPresent'
                  ? isChecked
                  : program.processingTypes.cardNotPresent.active,
            },
            eCommerce: {
              ...program.processingTypes.eCommerce,
              active:
                processingType === 'eCommerce'
                  ? isChecked
                  : program.processingTypes.eCommerce.active,
            },
          },
        },
        {merge: true}
      );
    }

    setSaving(false);
  };

  const checkActiveRestrictionsForPrograms = async (
    removableProcessingTypeId?: string
  ): Promise<boolean> => {
    const activeProcessingTypesMap: {[key in ProcessingTypesKeys]: boolean} = {
      cardPresent: false,
      cardNotPresent: false,
      eCommerce: false,
    };
    const types = Object.keys(activeProcessingTypesMap);

    // check count of active processing types for the program
    if (removableProcessingTypeId) {
      let activeProcessingTypesCount = 0;

      types.forEach(pt => {
        const key = pt as keyof typeof ProcessingTypes;

        if (rateProgram.processingTypes[key].active) {
          activeProcessingTypesCount++;
        }
      });

      if (activeProcessingTypesCount === 1) {
        showNotification(
          'error',
          'At least one Processing Type must be active for the Pricing Program.'
        );
        return false;
      }
    }

    // check count of active programs
    const activeProgramsSnapshot = await Firebase.firestore
      .collection(Collections.groups)
      .doc(authProfile?.data?.group.id)
      .collection(Collections.programs)
      .where('active', '==', true)
      .get();

    if (activeProgramsSnapshot.size < 2) {
      showNotification('error', 'At least one custom program must be active.');
      return false;
    }

    // check Processing Types:
    activeProgramsSnapshot.docs.forEach(p => {
      // skip from the check removable program id (only if we remove the full program)
      if (p.id === rateProgram.id && !removableProcessingTypeId) {
        return;
      }

      const program = p.data() as RateProgram;

      types.forEach(pt => {
        const key = pt as keyof typeof ProcessingTypes;

        // skip particular removable processing type as active
        if (p.id === rateProgram.id && key === removableProcessingTypeId) {
          return;
        }

        if (!activeProcessingTypesMap[key] && program.processingTypes[key].active) {
          activeProcessingTypesMap[key] = true;
        }
      });
    });

    // get the list of inactive processing types
    const uniqueProcessingTypes: ProcessingTypes[] = [];
    types.forEach(pt => {
      const key = pt as keyof typeof ProcessingTypes;

      if (!activeProcessingTypesMap[key]) {
        uniqueProcessingTypes.push(ProcessingTypes[key]);
      }
    });
    if (uniqueProcessingTypes.length > 0) {
      showNotification(
        'error',
        `You unable to disable the last active ${
          !removableProcessingTypeId ? 'program for' : ''
        } ${uniqueProcessingTypes.join(', ')} Processing Type${
          uniqueProcessingTypes.length > 1 ? 's' : ''
        }`
      );
      return false;
    }

    return true;
  };

  const handleUpdateBenefits = (benefits: Benefits) => {
    const equals =
      benefits.list.length === initialBenefitsList.length &&
      benefits.list.every((v, i) => v === initialBenefitsList[i]);
    formMethods.setValue('benefits.list', benefits.list, {shouldDirty: !equals});
  };

  const onSuccess = async (data: any) => {
    setSaving(true);
    await Firebase.firestore
      .collection(Collections.groups)
      .doc(authProfile?.data?.group.id)
      .collection(Collections.programs)
      .doc(rateProgram.id)
      .set(data, {merge: true});
    formMethods.reset(data);
    setSaving(false);
    onSubmit();
    showNotification('success', 'Pricing successfully updated.');
  };

  const onError = (_data: any) => {
    showNotification('error', 'An error occurred while saving.');
  };

  const readonly = rateProgram.global || group?.data?.allowCustomPrograms === false;

  return (
    <FormProvider {...formMethods}>
      <Accordion
        key={rateProgram.id}
        TransitionProps={{unmountOnExit: true}}
        expanded={whichExpanded === rateProgram.name}
        onChange={onChange(rateProgram.name)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-${rateProgram.id}-content`}
          id={`panel-${rateProgram.id}-header`}
        >
          <Box sx={styles.toggle}>
            <FiberManualRecordSharpIcon
              color={!rateProgram.global && rateProgram.active ? 'primary' : 'disabled'}
            />
          </Box>
          <Box sx={styles.column}>
            <Typography variant="body1">{rateProgram.name}</Typography>
          </Box>
          <Box sx={styles.grow}>
            {Object.entries(rateProgram.processingTypes).map(([key, value]) => {
              return (
                value.active && (
                  <Chip
                    key={`${rateProgram.id}-${key}`}
                    icon={ProcessingTypeIcons[String(key)]}
                    label={`${value.featuredText} ${value.featuredCaption}`}
                    variant="outlined"
                  />
                )
              );
            })}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {isGroupAdminOrOwner && !rateProgram.global && (
              <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                <Typography variant="subtitle1">
                  <Typography variant="subtitle2" component="span">
                    Program Status &nbsp;
                  </Typography>
                  {rateProgram.active ? 'Active' : 'Inactive'}

                  <Switch
                    checked={rateProgram.active}
                    onChange={handleToggleIsActive}
                    color="primary"
                    inputProps={{'aria-label': 'primary checkbox'}}
                  />
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                name="name"
                label="Program Name"
                errors={formMethods.formState.errors}
                control={formMethods.control}
                disabled={readonly}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="tooltip"
                label="Tooltip"
                errors={formMethods.formState.errors}
                control={formMethods.control}
                disabled={readonly}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography sx={styles.title} variant="caption" gutterBottom>
                Benefits List:
              </Typography>
              <TextField
                name="description"
                label="Description"
                errors={formMethods.formState.errors}
                control={formMethods.control}
                disabled={readonly}
              />
              <EditBenefits
                id={rateProgram.id}
                benefits={benefitsState}
                alwaysExpanded={true}
                handleUpdate={handleUpdateBenefits}
                disabled={readonly}
              ></EditBenefits>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {Object.values(ProcessingTypes).map(processingType => {
              const key = getEnumKeyByValue(ProcessingTypes, processingType) as string;
              const rateSet = rateProgram.processingTypes[key as keyof typeof ProcessingTypes];
              const isActiveProcessingType = rateSet.active;

              const watchPCIFee =
                formMethods.watch(`processingTypes.${key}.pciFee`) || rateSet?.pciFee;
              const displayPCIFeeValue = Object.values(PCIFee)
                .filter(x => x !== PCIFee.waived)
                .includes(watchPCIFee as PCIFee);

              const handlePCIFeeChange = (value: string) => {
                formMethods.setValue(
                  `processingTypes.${key}.pciFeeValue`,
                  PCIFeeDefaultValues[value as PCIFee] as never
                );
              };
              return (
                <Grid key={`processingTypes.${key}`} item xs={12} md={4}>
                  <Grid container spacing={1}>
                    {isGroupAdminOrOwner && !rateProgram.global && (
                      <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                        <Typography variant="subtitle1">
                          {isActiveProcessingType ? 'Active' : 'Inactive'}

                          <Switch
                            checked={isActiveProcessingType}
                            onChange={async (
                              _event: ChangeEvent<HTMLInputElement>,
                              isChecked: boolean
                            ) => await handleToggleIsActiveProcessingType(isChecked, key)}
                            color="primary"
                            inputProps={{'aria-label': 'primary checkbox'}}
                          />
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography sx={styles.processingTypeTitle} gutterBottom>
                        {ProcessingTypeIcons[String(key)]} {processingType}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.featuredText`}
                        label="Feature Text"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.featuredCaption`}
                        label="Feature Caption"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Select
                        name={`processingTypes.${key}.applicationType`}
                        label="Application Type"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        options={Object.values(ApplicationType)}
                      />{' '}
                    </Grid>
                    <Grid item xs={12}>
                      <Select
                        name={`processingTypes.${key}.discountDuration`}
                        label="Discount Duration"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        options={Object.values(DiscountDuration)}
                      />{' '}
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.accountOnFileFee`}
                        label="Account on File Fee"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.retrievalFee`}
                        label="Retrieval Fee"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.chargebackFee`}
                        label="Chargeback Fee"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.qualifiedDiscountRateVisaMastercardDiscover`}
                        label="Qualified Rate - Visa/MC/Discover"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.midQualifiedDiscountRateVisaMastercardDiscover`}
                        label="Mid Qualified Rate - Visa/MC/Discover"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.nonQualifiedDiscountRateVisaMastercardDiscover`}
                        label={nonQualifiedRateTitle}
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.qualifiedTransactionFeeVisaMastercardDiscover`}
                        label="Qualified Fee - Visa/MC/Discover"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.midQualifiedTransactionFeeVisaMastercardDiscover`}
                        label="Mid Qualified Fee - Visa/MC/Discover"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.nonQualifiedTransactionFeeVisaMastercardDiscover`}
                        label="Non Qualified Fee - Visa/MC/Discover"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.qualifiedDiscountRateAmex`}
                        label="Qualified Rate - Amex"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.midQualifiedDiscountRateAmex`}
                        label="Mid Qualified Rate - Amex"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.nonQualifiedDiscountRateAmex`}
                        label="Non Qualified Rate - Amex"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.qualifiedTransactionFeeAmex`}
                        label="Qualified Fee - Amex"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.midQualifiedTransactionFeeAmex`}
                        label="Mid Qualified Fee - Amex"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.nonQualifiedTransactionFeeAmex`}
                        label="Non Qualified Fee - Amex"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.qualifiedDebitPINRate`}
                        label="Qualified Rate - Debit PIN Based"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.qualifiedDebitPINFee`}
                        label="Qualified Fee - Debit PIN Based"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.qualifiedDebitNonPINRate`}
                        label="Qualified Rate - Debit Non-PIN Based"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.qualifiedDebitNonPINFee`}
                        label="Qualified Fee - Debit Non-PIN Based"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.otherVolumeRate`}
                        label="Other Volume Rate"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.otherItemFee`}
                        label="Other Item Fee"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.monthlyMinimumFee`}
                        label="Monthly Minimum Fee"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.avsFee`}
                        label="AVS Fee"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.batchFee`}
                        label="Batch Fee"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.debitAccessFee`}
                        label="Debit Access Fee"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.additionalServicesFee`}
                        label="Additional Services Fees"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Select
                        name={`processingTypes.${key}.pciFee`}
                        label="PCI Fee"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        displayValue={true}
                        onChangeSuccess={handlePCIFeeChange}
                        compareFn={(a, b) => -1}
                        options={PCIFeeTypes}
                      />{' '}
                    </Grid>
                    {displayPCIFeeValue && (
                      <Grid item xs={12}>
                        <TextField
                          name={`processingTypes.${key}.pciFeeValue`}
                          label="PCI Fee Value"
                          type="number"
                          errors={formMethods.formState.errors}
                          control={formMethods.control}
                          disabled={readonly}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.earlyDeconversionFee`}
                        label="Early Deconversion Fee"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.annualFee`}
                        label="Annual Fee"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.regulatoryFee`}
                        label="Regulatory Fee"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.ebtDiscountRate`}
                        label="EBT Discount rate"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.ebtTransactionFee`}
                        label="EBT Transaction Fee"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.gatewaySetupFee`}
                        label="Gateway Setup Fee"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.gatewayMonthlyFee`}
                        label="Gateway Monthly Fee"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={`processingTypes.${key}.gatewayTransactionFee`}
                        label="Gateway Transaction Fee"
                        errors={formMethods.formState.errors}
                        control={formMethods.control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
        {!readonly && (
          <>
            <Divider />
            <AccordionActions>
              <Button onClick={handleResetClick} disabled={!isDirty || saving}>
                Reset
              </Button>
              <Button
                color="primary"
                onClick={formMethods.handleSubmit(onSuccess, onError)}
                disabled={!isDirty || saving}
              >
                {saving && <CircularProgress sx={styles.buttonProgress} size={24} />}Save
              </Button>
            </AccordionActions>
          </>
        )}
      </Accordion>
    </FormProvider>
  );
};
