import {Dispositions} from './Dispositions';

export enum UserRoles {
  support = 'Support',
  operations = 'Operations',
  agentSupport = 'Agent Support',
  underwriter = 'Underwriter',
  admin = 'Administrator',
  auditor = 'Auditor',
  agent = 'Agent',
  merchant = 'Merchant',
  sales = 'Sales',
}

export const OzarkUserRoles = [
  UserRoles.support,
  UserRoles.operations,
  UserRoles.agentSupport,
  UserRoles.underwriter,
  UserRoles.admin,
  UserRoles.sales,
];

export const OzarkUserRolesWithoutUnderwriter = OzarkUserRoles.filter(
  x => x !== UserRoles.underwriter
);

export const OzarkUserRolesWithoutSales = OzarkUserRoles.filter(x => x !== UserRoles.sales);

// please check isErpUser method in file firestore.rules after you change this array
export const ERPUserRoles = [
  UserRoles.support,
  UserRoles.operations,
  UserRoles.agentSupport,
  UserRoles.underwriter,
  UserRoles.admin,
  UserRoles.auditor,
  UserRoles.sales,
];

export const PortalUserRoles = [UserRoles.agent, UserRoles.merchant];

export const hasOzarkRole = (role: UserRoles | null | undefined) => {
  if (!role) return false;
  return OzarkUserRoles.includes(role);
};

export const hasErpRole = (role: UserRoles | null | undefined) => {
  if (!role) return false;
  return ERPUserRoles.includes(role);
};

export const RolesByDisposition: {[_ in Dispositions]: UserRoles[]} = {
  [Dispositions.started]: [UserRoles.operations],
  [Dispositions.signatureNeeded]: [UserRoles.operations],
  [Dispositions.incomplete]: [UserRoles.operations],
  [Dispositions.asIncomplete]: [UserRoles.agentSupport],
  [Dispositions.asWithdrawn]: [UserRoles.agentSupport],
  [Dispositions.asDeclined]: [UserRoles.agentSupport],
  [Dispositions.asReceived]: [UserRoles.agentSupport],
  [Dispositions.asRequestedSupportingDocuments]: [UserRoles.agentSupport],
  [Dispositions.asSupportingDocumentsReceived]: [UserRoles.agentSupport],
  [Dispositions.uwReceived]: [UserRoles.underwriter],
  [Dispositions.uwPending]: [UserRoles.underwriter],
  [Dispositions.uwApproved]: [UserRoles.underwriter],
  [Dispositions.uwDeclined]: [UserRoles.underwriter],
  [Dispositions.uwWithdrawn]: [UserRoles.underwriter],
  [Dispositions.uwArchived]: [UserRoles.underwriter],
  [Dispositions.uwSubmittedToOtherBank]: [UserRoles.underwriter],
  [Dispositions.enqueued]: [UserRoles.underwriter],
  [Dispositions.failed]: [UserRoles.underwriter],
  [Dispositions.boarded]: [UserRoles.underwriter],
};
