import {Firebase} from '@ozark/common';

export const startDownload = (downloadUrl: string, fileName: string) => {
  const a = document.createElement('a');
  a.setAttribute('href', downloadUrl);
  a.setAttribute('download', fileName);
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const getDownloadLinkFromPath = async (cloudPath: string) => {
  const storageRef = Firebase.storage.ref(cloudPath);
  return await storageRef.getDownloadURL();
};
