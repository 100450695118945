import {Collections, NotificationView, selectNotificationView} from '@ozark/common';
import firebase from 'firebase/compat';
import {debounce} from 'lodash';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Firebase} from '../../../firebase';
import {
  useCallable,
  useInfiniteData,
  useInfiniteSnapshotsFromRef,
  useNotification,
} from '../../../hooks';

const DEBOUNCE_TIME = 3_000;
const IMPORTANT_NOTIFICATIONS_LIMIT = 10;

const getImportantNotificationsDocsFilter = (
  query: firebase.firestore.Query<firebase.firestore.DocumentData>
): firebase.firestore.Query<firebase.firestore.DocumentData> => {
  return query.where('status', '==', 'new').where('isImportantNotification', '==', true);
};

export const useImportantNotificationsState = (userUid: string | undefined) => {
  const [isMarkingAsRead, setIsMarkingAsRead] = useState(false);
  const {markAllNotificationsAsRead} = useCallable();
  const [importantNotifications, setImportantNotifications] = useState<NotificationView[]>([]);
  const showNotification = useNotification();
  const debouncedSetNotifications = useCallback(
    debounce(setImportantNotifications, DEBOUNCE_TIME),
    []
  );

  const notificationsCollectionRef = useMemo(
    () =>
      userUid
        ? Firebase.firestore
            .collection(Collections.userNotifications)
            .doc(userUid)
            .collection(Collections.notifications)
        : null,
    [userUid]
  );

  const {documents: importantNotificationDocuments, next} = useInfiniteSnapshotsFromRef(
    notificationsCollectionRef,
    selectNotificationView,
    {
      limit: IMPORTANT_NOTIFICATIONS_LIMIT,
      order: 'desc',
      orderBy: 'createdAt',
      filter: getImportantNotificationsDocsFilter,
    }
  );

  useEffect(() => {
    if (isMarkingAsRead) {
      // while isMarkingAsRead is processing, updating importantNotifications is blocked
      return;
    }

    // Accumulate all important notifications from the Firestore before updating the state
    debouncedSetNotifications(Object.values(importantNotificationDocuments.data ?? {}));
  }, [importantNotificationDocuments]);

  const onLoadMore = useCallback(() => {
    const lastNotificationInList = importantNotifications[importantNotifications.length - 1];

    if (lastNotificationInList) {
      next({startAfter: lastNotificationInList?.ref});
    }
  }, [importantNotifications, next]);

  const loadMoreRef = useInfiniteData(onLoadMore, importantNotificationDocuments.hasNextPage);

  const markAllImportantNotificationsAsRead = async () => {
    try {
      setIsMarkingAsRead(true);

      const result = await markAllNotificationsAsRead({
        isImportantNotificationsOnly: true,
      });
      if (result.status === 'error') {
        throw new Error('Error in markAllNotificationsAsRead callable function');
      } else {
        setImportantNotifications([]);
      }
    } catch (error) {
      showNotification('error', 'Failed to mark important notifications as read');
      console.error('Failed to mark important notifications as read with an error:', error);
    } finally {
      setIsMarkingAsRead(false);
    }
  };

  return {
    importantNotifications,
    loadMoreRef,
    isMarkingAsRead,
    markAllImportantNotificationsAsRead,
  };
};
