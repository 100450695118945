import {Backdrop, CircularProgress} from '@mui/material';

type Props = {
  open?: boolean;
};

export const Loading = ({open = true}: Props) => {
  return (
    <Backdrop open={open} sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
