import {useEffect, useState} from 'react';
import {useWatch} from 'react-hook-form';
import {AssociationType, TicketAssociation, TicketSearchItemModel, TicketStatus} from '../../..';
import {useAlgoliaSearchTickets} from '../../../hooks/useAlgoliaSearchTickets';
import {FIELD_NAME_ASSOCIATION, FIELD_NAME_SUBJECT} from '../constants/constants';
import {useTicketId} from '../hooks/useTicketId';

const activeStatusSet = [
  TicketStatus.Open,
  TicketStatus.InProgress,
  TicketStatus.Closed,
  TicketStatus.Unresolved,
];

export function usePossibleDupes(isDraft: boolean) {
  const {search} = useAlgoliaSearchTickets();
  const {ticketId} = useTicketId();
  const subject: string | undefined = useWatch({name: FIELD_NAME_SUBJECT});
  const association: TicketAssociation | undefined = useWatch({name: FIELD_NAME_ASSOCIATION});
  const [possibleDupes, setPossibleDupes] = useState<TicketSearchItemModel[]>([]);

  const shouldCheckForDupes = Boolean(
    isDraft &&
      association?.type &&
      [AssociationType.merchant, AssociationType.agent].includes(association.type)
  );

  useEffect(() => {
    let isMounted = true;

    if (!shouldCheckForDupes || !subject || subject.length < 3) {
      setPossibleDupes([]);
      return;
    }

    search({input: subject}, (results?: TicketSearchItemModel[]) => {
      if (isMounted) {
        let newPossibleDupes = [] as TicketSearchItemModel[];

        if (results?.length) {
          // we should include
          // - assignee.id? === assignee.id?
          // we should exclude
          // - our ticket from search
          // - non active tickets
          const accociationId =
            association?.type === AssociationType.merchant
              ? association?.merchant?.mid
              : association?.agent?.id;
          newPossibleDupes = results.filter(
            t =>
              t.id &&
              t.id !== ticketId &&
              t.status &&
              activeStatusSet.includes(t.status) &&
              t.associationId === accociationId
          );
        }

        setPossibleDupes(newPossibleDupes);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [association, subject, search, shouldCheckForDupes]);

  return {
    possibleDupes,
  };
}
