import {useEffect, useState} from 'react';
import {Collections, Firebase, selectTicketView, TicketStatus} from '../../../..';
import {useUserInfo} from '../../../../hooks';
import {stateEmpty, statePromised} from '../../constants/constants';
import {useTicketsViewableBy} from './useTicketsViewableBy';

export function useTicketsMyAssignee(statusSet: TicketStatus[]) {
  const {uid, isMerchant, isAgent} = useUserInfo();
  const {filterOutInternalTickets} = useTicketsViewableBy();
  const [ticketsMyAssignee, setTicketsMyAssignee] = useState(statePromised);

  useEffect(() => {
    if (statusSet.includes(TicketStatus.Draft)) {
      setTicketsMyAssignee(stateEmpty);
      return;
    }
    setTicketsMyAssignee(statePromised);

    let query = Firebase.firestore
      .collection(Collections.tickets)
      .where('status', 'in', statusSet)
      .where('author.id', '!=', uid); // creator tickets are returned by author query

    if (isMerchant) {
      query = query.where('assignee.merchant.id', '==', uid);
    } else if (isAgent) {
      query = query.where('associatedAgents', 'array-contains', uid);
    } else {
      query = query.where('assignee.erpUser.id', '==', uid);
    }

    return query.onSnapshot(
      async snapshot => {
        if (snapshot.size === 0) {
          setTicketsMyAssignee(stateEmpty);
          return;
        }
        const tickets = filterOutInternalTickets(snapshot.docs.map(selectTicketView));
        setTicketsMyAssignee({promised: false, data: tickets});
      },
      err => {
        console.error(err);
        setTicketsMyAssignee({promised: false, error: err});
      }
    );
  }, [statusSet, isAgent, isMerchant, filterOutInternalTickets]);

  return {
    ticketsMyAssignee,
  };
}
