import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import {DataFormat, formatData} from './utils';

export type DashTableRow = {
  label: string;
  value?: string | number | null;
  dataFormat: DataFormat;
  noDataMsg?: string;
  displayNoDataForZero?: boolean;
};

type Props = {
  data: DashTableRow[];
  size?: 'small' | 'medium';
};

export function DashboardTable({data, size}: Props) {
  return (
    <TableContainer>
      <Table size={size}>
        <TableBody>
          {data.map(row => (
            <TableRow key={row.label} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
              <TableCell component="th" scope="row">
                {row.label}
              </TableCell>
              <TableCell align="right">
                {formatData(row.value, row.dataFormat, row.noDataMsg, row.displayNoDataForZero)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
