import {CalendarEventParticipant} from '../../../../..';
import {useUserInfo} from '../../../../../hooks/useUserInfo';

export function useNewTicketEventParticipants() {
  const {uid, userRoleName, userDisplayName} = useUserInfo();

  // creator participant
  const defaultParticipants: CalendarEventParticipant[] = [
    {id: uid, name: userDisplayName, role: userRoleName, isOrganizer: true},
  ];

  // assignee participant
  // const assignee: TicketAssignee | undefined = useWatch({name: FIELD_NAME_ASSIGNEE});
  // if (assignee?.type && assignee.type !== AssigneeType.crmDepartment) {
  //   const assigneeUid = getAssigneeUid(assignee);
  //   if (assigneeUid !== uid) {
  //     defaultParticipants.push({
  //       id: assigneeUid,
  //       name: getAssigneeName(assignee),
  //       role: userRoleName,
  //     });
  //   }
  // }

  return {
    defaultParticipants,
  };
}
