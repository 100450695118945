import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Box, Chip, IconButton, Tooltip} from '@mui/material';
import {useEffect, useState} from 'react';
import {Collections, Firebase} from '../../../..';
import {FIELD_NAME_DISTINGUISHABLE_ID} from '../../constants/constants';
import {useTicketId} from '../../hooks/useTicketId';

export const IdInfo = () => {
  const {ticketId} = useTicketId();
  const [distinguishableId, setDistinguishableId] = useState<string>('');
  useEffect(() => {
    if (!ticketId) return;
    const unsub = Firebase.firestore
      .collection(Collections.tickets)
      .doc(ticketId)
      .onSnapshot(
        nextSnap => {
          const result = nextSnap.exists ? nextSnap.get(FIELD_NAME_DISTINGUISHABLE_ID) : null;
          setDistinguishableId(result ?? '');
          if (result) unsub?.();
        },
        err => console.error('IdInfo', err)
      );
    return unsub;
  }, [ticketId]);
  return (
    <Box display="flex" alignItems="center">
      <Box flex={1}>
        {distinguishableId ? (
          <Chip
            label={distinguishableId}
            variant="filled"
            onClick={() => navigator.clipboard.writeText(distinguishableId)}
            icon={<ContentCopyIcon fontSize="small" sx={{pl: 0.5}} />}
          />
        ) : (
          '...'
        )}
      </Box>
      <Box>
        <Tooltip title="Copy ticket ID">
          <span>
            <IconButton
              onClick={() => navigator.clipboard.writeText(distinguishableId)}
              disabled={!Boolean(distinguishableId)}
            >
              <ContentCopyIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};
