import {ApiBase} from '@ozark/common';
import type {
  FinicityAchDetailRequest,
  FinicityAchDetailResponse,
  FinicityGenerateUrlRequest,
  FinicityGenerateUrlResponse,
  FinicityInstitutionResponse,
} from '@ozark/functions/src/shared';

export class FinicityApi extends ApiBase {
  getGenerateUrl = (request: FinicityGenerateUrlRequest) => {
    return this.post<FinicityGenerateUrlResponse>('finicity/get-generate-url', request);
  };

  getInstitution = (institutionId: string) => {
    return this.get<FinicityInstitutionResponse>(`finicity/institution/${institutionId}`);
  };

  getAchDetail = (request: FinicityAchDetailRequest) => {
    return this.post<FinicityAchDetailResponse>('finicity/ach-detail', request);
  };
}
