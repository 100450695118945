import {Link} from '@mui/material';
import {styles} from './constants';
import {DisplayText} from './DisplayText';

interface Props {
  url: string | null | undefined;
  title: string;
}
export const DisplayLink = ({url, title}: Props) => {
  if (url) {
    return (
      <Link
        variant="caption"
        sx={styles.resourceLinkFont}
        href={url}
        target="_blank"
        rel="noreferrer noopener"
      >
        {title}
      </Link>
    );
  }

  return <DisplayText value={title} />;
};
