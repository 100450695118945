import {
  Agent,
  AgentView,
  Collections,
  DocumentBase,
  ScheduleABase,
  UniversalSnapshot,
} from '@ozark/common';
import {useEffect, useState} from 'react';
import {Firebase} from '../firebase';

export type GroupAgentsScheduleA = {
  agent: Agent;
  scheduleA: ScheduleABase;
};

/* Get Agents' Schedule A forms, take the newest, combine with the agent and aggregate them all in an array */
const getScheduleAByAgents = async (
  agentsDocs: UniversalSnapshot<DocumentBase>[]
): Promise<GroupAgentsScheduleA[]> => {
  const result: GroupAgentsScheduleA[] = [];

  await Promise.all(
    agentsDocs.map((doc, i) =>
      doc.ref
        .collection(Collections.scheduleA)
        .orderBy('version', 'desc')
        .get()
        .then((scheduleADocs: {empty: boolean; docs: UniversalSnapshot<DocumentBase>[]}) => {
          if (scheduleADocs.empty) {
            return;
          }

          result.push({
            agent: agentsDocs[i].data() as Agent,
            scheduleA: scheduleADocs.docs[0].data() as ScheduleABase,
          });
        })
    )
  );

  return result;
};

/* Get Agents with their latest Schedule A from the group */
const getGroupAgentsScheduleA = async (
  agentId: string,
  groupId: string
): Promise<GroupAgentsScheduleA[]> => {
  /* Get Agents participated in the Group */
  const {empty, docs: agentsDocs} = await Firebase.firestore
    .collection(Collections.agents)
    .where('isActive', '==', true)
    .where('group.id', '==', groupId)
    .get();

  if (empty) {
    return [];
  }

  /* Filter out the current agent */
  const filteredAgentsDocs = agentsDocs.filter(doc => doc.id !== agentId);

  return getScheduleAByAgents(filteredAgentsDocs);
};

/* Get Sub Agents with their latest Schedule A by their Master Agent's id */
const getSubAgentsScheduleA = async (
  agentId: string,
  masterAgentId?: string
): Promise<GroupAgentsScheduleA[]> => {
  if (!masterAgentId) {
    return [];
  }

  /* Get a Master Agent */
  const masterAgentDoc = await Firebase.firestore
    .collection(Collections.agents)
    .doc(masterAgentId)
    .get();

  if (!masterAgentDoc.exists) {
    return [];
  }

  const {subAgentUids} = masterAgentDoc.data() as Agent;

  /* Get Sub Agents by Master Agent's id */
  const subAgentsDocs = await Promise.all(
    (subAgentUids ?? []).map(uid =>
      Firebase.firestore.collection(Collections.agents).doc(uid).get()
    )
  );

  /* Filter out the current agent */
  const filteredSubAgentsDocs = subAgentsDocs.filter(doc => doc.id !== agentId);

  return getScheduleAByAgents(filteredSubAgentsDocs as UniversalSnapshot<DocumentBase>[]);
};

const getAllAgentsScheduleA = async (agentId: string): Promise<GroupAgentsScheduleA[]> => {
  /* Get all agents */
  const {empty, docs: agentsDocs} = await Firebase.firestore
    .collection(Collections.agents)
    .where('isActive', '==', true)
    .get();

  if (empty) {
    return [];
  }

  /* Filter out the current agent */
  const filteredAgentsDocs = agentsDocs.filter(doc => doc.id !== agentId);

  return getScheduleAByAgents(filteredAgentsDocs);
};

const useAgentsScheduleA = (
  agent: AgentView,
  canCopyGroupAgentsScheduleA: boolean,
  canCopySubAgentsScheduleA: boolean,
  canCopyAllAgentsScheduleA: boolean
) => {
  const [state, setState] = useState<{promised: boolean; data?: GroupAgentsScheduleA[]}>({
    promised: true,
    data: undefined,
  });

  useEffect(() => {
    if (canCopyGroupAgentsScheduleA) {
      getGroupAgentsScheduleA(agent.id, agent.group.id).then(data => {
        setState({promised: false, data});
      });
    } else if (canCopySubAgentsScheduleA) {
      getSubAgentsScheduleA(agent.id, agent.masterUid).then(data => {
        setState({promised: false, data});
      });
    } else if (canCopyAllAgentsScheduleA) {
      getAllAgentsScheduleA(agent.id).then(data => {
        setState({promised: false, data});
      });
    }
  }, []);

  return {...state};
};

export {useAgentsScheduleA};
