import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {CardVolumeSummary} from '../../..';
import {CardImage, CardImagesDictionaryType} from '../../CardImage';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

type Props = {summaryByCardType: CardVolumeSummary[]};

const getNumFormatted = (num?: string | number) => formatter.format(Number(num ?? 0));
const getNum = (num?: string | number) => Number(num ?? 0);

export const VolumeSummaryByCardTypeTable = ({summaryByCardType}: Props) => {
  const totalSalesCount = summaryByCardType.reduce((a, v) => a + getNum(v.salesCount), 0);
  const totalSalesAmount = summaryByCardType.reduce((a, v) => a + getNum(v.salesAmount), 0);

  return (
    <TableContainer>
      <Table sx={{minWidth: 650}} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell align="right">Sales Count</TableCell>
            <TableCell align="right">Sales Amount</TableCell>
            <TableCell align="right">Average Ticket</TableCell>
            <TableCell align="right">Credit Count</TableCell>
            <TableCell align="right">Credit Amount</TableCell>
            <TableCell align="right">Chargeback Count</TableCell>
            <TableCell align="right">Chargeback Volume</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {summaryByCardType
            .sort((a, b) => Number(b.salesCount) - Number(a.salesCount))
            .map(row => (
              <TableRow key={row.cardType}>
                <TableCell component="th" scope="row">
                  <Box width={52} mr={2}>
                    <CardImage
                      cardType={row.cardType}
                      dictionaryType={CardImagesDictionaryType.Transactions}
                    />
                  </Box>
                </TableCell>
                <TableCell align="right">{getNum(row.salesCount)}</TableCell>
                <TableCell align="right">{getNumFormatted(row.salesAmount)}</TableCell>
                <TableCell align="right">{getNumFormatted(row.averageTicket)}</TableCell>
                <TableCell align="right">{getNum(row.creditCount)}</TableCell>
                <TableCell align="right">{getNumFormatted(row.creditAmount)}</TableCell>
                <TableCell align="right">{getNum(row.cbCount)}</TableCell>
                <TableCell align="right">{getNumFormatted(row.cbVolume)}</TableCell>
              </TableRow>
            ))}
          <TableRow key="total">
            <TableCell component="th" scope="row">
              <Typography variant="h6">Total</Typography>
            </TableCell>
            <TableCell align="right">{totalSalesCount}</TableCell>
            <TableCell align="right">{formatter.format(totalSalesAmount)}</TableCell>
            <TableCell align="right">
              {formatter.format(totalSalesCount > 0 ? totalSalesAmount / totalSalesCount : 0)}
            </TableCell>
            <TableCell align="right">
              {summaryByCardType.reduce((a, v) => a + getNum(v.creditCount), 0)}
            </TableCell>
            <TableCell align="right">
              {formatter.format(summaryByCardType.reduce((a, v) => a + getNum(v.creditAmount), 0))}
            </TableCell>
            <TableCell align="right">
              {summaryByCardType.reduce((a, v) => a + getNum(v.cbCount), 0)}
            </TableCell>
            <TableCell align="right">
              {formatter.format(summaryByCardType.reduce((a, v) => a + getNum(v.cbVolume), 0))}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
