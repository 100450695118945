import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {ResourceView} from '@ozark/common';
import {useMemo} from 'react';
import {useDisplayContext} from './DisplayContext';
import {DisplayResourceAsRow} from './DisplayResourceAsRow';

interface Props {
  resourceView: ResourceView;
}

export const DisplaySortableResourceAsRow = ({resourceView}: Props) => {
  const {activeDraggedResourceView} = useDisplayContext();
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({
    id: resourceView.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const displayDocumentsAsRow = useMemo(
    () => (
      <DisplayResourceAsRow
        resourceView={resourceView}
        attributes={attributes}
        listeners={listeners}
        ref={setNodeRef}
        style={{...style, opacity: resourceView.id === activeDraggedResourceView?.id ? 0.5 : 1}}
      />
    ),
    [resourceView, attributes, listeners, setNodeRef, style]
  );

  return <>{displayDocumentsAsRow}</>;
};
