import {SearchCriteria} from '@ozark/common';
import {Table} from '@ozark/common/components';
import {
  GroupAgentResponse,
  PaginatedGroupAgentResponse,
} from '@ozark/functions/src/functions/express/private/types/Residual';
import {useHistory} from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import {currentFormatter} from '../../ReportingPage';
import {AgentAggregateGroupColumns} from './columns';

interface Props {
  paginatedGroupAgentResponse: PaginatedGroupAgentResponse;
  handleRetrieveData: (searchCriteria: SearchCriteria) => void;
  selectedYearMonth: string | undefined;
}

export const AgentAggregateResidualsTable = ({
  paginatedGroupAgentResponse,
  handleRetrieveData,
  selectedYearMonth,
}: Props) => {
  const history = useHistory<{agentId: string; yearMonth: string}>();
  const handleRowClick = (groupAgentResponse: GroupAgentResponse) => {
    if (selectedYearMonth) {
      history.push(ROUTES.RESIDUALS_AGENT, {
        agentId: groupAgentResponse.firestoreAgentId,
        yearMonth: selectedYearMonth,
      });
    }
  };
  return (
    <Table
      noWrap={true}
      columns={AgentAggregateGroupColumns}
      data={paginatedGroupAgentResponse}
      onRowClick={(row: GroupAgentResponse) => handleRowClick(row)}
      summary={{
        bankcardSalesVolume: currentFormatter.format(
          parseFloat(paginatedGroupAgentResponse?.bankcardSalesVolume || '0')
        ),
        transactionCount: parseFloat(paginatedGroupAgentResponse?.transactionCount || '0'),
        totalIncome: currentFormatter.format(
          parseFloat(paginatedGroupAgentResponse?.totalIncome || '0')
        ),
        groupExpense: currentFormatter.format(
          parseFloat(paginatedGroupAgentResponse?.groupExpense || '0')
        ),
        groupNetSplit: currentFormatter.format(
          parseFloat(paginatedGroupAgentResponse?.groupNetSplit || '0')
        ),
        groupNet: currentFormatter.format(parseFloat(paginatedGroupAgentResponse?.groupNet || '0')),
        agentExpense: currentFormatter.format(
          parseFloat(paginatedGroupAgentResponse?.agentExpense || '0')
        ),
        agentNet: currentFormatter.format(parseFloat(paginatedGroupAgentResponse?.agentNet || '0')),
        agentNetSplit: currentFormatter.format(
          parseFloat(paginatedGroupAgentResponse?.agentNetSplit || '0')
        ),
        groupProfit: currentFormatter.format(
          parseFloat(paginatedGroupAgentResponse?.groupProfit ?? '0')
        ),
        agentProfit: currentFormatter.format(
          parseFloat(paginatedGroupAgentResponse?.agentProfit || '0')
        ),
        subAgentProfit: currentFormatter.format(
          parseFloat(paginatedGroupAgentResponse?.subAgentProfit || '0')
        ),
        shareNetSplit: currentFormatter.format(
          parseFloat(paginatedGroupAgentResponse?.shareNetSplit || '0')
        ),
        groupProfitAdjusted: currentFormatter.format(
          parseFloat((paginatedGroupAgentResponse?.groupProfitAdjusted ?? 0).toString())
        ),
      }}
      onRetrieveData={handleRetrieveData}
      paginate
      stickyHeader
      scrollableBody
    />
  );
};
