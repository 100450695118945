import {
  emptyStringToNull as emptyStringToNullCommon,
  toBoolean as toBooleanCommon,
  trimWhiteSpace as trimWhiteSpaceCommon,
} from '@ozark/common';

export const emptyStringToNull = (value: any, originalValue: any) => {
  return emptyStringToNullCommon(value, originalValue);
};

export const trimWhiteSpace = (value: unknown) => {
  return trimWhiteSpaceCommon(value);
};

/**
 * Transform function to display the correct error message for YesNo dropdown
 * instead of `must be a `boolean` type, but the final value was: `""``
 */
export const toBoolean = (value: unknown) => {
  return toBooleanCommon(value);
};
