import {Box, Grow, Stack} from '@mui/material';
import {LoadingStatus} from '../../Analytics/common/LoadingStatus';
import {BoxCentered, BoxParentHeight} from '../../common';
import {useTicketTypeCategories} from '../hooks/useTicketTypeCategories';
import {useTicketTypeId} from '../hooks/useTicketTypeId';
import {TicketCategoryCard} from './TicketCategoryCard';

type Props = {
  id: string;
  onClose: () => void;
};

export const TicketCategoriesList = () => {
  const {ticketTypeId} = useTicketTypeId();
  const {ticketTypeCategories} = useTicketTypeCategories(ticketTypeId);
  const loading = Boolean(ticketTypeCategories?.promised);
  const hasData = Boolean(ticketTypeCategories?.data?.length);

  return (
    <>
      {!hasData && (
        <BoxCentered flex={1}>
          <LoadingStatus loading={loading} hasData={hasData} />
        </BoxCentered>
      )}
      {hasData && (
        <BoxParentHeight ml={2}>
          <Stack pt={0.5}>
            {ticketTypeCategories?.data &&
              ticketTypeCategories.data.map(ticketTypeCategory => (
                <Grow in key={ticketTypeCategory.id}>
                  <Box mr={2} mb={2}>
                    <TicketCategoryCard
                      ticketTypeId={ticketTypeId}
                      ticketCategory={ticketTypeCategory}
                    />
                  </Box>
                </Grow>
              ))}
          </Stack>
        </BoxParentHeight>
      )}
    </>
  );
};
