import {Box, Divider, Grid, Grow, Paper, Typography} from '@mui/material';
import {ReactNode} from 'react';

type Props = {
  children: ReactNode;
  title: ReactNode;
  height?: number;
  width?: number;
};

export const DashboardGrid = ({children}: Pick<Props, 'children'>) => {
  return (
    <Grid container spacing={3} mt={2}>
      {children}
    </Grid>
  );
};

export const DashboardCell = ({children, title, width = 6, height = 420}: Props) => {
  return (
    <Grow in>
      <Grid item sm={12} md={12} lg={12} xl={width}>
        <Paper sx={{borderTop: '4px solid', borderTopColor: 'primary.main'}}>
          <Box p={3}>
            <Typography variant="body1" sx={{fontSize: 18}}>
              {title}
            </Typography>
          </Box>
          <Divider />
          <Box height={height} p={3} pr={5}>
            {children}
          </Box>
        </Paper>
      </Grid>
    </Grow>
  );
};
