import {yupResolver} from '@hookform/resolvers/yup';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  AsyncState,
  Collections,
  copyWithoutRef,
  Equipment,
  EquipmentConnectionType,
  EquipmentDefaults,
  EquipmentFileType,
  EquipmentOptions,
  EquipmentTipMode,
  EquipmentView,
  Firebase,
  selectEquipmentView,
  ShippingSpeed,
  StoragePath,
  UniversalSnapshot,
} from '@ozark/common';
import {useNotification} from '@ozark/common/hooks';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {EquipmentForm, EquipmentFormSchema} from '.';
import {ConfirmationDialog, Loading} from '..';
import {EquipmentFormData, uploadLogo} from './hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      [theme.breakpoints.up('md')]: {
        width: 680,
      },
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

type Props = {
  id: string;
  onClose: () => void;
};

export const EditDialog = ({id, onClose}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const showNotification = useNotification();
  const [equipment, setEquipment] = useState<AsyncState<EquipmentView>>({promised: true});
  const [isConfirmationOpened, setConfirmationOpened] = useState<boolean>(false);

  const hookForm = useForm<EquipmentFormData>({
    resolver: yupResolver(EquipmentFormSchema),
  });

  const {reset, handleSubmit} = hookForm;

  useEffect(() => {
    const getEquipment = async () => {
      const snapshot = await Firebase.firestore.collection(Collections.equipment).doc(id).get();
      if (snapshot.exists) {
        const docRef = await snapshot.ref.get();
        const equipmentForEdit: EquipmentView = selectEquipmentView(
          docRef as UniversalSnapshot<Equipment>
        );
        equipmentForEdit.isGateway = equipmentForEdit.isGateway || undefined;
        setEquipment({
          data: equipmentForEdit,
          promised: false,
        });
      }
    };
    getEquipment();
  }, [id]);

  useEffect(() => {
    if (equipment.promised || !equipment.data) return;

    const data: EquipmentFormData = {
      ...equipment.data,
      defaultsAccessories: equipment.data?.defaults?.accessories,
      defaultsAutoBatch: equipment.data?.defaults?.autoBatch,
      defaultsAutoBatchTime: equipment.data?.defaults?.autoBatchTime,
      defaultsCashDiscount: equipment.data?.defaults?.cashDiscount,
      defaultsCashDiscountPercent: equipment.data?.defaults?.cashDiscountPercent,
      defaultsConnectionType: equipment.data?.defaults?.connectionType,
      defaultsContactless: equipment.data?.defaults?.contactless,
      defaultsFileType: equipment.data?.defaults?.fileType,
      defaultsShippingSpeed: equipment.data?.defaults?.shippingSpeed,
      defaultsTipMode: equipment.data?.defaults?.tipMode,
    };

    //@ts-ignore
    reset(copyWithoutRef(data));
  }, [equipment.promised, equipment.data, reset]);

  const onSuccess = async (data: EquipmentFormData) => {
    setLoading(true);

    // TODO: on edit remove prev file
    if (data.image) {
      data.imageUrl = await uploadLogo(StoragePath.equipment, data.image.file);
      delete data.image;
    }

    try {
      const {
        defaultsAutoBatch,
        defaultsAutoBatchTime,
        defaultsCashDiscount,
        defaultsCashDiscountPercent,
        defaultsConnectionType,
        defaultsContactless,
        defaultsFileType,
        defaultsShippingSpeed,
        defaultsTipMode,
        image,
        ...equipment
      } = data;

      const updatedEquipment: Partial<Equipment> = {
        ...equipment,
        isGateway: data.isGateway || false,
        programs: data.programs,
        defaults: {
          accessories: data.defaultsAccessories,
          autoBatch: data.defaultsAutoBatch,
          autoBatchTime: data.defaultsAutoBatch ? data.defaultsAutoBatchTime : undefined,
          cashDiscount: data.defaultsCashDiscount,
          cashDiscountPercent: data.defaultsCashDiscountPercent,
          connectionType: data.defaultsConnectionType as EquipmentConnectionType,
          contactless: data.defaultsContactless,
          fileType: data.defaultsFileType as EquipmentFileType,
          shippingSpeed: data.defaultsShippingSpeed as ShippingSpeed,
          tipMode: data.defaultsTipMode as EquipmentTipMode,
        } as EquipmentDefaults,
        options: {
          accessories: data.options?.accessories || [],
          cashDiscount: data.options?.cashDiscount,
          connectionType: data.options?.connectionType,
        } as EquipmentOptions,
      };

      const snapshot = await Firebase.firestore.collection(Collections.equipment).doc(id).get();
      if (snapshot.exists) {
        await snapshot.ref.set(updatedEquipment, {merge: true});
        showNotification('success', `Equipment successfully updated`);
      }

      setLoading(false);
      onClose();
    } catch (_err) {
      showNotification('error', 'Failed to update equipment.');
      console.error(`error saving equipment`, _err);
      setLoading(false);
    }
  };

  const onError = (data: any) => {
    console.error(`error saving equipment ${JSON.stringify(data)}`);
    setLoading(false);
  };

  const handleDeleteEquipment = async () => {
    try {
      const snapshot = await Firebase.firestore.collection(Collections.equipment).doc(id).get();

      if (snapshot.exists) {
        await snapshot.ref.delete();
        onClose();
      }
    } catch (err: any) {
      console.error(`failed to delete document. ${err.toString()}`);
    }
  };

  if (equipment.promised) return <Loading />;

  return (
    <>
      <Dialog
        open={true}
        onClose={onClose}
        aria-labelledby="create-dialog-title"
        fullScreen={fullScreen}
        maxWidth={'lg'}
      >
        <DialogTitle id="create-dialog-title">Edit Equipment</DialogTitle>
        <DialogContent>
          <div className={classes.content}>
            <Grid container spacing={1}>
              <EquipmentForm hookForm={hookForm} />
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmationOpened(true)}>Delete</Button>
          <Button onClick={onClose} style={{marginLeft: 'auto'}}>
            Cancel
          </Button>
          <Button onClick={handleSubmit(onSuccess, onError)} color="primary" disabled={loading}>
            {loading && <CircularProgress className={classes.buttonProgress} size={24} />}
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        title="Confirmation"
        message="Are you sure you want to permanently delete this equipment?"
        onClose={() => setConfirmationOpened(false)}
        onConfirm={isConfirmationOpened ? handleDeleteEquipment : null}
      />
    </>
  );
};
