import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import GroupIcon from '@mui/icons-material/Group';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {useMidsContainer} from '@ozark/common';
import {
  Authorizations,
  Batches,
  ChargebacksSummaryByMid,
  DepositsCpyDays,
  Loading,
  MidAndDba,
  StatementsPage,
  Tab,
  Tabs,
  Title,
  Transactions,
} from '@ozark/common/components';
import {MerchantPortfolioShortInfo} from '@ozark/common/types/MerchantInfo';
import {format} from 'date-fns';
import React, {ReactNode, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';
import {Applications} from './Elements';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '100%',
    },
    paper: {
      height: '100%',
      padding: theme.spacing(1, 2, 1),
      color: '#4d6575',
    },
    title: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      height: 48,
      alignItems: 'center',
      margin: theme.spacing(0, 0, 0.5),
      '& > *': {
        margin: theme.spacing(0, 1),
      },
    },
    titleText: {
      flex: 1,
    },
    list: {
      padding: theme.spacing(0.5, 0, 0),
    },
    text: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
);

const renderWithMid = (children: ReactNode, mid?: MidAndDba) => {
  if (!mid) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={400}>
        <Typography>Merchant has no MID's assigned or access is restricted.</Typography>
      </Box>
    );
  }

  return children;
};

const MerchantsPortfolioDetails = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const {getAuthorizedMerchantPortfolio} = useStore();
  const {mids} = useMidsContainer();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('md'));
  const {id, tabValue} = useParams<{id: string; tabValue?: string}>();
  const [tab, setTab] = useState<string | null>(null);
  const [merchant, setMerchant] = useState<MerchantPortfolioShortInfo | null>(null);

  useEffect(() => {
    getAuthorizedMerchantPortfolio(id).then(m => {
      const currentUserAuthorizedMids = Array.from((mids.data ?? new Map()).keys());
      const merchantPortfolioUpdated = {
        ...m,
        // TODO: WTF?
        mid: currentUserAuthorizedMids.includes(m.mid.mid) ? m.mid : m.mid,
      };
      setMerchant(merchantPortfolioUpdated);
    });
  }, [getAuthorizedMerchantPortfolio, id, mids]);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: string) => {
    setTab(newTab);
    history.push(`${ROUTES.MERCHANTS_PORTFOLIO}/${id}${newTab ? '/' + newTab : ''}`);
  };

  useEffect(() => {
    if (!tabValue) {
      return;
    }

    setTab(tabValue);
  }, [tabValue]);

  const displayCreatedDate = () =>
    merchant!.createdAt ? format(merchant!.createdAt.toDate(), 'MM/dd/yyyy h:mm a') : 'N/A';

  const displayAccountStatus = () => merchant!.accountStatus?.status;

  const route = (path?: string) =>
    `${ROUTES.MERCHANTS_PORTFOLIO}/${merchant?.id}${path ? '/' + path : ''}`;

  if (!merchant) return <Loading />;

  const merchantName = `${merchant.firstName ?? ''} ${merchant.lastName ?? ''}`.trim() || 'n/a';

  return (
    <div className={classes.root}>
      <Title
        breadcrumbs={
          smDown
            ? [
                <IconButton onClick={() => history.goBack()} size="large">
                  <ChevronLeftIcon />
                </IconButton>,
              ]
            : [
                <Link
                  component="button"
                  variant="body1"
                  onClick={() => history.push(ROUTES.MERCHANTS_PORTFOLIO)}
                >
                  Merchants
                </Link>,
                <Typography variant="body1">{merchantName}</Typography>,
              ]
        }
      >
        <Tabs value={tab} onChange={handleTabChange} centered>
          <Tab value={null} label="Profile" selected={!tab || tab === 'profile'} />
          <Tab value="applications" label="Applications" />
          <Tab value="authorizations" label="Authorizations" />
          <Tab value="transactions" label="Transactions" />
          <Tab value="batches" label="Batches" />
          <Tab value="deposits" label="Deposits" />
          <Tab value="statements" label="Statements" />
          <Tab value="chargebacks" label="Disputes" />
        </Tabs>
      </Title>
      {(!tab || tab === 'profile') && (
        <Grid container spacing={2} direction="row" alignItems="stretch">
          <Grid item xs={12} md={9}>
            <Paper className={classes.paper}>
              <Typography className={classes.title} variant="body1" component="div" noWrap>
                <GroupIcon />
                <b className={classes.titleText}>Contact Information</b>
              </Typography>
              <Divider />
              <List className={classes.list}>
                <ListItem>
                  <ListItemText
                    className={classes.text}
                    primary={merchant.firstName}
                    secondary="First Name"
                  />
                  <ListItemText
                    className={classes.text}
                    primary={merchant.lastName}
                    secondary="Last Name"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    className={classes.text}
                    primary={merchant.customerServiceEmail ?? merchant.email}
                    secondary="Email"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    className={classes.text}
                    primary={merchant.phoneNumber}
                    secondary="Phone Number"
                  />
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={3}>
            <Paper className={classes.paper}>
              <Typography className={classes.title} variant="body1" component="div" noWrap>
                <GroupIcon />
                <b className={classes.titleText}>Activity Status</b>
              </Typography>
              <Divider />
              <List className={classes.list}>
                <ListItem>
                  <ListItemText
                    className={classes.text}
                    primary={displayCreatedDate()}
                    secondary="Date Created"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    className="classes.text"
                    primary={displayAccountStatus()}
                    secondary="Account Status"
                  />
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
      )}

      {tab === 'applications' &&
        renderWithMid(<Applications midsLoaded={!!merchant} mids={[merchant.mid]} />, merchant.mid)}

      {tab === 'transactions' &&
        renderWithMid(<Transactions midOptionPreselected={merchant.mid} />, merchant.mid)}

      {tab === 'batches' &&
        renderWithMid(
          <Batches
            midOptionPreselected={merchant.mid}
            transactionRouteUrl={route('transactions')}
          />,
          merchant.mid
        )}

      {tab === 'authorizations' &&
        renderWithMid(<Authorizations midOptionPreselected={merchant.mid} />, merchant.mid)}

      {tab === 'statements' && <StatementsPage />}

      {tab === 'deposits' && <DepositsCpyDays />}

      {tab === 'chargebacks' &&
        renderWithMid(
          <ChargebacksSummaryByMid
            midOptionPreselected={merchant.mid}
            chargebackDetailsUrl={ROUTES.CHARGEBACKS_DETAILS}
          />,
          merchant.mid
        )}
    </div>
  );
};

export default MerchantsPortfolioDetails;
