import SortIcon from '@mui/icons-material/Sort';
import {Box, IconButton, InputAdornment, MenuItem, TextField, Tooltip} from '@mui/material';
import {TextFieldProps} from '@mui/material/TextField/TextField';
import {Agent, GroupView, ViewBase} from '@ozark/common';
import {orderBy as orderByLodash} from 'lodash';
import {StyledDivider} from '../common';

export type GroupAgentAndSortFiltersProps = {
  orderBy: string;
  order: 'desc' | 'asc';
  onSortChange: (orderBy: string, order: 'desc' | 'asc') => void;
  sortOptions: {title: string; key: string; defaultOrder: 'desc' | 'asc'}[];

  selectedGroup?: string | null;
  groups?: GroupView[] | undefined;
  onGroupChange?: (id: string) => void;

  selectedAgent?: string | null;
  agents?: ViewBase<Agent>[];
  onAgentChange?: (id: string) => void;
  showAgentsFilter?: boolean;
};

const DEFAULT_VALUE = '0';

export const GroupAgentAndSortFilters = ({
  orderBy,
  order,
  onSortChange,
  sortOptions,
  selectedGroup,
  groups,
  onGroupChange,
  selectedAgent,
  agents,
  onAgentChange,
  showAgentsFilter = true,
}: GroupAgentAndSortFiltersProps) => {
  const handleSortChange: TextFieldProps['onChange'] = event => {
    const orderBy = event.target.value;
    const option = sortOptions.find(item => item.key === orderBy);
    if (!option) {
      return;
    }

    onSortChange(option.key, option.defaultOrder);
  };

  const toggleOrder = () => {
    onSortChange(orderBy, order === 'desc' ? 'asc' : 'desc');
  };

  return (
    <>
      {!!groups && (
        <Box display="flex">
          <StyledDivider orientation="vertical" flexItem />
          <TextField
            variant="standard"
            select
            label="Filter by Group"
            InputProps={{
              disableUnderline: true,
              sx: {'& .MuiInput-input': {backgroundColor: 'transparent !important'}},
            }}
            value={selectedGroup ?? DEFAULT_VALUE}
            onChange={event => onGroupChange?.(event.target.value as string)}
            sx={{minWidth: 200, width: '100%'}}
          >
            <MenuItem key="0" value="0">
              All
            </MenuItem>
            {groups?.map(group => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      )}

      {showAgentsFilter && (
        <Box display="flex">
          <StyledDivider orientation="vertical" flexItem />
          <TextField
            variant="standard"
            select
            label="Filter by Agent"
            InputProps={{
              disableUnderline: true,
              sx: {'& .MuiInput-input': {backgroundColor: 'transparent !important'}},
            }}
            value={selectedAgent ?? DEFAULT_VALUE}
            onChange={event => onAgentChange?.(event.target.value as string)}
            sx={{minWidth: 200, width: '100%'}}
          >
            <MenuItem key="0" value="0">
              All
            </MenuItem>
            {orderByLodash(agents ?? [], ['firstName', 'lastName']).map(agent => (
              <MenuItem key={agent.id} value={agent.id}>
                {agent.firstName} {agent.lastName}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      )}

      <Box display="flex">
        <StyledDivider orientation="vertical" flexItem />
        <TextField
          value={orderBy}
          onChange={handleSortChange}
          variant="standard"
          label="Order and Sort by"
          sx={{minWidth: '200px'}}
          InputProps={{
            sx: {'& .MuiInput-input': {backgroundColor: 'transparent !important'}},
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <Tooltip title={order === 'desc' ? 'Descending' : 'Ascending'}>
                  <IconButton onClick={toggleOrder} sx={{ml: -1}}>
                    {order === 'desc' ? (
                      <SortIcon style={{transform: 'rotateX(180deg)'}} />
                    ) : (
                      <SortIcon />
                    )}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          select
        >
          {sortOptions.map(option => (
            <MenuItem key={option.key} value={option.key}>
              {option.title}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </>
  );
};
