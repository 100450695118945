import {useEffect, useState} from 'react';
import {Collections, Firebase, selectTicketView, TicketStatus} from '../../../..';
import {useUserInfo} from '../../../../hooks';
import {stateEmpty, statePromised} from '../../constants/constants';
import {useTicketsViewableBy} from './useTicketsViewableBy';

export function useTicketsMyAssociationX(
  statusSet: TicketStatus[],
  merchantMids: string[],
  associationPath: string,
  promised: boolean = false // used to override promised when first call is awating mids
) {
  const {uid} = useUserInfo();
  const {filterOutInternalTickets} = useTicketsViewableBy();
  const [ticketsAssociated, setTicketsAssociated] = useState(statePromised);

  useEffect(() => {
    if (!merchantMids.length || statusSet.includes(TicketStatus.Draft)) {
      setTicketsAssociated({...stateEmpty, promised});
      return;
    }
    setTicketsAssociated(statePromised);

    let query = Firebase.firestore
      .collection(Collections.tickets)
      .where('author.id', '!=', uid) // creator tickets are returned by repoter query
      .where(associationPath, 'in', merchantMids.slice(0, 10)); //TODO: think about how to convert to onSnapshot batch

    if (statusSet.length === 1) {
      query = query.where('status', '==', statusSet[0]);
    }

    return query.onSnapshot(
      async snapshot => {
        if (snapshot.size === 0) {
          setTicketsAssociated({...stateEmpty, promised});
          return;
        }
        let tickets = filterOutInternalTickets(snapshot.docs.map(selectTicketView));
        if (statusSet.length > 1) {
          tickets = tickets.filter(ticket => ticket.status && statusSet.includes(ticket.status));
        }
        setTicketsAssociated({promised, data: tickets});
      },
      err => {
        console.error(err);
        setTicketsAssociated({promised, error: err});
      }
    );
  }, [statusSet, merchantMids, filterOutInternalTickets]);

  return {
    ticketsAssociated,
  };
}
