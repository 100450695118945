import {PaginatedResponse} from '@ozark/functions/src/functions/express/private/types';
import {
  VDMPChargebackReport,
  VDMPChargebackReportFields,
} from '@ozark/functions/src/functions/express/private/types/Reports';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {AllMIDs, useApiContainer, useMidsContainer, useNotification} from '../../..';
import {SearchCriteria} from '../../../api/SearchCriteria';
import {useQueryMonthYear} from '../../Analytics/common/useQueryMonthYear';
import {ExportProps} from '../../common';
import {Filter} from '../../reports';
import {VDMPReport} from './VDMPReport';
import {getHighlightedReportRowData} from './VDMPReportWarningUtils';

export const DEFAULT_SEARCH_CRITERIA: SearchCriteria = {
  limit: 20, // page size
  offset: 1, // page
  order: 'asc',
  orderBy: VDMPChargebackReportFields.doingBusinessAs,
};

export const FraudAnalysisVDMP = () => {
  const api = useApiContainer();
  const {selectedMid} = useMidsContainer();
  const {monthYear} = useQueryMonthYear();
  const [vdmpChargebacksReport, setVdmpChargebacksReport] =
    useState<PaginatedResponse<VDMPChargebackReport> | null>(null);
  const [loading, setLoading] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>(DEFAULT_SEARCH_CRITERIA);
  const [filters, setFilters] = useState<Filter>({});

  const showNotification = useNotification();

  const getDateRangesArray = (): string[] | undefined => {
    return Object.keys(filters).length
      ? Object.keys(filters)
          .sort()
          // @ts-ignore
          .map(key => `${filters[key].value[0]},${filters[key].value[1]}`)
      : undefined;
  };

  const fetchReport = useCallback(
    async (searchCriteriaOpts: Partial<SearchCriteria>) => {
      if (!api || !monthYear) {
        return null;
      }

      const mid = selectedMid === AllMIDs ? undefined : selectedMid;
      const dateRanges = getDateRangesArray();

      try {
        const response = await api.fraud.getFraudVDMPChargebackReport(
          {...DEFAULT_SEARCH_CRITERIA, ...searchCriteriaOpts},
          monthYear,
          dateRanges,
          mid
        );

        return response;
      } catch (error) {
        console.log('getFraudVDMPChargebackReport error', error);

        showNotification('error', 'Error while loading report occurred.');

        return null;
      }
    },
    [api, monthYear, selectedMid, filters]
  );

  const exportProps = useMemo<Partial<ExportProps>>(() => {
    const getAllDataForExport = async () => {
      const response = await fetchReport({limit: 0, offset: 0});

      if (!response || !response.data || !response.data.length) {
        return [];
      }

      return response.data.map(getHighlightedReportRowData);
    };

    return {
      getRows: getAllDataForExport,
      useSelectorMapping: true,
    };
  }, [fetchReport]);

  useEffect(() => {
    const getVDMPChargebacksReport = async () => {
      setLoading(true);

      try {
        const response = await fetchReport(searchCriteria);

        setVdmpChargebacksReport(response);
      } catch (error) {
        setVdmpChargebacksReport(null);
      } finally {
        setLoading(false);
      }
    };

    getVDMPChargebacksReport();
  }, [fetchReport, searchCriteria]);

  return (
    <VDMPReport
      exportProps={exportProps}
      filters={filters}
      report={vdmpChargebacksReport}
      searchCriteria={searchCriteria}
      setFilters={setFilters}
      setSearchCriteria={setSearchCriteria}
      loading={loading}
    />
  );
};
