import {UniversalTimestamp} from '../documents';
import {AccountStatus} from './AccountRisk';
import {Dispositions} from './Dispositions';
import {SalesDisposition} from './SalesDisposition';

const YELLOW = '#FFDB00';
const ORANGE = '#FF9E0A';
const GREEN = '#00D68E';
const BLUE = '#1877f2';
const PURPLE = '#9c27b0';
const RED = '#E60047';
const HOT_PINK = '#FF0B9A';
const BLACK = '#000';
const OFF_WHITE = '#f5fafc';
const NONE = 'transparent';

export const Colors: {
  [_ in Dispositions]: string;
} = {
  [Dispositions.started]: HOT_PINK,
  [Dispositions.signatureNeeded]: HOT_PINK,
  [Dispositions.incomplete]: HOT_PINK,
  [Dispositions.asWithdrawn]: ORANGE,
  [Dispositions.asDeclined]: ORANGE,
  [Dispositions.asIncomplete]: ORANGE,
  [Dispositions.asReceived]: ORANGE,
  [Dispositions.asRequestedSupportingDocuments]: ORANGE,
  [Dispositions.asSupportingDocumentsReceived]: ORANGE,
  [Dispositions.uwReceived]: BLUE,
  [Dispositions.uwPending]: ORANGE,
  [Dispositions.uwSubmittedToOtherBank]: ORANGE,
  [Dispositions.uwApproved]: GREEN,
  [Dispositions.uwDeclined]: RED,
  [Dispositions.uwWithdrawn]: RED,
  [Dispositions.uwArchived]: RED,
  [Dispositions.enqueued]: YELLOW,
  [Dispositions.failed]: RED,
  [Dispositions.boarded]: OFF_WHITE,
};

export const getColor = (
  disposition: Dispositions,
  isClosed?: boolean,
  uwUploadedAt?: UniversalTimestamp | null
) => {
  if (isClosed) {
    return BLACK; // black
  }

  if (disposition === Dispositions.uwPending && !!uwUploadedAt) {
    return PURPLE;
  }

  return Colors[disposition];
};

export const SalesLeadColors: {
  [_ in SalesDisposition]: string;
} = {
  [SalesDisposition.new]: HOT_PINK,
  [SalesDisposition.leftVM]: PURPLE,
  [SalesDisposition.followUpScheduled]: ORANGE,
  [SalesDisposition.hotReadyToSign]: RED,
  [SalesDisposition.sentApp]: RED,
  [SalesDisposition.sentIntroEmailWithApp]: BLUE,
  [SalesDisposition.contactAttempt1]: BLUE,
  [SalesDisposition.contactAttempt2]: BLUE,
  [SalesDisposition.contactAttempt3]: BLUE,
  [SalesDisposition.contactAttempt4]: BLUE,
  [SalesDisposition.contactAttempt5]: BLUE,
  [SalesDisposition.appCompleted]: GREEN,
  [SalesDisposition.appSubmittedReqSupDocsContactAttempt1]: GREEN,
  [SalesDisposition.appSubmittedReqSupDocsContactAttempt2]: GREEN,
  [SalesDisposition.appSubmittedReqSupDocsContactAttempt3]: GREEN,
  [SalesDisposition.appSubmittedReqSupDocsContactAttempt4]: GREEN,
  [SalesDisposition.appSubmittedReqSupDocsContactAttempt5]: GREEN,
  [SalesDisposition.appSubmittedWithSupportingDocs]: GREEN,
  [SalesDisposition.invalidLead]: BLACK,
  [SalesDisposition.badLead]: BLACK,
  [SalesDisposition.notInterested]: BLACK,
  [SalesDisposition.deleted]: BLACK,
  [SalesDisposition.unresponsive]: BLACK,
};

export const getSalesLeadColor = (disposition: SalesDisposition) => {
  return SalesLeadColors[disposition];
};

export const AccountStatusColors: {
  [_ in AccountStatus]: string;
} = {
  [AccountStatus.inactive]: YELLOW,
  [AccountStatus.activeFirstBatchReview]: ORANGE,
  [AccountStatus.active]: GREEN,
  [AccountStatus.activePending]: PURPLE,
  [AccountStatus.approved]: BLUE,
  [AccountStatus.terminated]: RED,
  [AccountStatus.closed]: NONE,
};

export const getAccountStatusColor = (accountStatus?: AccountStatus) => {
  if (!accountStatus) {
    return NONE;
  }

  return AccountStatusColors[accountStatus];
};
