import {Autocomplete, Box, TextField} from '@mui/material';
import {omit, sortBy} from 'lodash';
import {useEffect, useRef, useState} from 'react';
import {
  Agent,
  Collections,
  Firebase,
  selectAgentView,
  TicketAssignee,
  UniversalSnapshot,
} from '../../../..';
import {FIELD_NAME_AGENT} from '../../constants/constants';
import {InputAssigneeError} from '../../types';
import {AssigneeEditorValueGroup} from './AssigneeEditorValueGroup';

type AgentOption = TicketAssignee['agent'];

type Props = {
  draft: TicketAssignee;
  setDraft: (draft: TicketAssignee) => void;
  errors: Record<InputAssigneeError, string>;
  setErrors: (errors: Record<InputAssigneeError, string>) => void;
};

export const AssigneeEditorValueGroupAgent = ({draft, setDraft, errors, setErrors}: Props) => {
  const [internalValue, setInternalValue] = useState<TicketAssignee['agent'] | null>(null);

  const [agents, setAgents] = useState<AgentOption[]>([]);

  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const groupId = draft.group?.id;
    if (!groupId) {
      return;
    }

    Firebase.firestore
      .collection(Collections.agents)
      .where('group.id', '==', groupId)
      .where('isActive', '==', true)
      .get()
      .then(snap => {
        if (snap.size) {
          const agentViews = sortBy(
            snap.docs.map(doc => {
              const agentView = selectAgentView(doc as UniversalSnapshot<Agent>);
              return {
                id: agentView.id,
                name: `${agentView.firstName} ${agentView.lastName}`.trim(),
              };
            }),
            e => e.name
          );
          isMounted.current && setAgents(agentViews);
        } else {
          isMounted.current && setAgents([]);
        }
      })
      .catch(error => {
        console.error('Error writing document (InputAgent): ', error);
      });
  }, [draft.group?.id]);

  useEffect(() => {
    if (agents.length === 0 || !draft?.agent) {
      return;
    }

    setInternalValue(draft.agent);
  }, [agents.length, draft.agent?.id]);

  return (
    <>
      <AssigneeEditorValueGroup
        draft={draft}
        setDraft={setDraft}
        errors={errors}
        setErrors={setErrors}
      />
      <Box mt={2}>
        <Autocomplete
          value={internalValue}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          onChange={async (
            _: React.SyntheticEvent<Element, Event>,
            newValue: AgentOption | null
          ) => {
            setDraft({...draft, agent: newValue ?? undefined});
            if (newValue) {
              setErrors(omit(errors, FIELD_NAME_AGENT) as Record<InputAssigneeError, string>);
            }
          }}
          disabled={!draft.group?.id || !agents.length}
          options={agents}
          renderInput={params => (
            <TextField
              {...params}
              label="Assignee Agent"
              required
              variant="standard"
              error={Boolean(errors[FIELD_NAME_AGENT])}
              helperText={errors[FIELD_NAME_AGENT]}
            />
          )}
          getOptionLabel={(item: AgentOption) => item?.name ?? 'n/a'}
          renderOption={(props, option) => (
            <li {...props} key={option?.id}>
              {option?.name ?? 'n/a'}
            </li>
          )}
        />
      </Box>
    </>
  );
};
