export enum ApplicationType {
  tiered = 'Tiered',
  flatRate = 'Flat Rate',
  cashDiscount = 'Cash Discount',
  interchange = 'Interchange',
  err = 'ERR',
  cnpVCp = 'CNP v CP',
  surcharge = 'Surcharge',
  dualPricing = 'Dual Pricing', // DEPRECATED
  dualPricingPassThroughFees = 'Dual Pricing Credit w/PIN Debit Pass-through Fees', // Old "Dual Pricing"
  dualPricingFlatRate = 'Dual Pricing w/PIN Debit Flat Rate',
}
