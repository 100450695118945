import {Box, Divider, Drawer, DrawerProps, List} from '@mui/material';
import React, {useEffect} from 'react';
import {matchPath} from 'react-router';
import {useLocation} from 'react-router-dom';
import {NavMenuItem} from './NavMenuItem';
import {BadgeCounters, NavMenuLink, NavMenuParentLink} from './NavMenuTypes';

type Props = {
  links: NavMenuParentLink[];
  logo?: string;
  logoAlt?: string;
  isItemVisible: (link: NavMenuLink) => boolean;
  drawerProps?: Partial<DrawerProps>;
  badgeCounters?: BadgeCounters;
};

const activeChild = (
  pathname: string,
  linkArray: NavMenuParentLink[]
): NavMenuParentLink | undefined => {
  return linkArray.find(
    ({children = [], route, exact}) =>
      (route && matchPath(pathname, {path: route, exact: exact ?? true})) ||
      children.some(({route: childRoute}) => matchPath(pathname, childRoute))
  );
};

export const NavMenu = ({
  links,
  isItemVisible,
  logo,
  logoAlt,
  drawerProps = {},
  badgeCounters,
}: Props): JSX.Element => {
  const location = useLocation();
  const [expanded, setExpanded] = React.useState('');
  const handleExpandClick = (name: string) => {
    setExpanded(name !== expanded ? name : '');
  };

  useEffect(() => {
    setExpanded(activeChild(location.pathname, links)?.name ?? '');
  }, [location.pathname, links]);

  return (
    <Drawer variant="permanent" PaperProps={{style: {width: 256}}} {...drawerProps}>
      <Box
        sx={theme => ({
          display: 'flex',
          alignItems: 'center',
          padding: theme.spacing(0, 1),
          justifyContent: 'center',
          minHeight: '64px',
        })}
      >
        <Box
          component="img"
          sx={{
            width: 'calc(100% - 32px)',
          }}
          src={logo}
          alt={logoAlt}
        />
      </Box>
      <Divider sx={{margin: theme => theme.spacing(0, 2)}} />
      <Box p={2}>
        <List
          disablePadding
          sx={{
            '& > *': {
              mt: 0.5,
              mb: 0.5,
            },
          }}
        >
          {links.map(link =>
            isItemVisible(link) ? (
              <NavMenuItem
                key={link.name}
                link={link}
                expandedLink={expanded}
                onExpand={handleExpandClick}
                isItemVisible={isItemVisible}
                badgeCounters={badgeCounters}
              />
            ) : null
          )}
        </List>
      </Box>
    </Drawer>
  );
};
