import {yupResolver} from '@hookform/resolvers/yup';
import {LoadingButton} from '@mui/lab';
import {Box, Stack} from '@mui/material';
import {MerchantView, PreferredCommunicationMethod} from '@ozark/common';
import {SelectHooked, TextFieldHooked} from '@ozark/common/components';
import PhoneNumber from 'awesome-phonenumber';
import {FormProvider, useForm} from 'react-hook-form';
import * as yup from 'yup';

type TMerchantProfileForm = Pick<
  MerchantView,
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'customerServiceEmail'
  | 'phoneNumber'
  | 'preferredCommunicationMethod'
>;

type Props = {
  profile: MerchantView;
  onSubmit: (data: Partial<MerchantView>) => Promise<void>;
  isSaving: boolean;
};

export const MerchantProfileForm = ({profile, onSubmit, isSaving}: Props) => {
  const methods = useForm<TMerchantProfileForm>({
    resolver: yupResolver(merchantProfileSchema),
    defaultValues: {
      firstName: profile.firstName,
      lastName: profile.lastName,
      email: profile.email,
      customerServiceEmail: profile.customerServiceEmail,
      phoneNumber: profile.phoneNumber,
      preferredCommunicationMethod: profile.preferredCommunicationMethod,
    },
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <Stack spacing={3} mt={3}>
          <TextFieldHooked name="firstName" label="First Name" required />
          <TextFieldHooked name="lastName" label="Last Name" required />
          <TextFieldHooked name="email" label="Email" required />
          <TextFieldHooked name="customerServiceEmail" label="Customer Service Email" />
          <TextFieldHooked name="phoneNumber" label="Phone Number" required />
          <SelectHooked
            name="preferredCommunicationMethod"
            label="Preferred Communication Method"
            options={preferredCommunicationMethodOptions}
            required
          />
          <Box>
            <LoadingButton type="submit" variant="contained" loading={isSaving}>
              Save
            </LoadingButton>
          </Box>
        </Stack>
      </form>
    </FormProvider>
  );
};

const preferredCommunicationMethodOptions = Object.values(PreferredCommunicationMethod).map(
  val => ({value: val, label: val})
);
const merchantProfileSchema = yup.object().shape({
  firstName: yup.string().required().label('First Name'),
  lastName: yup.string().required().label('Last Name'),
  email: yup.string().email().required().label('Email'),
  customerServiceEmail: yup.string().email().label('Customer Service Email'),
  phoneNumber: yup
    .string()
    .min(12, 'Must have at least 12 digits') // validating against e164 format (+18002333333)
    .matches(/^\+1[2-9]{1}[0-9]{9}$/, 'Must be a valid US phone number')
    .transform(value => {
      const phone = value ? new PhoneNumber(value, 'US').getNumber('e164') : value;
      return phone;
    })
    .required('Phone is required'),
  preferredCommunicationMethod: yup.string().required().label('Preferred Communication Method'),
});
