import {NotificationCategory} from '@ozark/common';

export const ACTIVE_NOTIFICATION_ID_KEY = 'activeNotificationId';

export const NOTIFICATIONS_ROOT = '/notifications';
export const NOTIFICATIONS = `/notifications/:category(${NotificationCategory.Announcements}|${NotificationCategory.System})?`;
export const ANNOUNCEMENTS = `${NOTIFICATIONS_ROOT}/${NotificationCategory.Announcements}`;
export const SYSTEM_NOTIFICATIONS = `${NOTIFICATIONS_ROOT}/${NotificationCategory.System}`;

export const getNotificationDetailsRoute = (
  notificationId: string,
  category?: NotificationCategory
): string => {
  let route = NOTIFICATIONS_ROOT;
  if (category) {
    route += `/${category}`;
  }

  return `${route}?${ACTIVE_NOTIFICATION_ID_KEY}=${notificationId}`;
};
