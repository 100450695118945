import {Box, FormHelperText} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {omit} from 'lodash';
import {AssigneeType, TicketAssignee, ViewableByType} from '../../../..';
import {useUserInfo} from '../../../../hooks';
import {InputAssigneeError} from '../../types';

type Props = {
  draft?: TicketAssignee;
  setDraft: (draft: TicketAssignee) => void;
  errors: Record<InputAssigneeError, string>;
  setErrors: (errors: Record<InputAssigneeError, string>) => void;
  viewableBy: ViewableByType | undefined;
};

export function InputAssigneeType({draft, setDraft, errors, setErrors, viewableBy}: Props) {
  const {isPortal, isAgentMember, isMerchant} = useUserInfo();

  let options = Object.values(AssigneeType);

  if (isMerchant) {
    options = Object.values(AssigneeType).filter(o => o !== AssigneeType.merchant);
  }

  if (isPortal) {
    options = options.filter(o => o !== AssigneeType.erpUser);

    if (isAgentMember || isMerchant) {
      options = options.filter(o => o !== AssigneeType.agent);
    }
  }

  if (viewableBy === ViewableByType.internalOnly) {
    options = options.filter(o => o === AssigneeType.erpDepartment || o === AssigneeType.erpUser);
  } else if (viewableBy === ViewableByType.agentOrErp) {
    options = options.filter(
      o =>
        o === AssigneeType.erpDepartment || o === AssigneeType.erpUser || o === AssigneeType.agent
    );
  }
  const handleChange = (event: SelectChangeEvent) => {
    setDraft({type: event.target.value as AssigneeType});
    if (event.target.value) {
      setErrors(omit(errors, 'type') as Record<InputAssigneeError, string>);
    }
  };

  return (
    <Box mt={2} minWidth={450}>
      <FormControl fullWidth variant="standard" error={Boolean(errors['type'])} required>
        <InputLabel id="assignee-type-label">Type</InputLabel>
        <Select
          labelId="assignee-type-label"
          id="assignee-type"
          value={draft?.type ?? ''}
          label="Type"
          onChange={handleChange}
        >
          {options.map(o => (
            <MenuItem key={o} value={o}>
              {o}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{errors['type']}</FormHelperText>
      </FormControl>
    </Box>
  );
}
