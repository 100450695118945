import {Button, Divider, Link, Paper, TextField} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ApplicationStatusGroups} from '@ozark/common';
import {Title} from '@ozark/common/components/Title';
import React, {Fragment, useState} from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      marginBottom: theme.spacing(2),
    },
    paper: {
      marginTop: theme.spacing(0),
      padding: theme.spacing(1, 2, 2),
      position: 'relative',
      borderTop: 'solid 4px',
    },
    heading: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1, 0, 0),
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: '100%',
      paddingRight: theme.spacing(1),
      '& > *': {
        margin: theme.spacing(0, 0.5),
      },
    },
    midButton: {
      textTransform: 'none !important' as any,
    },
    grow: {
      flexGrow: 1,
    },
    divider: {
      margin: theme.spacing(0, 2),
    },
    selectInput: {
      backgroundColor: 'transparent !important',
    },
    menuPaper: {
      minWidth: 200,
    },
    midListItem: {
      padding: theme.spacing(0, 2),
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

type BarProps = {
  onLinkClick: () => void;
  group?: ApplicationStatusGroups;
  onGroupChange?: (event: any) => void;
  groupMenuItems?: JSX.Element[];
  dispositionString?: string;
  onDispositionChange?: (event: any) => void;
  dispositionMenuItems?: JSX.Element[];
  onShowAll?: () => void;
  showIcon?: React.ReactNode;
  onToggleSort: () => void;
  sortIcon: React.ReactNode;
  sortText: string;
};

export const ApplicationsBar = ({
  onLinkClick,
  group,
  onGroupChange,
  groupMenuItems,
  dispositionString,
  onDispositionChange,
  dispositionMenuItems,
  onShowAll,
  showIcon,
  onToggleSort,
  sortIcon,
  sortText,
}: BarProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  return (
    <Fragment>
      <Paper className={classes.paper} style={{borderTopColor: '#1877f2'}} square>
        <Title
          breadcrumbs={[
            <Link component="button" variant="body1" onClick={onLinkClick}>
              Applications
            </Link>,
          ]}
        >
          <div className={classes.grow} />
          {group && onGroupChange && groupMenuItems?.length && (
            <Fragment>
              <TextField
                value={group}
                onChange={onGroupChange}
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.selectInput,
                  },
                  disableUnderline: true,
                }}
                select
              >
                {groupMenuItems}
              </TextField>
              <Divider orientation="vertical" className={classes.divider} flexItem />
            </Fragment>
          )}
          {dispositionString && onDispositionChange && dispositionMenuItems?.length && (
            <Fragment>
              <TextField
                value={dispositionString}
                onChange={onDispositionChange}
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.selectInput,
                  },
                  disableUnderline: true,
                }}
                select
              >
                {dispositionMenuItems}
              </TextField>
              <Divider orientation="vertical" className={classes.divider} flexItem />
            </Fragment>
          )}
          {onShowAll && showIcon && (
            <Fragment>
              <Button size="small" onClick={onShowAll} startIcon={showIcon}>
                Assigned to me
              </Button>
              <Divider orientation="vertical" className={classes.divider} flexItem />
            </Fragment>
          )}

          <Button size="small" onClick={onToggleSort} endIcon={sortIcon}>
            {sortText}
          </Button>
        </Title>
      </Paper>
    </Fragment>
  );
};
