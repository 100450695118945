import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DoneIcon from '@mui/icons-material/Done';
import {LoadingButton} from '@mui/lab';
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  ApplicationSubCollection,
  AsyncState,
  Collections,
  DocumentsUploadFinicityGetAchDetailResult,
  DocumentsUploadFinicityGetInstitutionResult,
  FinicityAccount,
  FinicityData,
  FinicityDocumentId,
  Firebase,
  formatterCurrency,
  GroupView,
  useApiContainer,
  useCallable,
  useNotification,
} from '@ozark/common';
import React, {Fragment, useEffect, useState} from 'react';

type Props = {
  applicationId: string;
  group: AsyncState<GroupView>;
  token: string;
  onSuccess: (bankName: string) => void;
  linkedBankAccount?: string;
};

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

export const DocumentUploadFinicityLink = ({
  onSuccess,
  group,
  token,
  applicationId,
  linkedBankAccount,
}: Props) => {
  const classes = useStyles();
  const apiClient = useApiContainer();
  const showNotification = useNotification();

  const {
    getDocumentsUploadFinicityLinkToken,
    getDocumentsUploadFinicityAchDetail,
    getDocumentsUploadFinicityInstitution,
    selectDocumentsUploadFinicityLinkBankAccount,
    selectDocumentsUploadFinicityAccount,
    getDocumentsUploadFinicityAccounts,
  } = useCallable();
  const [isConnectLoading, setConnectLoading] = useState<boolean>(false);
  const [finicityAccount, setFinicityAccount] = useState<FinicityAccount>();
  const [finicityData, setFinicityData] = useState<FinicityData>();
  const [generateUrl, setGenerateUrl] = useState<string | null>(null);

  const [linkedBankAccountName, setLinkedBankAccountName] = useState<string>();

  useEffect(() => {
    // load finicity script only once on page load
    const script = document.createElement('script');
    script.src = 'https://connect2.finicity.com/assets/sdk/finicity-connect.min.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (!linkedBankAccount) {
      return;
    }
    setLinkedBankAccountName(linkedBankAccount);
  }, [linkedBankAccount]);

  const handleAccountChange = (account: any) => async (_event: any) => {
    if (!apiClient) {
      return;
    }
    setConnectLoading(true);
    // prevent setting value if account is already selected
    if (account.id === finicityAccount?.accountId) {
      setConnectLoading(false);
      return;
    }
    // make sure the account id is only a checking or savings account
    if (!['checking', 'savings'].includes(account.type)) {
      showNotification('error', 'Only checking and savings accounts are allowed for deposits.');
      setConnectLoading(false);
      return;
    }

    // async call to get institution and ach detail so checkbox can be checked without waiting for response
    Promise.all([
      getDocumentsUploadFinicityInstitution({
        token,
        institutionId: account.institutionId,
      }),
      getDocumentsUploadFinicityAchDetail({
        token,
        customerId: account.customerId,
        accountId: account.id,
      }),
    ])
      .then(
        async (
          results: [
            DocumentsUploadFinicityGetInstitutionResult | null,
            DocumentsUploadFinicityGetAchDetailResult | null
          ]
        ) => {
          const [institution, achDetail] = results;

          if (
            !(achDetail && institution) ||
            institution.status !== 'ok' ||
            achDetail.status !== 'ok'
          ) {
            return;
          }

          try {
            const linkedBankName = institution.data.institution?.name || 'Unknown Bank';

            const finicityAcc = {
              customerId: account.customerId,
              accountId: account.id,
              bankName: linkedBankName,
            };
            setFinicityAccount(finicityAcc);

            await selectDocumentsUploadFinicityAccount({
              token,
              finicityAccount: finicityAcc,
              bankName: institution.data.institution?.name || '',
              routingNumber: achDetail.data.routingNumber,
              bankAccountNumber: achDetail.data.realAccountNumber,
            });
            setLinkedBankAccountName(linkedBankName);

            // save linked account into Firestore:
            await selectDocumentsUploadFinicityLinkBankAccount({token, linkedBankName});

            onSuccess(linkedBankName);
          } catch (err: any) {
            console.error(err);
          }
        }
      )
      .catch(_err => {
        showNotification(
          'error',
          'Please select another account or enter your account details manually.'
        );
      })
      .finally(() => {
        setConnectLoading(false);
      });
  };

  useEffect(() => {
    // subscribes to finicity subcollection to show list of accounts when available
    const unsubscribe = Firebase.firestore
      .collection(Collections.applications)
      .doc(applicationId)
      .collection(ApplicationSubCollection.finicity)
      .doc(FinicityDocumentId.link)
      .onSnapshot(async snapshot => {
        if (!snapshot.exists) {
          return;
        }

        if (!finicityData) {
          const finicity = await getDocumentsUploadFinicityAccounts({token});
          if (finicity.status === 'ok' && finicity.data) {
            setFinicityData(finicity.data);

            // mark as verified if already connected
            if (finicity.data.linkedBankName) {
              setLinkedBankAccountName(finicity.data.linkedBankName);
              onSuccess(finicity.data.linkedBankName);
            }
          }
        }
      });
    return () => {
      unsubscribe();
    };
  }, [applicationId, finicityData, getDocumentsUploadFinicityAccounts, onSuccess, token]);

  useEffect(() => {
    if (!generateUrl) return;
    const connect = (window as any).finicityConnect;
    if (!connect) {
      return;
    }
    // launching finicity connect doesn't work without being wrapped in setTimeout
    setTimeout(() => {
      connect.launch(generateUrl, {
        selector: '#connect-container',
        overlay: 'rgba(255,255,255, 0)',
        success: (_event: any) => {
          handleConnectClose();
        },
        cancel: (_event: any) => {
          handleConnectClose();
        },
      });
    }, 0 /* delay duration does not matter */);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateUrl]);

  const handleConnectClose = () => {
    // finicity connect must be destroyed before it can be launched again
    (window as any).finicityConnect.destroy();
    setGenerateUrl(null);
  };

  const handleConnectFinicity = async () => {
    setConnectLoading(true);
    try {
      if (!apiClient) {
        return;
      }

      const response = await getDocumentsUploadFinicityLinkToken({
        token,
        redirectUri: window.location.href.replace('localhost:3001', 'local.luqra.com'),
        experienceId: group.data?.finicityExperienceId,
      });

      if (response.status === 'ok') {
        if (response.finicityLinkToken) {
          setGenerateUrl(response.finicityLinkToken);
        }
      } else {
        console.error(response);
        showNotification('error', 'Erroring connecting to Finicity');
      }
    } catch (err) {
      showNotification(
        'error',
        'Erroring connecting to Finicity. Please try again or enter your account details manually.'
      );
    }
    setConnectLoading(false);
  };

  return (
    <div className={classes.root}>
      {group.promised || !group.data || !token ? (
        <CircularProgress />
      ) : linkedBankAccountName ? (
        <h4>
          <span>
            <DoneIcon
              sx={{
                mb: -0.6,
              }}
              color="success"
            />
          </span>
          <span>Deposit Account Linked to {linkedBankAccountName}</span>
        </h4>
      ) : (
        <Fragment>
          <LoadingButton
            loading={isConnectLoading}
            variant="contained"
            size={'small'}
            color={'secondary'}
            style={{fontSize: '1em'}}
            onClick={handleConnectFinicity}
            endIcon={<ArrowForwardIosIcon />}
          >
            Instantly Link your deposit account with Finicity
          </LoadingButton>
          {!isConnectLoading && finicityData?.isConnected && finicityData?.accounts.length > 0 && (
            <Grid
              item
              container
              flexDirection="column"
              alignContent="center"
              alignItems="center"
              justifyContent="center"
              sx={{
                pt: 5,
                pb: 4,
              }}
            >
              <Grid item>
                <Typography variant="subtitle1" component="p" gutterBottom>
                  Select your account to use for deposits:
                </Typography>
              </Grid>
              <Grid item>
                <List
                  sx={{
                    px: 2,
                    width: 400,
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    borderRadius: 2,
                  }}
                >
                  {finicityData?.accounts?.map((account: any, index: number) => {
                    return (
                      <React.Fragment key={`account-${index}`}>
                        {index > 0 && <Divider />}
                        <ListItem
                          secondaryAction={
                            <Typography variant="body2">
                              {formatterCurrency.format(Number(account.balance))}
                            </Typography>
                          }
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={account.id === finicityAccount?.accountId}
                              onChange={handleAccountChange(account)}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{'aria-labelledby': `labelId-${index}`}}
                              disabled={!['checking', 'savings'].includes(account.type)}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={`labelId-${index}`}
                            primary={account.accountNickname}
                            secondary={
                              !['checking', 'savings'].includes(account.type) ? (
                                <Typography variant={'caption'} color={'red'}>
                                  Invalid Deposit Account
                                </Typography>
                              ) : (
                                `Ending in ${account.accountNumberDisplay}`
                              )
                            }
                          />
                        </ListItem>
                      </React.Fragment>
                    );
                  })}
                </List>
              </Grid>
            </Grid>
          )}
          <Dialog open={!!generateUrl} onClose={handleConnectClose}>
            <DialogContent>
              <Box
                id="connect-container"
                sx={{
                  '& iframe': {
                    height: 755,
                    width: 375,
                  },
                }}
              ></Box>
            </DialogContent>
          </Dialog>
        </Fragment>
      )}
    </div>
  );
};
