import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import {
  Alert,
  Box,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import {TicketSearchItemModel, TicketStatus, TicketStatusName} from '../../..';
import * as ROUTES from '../../../constants/routes';

type Props = {
  possibleDupes: TicketSearchItemModel[];
};

export const PossibleDupes = ({possibleDupes}: Props) => {
  if (!possibleDupes.length) return null;

  return (
    <Box mt={2}>
      <Alert severity="warning">
        Please ensure this ticket is not a duplicate of the following tickets:
      </Alert>
      <List sx={{width: '100%'}}>
        {possibleDupes.map(ticket => (
          <Link href={`${ROUTES.TICKETS}/id/${ticket.id}`} target="_blank">
            <ListItem key={ticket.id} disableGutters alignItems="flex-start" sx={{py: 0}}>
              <ListItemIcon sx={{minWidth: 40, pt: 0.2}}>
                <AssignmentTurnedInOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={ticket.subject}
                secondary={
                  <>
                    <Box>
                      <Typography
                        sx={{display: 'inline'}}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {'Association: '}
                      </Typography>
                      {ticket.association}
                    </Box>
                    <Box>
                      <Typography
                        sx={{display: 'inline'}}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {'Assignee: '}
                      </Typography>
                      {ticket.assignee}
                    </Box>
                    <Box>
                      <Typography
                        sx={{display: 'inline'}}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {'Creator: '}
                      </Typography>
                      {ticket.creator}
                    </Box>
                    <Box>
                      <Typography
                        sx={{display: 'inline'}}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {'Status: '}
                      </Typography>
                      {TicketStatusName[ticket.status as TicketStatus]}
                    </Box>
                  </>
                }
                sx={{textDecoration: 'underline'}}
                primaryTypographyProps={{variant: 'body2'}}
              />
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );
};
