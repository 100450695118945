export type Authorization = {
  id: string;
  cardType: string;
  accountNumberFirst6: string;
  accountNumberLast4: string;
  authorizedAmount: number;
  terminalNumber: string;
  transactionDate: string;
  entryMode: string;
  approvalCode: string;
  authorizationResponse: string;
  messageReasonCode?: string;
  authorizedAmountSigned: number;
};

export type MidAuthDeclinesResult = {
  totalCount: number;
  data: MidAuthDeclines[];
};

export type MidAuthDeclines = {
  applicationId?: string;
  mid: string;
  dba: string;
  /** authorizations count */
  authorizations: string;
  /** declines count */
  declines: string;
  /** calculated field: */
  authDeclineRatio?: string;
};

export const getEntryModeByCode = (code: string) => {
  switch (code) {
    case '00':
      return 'Other';
    case '01':
    case '09':
      return 'Keyed';
    case '02':
    case '06':
    case '90':
      return 'Swiped';
    case '03':
    case '07':
    case '91':
      return 'Contactless';
    case '05':
    case '95':
      return 'EMV Chip';
    case '10':
      return 'Stored Data';
    case '80':
      return 'Swiped - Fallback';
    case '81':
      return 'Integrated Payment';
    default:
      return 'Other';
  }
};

export const authStatusDeclined = 'statusDeclined';
export const authStatusApproved = 'statusApproved';
