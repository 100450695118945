import {add, isBefore} from 'date-fns';
import {useCallback, useEffect, useState} from 'react';
import {Filter} from '..';
import {getSearchTokens, GroupRole, useApiContainer, useAuthContainer, UserRoles} from '../..';
import {
  getMerchantsAuthorized,
  getMerchantsAuthorizedByClaims,
  getMerchantsRegInfo,
} from '../../helpers';
import {getAgentByUid} from '../../hooks/useAgents';
import {useUserInfo} from '../../hooks/useUserInfo';
import {
  ApplicationInfo,
  getAgentsInfo,
  MerchantActivityEnum,
  MerchantActivityMap,
  MerchantInfoAndActivity,
  SearchableMerchantInfoAndActivity,
} from './types';

export function useMerchantsInfoAndActivity() {
  const {claims} = useAuthContainer();
  const {isPortal, uid} = useUserInfo();
  const apiClient = useApiContainer();
  const [loading, setLoading] = useState(true);
  const [merchantsInfoAndActivity, setMerchantsInfoAndActivity] = useState<
    SearchableMerchantInfoAndActivity[]
  >([]);

  const [subAgentUids, setSubAgentUids] = useState<string[]>([]);

  const [filters, setFilters] = useState<Filter>({});

  const getMerchantsInfoAndActivityFiltered = useCallback(
    async (merchantsAuthorized: SearchableMerchantInfoAndActivity[]) => {
      let result = merchantsAuthorized;

      if (filters.mid?.value) {
        result = result.filter(am => am.mid === filters.mid.value);
      }
      const activity = 'activity';
      if (filters[activity]?.value === MerchantActivityEnum.ProccessedActively) {
        result = result.filter(am => am.processedActively);
      }
      if (filters[activity]?.value === MerchantActivityEnum.ProcessingStoped) {
        result = result.filter(am => am.processingStoped);
      }
      if (filters.merchant?.value) {
        result = result.filter(am =>
          am.merchant.toLowerCase().includes((filters.merchant.value as string)?.toLowerCase())
        );
      }
      if (filters.email?.value) {
        result = result.filter(am =>
          am.email.toLowerCase().includes((filters.email.value as string)?.toLowerCase())
        );
      }

      return result;
    },
    [filters]
  );

  const getSearchableFields = (rawMerchantInfoAndActivity: MerchantInfoAndActivity) => {
    return [
      rawMerchantInfoAndActivity.mid,
      rawMerchantInfoAndActivity.merchant,
      getAgentsInfo(rawMerchantInfoAndActivity.agents),
      rawMerchantInfoAndActivity.email,
      rawMerchantInfoAndActivity.phoneNumber,
    ];
  };

  const getMerchantsInfoAndActivity = async (
    merchantsInfo: ApplicationInfo[]
  ): Promise<SearchableMerchantInfoAndActivity[]> => {
    if (!merchantsInfo?.length) return [];

    const midActivitiesLastMonth = (await apiClient?.transactions.getMidActivityLastMonth()) ?? [];

    const dateThreeDaysBefore = add(new Date(), {
      days: -3,
    });
    const isBeforeLogged = (dateStr: string) => {
      const date = new Date(dateStr);
      return isBefore(date, dateThreeDaysBefore);
    };
    const midActivitiesLastMonthMap = midActivitiesLastMonth.reduce(
      (acc, next) => ({
        ...acc,
        [next.mid]: {
          processedActively: next.lastMonthTransactionAmount > 10,
          processingStoped: isBeforeLogged(next.lastTransactionDate),
        },
      }),
      {} as MerchantActivityMap
    );

    return merchantsInfo.map(
      m =>
        ({
          ...m,
          merchant: m.merchant || `Merchant (${m.email})`,
          processedActively: midActivitiesLastMonthMap[m.mid]?.processedActively,
          processingStoped: midActivitiesLastMonthMap[m.mid]?.processingStoped,
          searchTokens: getSearchTokens(getSearchableFields(m)),
        } as SearchableMerchantInfoAndActivity)
    );
  };

  useEffect(() => {
    if (!uid) {
      return;
    }

    if (claims?.role !== UserRoles.agent) {
      return;
    }

    // we handle Admin Group role here -> getMerchantsAuthorizedByClaims
    if (claims?.groupRole !== GroupRole.member) {
      return;
    }

    const getSubAgentIUids = async () => {
      const agent = await getAgentByUid(uid);

      if (!agent || !agent.subAgentUids?.length) {
        return;
      }

      setSubAgentUids(agent.subAgentUids);
    };

    getSubAgentIUids();
  }, [claims, uid]);

  useEffect(() => {
    setLoading(true);
    let active: boolean | undefined = true;
    (isPortal
      ? getMerchantsAuthorizedByClaims({
          uid,
          claims,
          subAgentUids: subAgentUids.length > 0 ? subAgentUids : undefined,
        })
      : getMerchantsAuthorized()
    )
      .then(getMerchantsRegInfo)
      .then(getMerchantsInfoAndActivity)
      .then(getMerchantsInfoAndActivityFiltered)
      .then(setMerchantsInfoAndActivity)
      .finally(() => active && setLoading(false));

    return () => (active = undefined);
  }, [claims, filters, subAgentUids]);

  return {
    loading,
    merchantsInfoAndActivity,
    filters,
    setFilters,
    subAgentUids,
  };
}
