import {Typography} from '@mui/material';
import {ReactNode} from 'react';

type Props = {
  children: ReactNode;
};

export const TextDetailTitle = ({children}: Props) => {
  return (
    <Typography variant="body1" component="div" sx={{wordWrap: 'break-word'}}>
      {children}
    </Typography>
  );
};
