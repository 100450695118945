import {TmpAttachment} from '@ozark/functions/src/functions/express/private/types/Attachments';
import {useCallback, useEffect, useState} from 'react';
import {
  Collections,
  Firebase,
  useApiClient,
  useAuthContainer,
  useLoadingContainer,
  useNotification,
} from '../../..';
import {TicketAttachment} from '../types';
import {useTicketId} from './useTicketId';

export function useDescriptionAttachments() {
  const {authUser} = useAuthContainer();
  const {apiClient} = useApiClient(authUser);
  const {ticketId} = useTicketId();
  const [attachments, setAttachments] = useState<TicketAttachment[]>([]);
  const {setIsLoading} = useLoadingContainer();
  const showNotification = useNotification();

  const uploadAttachment = useCallback(
    async (attachments: TmpAttachment[]) => {
      try {
        setIsLoading(true);

        await apiClient.attachments.createAttachments({
          baseCloudPath: `tickets/${ticketId}`,
          cloudPathField: 'fileCloudPath',
          author: {
            uid: authUser.data?.uid ?? '',
            name: authUser.data?.displayName ?? '',
          },
          collection: {
            name: Collections.tickets,
            id: ticketId,
          },
          attachments: attachments.map(att => ({
            ...att,
            extraFields: {
              fileName: att.oldFilename,
            },
          })),
        });

        showNotification('success', 'File(s) uploaded successfully.');
      } catch (error) {
        console.error('Error adding document: ', error);
        showNotification('error', 'File upload failed.');
      } finally {
        setIsLoading(false);
      }
    },
    [ticketId]
  );

  const removeAttachment = useCallback(
    async (attachmentId?: string) => {
      if (!ticketId || !attachmentId) {
        console.log('remove attachment', ticketId, attachmentId);
        showNotification('error', 'Error removing document (missing params)');
        return;
      }
      try {
        await Firebase.firestore
          .collection(Collections.tickets)
          .doc(ticketId)
          .collection(Collections.attachments)
          .doc(attachmentId)
          .delete();
        showNotification('success', 'Document successfully deleted!');
      } catch (error) {
        showNotification('error', 'Error removing document');
        console.error('Error removing document: ', error);
      }
    },
    [ticketId]
  );

  useEffect(() => {
    if (ticketId) {
      return Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .collection(Collections.attachments)
        .orderBy('createdAt', 'desc')
        .onSnapshot(
          snap => {
            if (snap.size) {
              const result = snap.docs.map(d => ({id: d.id, ...d.data()} as TicketAttachment));
              setAttachments(result);
            } else {
              setAttachments([]);
            }
          },
          err => {
            console.error('Error reading attachments: ', err);
            setAttachments([]);
          }
        );
    }
  }, [ticketId]);

  return {
    attachments,
    uploadAttachment,
    removeAttachment,
  };
}
