import {Autocomplete, Box, TextField} from '@mui/material';
import {omit, pick} from 'lodash';
import {useEffect, useState} from 'react';
import {
  AssigneeType,
  Collections,
  Department,
  Firebase,
  Profile,
  selectProfileView,
  TicketAssignee,
  UniversalSnapshot,
} from '../../../..';
import {FIELD_NAME_ERP_USER} from '../../constants/constants';
import {InputAssigneeError} from '../../types';

type Props = {
  draft: TicketAssignee;
  setDraft: (draft: TicketAssignee) => void;
  errors: Record<InputAssigneeError, string>;
  setErrors: (errors: Record<InputAssigneeError, string>) => void;
};

type ErpUser = Pick<TicketAssignee, 'erpUser' | 'erpDepartment' | 'erpRole'>;

export const AssigneeEditorValueErpUser = ({draft, setDraft, errors, setErrors}: Props) => {
  const [erpUsersByRole, setErpUsersByRole] = useState<ErpUser[]>([]);

  useEffect(() => {
    Firebase.firestore
      .collection(Collections.profiles)
      .where('isActive', '==', true)
      .orderBy('firstName')
      .get()
      .then(snap => {
        if (snap.size) {
          const profileViews = snap.docs.map(doc => {
            const profileView = selectProfileView(doc as UniversalSnapshot<Profile>);
            return {
              erpUser: {
                id: profileView.id,
                name: `${profileView.firstName} ${profileView.lastName}`.trim(),
              },
              erpDepartment: profileView.department as Department,
              erpRole: profileView.role,
            };
          });
          setErpUsersByRole(profileViews);
        } else {
          setErpUsersByRole([]);
        }
      })
      .catch(error => {
        console.error('Error writing document (InputAgent): ', error);
      });
  }, [draft.erpRole]);

  const value = pick(draft, ['erpUser', 'erpDepartment', 'erpRole']) as ErpUser;

  return (
    <>
      <Box mt={2}>
        <Autocomplete
          options={erpUsersByRole}
          value={value}
          onChange={(_: React.SyntheticEvent<Element, Event>, newValue: ErpUser | null) => {
            setDraft({
              type: AssigneeType.erpUser,
              erpUser: newValue?.erpUser,
              erpDepartment: newValue?.erpDepartment,
            });
            if (newValue) {
              setErrors(omit(errors, FIELD_NAME_ERP_USER) as Record<InputAssigneeError, string>);
            }
          }}
          getOptionLabel={(item: ErpUser) => item?.erpUser?.name ?? ''}
          isOptionEqualToValue={(option, value) => option?.erpUser?.id === value?.erpUser?.id}
          disableClearable
          renderInput={params => (
            <TextField
              {...params}
              label="ERP User"
              required
              variant="standard"
              error={Boolean(errors[FIELD_NAME_ERP_USER])}
              helperText={errors[FIELD_NAME_ERP_USER]}
            />
          )}
        />
      </Box>
    </>
  );
};
