import {ValueFormat} from '@nivo/core';
import {ResponsivePie} from '@nivo/pie';
import {formatNumber} from '../../../util/format';

export type Serie = {
  id: string;
  value?: string | number | null;
  label: string;
};

type Props = {
  data: Serie[];
  valueFormatter?: ValueFormat<number, void>;
  customScheme?: string[];
};
export const ChartPie = ({data, customScheme, valueFormatter = formatNumber}: Props) => {
  return (
    <ResponsivePie
      colors={customScheme ? customScheme : {scheme: 'set2'}}
      data={data}
      margin={{top: 40, right: 140, bottom: 80, left: 140}}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}
      enableArcLinkLabels={true}
      arcLinkLabelsSkipAngle={0}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{from: 'color'}}
      enableArcLabels={false}
      arcLinkLabel={d => (d.data.label ? d.data.label ?? '' : '...' + d.id.toString().slice(8))}
      valueFormat={valueFormatter}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{from: 'color', modifiers: [['darker', 2]]}}
    />
  );
};
