import {useCallback} from 'react';
import {PortalUserRoles, TicketHistoryRecord, UserRoles} from '../../..';
import {useUserInfo} from '../../../hooks';

export const useTicketHistoryDisplayName = () => {
  const {authUser, isErpUser, isAgent, isMerchant} = useUserInfo();

  const getTicketHistoryDisplayName = useCallback(
    (rec: TicketHistoryRecord): string => {
      const {uid, userName, department, userRole} = rec ?? {};

      if (isErpUser) {
        return userName;
      }

      if (department) {
        return `${department} department`;
      }

      if (uid && uid === authUser.data?.uid) {
        return authUser.data?.displayName!;
      }

      if ((isAgent || isMerchant) && PortalUserRoles.includes(userRole as UserRoles)) {
        return userName;
      }

      return userRole;
    },
    [authUser.data?.uid]
  );

  return {
    getTicketHistoryDisplayName,
  };
};
