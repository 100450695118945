import {Theme} from '@emotion/react';
import {Box, SxProps} from '@mui/material';
import {memo, ReactNode} from 'react';
import {StyledDivider} from '../../common';

const Divider = memo(() => <StyledDivider orientation="vertical" flexItem />);

export const PanelItem = ({
  children,
  dividerPosition,
  sx,
}: {
  children?: ReactNode;
  dividerPosition?: 'start' | 'end';
  sx?: SxProps<Theme>;
}) => {
  return (
    <Box sx={{display: 'flex', ...sx}}>
      {dividerPosition === 'start' && <Divider />}
      {children}
      {dividerPosition === 'end' && <Divider />}
    </Box>
  );
};
