import {EncryptedField} from '@ozark/common';
import {useCallback} from 'react';
import {useCallable} from './useCallable';
import {useNotification} from './useNotification';

export enum SensitiveFieldCaptions {
  socialSecurityNumber = 'Social Security Number',
  viewTaxId = 'Tax ID',
  bankAccountNumber = 'Bank Account Number',
  routingNumber = 'Routing Number',
}

export const useSensitiveData = (applicationId: string) => {
  const {decryptApplicationField} = useCallable();
  const showNotification = useNotification();

  const decryptField = useCallback(
    async (
      encryptedField: EncryptedField,
      humanFieldName: string,
      returnEncryptedValue: boolean
    ) => {
      const decryptApplicationFieldResult = await decryptApplicationField({
        applicationId: applicationId,
        encryptedField: encryptedField,
        returnEncryptedValue: returnEncryptedValue,
      });
      if (decryptApplicationFieldResult.status !== 'ok') {
        showNotification('error', `Failed to get ${humanFieldName}`);
        return;
      }
      return decryptApplicationFieldResult.plainText;
    },
    [applicationId, decryptApplicationField, showNotification]
  );

  return {
    decryptField,
  };
};
