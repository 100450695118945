import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {Box, Button, Input, Tooltip, Typography} from '@mui/material';
import {TooltipPlacement, useOnClickOutside} from '@ozark/common';
import React, {Fragment, useEffect, useLayoutEffect, useRef, useState} from 'react';

interface Props {
  value: string;
  setText: (val: string) => Promise<void> | void;
  className?: string;
  onValidate?: (value: string) => Promise<boolean> | boolean;
  emptyValue?: React.ReactNode | string;
  tooltip?: string;
  tooltipPlacement?: TooltipPlacement;
}

export const InlineTypography = ({
  value,
  setText,
  className = '',
  onValidate = () => true,
  emptyValue = <Fragment>&nbsp;</Fragment>,
  tooltip = 'Click to edit',
  tooltipPlacement = TooltipPlacement.Bottom,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>(value);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);

  const wrapperRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const revertChanges = () => {
    // revert the text and close the editor
    setInputValue(value);
    setIsEditing(false);
  };

  const saveChanges = async () => {
    const isValid = await onValidate(inputValue);
    if (!isValid) return;

    // save the text and close the editor
    setIsSaving(true);
    await setText(inputValue);
    setIsEditing(false);
    setIsSaving(false);
  };

  useOnClickOutside(wrapperRef, () => {
    if (isEditing) {
      revertChanges();
    }
  });

  const onValueClick = () => {
    setIsEditing(true);
  };

  useLayoutEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      saveChanges();
      return;
    }

    if (event.key === 'Escape') {
      revertChanges();
    }
  };

  return (
    <Tooltip
      title={isEditing ? '' : tooltip}
      placement={tooltipPlacement}
      TransitionProps={{
        timeout: {
          appear: 500,
          enter: 300,
          exit: 0,
        },
      }}
    >
      <div ref={wrapperRef}>
        <Typography
          onClick={onValueClick}
          className={className}
          noWrap={true}
          variant="caption"
          sx={[
            {
              cursor: 'pointer',
              display: 'inherit',
            },
            isEditing && {
              display: 'none',
            },
          ]}
        >
          {inputValue?.length ? inputValue : emptyValue}
        </Typography>
        <Box
          sx={[
            !isEditing && {
              display: 'none',
            },
          ]}
          position="relative"
        >
          <Input
            disabled={isSaving}
            value={inputValue}
            inputRef={inputRef}
            className={className}
            onChange={e => setInputValue(e.target.value)}
            sx={{
              width: '100%',
            }}
            inputProps={{
              onKeyDown: onKeyDown,
              sx: {
                paddingRight: 6,
              },
            }}
          />
          <Box sx={{position: 'absolute', top: '50%', right: 0, transform: 'translateY(-50%)'}}>
            <Button
              disabled={isSaving}
              variant="contained"
              onClick={saveChanges}
              size="small"
              sx={{padding: 0, minWidth: 'unset', marginRight: 0.5}}
            >
              <CheckIcon fontSize="small" />
            </Button>
            <Button
              onClick={revertChanges}
              size="small"
              sx={{padding: 0, minWidth: 'unset'}}
              disabled={isSaving}
            >
              <CloseIcon fontSize="small" />
            </Button>
          </Box>
        </Box>
      </div>
    </Tooltip>
  );
};
