import {
  Box,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {groupBy} from '@s-libs/micro-dash';
import {currentFormatter} from '../../ReportingPage';
import {AgentPayoutsToDisplay} from './types';

interface Props {
  agentPayouts: AgentPayoutsToDisplay[] | null;
  agentPayoutAdj: number;
}
export const AgentAdjustments = ({agentPayouts, agentPayoutAdj}: Props) => {
  return (
    <>
      {!!agentPayouts && (
        <Box mt={2} p={2}>
          <Typography variant="h5">Agent Adjustments</Typography>

          <TableContainer component={Paper}>
            <MuiTable sx={{maxWidth: 650}}>
              <TableHead>
                <TableRow>
                  <TableCell>Agent</TableCell>
                  <TableCell align="right">Description</TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(groupBy(agentPayouts, agentPayout => agentPayout.agentName)).map(
                  ([agentName, payouts], index) =>
                    payouts.map(({amount, description}, idx) => (
                      <TableRow
                        key={`${agentName}-${index}-${idx}`}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                      >
                        <TableCell component="th" scope="row">
                          {agentName}
                        </TableCell>

                        <TableCell align="right">{description}</TableCell>

                        <TableCell align="right" sx={{color: amount >= 0 ? 'green' : 'red'}}>
                          {currentFormatter.format(amount)}
                        </TableCell>
                      </TableRow>
                    ))
                )}
                <TableRow key="summary">
                  <TableCell align="right" colSpan={2} sx={{fontWeight: 500}}>
                    Total
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{color: agentPayoutAdj >= 0 ? 'green' : 'red', fontWeight: 500}}
                  >
                    {currentFormatter.format(agentPayoutAdj)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </MuiTable>
          </TableContainer>
        </Box>
      )}
    </>
  );
};
