import {
  ApplicationDocument,
  ApplicationSubCollection,
  Collections,
  Firebase,
  getBoardedApplicationDocumentValues,
  getNotBoardedApplicationDocumentValues,
  useApplicationView,
} from '@ozark/common';
import {Notes} from '@ozark/common/components';
import {useEffect, useMemo, useState} from 'react';

type ApplicationNotesProps = {
  applicationId: string;
  notesSubCollection: ApplicationSubCollection.uwRiskNotes | ApplicationSubCollection.supportNotes;
};

export const ApplicationNotes = ({applicationId, notesSubCollection}: ApplicationNotesProps) => {
  const {application} = useApplicationView(applicationId);
  const applicationFirebaseRef = useMemo(() => {
    return Firebase.firestore.collection(Collections.applications).doc(applicationId);
  }, [applicationId]);

  const [documentTypeOptions, setDocumentTypeOptions] = useState<ApplicationDocument[]>([]);
  useEffect(() => {
    setDocumentTypeOptions(
      !!application.data?.boardedAt
        ? getBoardedApplicationDocumentValues()
        : getNotBoardedApplicationDocumentValues()
    );
  }, [application.data?.boardedAt]);

  return (
    <Notes
      allowAddComments
      allowAttachments
      allowEditMessages
      allowDeleteMessages
      firestoreDocumentRef={applicationFirebaseRef}
      notesSubCollection={notesSubCollection}
      documentTypeOptions={documentTypeOptions}
    />
  );
};
