import {
  AsyncState,
  Collections,
  Firebase,
  ScheduledJob,
  ScheduledJobView,
  selectScheduledJobView,
  UniversalSnapshot,
} from '@ozark/common';
import {useCallback, useEffect, useState} from 'react';

export const useScheduledReporting = (userId: string | undefined) => {
  const [scheduledJobs, setScheduledJobs] = useState<AsyncState<ScheduledJobView[]>>({
    promised: true,
  });

  const fetchUserScheduledReportingList = useCallback(() => {
    if (!userId) return;
    return Firebase.firestore
      .collection(Collections.scheduledJobs)
      .where('uid', '==', userId)
      .where('isOneTime', '==', false)
      .onSnapshot(
        async snapshot => {
          if (snapshot.size === 0) {
            setScheduledJobs({promised: false, data: []});
            return;
          }

          const result = snapshot.docs.map(doc =>
            selectScheduledJobView(doc as UniversalSnapshot<ScheduledJob>)
          );

          setScheduledJobs({promised: false, data: result});
          return;
        },
        err => {
          console.error(err);
          setScheduledJobs({promised: false, error: err});
          return;
        }
      );
  }, [userId]);

  useEffect(() => {
    const unsubscribe = fetchUserScheduledReportingList();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [fetchUserScheduledReportingList]);

  return {scheduledJobs};
};
