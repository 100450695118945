import {Box, CircularProgress, styled, Typography} from '@mui/material';

type Props = {
  loading?: boolean;
  hasData?: boolean;
};

export const LoadingStatus = ({loading, hasData}: Props) => {
  if (!loading && hasData) return null;
  return (
    <div>
      {loading && (
        <BoxStyled>
          <Box>
            <TypographyStyled>Please wait. Data is being loaded...</TypographyStyled>
            <Box textAlign="center" pt={2}>
              <CircularProgress color="primary" />
            </Box>
          </Box>
        </BoxStyled>
      )}
      {!loading && !hasData && (
        <BoxStyled>
          <TypographyStyled>There is no data available.</TypographyStyled>
        </BoxStyled>
      )}
    </div>
  );
};

const TypographyStyled = styled(Typography)`
  top: 40%;
  position: relative;
  text-align: center;
`;

const BoxStyled = styled(Box)({
  display: 'flex',
  minHeight: 616,
  justifyContent: 'center',
  alignItems: 'center',
});
