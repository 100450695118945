import {Alert, Divider, MenuItem, Paper, TextField} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ProgramDisplayPreview} from '@ozark/app/src/components/ProgramPage';
import {useEquipment} from '@ozark/app/src/firebase/hooks/useEquipment';
import {
  GroupRateProgram,
  ProcessingTypes,
  ProcessingTypesKeys,
  useRatePrograms,
  useRateProgramsByGroup,
} from '@ozark/common';
import {Loading} from '@ozark/common/components';
import {useState} from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(1),
    },
    heading: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
    },
    preview: {
      padding: theme.spacing(4, 4, 0),
    },
    selectInput: {
      backgroundColor: 'transparent !important',
    },
  })
);

type Props = {
  groupId?: string | null;
};

export const Preview = ({groupId = null}: Props) => {
  const classes = useStyles();
  const [processingType, setProcessingType] = useState<ProcessingTypes>(
    ProcessingTypes.cardPresent
  );
  const {equipment} = useEquipment(processingType);

  const {ratePrograms, getRateSetByProcessingType} = groupId
    ? useRateProgramsByGroup(groupId)
    : useRatePrograms();

  const getRateProgramsAsArray = () => {
    const programs =
      !ratePrograms.promised && ratePrograms.data ? (ratePrograms.data as GroupRateProgram[]) : [];
    const processingTypeKey = Object.entries(ProcessingTypes).find(
      ([key, val]) => val === processingType
    )?.[0] as ProcessingTypesKeys;
    if (processingTypeKey) {
      return programs.filter(x => x.processingTypes[processingTypeKey].active);
    }
    return programs;
  };

  const getRateSet = (programName: string) =>
    getRateSetByProcessingType(ratePrograms, processingType, programName)!;

  if (ratePrograms.promised || equipment.promised || !equipment.data) return <Loading />;

  return (
    <div>
      <Alert severity="warning">
        This is a <b>preview</b> of what the applicant will see on the <b>Program Page</b> of the
        application.
      </Alert>
      <Paper className={classes.paper}>
        <div className={classes.heading}>
          <TextField
            value={processingType}
            onChange={(event: any) => setProcessingType(event.target.value as ProcessingTypes)}
            variant="standard"
            InputProps={{
              classes: {
                input: classes.selectInput,
              },
              disableUnderline: true,
            }}
            select
          >
            {Object.values(ProcessingTypes).sortAndMap(e => (
              <MenuItem key={e} value={e}>
                {e}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <Divider />
        <div className={classes.preview}>
          <ProgramDisplayPreview
            equipment={equipment.data}
            getRateSet={getRateSet}
            getRateProgramsAsArray={getRateProgramsAsArray}
            processingType={processingType}
          />
        </div>
      </Paper>
    </div>
  );
};
