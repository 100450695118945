export enum StoragePath {
  applications = 'applications',
  equipment = 'equipment',
  groups = 'groups',
  notifications = 'notifications',
  residuals = 'residuals',
  profiles = 'profiles',
  public = 'public',
  reports = 'reports',
  resources = 'resources',
  signatures = 'signatures',
  statements = 'statements',
  merchantDocuments = 'merchant-documents',
  archives = 'archives',
  chargebacks = 'chargebacks',
}
