import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ResponsiveLine} from '@nivo/line';
import {addDays, format} from 'date-fns';
import {DailyAuthorizationSummary, MonthlyAuthorizationResult} from '../..';
import {GraphColors} from '../../constants/colors';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      height: 300,
      width: 'auto',
    },
  })
);

type Props = {monthlyAuthorizationResult: MonthlyAuthorizationResult};

const formatDataForGraph = (dailyVolumeSummary: DailyAuthorizationSummary[]) => {
  const approvalData = dailyVolumeSummary
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .map(row => ({
      x: format(addDays(new Date(row.date), 1), 'MMM dd,yyyy'),
      y: row.approvalAmount,
    }));
  const declineData = dailyVolumeSummary
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .map(row => ({
      x: format(addDays(new Date(row.date), 1), 'MMM dd,yyyy'),
      y: row.declineAmount,
    }));

  return [
    {
      id: 'Approvals',
      color: GraphColors.Sales,
      data: approvalData,
    },
    {
      id: 'Declines',
      color: GraphColors.Credits,
      data: declineData,
    },
  ];
};

export const AuthorizationSummaryDetailGraph = ({monthlyAuthorizationResult}: Props) => {
  const classes = useStyles();

  const authorizationAmount = monthlyAuthorizationResult.monthlyAuthorizationSummary.reduce(
    (a, v) => a + Number(v.approvalAmount),
    0
  );

  return (
    <div className={classes.root}>
      <ResponsiveLine
        data={formatDataForGraph(monthlyAuthorizationResult.monthlyAuthorizationSummary)}
        margin={{top: 50, right: 110, bottom: 65, left: 90}}
        xScale={{type: 'point'}}
        yScale={{
          type: 'linear',
          min: 0,
          max: authorizationAmount ? 'auto' : 1000,
          stacked: false,
          reverse: false,
        }}
        yFormat=" >-$,.0f"
        lineWidth={3}
        curve="monotoneX"
        colors={{datum: 'color'}}
        animate={true}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 10,
          tickPadding: 5,
          tickRotation: -25,
          legend: 'Period',
          legendOffset: 60,
          legendPosition: 'middle',
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 25,
          tickRotation: 0,
          legend: 'Authorizations',
          legendOffset: -85,
          legendPosition: 'middle',
          format: amount => Number(amount).toLocaleString(),
        }}
        enableSlices="x"
        sliceTooltip={({slice}) => {
          return (
            <div
              style={{
                background: 'white',
                padding: '9px 12px',
                border: '1px solid #ccc',
              }}
            >
              {slice.points.map(point => (
                <div
                  key={point.id}
                  style={{
                    color: point.serieColor,
                    padding: '3px 0',
                  }}
                >
                  <strong>{point.serieId}</strong> [{point.data.yFormatted}]
                </div>
              ))}
            </div>
          );
        }}
        enablePoints={true}
        pointSize={8}
        pointColor={{theme: 'background'}}
        pointBorderWidth={2}
        pointBorderColor={{from: 'serieColor'}}
        pointLabel="yFormatted"
        pointLabelYOffset={-12}
        enablePointLabel={true}
        useMesh={true}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};
