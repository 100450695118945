import {Tabs} from '@mui/material';
import {ComponentType, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {TabsRouterTab} from '.';

export type TabsRoute = {
  label: string;
  href: string;
  component: ComponentType<React.PropsWithChildren<unknown>>;
  exact?: boolean;
};

type TabsRouterProps = {
  tabs: TabsRoute[];
};

export const TabsRouter = ({tabs}: TabsRouterProps) => {
  const {pathname} = useLocation();
  const [value, setValue] = useState(tabs.findIndex(t => t.href.startsWith(pathname)) ?? 0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const indexByPath = tabs.findIndex(t =>
      t.exact ? t.href === pathname : pathname.startsWith(t.href)
    );
    if (indexByPath > -1 && indexByPath != value) {
      setValue(indexByPath);
    }
  }, [pathname]);

  return (
    <Tabs value={value} onChange={handleChange} aria-label="fraud analysis tabs" centered>
      {tabs.map(tab => (
        <TabsRouterTab key={tab.label} label={tab.label} href={tab.href} />
      ))}
    </Tabs>
  );
};
