export enum SwipedVsKeyedStatisticFields {
  mid = 'mid',
  dba = 'dba',
  legalName = 'legalName',
  agent = 'agent',
  swiped = 'swiped',
  swipedPercent = 'swipedPercent',
  swipedPercentDelta = 'swipedPercentDelta',
  keyed = 'keyed',
  keyedPercent = 'keyedPercent',
  keyedPercentDelta = 'keyedPercentDelta',
  total = 'total',
  processingType = 'processingType',
}

export type SwipedVsKeyedStatistic = {
  mid: string;
  dba: string;
  legalName: string;
  agent: string;
  swiped: number;
  swipedPercent: number;
  swipedPercentDelta: number;
  keyed: number;
  keyedPercent: number;
  keyedPercentDelta: number;
  total: number;
  processingType: string;
  rowsCount: number;
};
