import {CreateDisputeReasonProps, Dispositions} from '@ozark/common';
import {DisputeReason} from '../../ApplicationDisputeHandlers';
import {createDisputeReason} from '../createDisputeReason';

export const declined = {
  [Dispositions.asDeclined]: [
    {
      name: 'Copyright Infringement',
      description: `Copyright Infringement`,
      type: 'checkbox',
    },
    {
      name: 'Excessive Chargebacks',
      description: `Excessive Chargeback Ratio above the card brand&#39;s thresholds`,
      type: 'checkbox',
    },
    {
      name: 'Prohibited Business',
      description: `Business type and/or model is not supported by our sponsoring bank`,
      type: 'checkbox',
    },
    {
      name: 'Other Reason',
      description: `Other Reason`,
      type: 'rich-text',
    },
  ].map(reason => {
    const {name, description, type} = reason as CreateDisputeReasonProps;
    return createDisputeReason({
      name: name,
      description: description,
      type: type,
    });
  }) as DisputeReason[],
  [Dispositions.uwDeclined]: [
    {
      name: 'Credit Profile',
      description: `Merchant&#39;s credit profile does not meet our minimum threshold`,
      type: 'checkbox',
    },
    {
      name: 'Copyright Infringement',
      description: `Copyright Infringement`,
      type: 'checkbox',
    },
    {
      name: 'Excessive Chargebacks',
      description: `Excessive Chargeback Ratio above the card brand&#39;s thresholds`,
      type: 'checkbox',
    },
    {
      name: 'ID Theft',
      description: 'Suspected ID Fraud',
      type: 'checkbox',
    },
    {
      name: 'MATCH',
      description: 'Merchant was placed on MATCH',
      type: 'checkbox',
    },
    {
      name: 'Misrepresentation',
      description: `Information data provided on the application and/or the supporting documentation is not consistent with the underwriting findings`,
      type: 'checkbox',
    },
    {
      name: 'Negative Online Presence',
      description: `Negative Online Presence and Reviews`,
      type: 'checkbox',
    },
    {
      name: 'Prohibited Business',
      description: `Business type and/or model is not supported by our sponsoring bank`,
      type: 'checkbox',
    },
    {
      name: 'Suspected Fraud/Straw Signer',
      description: `Information data provided on the application and/or the supporting documentation is not consistent with the underwriting findings`,
      type: 'checkbox',
    },
    {
      name: 'Other Reason',
      description: `Other Reason`,
      type: 'rich-text',
    },
  ].map(reason => {
    const {name, description, type} = reason as CreateDisputeReasonProps;
    return createDisputeReason({
      name: name,
      description: description,
      type: type,
    });
  }) as DisputeReason[],
} as {[key in Dispositions]?: DisputeReason[]};
