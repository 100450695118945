import {ResourceDocumentCategoryView, ResourceView} from '@ozark/common';
import {createContext, useContext} from 'react';
import {DisplayMode, ResourcesApplicationType} from '../types';
import {EditableField} from './constants';

export interface DisplayContextProps {
  appType: ResourcesApplicationType;
  groupId?: string;
  allowEdit: boolean;
  displayMode: DisplayMode;
  documentCategories: ResourceDocumentCategoryView[];
  activeDraggedResourceView?: ResourceView;
  isDragHandleAllowed: boolean;
  isRequiredFieldValid: (field: EditableField, value: string) => Promise<boolean>;
  handleSaveField: (field: EditableField, id: string, value: string) => Promise<void>;
  writePermissionsForResource: (r: ResourceView) => boolean;
  handleEditClick: (r: ResourceView) => void;
  handleDeleteClick: (resourceId: string) => void;
  handleValidateCategoryName: (newValue: string) => Promise<boolean>;
  handleSaveCategory: (id: string, value: string) => Promise<void>;
}

const DEFAULT_CONTEXT_VALUE: DisplayContextProps = {
  appType: ResourcesApplicationType.erp,
  allowEdit: false,
  displayMode: DisplayMode.list,
  documentCategories: [],
  isDragHandleAllowed: false,
  isRequiredFieldValid: () => Promise.resolve(false),
  handleSaveField: async () => {},
  writePermissionsForResource: () => false,
  handleEditClick: () => {},
  handleDeleteClick: () => {},
  handleValidateCategoryName: () => Promise.resolve(false),
  handleSaveCategory: async () => {},
};

export const DisplayContext = createContext<DisplayContextProps>(DEFAULT_CONTEXT_VALUE);
export const useDisplayContext = () => useContext(DisplayContext);
