import {Button} from '@mui/material';
import {useState} from 'react';
import {EditTypeDialog} from './EditTypeDialog';

export const TicketTypeCreateButton = () => {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  return (
    <>
      <Button variant="contained" onClick={() => setCreateDialogOpen(true)}>
        New Ticket Type
      </Button>
      {createDialogOpen && <EditTypeDialog onClose={() => setCreateDialogOpen(false)} />}
    </>
  );
};
