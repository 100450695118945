import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {Box, IconButton, InputBase} from '@mui/material';
import {ChangeEventHandler, useRef} from 'react';

interface Props {
  filterText: string;
  onTextChange: (value: string) => void;
  disableExtendOnFocus?: boolean;
  widthOnFocus?: string;
  inputSmallWidth?: string;
}

export const SearchInput = ({
  filterText,
  onTextChange,
  disableExtendOnFocus,
  widthOnFocus,
  inputSmallWidth,
}: Props) => {
  const ref = useRef<HTMLInputElement>();
  const handleTextChange: ChangeEventHandler<HTMLInputElement> = e => {
    onTextChange(e.target.value);
  };
  const onClearClick = () => {
    ref.current?.focus();
    onTextChange('');
  };

  return (
    <Box
      sx={theme => ({
        position: 'relative',
        borderRadius: '4px',
        boxShadow:
          '0px 2px 1px -1px rgba(0,0,0,0.2) inset, 0px 1px 1px 0px rgba(0,0,0,0.14) inset, 0px 1px 3px 0px rgba(0,0,0,0.12) inset',
        backgroundColor: '#fff',
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('lg')]: {
          width: 'auto',
        },
      })}
    >
      <Box
        sx={theme => ({
          padding: theme.spacing(0, 2),
          height: '100%',
          position: 'absolute',
          pointerEvents: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        })}
      >
        <SearchIcon />
      </Box>
      <InputBase
        inputRef={ref}
        value={filterText}
        onChange={handleTextChange}
        placeholder="Filter..."
        fullWidth
        sx={{
          '.MuiInputBase-root': {
            color: 'inherit',
          },
          '.MuiInputBase-input': theme => ({
            color: 'rgba(0, 0, 0, 0.8)',
            padding: theme.spacing(1.5, 1.5, 1.5, 0),
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            paddingRight: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
              width: inputSmallWidth ?? '10ch',
              '&:focus': {
                width: inputSmallWidth ?? (disableExtendOnFocus ? '10ch' : widthOnFocus ?? '30ch'),
              },
            },
          }),
        }}
        endAdornment={
          <Box sx={{minWidth: {md: 40, sm: 'auto'}}}>
            {filterText && (
              <IconButton size="small" onClick={onClearClick}>
                <ClearIcon />
              </IconButton>
            )}
          </Box>
        }
        inputProps={{'aria-label': 'search'}}
      />
    </Box>
  );
};
