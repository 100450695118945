import GetAppIcon from '@mui/icons-material/GetApp';
import {Button, Divider, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Title} from '@ozark/common/components/Title';
import {reduce} from '@s-libs/micro-dash';
import {ReactElement} from 'react';
import {ButtonExportCsv, ExportProps} from '../../common';
import {ButtonEmailReport, ScheduleReportProps} from '../../common/ButtonEmailReport';
import {MidSelect} from '../../MidSelect/MidSelect';
import {MidAndDba} from '../common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      marginBottom: theme.spacing(2),
    },
    paper: {
      marginTop: theme.spacing(0),
      padding: theme.spacing(1, 2, 2),
      position: 'relative',
      borderTop: 'solid 4px',
    },
    heading: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1, 0, 0),
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: '100%',
      paddingRight: theme.spacing(1),
      '& > *': {
        margin: theme.spacing(0, 0.5),
      },
    },
    midButton: {
      textTransform: 'none !important' as any,
    },
    grow: {
      flexGrow: 1,
    },
    divider: {
      margin: theme.spacing(2, 2),
    },
    selectInput: {
      backgroundColor: 'transparent !important',
    },
    menuPaper: {
      minWidth: 200,
    },
    midListItem: {
      padding: theme.spacing(0, 2),
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

type BarProps = {
  title?: string;
  mids: MidAndDba[];
  midsLoaded: boolean;
  selectedMid: string;
  disableExport?: boolean;
  onMidSelect: (mid: string) => void;
  exportToCsv?: () => Promise<void>;
  Filters?: ReactElement;
  noBorder?: boolean;
  noMargins?: boolean;
  exportProps?: ExportProps;
  scheduleReportProps?: ScheduleReportProps;
};

export const Bar = ({
  title = '',
  mids,
  midsLoaded,
  selectedMid,
  disableExport,
  exportToCsv,
  onMidSelect,
  Filters,
  noBorder,
  noMargins,
  exportProps,
  scheduleReportProps,
}: BarProps) => {
  const classes = useStyles();

  const formattedMids = midsLoaded
    ? {
        promised: false,
        data: reduce(
          mids,
          (ac: Map<string, string>, value) => {
            if (value.mid && value.doingBusinessAs) {
              ac.set(value.mid, value.doingBusinessAs);
            }
            return ac;
          },
          new Map()
        ),
      }
    : {promised: true};

  return (
    <Title
      breadcrumbs={[<Typography variant="body1">{title}</Typography>]}
      noBorder={noBorder}
      noMargins={noMargins}
    >
      <div className={classes.grow} />
      {Filters && (
        <>
          {Filters}
          <Divider orientation="vertical" className={classes.divider} flexItem />
        </>
      )}
      <MidSelect mids={formattedMids} handleSelectMid={onMidSelect} selectedMid={selectedMid} />

      {scheduleReportProps && (
        <>
          <Divider orientation="vertical" className={classes.divider} flexItem />
          <ButtonEmailReport scheduleReportProps={scheduleReportProps} />
        </>
      )}
      {(exportToCsv || exportProps) && (
        <>
          <Divider orientation="vertical" className={classes.divider} flexItem />
          {exportToCsv && (
            <Button
              startIcon={<GetAppIcon />}
              disabled={disableExport}
              onClick={exportToCsv}
              variant="contained"
              color="primary"
            >
              Export
            </Button>
          )}
          {exportProps && (
            <ButtonExportCsv
              filename={exportProps.filename}
              disabled={exportProps.disabled}
              rows={exportProps.rows}
              getRows={exportProps.getRows}
              columnsConfig={exportProps.columnsConfig}
              useSelectorMapping={exportProps.useSelectorMapping}
            />
          )}
        </>
      )}
    </Title>
  );
};
