import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';

type AttachmentListItem = {
  name: string;
  downloadUrl: string;
};

type Props<T extends AttachmentListItem> = {
  attachments: T[];
  onDelete?: (attachment: T) => void;
  isReadOnly?: boolean;
};

export const AttachmentsList = <T extends AttachmentListItem>({
  attachments,
  onDelete,
  isReadOnly = false,
}: Props<T>): JSX.Element => (
  <List dense={true} sx={{p: 0}}>
    {attachments.map((attachment, index) => (
      <ListItem
        key={`${attachment.name}-${index}`}
        secondaryAction={
          !isReadOnly &&
          onDelete && (
            <IconButton edge="end" aria-label="delete" onClick={() => onDelete(attachment)}>
              <DeleteIcon />
            </IconButton>
          )
        }
        sx={{px: 0}}
      >
        <ListItemButton
          component="a"
          href={attachment.downloadUrl}
          target="_blank"
          rel="noreferrer"
          sx={{pl: 0.5}}
        >
          <ListItemAvatar>
            <Avatar>
              <InsertDriveFileIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={attachment.name} />
        </ListItemButton>
      </ListItem>
    ))}
  </List>
);
