import makeStyles from '@mui/styles/makeStyles';
import {ActiveFilter, FilterOption} from '../../../../common/components';

export const forceActiveFilter = (
  filterOptions: FilterOption[],
  id: string,
  operatorId: string,
  value: string | [Date, Date]
) => {
  const option = filterOptions.find(e => e.id === id);
  const operator = option?.operators.find(e => e.id === operatorId);
  if (!(option && operator)) {
    console.error('An error occurred while applying a filter with id =', id);
    throw Error(`invalid filter with name = ${id}`);
  }
  const activeFilter: ActiveFilter = {
    option,
    operator,
    value,
  };
  return activeFilter;
};

export const percentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 2,
});

export const currentFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const useReportingPageStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  grow: {
    flex: 1,
  },
  space: {
    flexGrow: 1,
  },
  menuPaper: {
    minWidth: 200,
  },
  divider: {
    margin: theme.spacing(2),
  },
  chip: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  midButton: {
    textTransform: 'none !important' as any,
  },
  midListItem: {
    padding: theme.spacing(0, 2),
  },
  noContent: {
    top: '40%',
    position: 'relative',
    textAlign: 'center',
  },
  selectInput: {
    backgroundColor: 'transparent !important',
  },
}));
