import {MerchantView} from '@ozark/common';
import {Loading} from '@ozark/common/components';
import {MerchantsList} from '@ozark/common/components/Merchants';
import {useStore} from '../../store/helpers';

const Merchants = () => {
  const {authProfile, isUserMerchant} = useStore();

  if (authProfile.promised) {
    return <Loading />;
  }

  return (
    <MerchantsList
      userGroupId={authProfile.data?.group?.id || authProfile.data?.groupId}
      masterMerchant={isUserMerchant() ? (authProfile.data as MerchantView) : undefined}
    ></MerchantsList>
  );
};

export default Merchants;
