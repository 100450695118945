import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import {Link} from 'react-router-dom';
import {ticketStatusFolders} from '../constants/ticketStatusFolders';
import {useTicketsFiltersContainer} from '../hooks/useTicketsFiltersContainer';
import {useTicketsQuery} from '../hooks/useTicketsQuery';
import {TFolder} from '../types';
import {TicketsListFolderAll} from './TicketsListFolderAll';
import {TicketsListFolderMy} from './TicketsListFolderMy';

const getLink = (folder: TFolder) =>
  folder.filterKey
    ? `/tickets/view/${folder.id}?filterKey=${folder.filterKey}`
    : `/tickets/view/${folder.id}`;

export function TicketsListFolders() {
  const {folderId, filterKey} = useTicketsQuery();
  const {isAllTicketsView} = useTicketsFiltersContainer();

  return (
    <Box>
      <List component="nav" aria-label="main mailbox folders" sx={{pt: 0}}>
        {ticketStatusFolders.map(folder => (
          <Link key={folder.folderName} to={getLink(folder)}>
            {isAllTicketsView && (
              <>
                {folder.hasDivider && <Divider sx={{mr: 1, mt: 1}} />}
                <TicketsListFolderAll
                  key={folder.folderName}
                  folder={folder}
                  isSelected={folder.id === folderId && folder.filterKey === filterKey}
                />
              </>
            )}
            {!isAllTicketsView && (
              <>
                {folder.hasDivider && <Divider sx={{mr: 1, mt: 1}} />}
                <TicketsListFolderMy
                  key={folder.folderName}
                  folder={folder}
                  isSelected={folder.id === folderId && folder.filterKey === filterKey}
                />
              </>
            )}
          </Link>
        ))}
      </List>
    </Box>
  );
}
