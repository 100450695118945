import {isBoolean, isString} from '@s-libs/micro-dash';

export const emptyStringToNull = (value: any, originalValue: any) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
};

export const trimWhiteSpace = (value: unknown) => {
  if (!isString(value)) return value;
  return value.trim();
};

/**
 * Transform function to display the correct error message for YesNo dropdown
 * instead of `must be a `boolean` type, but the final value was: `""``
 */
export const toBoolean = (value: unknown) => {
  if (isBoolean(value)) return value;
  if (isString(value) && ['true', 'false'].includes(value)) return value === 'true';
  return undefined;
};

export const getApiToBoolean = (useCustomErrorMessage: boolean) => (value: unknown) => {
  if (!useCustomErrorMessage) {
    return value;
  }
  return toBoolean(value);
};
