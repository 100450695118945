export const batchRejectCodes: Record<string, string> = {
  '001': 'INVALID ACCOUNT LENGTH',
  '002': 'ACCOUNT NUMBER NOT NUMERIC',
  '003': 'INVALID ACCOUNT NUMBER',
  '004': 'MERCHANT DOES NOT ACCEPT PLAN',
  '005': 'INVALID CHECK DIGIT ON ACCT NUMBER',
  '006': 'INVALID ACCOUNT RANGE WITHIN TYPE',
  '007': 'PAYMENT TRANSACTION ON DEBIT CARD',
  '008': 'INVALID ACCOUNT FOR TRANSACTION CODE',
  '009': 'TRANSACTION AMOUNT NOT NUMERIC',
  '010': 'TRANSACTION AMOUNT EQUAL ZERO',
  '011': 'INVALID MERCHANT NUMBER',
  '012': 'PURCHASE DATE NOT NUMERIC',
  '013': 'INVALID PURCHASE DATE',
  '014': 'PURCHASE DATE EXCEEDS PROCESS DATE',
  '015': 'PURCHASE DATE EXCEEDS AGE LIMIT',
  '016': 'INVALID TRANSACTION CODE',
  '017': 'MERCHANT NUMBER NOT NUMERIC',
  '018': 'MERCHANT NOT ON FILE',
  '019': 'INVALID CHECK DIGIT ON MERCHANT ACCT',
  '020': 'MERCHANT CODED AS CLOSED',
  '021': 'MERCHANT CODED AS FRAUD',
  '022': 'MERCHANT CODED AS DO NOT POST',
  '023': 'MERCHANT CODED AS DO NOT AUTHORIZE',
  '024': 'MERCHANT INFORMATION MISSING',
  '025': 'MERCHANT DBA NAME MISSING',
  '026': 'MERCHANT DBA CITY MISSING',
  '027': 'MERCHANT DBA STATE MISSING',
  '028': 'MERCHANT RECORD NOT FOUND',
  '029': 'INVALID CLASS/SIC CODE',
  '030': 'MERCHANT REFERENCE NUMBER NT NUMERIC',
  '031': 'MERCHANT DISCOUNT AMOUNT NOT NUMERIC',
  '032': 'MERCHANT DISCOUNT AMT EXCEEDS LIMIT',
  '033': 'MERCHANT NET DEPOSIT NOT NUMERIC',
  '034': 'DEPARTMENT CODE NOT IN INT. TABLES',
  '035': 'DEPARTMENT CODE NOT NUMERIC',
  '036': 'PAYMENT PRESENTED ON OUTGOING ACCT',
  '037': 'REQUEST ON INVALID ACCOUNT',
  '038': 'REQUEST ON ON-US TRANSACTION',
  '039': 'INVALID REFERENCE NUMBER',
  '040': 'INVALID REASON CODE',
  '041': 'CONFIRMATION ON ON-US ACCOUNT',
  '042': 'CONFIRMATION ON OUTGOING TRANSACTION',
  '043': 'REFERENCE NUMBER NOT NUMERIC',
  '044': 'INVALID CHECK DIGIT ON REF NUMBER',
  '045': 'INVALID STATEMENT DATE',
  '046': 'STATEMENT DATE NOT NUMERIC',
  '047': 'CONTROL NUMBER NOT NUMERIC',
  '048': 'REFERENCE NUMBER HAS IMBEDED BLANKS',
  '049': 'OUTGOING TRANSACTION IS NOT VALID',
  '050': 'ON-US TRANSACTION IS NOT VALID',
  '051': 'CLASS/SIC CODE INVALID FOR CASH',
  '052': 'CLASS/SIC CODE VALID ONLY FOR CASH',
  '053': 'INVALID ATTRIBUTE CODE',
  '054': 'INVALID DATE IN REFERENCE NUMBER',
  '055': 'INVALID USAGE CODE',
  '056': 'INVALID ISSUING ICA NUMBER',
  '057': 'INVALID ACQUIRING ICA NUMBER',
  '058': 'BANK DOES NOT ACCEPT PLAN',
  '059': 'TRANSACTION CODE INVALID FOR BATCH TYPE',
  '060': 'INVALID MERCHANT PLAN',
  '061': 'INVALID OUTCLEAR REFERENCE NUMBER',
  '062': 'INVALID MERCHANT ZIP CODE',
  '063': 'INVALID AUTHORIZATION CODE',
  '064': 'TMP INFORMATION INVALID',
  '065': 'NO ACCOUNT FOUND',
  '066': 'TRANSFERED ACCOUNT',
  '067': 'USER OPTION',
  '068': 'UNMATCHED REVERSAL',
  '069': 'CLOSED ACCOUNT',
  '070': 'COMB. DESC. INVALID FOR OUTGOING TRANS.',
  '071': 'UNABLE TO CONVERT CURRENCY',
  '072': 'NOT CURRENTLY USED',
  '073': 'INVALID PROVINCE CODE',
  '074': 'INVALID MERCHANT COUNTRY CODE',
  '075': 'INVALID SERVICE LEVEL',
  '076': 'INVALID AUTHORIZATION SOURCE',
  '077': 'INVALID POS TERMINAL CAPABILITY',
  '078': 'INVALID TERMINAL ENTRY MODE',
  '079': 'INVALID CARDHOLDER ID METHOD',
  '080': 'INVALID ORIGIN CITY/AIRPORT CODE',
  '081': 'INVALID CARRIER CODE (AIRLINE ITINERARY)',
  '082': 'INVALID SERVICE CLASS (AIRLINE ITINERARY)',
  '083': 'INVALID STOPOVER CODE (AIRLINE ITINERARY)',
  '084': 'INVALID PASSENGER NAME',
  '085': 'INVALID DESTINATION CITY/AIRPORT CODE',
  '086': 'RESERVED FOR FUTURE USE',
  '087': 'VISAPHONE OUTGOING MUST BE A CHARGEBACK',
  '088': 'M-PHONE OUTGOING SALES/CREDIT NOT VALID',
  '089': 'M-PHONE CHRGBACK INVALID ARN OR AQU. ICA',
  '090': 'UNIQUE TRANSACTION WITH INVALID MCC/SIC',
  '091': 'INVALID CHARGEBACK REASON FOR EPS TRANS.',
  '092': 'INVALID CHARGEBACK REASON FOR T&&E TRAN.',
  '093': 'CARD ACCEPTOR ID NOT ALPHANUMERIC',
  '094': 'INVALID CHARGEBACK REASON FOR NON T&&E.',
  '095': 'REQUEST/CONFIRMATION INVALID ON ATM TRANS',
  '096': 'INVALID AIRLINE TICKET NUMBER',
  '097': 'INVALID DEPARTURE DATE',
  '098': 'CARDHOLDER BANK NOT FOUND',
  '099': 'INVALID ACI FOR RPS (CPS)',
  '100': 'INVALID C/H ACTIVATED TERMINAL INDICATOR',
  '101': 'INVALID TRANS ID / BANKNET REF #',
  '102': 'INVALID AUTH CURRENCY (CPS)',
  '103': 'INVALID AUTH AMOUNT (CPS)',
  '104': 'VALIDATION CODE IS BLANK (CPS)',
  '105': 'INVALID AUTH RESPONSE (CPS)',
  '106': 'INVALID AUTHORIZATION DATE',
  '107': 'INVALID TERMINAL ENTRY MODE FOR RPS (CPS)',
  '108': 'INVALID MERCHANT ZIP (CPS)',
  '109': 'INVALID TERMINAL CAPABILITY (CPS)',
  '110': 'INVALID CARDHOLDER ID METH. FOR RPS (CPS)',
  '111': 'INVALID CARD ACCEPTOR (CPS)',
  '112': 'INVALID AUTHORIZATION SOURCE (CPS)',
  '113': 'INVALID AUTHORIZATION CODE (CPS)',
  '114': 'INVALID VISA BIN',
  '115': 'ANNUAL FEE PREVIOUSLY REVERSED',
  '116': 'INVALID EXCLUDE TRANSACTION ID REASON',
  '117': 'INVALID RIS INDICATOR',
  '118': 'INVALID MERCHANT INDICATOR',
  '119': 'TRANSACTION CATEGORY NOT ACCEPTED',
  '120': 'PAYMENT APPLICATION ROUTINE NOT DEFINED',
  '121': 'MCC/SIC CODE INVALID FOR ATM CASH ITEMS',
  '122': 'MCC 6011 VALID ONLY FOR ATM CASH ITEMS',
  '123': 'INVALID AVS RESPONSE CODE FOR ACI=V (CPS)',
  '124': 'INVALID RESTRICTED TICKET IDENTIFIER',
  '125': 'INVALID NO SHOW',
  '126': 'INVALID EXTRA CHARGES',
  '127': 'INVALID CHECK IN OR CHECK OUT DATE',
  '128': 'INVALID SALES TAX',
  '129': 'RECOVERY CREDIT EXCEPTION',
  '130': 'INVALID CARD ACTIVATED TERMINAL INDICATOR',
  '131': 'INVALID PREPAID CARD INDICATOR',
  '132': 'INVALID ATM ACCOUNT SELECTION ID',
  '133': 'INVALID ADJUSTMENT PROCESSING ID',
  '134': 'MULTIPLE CLEARING NUM/SEQ NOT NUMERIC',
  '135': 'RPS/EXT. RECORD FAILURE',
  '136': 'INVALID AMEX/DISC/JCB MERCHANT NUMBER',
  '137': 'INVALID CONTROL SEQUENCE NUMBER',
  '138': 'INVALID AUTHORIZATION RESPONSE CODE',
  '139': 'INVALID AMEX FOOD AMOUNT',
  '140': 'INVALID AMEX PCID',
  '141': 'INVALID AMEX DESCRIPTOR CODE',
  '142': 'INVALID REQUESTED PAYMENT SERVICE IND CPS',
  '143': 'INVALID MRKT SPECIFIC DATA FOR RPS (CPS)',
  '144': 'PT ITINERARY DATA REQUIRED FOR CPS/PT CPS',
  '145': 'INVALID PRIME ENTRY',
  '146': 'INVALID DISCLOSURE GROUP',
  '147': 'INVALID BALANCE STRUCTURE',
  '148': 'INVALID CUSTOMER CODE',
  '149': 'INVALID FREIGHT AMOUNT',
  '150': 'INVALID DESTINATION ZIP CODE',
  '151': 'INVALID MERCHANT TYPE',
  '152': 'INVALID DUTY AMOUNT',
  '153': 'INVALID PRODUCT CODE',
  '154': 'INVALID ITEM DESCRIPTION',
  '155': 'INVALID QUANTITY',
  '156': 'INVALID EXTENDED ITEM AMOUNT',
  '157': 'INVALID HOTEL FOLIO NUMBER',
  '158': 'INVALID PROPERTY TELEPHONE NUMBER',
  '159': 'INVALID CUSTOMER SERVICE 800 NUMBER',
  '160': 'INVALID CAR RENTAL AGREEMENT NUMBER',
  '161': 'INVALID CAR RENTAL RENTER NAME',
  '162': 'INVALID CAR RENTAL RETURN CITY',
  '163': 'INVALID CAR RENTAL RETURN STATE/CTRY',
  '164': 'INVALID CAR RENTAL RETURN DATE',
  '165': 'INVALID ITEM UNIT OF MEASURE',
  '166': 'INVALID AMOUNT IN CURRENCY OF TRANSACTION',
  '167': 'INVALID CURRENCY CODE',
  '168': 'DUPLICATE MERCHANT DEPOSIT',
  '169': 'INVALID NATIONAL/ALTERNATE TAX INDICATOR',
  '170': 'INVALID MOTO TRANS FOR CPS/RETAIL KEYED',
  '171': 'INVALID DOC INDICATOR VALUE',
  '172': 'INACTIVE ACCOUNT',
  '173': 'INVALID MERCHANT TAX ID',
  '174': 'FLEET OIL COMP BRAND NAME INVALID',
  '175': 'FLEET MERCHANT ADDRESS INVALID',
  '176': 'FLEET MERCHANT POSTAL CODE INVALID',
  '177': 'FLEET PURCHASE TIME INVALID',
  '178': 'FLEET FUEL PROD CODE INVALID',
  '179': 'FLEET FUEL UNIT PRICE INVALID',
  '180': 'FLEET FUEL UNIT OF MEASURE INVALID',
  '181': 'FLEET FUEL QUANTITY INVALID',
  '182': 'FLEET FUEL SALE AMOUNT INVALID',
  '183': 'FLEET COUPON DISCOUNT AMT INVALID',
  '184': 'FLEET PRODUCT CODE INVALID',
  '185': 'FLEET EXTENDED ITEM AMOUNT INVALID',
  '186': 'FLEET DR/CR INDICATOR INVALID',
  '187': 'FLEET INVALID TYPE OF PURCHASE',
  '188': 'INVALID LOCAL TAX/LOCAL TAX INCL COMBO',
  '189': 'INCOMPLETE TRANSFER',
  '190': 'DECONVERT REJECT',
  '191': 'INVALID RETAIL TRANSACTION INDICATOR',
  '192': 'INVALID EXPIRATION DATE',
  '193': 'TLP REJECT',
  '194': 'INVALID SKU',
  '195': 'UNMATCHED COMPANY NUMBER',
  '196': 'INVALID CAR RENTAL LOCATOR ID',
  '197': 'EXCESSIVE CREDITS',
  '198': 'GIFTCARD',
  '199': 'THEAD/MHED6 CURRENCY CODE DO NOT MATCH',
  '200': 'NO MATCHING AUTHORIZATION RECORD',
  '201': 'MC-O INVALID RETRIEVAL REQUEST RES',
  '202': 'AMOUNT INCORRECTLY ROUNDED',
  '203': 'NO ADDENDUM DATA',
  '204': 'PROCESSING CODE DOES NOT MATCH TRAN CODE',
  '205': 'FUNCTION CODE INVALID',
  '206': 'INVALID TCQ',
  '207': 'INVALID DINERS ACQUIRER NUMBER',
  '208': 'INVALID PROD ENHANCEMENT',
  '209': 'MERCHANT PER ITEM FEES NOT NUMERIC',
  '210': 'INVALID LOCAL ACCEPTANCE TRANSACTION',
  '211': 'RESERVED FOR FUTURE USE',
  '212': 'STATUS BLOCKED',
  '213': 'RESERVED FOR FUTURE USE',
  '214': 'INVALID PAYMENT SERVICE INDICATOR (REPS)',
  '215': 'INVALID CHARGEBACK CURRENCY CODE',
  '216': 'INVALID CB AMOUNT IN TRANSACTION CURRENCY',
  '217': 'INVALID MERCHANT ID FOR CAT LEVEL',
  '218': 'INVALID INTERNATIONAL FEE INDICATOR',
  '219': 'INVALID SETTLEMENT FLAG',
  '220': 'INCORRECT MERCHANT ELIGIBILITY FLAG',
  '221': 'INVALID IRF INDICATOR',
  '222': 'IVA ON MERCHANT PER ITEM FEES NOT NUMERIC',
  '223': 'IVA ON MERCHANT DISCOUNT NOT NUMERIC',
  '224': 'MERCHANT PER ITEM FEES NOT NUMERIC',
  '225': 'STATUS DROPPED',
  '226': 'PREPAID POSTING REJECT',
  '227': 'INVALID SECURITIZED ITEM',
  '228': 'RESERVED FOR FUTURE USE',
  '229': 'INVALID TCAT',
  '230': 'INVALID TCAT/TRAN CODE COMBINATION',
  '231': 'INVALID CASH BACK AMOUNT',
  '232': 'INVALID TRAN CODE FOR CPD',
  '233': 'INVALID POS DATA CODE',
  '234': 'INVALID MOTOR FUEL SERVICE TYPE',
  '235': 'INVALID DISCOVER IIN',
  '236': 'INVALID PROCESSING CODE',
  '237': 'INVALID TRACK DATA CONDITION CODE',
  '238': 'EXCESSIVE TRAN AMT',
  '239': 'LID LEVEL 3 NOT MATCH',
  '240': 'FLEET FUEL DRIVER/ID NUMBER INVALID',
  '241': 'INVALID STORE ID',
  '242': 'NOT VALID FOR RTC',
  '243': 'MERCH BATCH WITHOUT DETAIL TRANSACTIONS',
  '244': 'INVALID DCC TRANSACTION',
  '245': 'DUPLICATE TXN',
  '246': 'EXCESSIVE DUPLICATE TXN',
  '247': 'OVER FLOOR LIMIT/NO AUTH',
  '248': 'ACCT ON STOPLIST',
  '249': 'RESERVED FOR FUTURE USE',
  '250': 'TXN-CUR NE MER-CUR',
  '251': 'RESERVED FOR FUTURE USE',
  '252': 'RESERVED FOR FUTURE USE',
  '253': 'INVALID AMEX SUBMITTER ID',
  '254': 'ORIG-PROC NOT NUM',
  '255': 'RESERVED FOR FUTURE USE',
  '256': 'RESERVED FOR FUTURE USE',
  '257': 'INVALID COMPONENTS',
  '258': 'RESERVED FOR FUTURE USE',
  '259': 'VTM ONLY CARD',
  '260': 'RESERVED FOR FUTURE USE',
  '261': 'RESERVED FOR FUTURE USE',
  '262': 'RESERVED FOR FUTURE USE',
  '263': 'INV. GAMBLING C.V.',
  '264': 'INV. TXN FOR CFT MER',
  '265': 'INV. MCC FOR CFT TXN',
  '266': 'INV. ISSUER FOR CFT',
  '267': 'TXN AMT>CFT LIMIT',
  '268': 'DAILY CFT LIMIT',
  '269': 'INVALID BIN REGION',
  '270': 'CV AMT >= MAX AMT',
  '271': 'CV/ACCT >= MAX AMT',
  '272': 'CV/CNT/VU >= MAX CNT',
  '273': 'CV/AMT/VU >= MAX AMT',
  '274': 'CV/AMT/VU >= MAX PER',
  '275': 'ATM ONLY CARD',
  '276': 'CV AMT <= MIN AMT',
};
