import {Chip} from '@mui/material';
import {StatementReportingJobParams} from '@ozark/common';
import {ReportingPageStyles} from '../Reporting';

interface Props {
  filter: StatementReportingJobParams;
}
export const StatementsFilters = ({filter}: Props) => {
  return (
    <Chip
      sx={ReportingPageStyles.chip}
      label={
        <span>
          <b>MID</b> equals '<b>{filter.mid}</b>'
        </span>
      }
      variant="outlined"
    />
  );
};
