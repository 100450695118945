import type {Firebase} from '.';

const hostport = (env: string): [string, number] => {
  const [host, port] = env.split(/:/) || [];
  return [host, parseInt(port, 10)];
};

/**
 *
 * Defaults:
 * Authentication	    9099
 * Emulator Suite UI	4000
 * Cloud Functions	  5001
 * Realtime Database	9000
 * Cloud Firestore	  8081
 * Cloud Pub/Sub	    8085
 * Firebase Hosting   5000
 *
 */
export const allowEmulators = (firebase: typeof Firebase) => {
  if (
    !(window.location.hostname.startsWith('local') || window.location.hostname === 'localhost') ||
    process.env.REACT_APP_USE_REMOTE === 'true'
  ) {
    return;
  }
  firebase.auth.useEmulator(process.env.REACT_APP_EMULATOR_AUTH || 'http://localhost:9099');
  if (['3002', '5000', '5005'].includes(window.location.port)) {
    firebase.firestore.settings({merge: true, experimentalForceLongPolling: true});
  }
  firebase.firestore.useEmulator(
    ...hostport(process.env.REACT_APP_EMULATOR_FIRESTORE || 'localhost:8081')
  );
  firebase.functions.useEmulator(
    ...hostport(process.env.REACT_APP_EMULATOR_FUNCTIONS || 'localhost:5001')
  );
  firebase.storage.useEmulator(
    ...hostport(process.env.REACT_APP_EMULATOR_STORAGE || 'localhost:9199')
  );
};
