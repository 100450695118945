import {ScheduleA} from './ScheduleA';
import {ScheduleAProps} from './types/ScheduleAProps';

export const GroupScheduleA = ({
  document,
  parentScheduleAParams,
  set,
  applyDefaults = false,
  readonly = false,
  allowNegatives = false,
  riskTogglesEnabled = true,
  displayCalculationMethod = false,
  disableAgentCalculationMethod = false,
}: ScheduleAProps) => {
  return (
    <ScheduleA
      document={document}
      set={set}
      disableAgentCalculationMethod={disableAgentCalculationMethod}
      allowNegatives={allowNegatives}
      applyDefaults={applyDefaults}
      displayCalculationMethod={displayCalculationMethod}
      parentScheduleAParams={parentScheduleAParams}
      readonly={readonly}
      riskTogglesEnabled={riskTogglesEnabled}
    />
  );
};
