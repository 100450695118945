import {Domain, useApiClient, useGroupFromDomain} from '@ozark/common';
import {createContainer} from 'unstated-next';
import {useAuthProfile} from '../hooks/useAuthProfile';
import {useAuthUser} from '../hooks/useAuthUser';

const useStoreBase = () => {
  const groupStore = useGroupFromDomain(Domain.portalDomain);
  const authUserStore = useAuthUser(groupStore);
  const agentProfileStore = useAuthProfile(authUserStore);
  const httpClientStore = useApiClient(authUserStore.authUser);

  return {
    ...authUserStore,
    ...agentProfileStore,
    ...groupStore,
    ...httpClientStore,
  };
};

const Store = createContainer(useStoreBase);

export {Store};
