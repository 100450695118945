import {Grid, MenuItem, TextField as MuiTextField} from '@mui/material';
import {ApplicationView, ShippingSpeed, ShippingType, StateArray, StateHash} from '@ozark/common';
import {AutoCompleteAddressTextField, Select, TextField} from '@ozark/common/components';
import {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {PricingEquipmentFormInput} from '../PricingEquipmentSchema';
import {useApplicationShippingAddresses} from './useApplicationShippingAddresses';

type Props = {
  readonly?: boolean;
  application: ApplicationView;
};

export const PricingEquipmentShipping = ({readonly, application}: Props) => {
  const [chosenAddress, setChosenAddress] = useState<string>(
    application.shipping?.select ?? 'businessAddress'
  );

  const {
    watch,
    setValue,
    control,
    formState: {errors},
  } = useFormContext<PricingEquipmentFormInput>();
  const watchShippingOther = watch('shipping.other');
  const watchShippingSpeed = watch('equipment.shippingSpeed');
  const watchEquipmentIsGateway = watch('equipment.isGateway');
  const watchEquipmentIsVarSheet = watch('equipment.isVarSheet');
  const {shippingAddresses, agentAddress} = useApplicationShippingAddresses(application);

  const isLocalPickupVisible = watchShippingSpeed === ShippingSpeed.na;
  const isLocalPickupOnly =
    isLocalPickupVisible && !watchEquipmentIsGateway && !watchEquipmentIsVarSheet;

  useEffect(() => {
    if (application?.shipping?.select) {
      setChosenAddress(application?.shipping?.select);
    }
  }, [setChosenAddress]);

  useEffect(() => {
    if (watchEquipmentIsGateway) {
      setChosenAddress('');
      setValue('shipping.select', '', {shouldDirty: true});
      setValue('shipping.other', undefined, {shouldDirty: true});
      setAddress();
    }
  }, [watchEquipmentIsGateway]);

  useEffect(() => {
    if (watchShippingSpeed === ShippingSpeed.na) {
      setChosenAddress('');
      setValue('shipping.select', '', {shouldDirty: true});
      setValue('shipping.other', undefined, {shouldDirty: true});
      setAddress();
    }
  }, [watchShippingSpeed]);

  const setAddress = useCallback(
    (address1?: string, address2?: string, city?: string, state?: string, zip?: string) => {
      setValue('shipping.address1', address1 ?? '', {shouldDirty: true});
      setValue('shipping.address2', address2 ?? '', {shouldDirty: true});
      setValue('shipping.city', city ?? '', {shouldDirty: true});
      setValue('shipping.state', state ?? '', {shouldDirty: true});
      setValue('shipping.zip', zip ?? '', {shouldDirty: true});
    },
    [setValue]
  );

  const handleShippingAddressAutoFill = (city: any, state: any, zip: any) => {
    setValue('shipping.address2', '', {shouldDirty: true});
    setValue('shipping.city', city ?? '', {shouldDirty: true});
    setValue('shipping.state', state ?? '', {shouldDirty: true});
    setValue('shipping.zip', zip ?? '', {shouldDirty: true});
  };

  const handleShippingChange = (event: ChangeEvent<{value: unknown}>) => {
    const shippingChoice = event.target.value as ShippingType;
    setChosenAddress(shippingChoice);
    setValue('shipping.select', shippingChoice as any, {shouldDirty: true});
    switch (shippingChoice) {
      case ShippingType.agentAddress:
        if (!agentAddress) break;
        const {address, address2, city, state, zipCode} = agentAddress;
        setAddress(address, address2, city, state, zipCode);
        break;
      case ShippingType.businessAddress:
        const {businessAddress1, businessAddress2, businessCity, businessState, businessZip} =
          application;
        setAddress(businessAddress1, businessAddress2, businessCity, businessState, businessZip);
        break;
      case ShippingType.mailingAddress:
        const {mailingAddress1, mailingAddress2, mailingCity, mailingState, mailingZip} =
          application;
        setAddress(mailingAddress1, mailingAddress2, mailingCity, mailingState, mailingZip);
        break;
      case ShippingType.homeAddress:
        const {homeAddress1, homeAddress2, homeCity, homeState, homeZip} = application;
        setAddress(homeAddress1, homeAddress2, homeCity, homeState, homeZip);
        break;
      case ShippingType.localPickup:
        setAddress();
        break;
      case 'other':
        setAddress();
        break;
    }
    if (shippingChoice === ShippingType.other) {
      setValue('shipping.other', true, {shouldDirty: true});
    } else {
      setValue('shipping.other', false, {shouldDirty: true});
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Select
          name="equipment.shippingSpeed"
          label="Shipping Speed"
          errors={errors}
          control={control}
          disabled={readonly}
          options={Object.values(ShippingSpeed)}
        />
      </Grid>
      <Grid item xs={12}>
        <MuiTextField
          name="whereto"
          label="Where to Ship?"
          value={chosenAddress}
          onChange={handleShippingChange}
          select
          variant="outlined"
          margin="normal"
          fullWidth
          disabled={readonly}
        >
          {shippingAddresses
            .filter(x => {
              if (x.key === ShippingType.localPickup) {
                return isLocalPickupVisible || isLocalPickupOnly;
              }

              return !isLocalPickupOnly;
            })
            .map(addr => (
              <MenuItem key={addr.key} value={addr.key}>
                {addr.value}
              </MenuItem>
            ))}
        </MuiTextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="shipping.attention"
          label="Attention"
          disabled={!watchShippingOther || readonly}
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <AutoCompleteAddressTextField
          setAutofillHandler={handleShippingAddressAutoFill}
          name="shipping.address1"
          label="Address Line 1"
          disabled={!watchShippingOther || readonly}
          required
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="shipping.address2"
          label="Address Line 2"
          disabled={!watchShippingOther || readonly}
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          name="shipping.city"
          label="City"
          required
          disabled={!watchShippingOther || readonly}
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Select
          name="shipping.state"
          label="State"
          required
          disabled={!watchShippingOther || readonly}
          errors={errors}
          control={control}
        >
          {StateArray.sortAndMap(
            state => (
              <MenuItem key={`${state}2`} value={state}>
                {StateHash[state]}
              </MenuItem>
            ),
            state => StateHash[state]
          )}
        </Select>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          name="shipping.zip"
          label="Zip Code"
          required
          disabled={!watchShippingOther || readonly}
          errors={errors}
          control={control}
          transform={{
            pattern: '99999',
          }}
        />
      </Grid>
    </Grid>
  );
};
