import {Box, CircularProgress, Fade} from '@mui/material';
import {BoxCentered} from '../common';

type PropsContainer = {
  children: React.ReactElement<any, any>;
  minHeight: number;
  loading?: boolean;
  overlay?: boolean;
};

export const LoadingContainer = ({children, minHeight, loading}: PropsContainer) => {
  return (
    <Box position="relative" minHeight={minHeight}>
      {!loading && (
        <Fade in timeout={500}>
          <div>{children}</div>
        </Fade>
      )}
      {loading && (
        <BoxCentered position="absolute" top={0} left={0} width="100%" height="100%">
          <CircularProgress />
        </BoxCentered>
      )}
    </Box>
  );
};
