import firebase from 'firebase/compat/app';
import 'firebase/compat/app-check';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import {allowEmulators} from './firebase-emulator';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

try {
  firebase.initializeApp(config);
  firebase.firestore().settings({
    merge: true,
    ignoreUndefinedProperties: true,
  });
  if (process.env.REACT_APP_APP_CHECK_KEY_ID) {
    const appCheck = firebase.appCheck();
    appCheck.activate(
      new firebase.appCheck.ReCaptchaEnterpriseProvider(process.env.REACT_APP_APP_CHECK_KEY_ID),
      true
    );
    console.log('AppCheck activated.');
  } else {
    console.log('AppCheck cannot be activated because of a missing key ID.');
  }
} catch (err) {
  console.warn(err);
}

export type TimestampType = firebase.firestore.Timestamp;

const {Timestamp} = firebase.firestore;

const Firebase = {
  auth: firebase.auth(),
  Persistence: firebase.auth.Auth.Persistence,
  firebase,
  firestore: firebase.firestore(),
  functions: firebase.functions(),
  storage: firebase.storage(),
  FieldValue: {
    delete: firebase.firestore.FieldValue.delete,
    arrayRemove: firebase.firestore.FieldValue.arrayRemove,
    arrayUnion: firebase.firestore.FieldValue.arrayUnion,
    now: firebase.firestore.FieldValue.serverTimestamp,
    increment: firebase.firestore.FieldValue.increment,
  },
  FieldPath: {
    documentId: firebase.firestore.FieldPath.documentId,
  },
  Timestamp,
  now: Timestamp.now,
  apiKey: config.apiKey!,
};

allowEmulators(Firebase);

export {Firebase};
