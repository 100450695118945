import {TicketsListFilter} from '../common/TicketListFilter';
import {useTicketsFiltersContainer} from '../hooks/useTicketsFiltersContainer';
import {useTicketTypes} from '../hooks/useTicketTypes';

export function TicketsListFilterType() {
  const {ticketTypes} = useTicketTypes();
  const {ticketTypeFilter, setTicketTypeFilter, ticketCategoryFilter, setTicketCategoryFilter} =
    useTicketsFiltersContainer();
  const categoryOptions = ticketTypeFilter
    ? ticketTypes.data?.find(tt => tt.name === ticketTypeFilter)?.categories ?? []
    : [];

  const handleChangeTicketTypeFilter = (value: string | undefined) => {
    if (value === undefined) setTicketCategoryFilter(value);
    setTicketTypeFilter(value);
  };

  return (
    <>
      <TicketsListFilter
        options={ticketTypes.data?.map(tt => tt.name) ?? []}
        label="Ticket Type"
        value={ticketTypeFilter}
        valueDefault="All Types"
        handleChange={handleChangeTicketTypeFilter}
      />
      {ticketTypeFilter && (
        <TicketsListFilter
          options={categoryOptions}
          label="Ticket Category"
          valueDefault="All Categories"
          value={ticketCategoryFilter}
          handleChange={setTicketCategoryFilter}
        />
      )}
    </>
  );
}
