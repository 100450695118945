import {Firebase} from '..';

import {Application, Collections, selectApplicationViewAdapter} from '../..';

export const getApplicationByMid = async (
  mid: string,
  agentId?: string,
  groupId?: string
): Promise<Application | null> => {
  let query = Firebase.firestore
    .collection(Collections.applications)
    .where('mid', '==', mid)
    .limit(1);

  if (agentId) {
    query = query.where('associatedAgents', 'array-contains', agentId);
  }

  if (groupId) {
    query = query.where('group.id', '==', groupId);
  }

  const snapshot = await query.get();

  if (snapshot.empty) {
    return null;
  }

  return selectApplicationViewAdapter(snapshot.docs[0]);
};
