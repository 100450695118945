export const ChargebackStatusType: {[_: number]: string} = {
  0: 'N/A',
  1: 'New Case - First Chargeback',
  2: 'Case Update - Reversal',
  3: 'Case Update - PreArbitration',
  4: 'Case Update - Arbitration',
  5: 'Case Update',
  6: 'Fulfilled Documentation Request - Retrieval',
  7: 'Retrieval Request Update',
  8: "Dispute Resolved in Merchant's Favor - First Chargeback",
  9: "Dispute Resolved in Merchant's Favor - Reversal",
  10: "Dispute Resolved in Merchant's Favor - PreArbitration",
  11: "Dispute Resolved in Merchant's Favor - Arbitration",
  12: "Dispute Resolved In Merchant's Favor",
  13: "Dispute Resolved in Cardholder's Favor - First Chargeback",
  14: "Dispute Resolved in Cardholder's Favor - Reversal",
  15: "Dispute Resolved in Cardholder's Favor",
  16: "Dispute Resolved in Cardholder's Favor - PreArbitration",
  17: "Dispute Resolved in Cardholder's Favor - Arbitration",
  18: 'Case Correction/Update - First Chargeback',
  19: 'Case Correction/Update - Retrieval',
  20: 'Case Correction/Update - Chargeback',
  21: 'Case Correction/Update - Allocation',
  22: 'Case Correction/Update',
  23: 'New Retrieval Request',
  24: 'Second Retrieval Request',
  25: 'Outgoing PreArb - Reversal',
  26: 'PreArbitration',
  27: 'Arbitration',
  28: 'Collection - Exceeded Cardbrand Time Frame',
  29: 'Outgoing Compliance Case',
};
