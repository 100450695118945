import {Box} from '@mui/material';
import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {TICKET_TYPES_MANAGER_ROUTE} from '../../constants/routes';
import {useUserInfo} from '../../hooks/useUserInfo';
import {Breadcrumbs, useBreadcrumbsContainer} from '../common/Breadcrumbs';
import {TicketTypeCreateButton} from './TicketTypes/TicketTypeCreateButton';
import {TicketTypesList} from './TicketTypes/TicketTypesList';

export const TicketTypes = () => {
  const {isErpAdmin} = useUserInfo();
  const history = useHistory();
  const {setBreadcrumbs} = useBreadcrumbsContainer();

  useEffect(() => {
    if (!isErpAdmin) {
      history.push(`/tickets`);
    }
  }, [isErpAdmin]);

  useEffect(() => {
    setBreadcrumbs([{text: 'Ticket Types', url: TICKET_TYPES_MANAGER_ROUTE}]);
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Breadcrumbs>
        <Box flex={1} mx={2} />
        <TicketTypeCreateButton />
      </Breadcrumbs>
      <Box display="flex" my={2} height="100%" flex={1} position="relative">
        <TicketTypesList />
      </Box>
    </Box>
  );
};
