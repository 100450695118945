export const IncompleteIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g fill={'inherit'}>
        <path
          d="M384,128H272c-8.8,0-16-7.2-16-16V0H24C10.8,0,0,10.7,0,23.9c0,0,0,0,0,0V488c0,13.2,10.7,24,23.9,24
		c0,0,0,0,0,0H360c13.2,0,24-10.7,24-23.9c0,0,0,0,0,0V128z M192,192c35.3,0,64,28.7,64,64s-28.7,64-64,64s-64-28.7-64-64
		S156.7,192,192,192z M304,428.8c0,10.6-10,19.2-22.4,19.2H102.4C90,448,80,439.4,80,428.8v-19.2c0-31.8,30.1-57.6,67.1-57.6h5
		c25.5,10.7,54.2,10.7,79.7,0h5c37.1,0,67.2,25.8,67.2,57.6V428.8z"
          opacity={0.4}
        />
        <path
          d="M377,105L279.1,7c-4.5-4.5-10.6-7-17-7H256v112c0,8.8,7.2,16,16,16h112v-6.1C384,115.6,381.5,109.5,377,105z
		 M192,320c35.3,0,64-28.7,64-64s-28.7-64-64-64s-64,28.7-64,64S156.7,320,192,320z M236.8,352h-5c-25.5,10.7-54.2,10.7-79.7,0h-5
		c-37,0-67.1,25.8-67.1,57.6v19.2c0,10.6,10,19.2,22.4,19.2h179.2c12.4,0,22.4-8.6,22.4-19.2v-19.2C304,377.8,273.9,352,236.8,352z"
        />
      </g>
    </svg>
  );
};
