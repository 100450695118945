import {Typography} from '@mui/material';
import {Column} from '@ozark/common/api/Column';
import {ButtonExportCsv, Table} from '@ozark/common/components';
import {MidsByChargebacksInfo} from '@ozark/functions/src/functions/express/private/types/Chargebacks';
import {useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useApiContainer} from '../../..';
import {formatAsPercent} from '../../../util';
import {LoadingStatus} from '../common/LoadingStatus';
import {ReportingMonthTitle} from '../common/ReportingMonthTitle';
import {useDataSorted} from '../common/useDataSorted';
import {useQueryMonthYear} from '../common/useQueryMonthYear';

type PropSorted = Pick<MidsByChargebacksInfo, 'chargebacks_visa_count' | 'chargebacks_mc_count'>;

const TITLE = "Top 10 MID's by Chargebacks";

export const MidsByChargebacksTable = ({
  breadcrumbs,
  setBreadcrumbs,
}: {
  breadcrumbs: React.ReactNode[];
  setBreadcrumbs: (els: React.ReactNode[]) => void;
}) => {
  const api = useApiContainer();
  const history = useHistory();
  const {path} = useRouteMatch();
  const {year, month} = useQueryMonthYear();
  const [data, setData] = useState<MidsByChargebacksInfo[]>([]);
  const {dataSorted, sort, hasData, handleRetrieveData} = useDataSorted<PropSorted>(data);
  const [loading, setLoading] = useState(true);

  const totalVisaCBCountForMonth = data.reduce(
    (acc, next) => acc + (next.chargebacks_visa_count ?? 0),
    0
  );
  const totalMCCBAmountForMonth = data.reduce(
    (acc, next) => acc + (next.chargebacks_mc_amount ?? 0),
    0
  );

  useEffect(() => {
    setBreadcrumbs([<Typography variant="body1">{TITLE}</Typography>]);
  }, [setBreadcrumbs]);

  useEffect(() => {
    if (Boolean(year && month)) {
      setLoading(true);
      api?.chargebacks
        .getMidsByChargebacks(year, month)
        .then(result => {
          setLoading(false);
          setData(result || []);
        })
        .catch(err => {
          setLoading(false);
          console.error(err);
        });
    } else {
      setData([]);
    }
  }, [year, month]);

  const columnsConfig = getColumnsConfig(totalVisaCBCountForMonth, totalMCCBAmountForMonth);

  return (
    <>
      <ReportingMonthTitle breadcrumbs={breadcrumbs}>
        <ButtonExportCsv
          columnsConfig={columnsConfig}
          disabled={loading}
          filename="mids-by-chargebacks"
          rows={data}
        />
      </ReportingMonthTitle>
      {(loading || !hasData) && <LoadingStatus loading={loading} hasData={hasData} />}
      {!loading && hasData && (
        <div>
          <Table
            columns={columnsConfig}
            data={{
              sort,
              data: dataSorted as any, // todo: remove any and fix type errors
              limit: 10,
              offset: 0,
              totalCount: 10,
            }}
            onRetrieveData={handleRetrieveData}
            onRowClick={({mid}) => history.push(`${path}/${mid}?year=${year}&month=${month}`)}
          />
        </div>
      )}
    </>
  );
};

const getColumnsConfig = (
  totalVisaCBCountForMonth: number,
  totalMCCBAmountForMonth: number
): Column<MidsByChargebacksInfo>[] => [
  {
    id: 'mid',
    numeric: false,
    sortable: false,
    label: 'MID',
    export: true,
  },
  {
    id: 'dba_name',
    numeric: false,
    sortable: false,
    label: 'DBA Name',
    selector: row => row.merchant_name || 'n/a',
    export: row => row.merchant_name || 'n/a',
  },
  {
    id: 'chargebacks_visa_count',
    numeric: true,
    sortable: true,
    label: 'Visa CB Count',
    selector: row => row.chargebacks_visa_count || '-',
    export: true,
  },
  {
    id: 'chargebacks_visa_percent_by_count',
    numeric: true,
    sortable: false,
    label: 'Total Visa CB Ratio by Count',
    headingTooltip: 'Percentage of Visa chargebacks of all chargebacks for the reporting month.',
    selector: row => formatAsPercent(row.chargebacks_visa_count / totalVisaCBCountForMonth),
    export: row => formatAsPercent(row.chargebacks_visa_count / totalVisaCBCountForMonth),
  },
  {
    id: 'chargebacks_mc_count',
    numeric: true,
    sortable: true,
    label: 'MC CB Count',
    selector: row => row.chargebacks_mc_count || '-',
    export: true,
  },
  {
    id: 'chargebacks_mc_percent_by_count',
    numeric: true,
    sortable: false,
    label: 'Total MC CB Ratio by Volume',
    headingTooltip:
      'Percentage of Mastercard chargebacks of all chargebacks for the reporting month.',
    selector: row => formatAsPercent(row.chargebacks_mc_amount / totalMCCBAmountForMonth),
    export: row => formatAsPercent(row.chargebacks_mc_amount / totalMCCBAmountForMonth),
  },
];
