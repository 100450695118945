import {Box, Divider, Typography} from '@mui/material';
import {useEffect} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {
  AssigneeType,
  Collections,
  createAuditNonce,
  Firebase,
  getAssigneeUid,
  Ticket,
  TicketHistoryRecordType,
  TicketStatus,
  useNotification,
} from '../../..';
import {useUserInfo} from '../../../hooks';
import {Breadcrumbs, useBreadcrumbsContainer} from '../../common/Breadcrumbs';
import {FIELD_NAME_AUTHOR} from '../constants/constants';
import {useHistoryRecord} from '../hooks/useHistoryRecord';
import {useTicketEditContainer} from '../hooks/useTicketEditContainer';
import {useTicketId} from '../hooks/useTicketId';
import {useTicketStatus} from '../hooks/useTicketStatus';
import {TicketEditContent} from '../TicketEditContent/TicketEditContent';
import {TicketEditDetails} from '../TicketEditDetails/Details';
import {TicketsHistory} from '../TicketEditHistory/TicketsHistory';
import {ButtonAssignToMeTicket} from './ButtonAssignToMeTicket';
import {ButtonDeleteTicket} from './ButtonDeleteTicket';
import {WatchingToggle} from './ButtonWatchingToggle';
import {InputStatus} from './InputStatus';

export const TicketEdit = () => {
  const {setIsLoading} = useTicketEditContainer();
  const {isErpAdmin, uid, isPortal, isAgent} = useUserInfo();
  const {isDraft} = useTicketStatus();

  const {ticketId, isTicketIdValid} = useTicketId();
  const author = useWatch({name: FIELD_NAME_AUTHOR});
  const {setBreadcrumbs} = useBreadcrumbsContainer();
  const {reset} = useFormContext();
  const {addHistoryRecord} = useHistoryRecord();

  const showNotification = useNotification();

  useEffect(() => {
    (async () => {
      if (!isTicketIdValid) return;
      try {
        setIsLoading(true);

        const ticketRef = Firebase.firestore.collection(Collections.tickets).doc(ticketId);

        const ticketSnap = await ticketRef.get();

        if (ticketSnap.exists) {
          const ticket = ticketSnap.data() as Ticket;
          if (
            ticket.status === TicketStatus.Open &&
            ticket.assignee?.type !== AssigneeType.erpDepartment &&
            uid &&
            ticket.assignee &&
            getAssigneeUid(ticket.assignee) === uid
          ) {
            ticket.status = TicketStatus.InProgress;
            const auditNonce = createAuditNonce(Firebase.auth.currentUser!.uid);
            await ticketRef.update({status: ticket.status, auditNonce});
            await addHistoryRecord(`Status Change to In Progress`, TicketHistoryRecordType.Status);
          }

          reset(ticket);

          setBreadcrumbs([
            {text: 'Tickets', url: `/tickets/view/${ticket.status}`},
            {text: 'Edit Ticket', url: ''},
          ]);
        }
      } catch (err) {
        console.error(`Couldn't load data for ticket id:`, ticketId, err);
        showNotification('error', 'An error occurred while loading ticket data');
      } finally {
        setIsLoading(false);
      }
    })();
  }, [ticketId]);

  return (
    <form
      noValidate
      autoComplete="off"
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Breadcrumbs>
        <Box flex={1} />
        {!isPortal && !isDraft && <WatchingToggle />}
        <InputStatus />
        {author && uid && <ButtonAssignToMeTicket />}
        {(isDraft || isErpAdmin || (!isAgent && author && uid && author.id === uid)) && (
          <ButtonDeleteTicket />
        )}
      </Breadcrumbs>
      <Box pb={0} pt={2} flex={1} display="flex" flexDirection="column">
        <Box
          flex={1}
          sx={{
            '& .MuiTextField-root': {mb: 3},
            position: 'relative',
          }}
        >
          <Box display="flex" height="100%">
            <Box sx={{width: {lg: '30%', xl: '20%'}, minWidth: 'fit-content'}}>
              <Box display="flex" justifyContent="space-between" alignItems="center" height={34}>
                <Typography variant="body1" sx={{color: 'lightslategrey'}}>
                  Details
                </Typography>
              </Box>
              <Box pt={1}>
                <TicketEditDetails />
              </Box>
            </Box>
            <Divider flexItem orientation="vertical" sx={{mx: 2}} />
            <Box flex={1}>
              <TicketEditContent />
            </Box>
            <Divider
              flexItem
              orientation="vertical"
              sx={{ml: 1, mr: 2, display: {lg: 'none', xl: 'block'}}}
            />
            <Box width="20%" pt={1} sx={{display: {lg: 'none', xl: 'block'}}}>
              <TicketsHistory isDraft={isDraft} />
            </Box>
          </Box>
        </Box>
      </Box>
    </form>
  );
};
