import {DashboardTable, DashTableRow} from '../common/DashboardTable';
import {DataIsLoading} from '../common/DataIsLoading';
import {DataIsMissing} from '../common/DataIsMissing';
import {WidgetProps} from '../common/types';
import {DataFormat} from '../common/utils';

export function WidgetTransactions({loading, data}: WidgetProps) {
  if (loading) return <DataIsLoading />;
  if (!data) return <DataIsMissing />;

  const rows: DashTableRow[] = [
    {
      label: 'Authorization Approval Ratio',
      value: data.authApprovalRatio,
      dataFormat: DataFormat.percent,
    },
    {
      label: 'Authorization Decline Ratio',
      value: data.authDeclinedRatio,
      dataFormat: DataFormat.percent,
    },
    {
      label: 'Largest Transaction Amount',
      value: data.largestTransactionAmount,
      dataFormat: DataFormat.currency,
    },
    {
      label: 'Smallest Transaction Amount',
      value: data.smallestTransactionAmount,
      dataFormat: DataFormat.currency,
    },
    {
      label: 'Total Visa Transactions',
      value: data.totalVisaTransactionsCount,
      dataFormat: DataFormat.number,
    },
    {
      label: 'Total MC Transactions',
      value: data.totalMcTransactionsCount,
      dataFormat: DataFormat.number,
    },
    {
      label: 'Total Amex Transactions',
      value: data.totalAmexTransactionsCount,
      dataFormat: DataFormat.number,
    },
    {
      label: 'Total Discover Transactions',
      value: data.totalDiscoveryTransactionsCount,
      dataFormat: DataFormat.number,
    },
    {
      label: 'PIN Debit Transaction Count',
      value: data.pinDebitTransactionCount,
      dataFormat: DataFormat.number,
    },
    {
      label: 'PIN Debit Volume',
      value: data.pinDebitVolume,
      dataFormat: DataFormat.currency,
    },
    {
      label: 'EBT Transaction Count',
      value: data.ebtTransactionCount,
      dataFormat: DataFormat.number,
    },
    {
      label: 'EBT Volume',
      value: data.ebtVolume,
      dataFormat: DataFormat.currency,
    },
  ];

  return <DashboardTable data={rows} />;
}
