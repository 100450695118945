export const StatementIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g fill="#b2c2cd">
        <path
          d="M384 128H272c-8.8 0-16-7.2-16-16V0H24C10.8 0 0 10.7 0 23.9V488c0 13.2 10.7 24 23.9 24H360c13.2 0 24-10.7 24-23.9V128zM64 72c0-4.4 3.6-8 8-8h80c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H72c-4.4 0-8-3.6-8-8V72zm0 80v-16c0-4.4 3.6-8 8-8h80c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H72c-4.4 0-8-3.6-8-8zm144 263.9V440c0 4.4-3.6 8-8 8h-16c-4.4 0-8-3.6-8-8v-24.3c-11.4-.6-22.3-4.5-31.4-11.4-3.5-2.7-4.1-7.7-1.4-11.2.3-.3.5-.6.8-.9l11.8-11.2c2.8-2.5 7-2.9 10.1-.7 3.8 2.4 8.3 3.7 12.8 3.7h28.1c6.5 0 11.8-5.9 11.8-13.2 0-6-3.6-11.2-8.8-12.7l-45-13.5c-18.6-5.6-31.6-23.4-31.6-43.4 0-24.5 19.1-44.4 42.7-45.1V232c0-4.4 3.6-8 8-8h16c4.4 0 8 3.6 8 8v24.3c11.4.6 22.3 4.5 31.4 11.4 3.5 2.7 4.1 7.7 1.4 11.2-.3.3-.5.6-.8.9L228.2 291c-2.8 2.5-7 2.9-10.1.7-3.8-2.4-8.3-3.7-12.8-3.7h-28.1c-6.5 0-11.8 5.9-11.8 13.2 0 6 3.6 11.2 8.8 12.7l45 13.5c18.6 5.6 31.6 23.4 31.6 43.4-.1 24.5-19.1 44.4-42.8 45.1z"
          opacity={0.4}
        />
        <path d="M377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v112c0 8.8 7.2 16 16 16h112v-6.1c0-6.3-2.5-12.4-7-16.9zM219.1 327.4l-45-13.5c-5.2-1.5-8.8-6.8-8.8-12.7 0-7.3 5.3-13.2 11.8-13.2h28.1c4.5 0 9 1.3 12.8 3.7 3.1 2.1 7.3 1.8 10.1-.7l11.8-11.2c3.2-3 3.4-8.1.3-11.3-.3-.3-.6-.6-.9-.8-9.1-6.8-20-10.8-31.4-11.3V232c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v24.1c-23.6.6-42.7 20.5-42.7 45.1 0 20 13 37.8 31.6 43.4l45 13.5c5.2 1.5 8.8 6.8 8.8 12.7 0 7.3-5.3 13.2-11.8 13.2h-28.1c-4.5 0-9-1.3-12.8-3.7-3.1-2.1-7.3-1.8-10.1.7L144 392.2c-3.2 3-3.4 8.1-.3 11.3.3.3.6.6.9.8 9.1 6.8 20 10.8 31.4 11.3V440c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-24.1c23.7-.6 42.7-20.5 42.7-45.1 0-20-13-37.8-31.6-43.4z" />
      </g>
    </svg>
  );
};
