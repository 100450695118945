import {Alert, Grid} from '@mui/material';
import {MerchantView, useMerchant, useNotification} from '@ozark/common';
import {Loading} from '@ozark/common/components';
import {useState} from 'react';
import {InfoItemsList, TInfoItem} from './InfoItemsList';
import {MerchantProfileForm} from './MerchantProfileForm';

type Props = {
  merchantUid: string;
  displayAlert: boolean;
};

export const MerchantProfile = ({merchantUid, displayAlert}: Props) => {
  const {document, set} = useMerchant(merchantUid);
  const showNotification = useNotification();
  const [isSaving, setIsSaving] = useState(false);
  const [isDisplayAlertMessage, setDisplayAlertMessage] = useState(displayAlert);

  const handleSubmit = async (data: Partial<MerchantView>) => {
    try {
      setIsSaving(true);
      await set(data);
      showNotification('success', 'Merchant profile has been updated.');
      setDisplayAlertMessage(false);
    } catch (err) {
      console.error(err);
      showNotification('error', 'Unable to save Merchant profile. Please try again later.');
    } finally {
      setIsSaving(false);
    }
  };

  if (document.promised) return <Loading />;
  if (!document.data) return null;

  const infoItems: TInfoItem[] = [
    {title: 'My Applications', lines: [document.data.applicationMids.filter(am => am).join(', ')]},
    {title: 'My Timezone', lines: [document.data.timeZoneId]},
    {title: 'Profile Created On', lines: [document.data.createdAt.toDate().toLocaleDateString()]},
  ];
  return (
    <Grid container spacing={3}>
      {isDisplayAlertMessage && (
        <Grid item xs={12} lg={12}>
          <Alert severity="warning">
            To continue to use portal please fill in all required fields.
          </Alert>
        </Grid>
      )}
      <Grid item lg={6} xs={12}>
        <MerchantProfileForm profile={document.data} onSubmit={handleSubmit} isSaving={isSaving} />
      </Grid>
      <Grid item lg={6} xs={10}>
        <InfoItemsList infoItems={infoItems} />
      </Grid>
    </Grid>
  );
};
