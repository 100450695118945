export enum ShippingType {
  other = 'other',
  businessAddress = 'businessAddress',
  mailingAddress = 'mailingAddress',
  homeAddress = 'homeAddress',
  agentAddress = 'agentAdress',
  localPickup = 'localPickup',
}

export const ShippingTypeText: Record<ShippingType, string> = {
  [ShippingType.other]: 'Other',
  [ShippingType.businessAddress]: 'Business Address',
  [ShippingType.mailingAddress]: 'Mailing Address',
  [ShippingType.homeAddress]: 'Home Address',
  [ShippingType.agentAddress]: 'Agent Address',
  [ShippingType.localPickup]: 'Local Pickup',
};
