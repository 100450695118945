import {
  Collections,
  Notification,
  NotificationStatus,
  NotificationView,
  selectNotificationView,
} from '@ozark/common';
import {useCallback, useEffect, useRef, useState} from 'react';
import {createContainer} from 'unstated-next';
import {
  Firebase,
  UniversalSnapshot,
  UniversalTimestamp,
  useAuthContainer,
  useCallable,
  useNotification,
} from '../../..';

const PAGE_SIZE = 20;

function useNotificationsData() {
  const {authUser} = useAuthContainer();
  const [items, setItems] = useState<NotificationView[]>([]);
  const [isUnreadView, setIsUnreadView] = useState(true);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isUnreadCountLoading, setUnreadCountLoading] = useState(true);
  const [hasMoreItems, setHasMoreItems] = useState(false);
  const lastKey = useRef<UniversalTimestamp>();
  const uid = authUser?.data?.uid;
  const showNotification = useNotification();
  const {markAllNotificationsAsRead} = useCallable();
  const [isMarkingAsRead, setIsMarkingAsRead] = useState(false);

  const markRead = useCallback(
    (id: string) => {
      const item = items.find(i => i.id === id);
      if (!item) return;
      const status =
        item.status === NotificationStatus.read ? NotificationStatus.new : NotificationStatus.read;
      items.splice(items.indexOf(item), 1, {...item, status});
      item && setItems([...items]);

      Firebase.firestore
        .collection(Collections.userNotifications)
        .doc(uid)
        .collection(Collections.notifications)
        .doc(id)
        .update({
          status,
        });
    },
    [items]
  );

  const markReadAll = async () => {
    try {
      setIsMarkingAsRead(true);
      const result = await markAllNotificationsAsRead();
      if (result.status === 'error') {
        throw new Error('Error in markAllNotificationsAsRead callable function');
      }
    } catch (error) {
      showNotification('error', 'Failed to mark all notifications as read');
      console.error('Failed to mark all notifications as read with an error:', error);
    } finally {
      setIsMarkingAsRead(false);
    }
  };

  const getNextItems = useCallback(async () => {
    if (uid && lastKey.current) {
      const nextItems = await getNextBatch(uid, lastKey.current);
      nextItems.length && setItems(items.concat(nextItems));
      lastKey.current =
        nextItems.length === PAGE_SIZE ? nextItems[nextItems.length - 1].createdAt : undefined;
      setHasMoreItems(Boolean(lastKey.current));
    }
  }, [uid, items, setItems, lastKey]);

  useEffect(() => {
    if (uid && items.length) {
      setUnreadCountLoading(true);

      getItemsNewCount(uid).then(count => {
        setUnreadCount(count);
        setUnreadCountLoading(false);
      });
    } else {
      setUnreadCount(0);
    }
  }, [uid, items, setUnreadCount]);

  useEffect(() => {
    if (!uid) return;
    lastKey.current = undefined;

    const ref = Firebase.firestore
      .collection(Collections.userNotifications)
      .doc(uid)
      .collection(Collections.notifications);

    const refFiltered = ref.where('status', '==', NotificationStatus.new);

    return (isUnreadView ? refFiltered : ref)
      .orderBy('createdAt', 'desc')
      .limit(PAGE_SIZE)
      .onSnapshot(
        async snapshot => {
          if (snapshot.size === 0) {
            setItems([]);
            setUnreadCount(0);
            return;
          }
          const notifications = snapshot.docs.map(doc =>
            selectNotificationView(doc as UniversalSnapshot<Notification>)
          );
          setItems(notifications);
          lastKey.current =
            notifications.length === PAGE_SIZE
              ? notifications[notifications.length - 1].createdAt
              : undefined;
          setHasMoreItems(Boolean(lastKey.current));
        },
        err => {
          console.error('useNotificationsData error:', err.message);
          setItems([]);
        }
      );
  }, [uid, setItems, isUnreadView]);

  return {
    items,
    unreadCount,
    isUnreadCountLoading,
    isUnreadView,
    setIsUnreadView,
    getNextItems,
    hasMoreItems,
    markRead,
    markReadAll,
    isMarkingAsRead,
  };
}

const getItemsNewCount = async (uid: string) => {
  try {
    const newCountQuery = await Firebase.firestore
      .collection(Collections.userNotifications)
      .doc(uid)
      .collection(Collections.notifications)
      .where('status', '==', NotificationStatus.new)
      .get();
    return newCountQuery.size;
  } catch (err) {
    console.log(err);
  }
  return 0;
};

const getNextBatch = async (uid: string, lastKey: UniversalTimestamp) => {
  try {
    const snapshot = await Firebase.firestore
      .collection(Collections.userNotifications)
      .doc(uid)
      .collection(Collections.notifications)
      .orderBy('createdAt', 'desc')
      .startAfter(lastKey)
      .limit(PAGE_SIZE)
      .get();

    const notifications = snapshot.docs.map(doc =>
      selectNotificationView(doc as UniversalSnapshot<Notification>)
    );

    return notifications;
  } catch (e) {
    console.log(e);
  }
  return [];
};

export const NotificationsState = createContainer(useNotificationsData);
export const useNotificationsState = NotificationsState.useContainer;
