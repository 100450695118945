import {Box} from '@mui/material';
import {DashboardTable, DashTableRow} from '../common/DashboardTable';
import {DataIsLoading} from '../common/DataIsLoading';
import {DataIsMissing} from '../common/DataIsMissing';
import {WidgetProps} from '../common/types';

import {DataFormat} from '../common/utils';

const noVolume = 'No Sales Data';
export function WidgetDisputes({loading, data}: WidgetProps) {
  if (loading) return <DataIsLoading />;
  if (!data) return <DataIsMissing />;

  const rowsTotal: DashTableRow[] = [
    {
      label: 'Total Dispute Count',
      value: data.totalChargebacks,
      dataFormat: DataFormat.number,
    },
    {
      label: 'Total Dispute Ratio',
      value: data.totalCbRatio,
      dataFormat: DataFormat.percent,
      noDataMsg: noVolume,
      displayNoDataForZero: true,
    },
    {
      label: 'Total Dispute Volume',
      value: data.totalCbVolume,
      dataFormat: DataFormat.currency,
    },
    {
      label: 'RDR by Count',
      value: data.totalRdrCount,
      dataFormat: DataFormat.number,
    },
    {
      label: 'RDR by Volume',
      value: data.totalRdrVolume,
      dataFormat: DataFormat.currency,
    },
  ];

  const rowsVisa: DashTableRow[] = [
    {
      label: 'Visa Dispute Count',
      value: data.visaCbCount,
      dataFormat: DataFormat.number,
    },
    {
      label: 'Visa Dispute Ratio by Count',
      value: data.visaCbRatioByCount,
      dataFormat: DataFormat.percent,
      noDataMsg: noVolume,
      displayNoDataForZero: true,
    },
    {
      label: 'Visa Dispute Ratio by Volume',
      value: data.visaCbRatioByVolume,
      dataFormat: DataFormat.percent,
      noDataMsg: noVolume,
      displayNoDataForZero: true,
    },
  ];

  const rowsMasterCard: DashTableRow[] = [
    {
      label: 'MC Dispute Count',
      value: data.mcCbCount,
      dataFormat: DataFormat.number,
    },
    {
      label: 'MC Dispute Ratio by Count',
      value: data.mcCbRatioByCount,
      dataFormat: DataFormat.percent,
      noDataMsg: noVolume,
      displayNoDataForZero: true,
    },
    {
      label: 'MC Dispute Ratio by Volume',
      value: data.mcRatioByVolume,
      dataFormat: DataFormat.percent,
      noDataMsg: noVolume,
      displayNoDataForZero: true,
    },
  ];

  return (
    <Box>
      <Box pb={1}>
        <DashboardTable data={rowsTotal} size="small" />
      </Box>
      <Box pb={1}>
        <Box bgcolor="#ebeff4">
          <DashboardTable data={rowsVisa} size="small" />
        </Box>
      </Box>
      <Box pb={1}>
        <DashboardTable data={rowsMasterCard} size="small" />
      </Box>
    </Box>
  );
}
