import {Chip, ChipProps} from '@mui/material';
import {NotificationType} from '@ozark/common';

type Props = {
  type?: NotificationType;
};

const mapCustomNotificationTypeToChipColor: {[key in NotificationType]: ChipProps['color']} = {
  [NotificationType.error]: 'error',
  [NotificationType.warning]: 'warning',
  [NotificationType.information]: 'info',
  [NotificationType.success]: 'success',
};

export const NotificationTypeChip = ({type}: Props): JSX.Element => (
  <Chip
    label={type ?? NotificationType.information}
    color={mapCustomNotificationTypeToChipColor[type ?? NotificationType.information]}
    size="small"
  />
);
