import {Divider, Paper, Tooltip, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {TicketTypeView} from '@ozark/functions/src/documents/TicketType';
import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {TICKET_TYPES_MANAGER_ROUTE} from '../../../constants/routes';
import {EditTypeDialog} from './EditTypeDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: 58,
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: theme.spacing(2, 2, 2, 2),
      borderLeft: `solid 8px ${theme.palette.primary.light}`,
      width: '100%',
    },
    divider: {
      padding: 0,
    },
    name: {
      width: 300,
      minWidth: 300,
      fontWeight: 400,
      cursor: 'pointer',
      letterSpacing: '0.5px',
    },
    rolesLabel: {
      width: theme.spacing(16),
      marginLeft: theme.spacing(2),
      color: 'gray',
    },
    roles: {
      marginLeft: theme.spacing(1),
      width: 222,
      minWidth: 222,
    },
    categoriesLabel: {
      width: 80,
      marginLeft: theme.spacing(2),
      cursor: 'pointer',
      color: 'gray',
    },
    categories: {
      marginLeft: theme.spacing(1),
      cursor: 'pointer',
      textOverflow: 'ellipsis',
    },
  })
);

type Props = {
  ticketType: TicketTypeView;
};

const getOpacity = (deleted?: boolean | null) => {
  return deleted === true ? 0.4 : 1;
};

export const TicketTypeCard = ({ticketType}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const editCategoriesClick = () => history.push(`${TICKET_TYPES_MANAGER_ROUTE}/${ticketType.id}`);

  const displayCategories = () => {
    const displayCount = 2;
    const count =
      ticketType.categories.length > displayCount ? ` (\+${ticketType.categories.length - 2})` : '';
    return `${ticketType.categories.slice(0, displayCount).join(', ')}${count}`;
  };

  return (
    <Paper
      className={classes.paper}
      style={{
        opacity: getOpacity(ticketType.isDeleted),
      }}
    >
      <Tooltip title={'Edit ticket type'}>
        <div className={classes.name} onClick={() => setEditDialogOpen(true)}>
          {ticketType.name}
        </div>
      </Tooltip>

      <Divider className={classes.divider} orientation="vertical" flexItem />
      <div className={classes.rolesLabel}>Visible To Roles:</div>
      <div className={classes.roles}>{ticketType.visibleToRoles.join(', ')}</div>

      <Divider className={classes.divider} orientation="vertical" flexItem />
      <div className={classes.categoriesLabel} onClick={editCategoriesClick}>
        Categories:
      </div>
      <div onClick={editCategoriesClick}>
        <Tooltip
          title={
            <div>
              Click to edit categories: <br />
              <br /> {ticketType.categories.join(', ')}
            </div>
          }
        >
          <Typography className={classes.categories}>{displayCategories()}</Typography>
        </Tooltip>
      </div>

      {editDialogOpen && (
        <EditTypeDialog
          key={ticketType.id}
          ticketType={ticketType}
          onClose={() => setEditDialogOpen(false)}
        />
      )}
    </Paper>
  );
};
