import {Box, Divider} from '@mui/material';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {TicketAssociation} from '../../..';
import {useTicketsFiltersContainer} from '../hooks/useTicketsFiltersContainer';
import {Wizard} from '../TicketEditWizard/Wizard';
import {TicketsListFolders} from '../TicketsListFolders/TicketsListFolders';
import {TicketListSortBy} from '../TicketsListToolbar/TicketsListSortBy';
import {TicketsListViewAllFilters} from '../TicketsListToolbar/TicketsListViewFilters';
import {TicketsListCreateButton} from './TicketsListCreateButton';
import {TicketsListViewAll} from './TicketsListViewAll';
import {TicketsListViewMy} from './TicketsListViewMy';
import {TicketsListViewSelector} from './TicketsListViewSelector';

export const TicketsListView = () => {
  const {isAllTicketsView} = useTicketsFiltersContainer();
  const history = useHistory<{
    createNewTicket?: boolean;
    association?: TicketAssociation;
  }>();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  useEffect(() => {
    if (history.location.state?.createNewTicket) {
      setIsCreateDialogOpen(true);
    }
  }, [history.location.state]);

  return (
    <>
      <Box height="100%" display="flex" flexDirection="column">
        <Box>
          <Box display="flex" alignItems="center" minHeight="4em" mb={0.5}>
            <TicketsListViewSelector />
            {isAllTicketsView && <TicketsListViewAllFilters />}
            <Box flex={1} />
            <Box display="flex" alignItems="center" minWidth={302}>
              <TicketListSortBy />
              <Box mx={2} />
              <TicketsListCreateButton />
            </Box>
          </Box>
          <Divider />
        </Box>
        <Box display="flex" my={2} height="100%" flex={1} position="relative">
          <Box flexBasis={212} minWidth={212}>
            <TicketsListFolders />
          </Box>
          <Divider flexItem orientation="vertical" />
          <Box display="flex" width="100%" flexDirection="column">
            {isAllTicketsView ? <TicketsListViewAll /> : <TicketsListViewMy />}
          </Box>
        </Box>
      </Box>
      {isCreateDialogOpen && (
        <Wizard
          handleClose={() => setIsCreateDialogOpen(false)}
          association={history.location.state.association}
        />
      )}
    </>
  );
};
