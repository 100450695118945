export enum Dispositions {
  started = 'Started',
  incomplete = 'Incomplete',
  signatureNeeded = 'Signature Needed',
  asReceived = 'AS Received',
  asIncomplete = 'AS Incomplete',
  asDeclined = 'AS Declined',
  asWithdrawn = 'AS Withdrawn',
  asRequestedSupportingDocuments = 'AS Requested Supporting Documents',
  asSupportingDocumentsReceived = 'AS Supporting Documents Received',
  uwReceived = 'UW Received',
  uwSubmittedToOtherBank = 'UW Submitted to Other Bank',
  uwPending = 'UW Pending',
  uwApproved = 'UW Approved',
  uwDeclined = 'UW Declined',
  uwWithdrawn = 'UW Withdrawn',
  uwArchived = 'UW Archived',
  enqueued = 'Enqueued',
  failed = 'Failed',
  boarded = 'Boarded',
}
