import {
  Collections,
  Firebase,
  Profile,
  TicketComment,
  TicketCommentType,
  TicketLastCommentInfo,
} from '../../..';
import {LastCommentsInfo} from '../types';

export const useLastCommentsInfo = () => {
  const getCollectionByCommentType = (commentType: TicketCommentType) => {
    switch (commentType) {
      case TicketCommentType.Internal:
        return Collections.commentsInternal;
      case TicketCommentType.Public:
        return Collections.comments;
      default:
        throw RangeError('invalid comment type');
    }
  };
  const getLastCommentInCollectionInfo = async (
    commentType: TicketCommentType,
    ticketId: string
  ): Promise<TicketLastCommentInfo | undefined> => {
    let lastCommentInfo: TicketLastCommentInfo | undefined;
    const ticketSnapshot = await Firebase.firestore
      .collection(Collections.tickets)
      .doc(ticketId)
      .collection(getCollectionByCommentType(commentType))
      .orderBy('createdAt', 'desc')
      .limit(1)
      .get();

    ticketSnapshot.docs.forEach(ticketComment => {
      const comment = ticketComment.data() as TicketComment;
      lastCommentInfo = {
        commentId: ticketComment.id,
        author: comment.author,
        authorProfileId: comment.uid,
        authorRole: null,
        authorDepartment: null,
        createdAt: comment.createdAt,
      };
    });

    if (lastCommentInfo && lastCommentInfo.authorProfileId) {
      const profileSnapshot = await Firebase.firestore
        .collection(Collections.profiles)
        .doc(lastCommentInfo.authorProfileId)
        .get();

      if (profileSnapshot.exists) {
        const profile = profileSnapshot.data() as Profile;
        lastCommentInfo.authorDepartment = profile.department ?? null;
        lastCommentInfo.authorRole = profile.role;
      }
    }

    return lastCommentInfo;
  };

  const getLastPublicCommentInfo = async (ticketId: string) => {
    return await getLastCommentInCollectionInfo(TicketCommentType.Public, ticketId);
  };

  const getLastInternalCommentInfo = async (ticketId: string) => {
    return await getLastCommentInCollectionInfo(TicketCommentType.Internal, ticketId);
  };

  const getLastCommentsInfo = async (ticketId: string): Promise<LastCommentsInfo> => {
    const lastPublicCommentInfo = await getLastPublicCommentInfo(ticketId);
    const lastInternalCommentInfo = await getLastInternalCommentInfo(ticketId);

    return {
      lastPublicCommentInfo: lastPublicCommentInfo ?? Firebase.FieldValue.delete(),
      lastInternalCommentInfo: lastInternalCommentInfo ?? Firebase.FieldValue.delete(),
    } as LastCommentsInfo;
  };

  return {
    getLastCommentsInfo,
  };
};
