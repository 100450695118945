import DoneIcon from '@mui/icons-material/Done';
import {CircularProgress} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {AsyncState, GroupView, useCallable} from '@ozark/common';
import {PlaidLinkButton} from '@ozark/common/components';
import {useCallback, useEffect, useState} from 'react';
import {PlaidLinkOnSuccess} from 'react-plaid-link';
import {DOCUMENTS_UPLOAD} from '../../constants/routes';

type Props = {
  group: AsyncState<GroupView>;
  token: string;
  onSuccess: (bankName: string) => void;
  linkedBankAccount?: string;
};

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

export const DocumentUploadPlaidLink = ({onSuccess, group, token, linkedBankAccount}: Props) => {
  const classes = useStyles();
  const [addingBankInfo, setAddingBankInfo] = useState(false);
  const {getDocumentsUploadPlaidLinkToken, selectDocumentsUploadPlaidAccount} = useCallable();
  const [linkToken, setLinkToken] = useState<string | null>(() =>
    window.location.search.indexOf('oauth') > -1 ? sessionStorage.getItem('link_token') : null
  );

  const [linkedBankAccountName, setLinkedBankAccountName] = useState<string>();

  useEffect(() => {
    if (!linkedBankAccount) {
      return;
    }
    setLinkedBankAccountName(linkedBankAccount);
  }, [linkedBankAccount]);

  useEffect(() => {
    if (group.promised || !group.data || linkToken || linkedBankAccountName || !token) return;

    (async () => {
      try {
        const response = await getDocumentsUploadPlaidLinkToken({
          token,
          groupName: group.data!.name,
          redirectUrl: `${window.location.origin}${DOCUMENTS_UPLOAD}`,
        });
        if (response.status === 'ok') {
          if (response.plaidLinkToken) {
            setLinkToken(response.plaidLinkToken);
          }
          if (response.bankAccountName) {
            setLinkedBankAccountName(response.bankAccountName);
            onSuccess(response.bankAccountName);
          }
        } else {
          console.error(response);
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, [group, linkToken, linkedBankAccountName, getDocumentsUploadPlaidLinkToken, token, onSuccess]);

  const refreshToken = useCallback(async () => {
    if (!group.data) return;

    const response = await getDocumentsUploadPlaidLinkToken({
      token,
      groupName: group.data.name,
      redirectUrl: `${window.location.origin}${DOCUMENTS_UPLOAD}`,
    });
    if (response.status === 'ok') {
      if (response.plaidLinkToken) {
        setLinkToken(response.plaidLinkToken);
      }
      if (response.bankAccountName) {
        setLinkedBankAccountName(response.bankAccountName);
        onSuccess(response.bankAccountName);
      }
    } else {
      console.error(response);
    }
  }, [group.data, getDocumentsUploadPlaidLinkToken, token, onSuccess]);

  const handleLinkBank = useCallback<PlaidLinkOnSuccess>(
    async (public_token, metadata) => {
      setAddingBankInfo(true);
      const linkedBankName = metadata.institution?.name || 'Unknown Bank';
      try {
        await selectDocumentsUploadPlaidAccount({
          accountId: metadata.accounts[0].id,
          bankName: linkedBankName,
          publicToken: public_token,
          token,
        });
      } catch (err: any) {
        console.error(err);
      }
      setAddingBankInfo(false);
      sessionStorage.removeItem('link_token');
      setLinkToken(null);
      setLinkedBankAccountName(linkedBankName);
      onSuccess(linkedBankName);
    },
    [token, selectDocumentsUploadPlaidAccount, onSuccess]
  );

  return (
    <div className={classes.root}>
      {addingBankInfo || (!linkToken && !linkedBankAccountName) ? (
        <CircularProgress />
      ) : linkedBankAccountName ? (
        <h4>
          <span>
            <DoneIcon
              sx={{
                mb: -0.6,
              }}
              color="success"
            />
          </span>

          <span>Deposit Account Linked to {linkedBankAccountName}</span>
        </h4>
      ) : (
        linkToken && (
          <PlaidLinkButton
            isOauth={
              window.location.search.indexOf('oauth') > -1 &&
              Boolean(sessionStorage.getItem('link_token'))
            }
            token={linkToken}
            onSuccess={handleLinkBank}
            size={'small'}
            secondary={true}
            refreshToken={refreshToken}
          >
            Instantly Link your deposit account with Plaid
          </PlaidLinkButton>
        )
      )}
    </div>
  );
};
