import {Autocomplete, Box, TextField} from '@mui/material';
import {omit} from 'lodash';
import {useEffect, useState} from 'react';
import {TicketAssignee, TicketManager} from '../../../..';
import {FIELD_NAME_GROUP} from '../../constants/constants';
import {useGroupsOptions} from '../../hooks/useGroupsOptions';
import {InputAssigneeError} from '../../types';

type GroupOption = TicketManager;

type Props = {
  draft: TicketAssignee;
  setDraft: (draft: TicketAssignee) => void;
  errors: Record<InputAssigneeError, string>;
  setErrors: (errors: Record<InputAssigneeError, string>) => void;
};

export const AssigneeEditorValueGroup = ({draft, setDraft, errors, setErrors}: Props) => {
  const [internalValue, setInternalValue] = useState<TicketAssignee['group'] | null>(null);

  const {groups} = useGroupsOptions();

  useEffect(() => {
    if (groups.length === 0 || !draft?.group) {
      return;
    }

    setInternalValue(draft.group);
  }, [groups.length, draft.group?.id]);

  return (
    <Box mt={2}>
      <Autocomplete
        value={internalValue as TicketManager}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onChange={(_: React.SyntheticEvent<Element, Event>, newValue: GroupOption | string) => {
          setDraft({...draft, group: (newValue as TicketManager) ?? undefined});
          if (newValue) {
            setErrors(omit(errors, FIELD_NAME_GROUP) as Record<InputAssigneeError, string>);
          }
        }}
        disableClearable
        options={groups}
        getOptionLabel={(item: GroupOption) => item?.name ?? ''}
        renderInput={params => (
          <TextField
            {...params}
            label="Assignee Group"
            required
            variant="standard"
            error={Boolean(errors[FIELD_NAME_GROUP])}
            helperText={errors[FIELD_NAME_GROUP]}
          />
        )}
      />
    </Box>
  );
};
