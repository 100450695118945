import {useCallback} from 'react';
import {
  Collections,
  Firebase,
  Ticket,
  TicketComment,
  TicketLastCommentInfo,
  useNotification,
} from '../../..';
import {useUserInfo} from '../../../hooks/useUserInfo';
import {useTicketEditContainer} from './useTicketEditContainer';
import {useTicketId} from './useTicketId';
import {useUserDepartment} from './useUserDepartment';

export function useComment() {
  const {commentsCollection, commentsInternal} = useTicketEditContainer();
  const {uid, userDisplayName} = useUserInfo();
  const {role: userRole} = useUserInfo();
  const {ticketId, isTicketIdValid} = useTicketId();
  const showNotification = useNotification();
  const {department} = useUserDepartment();

  const commentAdd = useCallback(
    async (editorValueHtml?: string) => {
      try {
        if (!isTicketIdValid || !uid || !editorValueHtml) {
          throw new Error('commentAdd: invalid parameters');
        }

        const commentToSave: TicketComment = {
          comment: editorValueHtml,
          uid,
          createdAt: Firebase.FieldValue.now(),
          author: userDisplayName ?? null,
          authorRole: userRole ?? null,
        };
        const ref = await Firebase.firestore
          .collection(Collections.tickets)
          .doc(ticketId)
          .collection(commentsCollection)
          .add(commentToSave);

        const commentSnap = await ref.get();
        if (commentSnap.exists) {
          const comment = commentSnap.data() as TicketComment;
          const lastCommentInfo: TicketLastCommentInfo = {
            commentId: commentSnap.id,
            author: comment.author,
            authorProfileId: comment.uid,
            authorDepartment: department ?? null,
            authorRole: userRole ?? null,
            createdAt: comment.createdAt,
          };

          const fieldsToUpdate: Partial<Ticket> = {};

          if (commentsInternal) {
            fieldsToUpdate.lastInternalCommentInfo = lastCommentInfo;
          } else {
            fieldsToUpdate.lastPublicCommentInfo = lastCommentInfo;
          }

          await Firebase.firestore
            .collection(Collections.tickets)
            .doc(ticketId)
            .update(fieldsToUpdate);
        }
        return ref.id;
      } catch (err) {
        console.error(err);
        showNotification('error', 'An error occurred while saving comment.');
      }
    },
    [uid, ticketId, isTicketIdValid, commentsCollection, commentsInternal]
  );

  const commentUpdate = useCallback(
    async (editorValueHtml?: string, commentId?: string) => {
      try {
        if (!isTicketIdValid || !uid || !editorValueHtml || !commentId) {
          throw new Error('commentUpdate: invalid parameters');
        }
        await Firebase.firestore
          .collection(Collections.tickets)
          .doc(ticketId)
          .collection(commentsCollection)
          .doc(commentId)
          .update({
            comment: editorValueHtml,
            updatedAt: Firebase.FieldValue.now(),
          });
      } catch (err) {
        console.error(err);
        showNotification('error', 'An error occurred while saving comment.');
      }
    },
    [uid, ticketId, isTicketIdValid, commentsCollection]
  );

  return {
    commentAdd,
    commentUpdate,
  };
}
