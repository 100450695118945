import {DocumentBase, UniversalTimestamp} from '.';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export const BusinessTypeCodes = {
  GADGETS: 'GADGETS',
  GADGETS_NO_HISTORY: 'GADGETS_NO_HISTORY',
  NUTRA: 'NUTRA',
  NUTRA_NO_HISTORY: 'NUTRA_NO_HISTORY',
} as const;

export type BusinessTypeCodeValue = typeof BusinessTypeCodes[keyof typeof BusinessTypeCodes];

export const BusinessTypePercentReserveDefault = 0;
export const BusinessTypePercentReserve: {[_ in BusinessTypeCodeValue]?: number} = {
  // 1234: 10%
  // 5678: 10%
  // 9932: 15%
  [BusinessTypeCodes.GADGETS]: 5,
  [BusinessTypeCodes.GADGETS_NO_HISTORY]: 10,
  [BusinessTypeCodes.NUTRA]: 5,
  [BusinessTypeCodes.NUTRA_NO_HISTORY]: 10,
};

// DEPRECATED
export type BusinessType = DocumentBase & {
  name: string;
  mcc: string;
  code: string; // to use in conditions i.e. [BusinessType.gadgets]: 5,
  order: number;
  deleted: boolean;
  createdAt: UniversalTimestamp;
};

// DEPRECATED
export type BusinessTypeView = ViewBase<BusinessType>;

export type SimpleBusinessType = DocumentBase & {
  name: string;
  mcc: string;
  deleted: boolean;
  createdAt: UniversalTimestamp;
};

export type SimpleBusinessTypeView = ViewBase<SimpleBusinessType>;

// DEPRECATED
export const selectBusinessTypeView: ViewSelector<BusinessTypeView, BusinessType> = snapshot => {
  const businessType: BusinessTypeView = {
    ...defaultViewSelector(snapshot),
  };
  return businessType;
};

export const selectSimpleBusinessTypeView: ViewSelector<
  SimpleBusinessTypeView,
  SimpleBusinessType
> = snapshot => {
  const simpleBusinessTypeView: SimpleBusinessTypeView = {
    ...defaultViewSelector(snapshot),
  };
  return simpleBusinessTypeView;
};

/*
// [UPD] This might not working due to busuness types and categories reworking
// Leaving this in here to use with Quokka to export list for Lauren (by Jeff - Group Level Pricing)
const exportTypesAndCategories = async () => {
  let str = '';
  let obj: any = [];
  Object.keys(BusinessTypeGroups).forEach((group: any) => {
    (BusinessTypeGroups as any)[group as any].forEach((type: any) => {
      const mcc = await getMccByBusinessType(type);
      //str += `${group.replace(',','\,')}\t${type.replace(',','\,')}\t${mcc}\r\n`;
      obj.push({group, type, mcc});
    });
  });
  //console.log(str);
  console.log(JSON.stringify(obj));
};
await exportTypesAndCategories();
*/
