import type {SearchOptions, SearchResponse} from '@algolia/client-search';
import {Dispositions, getSearchGroup, IndexableApplication, IndexableLead} from '@ozark/common';
import {flatten, sortBy} from '@s-libs/micro-dash';
import {useCallback, useState} from 'react';
import {useApplicationSearch} from './useApplicationSearch';
import {useDebouncedCallback} from './useDebouncedCallback';
import {useLeadSearch} from './useLeadSearch';

type SearchResponses = [
  SearchResponse<IndexableApplication> | null,
  SearchResponse<IndexableLead> | null
];

const EMPTY_RESPONSES: SearchResponses = [null, null];

interface UseGlobalSearchProps {
  applicationSearchOptions?: Partial<SearchOptions>;
  leadSearchOptions?: Partial<SearchOptions>;
}

export const useGlobalSearch = ({
  applicationSearchOptions,
  leadSearchOptions,
}: UseGlobalSearchProps = {}) => {
  const [responses, setResponses] = useState(EMPTY_RESPONSES);

  const {search: applicationSearch} = useApplicationSearch(applicationSearchOptions);

  const {search: leadSearch} = useLeadSearch(leadSearchOptions);

  // This is an old version of search which is used in <Search> component
  // We can remove it after reworking search:
  // e.g. merge with ticket search and remove previous version of Search component
  const search_DEPRECATED = useCallback(
    (request: {input: string}, callback: (results?: any[]) => void) => {
      if (!request?.input) {
        return null;
      }

      applicationSearch(request.input).then(
        (response: SearchResponse<IndexableApplication> | null) => {
          let suggestions: (IndexableApplication & {id: string; searchGroup?: string})[] = [];

          if (response && response.hits.length) {
            suggestions = response.hits.map(hit => ({
              ...hit,
              id: hit.objectID,
              searchGroup: getSearchGroup(hit!.disposition as Dispositions),
            }));
          }

          const flattened = sortBy(flatten(suggestions), suggestion => suggestion.searchGroup);
          callback(flattened);
        }
      );
    },
    []
  );

  const search = useCallback(
    async (query: string) => {
      // prevent all-items request after autocomplete clear onBlur
      if (!query) {
        setResponses(EMPTY_RESPONSES);
        return EMPTY_RESPONSES;
      }

      try {
        const searches = [
          applicationSearchOptions ? applicationSearch(query) : Promise.resolve(null),
          leadSearchOptions ? leadSearch(query) : Promise.resolve(null),
        ];

        const nextResponses = (await Promise.all(searches)) as SearchResponses;

        setResponses(nextResponses);

        return nextResponses;
      } catch (error) {
        console.error('Global Search error', error);

        return EMPTY_RESPONSES;
      }
    },
    [applicationSearchOptions, leadSearchOptions]
  );

  const debouncedSearch = useDebouncedCallback(search, 300);

  return {
    search: debouncedSearch,
    search_DEPRECATED,
    responses,
  } as const;
};
