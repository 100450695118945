import {SearchCriteria} from '..';
import {ActiveFilter} from '../components';

export const toQuery = (searchCriteria: SearchCriteria | null, filters?: ActiveFilter[]) => {
  let params = [];

  if (searchCriteria) {
    params.push(
      `page=${searchCriteria.offset}`,
      `count=${searchCriteria.limit}`,
      `order_by=${searchCriteria.order === 'desc' ? '-' : ''}${searchCriteria.orderBy}`
    );
    if (searchCriteria.index !== undefined) {
      params.push(`index=${searchCriteria.index}`);
    }
  }

  if (filters) {
    filters.forEach(filter => {
      if (filter.option.type === 'dateRange' || filter.option.type === 'date') {
        const range: [Date, Date] = filter.value as [Date, Date];
        const fromDate = encodeURIComponent(range[0].toISOString());
        const toDate = encodeURIComponent(range[1].toISOString());
        // todo: identify why toISOString isn't a valid choice?
        // const fromDate = encodeURIComponent(
        //   format(range[0], 'yyyy-MM-dd HH:mm:ssXXX').replace(' ', 'T')
        // );
        // const toDate = encodeURIComponent(
        //   format(range[1], 'yyyy-MM-dd HH:mm:ssXXX').replace(' ', 'T')
        // );
        params.push(`${filter.option.column}${filter.operator.id}=${fromDate},${toDate}`);
        return;
      }
      if (['dateOnlyRange', 'dateOnly', 'monthRange', 'month'].includes(filter.option.type)) {
        const [start, end] = filter.value as [Date, Date];

        const startDate = `${start.getFullYear()}-${(start.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${start.getDate().toString().padStart(2, '0')}`; // Date provides month index; not month number
        const endDate = `${end.getFullYear()}-${(end.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${end.getDate().toString().padStart(2, '0')}`; // Date provides month index; not month number

        params.push(
          `${filter.option.column}${filter.operator.id}=${startDate}${
            ['dateOnlyRange', 'monthRange', 'month'].includes(filter.option.type)
              ? `,${endDate}`
              : ''
          }`
        );
        return;
      }
      params.push(
        `${filter.option.id}${filter.operator.id}=${encodeURIComponent(filter.value as string)}`
      );
    });
  }
  return [...params].join('&');
};

declare global {
  interface Array<T> {
    sortAndMap<U>(
      callbackfn: (value: T, index: number, array: T[]) => U,
      getValueFn?: (value: T) => string,
      thisArg?: any
    ): U[] | undefined;
  }
}

if (!Array.prototype.sortAndMap) {
  Array.prototype.sortAndMap = function <U, T>(
    callbackfn: (value: T, index: number, array: T[]) => U,
    getValueFn?: (value: T) => string,
    thisArg?: any
  ): U[] {
    return getSortedOptions(this, getValueFn).map(callbackfn);
  };
}

export function getSortedKeys<T>(o: T[], getValue: (value: T) => string): string[] {
  const options = getSortedOptions(o, getValue);
  return options ? Object.keys(options) : [];
}

export function getSortedOptions<T>(o: T[], getValue?: (value: T) => string): T[] {
  const value = (a: T) => (getValue ? getValue(a) : a) as string;

  const compareFn = (a: T, b: T) => value(a).toString().localeCompare(value(b).toString());
  return o?.sort ? o?.sort(compareFn) : o;
}
