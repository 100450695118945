import {AppBar, Divider, Paper, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'sticky',
      top: theme.spacing(8.25),
      margin: theme.spacing(0, 0, 2, 0),
    },
    header: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      color: '#4d6575',
      padding: theme.spacing(2, 2, 2, 0),
      width: '100%',
      transition: theme.transitions.create(['padding', 'border-color', 'box-shadow']),
      duration: theme.transitions.duration.complex,
      textAlign: 'center',
    },
    headerColumn: {
      height: '100%',
      padding: theme.spacing(0, 3, 0, 3),
      margin: theme.spacing(0, 3, 0, 3),
      flexBasis: '18%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      transition: theme.transitions.create(['flexBasis'], {
        duration: theme.transitions.duration.standard,
      }),
      [theme.breakpoints.down('lg')]: {
        '&:hover': {
          flexBasis: '40%',
        },
      },
      color: 'black',
    },
    lastHeaderColumn: {
      maxWidth: theme.spacing(20.25),
    },
  })
);

export const MerchantPortfolioHeader = () => {
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar} elevation={0}>
      <Paper className={classes.header}>
        <div className={classes.headerColumn}>
          <Typography variant="body1" noWrap>
            {'Group/Agent'}
          </Typography>
        </div>
        <Divider orientation="vertical" flexItem />
        <div className={classes.headerColumn}>
          <Typography variant="body1" noWrap>
            {'Approval Information'}
          </Typography>
        </div>
        <Divider orientation="vertical" flexItem />
        <div className={classes.headerColumn}>
          <Typography variant="body1" noWrap>
            {'Account Status'}
          </Typography>
        </div>
        <Divider orientation="vertical" flexItem />
        <div className={classes.headerColumn}>
          <Typography variant="body1" noWrap>
            {`First Batch Date/Amount`}
          </Typography>
        </div>
        <Divider orientation="vertical" flexItem />
        <div className={classes.headerColumn}>
          <Typography variant="body1" noWrap>
            {'Last Batch Date/Amount'}
          </Typography>
        </div>
        <div className={`${classes.headerColumn} ${classes.lastHeaderColumn}`}></div>
      </Paper>
    </AppBar>
  );
};
