export enum ApplicationAchDelay {
  zeroDays = 'zeroDays',
  oneDay = 'oneDay',
  twoDays = 'twoDays',
}

export const ApplicationAchDelayOptions: {
  applicationAchDelay: ApplicationAchDelay;
  text: string;
}[] = [
  {
    applicationAchDelay: ApplicationAchDelay.zeroDays,
    text: '0 Days',
  },
  {
    applicationAchDelay: ApplicationAchDelay.oneDay,
    text: '1 Day',
  },
  {
    applicationAchDelay: ApplicationAchDelay.twoDays,
    text: '2 Days',
  },
];
