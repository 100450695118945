export enum ShippingSpeed {
  na = 'N/A',
  ground = 'FedEx Ground (Free)',
  threeDayExpress = 'FedEx 3-Day Express by 4:30pm (Free)',
  twoDayExpress = 'FedEx 2 Day by 4:30pm (Additional charges based upon weight & distance)',
  twoDayFree = 'FedEx 2 Day Shipping (Free)',
  fedexOvernight = 'FedEx Overnight  (Additional charges based upon weight and distance)',
  standardOvernight = 'FedEx Standard Overnight by 4:30pm (Additional charges based upon weight & distance)',
  priorityOvernight = 'FedEx Priority Overnight by 10:30am (Additional charges based upon weight & distance)',
  firstOvernight = 'FedEx First Overnight by 9:00am (Additional charges based upon weight & distance)',
}

// list of shipping speeds available only for Merchant Facing Online App
// beware of adding N/A value here due to extra logic for ShippingSpeed.na and ShippingType.localPickup
export const OnlineAppShippingSpeeds = [ShippingSpeed.twoDayFree, ShippingSpeed.fedexOvernight];

export const DeprecatedShippingSpeeds = [ShippingSpeed.threeDayExpress];
