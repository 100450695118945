import {ListOption} from '../../models';
import {AutoCompleteInputBase} from '../AutoComplete';

type Props = {
  options: ListOption[];
  onOptionSelect: (option?: ListOption) => void;
  selectedOption?: ListOption;
  placeholder?: string;
};

export const SelectOptions = ({
  options,
  onOptionSelect,
  selectedOption,
  placeholder = 'Select ...',
}: Props) => {
  const getDropDownTitle = (option: ListOption): string => option.value;

  return (
    <AutoCompleteInputBase
      selected={selectedOption}
      setSelected={onOptionSelect}
      icon={false}
      placeholder={placeholder}
      width={200}
      options={options}
      getOptionLabel={(option: ListOption) => getDropDownTitle(option)}
      onItemSelect={(option: ListOption | null) => onOptionSelect(option ?? undefined)}
    />
  );
};
