import {AssociationType, TicketAssociation, TicketTypeAndCategory} from '../../..';

export const getTicketSubject = (
  category: TicketTypeAndCategory | undefined,
  association: TicketAssociation | undefined
): string | undefined => {
  const newSubject = [];
  if (association?.type === AssociationType.merchant && association?.merchant) {
    newSubject.push(association.merchant.dba);
    newSubject.push(`MID:${association.merchant.mid}`);
  }
  if (association?.type === AssociationType.mid && association?.mid) {
    newSubject.push(association.mid.dba);
    newSubject.push(`MID:${association.mid.mid}`);
  }
  if (association?.type === AssociationType.agent && association?.agent) {
    newSubject.push(association?.agent?.name);
    newSubject.push(association?.group?.name);
  }
  if (association?.type === AssociationType.erpUser && association?.erpUser) {
    newSubject.push(association.erpUser.name);
  }
  if (association?.type === AssociationType.application && association?.application) {
    const item = association?.application;
    newSubject.push(`${item.name ?? ''} (${item.distinguishableId})`.trim());
  }

  if (category?.category?.name) {
    newSubject.push(category?.category?.name);
  }
  return newSubject.filter(i => i).join(' | ');
};
