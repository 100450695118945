import {
  CommandNames,
  CreateMerchantUserPayload,
  CreateMerchantUserResult,
  Firebase,
  GetPortalTokenPayload,
  GetPortalTokenResult,
  RegisterMerchantUserPayload,
  RegisterMerchantUserResult,
  ResetPasswordPayload,
  ResetPasswordResult,
  TransferApplicationPayload,
  TransferApplicationResult,
  ValidateMerchantForRegistrationPayload,
  ValidateMerchantForRegistrationResult,
} from '@ozark/common';

const createCallableWrapper =
  <TPayload, TResult>(command: CommandNames) =>
  async (payload?: TPayload): Promise<TResult> => {
    const callFunction = Firebase.functions.httpsCallable('dispatchCallable');
    const result: TResult = await (async () => {
      try {
        const {data} = await callFunction({command, payload});
        return data;
      } catch (err: any) {
        return {
          status: 'error',
          message: err.message,
        };
      }
    })();
    return result;
  };

export const useCallable = () => {
  return {
    [CommandNames.validateMerchantForRegistration]: createCallableWrapper<
      ValidateMerchantForRegistrationPayload,
      ValidateMerchantForRegistrationResult
    >(CommandNames.validateMerchantForRegistration),
    [CommandNames.createMerchantUser]: createCallableWrapper<
      CreateMerchantUserPayload,
      CreateMerchantUserResult
    >(CommandNames.createMerchantUser),
    [CommandNames.registerMerchantUser]: createCallableWrapper<
      RegisterMerchantUserPayload,
      RegisterMerchantUserResult
    >(CommandNames.registerMerchantUser),
    [CommandNames.resetPassword]: createCallableWrapper<ResetPasswordPayload, ResetPasswordResult>(
      CommandNames.resetPassword
    ),
    [CommandNames.getPortalToken]: createCallableWrapper<
      GetPortalTokenPayload,
      GetPortalTokenResult
    >(CommandNames.getPortalToken),
    [CommandNames.transferApplication]: createCallableWrapper<
      TransferApplicationPayload,
      TransferApplicationResult
    >(CommandNames.transferApplication),
  };
};
