export enum AccordionId {
  VisaMatercardDiscover = 'Visa/Mastercard/Discover Credit',
  AmericanExpress = 'American Express',
  Debit = 'Debit',
  Miscellaneous = 'Miscellaneous',
  MerchantAdvantageProgram = 'MerchantAdvantageProgram',
  Reserves = 'Reserves',
  Equipment = 'Equipment',
  Shipping = 'Shipping',
  OtherVolumeAndItemFee = 'OtherVolumeAndItemFee',
}

const fieldToAccordionIdMap = {
  avsFee: AccordionId.Miscellaneous,
  regulatoryFee: AccordionId.Miscellaneous,
  earlyDeconversionFee: AccordionId.Miscellaneous,
  additionalServicesFee: AccordionId.Miscellaneous,
  debitAccessFee: AccordionId.Miscellaneous,
  monthlyMinimumFee: AccordionId.Miscellaneous,
  batchFee: AccordionId.Miscellaneous,
  annualFee: AccordionId.Miscellaneous,
  // equipment
  price: AccordionId.Equipment,
  // shipping
  address1: AccordionId.Shipping,
  city: AccordionId.Shipping,
  zip: AccordionId.Shipping,
};

const getErrorAccordion = (formErrors: any): AccordionId | undefined => {
  const errorSource = formErrors?.rateSet ?? formErrors?.equipment ?? formErrors?.shipping;
  if (!errorSource) return;

  const firstErrorField = Object.keys(errorSource)[0] as
    | keyof typeof fieldToAccordionIdMap
    | undefined;
  if (!firstErrorField) return;

  return fieldToAccordionIdMap[firstErrorField];
};

const expandAccordion = (targetAccordion?: string) => {
  if (!targetAccordion) return;

  const elAccordionRoot = document.getElementById(targetAccordion);
  if (!elAccordionRoot) return;
  elAccordionRoot.classList.add('Mui-expanded');

  const elHeaderRoot = elAccordionRoot.querySelector('.MuiAccordionSummary-expandIconWrapper');
  elHeaderRoot?.classList.add('Mui-expanded');

  const elCollapseRoot = elAccordionRoot.querySelector<HTMLElement>('.MuiCollapse-root');
  if (!elCollapseRoot) return;

  elCollapseRoot.style.height = 'auto';
  elCollapseRoot.style.visibility = 'visible';

  elAccordionRoot.scrollTo(0, screen.height / 2);
};

export const accordionOnError = (formErrors: any) => expandAccordion(getErrorAccordion(formErrors));
