import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {useNotification} from '@ozark/common';
import {Loading, Title} from '@ozark/common/components';
import {useStore} from '../../store/helpers';

const AppUrl = () => {
  const {authUser, group} = useStore();
  const showNotification = useNotification();

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    showNotification('success', 'Copied to clipboard');
  };

  if (!authUser.data) return <Loading />;

  const url = `${window.location.protocol}//${group.data?.appDomain}/start?a=${authUser.data?.uid}`;

  return (
    <div>
      <Title
        breadcrumbs={[
          <Typography sx={{py: 1}} variant="body1">
            App Url
          </Typography>,
        ]}
      />

      <Paper>
        <Table>
          <TableHead>
            <TableRow sx={{bgcolor: 'action.hover', position: 'relative'}}>
              <TableCell>Link Description</TableCell>
              <TableCell>URL</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell>Agent Referral Link</TableCell>
              <TableCell>
                <a href={url} target="_blank" rel="noreferrer">
                  {url}
                </a>
              </TableCell>
              <TableCell>
                <Tooltip title="Copy URL to Clipboard">
                  <span>
                    <IconButton onClick={() => handleCopy(url)}>
                      <ContentCopyIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default AppUrl;
