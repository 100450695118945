import {Tabs as MuiTabs} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

export const Tabs = withStyles((theme: Theme) =>
  createStyles({
    root: {
      alignSelf: 'flex-end',
    },
    indicator: {
      backgroundColor: theme.palette.primary.main,
    },
  })
)(MuiTabs);
