import {Box} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import ListSubheader from '@mui/material/ListSubheader';
import MuiPopper, {PopperProps} from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import {getSearchGroup, IndexableApplication, IndexableLead, useGlobalSearch} from '@ozark/common';
import {SearchIcon} from '@ozark/common/icons';
import {sortBy} from '@s-libs/micro-dash';
import {SyntheticEvent, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {SearchOptionLinkWrapper} from './SearchOptionLinkWrapper';
import type {EntityAutocompleteOptionConfig, Option} from './types';

const MIN_QUERY_LENGTH = 2;

interface GlobalSearchProps {
  applicationAutocompleteOptionConfig?: EntityAutocompleteOptionConfig<IndexableApplication>;
  leadAutocompleteOptionConfig?: EntityAutocompleteOptionConfig<IndexableLead>;
}

const GlobalSearch = ({
  applicationAutocompleteOptionConfig,
  leadAutocompleteOptionConfig,
}: GlobalSearchProps) => {
  const [options, setOptions] = useState<Option[]>([]);

  const location = useLocation();

  const {search, responses} = useGlobalSearch({
    applicationSearchOptions: {},
    leadSearchOptions: {},
  });

  useEffect(() => {
    const [applicationResponse, leadResponse] = responses;

    let applicationOptions: Option<IndexableApplication>[] = [];

    if (
      applicationResponse &&
      applicationResponse.hits.length &&
      applicationAutocompleteOptionConfig
    ) {
      applicationOptions = applicationResponse.hits.map(applicationHit => {
        const option: Option<IndexableApplication> = {
          ...applicationHit,
          group: `Applications :: ${getSearchGroup(applicationHit.disposition)}`,
          id: applicationHit.objectID,
          label: [
            `Applications :: ${getSearchGroup(applicationHit.disposition)}`,
            applicationHit.legalBusinessName,
            applicationHit.dbaName,
            applicationHit.distinguishableId ? `(${applicationHit.distinguishableId})` : '',
            applicationHit?.mid ? `— ${applicationHit?.mid}` : '',
          ]
            .filter(Boolean)
            .join(' '),
        };

        return {
          ...option,
          onSelect: () => applicationAutocompleteOptionConfig.onSelect?.(option),
          render: () => applicationAutocompleteOptionConfig.render(option),
          linkRoute: applicationAutocompleteOptionConfig.getLinkRoute?.(option),
        };
      });

      // to avoid autocomplete groupBy duplicated headers
      applicationOptions = sortBy(applicationOptions, application => application.group);
    }

    let leadOptions: Option<IndexableLead>[] = [];

    if (leadResponse && leadResponse.hits.length && leadAutocompleteOptionConfig) {
      leadOptions = leadResponse.hits.map(leadHit => {
        const option = {
          ...leadHit,
          group: `Leads :: ${leadHit.salesDisposition || 'NONE'}`,
          id: leadHit.objectID,
          label: [
            `Leads :: ${leadHit.salesDisposition || 'NONE'}`,
            leadHit.businessName,
            `(${leadHit.objectID})`,
          ].join(' '),
        };

        return {
          ...option,
          onSelect: () => leadAutocompleteOptionConfig.onSelect?.(option),
          render: () => leadAutocompleteOptionConfig.render(option),
          linkRoute: leadAutocompleteOptionConfig.getLinkRoute?.(option),
        };
      });

      // to avoid autocomplete groupBy duplicated headers
      leadOptions = sortBy(leadOptions, lead => lead.group);
    }

    // lift application search result if we are on the application pages
    const isApplicationPage = applicationAutocompleteOptionConfig?.pathsToLiftUpSearchResult?.some(
      path => location.pathname.indexOf(path) === 0
    );
    if (isApplicationPage) {
      // sort applications by search group
      const sortedApplicationOptions = applicationOptions.sort((a, b) => {
        const bSearchGroup = getSearchGroup(b.disposition)?.toLowerCase();
        return location.pathname.indexOf(`/${bSearchGroup}`) === 0 ? 1 : -1;
      });

      setOptions([...sortedApplicationOptions, ...leadOptions]);
      return;
    }

    // lift lead search result if we are on the leads pages
    const isLeadPage = leadAutocompleteOptionConfig?.pathsToLiftUpSearchResult?.some(
      path => location.pathname.indexOf(path) === 0
    );
    if (isLeadPage) {
      setOptions([...leadOptions, ...applicationOptions]);
      return;
    }

    // default search result order
    setOptions([...applicationOptions, ...leadOptions]);
  }, [
    applicationAutocompleteOptionConfig?.pathsToLiftUpSearchResult,
    leadAutocompleteOptionConfig?.pathsToLiftUpSearchResult,
    location.pathname,
    responses,
  ]);

  return (
    <Autocomplete
      blurOnSelect
      clearOnBlur
      filterSelectedOptions
      includeInputInList
      id="search"
      forcePopupIcon={false}
      filterOptions={items => items}
      getOptionDisabled={(option: Option) => !option.id}
      groupBy={(option: Option) => option.group}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      noOptionsText="No results"
      openOnFocus={false}
      options={options}
      PopperComponent={Popper}
      value={null} // to avoid filling input with option labels on change event
      onChange={(_event: SyntheticEvent<Element, Event>, option: Option | null) => {
        if (option?.onSelect) {
          option.onSelect();
        }
      }}
      onInputChange={(_event, newInputValue) => {
        if (!newInputValue || newInputValue.length < MIN_QUERY_LENGTH) {
          setOptions([]);
          return;
        }

        search(newInputValue);
      }}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          margin="none"
          placeholder="Search for anything..."
          variant="standard"
          onBlur={() => setOptions([])}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment sx={{mr: 2}} position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
        />
      )}
      renderGroup={params => (
        <li>
          <ListSubheader sx={{top: -8, lineHeight: 2.5}}>
            <Box sx={{mb: 1}}>{params.group}</Box>
            {applicationAutocompleteOptionConfig?.renderGroupHeader &&
              params.group.includes('Applications') &&
              applicationAutocompleteOptionConfig.renderGroupHeader({group: params.group})}
          </ListSubheader>

          <Box component="ul" sx={{p: 0}}>
            {params.children}
          </Box>
        </li>
      )}
      renderOption={(props, option: Option) => {
        if (option.render) {
          return (
            <li {...props}>
              <SearchOptionLinkWrapper linkRoute={option.linkRoute}>
                {option.render()}
              </SearchOptionLinkWrapper>
            </li>
          );
        }
      }}
      sx={{position: 'relative'}}
    />
  );
};

const Popper = (props: PopperProps) => (
  <MuiPopper
    {...props}
    placement="bottom-start"
    style={{
      minWidth: 800,
      width: window.innerWidth - 325,
      maxWidth: 1500,
    }}
  />
);

export {GlobalSearch};
