import {Application} from '../../../../documents';

export type MonthlySalesSummary = {
  monthYear: string;
  salesAmount: string;
  salesCount: string;
  averageTicket: string;
  creditAmount: string;
  creditCount: string;
};

export type PortfolioStatistics = {
  totalMids: number;
  yearlySummary: PortfolioSummary[];
};

export type PortfolioSummary = {
  monthYear: string;
  boardedCount: string;
};

export type DailyVolumeSummary = {
  date: string;
  salesAmount: string | number;
  salesCount: string;
  creditAmount: string | number;
  creditCount: string;
};

export type CardVolumeSummary = {
  cardType: string;
  accountFirstDigit?: string;
  salesAmount: string | number;
  salesCount: string;
  averageTicket: string | number;
  creditAmount: string | number;
  creditCount: string;
  cbCount?: string;
  cbVolume?: string;
};

export type MonthlyVolumeResult = {
  monthlyVolumeSummary: DailyVolumeSummary[];
  summaryByCardType: CardVolumeSummary[];
};

export type VolumeResult = {
  volumeSummary: DailyVolumeSummary[];
  summaryByCardType: CardVolumeSummary[];
  hasData: boolean;
};

export enum VolumeFilterTypes {
  Today = 'filter-today',
  OneWeek = 'filter-1-week',
  FourWeeks = 'filter-4-weeks',
  OneYear = 'filter-1-year',
  WTD = 'filter-wtd',
  MTD = 'filter-mtd',
  QTD = 'filter-qtd',
  YTD = 'filter-ytd',
  All = 'filter-all',
  Custom = 'custom',
}

export type DailyAuthorizationSummary = {
  date: string;
  approvalAmount: string;
  declineAmount: string;
};

export type CardAuthorizationSummary = {
  cardType: string;
  networkId: string;
  approvalCount: string;
  approvalAmount: string;
  declineCount: string;
  declineAmount: string;
};

export type AuthorizationDeclineSummary = {
  declineCode: string;
  declineAmount: string;
};

export type MonthlyAuthorizationResult = {
  monthlyAuthorizationSummary: DailyAuthorizationSummary[];
  summaryByCardType: CardAuthorizationSummary[];
  declineSummary: AuthorizationDeclineSummary[];
};

export type TrailingAgentVolume = {
  mid: string;
  dba: string;
  agentId: string;
  firstName: string;
  lastName: string;
  salesAmountVisaMcDiscThirtyDay: number;
  salesAmountAmexThirtyDay: number;
  salesAmountVisaMcDiscYTD: number;
  salesAmountAmexYTD: number;
  estimatedMonthlyCreditCardSalesVisaMcDisc: number;
  estimatedMonthlyCreditCardSalesAmex: number;
  estimatedAverageSale: number;
  averageActualSaleThirdayDay: number;
  averageActualSaleYTD: number;
};

export type MccInfo = {
  businessType: string;
  mcc: string;
  midsCount: number;
  sales: number;
  salesAmount: number;
  percentOfTotal: number;
};

export type MccInfoQueryResult = {
  business_type: string;
  mcc: string;
  mids_count: string;
  transactions_amount: string;
  transactions_count: string;
};

export type MidInfo = {
  mid: string;
  merchantName: string;
  salesCount: number;
  salesAmount: number;
  percentOfTotal?: number;
  avgTicket?: number;
};

export type MidInfoQueryResult = {
  mid: string;
  merchant_name: string | null;
  transactions_amount: number;
  transactions_count: number;
};

export type VDMPChargebackReportDates = {
  currentMonthStartDate: string;
  currentMonthEndDate: string;
  prevMonthStartDate: string;
  prevMonthEndDate: string;
};

export enum VDMPChargebackReportFields {
  mid = 'mid',
  agent = 'agent',
  legalBusinessName = 'legalBusinessName',
  doingBusinessAs = 'doingBusinessAs',
  mcc = 'mcc',
  visaChargebacksRatioByCount = 'visaChargebacksRatioByCount',
  visaChargebacksRatioByVolume = 'visaChargebacksRatioByVolume',
  visaChargebacksCount = 'visaChargebacksCount',
  mcChargebacksRatioByCount = 'mcChargebacksRatioByCount',
  mcChargebacksRatioByVolume = 'mcChargebacksRatioByVolume',
  mcChargebacksCount = 'mcChargebacksCount',
}

export enum VDMPChargebackFields {
  visaChargebacksRatioByCount = 'visaChargebacksRatioByCount',
  visaChargebacksRatioByVolume = 'visaChargebacksRatioByVolume',
  visaChargebacksCount = 'visaChargebacksCount',
  mcChargebacksRatioByCount = 'mcChargebacksRatioByCount',
  mcChargebacksRatioByVolume = 'mcChargebacksRatioByVolume',
  mcChargebacksCount = 'mcChargebacksCount',
}

export type VDMPChargebackReport = {
  applicationId: string | null;
  mid: string;
  agent: string | null;
  mcc: string;
  legalBusinessName: Application['legalBusinessName'] | null;
  doingBusinessAs: Application['doingBusinessAs'] | null;
  chargebacks: VDMPChargebacks[];
};

export type VDMPChargebacks = {
  visaChargebacksRatioByCount: number | null;
  visaChargebacksRatioByVolume: number | null;
  visaChargebacksCount: number;
  mcChargebacksRatioByCount: number | null;
  mcChargebacksRatioByVolume: number | null;
  mcChargebacksCount: number;
};

export type FraudSameBinReport = {
  totalCount: number;
  data: FraudSameBinRecord[];
};

export type FraudSameBinRecord = {
  mid: string;
  dba: string;
  legalName: string;
  agent: string;
  transactionsCountTotal: number;
  binsDistinctCount: number;
  binsInternational: number;
  binsProhibited: number;
  // calculated fields
  binToTransactionsAverage?: number;
  binsInternationalRatio?: number;
  binsProhibitedRatio?: number;
};

export enum FraudUnmatchedRefundsFields {
  dba = 'dba',
  legalName = 'legalName',
  amount = 'amount',
  agent = 'agent',
  accountNumberFirst6 = 'accountNumberFirst6',
  accountNumberLast4 = 'accountNumberLast4',
  transactionDate = 'transactionDate',
}

export type FraudUnmatchedRefundsRecord = {
  mid: string;
  dba: string;
  legalName: string;
  agent: string;
  transactionDate: string;
  transactionId: string;
  amount: number;
  accountNumberFirst6: string;
  accountNumberLast4: string;
  rowsCount: number;
};

export type FraudMidBinRecord = {
  bin: string;
  bankName: string;
  prepaidCard: boolean;
  international: boolean;
  prohibited: boolean;
  transactionsCount: string;
};

export type FraudMidBinReport = {
  totalCount: number;
  data: FraudMidBinRecord[];
};

export type FraudMidBinTransactionRecord = {
  transactionAmount: number;
  accountNumberLast4: string;
  transactionDate: string;
};

export type FraudMidBinTransactionReport = {
  totalCount: number;
  data: FraudMidBinTransactionRecord[];
};
