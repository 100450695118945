import {yupResolver} from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import FileUpload from '@mui/icons-material/FileUpload';
import {LoadingButton} from '@mui/lab';
import {
  Box,
  Button,
  Checkbox as MuiCheckbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select as MuiSelect,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {SelectChangeEvent} from '@mui/material/Select/SelectInput';
import {Loading, Select, TextField, UploadButton} from '@ozark/common/components';
import {
  ResourceDocumentCategoryView,
  ResourceInput,
  ResourceType,
  ResourceView,
  ResourceViewableByUsers,
  ResourceViewableByUsersNames,
  ResourceViewableByUsersPortalNames,
  VideoResource,
} from '@ozark/functions/src/documents';
import {isPreviewable} from '@ozark/functions/src/shared';
import {useCallback, useEffect, useRef, useState} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {useGroups, useUserInfo} from '../../hooks';
import NoPreview from '../../static/images/no_preview.png';
import {getErrorMessage} from '../../util';
import {AllGroups} from './types';
import {getPreviewUrl} from './utils';

const shapeBase = {
  title: yup.string().trim().required('Title is required'),
  category: yup.string().trim().required('Category is required'),
  vimeoLink: yup.string(),
  fileName: yup.string(),
  viewableByUsers: yup
    .array()
    .of(yup.string())
    .required('Viewable By is required')
    .min(1, 'Viewable By is required')
    .typeError('Viewable By is required'),
};

type IsNewCategoryState = {
  state: boolean; // actual state
  force: boolean; // true if force creating a new category
};

export type DocumentMetaData = {
  downloadUrl?: string;
  size?: number | null;
  contentType?: string | null;
  cloudPreviewImagePath?: string | null;
  cloudPath?: string | null;
  downloadPreviewUrl?: string | null;
};

export type DocumentResourceInput = ResourceInput & {
  isNewCategory: boolean;
  file: File | null;
  fileName?: string;
};

interface Props {
  resource?: ResourceView;
  resourceType: ResourceType;
  onClose: () => void;
  onSubmit: (data: DocumentResourceInput, existingResource?: ResourceView) => Promise<void>;
  documentCategories: ResourceDocumentCategoryView[] | undefined;
}

interface FormInputs {
  title: string;
  category: string;
  description: string;
  vimeoLink?: string;
  fileName?: string;
  viewableByUsers: ResourceViewableByUsers[];
  groups: string[];
}

interface GroupType {
  id: string;
  name: string;
}

const getSchema = (resourceType: ResourceType) => {
  const shape = {...shapeBase};
  if (resourceType === ResourceType.video) {
    shape.vimeoLink = yup.string().required('Vimeo link is required');
  } else {
    shape.fileName = yup.string().required('Select file to upload');
  }

  return yup.object().shape(shape);
};

const getFileName = (resource: ResourceView | undefined) => {
  if (!resource || !resource.cloudPath) {
    return undefined;
  }

  const name = 'file';

  if (resource.cloudPath.indexOf('.') === -1) {
    return name;
  }
  return `${name}.${resource.cloudPath.split('.').pop()}`;
};

const getVimeoLink = (resource: ResourceView | undefined) => {
  if (resource?.type !== ResourceType.video) {
    return undefined;
  }

  return (resource as VideoResource).vimeoLink;
};

export const UpsertResourceDialog = ({
  resourceType,
  resource,
  onClose,
  onSubmit,
  documentCategories,
}: Props) => {
  const {isPortal} = useUserInfo();
  const {documents: groups} = useGroups();
  const theme = useTheme();
  const fileUploadButtonRef = useRef<HTMLInputElement | null>(null);
  const [isNewCategory, setIsNewCategory] = useState<IsNewCategoryState>({
    state: false,
    force: false,
  });
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | undefined>(getPreviewUrl(resource));
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isSaving, setIsSaving] = useState(false);
  const [imgSrc, setImgSrc] = useState(NoPreview);
  const [availableGroups, setAvailableGroups] = useState<GroupType[] | undefined>();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<FormInputs>({
    resolver: yupResolver(getSchema(resourceType)),
    shouldUnregister: true,
    defaultValues: {
      title: resource?.title,
      category: resource?.category,
      description: resource?.description,
      vimeoLink: getVimeoLink(resource),
      fileName: getFileName(resource),
      viewableByUsers: resource?.viewableByUsers,
      groups: resource?.groups ?? [],
    },
  });
  const resourceNames = isPortal
    ? ResourceViewableByUsersPortalNames
    : ResourceViewableByUsersNames;

  const checkForceNewDocumentCategory = useCallback(async () => {
    // force creating a new category if has no document categories yet
    if (documentCategories?.length === 0) {
      setIsNewCategory({
        state: true,
        force: true,
      });
    }
  }, [documentCategories]);

  useEffect(() => {
    checkForceNewDocumentCategory();
  }, [checkForceNewDocumentCategory]);

  useEffect(() => {
    setImgSrc(imagePreview ?? NoPreview);
  }, [imagePreview]);

  useEffect(() => {
    if (groups.data === undefined) {
      setAvailableGroups(undefined);
      return;
    }
    const res: GroupType[] = [
      {
        id: AllGroups,
        name: 'All Groups',
      },
    ];

    groups.data.sortAndMap(x => {
      res.push(x);
    });
    setAvailableGroups(res);
  }, [groups.data]);

  const onSuccess: SubmitHandler<FormInputs> = async data => {
    const documentResource = {
      title: data.title,
      category: data.category,
      description: data.description,
      vimeoLink: data.vimeoLink,
      isNewCategory: isNewCategory.state,
      type: resourceType,
      deleted: false,
      file: fileToUpload,
      viewableByUsers: data.viewableByUsers,
      fileName: data.fileName,
      groups: data.groups ?? [],
    } as DocumentResourceInput;

    await onSubmit(documentResource, resource);
    onClose();
  };

  const beforeSubmit = async () => {
    try {
      setIsSaving(true);
      await handleSubmit(onSuccess)();
    } finally {
      setIsSaving(false);
    }
  };

  const getCategoriesList = () =>
    documentCategories &&
    documentCategories.sortAndMap(
      category => (
        <MenuItem key={category.id} value={category.name}>
          {category.name}
        </MenuItem>
      ),
      category => category.name
    );

  const handleSelectFile = ({file}: {file: File}) => {
    setFileToUpload(file);
    setValue('fileName', file.name, {shouldDirty: true});
  };

  const isVideoResourceType = resourceType === ResourceType.video;

  const selectFile = () => {
    fileUploadButtonRef.current?.click();
  };

  const removeFile = () => {
    setFileToUpload(null);
    setValue('fileName', '', {shouldDirty: true});
    setImagePreview(undefined);
  };

  useEffect(() => {
    if (!fileToUpload) {
      return;
    }

    if (!isPreviewable(fileToUpload.name)) {
      setImagePreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(fileToUpload);
    setImagePreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [fileToUpload]);

  const isRemoveFileMode = isVideoResourceType && getValues().fileName;
  const isDisabledViewableBy = (key: string, value: string[] | undefined) => {
    if (isPortal && resource?.shared) {
      return true;
    }

    const currentVal = value || [];
    if (currentVal.length === 0) {
      return false;
    }
    if (key === ResourceViewableByUsers.hidden) {
      return false;
    }
    return currentVal.includes(ResourceViewableByUsers.hidden);
  };
  const onViewableByChange = (e: SelectChangeEvent<ResourceViewableByUsers[]>) => {
    const newVal = e.target.value;
    if (newVal.includes(ResourceViewableByUsers.hidden)) {
      setValue('viewableByUsers', [ResourceViewableByUsers.hidden], {shouldDirty: true});
    } else {
      setValue('viewableByUsers', [...newVal] as ResourceViewableByUsers[], {shouldDirty: true});
    }
  };

  if (!documentCategories) return <Loading />;
  const addOrEdit = resource ? 'Edit' : 'Add';
  const docType = isVideoResourceType ? 'Video' : 'Document';
  const title = `${addOrEdit} ${docType}`;

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="create-dialog-title"
      fullScreen={fullScreen}
      maxWidth={'lg'}
    >
      <UploadButton
        id="uploadButton"
        sx={{display: 'none'}}
        name="uploadDocument"
        variant="outlined"
        color="secondary"
        errors={{uploadDocument: null}}
        size="large"
        setInputRef={inputRef => {
          fileUploadButtonRef.current = inputRef;
        }}
        onSelectFile={handleSelectFile}
      ></UploadButton>
      <DialogTitle id="create-dialog-title">{title}</DialogTitle>
      <DialogContent
        sx={{
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              width: 600,
            },
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField name="title" label="Title" errors={errors} control={control} required />
            </Grid>
            <Grid item xs={isNewCategory.force ? 12 : fullScreen ? 10 : 11}>
              {isNewCategory.state && (
                <TextField
                  name="category"
                  label="Category"
                  errors={errors}
                  control={control}
                  required
                />
              )}
              {!isNewCategory.state && (
                <Select name="category" label="Category" errors={errors} control={control} required>
                  {getCategoriesList()}
                </Select>
              )}
            </Grid>
            {!isNewCategory.force && (
              <Grid item xs={fullScreen ? 2 : 1} sx={{paddingTop: '22px !important'}}>
                <Tooltip
                  title={!isNewCategory.state ? 'Add new Category' : 'Discard and select Category'}
                >
                  <IconButton
                    onClick={() => {
                      setIsNewCategory({...isNewCategory, state: !isNewCategory.state});
                    }}
                    size="large"
                  >
                    {!isNewCategory.state ? <AddIcon /> : <CancelIcon />}
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            {isVideoResourceType && (
              <Grid item xs={12}>
                <TextField
                  name="vimeoLink"
                  label="Vimeo Link"
                  errors={errors}
                  control={control}
                  required
                />
              </Grid>
            )}
            <Grid item xs={fullScreen ? 10 : 11}>
              <TextField
                name="fileName"
                label={isVideoResourceType ? 'Video preview' : 'File to upload'}
                errors={errors}
                control={control}
                required={!isVideoResourceType}
                inputProps={{readOnly: true}}
              />
            </Grid>
            <Grid item xs={fullScreen ? 2 : 1} sx={{paddingTop: '22px !important'}}>
              <Tooltip
                title={
                  isRemoveFileMode
                    ? 'Remove file'
                    : isVideoResourceType
                    ? 'Upload an image for the video preview'
                    : 'Select document'
                }
              >
                <IconButton
                  onClick={() => {
                    if (isRemoveFileMode) {
                      removeFile();
                    } else {
                      selectFile();
                    }
                  }}
                  size="large"
                >
                  {isRemoveFileMode ? <CancelIcon /> : <FileUpload />}
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <img
                src={imgSrc}
                alt="Preview"
                width="180px"
                height="180px"
                onError={() => setImgSrc(NoPreview)}
              />
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={12}>
              <TextField name="description" label="Description" errors={errors} control={control} />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                sx={{
                  margin: theme.spacing(2, 0, 0, 0),
                }}
                fullWidth
                variant="outlined"
                error={Boolean(getErrorMessage('viewableByUsers', errors))}
              >
                <InputLabel id="viewableByUsers-label" required>
                  Viewable By
                </InputLabel>
                <Controller
                  control={control}
                  name="viewableByUsers"
                  render={({field: {onBlur, ref, value, name}}) => (
                    <MuiSelect
                      name={name}
                      variant="outlined"
                      labelId="viewableByUsers-label"
                      label="Viewable By"
                      id={name}
                      multiple
                      required
                      renderValue={selected => {
                        return Object.entries(resourceNames)
                          .filter(([key, _]) => selected.includes(key as ResourceViewableByUsers))
                          .map(([_, val]) => val)
                          .filter(x => !!x)
                          .join(', ');
                      }}
                      value={value || []}
                      onChange={onViewableByChange}
                      onBlur={onBlur}
                      ref={ref}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 48 * 12,
                            width: 250,
                          },
                        },
                      }}
                      fullWidth
                    >
                      {Object.entries(resourceNames).map(([key, val]) => {
                        const disabled = isDisabledViewableBy(key, value);
                        return (
                          <MenuItem key={key} value={key} disabled={disabled}>
                            <MuiCheckbox
                              checked={value?.includes(key as ResourceViewableByUsers) ?? false}
                              color="primary"
                              disabled={disabled}
                            />
                            <ListItemText primary={val} />
                          </MenuItem>
                        );
                      })}
                    </MuiSelect>
                  )}
                />
                <FormHelperText>
                  {getErrorMessage('viewableByUsers', errors)?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            {!isPortal && (
              <Grid item xs={12}>
                {availableGroups && availableGroups.length && (
                  <FormControl
                    sx={{
                      margin: theme.spacing(2, 0, 0, 0),
                    }}
                    fullWidth
                    variant="outlined"
                  >
                    <InputLabel id="groups-label">Groups</InputLabel>
                    <Controller
                      control={control}
                      name="groups"
                      render={({field: {onBlur, onChange, ref, value, name}}) => (
                        <MuiSelect
                          name={name}
                          labelId="groups-label"
                          label="Groups"
                          id={name}
                          multiple
                          renderValue={selected => {
                            return availableGroups
                              .filter(x => selected.includes(x.id))
                              .map(x => x.name)
                              .filter(x => !!x)
                              .join(', ');
                          }}
                          value={value || []}
                          onChange={onChange}
                          onBlur={onBlur}
                          ref={ref}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 48 * 12,
                                width: 250,
                              },
                            },
                          }}
                          fullWidth
                        >
                          {availableGroups &&
                            availableGroups.map(group => (
                              <MenuItem key={group.id} value={group.id}>
                                <MuiCheckbox
                                  checked={value?.includes(group.id as ResourceViewableByUsers)}
                                  color="primary"
                                />
                                <ListItemText primary={group.name} />
                              </MenuItem>
                            ))}
                        </MuiSelect>
                      )}
                    />
                  </FormControl>
                )}
              </Grid>
            )}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isSaving}>
          Cancel
        </Button>
        <LoadingButton onClick={beforeSubmit} color="primary" loading={isSaving}>
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
