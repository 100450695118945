import {Box} from '@mui/material';
import React from 'react';
import {CountTile, CountTileProp} from './CountTile';

export enum TileTypes {
  CountTile,
}

type Props = {
  tileType: TileTypes;
  data: CountTileProp[];
  tileProps?: any;
};

export const TilesList = ({tileType, data, tileProps}: Props) => {
  const renderComponent = React.useCallback(
    (tile: CountTileProp) => {
      switch (tileType) {
        case TileTypes.CountTile:
          return <CountTile key={tile.name} {...tile} tileProps={tileProps} />;
        default:
          return <>Empty Tile</>;
      }
    },
    [tileType]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {data.map(item => renderComponent(item))}
    </Box>
  );
};
