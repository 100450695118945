import {ApiBase, SearchCriteria, toQuery} from '@ozark/common';
import {ActiveFilter} from '@ozark/common/components';
import {
  AgentResidual,
  GroupAgentResponse,
  GroupResidual,
  PaginatedGroupAgentResponse,
  ResidualOverview,
  ResidualPayout,
} from '@ozark/functions/src/functions/express/private/types/Residual';
import {PaginatedResponse} from '@ozark/functions/src/shared';

export class ResidualsApi extends ApiBase {
  private getAgentParam = (agentId?: string) => {
    return agentId ? `firestoreAgentId__eq=${agentId}&` : '';
  };
  getResidualExport = (yearMonth: string) => {
    return this.get<any[]>(`residuals/export?yearMonth__eq=${yearMonth}`);
  };
  getResidualPayoutByAgent = (yearMonth: string) => {
    return this.get<ResidualPayout[]>(`residuals/profits?yearMonth__eq=${yearMonth}`);
  };
  getGroupResidualOverview = () => {
    return this.get<ResidualOverview[]>(`residuals/overview`);
  };
  getGroupResiduals = (
    searchCriteria: SearchCriteria,
    filters?: ActiveFilter[],
    agentId?: string
  ) => {
    const queryString = toQuery(searchCriteria, filters);
    return this.get<PaginatedResponse<GroupResidual>>(
      `residuals/group?${this.getAgentParam(agentId)}${queryString}`
    );
  };
  getGroupAgentAggregateResiduals = (
    searchCriteria: SearchCriteria,
    filters?: ActiveFilter[],
    agentId?: string
  ) => {
    const queryString = toQuery(searchCriteria, filters);
    return this.get<PaginatedGroupAgentResponse>(
      `residuals/group_agent_aggregate?${this.getAgentParam(agentId)}${queryString}`
    );
  };
  getGroupResidualsExport = (
    searchCriteria: SearchCriteria,
    filters?: ActiveFilter[],
    agentId?: string
  ) => {
    const queryString = toQuery(searchCriteria, filters);
    return this.get<GroupResidual[]>(
      `residuals/group/export?${this.getAgentParam(agentId)}${queryString}`
    );
  };
  getGroupAgentAggregateResidualsExport = (
    searchCriteria: SearchCriteria,
    filters?: ActiveFilter[],
    agentId?: string
  ) => {
    const queryString = toQuery(searchCriteria, filters);
    return this.get<GroupAgentResponse[]>(
      `residuals/group_agent_aggregate/export?${this.getAgentParam(agentId)}${queryString}`
    );
  };
  getAgentResiduals = (
    searchCriteria: SearchCriteria,
    filters?: ActiveFilter[],
    agentId?: string
  ) => {
    const queryString = toQuery(searchCriteria, filters);
    return this.get<PaginatedResponse<AgentResidual>>(
      `residuals/agent?${this.getAgentParam(agentId)}${queryString}`
    );
  };
  getAgentResidualsExport = (
    searchCriteria: SearchCriteria,
    filters?: ActiveFilter[],
    agentId?: string
  ) => {
    const queryString = toQuery(searchCriteria, filters);
    return this.get<AgentResidual[]>(
      `residuals/agent/export?${this.getAgentParam(agentId)}${queryString}`
    );
  };
}
