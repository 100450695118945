import {Box, FormHelperText} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {omit} from 'lodash';
import {useEffect, useState} from 'react';
import {AssociationType, TicketAssociation} from '../../../..';
import {useUserInfo} from '../../../../hooks/useUserInfo';
import {InputAssociationError} from '../../types';

type Props = {
  draft?: TicketAssociation;
  setDraft: (draft: TicketAssociation) => void;
  errors: Record<InputAssociationError, string>;
  setErrors: (errors: Record<InputAssociationError, string>) => void;
};

export function AssociationEditorType({draft, setDraft, errors, setErrors}: Props) {
  const {isPortal, isMerchant} = useUserInfo();
  const [options, setOptions] = useState<AssociationType[]>([]);

  const handleChange = (event: SelectChangeEvent) => {
    setDraft({type: event.target.value as AssociationType});
    if (event.target.value) {
      setErrors(omit(errors, 'type') as Record<InputAssociationError, string>);
    }
  };

  useEffect(() => {
    let optionsFiltered = Object.values(AssociationType).filter(o => o !== AssociationType.group);
    if (!isMerchant) {
      optionsFiltered = optionsFiltered.filter(o => o !== AssociationType.mid);
    }
    if (isPortal) {
      optionsFiltered = optionsFiltered.filter(o => o !== AssociationType.erpUser);
    }
    if (isMerchant) {
      optionsFiltered = optionsFiltered.filter(
        o =>
          ![AssociationType.merchant, AssociationType.agent, AssociationType.application].includes(
            o
          )
      );
    }
    setOptions(optionsFiltered);
    if (!draft?.type && optionsFiltered.length === 1) {
      setDraft({type: optionsFiltered[0]});
    }
  }, [isPortal, isMerchant]);

  return (
    <Box mt={2} minWidth={450}>
      <FormControl fullWidth variant="standard" error={Boolean(errors['type'])} required>
        <InputLabel id="assignee-type-label">Type</InputLabel>
        <Select
          labelId="assignee-type-label"
          id="assignee-type"
          value={draft?.type ?? ''}
          label="Type"
          onChange={handleChange}
        >
          {options.map(o => (
            <MenuItem key={o} value={o}>
              {o}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{errors['type']}</FormHelperText>
      </FormControl>
    </Box>
  );
}
