import {FormControl, FormHelperText, FormLabel, RadioGroup as MuiRadioGroup} from '@mui/material';
import {DefaultTheme} from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import {Controller} from 'react-hook-form';

const useStyles = makeStyles<DefaultTheme>(theme => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  legend: {
    marginBottom: theme.spacing(1),
    lineHeight: '1.5em',
  },
}));

interface Props {
  name: string;
  label: string;
  defaultValue?: boolean;
  required?: boolean;
  errors: any;
  autoFocus?: boolean;
  [others: string]: any;
}

export const BooleanRadioGroup = ({
  name,
  label,
  defaultValue,
  required,
  errors,
  control,
  autoFocus,
  children,
  ...other
}: Props) => {
  const classes = useStyles();
  return (
    <FormControl
      component="fieldset"
      error={Boolean(errors[name])}
      required={required}
      className={classes.formControl}
    >
      <FormLabel component="legend" className={classes.legend}>
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        rules={{required: required}}
        defaultValue={defaultValue}
        render={({field}) => (
          <MuiRadioGroup
            aria-label={name}
            id={name}
            value={field.value}
            data-test={name}
            onChange={e => field.onChange(e.target.value === 'true')}
            {...other}
          >
            {children}
          </MuiRadioGroup>
        )}
      />
      {errors[name] && <FormHelperText>{errors[name]?.message}</FormHelperText>}
    </FormControl>
  );
};
