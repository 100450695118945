import {Box, Divider} from '@mui/material';
import {useEffect, useState} from 'react';
import {useUserInfo} from '../../../..';
import {getApplicationByMid} from '../../../../firebase/queries/applications';
import {Detail} from '../DetailsRow';

type MerchantInfo = {
  applicationId: string;
  agent?: string;
  group?: string;
};

type Props = {
  mid?: string;
  dba?: string;
};

export const AssociationInfoMerchant = ({mid, dba}: Props) => {
  const {isAgent, isAgentMember, uid, isAgentAdmin, isMerchant, userGroupId} = useUserInfo();
  const [merchantInfo, setMerchantInfo] = useState<MerchantInfo>();

  useEffect(() => {
    if (!mid) return;
    let mounted = true;

    (async () => {
      const agentUid = isAgentMember ? uid : undefined;
      const groupUid = isAgentAdmin ? userGroupId : undefined;
      const app = await getApplicationByMid(mid, agentUid, groupUid);
      if (!app) return;
      if (!mounted) return;
      setMerchantInfo({
        applicationId: app.id,
        agent: app.agent && `${app.agent.firstName} ${app.agent.lastName}`.trim(),
        group: app.group && app.group.name,
      });
    })();

    return () => {
      mounted = false;
    };
  }, [mid]);

  if (!mid) return null;

  if (isMerchant) {
    return (
      <>
        <Detail title={dba} description="Merchant" />
        {mid && (
          <>
            <Divider sx={{my: 0.5}} />
            <Detail
              title={<Box sx={{fontSize: '0.95rem', whiteSpace: 'nowrap'}}>{mid}</Box>}
              description="Associated MID"
            />
          </>
        )}
      </>
    );
  }

  const applicationsBaseUrl = isAgent ? 'application' : 'applications';
  return (
    <>
      <Detail
        title={dba}
        description="Merchant"
        url={
          merchantInfo?.applicationId
            ? `/${applicationsBaseUrl}/${merchantInfo.applicationId}`
            : undefined
        }
      />

      {merchantInfo?.agent && (
        <>
          <Divider sx={{my: 0.5}} />
          <Detail title={merchantInfo.agent} description="Agent" />
        </>
      )}

      {merchantInfo?.group && (
        <>
          <Divider sx={{my: 0.5}} />
          <Detail title={merchantInfo.group} description="Group" />
        </>
      )}
    </>
  );
};
