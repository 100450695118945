import {ERPUserRoles, TicketLastReplyInfo} from '../../..';
import {useUserInfo} from '../../../hooks/useUserInfo';

export const useLastReplyAuthor = (ticketLastReplyInfo: TicketLastReplyInfo | undefined) => {
  const {isErpUser, uid} = useUserInfo();
  const isReplyAuthor = uid === ticketLastReplyInfo?.authorProfileId;
  const isReplyAuthorErpUser =
    ticketLastReplyInfo?.authorRole && ERPUserRoles.includes(ticketLastReplyInfo.authorRole);

  return (
    (ticketLastReplyInfo
      ? isReplyAuthor
        ? ticketLastReplyInfo.author
        : isReplyAuthorErpUser
        ? isErpUser
          ? ticketLastReplyInfo.author
          : `ERP department`
        : ticketLastReplyInfo.author
      : '') ?? 'Not Available'
  );
};
