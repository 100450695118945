import {Box, BoxTypeMap} from '@mui/material';

type Props = BoxTypeMap<{}, 'div'>['props'] & {
  title?: string;
  hideTitle?: boolean;
  useLinesEllipsis?: boolean;
  visibleLines?: string;
};

export const BoxEllipsis = ({
  children,
  sx,
  hideTitle,
  visibleLines,
  useLinesEllipsis,
  ...rest
}: Props) => {
  return (
    <Box
      title={!hideTitle && typeof children === 'string' ? children : undefined}
      sx={
        useLinesEllipsis
          ? {
              display: '-webkit-box',
              WebkitLineClamp: visibleLines ?? '1',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              ...sx,
            }
          : {
              display: 'block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              ...sx,
            }
      }
      {...rest}
    >
      {children}
    </Box>
  );
};
