import {useEffect, useState} from 'react';
import {
  areValidPasswordConditions,
  hasLowerCase as hasLowerCaseHelper,
  hasNumber as hasNumberHelper,
  hasSpecialChar as hasSpecialCharHelper,
  hasUpperCase as hasUpperCaseHelper,
  isMatch as isMatchHelper,
  isValidLength as isValidLengthHelper,
} from '../helpers';

interface Props {
  password: string | undefined;
  confirmation: string | undefined;
}
export const usePasswordValidation = ({password, confirmation}: Props) => {
  const [isValidLength, setIsValidLength] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [isMatch, setIsMatch] = useState(false);
  const isValid =
    areValidPasswordConditions(
      isValidLength,
      hasNumber,
      hasUpperCase,
      hasLowerCase,
      hasSpecialChar
    ) && isMatch;

  useEffect(() => {
    setIsValidLength(isValidLengthHelper(password));
    setHasUpperCase(hasUpperCaseHelper(password));
    setHasLowerCase(hasLowerCaseHelper(password));
    setHasNumber(hasNumberHelper(password));
    setHasSpecialChar(hasSpecialCharHelper(password));
    setIsMatch(isMatchHelper(password, confirmation));
  }, [password, confirmation]);

  return {
    isValidLength,
    hasNumber,
    hasUpperCase,
    hasLowerCase,
    hasSpecialChar,
    isMatch,
    isValid,
  };
};
