import {yupResolver} from '@hookform/resolvers/yup';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField as MuiTextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import {UserRoles} from '@ozark/common';
import {useState} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {useUserInfo} from '../../hooks';
import {getErrorMessage} from '../../util';

interface Props {
  onClose: () => void;
  folderNamesOptions: string[];
  onSubmit: (folderName: string) => Promise<void>;
}

type FormValues = {
  folderName?: string;
};

export const MoveForm = yup.object({
  folderName: yup.string().required('Folder Name is required'),
});

export const MoveAttachmentsDialog = ({onClose, folderNamesOptions, onSubmit}: Props) => {
  const theme = useTheme();
  const {isErpAdmin, role} = useUserInfo();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isSaving, setIsSaving] = useState(false);

  const onSuccess: SubmitHandler<FormValues> = async data => {
    if (!data.folderName) {
      return;
    }
    setIsSaving(true);
    try {
      await onSubmit(data.folderName);
    } finally {
      setIsSaving(false);
    }
  };

  const onSubmitClick = async () => {
    await handleSubmit(onSuccess)();
  };

  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm<FormValues>({
    resolver: yupResolver(MoveForm),
  });

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="create-dialog-title"
      fullScreen={fullScreen}
      maxWidth="lg"
    >
      <DialogTitle id="create-dialog-title">Move Attachments</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              width: 600,
            },
          }}
        >
          {!isErpAdmin && role !== UserRoles.underwriter && (
            <Alert severity="info">Files from system folders will not be moved.</Alert>
          )}
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Controller
                name="folderName"
                control={control}
                render={({field: {onChange, ...props}}) => (
                  <Autocomplete
                    options={folderNamesOptions}
                    freeSolo
                    disabled={isSaving}
                    onChange={(_e, value) => {
                      onChange(value);
                    }}
                    onInputChange={(_e, value) => {
                      onChange(value);
                    }}
                    renderInput={params => (
                      <MuiTextField
                        {...params}
                        label="Folder Name"
                        variant="outlined"
                        error={Boolean(getErrorMessage('folderName', errors))}
                        helperText={getErrorMessage('folderName', errors)?.message}
                        disabled={isSaving}
                        margin="normal"
                      />
                    )}
                    {...props}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton onClick={onSubmitClick} color="primary" loading={isSaving}>
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
