import {formatAsPercent} from '../../..';
import {ChartPie, Serie} from '../common/DashboardChartPie';
import {DataIsLoading} from '../common/DataIsLoading';
import {DataIsMissing, NoTransactions} from '../common/DataIsMissing';
import {WidgetProps} from '../common/types';

import {hasData} from '../common/utils';

export const WidgetTransactionsGraph = ({loading, data}: WidgetProps) => {
  if (loading) return <DataIsLoading />;
  if (!data) return <DataIsMissing />;

  const rows: Serie[] = [
    {
      id: 'Auth Approval',
      label: `Auth Approval: ${formatAsPercent(data.authApprovalRatio ?? 0)}`,
      value: data.authApprovalRatio,
    },
    {
      id: 'Auth Decline',
      label: `Auth Decline: ${formatAsPercent(data.authDeclinedRatio ?? 0)}`,
      value: data.authDeclinedRatio,
    },
  ];

  if (!hasData(rows.map(r => r.value))) {
    return <NoTransactions label="No transactions to graph" />;
  }

  return <ChartPie data={rows} valueFormatter={formatAsPercent} />;
};
