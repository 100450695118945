import {addMinutes} from 'date-fns';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {Firebase} from '../../../firebase';

export enum SnoozeTime {
  fiveMinutes = '5 minutes',
  fifteenMinutes = '15 minutes',
  thirtyMinutes = '30 minutes',
  sixtyMinutes = '60 minutes',
  tomorrow = 'Tomorrow',
}

const snoozeTimeMap: {[key in SnoozeTime]: number} = {
  [SnoozeTime.fiveMinutes]: 5,
  [SnoozeTime.fifteenMinutes]: 15,
  [SnoozeTime.thirtyMinutes]: 30,
  [SnoozeTime.sixtyMinutes]: 60,
  [SnoozeTime.tomorrow]: 1440, // 24 hours * 60 minutes
};

export const useImportantNotificationsSnooze = (userUid?: string, userCollection?: string) => {
  const location = useLocation();
  const [anchorSnoozeMenuElement, setAnchorSnoozeMenuElement] = useState<null | HTMLElement>(null);
  const openSnoozeMenu = Boolean(anchorSnoozeMenuElement);
  const [snoozeNotifications, setSnoozeNotifications] = useState<{
    isSnooze: boolean;
    date?: Date;
  } | null>(null);

  const handleOpenSnoozeMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorSnoozeMenuElement(event.currentTarget);
  };
  const handleCloseSnoozeMenu = () => setAnchorSnoozeMenuElement(null);

  // load snoozeNotificationsUntil time
  useEffect(() => {
    if (!userCollection) return;

    const unsubscribe = Firebase.firestore
      .collection(userCollection)
      .doc(userUid)
      .onSnapshot(
        snapshot => {
          const data = snapshot.data();
          if (data && data.snoozeNotificationsUntil) {
            const snoozeUntil = new Date(data.snoozeNotificationsUntil.seconds * 1000);
            setSnoozeNotifications({isSnooze: snoozeUntil > new Date(), date: snoozeUntil});
          } else {
            setSnoozeNotifications({isSnooze: false});
          }
        },
        err => {
          console.error(err);
        }
      );
    return () => {
      unsubscribe();
    };
  }, [userUid, userCollection, location]);

  const saveSnooze = (value: SnoozeTime) => {
    setAnchorSnoozeMenuElement(null);
    if (!userCollection) return;

    const snoozeUntil = addMinutes(new Date(), snoozeTimeMap[value]);

    Firebase.firestore
      .collection(userCollection)
      .doc(userUid)
      .set({snoozeNotificationsUntil: Firebase.Timestamp.fromDate(snoozeUntil)}, {merge: true})
      .then(() => {
        setSnoozeNotifications({isSnooze: true, date: snoozeUntil});
      })
      .catch(e => {
        console.error(e);
        return;
      });
  };

  return {
    openSnoozeMenu,
    snoozeNotifications,
    handleOpenSnoozeMenu,
    handleCloseSnoozeMenu,
    saveSnooze,
    anchorSnoozeMenuElement,
  };
};
