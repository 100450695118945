import CloseIcon from '@mui/icons-material/Close';
import {LoadingButton} from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import RichTextEditor from 'react-rte';
import {TicketAssociation, TicketTypeAndCategory, useUserInfo, ViewableByType} from '../../..';
import {useCreateNewTicket} from '../hooks/useCreateNewTicket';
import {InputAssociationError, InputCategoryError} from '../types';
import {AssociationStep} from './AssociationStep';
import {CategoryStep} from './CategoryStep';
import {DescriptionStep} from './DescriptionStep';
import {FunctionReturningBoolean} from './types';
import {ViewableByStep} from './ViewableByStep';

enum StepEnum {
  Category = 1,
  Association = 2,
  ViewableBy = 3,
  Description = 4,
}

const StepDescription = new Map<StepEnum, string>([
  [StepEnum.Category, 'Category'],
  [StepEnum.Association, 'Association'],
  [StepEnum.Description, 'Description'],
  [StepEnum.ViewableBy, 'Viewable By'],
]);

const firstStep = StepEnum.Category;
const lastStep = StepEnum.Description;

interface Props {
  handleClose: () => void;
  association?: TicketAssociation;
}

export const Wizard = ({handleClose, association}: Props) => {
  const {isMerchant} = useUserInfo();
  const [step, setStep] = useState(firstStep);
  const [isSaving, setIsSaving] = useState(false);
  const history = useHistory();

  const [typeAndCategoryErrors, setTypeAndCategoryErrors] = useState(
    {} as Record<InputCategoryError, string>
  );
  const [draftTypeAndCategory, setDraftTypeAndCategory] = useState<
    TicketTypeAndCategory | undefined
  >(undefined);

  const [associationErrors, setAssociationErrors] = useState(
    {} as Record<InputAssociationError, string>
  );
  const [draftAssociation, setDraftAssociation] = useState<TicketAssociation | undefined>(
    association
  );
  const [editorValue, setEditorValue] = useState(RichTextEditor.createEmptyValue());
  const [viewableBy, setViewableBy] = useState(ViewableByType.all);
  const [onNextClick, setOnNextClick] = useState<FunctionReturningBoolean | undefined>();
  const {createNewTicket} = useCreateNewTicket();

  const handleSubmit = async () => {
    try {
      if (!draftTypeAndCategory || !draftAssociation) {
        return;
      }
      setIsSaving(true);
      const ticketRef = await createNewTicket(
        draftTypeAndCategory,
        draftAssociation,
        editorValue.toString('html'),
        viewableBy
      );
      if (ticketRef) {
        history.push(`/tickets/id/${ticketRef.id}`);
      }
    } finally {
      setIsSaving(false);
    }
  };

  const handleBack = () => {
    if (isMerchant && step === lastStep) {
      setStep(StepEnum.Association);
      return;
    }
    setStep(step - 1);
  };
  const handleNext = async () => {
    if (onNextClick && !onNextClick()) {
      return;
    }
    if (step === lastStep) {
      await handleSubmit();
      return;
    }
    if (isMerchant && step === StepEnum.Association) {
      // Viewable step is not available for Merchants.
      // We should skip it for Merchants and display last step
      setStep(lastStep);
      return;
    }
    setStep(step + 1);
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>
        {`${StepDescription.get(step)}`}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{minWidth: '400px'}}>
        {step === StepEnum.Category && (
          <CategoryStep
            draft={draftTypeAndCategory}
            setDraft={setDraftTypeAndCategory}
            errors={typeAndCategoryErrors}
            setErrors={setTypeAndCategoryErrors}
            setOnNextClick={setOnNextClick}
          />
        )}
        {step === StepEnum.Association && (
          <AssociationStep
            draft={draftAssociation}
            setDraft={setDraftAssociation}
            errors={associationErrors}
            setErrors={setAssociationErrors}
            setOnNextClick={setOnNextClick}
          />
        )}
        {step === StepEnum.Description && (
          <DescriptionStep editorValue={editorValue} setEditorValue={setEditorValue} />
        )}
        {step === StepEnum.ViewableBy && (
          <ViewableByStep viewableBy={viewableBy} setViewableBy={setViewableBy} />
        )}
      </DialogContent>
      <DialogActions sx={{mt: 1, mb: 1, mx: 1}}>
        <Box>
          {step !== firstStep && <Button onClick={handleBack}>Back</Button>}
          <LoadingButton onClick={handleNext} loading={isSaving}>
            {step === lastStep && 'Save'}
            {step !== lastStep && 'Next'}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
