import {ResourceType, ResourceView} from '@ozark/common';
import {TooltipPlacement} from '../../../material-ui';
import {InlineTypography} from '../../Inline';
import {EditableField, useStyles} from './constants';
import {useDisplayContext} from './DisplayContext';
import {DisplayLink} from './DisplayLink';

interface Props {
  resourceView: ResourceView;
}
export const DisplayTitle = ({resourceView}: Props) => {
  const classes = useStyles();
  const {isRequiredFieldValid, handleSaveField, writePermissionsForResource} = useDisplayContext();
  return writePermissionsForResource(resourceView) ? (
    <InlineTypography
      tooltip="Click to edit Title"
      value={resourceView.title}
      className={classes.titleFont}
      onValidate={(newValue: string) => isRequiredFieldValid(EditableField.title, newValue)}
      setText={newValue => handleSaveField(EditableField.title, resourceView.id, newValue)}
      tooltipPlacement={TooltipPlacement.BottomStart}
    />
  ) : (
    <DisplayLink
      url={
        resourceView.type === ResourceType.document
          ? resourceView.downloadUrl
          : resourceView.vimeoLink
      }
      title={resourceView.title}
    />
  );
};
