import {Box, Chip, styled} from '@mui/material';
import Typography from '@mui/material/Typography';
import type {IndexableApplication} from '@ozark/common';
import {AccountStatus, Dispositions, getAccountStatusColor, getColor} from '@ozark/common';
import {BoxEllipsis} from '../common/BoxEllipsis';
import type {Option} from './types';

const ColumnValue = styled(BoxEllipsis)(({theme}) => ({
  padding: theme.spacing(0, 1),
}));

export const GRID_TEMPLATES = {
  erp: {
    default: '1fr 2.5fr 3fr 2fr repeat(2, 1fr)',
    boarded: 'repeat(2, 1fr) 1.5fr 1.5fr 1.5fr repeat(4, 1fr)',
  },
  portal: {
    default: '1fr 2.5fr 3fr 1.5fr repeat(2, 1fr)',
    boarded: 'repeat(2, 1fr) 1.5fr 1.5fr 1.5fr 1.5fr repeat(2, 1fr)',
  },
};

interface ApplicationOptionProps {
  option: Option<IndexableApplication>;
  isERP?: boolean;
}

export const getGridTemplate = (isApplicationBoarded: boolean, isERP?: boolean): string => {
  const template = isERP ? GRID_TEMPLATES.erp : GRID_TEMPLATES.portal;

  return isApplicationBoarded ? template.boarded : template.default;
};

const ApplicationOption = ({option, isERP}: ApplicationOptionProps) => {
  const isBoarded = option.disposition === Dispositions.boarded;

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Box
        sx={[option.disposition !== Dispositions.boarded && {color: getColor(option.disposition)}]}
      >
        &bull;&bull;&bull;&nbsp;&nbsp;
      </Box>

      <Typography
        sx={{
          display: 'grid',
          gridTemplateColumns: getGridTemplate(isBoarded, isERP),
          flexGrow: 1,
          alignItems: 'center',
        }}
        component="div"
        variant="caption"
      >
        <ColumnValue>{option.distinguishableId}</ColumnValue>
        {isBoarded && (
          <>
            <ColumnValue>{option.platform}</ColumnValue>
            <ColumnValue>{option.mid}</ColumnValue>
          </>
        )}
        <ColumnValue>{option.legalBusinessName}</ColumnValue>
        <ColumnValue>{option.doingBusinessAs}</ColumnValue>
        <ColumnValue>
          {option.firstName} {option.lastName}
        </ColumnValue>
        <ColumnValue>{option.customerServiceEmail}</ColumnValue>
        {isBoarded && (
          <>
            <ColumnValue title={option.accountStatus?.status ?? ''}>
              {option.accountStatus?.status && (
                <Chip
                  variant="outlined"
                  size="small"
                  sx={[
                    option.accountStatus.status !== AccountStatus.closed && {
                      color: getAccountStatusColor(option.accountStatus?.status),
                      borderColor: getAccountStatusColor(option.accountStatus?.status),
                    },
                  ]}
                  label={option.accountStatus?.status}
                />
              )}
            </ColumnValue>
            {isERP && <ColumnValue>{option.riskStatus}</ColumnValue>}
          </>
        )}
        {!isBoarded && <ColumnValue>{option.businessPhone}</ColumnValue>}
      </Typography>
    </Box>
  );
};

export {ApplicationOption};
