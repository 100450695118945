import {ReportingScheduledJobParams} from '@ozark/functions/src/documents';
import {Filter} from '../components';
import {toQuery} from '../util';
import {SearchCriteria} from './SearchCriteria';

export const getScheduledReportParameters = (
  searchCriteria: SearchCriteria,
  filters: Filter
): ReportingScheduledJobParams => {
  const jobSearchCriteria: SearchCriteria = {...searchCriteria, offset: 0, limit: 0};
  return {
    uiFilters: filters, //filter parameters to use to display in the UI
    urlQuery: toQuery(jobSearchCriteria, Object.values(filters)), //filter parameters to parse on a backend
  };
};
