import ClearIcon from '@mui/icons-material/Clear';
import {Autocomplete, Box, IconButton, TextField} from '@mui/material';
import {useState} from 'react';
import {AssigneeType, useGroups} from '../../..';
import {useTicketsFiltersContainer} from '../hooks/useTicketsFiltersContainer';
import {Option} from '../types';

const defaultValue: Option = {label: 'All Groups', value: ''};

export function TicketsListFilterAssigneeGroup() {
  const {groupIdFilter, setGroupIdFilter, assigneeTypeFilter} = useTicketsFiltersContainer();
  const {documents: groups} = useGroups();
  const [value, setValue] = useState<Option | undefined>(defaultValue);

  const isVisible = assigneeTypeFilter === AssigneeType.agent;
  if (!isVisible) return null;

  const options: Option[] = groups?.data?.map(g => ({label: g.name, value: g.id})) ?? [];
  options.unshift(defaultValue);

  return (
    <Box ml={2} display="flex" alignItems="flex-end">
      <Autocomplete
        disablePortal
        disableClearable
        options={options}
        value={value}
        onChange={(_: React.SyntheticEvent<Element, Event>, newValue: Option | null) => {
          setValue(newValue ?? undefined);
          setGroupIdFilter(newValue?.value || undefined);
        }}
        getOptionLabel={(item: Option) => item?.label ?? ''}
        isOptionEqualToValue={(option, value) => option?.label === value?.label}
        renderInput={params => (
          <TextField
            {...params}
            sx={{
              minWidth: 200,
              '& .MuiInput-root': {
                fontStyle: groupIdFilter ? 'inherit' : 'italic',
                color: groupIdFilter ? 'inherit' : 'gray',
              },
              '& .MuiInput-root:hover:before': {border: 'none'},
              '& .MuiInput-root:before': {border: 'none'},
              '& .Mui-disabled:before': {border: 'none', borderBottomStyle: 'none !important'},
            }}
            label="Assignee Group"
            variant="standard"
          />
        )}
      />
      {groupIdFilter && (
        <IconButton
          size="small"
          sx={{alignSelf: 'flex-end', marginBottom: '-1px'}}
          onClick={() => {
            setGroupIdFilter(undefined);
            setValue(defaultValue);
          }}
        >
          <ClearIcon />
        </IconButton>
      )}
    </Box>
  );
}
