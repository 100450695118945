export const StatementsIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g fill={'inherit'}>
        <path
          opacity={0.4}
          d="M384,128H272c-8.8,0-16-7.2-16-16V0H24C10.8,0,0,10.7,0,23.9l0,0V488c0,13.2,10.7,24,23.9,24l0,0H360
		c13.2,0,24-10.7,24-23.9l0,0V128z M64,72c0-4.4,3.6-8,8-8h80c4.4,0,8,3.6,8,8v16c0,4.4-3.6,8-8,8H72c-4.4,0-8-3.6-8-8V72z M64,152
		v-16c0-4.4,3.6-8,8-8h80c4.4,0,8,3.6,8,8v16c0,4.4-3.6,8-8,8H72C67.6,160,64,156.4,64,152z M208,415.9V440c0,4.4-3.6,8-8,8h-16
		c-4.4,0-8-3.6-8-8v-24.3c-11.4-0.6-22.3-4.5-31.4-11.4c-3.5-2.7-4.1-7.7-1.4-11.2c0.3-0.3,0.5-0.6,0.8-0.9l11.8-11.2
		c2.8-2.5,7-2.9,10.1-0.7c3.8,2.4,8.3,3.7,12.8,3.7h28.1c6.5,0,11.8-5.9,11.8-13.2c0-6-3.6-11.2-8.8-12.7l-45-13.5
		c-18.6-5.6-31.6-23.4-31.6-43.4c0-24.5,19.1-44.4,42.7-45.1V232c0-4.4,3.6-8,8-8h16c4.4,0,8,3.6,8,8v24.3
		c11.4,0.6,22.3,4.5,31.4,11.4c3.5,2.7,4.1,7.7,1.4,11.2c-0.3,0.3-0.5,0.6-0.8,0.9L228.2,291c-2.8,2.5-7,2.9-10.1,0.7
		c-3.8-2.4-8.3-3.7-12.8-3.7h-28.1c-6.5,0-11.8,5.9-11.8,13.2c0,6,3.6,11.2,8.8,12.7l45,13.5c18.6,5.6,31.6,23.4,31.6,43.4
		C250.7,395.3,231.7,415.2,208,415.9L208,415.9z"
        />
        <path
          d="M377,105L279.1,7c-4.5-4.5-10.6-7-17-7H256v112c0,8.8,7.2,16,16,16h112v-6.1C384,115.6,381.5,109.5,377,105z
		 M219.1,327.4l-45-13.5c-5.2-1.5-8.8-6.8-8.8-12.7c0-7.3,5.3-13.2,11.8-13.2h28.1c4.5,0,9,1.3,12.8,3.7c3.1,2.1,7.3,1.8,10.1-0.7
		l11.8-11.2c3.2-3,3.4-8.1,0.3-11.3c-0.3-0.3-0.6-0.6-0.9-0.8c-9.1-6.8-20-10.8-31.4-11.3V232c0-4.4-3.6-8-8-8h-16c-4.4,0-8,3.6-8,8
		v24.1c-23.6,0.6-42.7,20.5-42.7,45.1c0,20,13,37.8,31.6,43.4l45,13.5c5.2,1.5,8.8,6.8,8.8,12.7c0,7.3-5.3,13.2-11.8,13.2h-28.1
		c-4.5,0-9-1.3-12.8-3.7c-3.1-2.1-7.3-1.8-10.1,0.7L144,392.2c-3.2,3-3.4,8.1-0.3,11.3c0.3,0.3,0.6,0.6,0.9,0.8
		c9.1,6.8,20,10.8,31.4,11.3V440c0,4.4,3.6,8,8,8h16c4.4,0,8-3.6,8-8v-24.1c23.7-0.6,42.7-20.5,42.7-45.1
		C250.7,350.8,237.7,333,219.1,327.4L219.1,327.4z"
        />
      </g>
    </svg>
  );
};
