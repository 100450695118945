import DeleteIcon from '@mui/icons-material/Delete';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton, {IconButtonProps} from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import {NoteReply, NoteView} from '@ozark/common';
import firebase from 'firebase/compat';
import {useMemo, useState} from 'react';
import RichTextEditor, {EditorValue} from 'react-rte';
import {withOverflowConfirmation} from '../../hocs';
import {StackOfAttachments} from '../StackOfAttachments';
import {StackAttachment} from '../StackOfAttachments/types';
import {isRteValueEmpty, timestampToFormattedDateTime} from './helpers';
import {useEditorWrapperStyles} from './hooks/useEditorWrapperStyles';
import {NoteFileUpload} from './NoteFileUpload';
import {NotesUsersProfiles} from './types';
import {useNoteContext} from './utils/NoteContext';

interface MessageProps {
  allowAddAttachments?: boolean;
  allowDeleteAttachments?: boolean;
  attachments?: StackAttachment[];
  entityView: NoteView | NoteReply;
  firestoreDocumentRef: firebase.firestore.DocumentReference | null;
  isReplyFormOpen?: boolean;
  parentNoteId?: string;
  onAttachmentRemove?: (attachmentId?: string) => Promise<void>;
  onEdit: (id: string, message: string) => Promise<boolean>;
  onDelete: (id: string) => Promise<void>;
  profiles: NotesUsersProfiles;
}

const ButtonDelete = withOverflowConfirmation<IconButtonProps>(IconButton);

const Message = ({
  allowAddAttachments,
  allowDeleteAttachments,
  attachments,
  entityView,
  firestoreDocumentRef,
  isReplyFormOpen,
  parentNoteId,
  onAttachmentRemove,
  onEdit,
  onDelete,
  profiles,
}: MessageProps) => {
  const [nextMessage, setNextMessage] = useState<EditorValue | null>(null);

  const {allowEditMessages, allowDeleteMessages, notesSubCollection, onEditClick, onReplyClick} =
    useNoteContext();

  const editorWrapperClasses = useEditorWrapperStyles();

  const authorProfile = profiles[entityView.uid];

  const authorInfo = useMemo(() => {
    if (authorProfile) {
      return authorProfile.displayName;
    }

    if (entityView.authorEmail) {
      return entityView.authorEmail;
    }

    return 'User not found';
  }, [authorProfile, entityView.authorEmail, entityView.uid]);

  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar alt={authorInfo} src={authorProfile?.photoUrl ?? undefined} />
      </ListItemAvatar>

      <ListItemText
        primary={
          <Box alignItems="center" display="flex" mb={1.5}>
            <Typography variant="overline" color="textPrimary">
              {authorInfo}
            </Typography>

            <Typography color="textSecondary" sx={{ml: '16px'}} variant="caption">
              {entityView.createdAt && timestampToFormattedDateTime(entityView.createdAt)}
            </Typography>

            {/*Reply button is for active notes only, not replies*/}
            {!parentNoteId && !entityView.deletedAt && (
              <Button
                sx={{ml: '16px', height: '26px', lineHeight: '26px'}}
                variant="outlined"
                onClick={() => {
                  onReplyClick(isReplyFormOpen ? null : entityView.id);
                }}
              >
                {isReplyFormOpen ? 'Cancel' : 'Reply'}
              </Button>
            )}

            {!entityView.deletedAt && (
              <Box ml="auto" display="flex" alignItems="center">
                {allowEditMessages && (
                  <Button
                    sx={{height: '26px', lineHeight: '26px'}}
                    variant="outlined"
                    onClick={() => {
                      setNextMessage(
                        !!nextMessage
                          ? null
                          : RichTextEditor.createValueFromString(entityView.message, 'html')
                      );

                      onEditClick(!!nextMessage ? null : entityView.id);
                    }}
                  >
                    {!!nextMessage ? 'Cancel' : 'Edit'}
                  </Button>
                )}

                {allowDeleteMessages && !entityView.deletedAt && (
                  <ButtonDelete
                    id="delete-confirmation-menu"
                    primaryButtonColor="error"
                    primaryButtonText="Delete"
                    secondaryButtonText="Cancel"
                    title={`Delete message${!!attachments?.length ? ' with attachments' : ''}?`}
                    tooltip="Delete message"
                    onClick={() => onDelete(entityView.id)}
                  >
                    <DeleteIcon color="secondary" />
                  </ButtonDelete>
                )}

                {allowAddAttachments && !!notesSubCollection && (
                  <NoteFileUpload
                    applicationId={firestoreDocumentRef?.id}
                    noteId={entityView.id}
                    notesSubCollection={notesSubCollection}
                  />
                )}
              </Box>
            )}
          </Box>
        }
        secondary={
          <>
            {entityView.deletedAt && entityView.deletedBy ? (
              <Box fontSize={12} fontStyle="italic">
                Deleted {timestampToFormattedDateTime(entityView.deletedAt)} by{' '}
                {profiles[entityView.deletedBy]?.displayName ?? 'Unknown user'}
              </Box>
            ) : (
              <>
                <Box
                  mb="-5px"
                  mt="-15px"
                  className="richtext-container"
                  dangerouslySetInnerHTML={{__html: entityView.message}}
                />

                {entityView.updatedAt && entityView.updatedBy && (
                  <Box fontSize={12} fontStyle="italic" mt={2}>
                    Edited {timestampToFormattedDateTime(entityView.updatedAt)} by{' '}
                    {profiles[entityView.updatedBy]?.displayName ?? 'Unknown user'}
                  </Box>
                )}
              </>
            )}

            {!!nextMessage && (
              <div className={editorWrapperClasses.root}>
                <RichTextEditor
                  value={nextMessage}
                  onChange={setNextMessage}
                  placeholder="Leave a comment"
                  editorStyle={{
                    minHeight: '200px',
                    fontFamily: 'Rubik, sans-serif',
                  }}
                />
                <Box sx={{py: 2, textAlign: 'right'}}>
                  <Button
                    color="primary"
                    disabled={isRteValueEmpty(nextMessage)}
                    size="medium"
                    variant="contained"
                    onClick={async () => {
                      if (isRteValueEmpty(nextMessage)) {
                        return;
                      }

                      const isOk = await onEdit(entityView.id, nextMessage.toString('html'));
                      if (isOk) {
                        setNextMessage(null);
                      }
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </div>
            )}

            {!!attachments?.length && (
              <Box mt={1} mb={0.5}>
                <StackOfAttachments
                  flexDirection="row"
                  attachments={attachments}
                  removeAttachment={allowDeleteAttachments ? onAttachmentRemove : undefined}
                />
              </Box>
            )}
          </>
        }
      />
    </ListItem>
  );
};

export {Message};
