import {Ticket} from '@ozark/common';
import {LoadingStatus} from '../../Analytics/common/LoadingStatus';
import {BoxCentered} from '../../common';
import {InfiniteDocuments} from '../../InfiniteDocuments';
import {useTicketsAllErp} from '../hooks/useTicketsAllErp';
import {useTicketsQuery} from '../hooks/useTicketsQuery';
import {TicketsListCard} from './TicketsListCard';

export const TicketsListViewAllErp = () => {
  const {statusSet, filterKey} = useTicketsQuery();

  const {tickets, hasData, loading, infiniteTicketsNext} = useTicketsAllErp(statusSet, filterKey);

  return (
    <>
      {(loading || !hasData) && (
        <BoxCentered flex={1}>
          <LoadingStatus loading={loading} hasData={hasData} />
        </BoxCentered>
      )}
      {hasData && !loading && (
        <InfiniteDocuments
          documents={tickets}
          next={infiniteTicketsNext}
          itemSize={190}
          onDocumentRender={(ticket: Ticket) => <TicketsListCard ticket={ticket} />}
        />
      )}
    </>
  );
};
