import {Box, Grow, Stack} from '@mui/material';
import {LoadingStatus} from '../../Analytics/common/LoadingStatus';
import {BoxCentered, BoxParentHeight} from '../../common';
import {useTicketTypes} from '../hooks/useTicketTypes';
import {TicketTypeCard} from './TicketTypeCard';

export const TicketTypesList = () => {
  const {ticketTypes} = useTicketTypes();
  const loading = Boolean(ticketTypes?.promised);
  const hasData = Boolean(ticketTypes?.data?.length);

  return (
    <>
      {!hasData && (
        <BoxCentered flex={1}>
          <LoadingStatus loading={loading} hasData={hasData} />
        </BoxCentered>
      )}
      {hasData && (
        <BoxParentHeight ml={2}>
          <Stack pt={0.5}>
            {ticketTypes?.data &&
              ticketTypes.data.map(ticketType => (
                <Grow in key={ticketType.id}>
                  <Box mr={2} mb={2}>
                    <TicketTypeCard ticketType={ticketType} />
                  </Box>
                </Grow>
              ))}
          </Stack>
        </BoxParentHeight>
      )}
    </>
  );
};
