import {Breadcrumbs, Divider, Grid} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {Fragment, ReactElement, ReactNode} from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      height: theme.spacing(8),
      color: '#586069',
      padding: theme.spacing(0, 1, 0, 0),
    },
    space: {
      flexGrow: 1,
    },
    divider: {
      margin: theme.spacing(0, 0, 2),
    },
  })
);

type Props = {titleText: string; breadcrumbs: (ReactElement | null)[]; children?: ReactNode};

export const Title = (props: Props) => {
  const classes = useStyles();
  const _breadcrumbs = props.breadcrumbs.filter(e => e !== null) as ReactElement[];
  return (
    <Fragment>
      <Grid className={classes.actions} container justifyContent="center" alignItems="center">
        {props.breadcrumbs && (
          <Breadcrumbs aria-label="breadcrumb">
            {_breadcrumbs.map((e: ReactElement, i: any) => React.cloneElement(e, {key: i}))}
          </Breadcrumbs>
        )}
        <div className={classes.space} />
        {props.children}
      </Grid>
      <Divider className={classes.divider} />
    </Fragment>
  );
};
