import {DatePicker} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {Box, CircularProgress, Grid, Paper, TextField, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {MonthlyAuthorizationResult} from '@ozark/functions/src/shared';
import {format} from 'date-fns';
import {useEffect, useState} from 'react';
import {useApiContainer, useMidsContainer} from '../..';
import {AllMIDs} from '../../../functions/src/documents/Mid';
import {MidSelect} from '../MidSelect/MidSelect';
import {Title} from '../Title';
import {AuthorizationSummaryByCardTypePieChart} from './AuthorizationSummaryByCardTypePieChart';
import {AuthorizationSummaryByCardTypeTable} from './AuthorizationSummaryByCardTypeTable';
import {AuthorizationSummaryDetailGraph} from './AuthorizationSummaryDetailGraph';

const useStyles = makeStyles(() => ({
  grow: {
    flex: 1,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
  },
  midButton: {
    textTransform: 'none !important' as any,
  },
}));

const AuthorizationSummaryPage = () => {
  const classes = useStyles();
  const [selectedDate, setDateChange] = useState<Date | null>(new Date());
  const [report, setReport] = useState<MonthlyAuthorizationResult>();
  const {selectedMid, handleSelectMid, mids} = useMidsContainer();
  const api = useApiContainer();

  useEffect(() => {
    if (!api || !selectedDate) return;
    const retrieveReport = async () => {
      const report = await api.reports.getMonthlyAuthorizationDetail(
        format(selectedDate, 'MM/yyyy'),
        selectedMid === AllMIDs ? undefined : selectedMid
      );
      setReport(report);
    };

    retrieveReport();
  }, [api, selectedDate, selectedMid]);

  const onDateChange = (date: Date | null) => {
    setDateChange(date);
  };

  const getBreadcrumbs = () => {
    return [<Typography variant="body1">Monthly Authorization Details</Typography>];
  };

  return (
    <Paper className={classes.root}>
      <Title breadcrumbs={getBreadcrumbs()}>
        <div className={classes.grow} />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            views={['year', 'month']}
            label="Year and Month"
            maxDate={new Date()}
            value={selectedDate}
            onChange={onDateChange}
            renderInput={params => <TextField variant="standard" {...params} />}
          />
        </LocalizationProvider>
        <Box mr={2} />
        <MidSelect selectedMid={selectedMid} handleSelectMid={handleSelectMid} mids={mids} />
      </Title>

      {report ? (
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <AuthorizationSummaryDetailGraph monthlyAuthorizationResult={report} />
          </Grid>
          <Grid item xs={12}>
            <AuthorizationSummaryByCardTypeTable summaryByCardType={report.summaryByCardType} />
          </Grid>
          <Grid item xs={12}>
            <AuthorizationSummaryByCardTypePieChart declineSummary={report.declineSummary} />
          </Grid>
        </Grid>
      ) : (
        <div className={classes.loading}>
          <CircularProgress color="inherit" />
        </div>
      )}
    </Paper>
  );
};

export default AuthorizationSummaryPage;
