import PhoneNumber from 'awesome-phonenumber';
import firebase from 'firebase/compat/app';
import {DocumentBase} from '.';
import {
  getMerchantStatus,
  getSearchTokens,
  MerchantStatus,
  SearchableTokens,
  SnoozeNotificationTime,
} from '../shared';
import {UniversalTimestamp} from './compat';
import {UserMFASettings} from './Multifactor';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export type Merchant = DocumentBase &
  SnoozeNotificationTime & {
    email: string;
    createdAt: UniversalTimestamp;
    isActive: boolean;
    applicationMids: string[];
    pendingApplicationIds?: string[];
    firstName: string;
    lastName: string;
    phoneNumber: string;
    timeZoneId: string;
    groupId?: string;
    masterUid?: string;
    lastLoginAt?: UniversalTimestamp;
    customerServiceEmail?: string;
    preferredCommunicationMethod?: PreferredCommunicationMethod;
    photoUrl?: string;
    mfa?: UserMFASettings;
  };

export enum PreferredCommunicationMethod {
  phone = 'Phone',
  email = 'Email',
}

export type MerchantView = ViewBase<Merchant> & {merchantStatus: MerchantStatus};
export type SearchableMerchantView = MerchantView & SearchableTokens;

export const selectMerchantView: ViewSelector<MerchantView, Merchant> = snapshot => {
  const data = snapshot.data();
  const merchant: MerchantView = {
    ...defaultViewSelector(snapshot),
    phoneNumber: data.phoneNumber
      ? new PhoneNumber(data.phoneNumber, 'US').getNumber('national')
      : data.phoneNumber,
    merchantStatus: getMerchantStatus(data),
  };

  return merchant;
};

export const selectSearchableMerchantView: ViewSelector<
  SearchableMerchantView,
  Merchant
> = snapshot => {
  const data = snapshot.data();
  const fieldValues = [data.email, data.firstName, data.lastName];
  const searchTokens = getSearchTokens(fieldValues);

  const merchant: SearchableMerchantView = {
    ...selectMerchantView(snapshot),
    searchTokens,
  };

  return merchant;
};

export type MerchantInfo = Merchant & {id: string; merchantStatus: MerchantStatus};

export function selectMerchantInfo(
  doc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
): MerchantInfo {
  const data = doc.data() as Merchant;
  const merchant: MerchantInfo = {
    ...data,
    id: doc.id,
    phoneNumber: data.phoneNumber
      ? new PhoneNumber(data.phoneNumber, 'US').getNumber('national')
      : data.phoneNumber,
    merchantStatus: getMerchantStatus(data),
  };

  return merchant;
}
