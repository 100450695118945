import {useState} from 'react';
import {createContainer} from 'unstated-next';
import {getWeekDatesFromDate} from '../utils';

function useWeekDates() {
  const [weekDates, setWeekDates] = useState(getWeekDatesFromDate(new Date()));
  return {
    weekDates,
    setWeekDates,
  };
}

export const WeekDatesContainer = createContainer(useWeekDates);
export const useWeekDatesContainer = WeekDatesContainer.useContainer;
