import {FilterOption} from '../../../Filters';

export type PaginatedTransactions = {
  sumTransactionAmount: number;
  maxTransactionAmount: number;
  minTransactionAmount: number;
};

export enum TerminalActualEntryModes {
  Unknown = 'Unknown',
  Other = 'Other',
  Keyed = 'Keyed',
  Swiped = 'Swiped',
  Contactless = 'Contactless',
  Chip = 'Chip',
}

/*
  '00': 'Unknown',
  '01': 'Manual (key entry)',
  '02': 'Magnetic stripe read',
  '03': 'Bar code/Payment code',
  '05': 'Integrated circuit card read (CVV data reliable)',
  '06': 'Track One read',
  '07': 'Contactless chip',
  '09': 'PAN entry via electronic commerce, includes remote chip',
  '10': 'Payment Credential stored on file',
  '80': 'Unable to process/mag stripe read',
  '81': 'Contactless',
  '90': 'Magnetic stripe read',
  '91': 'Contactless magnetic stripe',
  '95': 'Integrated circuit card read (CVV data may be unreliable)'
*/
export const TerminalEntryModesMap: {[_ in TerminalActualEntryModes]: string[]} = {
  [TerminalActualEntryModes.Unknown]: ['00'],
  [TerminalActualEntryModes.Other]: ['03', '10'],
  [TerminalActualEntryModes.Keyed]: ['01'],
  [TerminalActualEntryModes.Swiped]: ['02', '06', '80', '90'],
  [TerminalActualEntryModes.Contactless]: ['07', '81', '91'],
  [TerminalActualEntryModes.Chip]: ['05', '09', '95'],
};

export const terminalEntryModeOptions = Object.entries(TerminalEntryModesMap).map(
  ([key, value]) => ({
    key: (value as string[]).map(v => v.replace(/^0/, '')).join(','),
    value: key,
  })
);

export const TransactionFilters: FilterOption[] = [
  {
    id: 'mid',
    column: 'mid',
    label: 'MID',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'batchId',
    column: 'batchId',
    label: 'Batch ID',
    type: 'text',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'originalDate',
    column: 'originalTransactionDate',
    label: 'Transaction Date',
    type: 'dateOnly',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'originalDateRange',
    column: 'originalTransactionDate',
    label: 'Transaction Date Range',
    type: 'dateOnlyRange',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'statementDate',
    column: 'transactionDate',
    label: 'Statement Date',
    type: 'month',
    operators: [
      {
        id: '__between',
        label: 'equals',
      },
    ],
  },
  {
    id: 'statementDateRange',
    column: 'transactionDate',
    label: 'Statement Date Range',
    type: 'monthRange',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'accountNumberLast4',
    column: 'accountNumberLast4',
    label: 'Last 4',
    type: 'text',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'transactionAmount',
    column: 'transactionAmount',
    label: 'Amount',
    type: 'minorUnits',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'authorizationNumber',
    column: 'authorizationNumber',
    label: 'Authorization Code',
    type: 'text',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'posEntryMode',
    column: 'posEntryMode',
    label: 'Entry Mode',
    type: 'list',
    options: terminalEntryModeOptions,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
];
