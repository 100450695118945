import {isFunction} from '@s-libs/micro-dash';
import {ReportExportColumn} from './ReportExportColumn';

export const mapReportExportData = (
  rows: Record<string, unknown>[],
  columnsConfig: ReportExportColumn<any>[],
  customMapping?: (
    row: Record<string, unknown>,
    colConfig: ReportExportColumn<any>
  ) => {
    wasMapped: boolean;
    value?: unknown;
  }
) => {
  const colsExportable = columnsConfig.filter(col => col.export);
  const keysExportable = colsExportable.map(col => col.id);
  const getColConfig = (id: string) => colsExportable.find(col => col.id === id);
  return rows.map(row => {
    const rowCell: Record<string, unknown> = {};
    for (const key of keysExportable) {
      const colConfig = getColConfig(key);
      if (!colConfig) throw new Error(`invalid column key ${key} - not present in Type`);

      if (colConfig.export && isFunction(colConfig.export)) {
        rowCell[colConfig.label] = colConfig.export(row, colConfig.id);
        continue;
      }

      if (customMapping) {
        const mappingResult = customMapping(row, colConfig);
        if (mappingResult.wasMapped) {
          rowCell[colConfig.label] = mappingResult.value;
          continue;
        }
      }

      rowCell[colConfig.label] = row[key];
    }
    return rowCell;
  });
};
