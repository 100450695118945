import FilterListIcon from '@mui/icons-material/FilterList';
import {Chip, Grid, IconButton} from '@mui/material';
import {isEmpty} from '@s-libs/micro-dash';
import {format} from 'date-fns';
import {ListOption} from '../../../models';
import {Filter} from '../common/Types/index';
import {useReportingPageStyles} from '../Reporting';
import {getStatusName} from './Types';

type Props = {
  filters: Filter;
  handleDeleteFilter: (id: string) => () => void;
};

export const ChargebacksFilters = ({filters, handleDeleteFilter}: Props) => {
  const classes = useReportingPageStyles();

  return (
    <Grid item xs={12}>
      {filters && !isEmpty(filters) && (
        <IconButton disabled size="large">
          <FilterListIcon />
        </IconButton>
      )}
      {filters &&
        Object.keys(filters).map(key => {
          const filter = filters[key];
          if (filter.option.type === 'dateRange' && filter.operator.id === '__between') {
            return (
              <Chip
                key={`${key}-${filter.operator.id}`}
                className={classes.chip}
                label={
                  <span>
                    <b>{filter.option.label}</b> {filter.operator.label} '
                    <b>{format(filter.value?.[0] as Date, 'MM/dd/yyyy h:mm a')}</b>' and '
                    <b>{format(filter.value?.[1] as Date, 'MM/dd/yyyy h:mm a')}</b>'
                  </span>
                }
                variant="outlined"
                onDelete={handleDeleteFilter(key)}
              />
            );
          }
          if (filter.option.type === 'date' && filter.operator.id === '__between') {
            return (
              <Chip
                key={`${key}-${filter.operator.id}`}
                className={classes.chip}
                label={
                  <span>
                    <b>{filter.option.label}</b> {filter.operator.label} '
                    <b>{format(filter.value?.[0] as Date, 'MM/dd/yyyy')}</b>'
                  </span>
                }
                variant="outlined"
                onDelete={handleDeleteFilter(key)}
              />
            );
          }
          if (filter.option.type === 'currency' && filter.operator.id === '__between') {
            return (
              <Chip
                key={`${key}-${filter.operator.id}`}
                className={classes.chip}
                label={
                  <span>
                    <b>{filter.option.label}</b> {filter.operator.label}{' '}
                    <b>{`$${filter.value?.[0]}`}</b>
                    {' and '}
                    <b>{`$${filter.value?.[1]}`}</b>
                  </span>
                }
                variant="outlined"
                onDelete={handleDeleteFilter(key)}
              />
            );
          }

          if (filter.option.type === 'multiSelect') {
            const selectedListOptions: ListOption[] = [];
            for (let val of filter.value ?? []) {
              const selectedListOption = filter.option.options?.find(option => option.key === val);
              if (selectedListOption) {
                selectedListOptions.push(selectedListOption);
              }
            }

            const values = selectedListOptions.length
              ? selectedListOptions.map(x => x.value).join(',')
              : filter.value;

            return (
              <Chip
                key={`${key}-${filter.operator.id}`}
                className={classes.chip}
                label={
                  <span>
                    <b>{filter.option.label}</b> {filter.operator.label} '<b>{`${values}`}</b>'
                  </span>
                }
                variant="outlined"
                onDelete={filter.option.force ? undefined : handleDeleteFilter(key)}
              />
            );
          }

          if (key === 'status') {
            const currentStatus = getStatusName(+filter.value!);

            return (
              <Chip
                key={`${key}-${filter.operator.id}`}
                className={classes.chip}
                label={
                  <span>
                    <b>{filter.option.label}</b> {filter.operator.label} '
                    <b>{`${currentStatus}`}</b>'
                  </span>
                }
                variant="outlined"
                onDelete={filter.option.force ? undefined : handleDeleteFilter(key)}
              />
            );
          }

          return (
            <Chip
              key={`${key}-${filter.operator.id}`}
              className={classes.chip}
              label={
                <span>
                  <b>{filter.option.label}</b> {filter.operator.label} '
                  <b>
                    {filter.option.type === 'currency' ? `$${filter.value}` : `${filter.value}`}
                  </b>
                  '
                </span>
              }
              variant="outlined"
              onDelete={filter.option.force ? undefined : handleDeleteFilter(key)}
            />
          );
        })}
    </Grid>
  );
};
