import SecurityIcon from '@mui/icons-material/Security';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';

interface Props {
  onClose: () => void;
  label: string;
  decryptedText: string;
  showSecurityIcon?: boolean;
}
export const ViewingSensitiveData = ({onClose, label, decryptedText, showSecurityIcon}: Props) => {
  const [counter, setCounter] = useState(10);
  useEffect(() => {
    if (counter <= 0) {
      onClose();
    }
    let modalTimeout!: NodeJS.Timeout;
    if (counter > 0) {
      modalTimeout = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    }
    return () => {
      if (modalTimeout) {
        clearTimeout(modalTimeout);
      }
    };
  }, [counter]);

  return (
    <Dialog onClose={onClose} aria-labelledby="secret-dialog-title" open={true}>
      <DialogTitle id="secret-dialog-title">Viewing Sensitive Data</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          Please do not save any sensitive data to your computer. To meet compliance needs, all
          views of this information is tracked and will only be displayed for 10 seconds.
        </Typography>
        <TextField
          sx={{
            margin: theme => theme.spacing(2, 0, 3),
          }}
          variant="outlined"
          label={label}
          fullWidth
          value={decryptedText}
          InputProps={{
            startAdornment: showSecurityIcon && (
              <InputAdornment position="start">
                <SecurityIcon color="primary" />
              </InputAdornment>
            ),
          }}
        />
        <LinearProgress variant="determinate" value={counter * 10} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
