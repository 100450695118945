import {Box} from '@mui/material';
import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {TICKET_TYPES_MANAGER_ROUTE} from '../../constants/routes';
import {useUserInfo} from '../../hooks/useUserInfo';
import {Breadcrumbs, useBreadcrumbsContainer} from '../common/Breadcrumbs';
import {useTicketType} from './hooks/useTicketType';
import {useTicketTypeId} from './hooks/useTicketTypeId';
import {TicketCategoriesList} from './TicketTypes/TicketCategoriesList';
import {TicketCategoryCreateButton} from './TicketTypes/TicketCategoryCreateButton';

export const TicketTypeCategories = () => {
  const {isErpAdmin} = useUserInfo();
  const history = useHistory();
  const {ticketTypeId} = useTicketTypeId();
  const {ticketType} = useTicketType(ticketTypeId);
  const {setBreadcrumbs} = useBreadcrumbsContainer();

  useEffect(() => {
    if (!isErpAdmin) {
      history.push(`/tickets`);
    }
  }, [isErpAdmin]);

  useEffect(() => {
    if (ticketType.promised || !ticketType.data) {
      return;
    }

    setBreadcrumbs([
      {text: 'Ticket Types', url: TICKET_TYPES_MANAGER_ROUTE},
      {text: ticketType.data!.name, url: ''},
    ]);
  }, [ticketType]);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Breadcrumbs>
        <Box flex={1} mx={2} />
        <TicketCategoryCreateButton />
      </Breadcrumbs>
      <Box display="flex" my={2} height="100%" flex={1} position="relative">
        <TicketCategoriesList />
      </Box>
    </Box>
  );
};
