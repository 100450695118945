import {ApiBase, SearchCriteria} from '@ozark/common';
import {ActiveFilter} from '@ozark/common/components';
import {
  DepositDayDetails,
  DepositTotals,
  PaginatedDepositsCpyDaysResponse,
} from '@ozark/functions/src/functions/express/private/types';
import {ContextKey} from '@ozark/functions/src/functions/express/private/types/Context';
import {CancelToken} from 'axios';
import {toQuery} from '../util';

export class DepositsCpyDaysApi extends ApiBase {
  getDeposits = (
    searchCriteria: SearchCriteria,
    mid?: string,
    filters?: ActiveFilter[],
    cancelToken?: CancelToken
  ) => {
    const queryString = toQuery(searchCriteria, filters);
    return this.get<PaginatedDepositsCpyDaysResponse>(
      `depositsCpyDays?${queryString}`,
      {
        [ContextKey.Mid]: mid,
      },
      cancelToken
    );
  };

  getDepositsTotal = (
    searchCriteria: SearchCriteria,
    mid?: string,
    filters?: ActiveFilter[],
    cancelToken?: CancelToken
  ) => {
    const queryString = toQuery(searchCriteria, filters);
    return this.get<DepositTotals>(
      `depositsCpyDays/total?${queryString}`,
      {
        [ContextKey.Mid]: mid,
      },
      cancelToken
    );
  };

  getDepositsDetails = (
    searchCriteria: SearchCriteria,
    mid?: string,
    filters?: ActiveFilter[],
    cancelToken?: CancelToken
  ) => {
    const queryString = toQuery(searchCriteria, filters);
    return this.get<DepositDayDetails[]>(
      `depositsCpyDays/details?${queryString}`,
      {
        [ContextKey.Mid]: mid,
      },
      cancelToken
    );
  };
}
