import {AgentView, AsyncState, UserRoles} from '@ozark/common';
import {AgentResiduals, Loading} from '@ozark/common/components';
import {useEffect, useState} from 'react';
import {useStore} from '../../store/helpers';

const ResidualsAgentPage = () => {
  const {authProfile, isUserGroupAdmin} = useStore();
  const {getAuthorizedAgents} = useStore();
  const [authorizedAgents, setAuthorizedAgents] = useState<AsyncState<AgentView[]>>({
    promised: true,
  });

  useEffect(() => {
    getAuthorizedAgents().then(agents => {
      setAuthorizedAgents({promised: false, data: agents});
    });
  }, [getAuthorizedAgents]);

  const canUpdateManualAdjustments = isUserGroupAdmin();

  if (authProfile.promised || authorizedAgents.promised) return <Loading />;

  return (
    <AgentResiduals
      showActiveAgents={authProfile.data?.role === UserRoles.agentSupport}
      authorizedAgents={authorizedAgents.data || []}
      masterAgentUid={authProfile.data?.masterUid}
      canUpdateManualAdjustments={canUpdateManualAdjustments}
    />
  );
};

export default ResidualsAgentPage;
