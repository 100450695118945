import {ViewableByType} from '../../..';
import {InputViewableBy} from '../TicketEditDetails/ViewableBy/InputViewableBy';

interface Props {
  viewableBy: ViewableByType | undefined;
  setViewableBy: (viewableBy: ViewableByType) => void;
}

export const ViewableByStep = ({viewableBy, setViewableBy}: Props) => {
  return (
    <InputViewableBy
      viewableBy={viewableBy}
      isEditingDisabled={false}
      setViewableBy={setViewableBy}
    />
  );
};
