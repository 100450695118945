import {sortBy} from 'lodash';
import {AuthorizedMerchant, Collections, Firebase, selectMerchantInfo} from '..';
import {ApplicationInfo, MerchantAgentInfo} from '../components/MerchantsActivity/types';

export async function getMerchantsRegInfo(applicationsBoarded: AuthorizedMerchant[]) {
  const merchantsSnap = await Firebase.firestore.collection(Collections.merchants).get();
  const merchants = merchantsSnap.docs.map(selectMerchantInfo);

  const applicationMerchants = applicationsBoarded.map(app => {
    const registeredMerchant = app.merchantUid
      ? merchants.find(m => m.id === app.merchantUid)
      : undefined;

    const item: ApplicationInfo = {
      applicationId: app.applicationId,
      merchant: app.doingBusinessAs,
      email: app.email,
      customerServiceEmail: registeredMerchant?.email ?? app.customerServiceEmail,
      phoneNumber: registeredMerchant?.phoneNumber ?? app.phoneNumber,
      agents: [
        {
          group: app.groupName,
          agent: app.agent,
        } as MerchantAgentInfo,
      ],
      mid: app.mid,
      isRegistered: Boolean(app.merchantUid),
      merchantUid: app.merchantUid,
      invalidMerchantRegAttempts: app.invalidMerchantRegAttempts,
    };
    return item;
  });

  return sortBy<ApplicationInfo>(applicationMerchants, e => e.merchant);
}
