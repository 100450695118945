import {Autocomplete, Box, TextField} from '@mui/material';
import {omit} from 'lodash';
import {TicketAssociation, TicketManager} from '../../../..';
import {FIELD_NAME_GROUP} from '../../constants/constants';
import {useGroupsOptions} from '../../hooks/useGroupsOptions';
import {InputAssociationError} from '../../types';

type Props = {
  draft: TicketAssociation;
  setDraft: (draft: TicketAssociation) => void;
  errors: Record<InputAssociationError, string>;
  setErrors: (errors: Record<InputAssociationError, string>) => void;
};

export const AssociationEditorValueGroup = ({draft, setDraft, errors, setErrors}: Props) => {
  const {groups} = useGroupsOptions();
  return (
    <Box mt={2}>
      <Autocomplete
        value={draft.group}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onChange={(_: React.SyntheticEvent<Element, Event>, newValue: TicketManager | string) => {
          setDraft({...draft, group: (newValue as TicketManager) ?? undefined});
          if (newValue) {
            setErrors(omit(errors, FIELD_NAME_GROUP) as Record<InputAssociationError, string>);
          }
        }}
        disableClearable
        options={groups}
        getOptionLabel={(item: TicketManager) => item?.name ?? ''}
        renderInput={params => (
          <TextField
            {...params}
            label="Agent Group"
            required
            variant="standard"
            error={Boolean(errors[FIELD_NAME_GROUP])}
            helperText={errors[FIELD_NAME_GROUP]}
          />
        )}
      />
    </Box>
  );
};
