import {Button} from '@mui/material';
import {useState} from 'react';
import {useTicketTypeId} from '../hooks/useTicketTypeId';
import {EditCategoryDialog} from './EditCategoryDialog';

export const TicketCategoryCreateButton = () => {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const {ticketTypeId} = useTicketTypeId();

  return (
    <>
      <Button variant="contained" onClick={() => setCreateDialogOpen(true)}>
        New Type Category
      </Button>
      {createDialogOpen && (
        <EditCategoryDialog
          ticketTypeId={ticketTypeId}
          onClose={() => setCreateDialogOpen(false)}
        />
      )}
    </>
  );
};
