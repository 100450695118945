import PhoneNumber from 'awesome-phonenumber';

export type FormatOptions = {
  format: 'national' | 'e164';
  onlyHyphens: boolean;
};

export const formatPhone = (rawPhone: string, options?: Partial<FormatOptions>) => {
  if (rawPhone.includes('(')) return rawPhone; // parens will throw

  const _options: FormatOptions = {
    onlyHyphens: false,
    format: 'national',
    ...options,
  };
  const phoneString = new PhoneNumber(rawPhone).getNumber(_options.format);
  if (_options.onlyHyphens) {
    return phoneString.replace(/[()]/g, '').replace(/\s+/, '-');
  }
  return phoneString;
};

export const formatPhoneWithNumbersOnly = (rawPhone: string) => {
  return new PhoneNumber(rawPhone).getNumber('significant');
};

export const formatNumbersOnly = (value: string) => {
  return value.replace(/\D/g, '').replace(/^1/, '');
};
