export const FraudAnalysisIcon = (props: any) => {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      width="24px"
      height="24px"
      {...props}
    >
      <style>{'.prefix__st1{fill:#b2c2cd}'}</style>
      <path
        d="M208 80c70.7 0 128 57.3 128 128s-57.3 128-128 128S80 278.7 80 208c0-33.9 13.5-66.5 37.5-90.5C141.4 93.4 174 79.9 208 80m0-80C93.1 0 0 93.1 0 208s93.1 208 208 208 208-93.1 208-208S322.9 0 208 0z"
        opacity={0.4}
        fill="#b2c2cd"
      />
      <path
        className="prefix__st1"
        d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372l-36 36v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.3 9.4 24.4 9.4 33.8.1l.1-.1 28.3-28.3c9.4-9.4 9.4-24.6.1-34z"
      />
      <circle className="prefix__st1" cx={208} cy={156} r={46} />
      <path
        className="prefix__st1"
        d="M240.2 213.5h-6c-8 3.7-16.9 5.7-26.2 5.7s-18.2-2.1-26.2-5.7h-6c-26.7 0-48.3 21.6-48.3 48.3v14.9c0 9.5 7.7 17.2 17.2 17.2h126.5c9.5 0 17.2-7.7 17.2-17.2v-14.9c.1-26.7-21.5-48.3-48.2-48.3z"
      />
    </svg>
  );
};
