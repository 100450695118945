import {ApplicationType, ProcessingTypes} from '..';

export const resetProgramFields = (
  setValue: (name: any, value: unknown) => void,
  watchApplicationType?: ApplicationType,
  watchProcessingType?: ProcessingTypes
) => {
  if (!watchApplicationType) return;

  if (
    [
      ApplicationType.surcharge,
      ApplicationType.dualPricingPassThroughFees,
      ApplicationType.dualPricingFlatRate,
      ApplicationType.cashDiscount,
      ApplicationType.err,
      ApplicationType.interchange,
      ApplicationType.cnpVCp,
      ApplicationType.flatRate,
    ].includes(watchApplicationType)
  ) {
    setValue('rateSet.midQualifiedDiscountRateVisaMastercardDiscover', null);
    setValue('rateSet.midQualifiedTransactionFeeVisaMastercardDiscover', null);
    setValue('rateSet.midQualifiedDiscountRateAmex', null);
    setValue('rateSet.midQualifiedTransactionFeeAmex', null);
  }
  switch (watchApplicationType) {
    case ApplicationType.surcharge:
    case ApplicationType.dualPricingPassThroughFees:
    case ApplicationType.dualPricingFlatRate:
      setValue('rateSet.nonQualifiedDiscountRateVisaMastercardDiscover', null);
      setValue('rateSet.nonQualifiedTransactionFeeVisaMastercardDiscover', null);
      setValue('rateSet.nonQualifiedDiscountRateAmex', null);
      setValue('rateSet.nonQualifiedTransactionFeeAmex', null);
      setValue('rateSet.qualifiedDebitNonPINRate', null);
      setValue('rateSet.qualifiedDebitNonPINFee', null);
      break;
    case ApplicationType.cashDiscount:
      // removed due to released TG-1910
      // setValue('equipment.cashDiscount', 'true');
      setValue('rateSet.nonQualifiedDiscountRateVisaMastercardDiscover', null);
      setValue('rateSet.nonQualifiedTransactionFeeVisaMastercardDiscover', null);
      setValue('rateSet.nonQualifiedDiscountRateAmex', null);
      setValue('rateSet.nonQualifiedTransactionFeeAmex', null);
      setValue('rateSet.qualifiedDebitPINRate', null);
      setValue('rateSet.qualifiedDebitPINFee', null);
      setValue('rateSet.qualifiedDebitNonPINRate', null);
      setValue('rateSet.qualifiedDebitNonPINFee', null);
      break;
    case ApplicationType.err:
      setValue('rateSet.nonQualifiedTransactionFeeVisaMastercardDiscover', null);
      setValue('rateSet.nonQualifiedTransactionFeeAmex', null);
      break;
    case ApplicationType.flatRate:
    case ApplicationType.interchange:
      setValue('rateSet.nonQualifiedDiscountRateVisaMastercardDiscover', null);
      setValue('rateSet.nonQualifiedTransactionFeeVisaMastercardDiscover', null);
      setValue('rateSet.nonQualifiedDiscountRateAmex', null);
      setValue('rateSet.nonQualifiedTransactionFeeAmex', null);
      break;
    case ApplicationType.cnpVCp:
      setValue('rateSet.qualifiedDebitNonPINRate', null);
      setValue('rateSet.qualifiedDebitNonPINFee', null);
      break;
  }
  if (watchApplicationType !== ApplicationType.interchange) {
    setValue('rateSet.statementType', null);
  }
  if (watchProcessingType && watchProcessingType !== ProcessingTypes.cardPresent) {
    setValue('rateSet.qualifiedDebitPINRate', null);
    setValue('rateSet.qualifiedDebitPINFee', null);
  }
};
