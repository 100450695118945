import FilterListIcon from '@mui/icons-material/FilterList';
import {Chip, Grid, IconButton} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {ActiveFilter} from '@ozark/common/components';
import {format} from 'date-fns-tz';
import {isEmpty} from 'lodash';
import {formatYearMonth} from '../utils';

interface Props {
  filters: {[p: string]: ActiveFilter};
  handleDeleteFilter: (id: string) => () => void;
}

const sxStyles = {
  marginLeft: (theme: Theme) => theme.spacing(0.5),
  marginRight: (theme: Theme) => theme.spacing(0.5),
};
export const GroupFilters = ({filters, handleDeleteFilter}: Props) => {
  return (
    <Grid item xs={12}>
      {filters && !isEmpty(filters) && (
        <IconButton disabled size="large">
          <FilterListIcon />
        </IconButton>
      )}
      {filters &&
        Object.keys(filters).map(key => {
          const filter = filters[key];
          if (filter.option.type === 'dateRange' && filter.operator.id === '__between') {
            return (
              <Chip
                key={`${key}-${filter.operator.id}`}
                sx={sxStyles}
                label={
                  <span>
                    <b>{filter.option.label}</b> {filter.operator.label} '
                    <b>{format(filter.value?.[0] as Date, 'MM/dd/yyyy h:mm a')}</b>' and '
                    <b>{format(filter.value?.[1] as Date, 'MM/dd/yyyy h:mm a')}</b>'
                  </span>
                }
                variant="outlined"
                onDelete={handleDeleteFilter(key)}
              />
            );
          }
          if (filter.option.type === 'date' && filter.operator.id === '__between') {
            return (
              <Chip
                key={`${key}-${filter.operator.id}`}
                sx={sxStyles}
                label={
                  <span>
                    <b>{filter.option.label}</b> {filter.operator.label} '
                    <b>{format(filter.value?.[0] as Date, 'MM/dd/yyyy')}</b>'
                  </span>
                }
                variant="outlined"
                onDelete={handleDeleteFilter(key)}
              />
            );
          }
          if (filter.option.id === 'yearMonth') {
            return (
              <Chip
                key={`${key}-${filter.operator.id}`}
                sx={sxStyles}
                label={
                  <span>
                    <b>{filter.option.label}</b> {filter.operator.label} '
                    <b>{formatYearMonth(filter.value as string)}</b>'
                  </span>
                }
                variant="outlined"
                onDelete={filter.option.force ? undefined : handleDeleteFilter(key)}
              />
            );
          }
          return (
            <Chip
              key={`${key}-${filter.operator.id}`}
              sx={sxStyles}
              label={
                <span>
                  <b>{filter.option.label}</b> {filter.operator.label} '
                  <b>
                    {filter.option.type === 'currency' ? `$${filter.value}` : `${filter.value}`}
                  </b>
                  '
                </span>
              }
              variant="outlined"
              onDelete={filter.option.force ? undefined : handleDeleteFilter(key)}
            />
          );
        })}
    </Grid>
  );
};
