import {Box, Grow, Stack} from '@mui/material';
import {LoadingStatus} from '../../Analytics/common/LoadingStatus';
import {BoxCentered, BoxParentHeight} from '../../common';
import {useTicketsMy} from '../hooks/useTicketsMy';
import {useTicketsQuery} from '../hooks/useTicketsQuery';
import {TicketsListCard} from './TicketsListCard';

export const TicketsListViewMy = () => {
  const {statusSet, filterKey} = useTicketsQuery();
  const {tickets} = useTicketsMy(statusSet, filterKey);
  const loading = Boolean(tickets?.promised);
  const hasData = Boolean(tickets?.data?.length);
  return (
    <>
      {!hasData && (
        <BoxCentered flex={1}>
          <LoadingStatus loading={loading} hasData={hasData} />
        </BoxCentered>
      )}
      {hasData && (
        <BoxParentHeight ml={2}>
          <Stack pt={0.5}>
            {tickets?.data &&
              tickets.data.map(ticket => (
                <Grow in key={ticket.id}>
                  <Box mr={2} mb={2}>
                    <TicketsListCard ticket={ticket} />
                  </Box>
                </Grow>
              ))}
          </Stack>
        </BoxParentHeight>
      )}
    </>
  );
};
