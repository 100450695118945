import {Box, BoxTypeMap} from '@mui/material';

export const BoxParentHeight = ({children, ...rest}: BoxTypeMap<{id?: string}, 'div'>['props']) => {
  return (
    <Box position="relative" flex={1}>
      <Box position="absolute" width="100%" height="100%" overflow="auto" {...rest}>
        {children}
      </Box>
    </Box>
  );
};
