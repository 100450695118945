import {InputAdornment, Popper, PopperProps, TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {TicketSearchItemModel} from '@ozark/common';
import {SearchIcon} from '@ozark/common/icons';
import {ReactNode, SyntheticEvent, useEffect, useMemo, useState} from 'react';
import {useAlgoliaSearchTickets} from '../../hooks/useAlgoliaSearchTickets';

type SearchProps<T> = {
  onChange: (option: T | null) => void;
  renderOption: (option: T, options?: Record<string, any>) => ReactNode;
};

export const Search = <T,>({onChange, renderOption}: SearchProps<T>) => {
  const [value] = useState<T | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<any[]>([]);
  const {search} = useAlgoliaSearchTickets();

  useEffect(() => {
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return;
    }

    search({input: inputValue}, (results?: T[]) => {
      let newOptions = [] as T[];

      if (value) {
        newOptions = [value];
      }

      if (results) {
        newOptions = [...newOptions, ...results];
      }

      setOptions(newOptions);
    });
  }, [value, inputValue, search]);

  const wideTicketSubject = useMemo(() => {
    return !options.some(
      (ticket: TicketSearchItemModel) => !!ticket.labels && ticket.labels.length > 0
    );
  }, [options]);

  return (
    <Autocomplete
      PopperComponent={PopperMy}
      id="search"
      forcePopupIcon={false}
      blurOnSelect
      clearOnBlur
      openOnFocus={false}
      filterSelectedOptions
      filterOptions={results => results}
      value={value}
      options={options}
      noOptionsText={'No results'}
      getOptionLabel={option => option.distinguishableId ?? 'header'}
      getOptionDisabled={option => !option.id}
      groupBy={option => option.searchGroup}
      includeInputInList
      onChange={(_event: SyntheticEvent<Element, Event>, option: T | null) => {
        onChange(option);
      }}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          margin="none"
          placeholder="Search for tickets..."
          variant="standard"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment sx={{mr: 2}} position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
        />
      )}
      renderOption={(props, option: T) => (
        <li {...props}>{renderOption(option, {wideTicketSubject})}</li>
      )}
    />
  );
};

const PopperMy = (props: PopperProps) => (
  <Popper {...props} style={{minWidth: 900}} placement="bottom-start" />
);
