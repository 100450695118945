export const RelationshipManagementIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g fill={'inherit'}>
        <path
          d="M272,128c-8.8,0-16-7.2-16-16V0H24C10.8,0,0,10.7,0,23.9c0,0,0,0,0,0V488c0,13.2,10.7,24,23.9,24c0,0,0,0,0,0
		H360c13.2,0,24-10.7,24-23.9c0,0,0,0,0,0V128H272z M117.2,412c0,3.6-2.9,6.6-6.6,6.6H62.6c-3.6,0-6.6-2.9-6.6-6.6V364
		c0-3.6,2.9-6.6,6.6-6.6h48.1c3.6,0,6.6,2.9,6.6,6.6V412z M117.2,328c0,3.6-2.9,6.6-6.6,6.6H62.6c-3.6,0-6.6-2.9-6.6-6.6V280
		c0-3.6,2.9-6.6,6.6-6.6h48.1c3.6,0,6.6,2.9,6.6,6.6V328z M117.2,244c0,3.6-2.9,6.6-6.6,6.6H62.6c-3.6,0-6.6-2.9-6.6-6.6V196
		c0-3.6,2.9-6.6,6.6-6.6h48.1c3.6,0,6.6,2.9,6.6,6.6V244z M338,392c0,6.6-5.4,12-12,12H158c-6.6,0-12-5.4-12-12v-8
		c0-6.6,5.4-12,12-12h168c6.6,0,12,5.4,12,12V392z M338,308c0,6.6-5.4,12-12,12H158c-6.6,0-12-5.4-12-12v-8c0-6.6,5.4-12,12-12h168
		c6.6,0,12,5.4,12,12V308z M338,224c0,6.6-5.4,12-12,12H158c-6.6,0-12-5.4-12-12v-8c0-6.6,5.4-12,12-12h168c6.6,0,12,5.4,12,12V224z
		"
          opacity={0.4}
        />
        <g>
          <path
            d="M110.6,189.4H62.6c-3.6,0-6.6,2.9-6.6,6.6V244c0,3.6,2.9,6.6,6.6,6.6h48.1c3.6,0,6.6-2.9,6.6-6.6V196
			C117.2,192.3,114.2,189.4,110.6,189.4z M107.8,212.1C107.8,212.1,107.8,212.1,107.8,212.1l-25.1,25.1v0c-0.9,0.9-2.2,0.9-3.1,0
			L65.4,223c-0.9-0.9-0.9-2.2,0-3.1c0,0,0,0,0,0l3.1-3.1c0.9-0.9,2.2-0.9,3.1,0c0,0,0,0,0,0l9.6,9.6l3.5-3.5l17-17
			c0.9-0.9,2.2-0.9,3.1,0c0,0,0,0,0,0l3.1,3.1c0.4,0.4,0.6,1,0.6,1.5C108.4,211.1,108.2,211.6,107.8,212.1z"
          />
          <path d="M377,105L279.1,7c-4.5-4.5-10.6-7-17-7H256v112c0,8.8,7.2,16,16,16h112v-6.1C384,115.6,381.5,109.5,377,105z" />
          <path
            d="M326,204H158c-6.6,0-12,5.4-12,12v8c0,6.6,5.4,12,12,12h168c6.6,0,12-5.4,12-12v-8
			C338,209.4,332.6,204,326,204z"
          />
          <path
            d="M326,288H158c-6.6,0-12,5.4-12,12v8c0,6.6,5.4,12,12,12h168c6.6,0,12-5.4,12-12v-8
			C338,293.4,332.6,288,326,288z"
          />
          <path
            d="M326,372H158c-6.6,0-12,5.4-12,12v8c0,6.6,5.4,12,12,12h168c6.6,0,12-5.4,12-12v-8
			C338,377.4,332.6,372,326,372z"
          />
          <path
            d="M110.6,273.4H62.6c-3.6,0-6.6,2.9-6.6,6.6V328c0,3.6,2.9,6.6,6.6,6.6h48.1c3.6,0,6.6-2.9,6.6-6.6V280
			C117.2,276.4,114.2,273.4,110.6,273.4z M107.8,296.1C107.8,296.1,107.8,296.1,107.8,296.1l-25.1,25.1v0c-0.9,0.9-2.2,0.9-3.1,0
			L65.4,307c-0.9-0.9-0.9-2.2,0-3.1c0,0,0,0,0,0l3.1-3.1c0.9-0.9,2.2-0.9,3.1,0c0,0,0,0,0,0l9.6,9.6l3.5-3.5l17-17
			c0.9-0.9,2.2-0.9,3.1,0c0,0,0,0,0,0l3.1,3.1c0.4,0.4,0.6,1,0.6,1.5C108.4,295.1,108.2,295.7,107.8,296.1z"
          />
          <path
            d="M110.6,357.4H62.6c-3.6,0-6.6,2.9-6.6,6.6V412c0,3.6,2.9,6.6,6.6,6.6h48.1c3.6,0,6.6-2.9,6.6-6.6V364
			C117.2,360.4,114.2,357.4,110.6,357.4z"
          />
        </g>
      </g>
    </svg>
  );
};
