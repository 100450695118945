import {FormControl, MenuItem, OutlinedInput, Select, Typography} from '@mui/material';
import {DefaultComponentProps, OverridableTypeMap} from '@mui/material/OverridableComponent';
import {CreateDisputeReasonProps, ReasonInputType} from '@ozark/common/';
import camelcase from 'lodash/camelCase';
import {MenuProps} from '../../helpers';
import {DisputeReason} from './index';

export const createSelectReason = ({
  name,
  description,
  params,
}: CreateDisputeReasonProps): DisputeReason => ({
  name: camelcase(name),
  title: name,
  inputType: ReasonInputType.Select,
  descriptionHtml: description,
  Description: (props: DefaultComponentProps<OverridableTypeMap>) => (
    <Typography {...props}>
      <span dangerouslySetInnerHTML={{__html: params?.label ?? ''}} />
    </Typography>
  ),
  FieldInput: props => (
    <FormControl fullWidth variant="outlined">
      <Select
        name={name}
        variant="outlined"
        labelId={`${name}-label`}
        id={name}
        input={<OutlinedInput notched />}
        fullWidth
        MenuProps={MenuProps}
        {...props}
      >
        {params?.values?.map(value => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ),
});
