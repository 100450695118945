import {Visibility, VisibilityOff} from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import {IconButton, InputAdornment} from '@mui/material';
import {green} from '@mui/material/colors';

interface Props {
  displayIsValidPassword: boolean;
  handleClickShowPassword: () => void;
  showPassword: boolean;
}

export const InputPasswordAdornment = ({
  displayIsValidPassword,
  handleClickShowPassword,
  showPassword,
}: Props) => {
  return (
    <InputAdornment position="end">
      {displayIsValidPassword && <DoneIcon sx={{color: green[500]}} />}
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        edge="end"
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );
};
