import {Column} from '@ozark/common/api/Column';
import {SearchCriteria} from '@ozark/common/api/SearchCriteria';
import {Table} from '@ozark/common/components';
import {MidInfo} from '@ozark/functions/src/functions/express/private/types/Reports';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {formatAsPercent, formatterCurrency, formatterNumber} from '../../../util';
import {useQueryMonthYear} from '../common/useQueryMonthYear';

type PropSorted = Extract<keyof MidInfo, 'salesCount' | 'salesAmount'>;

type Props = {
  data: MidInfo[];
};

export const MidsByProcessingTable = ({data}: Props) => {
  const {year, month} = useQueryMonthYear();
  const history = useHistory();
  const [sort, setSort] = useState<[PropSorted, 'ASC' | 'DESC'][]>([]);
  const [dataSorted, setDataSorted] = useState<MidInfo[]>(data);

  const handleRetrieveData = (searchCriteria: SearchCriteria) => {
    setSort([
      [searchCriteria.orderBy as PropSorted, searchCriteria.order.toUpperCase() as 'ASC' | 'DESC'],
    ]);

    const prop = searchCriteria.orderBy as PropSorted;
    const result = dataSorted.sort((a, b) =>
      searchCriteria.order === 'desc' ? a[prop] - b[prop] : b[prop] - a[prop]
    );

    setDataSorted(result);
  };

  const onRowClick = (row: MidInfo) => {
    window.open(`/authorizations?year=${year}&month=${month}&mid=${row.mid}`, '_blank');
  };

  useEffect(() => setDataSorted(data), [data]);

  return (
    <Table
      columns={midsByProcessingColumnsConfig}
      data={{
        sort,
        data: dataSorted,
        limit: 10,
        offset: 0,
        totalCount: 10,
      }}
      onRetrieveData={handleRetrieveData}
      flat
      onRowClick={onRowClick}
    />
  );
};

export const midsByProcessingColumnsConfig: Column<MidInfo>[] = [
  {
    id: 'mid',
    numeric: false,
    sortable: false,
    label: 'MID',
    export: true,
  },
  {
    id: 'merchantName',
    numeric: false,
    sortable: false,
    label: 'Merchant Name (dba)',
    selector: (row: MidInfo) => row.merchantName || 'n/a',
    export: true,
  },
  {
    id: 'salesAmount',
    numeric: true,
    sortable: true,
    label: 'Sales Amount',
    selector: row => formatterCurrency.format(Number(row.salesAmount)),
    export: row => formatterCurrency.format(Number(row.salesAmount)),
  },
  {
    id: 'percentOfTotal',
    numeric: true,
    sortable: false,
    label: '% of Total',
    selector: row => formatAsPercent(row.percentOfTotal ?? 0),
    export: row => formatAsPercent(row.percentOfTotal ?? 0),
  },
  {
    id: 'salesCount',
    numeric: true,
    sortable: true,
    label: 'Transaction Count',
    selector: row => formatterNumber.format(Number(row.salesCount)),
    export: row => formatterNumber.format(Number(row.salesCount)),
  },
  {
    id: 'avgTicket',
    numeric: true,
    sortable: true,
    label: 'Average Ticket',
    selector: row => formatterCurrency.format(Number(row.avgTicket ?? 0)),
    export: row => formatterCurrency.format(Number(row.avgTicket ?? 0)),
  },
];
