import {Divider, Paper, Tooltip} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {TicketTypeCategoryView} from '@ozark/functions/src/documents/TicketTypeCategory';
import {useState} from 'react';
import {EditCategoryDialog} from './EditCategoryDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: 58,
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: theme.spacing(2, 2, 2, 2),
      borderLeft: `solid 8px ${theme.palette.primary.light}`,
      width: '100%',
    },
    divider: {
      padding: 0,
    },
    name: {
      width: 400,
      minWidth: 400,
      fontWeight: 400,
      cursor: 'pointer',
      letterSpacing: '0.5px',
    },
    assignmentLabel: {
      width: theme.spacing(12.5),
      marginLeft: theme.spacing(2),
      color: 'gray',
    },
    assignment: {
      marginLeft: theme.spacing(0.5),
      width: 150,
      minWidth: 150,
    },
    rolesLabel: {
      width: theme.spacing(16),
      marginLeft: theme.spacing(2),
      color: 'gray',
    },
    roles: {
      marginLeft: theme.spacing(1),
      width: 222,
      minWidth: 222,
    },
  })
);

type Props = {
  ticketTypeId: string;
  ticketCategory: TicketTypeCategoryView;
};

const getOpacity = (deleted?: boolean | null) => {
  return deleted === true ? 0.4 : 1;
};

export const TicketCategoryCard = ({ticketTypeId, ticketCategory}: Props) => {
  const classes = useStyles();
  const [editCategoryDialogOpen, setEditCategoryDialogOpen] = useState(false);

  return (
    <Paper
      className={classes.paper}
      style={{
        opacity: getOpacity(ticketCategory.isDeleted),
      }}
    >
      <Tooltip title={'Edit ticket category'}>
        <div className={classes.name} onClick={() => setEditCategoryDialogOpen(true)}>
          {ticketCategory.name}
        </div>
      </Tooltip>

      <Divider className={classes.divider} orientation="vertical" flexItem />
      <div className={classes.assignmentLabel}>Assignment:</div>
      <div className={classes.assignment}>{ticketCategory.assignment}</div>

      <Divider className={classes.divider} orientation="vertical" flexItem />
      <div className={classes.rolesLabel}>Visible To Roles:</div>
      <div className={classes.roles}>{ticketCategory.visibleToRoles.join(', ')}</div>

      {editCategoryDialogOpen && (
        <EditCategoryDialog
          key={ticketCategory.id}
          ticketTypeId={ticketTypeId}
          ticketCategory={ticketCategory}
          onClose={() => setEditCategoryDialogOpen(false)}
        />
      )}
    </Paper>
  );
};
