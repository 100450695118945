import {TextField as MuiTextField} from '@mui/material';
import {ReactNode} from 'react';
import {Control, Controller, FieldValues, Path} from 'react-hook-form';
import VMasker from 'vanilla-masker';
import {getErrorMessage} from '../../util';

type TTransform = {
  pattern?: string;
  toMoney?: boolean;
};

interface Props<T extends FieldValues, TName> {
  name: TName;
  label?: string;
  defaultValue?: any;
  required?: boolean;
  errors: any;
  control: Control<T, any>;
  fullWidth?: boolean;
  disableErrorText?: boolean;
  autoFocus?: boolean;
  transform?: TTransform;
  helperText?: ReactNode;
  [others: string]: any;
}

export const getMaskedValue = (value: string, pattern: string): string => {
  return VMasker.toPattern(value, pattern);
};

export const TextField = <T extends FieldValues, TName extends Path<T>>({
  name,
  label,
  defaultValue,
  required,
  errors,
  control,
  autoFocus,
  fullWidth = true,
  disableErrorText = false,
  transform,
  helperText,
  ...other
}: Props<T, TName>) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ?? ''}
      rules={{required: required}}
      render={({field: {onChange, value, ref}}) => (
        <MuiTextField
          variant="outlined"
          margin="normal"
          required={required}
          fullWidth={fullWidth}
          id={name}
          value={
            value && transform?.toMoney
              ? VMasker.toMoney(value, {delimiter: ',', zeroCents: false, precision: 0})
              : value ?? ''
          }
          onChange={e => {
            if (transform?.toMoney) {
              onChange(VMasker.toNumber(e.target.value));
              return;
            }

            onChange(
              transform?.pattern
                ? getMaskedValue(e.target.value, transform.pattern)
                : e.target.value
            );
          }}
          label={label}
          name={name}
          data-test={name}
          error={Boolean(getErrorMessage(name, errors))}
          helperText={
            // parenthesis are important!
            helperText || (disableErrorText ? null : getErrorMessage(name, errors)?.message)
          }
          inputRef={ref}
          autoFocus={autoFocus}
          {...other}
        />
      )}
    />
  );
};
