import {
  Application,
  ApplicationInput,
  ApplicationOwner,
  ApplicationSubCollection,
  ApplicationTransferInfo,
  ApplicationView,
  Collections,
  Firebase,
  StoragePath,
  toDateInUTCWithoutTime,
  TransferStatus,
  useGroupFromDomain,
} from '@ozark/common';
import {isValidDate} from '@ozark/common/helpers';
import {isEmpty, omitBy} from '@s-libs/micro-dash';
import PhoneNumber from 'awesome-phonenumber';
import {formatInTimeZone} from 'date-fns-tz';
import LogRocket from 'logrocket';
import {useCallback, useEffect, useRef, useState} from 'react';
import {isAgentOrErpUser} from '../../util/authUserUtils';
import {dataUrlToBlob} from '../../util/FileUtil';
import {AsyncState} from './AsyncState';
import {useAuth} from './useAuth';
import {useCallable} from './useCallable';

export const useApplication = (
  authStore: ReturnType<typeof useAuth>,
  groupStore: ReturnType<typeof useGroupFromDomain>
) => {
  // will refactor <Application | any>
  const [application, setApplication] = useState<AsyncState<Partial<ApplicationView> | any>>({
    promised: true,
  });
  const [disableLogRocket, setDisableLogRocket] = useState<boolean | undefined>();
  const [logRocketSessionURLSaved, setLogRocketSessionURLSaved] = useState<boolean>(false);
  const [applicationId, setApplicationId] = useState<string | undefined>();
  const [preventFinicityAutoLaunchConnect, setPreventFinicityAutoLaunchConnect] =
    useState<boolean>(false);
  const [logRocketSessionURL, setLogRocketSessionURL] = useState<string>();
  const {startApplication: startApplicationCallable, completeApplication} = useCallable();
  const creatorApplicationEmailRef = useRef<string | undefined>();
  const {authUser} = authStore;
  const agentOrErpUser = isAgentOrErpUser(authStore);
  const {group} = groupStore;

  const update = useCallback(
    async (data: any) => {
      await Firebase.firestore
        .collection(Collections.applications)
        .doc(applicationId)
        .update({updatedAt: new Date(), ...data});
    },
    [applicationId]
  );

  useEffect(() => {
    if (
      process.env.REACT_APP_LOGROCKET_APP_ID &&
      applicationId &&
      !logRocketSessionURL &&
      !disableLogRocket
    ) {
      LogRocket.getSessionURL(sessionURL => {
        setLogRocketSessionURL(sessionURL);
      });
    }

    if (logRocketSessionURL && applicationId && !disableLogRocket && !logRocketSessionURLSaved) {
      const logRocketSessionURLCollection = application.data?.logRocketSessionURLCollection ?? [];
      logRocketSessionURLCollection.push(logRocketSessionURL);
      update({
        logRocketSessionURLCollection,
      });
      setLogRocketSessionURLSaved(true);
    }
  }, [
    logRocketSessionURL,
    application,
    logRocketSessionURLSaved,
    applicationId,
    disableLogRocket,
    update,
  ]);

  useEffect(() => {
    setApplication({promised: true});

    if (!authUser.data?.uid) {
      setApplication({promised: false, data: null});
      return;
    }

    if (!applicationId) {
      setApplication({promised: false, data: null});
      return;
    }

    return Firebase.firestore
      .collection(Collections.applications)
      .doc(applicationId)
      .onSnapshot(
        snapshot => {
          const data = snapshot.data();
          if (!data) {
            throw Error('Data should never be null');
          }
          if (
            data.dateOfBirth &&
            typeof data.dateOfBirth === 'object' &&
            'toDate' in data.dateOfBirth
          ) {
            data.dateOfBirth = formatInTimeZone(data.dateOfBirth.toDate(), 'UTC', 'MM/dd/yyyy');
          }

          if (data.otherOwnersData?.length > 0) {
            for (const owner of data.otherOwnersData as ApplicationOwner[]) {
              if (
                owner.dateOfBirth &&
                typeof owner.dateOfBirth === 'object' &&
                'toDate' in owner.dateOfBirth
              ) {
                owner.dateOfBirth = formatInTimeZone(
                  owner.dateOfBirth.toDate(),
                  'UTC',
                  'MM/dd/yyyy'
                );
              }
            }
          }
          if (data.businessPhone) {
            data.businessPhone = new PhoneNumber(data.businessPhone, 'US').getNumber('national');
          }
          // Have to check createdAt field of data to set `promised` because
          // `onSnapshot` might be triggered by local changes like in `setTransferStatus` before loading data from the server`
          // `promised: false` might cause issues when we need to have data from the server
          setApplication({promised: !data.createdAt, data: {id: snapshot.id, ...data}});
        },
        err => {
          console.warn(err, err.code, err.name);
          setApplication({promised: false, error: err, data: null});
        }
      );
  }, [authUser.promised, authUser.data?.uid, applicationId]);

  const set = useCallback(
    async (data: any) => {
      if (data.dateOfBirth && typeof data.dateOfBirth === 'string') {
        data.dateOfBirth = new Date(data.dateOfBirth);
      }
      await Firebase.firestore
        .collection(Collections.applications)
        .doc(applicationId)
        .set(
          {
            updatedAt: new Date(),
            ...data,
            ...(data.dateOfBirth &&
              isValidDate(data.dateOfBirth) && {
                dateOfBirth: toDateInUTCWithoutTime(data.dateOfBirth),
              }),
          },
          {merge: true}
        );
    },
    [applicationId]
  );

  const getApplicationNoteUid = useCallback(
    (userId: string) => {
      const uid = agentOrErpUser ? authUser.data?.uid : userId;
      return uid ?? authUser.data?.uid; //fallback to current user
    },
    [authUser.data?.uid, agentOrErpUser]
  );

  const addApplicationUWRiskNote = useCallback(
    async (note: string, userId: string) => {
      const uid = getApplicationNoteUid(userId);
      await Firebase.firestore
        .collection(Collections.applications)
        .doc(applicationId)
        .collection(ApplicationSubCollection.uwRiskNotes)
        .add({message: note, createdAt: new Date(), uid: uid});
    },
    [applicationId, getApplicationNoteUid]
  );

  const addApplicationSupportNote = useCallback(
    async (note: string, userId: string) => {
      const uid = getApplicationNoteUid(userId);
      await Firebase.firestore
        .collection(Collections.applications)
        .doc(applicationId)
        .collection(ApplicationSubCollection.supportNotes)
        .add({message: note, createdAt: new Date(), uid: uid});
    },
    [applicationId, getApplicationNoteUid]
  );

  const startApplication = useCallback(
    async ({
      email,
      registration,
      tags,
      sourceApplicationId,
      externalLeadId,
      agentUid,
      startApplicationLink,
    }: {
      email: string;
      registration?: {firstName: string; lastName: string; businessPhone: string} | null;
      tags?: {[key: string]: string};
      sourceApplicationId?: string;
      externalLeadId?: string;
      agentUid?: string;
      startApplicationLink?: string;
    }): Promise<string | undefined> => {
      if (creatorApplicationEmailRef.current && creatorApplicationEmailRef.current === email) {
        console.log('Application is creating for this email', email);
        return;
      }

      if (!group.data) {
        console.error('group cannot be null');
        return;
      }

      creatorApplicationEmailRef.current = email;

      const id = await startApplicationCallable({
        email,
        group: {
          id: group.data.id,
          logoUrl: group.data.logoUrl,
          name: group.data.name,
        },
        registration: registration
          ? {...registration, timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone}
          : null,
        tags,
        sourceApplicationId,
        externalLeadId,
        agentUid,
        startApplicationLink,
      });

      creatorApplicationEmailRef.current = undefined;
      if (!id) {
        console.error('failed to start application');
        return;
      }
      return id;
    },
    [group.data, startApplicationCallable]
  );

  const complete = useCallback(
    async (id: string, signatureDataUrl: string) => {
      let signatureObject: {fullPath: string; downloadUrl: string} | null = null;

      if (signatureDataUrl) {
        const blob: Blob | null = dataUrlToBlob(signatureDataUrl);
        if (blob) {
          const uploadResult = Firebase.storage
            .ref()
            .child(`${StoragePath.signatures}/${id}`)
            .put(blob);
          const snapshot = await uploadResult;
          const signatureRef = snapshot.ref;
          const downloadUrl = await snapshot.ref.getDownloadURL();
          signatureObject = {fullPath: signatureRef.fullPath, downloadUrl};
        }
      }
      await completeApplication(id, signatureObject);
    },
    [completeApplication]
  );

  const saveProgress = useCallback(
    async (data: Partial<Application>) => {
      if (!applicationId) return;

      const withoutNil: any = (data: any) => {
        const e = Object.keys(data).reduce((previous: any, current: any) => {
          if (data[current] === undefined) return previous;
          if (
            typeof data[current] === 'object' &&
            !Array.isArray(data[current]) &&
            data[current] !== null
          ) {
            previous[current] = withoutNil(data[current]);
          } else {
            previous[current] = data[current];
          }
          return previous;
        }, {});
        // IsEmpty returns true for booleans, and we don't want to omit boolean values.
        return omitBy(e, value => typeof value !== 'boolean' && isEmpty(value)) as any;
      };

      const definedOnly = withoutNil(data) as Partial<ApplicationInput>;

      if (definedOnly.dateOfBirth && typeof definedOnly.dateOfBirth === 'string') {
        definedOnly.dateOfBirth = new Date(definedOnly.dateOfBirth);
      }

      // Workaround to remove sortAndMap prototype that persist in the object when saving
      if (definedOnly.otherOwnersData && definedOnly.otherOwnersData.length > 0) {
        await Firebase.firestore
          .collection(Collections.applications)
          .doc(applicationId)
          .set({...definedOnly, otherOwnersData: [...definedOnly.otherOwnersData]}, {merge: true});
      } else {
        await Firebase.firestore
          .collection(Collections.applications)
          .doc(applicationId)
          .set(
            // If otherOwners is false, need to delete existing otherOwnersData.
            {...definedOnly, ...(definedOnly.otherOwners === false && {otherOwnersData: []})},
            {merge: true}
          );
      }
    },
    [applicationId]
  );

  const setTransferStatus = useCallback(
    async (applicationId: string, transferStatus: TransferStatus) => {
      if (!applicationId) return;

      const transferInfo: Partial<ApplicationTransferInfo> = {
        transferStatus,
        viewedDate: Firebase.Timestamp.now(),
      };

      await Firebase.firestore
        .collection(Collections.applications)
        .doc(applicationId)
        .set({transferStatus: transferInfo}, {merge: true});
    },
    []
  );

  return {
    application,
    applicationId,
    set,
    update,
    addApplicationUWRiskNote,
    addApplicationSupportNote,
    startApplication,
    saveProgress,
    complete,
    setApplicationId,
    setDisableLogRocket,
    setTransferStatus,
    preventFinicityAutoLaunchConnect,
    setPreventFinicityAutoLaunchConnect,
  };
};
