import {CreateDisputeReasonProps, Dispositions, Markdown} from '@ozark/common';
import {DisputeReason} from '../../ApplicationDisputeHandlers';
import {createDisputeReason} from '../createDisputeReason';

export const incomplete = {
  [Dispositions.asIncomplete]: [
    {
      name: 'Missing Information on the Application',
      description: `Please have the merchant provide information regarding the following:`,
      type: 'rich-text',
    },
    {
      name: 'Request for Documentation - AMV',
      description: `Due to the high average monthly volume, please provide one of the following;
* 3 months most recent business bank statements
* 3 months most recent processing statements
* 2 years most recent business tax returns and P&amp;L &amp; Balance Sheet
      `,
      type: 'checkbox',
    },
    {
      name: 'Request for Documentation - Ticket',
      description: `Due to the high ticket amount, please provide one of the following;
* 3 months most recent business bank statements
* 3 months most recent processing statements
* 2 years most recent business tax returns and P&amp;L &amp; Balance Sheet
      `,
      type: 'checkbox',
    },
    {
      name: 'Reserve Agreement',
      description: `Please have the merchant complete a reserve agreement that reflects:`,
      type: 'rich-text',
    },
    {
      name: 'Additional Services',
      description: `Please have the merchant complete an additional services agreement.`,
      type: 'checkbox',
    },
    {
      name: 'Letter of Attestation',
      description: `Please provide a letter of attestation regarding the following:`,
      type: 'rich-text',
    },
    {
      name: 'Additional Website Work',
      description: `The website appears to be under construction or has not been completed with all required regulatory documentation. The website must include the following information
* Home Page
* Contact Page
* Terms and Conditions
* Privacy Policy
* Refund Policy
* Shipping Policy (if applicable)
* Products/Ingredients List (if applicable)
      `,
      type: 'checkbox',
    },
    {
      name: 'Other Reason',
      description: 'Other Reason',
      type: 'rich-text',
    },
  ].map(reason => {
    const {name, description, type} = reason as CreateDisputeReasonProps;
    return createDisputeReason({
      name: name,
      description: Markdown?.render(description) || '',
      type: type,
    });
  }) as DisputeReason[],
} as {[key in Dispositions]?: DisputeReason[]};
