export const getErrorMessage_old = (name: string, errors: any) => {
  // support for nested object validation
  return errors && Object.keys(errors).length > 0
    ? name.split('.').reduce((previous, current) => (previous ? previous[current] : null), errors)
    : null;
};

/**
 * Gets nested (object/arrays) property by path
 * @param propPath path like 'equipment.price' or 'equipmentAdditional[0].price' or equipmentAdditional.0.price
 * @param errorsObj object to traverse
 * @returns object path value
 */
export const getErrorMessage = (propPath: string, errorsObj: any) => {
  if (!errorsObj) return;

  propPath = propPath.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  propPath = propPath.replace(/^\./, ''); // strip a leading dot
  const segments = propPath.split('.');

  let errors = JSON.parse(JSON.stringify(errorsObj));

  for (let i = 0, n = segments.length; i < n; ++i) {
    const key = segments[i];

    if (key in errors && errors[key]) {
      errors = errors[key];
    } else {
      return;
    }
  }
  return errors;
};

export const checkIsEmpty = (value: any) => {
  return (
    // null or undefined
    value == null ||
    // has length and it's zero
    (value.hasOwnProperty('length') && value.length === 0) ||
    // is an Object and has no keys
    (value.constructor === Object && Object.keys(value).length === 0)
  );
};
