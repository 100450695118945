import {sortBy} from '@s-libs/micro-dash';
import {useEffect, useState} from 'react';
import {useGetAuthorizedAgents} from '../../..';
import {useUserInfo} from '../../../hooks';
import {Option} from '../types';

const defaultValue: Option = {label: 'All Agents', value: ''};

export function useAgentsOptions(addDefaultOption: boolean = true) {
  const {role, groupRole, uid, userGroupId, userDisplayName} = useUserInfo();

  const defaultOptionsArray = addDefaultOption ? [defaultValue] : [];

  const [agentOptions, setAgentOptions] = useState<Option[]>(defaultOptionsArray);

  const getAuthorizedAgents = useGetAuthorizedAgents({
    groupId: userGroupId,
    groupRole,
    role,
    uid,
  });

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      const authorizedAgents = await getAuthorizedAgents();

      if (!authorizedAgents) {
        return;
      }

      const agentOptionsData = authorizedAgents.map(agent => ({
        label: `${agent.firstName} ${agent.lastName}`,
        value: agent.id,
      }));

      const sortedAgentOptions = sortBy(agentOptionsData, [one => one.label.toLowerCase()]);

      isMounted && setAgentOptions(sortedAgentOptions);
    };

    try {
      fetchData();
    } catch (err) {
      console.error('An error occurred while getting agents data', err);
      setAgentOptions([]);
    }

    return () => {
      isMounted = false;
    };
  }, [getAuthorizedAgents]);

  return {
    agentOptions,
    defaultValue,
  };
}
