import {
  DepositCpyDay,
  DepositCpyDayView,
  DepositDayDetails,
  PaginatedDepositsCpyDaysResponse,
  PaginatedDepositsDaysViewResponse,
  PaginatedResponse,
} from '../types';

export const mapToPaginatedDepositsCpyDaysResponse = (
  days: PaginatedResponse<DepositCpyDay> | null,
  details: DepositDayDetails[] | null,
  returnDetailsInResponse: boolean
) => {
  const daysWithRefNumber = {
    ...days,
    data:
      days?.data.map(day => ({
        ...day,
        depositCpyDayDetails: day.depositCpyDayDetails.map(dayDetail => ({
          ...dayDetail,
          referenceNumber: details?.find(
            detail =>
              day.depositDate === detail.depositDate &&
              +detail.batchTotal === +dayDetail.depositAmount
          )?.referenceNumber,
        })),
      })) ?? [],
  } as PaginatedResponse<DepositCpyDay>;

  return mapPaginatedDepositsCpyDaysResponseToView(
    daysWithRefNumber,
    returnDetailsInResponse ? details : []
  );
};

export const mapToDepositsCpyDaysCsvData = (
  days: PaginatedResponse<DepositCpyDay> | null,
  details: DepositDayDetails[] | null
) => {
  const response = mapToPaginatedDepositsCpyDaysResponse(days, details, false);
  return response ? response.data : [];
};
export const mapPaginatedDepositsCpyDaysResponseToView = (
  response: PaginatedDepositsCpyDaysResponse,
  depositDayDetails: DepositDayDetails[] | null | undefined
): PaginatedDepositsDaysViewResponse | null => {
  if (!response) {
    return null;
  }
  const viewData: DepositCpyDayView[] = [];
  for (let day of response.data) {
    for (let detail of day.depositCpyDayDetails) {
      viewData.push({
        id: detail.id,
        depositDate: detail.depositDate,
        statementDate: detail.statementDate,
        mid: detail.mid,
        routingNumber: detail.routingNumber,
        ddaNumber: detail.ddaNumber,
        depositAmount: detail.depositAmount,
        isTotal: false,
        referenceNumber: detail.referenceNumber,
      });
    }
    viewData.push({
      id: `${day.mid}_totalId`,
      ddaNumber: 'Total Deposit',
      depositAmount: day.depositAmount,
      depositDayDetails: depositDayDetails
        ? depositDayDetails
            .filter(x => x.depositDate == day.depositDate)
            .map(x => {
              return {
                id: x.id,
                mid: x.mid,
                depositDate: x.depositDate,
                referenceNumber: x.referenceNumber,
                chargebackCaseNumber: x.chargebackCaseNumber,
                routingNumber: !!x.routingNumber ? +x.routingNumber : undefined,
                ddaNumber: x.ddaNumber,
                batchTotal: +x.batchTotal,
                dailyFees: x.dailyFees,
                chargeBackAmount: +x.chargeBackAmount,
                netBatches: +x.netBatches,
                reservedFunds: +x.reservedFunds,
                adjustmentAmount: +x.adjustmentAmount,
                netDeposit: +x.netDeposit,
              } as DepositDayDetails;
            })
        : null,
      isTotal: true,
    });
  }
  return {
    limit: response.limit,
    offset: response.offset,
    sort: response.sort,
    totalCount: response.totalCount,
    sortIndex: response.sortIndex,
    data: viewData,
  };
};
