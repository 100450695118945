import {
  Collections,
  selectTicketTypeCategoryView,
  TicketTypeCategory,
  UniversalSnapshot,
} from '../../..';
import {Firebase} from '../../../firebase';

export const getTicketCategory = async (ticketTypeId?: string, categoryId?: string) => {
  if (!ticketTypeId || !categoryId) {
    return null;
  }

  const snapshot = await Firebase.firestore
    .collection(Collections.ticketTypes)
    .doc(ticketTypeId)
    .collection(Collections.ticketTypeCategories)
    .doc(categoryId)
    .get();

  if (snapshot.exists) {
    const docRef = await snapshot.ref.get();
    return selectTicketTypeCategoryView(docRef as UniversalSnapshot<TicketTypeCategory>);
  }
};
