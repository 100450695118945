import type {Theme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import {getSalesLeadColor} from '@ozark/functions/src/constants';
import type {IndexableLead} from '@ozark/functions/src/documents';
import type {Option} from './types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    '& > *': {
      margin: theme.spacing(0, 1, 0, 0),
    },
  },
}));

interface LeadOptionProps {
  option: Option<IndexableLead>;
}

const LeadOption = ({option}: LeadOptionProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span style={{color: getSalesLeadColor(option.salesDisposition)}}>
        &bull;&bull;&bull;&nbsp;&nbsp;
      </span>

      {option.businessName && (
        <>
          <Typography>&bull;</Typography>
          <Typography variant="caption">{option.businessName}</Typography>
        </>
      )}

      {option.firstName && option.lastName && (
        <>
          <Typography>&bull;</Typography>
          <Typography variant="caption">
            {option.firstName} {option.lastName}
          </Typography>
        </>
      )}

      {option.email && (
        <>
          <Typography>&bull;</Typography>
          <Typography variant="caption">{option.email}</Typography>
        </>
      )}

      {option.phone && (
        <>
          <Typography>&bull;</Typography>
          <Typography variant="caption">{option.phone}</Typography>
        </>
      )}
    </div>
  );
};

export {LeadOption};
