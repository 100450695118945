import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import {
  AgentSubCollection,
  ApplicationSubCollection,
  LeadSubCollection,
} from '@ozark/functions/src/constants';
import {NoteView} from '@ozark/functions/src/documents';
import {groupBy} from '@s-libs/micro-dash';
import firebase from 'firebase/compat';
import {Fragment, useLayoutEffect, useMemo, useRef} from 'react';
import {timestampToHumanReadableDate} from './helpers';
import {NoteItem} from './NoteItem';
import {NotesUsersProfiles} from './types';

interface NoteListProps {
  profiles: NotesUsersProfiles;
  firestoreDocumentRef: firebase.firestore.DocumentReference | null;
  notesSubCollection:
    | ApplicationSubCollection.uwRiskNotes
    | ApplicationSubCollection.supportNotes
    | LeadSubCollection.notes
    | AgentSubCollection.notes;
  isFetching: boolean;
  noteViews?: NoteView[];
}

const DEFAULT_NOTE_VIEWS: NoteView[] = [];

const NoteList = ({
  profiles,
  firestoreDocumentRef,
  notesSubCollection,
  isFetching,
  noteViews = DEFAULT_NOTE_VIEWS,
}: NoteListProps) => {
  const noteListRef = useRef<HTMLUListElement | null>(null);

  const noteViewsGroupedByDate = useMemo(
    () => groupBy(noteViews, noteView => timestampToHumanReadableDate(noteView.createdAt)),
    [noteViews]
  );

  const dates = useMemo(() => Object.keys(noteViewsGroupedByDate), [noteViewsGroupedByDate]);

  useLayoutEffect(() => {
    if (!noteListRef.current) {
      return;
    }

    noteListRef.current.scrollTop = noteListRef.current.scrollHeight;
  }, [noteViews]);

  if (isFetching) {
    return (
      <Box textAlign="center" p={2}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (!dates.length) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Typography>There are no notes.</Typography>
        <Typography>You can add new note below.</Typography>
      </Box>
    );
  }

  return (
    <List
      sx={{
        overflowY: 'auto',
        maxHeight: '40vh',
        flexGrow: 1,
        color: '#4d6575',
      }}
      ref={noteListRef}
    >
      {dates.map(date => (
        <Fragment key={date}>
          <ListSubheader
            sx={{
              backgroundColor: theme => theme.palette.background.default,
              textAlign: 'center',
              top: -15,
            }}
          >
            {date}
          </ListSubheader>

          {noteViewsGroupedByDate[date].map(noteView => (
            <NoteItem
              profiles={profiles}
              key={noteView.id}
              firestoreDocumentRef={firestoreDocumentRef}
              notesSubCollection={notesSubCollection}
              noteView={noteView}
            />
          ))}
        </Fragment>
      ))}
    </List>
  );
};

export {NoteList};
