import {List} from '@mui/material';
import {
  lengthValidationText,
  lowerCharacterValidationText,
  matchValidationText,
  numberValidationText,
  specialCharacterValidationText,
  upperCharacterValidationText,
} from '../../helpers';
import {usePasswordValidation} from '../../hooks';
import {ListCheckItem} from './ListCheckItem';

interface Props {
  password: string;
  confirmation: string;
}

export const PasswordCheckList = ({password, confirmation}: Props) => {
  const {isValidLength, hasNumber, hasUpperCase, hasLowerCase, hasSpecialChar, isMatch} =
    usePasswordValidation({password, confirmation});

  return (
    <List dense disablePadding>
      <ListCheckItem isValid={isValidLength} text={lengthValidationText} />
      <ListCheckItem isValid={hasNumber} text={numberValidationText} />
      <ListCheckItem isValid={hasUpperCase} text={upperCharacterValidationText} />
      <ListCheckItem isValid={hasLowerCase} text={lowerCharacterValidationText} />
      <ListCheckItem isValid={hasSpecialChar} text={specialCharacterValidationText} />
      <ListCheckItem isValid={isMatch} text={matchValidationText} />
    </List>
  );
};
