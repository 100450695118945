import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {Box, CircularProgress, IconButton, InputBase, lighten, Theme} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {debounce} from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';

const DEBOUNCE_RATE = 350;

type Props = {
  fieldName: string;
  placeholder: string;
  disabled?: boolean;
  inputValue?: string;
  onSearchChange: (search: string) => void;
  fullWidth?: boolean;
};

export const InputSearch = ({
  fieldName,
  inputValue,
  placeholder,
  disabled,
  onSearchChange,
  fullWidth = false,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // handle enter key
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSearchChange?.(value);
  };

  const onSearchThrottled = useCallback(
    debounce((search: string) => onSearchChange?.(search), DEBOUNCE_RATE),
    [onSearchChange]
  );

  useEffect(() => onSearchThrottled(value), [value]);

  useEffect(() => {
    if (inputValue === undefined) {
      return;
    }

    setValue(inputValue);
  }, [inputValue]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '4px',
        backgroundColor: '#FAFDFE',
        transition: 'background-color 0.3s ease-in-out',
        '&:hover': {
          backgroundColor: lighten(theme.palette.common.white, 0.25),
        },
        '&:focus': {
          backgroundColor: lighten(theme.palette.common.white, 0.25),
        },
        paddingRight: theme.spacing(0.5),
        width: '100%',
        maxHeight: 35,
        [theme.breakpoints.up('sm')]: {
          width: fullWidth ? '100%' : 'auto',
        },
      }}
    >
      <div className={classes.searchIcon}>
        <SearchIcon color={isFocused ? 'primary' : undefined} />
      </div>
      <form className={classes.form} onSubmit={handleSubmit} autoComplete="off">
        <InputBase
          name={fieldName}
          disabled={disabled || isLoading}
          placeholder={placeholder}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{'aria-label': 'search'}}
          value={value}
          onChange={e => setValue(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          endAdornment={
            <Box minWidth={30}>
              {isLoading ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <CircularProgress size={23} />
                </Box>
              ) : (
                value && (
                  <IconButton size="small" onClick={() => setValue('')}>
                    <ClearIcon />
                  </IconButton>
                )
              )}
            </Box>
          }
        />
      </form>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      zIndex: 10,
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#aaa',
      '& svg': {
        transition: 'all 0.3s ease-in-out',
      },
    },
    form: {
      width: '100%',
    },
    inputRoot: {
      color: 'inherit',
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        minWidth: '34ch',
      },
    },
    inputInput: {
      margin: 0,
      padding: `${theme.spacing(0.75)} 0`,
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      flexGrow: 1,
    },
  })
);
