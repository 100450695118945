import {yupResolver} from '@hookform/resolvers/yup';
import {Grid} from '@mui/material';
import {
  EquipmentView,
  GroupRateProgram,
  isOtherNotListedEquipment,
  PlacementType,
  ProcessingTypes,
  ProcessingTypesKeys,
  RateSet,
  useRateProgramsByGroup,
} from '@ozark/common';
import {Loading, ProgramShipping, TextField} from '@ozark/common/components';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Redirect} from 'react-router-dom';
import * as yup from 'yup';
import * as ROUTES from '../../constants/routes';
import {useEquipment} from '../../firebase/hooks/useEquipment';
import {useStore} from '../../store';
import {useApplicationAddresses} from '../Application/hooks/useApplicationAddresses';
import {EquipmentFileDialog} from './EquipmentFileDialog';
import {ProgramDisplay} from './ProgramDisplay';

interface Props {
  setValidationHandler(handleSubmit: any): any;
}

const schema = yup.object().shape({
  programId: yup.string().required('Program Required - Please select a rate program.'),
  equipmentId: yup.string().required('Equipment Required - Please select your equipment.'),
});

export const ProgramDisplayPreview = ({
  equipment,
  getRateSet,
  getRateProgramsAsArray,
  processingType,
}: {
  equipment: EquipmentView[];
  getRateProgramsAsArray: () => GroupRateProgram[];
  getRateSet: (programName: string) => RateSet;
  processingType: ProcessingTypes;
}) => {
  return (
    <ProgramDisplay
      equipment={equipment}
      getRateSet={getRateSet}
      getRateProgramsAsArray={getRateProgramsAsArray}
      processingType={processingType}
    />
  );
};

const ProgramPage = ({setValidationHandler}: Props) => {
  const {application, update, group} = useStore();
  const {equipment} = useEquipment(application.data?.processingType);
  const {ratePrograms, getRateSetByProcessingType} = useRateProgramsByGroup(
    application.data?.group.id
  );
  const [selectedEquipment, setSelectedEquipment] = useState<EquipmentView | null | undefined>(
    null
  );
  const isOtherNotListed = isOtherNotListedEquipment(selectedEquipment?.name);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [isGateway, setIsGateway] = useState<boolean>(application?.data?.equipment?.isGateway);
  const {
    register,
    setValue,
    watch,
    control,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema),
    //@ts-ignore FIXME
    defaultValues: {
      ...(application.data ?? {}),
    },
    shouldUnregister: true,
  });
  const {getHomeAddress, getBusinessAddress} = useApplicationAddresses();

  const watchPlacementType = watch('equipment.placementType');
  const isVarSheetOnly = watchPlacementType === PlacementType.varSheetOnly;
  const isProgramShippingVisible = !isGateway && !isVarSheetOnly;

  const getRateProgramsAsArray = () => {
    const programs = !ratePrograms.promised && ratePrograms.data ? ratePrograms.data : [];
    const processingTypeKey = Object.entries(ProcessingTypes).find(
      ([key, val]) => val === application.data?.processingType
    )?.[0] as ProcessingTypesKeys;
    if (processingTypeKey) {
      return programs.filter(x => x.processingTypes[processingTypeKey].active);
    }
    return programs;
  };

  const getRateSet = (programName: string) =>
    getRateSetByProcessingType(ratePrograms, application.data?.processingType, programName)!;

  const handleProgramChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.name, event.target.value);

    if (event.target.name === 'programId') {
      const rateSet = getRateSetByProcessingType(
        ratePrograms,
        application.data?.processingType,
        undefined,
        event.target.value
      );
      if (!rateSet) {
        return;
      }
      // copy all fees from campaing to the app
      setValue('rateSet', rateSet);
    }
  };

  const handleEquipmentSelect =
    (id: string, isGateway: boolean): any =>
    (_event: React.MouseEvent<HTMLAnchorElement>) => {
      setValue('equipmentId', id);
      setValue('equipment.isGateway', isGateway);
      setIsGateway(isGateway);
      const selected = equipment.data?.find(e => e.id === id);
      setSelectedEquipment(selected);
      if (!isGateway) {
        setDialogOpen(true);
      }
    };

  useEffect(() => {
    const _handleSubmit = handleSubmit;
    setValidationHandler(
      () => (onSuccess: any, onError: any) =>
        _handleSubmit((data: any) => {
          const selected = equipment.data?.find(e => e.id === data.equipmentId);
          if (!isOtherNotListedEquipment(selected?.name) && data.equipment) {
            data.equipment.softwareName = null;
          }
          onSuccess(data);
        }, onError)
    );
  }, [setValidationHandler, handleSubmit, equipment.data]);

  useEffect(() => {
    register('programId');
    register('equipmentId');
  }, [register]);

  const watchProgramId = watch('programId');

  const watchEquipmentId = watch('equipmentId');

  useEffect(() => {
    if (!watchEquipmentId) {
      return;
    }
    const selected = equipment.data?.find(e => e.id === watchEquipmentId);
    setSelectedEquipment(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipment.data]);

  const homeAddress = getHomeAddress();
  const businessAddress = getBusinessAddress();

  if (!application.data) return <Loading />;

  if (!application.data?.processingType) {
    // Redirect to basics page if merchant is on this page and application doesn't have processing type
    return <Redirect to={ROUTES.BASICS} />;
  }

  if (ratePrograms.promised || !equipment.data || !ratePrograms.data) return <Loading />;
  return (
    <>
      <ProgramDisplay
        errors={errors}
        programId={watchProgramId}
        equipmentId={watchEquipmentId}
        equipment={equipment.data}
        getRateProgramsAsArray={getRateProgramsAsArray}
        getRateSet={getRateSet}
        handleProgramChange={handleProgramChange}
        handleEquipmentSelect={handleEquipmentSelect}
        processingType={application.data?.processingType}
      />
      <ProgramShipping
        isVisible={isProgramShippingVisible}
        control={control}
        errors={errors}
        setValue={setValue}
        watch={watch}
        isMerchantOnlineApp={true}
        homeAddress={homeAddress}
        businessAddress={businessAddress}
      />
      <EquipmentFileDialog
        application={application.data}
        update={update}
        equipment={selectedEquipment}
        open={group.data?.applicationSettings?.enableEquipmentFileDialog === true && dialogOpen}
        setOpen={setDialogOpen}
      />
      {isOtherNotListed && (
        <Grid item xs={12} sm={6}>
          <TextField
            name="equipment.softwareName"
            label="Software/Gateway Name"
            errors={errors}
            control={control}
          />
        </Grid>
      )}
    </>
  );
};

export default ProgramPage;
