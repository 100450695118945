export const OnlineAppNewIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g fill={'inherit'}>
        <g>
          <path d="M377,105L279.1,7c-4.5-4.5-10.6-7-17-7H256v112c0,8.8,7.2,16,16,16h112v-6.1C384,115.6,381.5,109.5,377,105z" />
          <path
            d="M344.5,296h-32v-32c0-4.4-3.6-8-8-8h-16c-4.4,0-8,3.6-8,8v32h-32c-4.4,0-8,3.6-8,8v16c0,4.4,3.6,8,8,8h32v32
			c0,4.4,3.6,8,8,8h16c4.4,0,8-3.6,8-8v-32h32c4.4,0,8-3.6,8-8v-16C352.5,299.6,348.9,296,344.5,296z"
          />
        </g>
        <path
          opacity={0.4}
          d="M272,128c-8.8,0-16-7.2-16-16V0H24C10.8,0,0,10.7,0,23.9V488c0,13.2,10.7,24,23.9,24H360
		c13.2,0,24-10.7,24-23.9V128H272z M144.5,192c35.3,0,64,28.7,64,64c0,35.3-28.7,64-64,64c-35.3,0-64-28.7-64-64
		C80.5,220.7,109.2,192,144.5,192z M256.5,428.8c0,10.6-10,19.2-22.4,19.2H54.9c-12.4,0-22.4-8.6-22.4-19.2v-19.2
		c0-31.8,30.1-57.6,67.1-57.6h5c25.5,10.7,54.2,10.7,79.7,0h5c37.1,0,67.2,25.8,67.2,57.6V428.8z M352.5,320c0,4.4-3.6,8-8,8h-32v32
		c0,4.4-3.6,8-8,8h-16c-4.4,0-8-3.6-8-8v-32h-32c-4.4,0-8-3.6-8-8v-16c0-4.4,3.6-8,8-8h32v-32c0-4.4,3.6-8,8-8h16c4.4,0,8,3.6,8,8
		v32h32c4.4,0,8,3.6,8,8V320z"
        />
        <g>
          <path
            d="M144.5,320c35.3,0,64-28.7,64-64s-28.7-64-64-64s-64,28.7-64,64S109.2,320,144.5,320z M189.3,352h-5
			c-25.5,10.7-54.2,10.7-79.7,0h-5c-37,0-67.1,25.8-67.1,57.6v19.2c0,10.6,10,19.2,22.4,19.2h179.2c12.4,0,22.4-8.6,22.4-19.2v-19.2
			C256.5,377.8,226.4,352,189.3,352z"
          />
        </g>
      </g>
    </svg>
  );
};
