import {TypographyTypeMap} from '@mui/material';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import {Dispositions, ReasonInputType, UserRoles} from '@ozark/common';
import reasons from './reasons';
export * as disputeReasonsByDisposition from './reasons';

export type DisputeReason = {
  name: string;
  title: string;
  inputType: ReasonInputType;
  Description: OverridableComponent<TypographyTypeMap>;
  descriptionHtml: string;
  FieldInput: (props: any) => JSX.Element;
};

export const disputableDispositions: Dispositions[] = [
  Dispositions.asIncomplete,
  Dispositions.asDeclined,
  Dispositions.asWithdrawn,
  Dispositions.uwPending,
  Dispositions.uwDeclined,
  Dispositions.uwWithdrawn,
];

export const disputableRoles: UserRoles[] = [
  UserRoles.admin,
  UserRoles.agentSupport,
  UserRoles.underwriter,
];

export const isDisputable = (disposition: Dispositions, role: UserRoles): boolean =>
  Boolean(disputableDispositions.includes(disposition) && disputableRoles.includes(role));

export const getReasonsByDisposition = (disposition: Dispositions): DisputeReason[] => {
  return reasons[disposition] as DisputeReason[];
};
