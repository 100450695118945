import {
  Checkbox,
  SortDirection,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import React, {ChangeEvent} from 'react';
import {FileFolder, HeadCell} from './types';

interface Props {
  numSelected: number;
  rowCount: number;
  handleSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
  orderBy: keyof FileFolder | undefined;
  order: SortDirection;
  handleRequestSort: (
    _event: React.MouseEvent<unknown>,
    property: keyof FileFolder | undefined
  ) => void;
  headCells: HeadCell[];
}
export const AttachmentsTableHead = ({
  numSelected,
  rowCount,
  handleSelectAllClick,
  orderBy,
  order,
  handleRequestSort,
  headCells,
}: Props) => {
  const createSortHandler =
    (property: keyof FileFolder | undefined) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={handleSelectAllClick}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell className={headCell.className}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order || undefined}
              onClick={headCell.sortable ? createSortHandler(headCell.id) : undefined}
              disabled={!headCell.sortable}
              hideSortIcon={!headCell.sortable}
              sx={{
                color: theme =>
                  headCell.sortable ? `${theme.palette.primary.main} !important` : undefined,
              }}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
        <TableCell />
      </TableRow>
    </TableHead>
  );
};
