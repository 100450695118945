import {ApiBase, SearchCriteria} from '@ozark/common';
import {ActiveFilter} from '@ozark/common/components';
import {
  Chargebacks,
  PaginatedChargebacksResponse,
} from '@ozark/functions/src/functions/express/private/types';
import {
  MidCbInfo,
  MidsByChargebacksInfo,
} from '@ozark/functions/src/functions/express/private/types/Chargebacks';
import {ContextKey} from '@ozark/functions/src/functions/express/private/types/Context';
import {CancelToken} from 'axios';
import {toQuery} from '../util/FilterUtil';

export class ChargebacksApi extends ApiBase {
  getChargebacks = (
    searchCriteria: SearchCriteria,
    mid?: string,
    filters?: ActiveFilter[],
    cancelToken?: CancelToken
  ) => {
    const queryString = toQuery(searchCriteria, filters);
    return this.get<PaginatedChargebacksResponse>(
      `chargebacks?${queryString}`,
      {
        [ContextKey.Mid]: mid,
      },
      cancelToken
    );
  };
  getChargebackByRefNumber = (mid: string, acquirerReferenceNumber: string) =>
    this.get<Chargebacks | null>(
      `chargebacks/chargeback?mid=${mid}&acquirerReferenceNumber=${acquirerReferenceNumber}`
    );

  getMidsByChargebacks = (year: number, month: number) =>
    this.get<MidsByChargebacksInfo[]>(
      `chargebacks/mids-by-chargebacks?year=${year}&month=${month}`
    );
  getMidChargebacksInfo = (mid: string, year: number, month: number) =>
    this.get<MidCbInfo>(`chargebacks/mids-by-chargebacks/${mid}?year=${year}&month=${month}`);
}
