import {yupResolver} from '@hookform/resolvers/yup';
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Fade,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select as MuiSelect,
  Typography,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  ApplicationType,
  ApplicationView,
  AsyncState,
  BillingMethod,
  Collections,
  createAuditNonce,
  DiscountDuration,
  EbtAcceptanceStatus,
  Firebase,
  getEnumKeyByValue,
  PCIFee,
  PCIFeeDefaultValues,
  PCIFeeTypes,
  PlacementType,
  ProcessingTypes,
  RateType,
  StatementType,
  useUserInfo,
} from '@ozark/common';
import {Loading, Select, TextField} from '@ozark/common/components';
import firebase from 'firebase/compat/app';
import {Fragment, useCallback, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {PRICING_VISA_MASTER} from '../../constants/strings';
import {getAdjustedDiscountRateBilledToMerchant, resetProgramFields} from '../../helpers';
import {useEquipment} from '../../hooks/useEquipment';
import {useNotification} from '../../hooks/useNotification';
import {AccordionSection} from './Elements/AccordionSection';
import {PricingEquipmentFormInput, pricingEquipmentSchema} from './PricingEquipmentSchema';
import {ProcessingTypeIcons} from './processingTypeIcons';
import {EquipmentList} from './SectionEquipment/EquipmentList';
import {PricingEquipmentShipping} from './SectionShipping/PricingEquipmentShipping';
import {AccordionId, accordionOnError} from './useAccordionOnError';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(0),
      padding: theme.spacing(1, 2, 2),
      color: '#4d6575',
    },
    flexGrow: {
      flexBasis: '100%',
    },
    alert: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: theme.spacing(1, 2, 0),
    },
    processingTypeTitle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      '& > *': {
        marginRight: theme.spacing(2),
      },
    },
    content: {
      padding: theme.spacing(1),
    },
    accordionWrapper: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      flexBasis: '100%',
    },
    sectionTitle: {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(2, 0, 0),
      marginRight: '-15px',
      fontWeight: 500,
      color: theme.palette.secondary.main,
      backgroundColor: '#f5fafc',
      padding: theme.spacing(2, 2),
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      '& > *': {
        marginRight: theme.spacing(1),
      },
    },
    saveButton: {
      width: 150,
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
  })
);

const formatAsMoney = (value: RateType | undefined) => {
  if (value && typeof value === 'number') {
    return value % 1 === 0 ? value : value.toFixed(2);
  }
  return '';
};

export const PricingEquipment = ({
  application,
  authUser,
  readonly,
}: {
  application: ApplicationView;
  authUser: AsyncState<firebase.User | null>;
  readonly: boolean;
}) => {
  const classes = useStyles();
  const {equipment: defaultEquipment} = useEquipment(application.processingType);
  const {
    equipment,
    equipmentAdditional,
    rateSet,
    percentReserve,
    shipping,
    merchantAdvantageProgram,
  } = application;
  const {isErpUser} = useUserInfo();
  const methods = useForm<PricingEquipmentFormInput>({
    resolver: yupResolver(pricingEquipmentSchema(application.rateSet?.applicationType)),
    defaultValues: {
      equipment,
      equipmentAdditional,
      percentReserve,
      rateSet: rateSet && {
        ...rateSet,
        qualifiedTransactionFeeVisaMastercardDiscover: formatAsMoney(
          rateSet.qualifiedTransactionFeeVisaMastercardDiscover
        ),
        midQualifiedTransactionFeeVisaMastercardDiscover: formatAsMoney(
          rateSet.midQualifiedTransactionFeeVisaMastercardDiscover
        ),
        nonQualifiedTransactionFeeVisaMastercardDiscover: formatAsMoney(
          rateSet.nonQualifiedTransactionFeeVisaMastercardDiscover
        ),
        qualifiedTransactionFeeAmex: formatAsMoney(rateSet.qualifiedTransactionFeeAmex),
        midQualifiedTransactionFeeAmex: formatAsMoney(rateSet.midQualifiedTransactionFeeAmex),
        nonQualifiedTransactionFeeAmex: formatAsMoney(rateSet.nonQualifiedTransactionFeeAmex),
      },
      shipping,
      merchantAdvantageProgram,
    },
    mode: 'onSubmit',
  });
  const {
    formState,
    formState: {errors},
    watch,
    control,
    handleSubmit,
    setValue,
  } = methods;

  const showNotification = useNotification();

  const {isDirty} = formState;
  const [loading, setLoading] = useState(false);
  const watchMerchantAdvantageProgram = watch('merchantAdvantageProgram');

  const watchPCIFee = watch('rateSet.pciFee', PCIFee.waived);
  const displayPCIFeeValue = Object.values(PCIFee)
    .filter(x => x !== PCIFee.waived)
    .includes(watchPCIFee as PCIFee);
  const watchEquipmentPrice = watch('equipment.price', application.equipment?.price);
  const watchEquipmentIsGateway = watch('equipment.isGateway');
  const watchPlacementType = watch('equipment.placementType');
  const watchApplicationType = watch('rateSet.applicationType');
  const watchQualifiedDiscountRateVisaMastercardDiscover = watch(
    'rateSet.qualifiedDiscountRateVisaMastercardDiscover'
  );
  const watchQualifiedDebitPINRate = watch('rateSet.qualifiedDebitPINRate');
  const watchQualifiedDebitNonPINRate = watch('rateSet.qualifiedDebitNonPINRate');
  const acceptEBT = application.ebtAcceptance === EbtAcceptanceStatus.Yes;
  const isErr = watchApplicationType === ApplicationType.err;
  const isCnp = watchApplicationType === ApplicationType.cnpVCp;
  const isInterchange = watchApplicationType === ApplicationType.interchange;
  const isFlat = watchApplicationType === ApplicationType.flatRate;

  const isDebit = ![
    ApplicationType.interchange,
    ApplicationType.err,
    ApplicationType.flatRate,
    ApplicationType.tiered,
  ].includes(watchApplicationType);

  const pciFeeTypes = Object.keys(PCIFeeTypes);

  const isGateway = watchEquipmentIsGateway === true;
  const isVarSheetOnly = watchPlacementType === PlacementType.varSheetOnly;
  const displayShippingSection = !isGateway && !isVarSheetOnly;

  const handlePCIFeeChange = (value: string) => {
    setValue('rateSet.pciFeeValue', PCIFeeDefaultValues[value as PCIFee]);
  };

  const onSuccess = async (data: any) => {
    try {
      setLoading(true);
      resetProgramFields(setValue, watchApplicationType);
      const ref = Firebase.firestore.collection(Collections.applications).doc(application.id);
      const auditNonce = createAuditNonce(authUser.data!.uid);
      await ref.set({...data, auditNonce, updatedAt: new Date()}, {merge: true});
      showNotification('success', 'Save was successful.');
      return true;
    } catch (err) {
      console.error(err);
      showNotification('error', 'An error occurred while saving.');
      return false;
    } finally {
      setLoading(false);
    }
  };

  const onError = (data: any) => {
    console.error('form errors', data);
    accordionOnError(data);
  };

  const onBeforeUploadEquipmentAttachment = useCallback(() => {
    return new Promise<boolean>((resolve, reject) => {
      handleSubmit(
        async data => {
          showNotification('info', 'All information should be saved before uploading VAR Sheet.');
          const wasSaved = await onSuccess(data);
          if (wasSaved) {
            resolve(true);
          } else {
            reject('There was an error during saving data');
          }
        },
        (data: any) => {
          onError(data);
          showNotification('error', 'Please enter all required data before uploading VAR Sheet.');
          reject('There was an error during saving data');
        }
      )();
    });
  }, [handleSubmit, onSuccess]);

  const saveApplication = useCallback(() => {
    handleSubmit(onSuccess, onError)();
  }, [handleSubmit]);

  if (defaultEquipment.promised || authUser.promised) return <Loading />;

  return (
    <FormProvider {...methods}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div className={classes.title}>
              <Typography variant="body1" component="div" noWrap>
                <b>Merchant Pricing</b>
              </Typography>
              <Chip
                icon={
                  ProcessingTypeIcons[
                    String(getEnumKeyByValue(ProcessingTypes, application.processingType))
                  ]
                }
                label={application.processingType}
                variant="outlined"
              />
            </div>
            <div className={classes.content}>
              {readonly && (
                <Alert severity="info" className={classes.alert}>
                  You currently have <b>read only</b> access to this application.
                </Alert>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Select
                    name={`rateSet.applicationType`}
                    label="Application Type"
                    errors={errors}
                    control={control}
                    disabled={readonly}
                    options={Object.values(ApplicationType)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Select
                    name={`rateSet.discountDuration`}
                    label="Discount Duration"
                    errors={errors}
                    control={control}
                    disabled={readonly}
                    options={Object.values(DiscountDuration)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Select
                    name="rateSet.mailStatement"
                    label="Mail Statement"
                    required
                    disabled={readonly}
                    errors={errors}
                    control={control}
                    yesOrNo
                  />
                </Grid>
                {watchApplicationType === ApplicationType.interchange && (
                  <Grid item xs={12}>
                    <Select
                      name="rateSet.statementType"
                      label="Statement Type"
                      defaultValue={StatementType.summary}
                      required
                      disabled={readonly}
                      errors={errors}
                      control={control}
                      options={Object.values(StatementType)}
                      compareFn={() => 0}
                    />
                  </Grid>
                )}
                <AccordionSection
                  title={`${PRICING_VISA_MASTER}${isDebit ? ' and Debit' : ''}`}
                  id={AccordionId.VisaMatercardDiscover}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        name={`rateSet.qualifiedDiscountRateVisaMastercardDiscover`}
                        label="Qualified Rate - Visa/MC/Discover"
                        errors={errors}
                        control={control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        helperText={
                          (watchApplicationType === ApplicationType.cashDiscount ||
                            watchApplicationType === ApplicationType.surcharge ||
                            watchApplicationType === ApplicationType.dualPricingPassThroughFees ||
                            watchApplicationType === ApplicationType.dualPricingFlatRate) &&
                          watchQualifiedDiscountRateVisaMastercardDiscover &&
                          `Adjusted Discount Rate Billed to Merchant ${getAdjustedDiscountRateBilledToMerchant(
                            watchQualifiedDiscountRateVisaMastercardDiscover
                          )}`
                        }
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        name={`rateSet.qualifiedTransactionFeeVisaMastercardDiscover`}
                        label="Qualified Transaction Fee - Visa/MC/Discover"
                        errors={errors}
                        control={control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    {!isCnp && !isInterchange && !isErr && !isFlat && (
                      <>
                        <Grid item xs={6}>
                          <TextField
                            name={`rateSet.midQualifiedDiscountRateVisaMastercardDiscover`}
                            label="Mid Qualified Rate - Visa/MC/Discover"
                            errors={errors}
                            control={control}
                            disabled={readonly}
                            type="number"
                            InputProps={{
                              endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            name={`rateSet.midQualifiedTransactionFeeVisaMastercardDiscover`}
                            label="Mid Qualified Transaction Fee - Visa/MC/Discover"
                            errors={errors}
                            control={control}
                            disabled={readonly}
                            type="number"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </Grid>
                      </>
                    )}
                    {!isInterchange && !isFlat && (
                      <Grid item xs={6}>
                        <TextField
                          name={`rateSet.nonQualifiedDiscountRateVisaMastercardDiscover`}
                          label={
                            isErr
                              ? 'Non-Qualified Surcharge - Visa/MC/Discover'
                              : 'Non-Qualified Rate - Visa/MC/Discover'
                          }
                          errors={errors}
                          control={control}
                          disabled={readonly}
                          type="number"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                        />
                      </Grid>
                    )}
                    {!isInterchange && !isErr && !isFlat && (
                      <Grid item xs={6}>
                        <TextField
                          name={`rateSet.nonQualifiedTransactionFeeVisaMastercardDiscover`}
                          label="Non-Qualified Transaction Fee - Visa/MC/Discover"
                          errors={errors}
                          control={control}
                          disabled={readonly}
                          type="number"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </AccordionSection>

                <AccordionSection title="American Express" id={AccordionId.AmericanExpress}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        name={`rateSet.qualifiedDiscountRateAmex`}
                        label="Qualified Rate - Amex"
                        errors={errors}
                        control={control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        name={`rateSet.qualifiedTransactionFeeAmex`}
                        label="Qualified Transaction Fee - Amex"
                        errors={errors}
                        control={control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>

                    {!isCnp && !isInterchange && !isErr && !isFlat && (
                      <>
                        <Grid item xs={6}>
                          <TextField
                            name={`rateSet.midQualifiedDiscountRateAmex`}
                            label="Mid Qualified Rate - Amex"
                            errors={errors}
                            control={control}
                            disabled={readonly}
                            type="number"
                            InputProps={{
                              endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            name={`rateSet.midQualifiedTransactionFeeAmex`}
                            label="Mid Qualified Transaction Fee - Amex"
                            errors={errors}
                            control={control}
                            disabled={readonly}
                            type="number"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </Grid>
                      </>
                    )}
                    {!isInterchange && !isFlat && (
                      <Grid item xs={6}>
                        <TextField
                          name={`rateSet.nonQualifiedDiscountRateAmex`}
                          label={
                            isErr ? 'Non-Qualified Surcharge - Amex' : 'Non-Qualified Rate - Amex'
                          }
                          errors={errors}
                          control={control}
                          disabled={readonly}
                          type="number"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                        />
                      </Grid>
                    )}
                    {!isInterchange && !isErr && !isFlat && (
                      <Grid item xs={6}>
                        <TextField
                          name={`rateSet.nonQualifiedTransactionFeeAmex`}
                          label="Non-Qualified Transaction Fee - Amex"
                          errors={errors}
                          control={control}
                          disabled={readonly}
                          type="number"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </AccordionSection>

                <AccordionSection
                  title="Other Volume & Other Item Fee (All Card Types)"
                  id={AccordionId.OtherVolumeAndItemFee}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        name={`rateSet.otherVolumeRate`}
                        label="Discount Rate"
                        errors={errors}
                        control={control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        name={`rateSet.otherItemFee`}
                        label="Transaction Fee"
                        errors={errors}
                        control={control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                  </Grid>
                </AccordionSection>

                {(!isCnp || application.processingType === ProcessingTypes.cardPresent) && (
                  <AccordionSection title="Debit & EBT" id={AccordionId.Debit}>
                    <Grid container spacing={2}>
                      {application.processingType === ProcessingTypes.cardPresent && (
                        <>
                          <Grid item xs={6}>
                            <TextField
                              name={`rateSet.qualifiedDebitPINRate`}
                              label="Debit Pin Based Rate"
                              errors={errors}
                              control={control}
                              defaultValue="0"
                              disabled={readonly}
                              type="number"
                              helperText={
                                watchApplicationType === ApplicationType.dualPricingFlatRate &&
                                watchQualifiedDebitPINRate &&
                                `Adjusted Debit Pin Based Rate Billed to Merchant ${getAdjustedDiscountRateBilledToMerchant(
                                  watchQualifiedDebitPINRate
                                )}`
                              }
                              InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              name={`rateSet.qualifiedDebitPINFee`}
                              label="Debit Pin Based Transaction Fee"
                              errors={errors}
                              control={control}
                              disabled={readonly}
                              defaultValue="0"
                              type="number"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                            />
                          </Grid>
                        </>
                      )}

                      {!isCnp && (
                        <Grid item xs={6}>
                          <TextField
                            name={`rateSet.qualifiedDebitNonPINRate`}
                            label="Signature Debit Non-Pin Based Rate"
                            errors={errors}
                            control={control}
                            defaultValue="0"
                            disabled={readonly}
                            type="number"
                            helperText={
                              watchApplicationType === ApplicationType.dualPricingFlatRate &&
                              watchQualifiedDebitNonPINRate &&
                              `Adjusted Debit Pin Based Rate Billed to Merchant ${getAdjustedDiscountRateBilledToMerchant(
                                watchQualifiedDebitNonPINRate
                              )}`
                            }
                            InputProps={{
                              endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                          />
                        </Grid>
                      )}

                      {!isCnp && (
                        <Grid item xs={6}>
                          <TextField
                            name={`rateSet.qualifiedDebitNonPINFee`}
                            label="Signature Debit Non-Pin Based Transaction Fee"
                            errors={errors}
                            control={control}
                            defaultValue="0"
                            disabled={readonly}
                            type="number"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </Grid>
                      )}

                      {acceptEBT && (
                        <>
                          <Grid item xs={6}>
                            <TextField
                              name={`rateSet.ebtDiscountRate`}
                              label="EBT Discount Rate"
                              errors={errors}
                              control={control}
                              defaultValue="0"
                              disabled={readonly}
                              type="number"
                              InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              name={`rateSet.ebtTransactionFee`}
                              label="EBT Transaction Fee"
                              errors={errors}
                              control={control}
                              disabled={readonly}
                              defaultValue="0"
                              type="number"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </AccordionSection>
                )}

                <AccordionSection title="Miscellaneous" id={AccordionId.Miscellaneous}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <TextField
                        name={`rateSet.accountOnFileFee`}
                        label="Account on File Fee"
                        errors={errors}
                        control={control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          endAdornment: (
                            <>
                              {watchPlacementType === PlacementType.gatewaySetupByProcessor && (
                                <InputAdornment position="end">
                                  ${(+(watchEquipmentPrice || 0)).toFixed(2)}
                                </InputAdornment>
                              )}
                            </>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name={`rateSet.chargebackFee`}
                        label="Chargeback Fee"
                        errors={errors}
                        control={control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name={`rateSet.retrievalFee`}
                        label="Retrieval Fee"
                        errors={errors}
                        control={control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name={`rateSet.monthlyMinimumFee`}
                        label="Monthly Minimum Fee"
                        errors={errors}
                        control={control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name={`rateSet.avsFee`}
                        label="AVS Fee"
                        errors={errors}
                        control={control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name={`rateSet.batchFee`}
                        label="Batch Fee"
                        errors={errors}
                        control={control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name={`rateSet.debitAccessFee`}
                        label="Debit Access Fee"
                        errors={errors}
                        control={control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name={`rateSet.additionalServicesFee`}
                        label="Additional Service Fees"
                        errors={errors}
                        control={control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Select
                        name={`rateSet.pciFee`}
                        label="PCI Fee"
                        errors={errors}
                        control={control}
                        disabled={readonly}
                        displayValue={true}
                        onChangeSuccess={handlePCIFeeChange}
                        compareFn={(a, b) => {
                          return pciFeeTypes.indexOf(a[0]) - pciFeeTypes.indexOf(b[0]);
                        }}
                        options={PCIFeeTypes}
                      />
                    </Grid>
                    {displayPCIFeeValue && (
                      <Grid item xs={3}>
                        <TextField
                          name={`rateSet.pciFeeValue`}
                          label="PCI Fee Value"
                          type="number"
                          errors={errors}
                          disabled={readonly}
                          control={control}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item xs={displayPCIFeeValue ? 2 : 3}>
                      <TextField
                        name={`rateSet.earlyDeconversionFee`}
                        label="Early Deconversion Fee"
                        errors={errors}
                        control={control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={displayPCIFeeValue ? 2 : 3}>
                      <TextField
                        name={`rateSet.annualFee`}
                        label="Annual Fee"
                        errors={errors}
                        control={control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={displayPCIFeeValue ? 2 : 3}>
                      <TextField
                        name={`rateSet.regulatoryFee`}
                        label="Regulatory Fee"
                        errors={errors}
                        control={control}
                        disabled={readonly}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                  </Grid>
                </AccordionSection>

                {isErpUser && (
                  <AccordionSection
                    title="Merchant Advantage Program"
                    id={AccordionId.MerchantAdvantageProgram}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel id="merchantAdvantageProgramLabel">Enrollment</InputLabel>
                          <MuiSelect
                            fullWidth
                            disabled={readonly}
                            label="Enrollment"
                            labelId="merchantAdvantageProgramLabel"
                            name="merchantAdvantageProgramEnabled"
                            value={!!watchMerchantAdvantageProgram ? 'yes' : 'no'}
                            onChange={({target: {value}}) => {
                              const nextProgram =
                                value === 'no'
                                  ? undefined
                                  : {
                                      amount: 0,
                                      billingMethod: BillingMethod.Monthly,
                                    };

                              setValue('merchantAdvantageProgram', nextProgram);
                            }}
                          >
                            <MenuItem value="yes">Yes</MenuItem>
                            <MenuItem value="no">No</MenuItem>
                          </MuiSelect>
                        </FormControl>
                      </Grid>
                      {!!watchMerchantAdvantageProgram && (
                        <>
                          <Grid item xs={3}>
                            <TextField
                              required
                              name="merchantAdvantageProgram.amount"
                              label="Amount"
                              errors={errors}
                              control={control}
                              disabled={readonly}
                              type="number"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Select
                              required
                              name="merchantAdvantageProgram.billingMethod"
                              label="Billing Method"
                              errors={errors}
                              control={control}
                              disabled={readonly}
                              type="number"
                              options={Object.values(BillingMethod)}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </AccordionSection>
                )}

                <AccordionSection title="Reserves" id={AccordionId.Reserves}>
                  <Box>
                    <TextField
                      name={`percentReserve`}
                      label="Reserve Percent"
                      errors={errors}
                      control={control}
                      disabled={readonly}
                      type="number"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        inputProps: {
                          min: 0,
                          max: 100,
                        },
                      }}
                    />
                  </Box>
                </AccordionSection>

                <EquipmentList
                  applicationId={application.id}
                  applicationType={watchApplicationType}
                  applicationProcessingType={application.processingType}
                  readonly={readonly}
                  // TODO remove in next task: possible unnecessary property
                  softwareName={application.equipment?.softwareName}
                  disposition={application.disposition}
                  onBeforeUpload={onBeforeUploadEquipmentAttachment}
                  saveApplication={saveApplication}
                />
                {displayShippingSection && (
                  <AccordionSection title="Shipping" defaultExpanded id={AccordionId.Shipping}>
                    <PricingEquipmentShipping readonly={readonly} application={application} />
                  </AccordionSection>
                )}

                {!readonly && (
                  <Fragment>
                    <Fade in={isDirty}>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Fade>
                    <Fade in={isDirty} unmountOnExit={true}>
                      <Grid item xs={12}>
                        <div className={classes.footer}>
                          <Button
                            variant="outlined"
                            color="primary"
                            className={classes.saveButton}
                            disabled={loading}
                            onClick={handleSubmit(onSuccess, onError)}
                          >
                            {loading ? (
                              <CircularProgress size={24} />
                            ) : (
                              <Fragment>Save Changes</Fragment>
                            )}
                          </Button>
                        </div>
                      </Grid>
                    </Fade>
                  </Fragment>
                )}
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
