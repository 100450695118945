import {
  DocumentBase,
  DocumentCreatedBy,
  DocumentDeleted,
  DocumentUpdated,
  NoteReply,
  NoteReplyView,
} from '.';
import {UniversalTimestamp} from './compat';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export type Note = DocumentBase &
  DocumentCreatedBy &
  DocumentUpdated &
  DocumentDeleted & {
    replies?: NoteReply[];
    message: string;
    emailMessage?: string;
    createdAt: UniversalTimestamp;
    authorEmail?: string;
    tag?: string; // this field is needed only for UW Pending documents notes
    repliesUpdatedAt?: UniversalTimestamp; // this field is needed only to request actual replies when we add/update/delete reply
    useWebhook?: boolean; // if set to true then we should send webhook
  };

export type NoteView = ViewBase<Note>;

export const selectNoteView: ViewSelector<NoteView, Note> = snapshot => {
  const note: NoteView = {
    ...defaultViewSelector(snapshot),
  };
  return note;
};

export const selectNoteReplyView: ViewSelector<NoteView, NoteReply> = snapshot => {
  const noteReply: NoteReplyView = {
    ...defaultViewSelector(snapshot),
  };
  return noteReply;
};

export type NoteAttachment = {
  id?: string;
  attachmentId: string;
  cloudPath: string;
  name: string;
  createdAt: UniversalTimestamp;
};

export const AUTOMATION_NOTE_UID = 'automation-note';
