export enum OwnerTitles {
  CEO = 'CEO',
  Owner = 'Owner',
  Manager = 'Manager',
  Director = 'Director',
  President = 'President',
  Partner = 'Partner',
  VicePresident = 'Vice President',
  Secretary = 'Secretary',
  Treasurer = 'Treasurer',
  CFO = 'CFO',
  Controller = 'Controller',
  Member = 'Member',
}

export const OwnerTitleValues = Object.values(OwnerTitles);
