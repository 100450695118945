import {Autocomplete, Box, TextField} from '@mui/material';
import {omit} from 'lodash';
import {useEffect, useState} from 'react';
import {
  AssociationType,
  Collections,
  Department,
  Firebase,
  Profile,
  selectProfileView,
  TicketAssociation,
  TicketAssociationErpUser,
  UniversalSnapshot,
} from '../../../..';
import {useUserInfo} from '../../../../hooks/useUserInfo';
import {FIELD_NAME_ERP_USER} from '../../constants/constants';
import {InputAssociationError} from '../../types';

type Props = {
  draft: TicketAssociation;
  setDraft: (draft: TicketAssociation) => void;
  errors: Record<InputAssociationError, string>;
  setErrors: (errors: Record<InputAssociationError, string>) => void;
};

export const AssociationEditorValueErpUser = ({draft, setDraft, errors, setErrors}: Props) => {
  const {isPortal} = useUserInfo();
  const [erpUsers, setErpUsers] = useState<TicketAssociationErpUser[]>([]);

  useEffect(() => {
    Firebase.firestore
      .collection(Collections.profiles)
      .where('isActive', '==', true)
      .orderBy('firstName')
      .get()
      .then(snap => {
        if (snap.size) {
          const profileViews = snap.docs.map(doc => {
            const profileView = selectProfileView(doc as UniversalSnapshot<Profile>);
            return {
              id: profileView.id,
              name: `${profileView.firstName} ${profileView.lastName}`.trim(),
              department: profileView.department as Department,
            };
          });
          setErpUsers(profileViews);
        } else {
          setErpUsers([]);
        }
      })
      .catch(error => {
        console.error('Error writing document: ', error);
      });
  }, [draft.erpUser]);

  return (
    <>
      <Box mt={2}>
        <Autocomplete
          disabled={isPortal}
          options={erpUsers}
          value={draft.erpUser}
          onChange={(
            _: React.SyntheticEvent<Element, Event>,
            newValue: TicketAssociationErpUser | null
          ) => {
            setDraft({
              type: AssociationType.erpUser,
              erpUser: newValue ?? undefined,
            });
            if (newValue) {
              setErrors(omit(errors, FIELD_NAME_ERP_USER) as Record<InputAssociationError, string>);
            }
          }}
          getOptionLabel={(item: TicketAssociationErpUser) => item?.name ?? ''}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          disableClearable
          renderInput={params => (
            <TextField
              {...params}
              label="ERP User"
              required
              variant="standard"
              error={Boolean(errors[FIELD_NAME_ERP_USER])}
              helperText={errors[FIELD_NAME_ERP_USER]}
            />
          )}
        />
      </Box>
    </>
  );
};
