import {Box, Link, Typography} from '@mui/material';

interface FooterProps {
  groupName: string;
  groupDomain: string;
}

export const PageFooter = ({groupName, groupDomain}: FooterProps) => {
  return (
    <Box
      component="footer"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 2,
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
      }}
    >
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href={groupDomain}>
          {groupName}
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Box>
  );
};
