import {DateTimePicker, LocalizationProvider} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import {isAfter, isBefore} from 'date-fns';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {CalendarEvent, CalendarEventParticipant, Collections, Firebase} from '../..';
import {CalendarEventFormLeadData} from './CalendarEventFormLeadData';
import {useParticipants} from './hooks/useParticipants';

type Props = {
  title: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: (data: CalendarEvent) => void;
  event: CalendarEvent;
};

const FIELD_NAME_PARTICIPANTS = 'participants';

export const CalendarEventForm = ({title, open, setOpen, onSubmit, event}: Props) => {
  const {handleSubmit, control, setValue, clearErrors, reset, formState} = useForm<CalendarEvent>({
    defaultValues: event,
  });

  const isExistingEvent = Boolean(event.id);
  const isPastEvent = isExistingEvent && event.dateTime && isBefore(event.dateTime, new Date());
  const isTicketEvent = Boolean(event.association?.type === 'Ticket');
  const isLeadEvent = Boolean(event.association?.type === 'Lead');

  const {participantOptions} = useParticipants();

  const handleClose = () => setOpen(false);

  const handleCancel = () => {
    handleClose();
    reset(event);
  };

  const handleOnSubmit: SubmitHandler<CalendarEvent> = data => {
    handleClose();
    onSubmit(data as CalendarEvent);
  };

  const handleMeetingDelete = async (calendarEventId?: string) => {
    if (!calendarEventId) return;
    try {
      await Firebase.firestore.collection(Collections.calendar).doc(calendarEventId).delete();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box>
          <Stack spacing={3} sx={{width: 450}}>
            <Controller
              name="subject"
              rules={{required: 'Title is required'}}
              control={control}
              render={({field, fieldState}) => (
                <TextField
                  {...field}
                  disabled={isPastEvent}
                  required
                  fullWidth
                  label="Title"
                  variant="standard"
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            {event.type === 'Event' && (
              <Controller
                name="description"
                control={control}
                render={({field, fieldState}) => (
                  <TextField
                    {...field}
                    multiline
                    maxRows={2}
                    disabled={isPastEvent}
                    fullWidth
                    label="Description"
                    variant="standard"
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
            <Controller
              name="type"
              control={control}
              render={({field}) => (
                <FormControl fullWidth variant="standard" disabled sx={{display: 'none'}}>
                  <InputLabel id="type-label">Type</InputLabel>
                  <Select {...field} labelId="type-label" label="Type">
                    <MenuItem value="Reminder">Reminder</MenuItem>
                    <MenuItem value="Event">Event</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            {isLeadEvent && <CalendarEventFormLeadData leadId={event.association?.id} />}
            <Controller
              name="dateTime"
              control={control}
              rules={{
                validate: (date?: Date) =>
                  (date && isAfter(date, new Date())) ||
                  'Event date is required and must be in the future',
              }}
              render={({field, fieldState}) => (
                <FormControl fullWidth variant="standard" required>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      {...field}
                      disabled={isPastEvent}
                      label="Event Date/Time"
                      minutesStep={5}
                      disablePast
                      renderInput={params => (
                        <TextField
                          {...params}
                          required
                          error={isPastEvent ? false : Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          variant="standard"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              )}
            />
            <Stack direction="row" spacing={2}>
              <Controller
                name="association.type"
                control={control}
                render={({field}) => (
                  <FormControl
                    fullWidth
                    variant="standard"
                    disabled={isTicketEvent || isLeadEvent || isPastEvent}
                  >
                    <InputLabel id="reminder-label">Association Type</InputLabel>
                    <Select {...field} labelId="reminder-label" label="Association Type">
                      <MenuItem value="Ticket">Ticket</MenuItem>
                      <MenuItem value="Application">Applicaton</MenuItem>
                      <MenuItem value="User">User</MenuItem>
                      <MenuItem value="Lead">Lead</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                name={
                  event.association?.type === 'Lead'
                    ? 'association.id'
                    : 'association.distinguishableId'
                }
                control={control}
                render={({field}) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Association ID"
                    variant="standard"
                    disabled={isTicketEvent || isLeadEvent || isPastEvent}
                  />
                )}
              />
            </Stack>

            <Controller
              name="reminderOffset"
              control={control}
              render={({field}) => (
                <FormControl fullWidth variant="standard" disabled sx={{display: 'none'}}>
                  <InputLabel id="reminder-label">Reminder</InputLabel>
                  <Select
                    {...field}
                    labelId="reminder-label"
                    id="reminder-select"
                    label="Reminder"
                    disabled={isPastEvent}
                  >
                    <MenuItem value={0}>0 minutes before event</MenuItem>
                    <MenuItem value={1}>1 minute before event</MenuItem>
                    <MenuItem value={5}>5 minutes before event</MenuItem>
                    <MenuItem value={10}>10 minutes before event</MenuItem>
                  </Select>
                  <FormHelperText>You will recieve an In-App and Email reminder</FormHelperText>
                </FormControl>
              )}
            />
            <Controller
              name="participants"
              control={control}
              rules={{required: 'Participants are required'}}
              render={({field, fieldState}) => (
                <Autocomplete
                  id="participants-autocomplete"
                  multiple
                  disabled={isPastEvent}
                  sx={{display: 'none'}}
                  value={field.value as CalendarEventParticipant[]}
                  onChange={(_, data) => {
                    setValue(FIELD_NAME_PARTICIPANTS, data);
                    clearErrors(FIELD_NAME_PARTICIPANTS);
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={participantOptions}
                  getOptionLabel={(option: CalendarEventParticipant) =>
                    option?.name ? `${option?.name} (${option?.role})` : 'n/a'
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Participants"
                      placeholder="Participants"
                      sx={{'& .MuiInput-root': {pb: 0.5}}}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions sx={{mt: 3, mb: 1, mx: 1, display: 'flex'}}>
        <Box flex={1}>
          {event.id && (
            <Button color="error" onClick={() => handleMeetingDelete(event.id)}>
              Delete Event
            </Button>
          )}
        </Box>
        <Box>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            onClick={handleSubmit(handleOnSubmit)}
            disabled={
              isExistingEvent
                ? !formState.isDirty
                : isPastEvent || (!formState.isDirty && !isTicketEvent)
            }
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
