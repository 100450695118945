import {Box, styled, Typography} from '@mui/material';
import {Dispositions} from '@ozark/common';
import {BoxEllipsis} from '../common/BoxEllipsis';
import {getGridTemplate} from './ApplicationOption';

type Props = {
  group: string;
  isERP?: boolean;
};

const Column = styled(BoxEllipsis)(({theme}) => ({
  padding: theme.spacing(0, 1),
  borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
  '&:first-of-type': {
    borderLeft: 'none',
  },
}));

export const ApplicationOptionHeader = ({group, isERP}: Props) => {
  const isBoarded = group.includes(Dispositions.boarded);

  return (
    <Typography
      variant="body2"
      width="100%"
      fontSize={12}
      component="div"
      sx={{pb: 1, mt: 0, pl: 3}}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: getGridTemplate(isBoarded, isERP),
        }}
      >
        <Column>Application ID</Column>
        {isBoarded && (
          <>
            <Column>Platform</Column>
            <Column>MID</Column>
          </>
        )}
        <Column>Legal Business Name</Column>
        <Column>Doing Business As</Column>
        <Column>Owner</Column>
        <Column>Email</Column>
        {isBoarded && (
          <>
            <Column>Account Status</Column>

            {isERP && <Column>Risk Status</Column>}
          </>
        )}
        {!isBoarded && <Column>Phone</Column>}
      </Box>
    </Typography>
  );
};
