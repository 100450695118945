import NotificationsIcon from '@mui/icons-material/Notifications';
import {Badge, IconButton} from '@mui/material';
import {Fragment, useState} from 'react';
import {prepareTextPreview} from '../../util';
import {NotificationsState, useNotificationsState} from './hooks/useNotificationsState';
import {ImportantNotificationsDialog} from './ImportantNotificationsDialog';
import {NotificationsDrawer} from './NotificationsDrawer';
import {NotificationsTabIndicator} from './NotificationsTabIndicator';

export const Notifications = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <NotificationsState.Provider>
      <NotificationsTabIndicator />
      <NotificationsButton toggleDrawer={toggleDrawer} />
      <NotificationsDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
      <ImportantNotificationsDialog />
    </NotificationsState.Provider>
  );
};

export const RenderNotificationText = (text: string | string[], html: boolean = false) =>
  Array.isArray(text) ? (
    text.map((line, index) => (
      <Fragment key={`${line}${index}`}>
        {line}
        <br />
      </Fragment>
    ))
  ) : html ? (
    <div className="richtext-container" dangerouslySetInnerHTML={{__html: text}} />
  ) : (
    prepareTextPreview(text)
  );

const NotificationsButton = ({
  toggleDrawer,
}: {
  toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}) => {
  const {unreadCount: unseenCount} = useNotificationsState();
  return (
    <IconButton
      aria-label={`show ${unseenCount} new notifications`}
      color="inherit"
      onClick={toggleDrawer(true)}
      size="large"
    >
      <Badge badgeContent={unseenCount} color="error">
        <NotificationsIcon />
      </Badge>
    </IconButton>
  );
};
