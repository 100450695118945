import {Avatar, Box, Grid, MenuItem, TextField} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {AsyncState, EquipmentView, getErrorMessage, useNotification} from '@ozark/common';
import ComingSoonImage from '@ozark/common/static/images/comingsoon.png';
import {useEffect, useRef} from 'react';
import {useFormContext} from 'react-hook-form';
import {PricingEquipmentFormInput} from '../PricingEquipmentSchema';

type Props = {
  targetFieldName: `equipmentAdditional.${number}` | 'equipment';
  equipment: AsyncState<EquipmentView[]>;
  readonly: boolean;
};

export const FieldSelectEquipmentValidatedNative = ({
  readonly,
  targetFieldName,
  equipment,
}: Props) => {
  const {
    setValue,
    clearErrors,
    watch,
    formState: {errors},
  } = useFormContext<PricingEquipmentFormInput>();
  const showNotification = useNotification();
  const fieldName = `${targetFieldName}.id` as const;
  const equipmentId = watch(fieldName, '');
  const classes = useStyles();
  const ref = useRef<HTMLInputElement>();
  const errorMsg = getErrorMessage(fieldName, errors);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedId = event.target.value;
    const selected = equipment.data?.find((e: any) => e.id === selectedId);
    if (!selected) {
      showNotification('error', 'Equipment is not selected.');
      return;
    }
    ['id', 'name', 'platform', 'price', 'imageUrl', 'isGateway', 'duration', 'isVarSheet'].forEach(
      property => {
        const updatedValue =
          property === 'isVarSheet' ? Boolean(selected[property]) : selected[property];

        setValue(
          `${targetFieldName}.${property}` as keyof PricingEquipmentFormInput,
          updatedValue,
          {
            shouldDirty: true,
          }
        );
        clearErrors(`${targetFieldName}.${property}` as keyof PricingEquipmentFormInput);
      }
    );
  };

  useEffect(() => {
    if (equipment?.data && errorMsg) {
      ref.current?.focus();
    }
  }, [errorMsg]);

  return (
    <Grid item xs={6}>
      <TextField
        name={fieldName}
        className={classes.root}
        margin="normal"
        fullWidth
        label="Equipment Type"
        value={equipmentId}
        onChange={handleChange}
        placeholder="Please select equipment"
        error={Boolean(errorMsg)}
        autoFocus={Boolean(errorMsg)}
        helperText={errorMsg?.message}
        variant="outlined"
        disabled={readonly || !equipment?.data}
        inputRef={ref}
        select
      >
        {equipment?.data?.sortAndMap(
          (e: EquipmentView) => (
            <MenuItem key={e.id} value={e.id}>
              <Box display="flex" alignItems="center">
                <Avatar
                  src={e.imageUrl ? e.imageUrl : ComingSoonImage}
                  variant="rounded"
                  className={classes.avatar}
                />
                {e.name}
              </Box>
            </MenuItem>
          ),
          (e: any) => e.name
        ) ?? []}
      </TextField>
    </Grid>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      '& .MuiSelect-root': {
        padding: theme.spacing(1, 2),
        minHeight: '40px',
      },
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
  })
);
