import {DocumentBase, ViewBase} from '.';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export type Version = DocumentBase & {
  version: number;
};

export type VersionView = ViewBase<Version>;

export const selectVersionView: ViewSelector<VersionView, Version> = snapshot => {
  const version: VersionView = {
    ...defaultViewSelector(snapshot),
  };
  return version;
};
