export const conditionallyApprovedBoardedRaw = [
  {
    name: 'Unable to Validate Banking (CP or CNP)',
    description: `We are unable to validate the banking provided. This merchant has been granted a conditional approval to begin processing but will NOT be funded until we are provided one of the following within 5 days of the first batch;

* Voided check
* Bank letter containing the account &amp; routing number and business name
* Bank statement showing the complete account number and routing number

`,
    type: 'checkbox',
  },
  {
    name: 'Proof of Physical Location (CP Only)',
    description: `We are unable to validate the merchant&#39;s business online through websites, marketing sites, reviews, images, etc. This merchant has been granted a conditional approval to begin processing but will NOT be funded until we are provided the merchant&#39;s current business license and/or store lease agreement`,
    type: 'checkbox',
  },
  {
    name: 'Additional Website Work (ECOM)',
    description: `The website appears to be under construction or has not been completed with all required regulatory documentation. This merchant has been granted a conditional approval to begin processing but will NOT be funded until the following items have been completed;

* Home Page
* Contact Page
* Terms and Conditions
* Privacy Policy
* Refund Policy
* Shipping Policy (if applicable)
* Products/Ingredients List (if applicable)

`,
    type: 'checkbox',
  },
  {
    name: 'SOS Good Standing (CNP)',
    description: `The Secretary of State shows that the merchant&#39;s registration is past due and that they are not in good standing/pending inactive. This merchant has been granted a conditional approval to begin processing but will NOT be funded until we are provided a proof of reinstatement or good standing`,
    type: 'checkbox',
  },
  {
    name: 'Other Reason',
    description: `Other Reason`,
    type: 'rich-text',
  },
];
