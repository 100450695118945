import {yupResolver} from '@hookform/resolvers/yup';
import {LoadingButton} from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useTheme,
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import {ReserveActivity} from '@ozark/functions/src/functions/express/private/types/Reserve';
import {useCallback, useState} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {TextField} from '../../TextField';

interface Props {
  reserveActivity: ReserveActivity;
  onSubmit: (data: ReserveActivity) => Promise<boolean>;
}

export const EditReserveDialog = ({onSubmit, reserveActivity}: Props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [displayDialog, setDisplayDialog] = useState(false);
  const {
    setValue,
    control,
    handleSubmit,
    formState: {errors},
  } = useForm<ReserveActivity>({
    resolver: yupResolver(yup.object().shape({})),
    shouldUnregister: true,
    defaultValues: {
      ...reserveActivity,
      grossSalesAmountUpdated: reserveActivity.grossSalesAmountUpdated
        ? reserveActivity.grossSalesAmountUpdated
        : reserveActivity.grossSalesAmount,
      reserveReleasePercentUpdated: reserveActivity.reserveReleasePercentUpdated
        ? reserveActivity.reserveReleasePercentUpdated
        : reserveActivity.reserveReleasePercent,
      releaseAmountUpdated: reserveActivity.releaseAmountUpdated
        ? reserveActivity.releaseAmountUpdated
        : reserveActivity.releaseAmount,
      reserveAmountUpdated: reserveActivity.reserveAmountUpdated
        ? reserveActivity.reserveAmountUpdated
        : reserveActivity.reserveAmount,
    },
  });
  const theme = useTheme();

  const onSuccess: SubmitHandler<ReserveActivity> = useCallback(async data => {
    const isSuccessUpdate = await onSubmit({...data, id: reserveActivity.id});
    if (isSuccessUpdate) {
      onClose();
    }
  }, []);

  const beforeSubmit = useCallback(async () => {
    try {
      setIsSaving(true);
      await handleSubmit(onSuccess)();
    } finally {
      setIsSaving(false);
    }
  }, [handleSubmit, onSuccess]);

  const handleEditClick = useCallback(() => {
    setDisplayDialog(true);
  }, []);

  const onClose = useCallback(() => {
    setDisplayDialog(false);
  }, []);

  return (
    <>
      <Button
        variant="contained"
        onClick={handleEditClick}
        size="small"
        // sx={{mt: 1.5, mb: 1.5, mr: 2}}
      >
        Edit
      </Button>
      {displayDialog && (
        <Dialog open={true} onClose={onClose} aria-labelledby="create-dialog-title" maxWidth={'lg'}>
          <DialogTitle id="create-dialog-title">Edit Reserve</DialogTitle>
          <DialogContent
            sx={{
              overflowX: 'hidden',
            }}
          >
            <Box
              sx={{
                [theme.breakpoints.up('md')]: {
                  width: 600,
                },
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={'Displayed?'}
                        secondary={'Enabling will display reserve to other users.'}
                      />

                      <ListItemSecondaryAction>
                        <Controller
                          control={control}
                          name="isDisplayed"
                          defaultValue={false}
                          render={({field: {value}}) => (
                            <Switch
                              checked={value || false}
                              onClick={() => {
                                setValue('isDisplayed', !value);
                              }}
                            />
                          )}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="grossSalesAmount"
                    label="Original Gross Amount"
                    errors={errors}
                    control={control}
                    inputProps={{readOnly: true}}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="grossSalesAmountUpdated"
                    label="Gross Amount to Display"
                    errors={errors}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="reserveReleasePercent"
                    label="Original Reserve Percent"
                    errors={errors}
                    control={control}
                    inputProps={{readOnly: true}}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="reserveReleasePercentUpdated"
                    label="Reserve Percent to Display"
                    errors={errors}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="releaseAmount"
                    label="Original Release Amount"
                    errors={errors}
                    control={control}
                    inputProps={{readOnly: true}}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="releaseAmountUpdated"
                    label="Release Amount to Display"
                    errors={errors}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="reserveAmount"
                    label="Original Reserve Amount"
                    errors={errors}
                    control={control}
                    inputProps={{readOnly: true}}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="reserveAmountUpdated"
                    label="Reserve Amount to Display"
                    errors={errors}
                    control={control}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} disabled={isSaving}>
              Cancel
            </Button>
            <LoadingButton onClick={beforeSubmit} color="primary" loading={isSaving}>
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
