import CheckIcon from '@mui/icons-material/Check';
import InfoIcon from '@mui/icons-material/Info';
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import {
  Equipment,
  EquipmentView,
  GroupRateProgram,
  prepareTextPreview,
  ProcessingTypes,
  RateProgram,
  RateSet,
  ViewBase,
  yesOrNoRegexp,
} from '@ozark/common';
import ComingSoonImage from '@ozark/common/static/images/comingsoon.png';
import clsx from 'clsx';
import React, {Fragment, useState} from 'react';
import replace from 'react-string-replace';
import Title from '../Title';
import {LearnMore} from './LearnMore';
import {ListItem} from './ListItem';

export const ProgramDisplay = ({
  errors,
  program,
  equipment,
  programId,
  equipmentId,
  processingType,
  getRateProgramsAsArray,
  getRateSet,
  handleProgramChange,
  handleEquipmentSelect,
}: {
  errors?: any;
  program?: string;
  equipment: EquipmentView[];
  programId?: string;
  equipmentId?: string;
  processingType: ProcessingTypes;
  getRateProgramsAsArray: () => GroupRateProgram[];
  getRateSet: (programName: string) => RateSet;
  handleProgramChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleEquipmentSelect?: (id: string, isGateway: boolean) => () => void;
}) => {
  const classes = useStyles();

  const [selectedLearnMore, setSelectedLearnMore] = useState<ViewBase<Equipment> | null>(null);
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);

  const handleLearnMoreClick =
    (equipment: ViewBase<Equipment> | null) =>
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setAnchorElement(event.currentTarget);
      setSelectedLearnMore(equipment);
    };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const getProgramSection = (rateProgram: RateProgram | GroupRateProgram, xlSize: number) => {
    const rateSet = getRateSet(rateProgram.name);
    const isSelected = program && program === rateProgram.name;
    return (
      <Grid key={`${rateProgram.name}-${rateProgram.program}`} item xs={12} sm={6} xl={xlSize}>
        <Paper elevation={0} className={classes.paper}>
          <Grid container spacing={2}>
            <Grid className={classes.alignCenter} item xs={12}>
              <FormControlLabel
                value={rateProgram.id}
                control={<Radio color="primary" />}
                label={
                  <Typography variant="h5" className={classes.programName}>
                    {rateProgram.name}
                    <LightTooltip title={rateProgram.tooltip}>
                      <InfoIcon color="primary" />
                    </LightTooltip>
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" align="center" gutterBottom>
                {rateSet.featuredText}&nbsp;
                <Typography variant="caption">{rateSet.featuredCaption}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div className={clsx(classes.list, {[classes.selected]: isSelected})}>
                <Typography variant="h6" align="center" gutterBottom>
                  {rateProgram.benefits.title}
                </Typography>
                <Typography align="center" gutterBottom>
                  {rateProgram.description}
                </Typography>
                {rateProgram.benefits.list.map(e => (
                  <Fragment key={`${rateProgram.program}-${e}`}>
                    <Divider />
                    <ListItem>
                      {/* ((?:a|b)c)  */}
                      {replace(e, yesOrNoRegexp, (match: string) => {
                        return <b key={match}>{match.toLocaleUpperCase()}</b>;
                      })}
                    </ListItem>
                  </Fragment>
                ))}
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  };

  const displayPrograms = () => {
    const programs = getRateProgramsAsArray();
    return programs.map(p => getProgramSection(p, programs.length === 4 ? 6 : 4));
  };

  return (
    <Grid className={classes.root} container spacing={4}>
      <Grid item xs={12}>
        <Title h1="Select your Program" h2="Choose what best fits your business." />
      </Grid>
      {errors && errors.programId && (
        <Grid item xs={12}>
          <Alert severity="error">{errors.programId.message}</Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControl className={classes.formControl} component="fieldset">
          <RadioGroup
            row
            aria-label="programId"
            name="programId"
            value={programId}
            onChange={handleProgramChange}
          >
            <Grid container spacing={4} alignItems="center" justifyContent="center">
              {displayPrograms()}
            </Grid>
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid className={classes.marginTop} item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          Select your {processingType === ProcessingTypes.eCommerce ? 'Gateway' : 'Equipment'}
        </Typography>
      </Grid>
      {errors && errors.equipmentId && (
        <Grid item xs={12}>
          <Alert severity="error">{errors.equipmentId.message}</Alert>
        </Grid>
      )}
      {equipment.map(e => {
        return (
          <Grid key={e.id} item xs={12} sm={6} md={4} lg={3} xl={2}>
            <div className={classes.equipment}>
              <Typography variant="body1" align="center" gutterBottom>
                {e.name}
              </Typography>
              {processingType !== ProcessingTypes.eCommerce &&
              !Boolean(e.isOther) &&
              (e.price || e.price === 0) ? (
                <Typography variant="h4" align="center" gutterBottom>
                  ${e.price?.toFixed(2) || ''}
                  <Typography variant="caption">/{e.duration}</Typography>
                </Typography>
              ) : (
                <Typography gutterBottom />
              )}
              {!e.imageUrl && (
                <div
                  className={`${classes.defaultLogo} ${classes.equipmentImage}`}
                  style={{backgroundImage: `url(${ComingSoonImage})`}}
                />
              )}
              {e.imageUrl && <img className={classes.equipmentImage} src={e.imageUrl} alt="logo" />}
              <Button
                variant="outlined"
                startIcon={equipmentId === e.id ? <CheckIcon /> : null}
                color={!equipmentId || equipmentId === e.id ? 'primary' : undefined}
                fullWidth
                onClick={
                  handleEquipmentSelect
                    ? handleEquipmentSelect(e.id, e.isGateway ?? false)
                    : () => console.log(`selected ${e.id}`)
                }
              >
                {equipmentId === e.id ? 'Selected' : 'Select'}
              </Button>
              {processingType !== ProcessingTypes.eCommerce &&
                (Boolean(prepareTextPreview(e.description)) || Boolean(e.features?.length)) && (
                  <Button
                    className={classes.learnMore}
                    size="small"
                    onClick={handleLearnMoreClick(e)}
                  >
                    Learn More
                  </Button>
                )}
            </div>
          </Grid>
        );
      })}
      <LearnMore
        anchorElement={anchorElement}
        description={selectedLearnMore?.description}
        features={selectedLearnMore?.features}
        onClose={handleClose}
      />
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  formControl: {
    width: '100%',
  },
  alignCenter: {
    textAlign: 'center',
  },
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(0),
    margin: 'auto',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  list: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 25,
    width: 400,
    margin: 'auto',
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('lg')]: {
      width: 'calc(90% - 32px)',
    },
  },
  selected: {
    boxShadow: theme.shadows[4],
  },
  programName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0, 1),
    },
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  equipment: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(4)})`,
    justifyContent: 'space-between',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 25,
    marginBottom: theme.spacing(2),
  },
  equipmentImage: {
    flexGrow: 1,
    borderRadius: 25,
    width: `100%`,
    height: 100,
    objectFit: 'contain',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xl')]: {
      height: 150,
    },
    [theme.breakpoints.down('lg')]: {
      height: 200,
    },
  },
  defaultLogo: {
    height: 96,
    width: 96,
    backgroundSize: 'cover',
  },
  learnMore: {
    marginTop: theme.spacing(1),
    fontWeight: 400,
  },
}));

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[2],
    fontSize: 14,
    textAlign: 'left',
  },
}))(Tooltip);
