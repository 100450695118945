import {useEffect, useState} from 'react';
import {TicketStatus} from '../../../..';
import {useUserInfo} from '../../../../hooks';
import {
  getAgentApplicationMidsAsync,
  getMerchantRegisteredMidsAsync,
} from '../../../../hooks/useMerchant';
import {useTicketsMyAssociationX} from './useTicketsMyAssociationX';

export function useTicketsMyAssociation(statusSet: TicketStatus[]) {
  const {uid, isMerchant, isAgentMember} = useUserInfo();
  const [merchantMids, setMerchantMids] = useState<string[]>([]);
  const {ticketsAssociated: ticketsMyAssociationMerchant} = useTicketsMyAssociationX(
    statusSet,
    merchantMids,
    'association.merchant.mid'
  );
  const {ticketsAssociated: ticketsMyAssociationApplication} = useTicketsMyAssociationX(
    statusSet,
    merchantMids,
    'association.application.mid'
  );

  useEffect(() => {
    if (!uid) return;
    isMerchant && getMerchantRegisteredMidsAsync(uid).then(setMerchantMids);
    isAgentMember && getAgentApplicationMidsAsync(uid).then(setMerchantMids);
  }, [uid]);

  return {
    ticketsMyAssociationMerchant,
    ticketsMyAssociationApplication,
  };
}
