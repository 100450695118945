/* eslint-disable react-hooks/exhaustive-deps */
import {Box, CircularProgress} from '@mui/material';
import {AsyncState, InfiniteSnapshotOptions, ViewTypes} from '@ozark/common';
import {Loading} from '@ozark/common/components';
import {useState} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {FixedSizeList as List} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

export const InfiniteDocuments = <TType extends ViewTypes>({
  documents,
  next,
  onDocumentRender,
  itemSize,
  isBatch = false,
}: {
  onDocumentRender: (document: TType) => JSX.Element;
  documents: AsyncState<{[_: string]: TType}> & {
    hasNextPage: boolean;
    size: number;
    refreshCount: number;
    lastDoc?: TType;
  };
  next: (options?: Partial<InfiniteSnapshotOptions>, onLoaded?: () => void) => void;
  itemSize?: number;
  isBatch?: boolean;
}) => {
  const [loading, setLoading] = useState(false);

  const getNextPage = () => {
    return new Promise<void>(resolve => {
      if (!documents.data) {
        return;
      }

      setLoading(true);

      // TG-1092 moved the tail calculation to hook
      const tail = documents.lastDoc;

      if (!tail) {
        setLoading(false);
        return;
      }

      const startAfter = {startAfter: isBatch ? tail.id : tail.ref};

      next(startAfter, () => {
        setLoading(false);
        resolve();
      });
    });
  };

  const itemCount = documents.hasNextPage ? documents.size + 1 : documents.size;

  const loadMoreItems = loading ? () => Promise.resolve() : getNextPage;

  const isItemLoaded = (index: number) => {
    return !documents.hasNextPage || index < documents.size;
  };

  if (documents.promised) return <Loading />;

  return (
    <Box
      sx={{
        flex: 1,
        scrollbarWidth: 'none',
        pt: 0.5,
        position: 'relative',
      }}
    >
      <Box
        sx={{
          height: '100%',
        }}
      >
        <AutoSizer>
          {({height, width}: any) => (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={itemCount}
              threshold={20}
              loadMoreItems={loadMoreItems}
            >
              {({onItemsRendered, ref}) => (
                <List
                  height={height}
                  itemCount={itemCount}
                  itemSize={itemSize ? itemSize : 120}
                  width={width}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                >
                  {
                    (({index, style}: any) => {
                      const apps = documents.data;
                      if (!apps || Object.keys(apps).length === 0) return;
                      const row = apps[Object.keys(apps)[index]];
                      return (
                        <div style={style}>
                          {isItemLoaded(index) ? (
                            onDocumentRender(row)
                          ) : (
                            <Box
                              sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          )}
                        </div>
                      );
                    }) as any
                  }
                </List>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </Box>
    </Box>
  );
};
