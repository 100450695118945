import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FmdBadTwoToneIcon from '@mui/icons-material/FmdBadTwoTone';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
  Zoom,
} from '@mui/material';
import {ErrorStateProps} from './ErrorBoundary';

export const ErrorState = ({error, onClose}: ErrorStateProps) => {
  return (
    <Zoom in>
      <Paper sx={{mt: 2, position: 'relative'}}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="large"
          sx={{
            position: 'absolute',
            top: theme => theme.spacing(2),
            right: theme => theme.spacing(2),
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            pt: 8,
            pb: 10,
            px: 3,
            textAlign: 'center',
          }}
        >
          <Box>
            <FmdBadTwoToneIcon color="error" sx={{fontSize: 130}} />
          </Box>
          <Typography variant="h3" sx={{fontWeight: 500, mb: 2}} color="error">
            Oops!
          </Typography>
          <Typography variant="subtitle1" sx={{mb: 3}}>
            Something went wrong. Please try again or reload the page.
          </Typography>
          <Box sx={{'& > button': {mx: 1}}}>
            <Button
              variant="contained"
              color="error"
              size="large"
              onClick={() => window.location.reload()}
            >
              Reload
            </Button>
            <Button variant="outlined" color="error" size="large" onClick={onClose}>
              Close
            </Button>
          </Box>

          {error && (
            <Accordion
              sx={{textAlign: 'left', mt: 4, width: '100%', maxWidth: 1000}}
              disableGutters
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <ErrorIcon color="error" sx={{mr: 1}} />
                <Typography>Error details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography color="error" sx={{fontSize: 18, fontWeight: 500}}>
                  {error.message}
                </Typography>
                <Typography color="error">{error.stack ?? error.toString()}</Typography>
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
      </Paper>
    </Zoom>
  );
};
