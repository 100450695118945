import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ResponsivePie} from '@nivo/pie';
import {AuthorizationDeclineSummary} from '../..';
import {AuthorizationResponseCodes} from '../../constants/authorizations';

type Props = {declineSummary: AuthorizationDeclineSummary[]};

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      height: 360,
    },
  })
);

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const formatDataForGraph = (declineSummary: AuthorizationDeclineSummary[]) => {
  const top5Declines = declineSummary
    .sort((a, b) => Number(b.declineAmount) - Number(a.declineAmount))
    .slice(0, 5);

  const top5DeclinesTotal = top5Declines.reduce(
    (accumulator, value) => accumulator + Number(value.declineAmount),
    0
  );

  const results = top5Declines.map(card => ({
    id: card.declineCode,
    label: AuthorizationResponseCodes[card.declineCode]
      ? AuthorizationResponseCodes[card.declineCode]
      : 'Other',
    value: Number(card.declineAmount),
  }));

  if (declineSummary.length > 5) {
    const allDeclinesTotal = declineSummary.reduce(
      (accumulator, value) => accumulator + Number(value.declineAmount),
      0
    );

    results.push({id: 'other', label: 'Other', value: allDeclinesTotal - top5DeclinesTotal});
  }

  return results;
};

export const AuthorizationSummaryByCardTypePieChart = ({declineSummary}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ResponsivePie
        data={formatDataForGraph(declineSummary)}
        innerRadius={0.6}
        margin={{top: 20, right: 60, bottom: 80, left: 60}}
        valueFormat={value => `${formatter.format(Number(value))}`}
        borderWidth={1}
        borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}
        arcLinkLabel={'label'}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{from: 'color'}}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{from: 'color', modifiers: [['darker', 2]]}}
        colors={{scheme: 'category10'}}
        padAngle={1.5}
        isInteractive={false}
        legends={[
          {
            anchor: 'right',
            direction: 'column',
            justify: false,
            translateX: 0,
            translateY: 0,
            itemsSpacing: 20,
            itemWidth: 170,
            itemHeight: 18,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 14,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000',
                },
              },
            ],
          },
        ]}
      ></ResponsivePie>
    </div>
  );
};
