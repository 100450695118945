import {Dispositions} from '@ozark/common';
import {DisputeReason} from '../../ApplicationDisputeHandlers';
//import {conditionallyApproved} from './conditionallyApproved';
import {declined} from './declined';
import {incomplete} from './incomplete';
import {pending} from './pending';
import {withdrawn} from './withdrawn';

const reasons = {
  // ...conditionallyApproved,
  ...declined,
  ...incomplete,
  ...pending,
  ...withdrawn,
} as {[key in Dispositions]?: DisputeReason[]};

export default reasons;
