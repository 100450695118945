import {Box, Divider, Typography} from '@mui/material';
import {ReactNode} from 'react';
import {MonthYearPicker, Title} from '../..';

type Props = {
  breadcrumbs: ReactNode[];
  children?: ReactNode;
};

export const ReportingMonthTitle = ({breadcrumbs, children}: Props) => {
  return (
    <Title breadcrumbs={breadcrumbs}>
      <Box flex={1} />
      <Typography>Reporting month:</Typography>
      &emsp;
      <MonthYearPicker />
      {children && <Divider orientation="vertical" flexItem variant="middle" light sx={{mx: 2}} />}
      {children}
    </Title>
  );
};
