import {Box, Paper, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Column} from '@ozark/common/api/Column';
import {Loading, Table, Title} from '@ozark/common/components';
import {parse} from 'date-fns';
import {format} from 'date-fns-tz';
import {isEmpty} from 'lodash';
import {useEffect, useState} from 'react';
import {useStore} from '../../store/helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    grow: {
      flex: 1,
    },
    noContent: {
      top: '40%',
      position: 'relative',
      textAlign: 'center',
    },
    paper: {
      padding: theme.spacing(2),
    },
    tableContainer: {
      height: '65vh',
      width: '100%',
    },
    pending: {
      fill: '#E68300',
    },
  })
);

const formatCurrency = (value?: number) => {
  if (!value) return '';

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(value);
};

type ResidualInfo = {
  id: string;
  count: string;
  yearMonth: string;
  grossSales: string;
  expense: string;
  transactionCount: string;
  bankcardSalesVolume: string;
  totalIncome: string;
  groupExpense: string;
  groupNetSplit: string;
  agentNetSplit: string;
  groupProfit: string;
};

const ResidualsOverview = () => {
  const classes = useStyles();
  const {apiClient, authProfile} = useStore();
  const [selected, setSelected] = useState<ResidualInfo>();
  const [residuals, setResiduals] = useState<ResidualInfo[]>();

  useEffect(() => {
    apiClient.residuals.getGroupResidualOverview().then((result: any) => {
      setResiduals(
        result?.map((e: any, i: number) => {
          return {...e, id: i};
        }) || {}
      );
    });
  }, [apiClient.residuals]);

  const handleRowSelect = (row: ResidualInfo) => {
    setSelected(row);
  };

  const formatYearMonth = (yearMonth: string) => {
    const date = parse(yearMonth as string, 'yyyyMM', new Date());
    return format(date, 'MMMM, yyyy');
  };

  const getBreadcrumbs = () => {
    const breadcrumbs = [<Typography variant="body1">Residuals Overview</Typography>];
    return breadcrumbs;
  };

  if (authProfile.promised) return <Loading />;

  if (!residuals) {
    return (
      <Box mt={6}>
        <Typography variant="body1" align="center">
          No residuals have been added.
        </Typography>
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <Title breadcrumbs={getBreadcrumbs()}>
        <div className={classes.grow} />
      </Title>

      {residuals && isEmpty(residuals) && (
        <Typography className={classes.noContent}>No Residuals</Typography>
      )}
      {residuals && !isEmpty(residuals) && (
        <Paper className={classes.paper}>
          <div className={classes.tableContainer}>
            <Table
              onRowClick={handleRowSelect}
              selectedId={selected?.id}
              rows={residuals}
              columns={
                [
                  {
                    id: 'yearMonth',
                    label: 'File Date',
                    width: 200,
                    selector: row => formatYearMonth(row.yearMonth),
                  },
                  {
                    id: 'count',
                    label: '# of Accounts',
                    width: 150,
                    numeric: true,
                  },
                  {
                    id: 'bankcardSalesVolume',
                    label: 'Sales Volume',
                    width: 150,
                    numeric: true,
                    selector: row => formatCurrency(Number(row.bankcardSalesVolume)),
                  },
                  {
                    id: 'totalIncome',
                    label: 'Merchant Income',
                    width: 150,
                    numeric: true,
                    selector: row => formatCurrency(Number(row.totalIncome)),
                  },
                  {
                    id: 'groupExpense',
                    label: 'Group Expense',
                    width: 150,
                    numeric: true,
                    selector: row => formatCurrency(Number(row.groupExpense)),
                  },
                  {
                    id: 'groupNetSplit',
                    label: 'Group Net',
                    width: 150,
                    numeric: true,
                    selector: row => formatCurrency(Number(row.groupNetSplit)),
                  },
                  {
                    id: 'agentNetSplit',
                    label: 'Agent Payout',
                    width: 150,
                    numeric: true,
                    selector: row => formatCurrency(Number(row.agentNetSplit)),
                  },
                  {
                    id: 'groupProfit',
                    label: 'Group Profit',
                    width: 150,
                    numeric: true,
                    selector: row => formatCurrency(Number(row.groupProfit)),
                  },
                ] as Column<ResidualInfo>[]
              }
            />
          </div>
        </Paper>
      )}
    </div>
  );
};

export default ResidualsOverview;
