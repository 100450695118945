import {yupResolver} from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import {TextField} from '@ozark/common/components';
import {useNotification} from '@ozark/common/hooks';
import {useCallback, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useHistory} from 'react-router';
import * as yup from 'yup';
import {useCallable} from '../../hooks';
import {Copyright} from '../Copyright/Copyright';

const schema = yup.object().shape({
  email: yup.string().trim().email().required('Email is required'),
});

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    margin: theme.spacing(2),
    width: 300,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

type FormData = {email: string};

interface ForgotPasswordProps {
  groupName?: string;
  groupDomain?: string;
  groupLogoUrl?: string;
  isErp?: boolean;
  loginPageUrl: string;
}

const ForgotPassword = ({
  groupName,
  groupDomain,
  groupLogoUrl,
  isErp,
  loginPageUrl,
}: ForgotPasswordProps) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const {resetPassword} = useCallable();
  const showNotification = useNotification();

  const classes = useStyles();

  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  const onResetLinkSend: SubmitHandler<FormData> = useCallback(
    async data => {
      try {
        setLoading(true);

        await resetPassword({email: data.email, isErp});

        showNotification('success', `Reset password email successfully sent`);

        history.push(loginPageUrl);
      } catch (err) {
        console.error(err);

        showNotification('error', 'Failed to reset password');

        setError('There was an error resetting your password.');
      } finally {
        setLoading(false);
      }
    },
    [loginPageUrl, resetPassword, showNotification]
  );

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />

      <Paper className={classes.paper}>
        {groupLogoUrl && <img alt="Sign in" className={classes.logo} src={groupLogoUrl} />}

        <form className={classes.form} noValidate onSubmit={handleSubmit(onResetLinkSend)}>
          <TextField
            fullWidth
            autoComplete="email"
            control={control}
            error={Boolean(error) || Boolean(errors.email)}
            errors={errors}
            helperText={errors.email?.message}
            label="Email"
            margin="normal"
            name="email"
            variant="outlined"
          />

          <LoadingButton
            fullWidth
            className={classes.submit}
            color="primary"
            data-test="submit"
            loading={loading}
            type="submit"
            variant="contained"
          >
            Reset Password
          </LoadingButton>

          <Grid container>
            <Grid item xs>
              <Link href={loginPageUrl} variant="body2">
                Go to sign in.
              </Link>
            </Grid>
            <Grid item></Grid>
          </Grid>
        </form>
      </Paper>

      <Box mt={8}>
        {!!groupName && !!groupDomain && (
          <Copyright groupName={groupName} groupDomain={groupDomain} />
        )}
      </Box>
    </Container>
  );
};

export {ForgotPassword};
