import {useState} from 'react';
import {createContainer} from 'unstated-next';

function useLoading() {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    setIsLoading,
  };
}

export const LoadingContainer = createContainer(useLoading);
export const useLoadingContainer = LoadingContainer.useContainer;
