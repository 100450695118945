import GroupIcon from '@mui/icons-material/Group';
import {Box} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import {UniversalTimestamp, useUserInfo} from '../../..';
import {AvatarFromName} from '../common/AvatarFromName';
import {useTicketHistory} from '../hooks/useTicketHistory';
import {useTicketHistoryDisplayName} from '../hooks/useTicketHistoryDisplayName';

export function TicketEditHistory() {
  const {isErpUser} = useUserInfo();
  const {records} = useTicketHistory();
  const {getTicketHistoryDisplayName} = useTicketHistoryDisplayName();
  return (
    <Box height="100%">
      <List>
        {records.map((rec, i) => (
          <ListItem key={i} disableGutters dense>
            <ListItemAvatar>
              {isErpUser && <AvatarFromName name={rec.userName} />}
              {!isErpUser && <GroupIcon />}
            </ListItemAvatar>
            <ListItemText
              primary={
                <Box>
                  <Box sx={{fontWeight: 'medium'}}>{getTicketHistoryDisplayName(rec)}</Box>
                  <Box>{isErpUser ? rec.title : rec.titlePortal ?? rec.title}</Box>
                </Box>
              }
              secondary={(rec.createdAt as UniversalTimestamp)?.toDate().toLocaleString()}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
