import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

type Props = {
  label: string;
  options: string[];
  value?: string;
  valueDefault: string;
  disabled?: boolean;
  handleChange: <T extends string>(val?: T) => void;
};

export function TicketsListFilter({
  label,
  options,
  value,
  valueDefault,
  disabled,
  handleChange,
}: Props) {
  const handleSelectChange = (event: SelectChangeEvent) => {
    const val = event.target.value;
    handleChange(val && val !== valueDefault ? val : undefined);
  };

  return (
    <Box ml={2} display="flex" alignItems="flex-end">
      <FormControl
        disabled={disabled}
        variant="standard"
        sx={{
          minWidth: 200,
          '& .MuiInput-root:hover:before': {border: 'none'},
          '& .MuiInput-root:before': {border: 'none'},
          '& .Mui-disabled:before': {border: 'none', borderBottomStyle: 'none !important'},
        }}
      >
        <InputLabel id="select-standard-label">{label}</InputLabel>
        <Select
          labelId="select-standard-label"
          id="select-assignee"
          value={value ?? valueDefault}
          onChange={handleSelectChange}
          label={label}
        >
          <MenuItem value={valueDefault}>
            <em style={{color: 'grey'}}>{valueDefault}</em>
          </MenuItem>
          {options.map(o => (
            <MenuItem key={o} value={o}>
              {o}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {value && (
        <IconButton
          size="small"
          sx={{alignSelf: 'flex-end', marginBottom: '-1px'}}
          onClick={() => handleChange(undefined)}
        >
          <ClearIcon />
        </IconButton>
      )}
    </Box>
  );
}
