import {DocumentBase, ViewBase} from '.';
import {Overwrite} from '../helpers';
import {UniversalTimestamp} from './compat';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export type DisputeAttachmentAuthor = {
  uid: string;
  name: string;
};

export enum NoResponseStatus {
  NoResponse = 'no_responses',
}

export type DisputeCaseStatus = DisputeResponseStatus | NoResponseStatus;

export type Dispute = DocumentBase & {
  mid: string;
  createdAt: UniversalTimestamp;
  updatedAt?: UniversalTimestamp;
  responseStatus?: DisputeCaseStatus;
};

export enum DisputeAttachmentStatus {
  Init = 'init',
  Created = 'created',
  Uploaded = 'uploaded',
}

export type DisputeAttachment = DocumentBase & {
  fileDate: UniversalTimestamp;
  name?: string;
  caseNumber: string;
  label?: string;
  fileNameKey?: string; // unique field for parsed files
  cloudPathView: string;
  cloudPathDownload: string;
  createdAt: UniversalTimestamp;
  status?: DisputeAttachmentStatus; // created for generated files
  author?: DisputeAttachmentAuthor; // for files uploaded by user
  deleted?: boolean;
  mid: string;
};

export type DisputeAttachmentView = ViewBase<DisputeAttachment>;

export type DisputeAttachmentInput = Overwrite<DisputeAttachment, {createdAt: Date}>;

export const selectDisputeAttachmentView: ViewSelector<
  DisputeAttachmentView,
  DisputeAttachment
> = snapshot => {
  const disputeAttachment: DisputeAttachmentView = {
    ...defaultViewSelector(snapshot),
  };
  return disputeAttachment;
};

export enum DisputeResponseStatus {
  InProgress = 'in_progress',
  DocumentsUploaded = 'documents_uploaded',
  ResponseFileCreated = 'response_file_created',
  RebuttalFileCreated = 'rebuttal_file_created',
  FilesConverted = 'files_converted',
  MultipageResponseCreated = 'multipage_response_created',
}

export const getDisputeCaseStatusOrder = (status: DisputeResponseStatus | NoResponseStatus) => {
  if (status === DisputeResponseStatus.InProgress) {
    return 1;
  } else if (status === DisputeResponseStatus.DocumentsUploaded) {
    return 2;
  } else if (status === DisputeResponseStatus.ResponseFileCreated) {
    return 3;
  } else if (status === DisputeResponseStatus.RebuttalFileCreated) {
    return 4;
  } else if (status === DisputeResponseStatus.FilesConverted) {
    return 5;
  } else if (status === DisputeResponseStatus.MultipageResponseCreated) {
    return 6;
  }

  // NoResponseStatus
  return 0;
};

export type DisputeResponse = DocumentBase & {
  mid: string;
  caseNumber: string;
  author: DisputeAttachmentAuthor;
  answer: boolean;
  fileName: string;
  rebuttal?: string;
  cloudPath?: string;
  signature?: {
    downloadUrl: string;
    fullPath: string;
  };
  status: DisputeResponseStatus;
  createdAt: UniversalTimestamp;
  updatedAt?: UniversalTimestamp;
  deleted: boolean;
  success?: boolean;
};

export type DisputeResponseAttachment = DocumentBase & {
  fileDate: UniversalTimestamp;
  name?: string;
  label?: string;
  cloudPathView: string;
  cloudPathDownload: string;
  createdAt: UniversalTimestamp;
  processed?: boolean;
};

export type DisputeResponseAttachmentView = ViewBase<DisputeResponseAttachment>;

export type DisputeResponseAttachmentInput = Overwrite<
  DisputeResponseAttachment,
  {createdAt: Date}
>;
