import {Collections, Firebase, Group, useNotification} from '@ozark/common';
import {useCallback} from 'react';
import {useCallable} from './useCallable';

export const useApplicationResume = () => {
  const showNotification = useNotification();
  const {getPortalToken} = useCallable();

  const resumeApplication = useCallback(
    async (applicationId: string, groupId: string) => {
      try {
        const result = await getPortalToken({applicationId, resume: true});
        if (result.status === 'error') {
          throw new Error(result.message);
        }

        const groupSnapshot = await Firebase.firestore
          .collection(Collections.groups)
          .doc(groupId)
          .get();

        if (!groupSnapshot.exists) {
          throw new Error(`Group with id ${groupId} is not found`);
        }

        const group = groupSnapshot.data() as Group;

        const a = document.createElement('a');
        a.setAttribute(
          'href',
          `${window.location.protocol}//${group.appDomain}/portal?token=${result.token}`
        );
        a.setAttribute('target', '_blank');
        a.click();
      } catch (err) {
        console.error('Failed to get portal token or application group with an error:', err);
        showNotification('error', 'Failed to resume application');
      }
    },
    [showNotification, getPortalToken]
  );

  return {resumeApplication};
};
