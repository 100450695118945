import {ApplicationView, ProcessingTypes} from '@ozark/common';
import React from 'react';

export const BusinessInfoWebSiteItemName: string = `Website`;
export const getWebsiteItemForBusinessInfoSummaryColumns = (
  application: ApplicationView
): React.ReactNode => {
  if (application.processingType === ProcessingTypes.cardPresent) {
    return undefined;
  }
  if (!application.website) {
    return '(No Website Provided)';
  }
  let url = application.website;
  if (!url.startsWith('http')) {
    url = `https://${url}`;
  }
  return (
    <a href={`${url}`} target="_blank" rel="noreferrer">
      {application.website}
    </a>
  );
};
