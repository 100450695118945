import {SortableContext} from '@dnd-kit/sortable';
import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@mui/material';
import {ResourceDocumentCategoryView, ResourceView} from '@ozark/common';
import {styles} from './constants';
import {useDisplayContext} from './DisplayContext';
import {DisplaySortableResourceAsRow} from './DisplaySortableResourceAsRow';

interface Props {
  documents: ResourceView[];
  categoryView: ResourceDocumentCategoryView;
}
export const DisplayDocumentsAsTable = ({documents, categoryView}: Props) => {
  const {allowEdit, isDragHandleAllowed} = useDisplayContext();

  if (documents.length === 0) {
    return <Typography sx={styles.noContent}>No Documents</Typography>;
  }
  return (
    <Table sx={styles.table} aria-label="resources table">
      <TableHead>
        <TableRow>
          <TableCell sx={styles.tableTitleHeader}>Title</TableCell>
          <TableCell sx={styles.tableDescriptionHeader}>Description</TableCell>
          {allowEdit && <TableCell sx={styles.tableViewableByHeader}>Viewable By</TableCell>}
          <TableCell sx={styles.tableSizeHeader}>Size</TableCell>
          <TableCell>Type</TableCell>
          <TableCell sx={styles.tableDateHeader}>Date Created</TableCell>
          {allowEdit && <TableCell></TableCell>}
          {allowEdit && <TableCell></TableCell>}
          {isDragHandleAllowed && allowEdit && <TableCell></TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        <SortableContext items={documents} id={categoryView.id}>
          {documents.map((r: ResourceView) => (
            <DisplaySortableResourceAsRow resourceView={r} key={r.id} />
          ))}
        </SortableContext>
      </TableBody>
    </Table>
  );
};
