import {AllMIDs, AsyncState, Collections, Firebase, Mid, MidUserAssociation} from '@ozark/common';
import {reduce, sortBy} from '@s-libs/micro-dash';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useUserInfo} from './useUserInfo';

export function useMids() {
  const {uid, isPortal, isAgentAdmin, userGroupId: groupId} = useUserInfo();
  const [mids, setMids] = useState<AsyncState<Map<string, string>>>({
    promised: true,
  });

  const [selectedMid, setSelectedMid] = useState(AllMIDs);

  useEffect(() => {
    if (!uid) return;

    if (isPortal) {
      // PORTAL
      return isAgentAdmin && groupId
        ? getMidsForGroup(groupId, setMids)
        : getMidsForUser(uid, setMids);
    }

    // ERP
    return getMidsAll(setMids);
  }, [uid, isPortal, isAgentAdmin, groupId]);

  useEffect(() => {
    if (mids?.data && mids.data.size === 1) {
      const [firstMid] = mids.data.values();
      setSelectedMid(firstMid);
    }
  }, [mids?.data]);

  const forceSelectMidIfNeeded = useCallback(() => {
    if (selectedMid === AllMIDs && mids.data) {
      for (const mid of mids.data.keys()) {
        if (mid === AllMIDs) continue;
        setSelectedMid(mid);
        return;
      }
    }
  }, [selectedMid, mids]);

  const midsOptions = useMemo(
    () =>
      Array.from((mids.data ?? new Map()).keys())
        .filter(mid => mid !== AllMIDs)
        .map(mid => ({mid, doingBusinessAs: mids.data?.get(mid)})),
    [mids.data]
  );

  const selectedMidOption = useMemo(
    () => ({mid: selectedMid, doingBusinessAs: mids.data?.get(selectedMid)}),
    [selectedMid, mids.data]
  );

  return {
    mids,
    midsOptions,
    selectedMid,
    selectedMidOption,
    handleSelectMid: setSelectedMid,
    forceSelectMidIfNeeded,
  };
}

type MidToFormat = {
  mid: string;
  dba: string;
  agentName?: string;
};

export const sortAndFormatMids = (
  mids: MidToFormat[],
  withoutAllItem?: boolean
): Map<string, string> =>
  reduce(
    sortBy(mids, e => (e.dba || '').toLowerCase()),
    (accumulator, {agentName, dba, mid}) => {
      let value = `${dba} ${mid}`;

      if (agentName) {
        value = `${value} — ${agentName}`;
      }

      accumulator.set(mid, value);

      return accumulator;
    },
    !withoutAllItem ? new Map().set('all', 'All MIDs') : new Map()
  );

/** erp option */
const getMidsAll = (setMids: (mids: AsyncState<Map<string, string>>) => void) => {
  return Firebase.firestore.collection(Collections.mids).onSnapshot(
    snapshot => {
      if (snapshot.empty) {
        setMids({promised: false, data: new Map()});
        return;
      }

      const mids = snapshot.docs.map(doc => ({...doc.data(), mid: doc.id} as Mid));
      const sortedAndFormatedMids = sortAndFormatMids(mids);

      setMids({
        promised: false,
        data: sortedAndFormatedMids,
      });
    },
    err => {
      console.error(err);
      setMids({promised: false, error: err});
    }
  );
};

export const getMidsForGroup = (
  groupId: string,
  setMids: (mids: AsyncState<Map<string, string>>) => void,
  availableMids?: string[],
  withoutAllItem?: boolean
) => {
  return Firebase.firestore
    .collection(Collections.mids)
    .where('groupId', '==', groupId)
    .onSnapshot(
      snapshot => {
        if (snapshot.empty) {
          setMids({promised: false, data: new Map()});
        } else {
          const mids = snapshot.docs.map(doc => ({...doc.data(), mid: doc.id} as Mid));
          const filteredMids =
            availableMids !== undefined ? mids.filter(x => availableMids.includes(x.mid)) : mids;

          const sortedAndFormatedMids = sortAndFormatMids(filteredMids, withoutAllItem ?? false);

          setMids({
            promised: false,
            data: sortedAndFormatedMids,
          });
        }
      },
      err => {
        console.error(err);
        setMids({promised: false, error: err});
      }
    );
};

const getMidsForUser = (
  /** authUser.data.uid */
  userId: string,
  setMids: (mids: AsyncState<Map<string, string>>) => void
) => {
  return Firebase.firestore
    .collection(Collections.midUser)
    .where('userId', '==', userId)
    .onSnapshot(
      snapshot => {
        if (snapshot.empty) {
          setMids({promised: false, data: new Map()});
          return;
        }
        const mids = snapshot.docs.map(doc => doc.data() as MidUserAssociation);

        const sortedAndFormatedMids = sortAndFormatMids(mids);

        setMids({
          promised: false,
          data: sortedAndFormatedMids,
        });
      },
      err => {
        console.error(err);
        setMids({promised: false, error: err});
      }
    );
};
