export enum ReservePercentOption {
  zero = 'zero',
  five = 'five',
  ten = 'ten',
  fifteen = 'fifteen',
  fiveCapped = 'fiveCapped',
  tenCapped = 'tenCapped',
  fifteenCapped = 'fifteenCapped',
  fiveUpfront = 'fiveUpfront',
  tenUpfront = 'tenUpfront',
  fifteenUpfront = 'fifteenUpfront',
}

export const ReservePercentCappedOptions = [
  ReservePercentOption.fiveCapped,
  ReservePercentOption.tenCapped,
  ReservePercentOption.fifteenCapped,
];

export const ReservePercentUpfrontOptions = [
  ReservePercentOption.fiveUpfront,
  ReservePercentOption.tenUpfront,
  ReservePercentOption.fifteenUpfront,
];

export const ReservePercentDisplayOptions: {
  reservePercentOption: ReservePercentOption;
  text: string;
}[] = [
  {
    reservePercentOption: ReservePercentOption.zero,
    text: '0%',
  },
  {
    reservePercentOption: ReservePercentOption.five,
    text: '5%',
  },
  {
    reservePercentOption: ReservePercentOption.ten,
    text: '10%',
  },
  {
    reservePercentOption: ReservePercentOption.fifteen,
    text: '15%',
  },
  {
    reservePercentOption: ReservePercentOption.fiveCapped,
    text: '5% Capped',
  },
  {
    reservePercentOption: ReservePercentOption.tenCapped,
    text: '10% Capped',
  },
  {
    reservePercentOption: ReservePercentOption.fifteenCapped,
    text: '15% Capped',
  },
  {
    reservePercentOption: ReservePercentOption.fiveUpfront,
    text: '5% with Upfront',
  },
  {
    reservePercentOption: ReservePercentOption.tenUpfront,
    text: '10% with Upfront',
  },
  {
    reservePercentOption: ReservePercentOption.fifteenUpfront,
    text: '15% with Upfront',
  },
];
