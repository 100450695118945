import makeStyles from '@mui/styles/makeStyles';

const useEditorWrapperStyles = makeStyles({
  root: {
    width: '100%',
    paddingLeft: 0,
    marginTop: '16px',
    '& button[title="Align Left"]': {
      display: 'none',
    },
    '& button[title="Align Center"]': {
      display: 'none',
    },
    '& button[title="Align Right"]': {
      display: 'none',
    },
    '& button[title="Align Justify"]': {
      display: 'none',
    },
    '& button[title="Link"]': {
      display: 'none',
    },
    '& button[title="Remove Link"]': {
      display: 'none',
    },
    '& button[title="Image"]': {
      display: 'none',
    },
    '& .public-DraftEditor-content': {
      minHeight: '200px',
    },
  },
  reply: {
    width: '95%',
    paddingLeft: '72px',
    '& .public-DraftEditor-content': {
      minHeight: '100px',
    },
  },
});

export {useEditorWrapperStyles};
