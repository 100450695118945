import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary, Box} from '@mui/material';

type Props = {
  id?: string;
  title: JSX.Element | string;
  children: JSX.Element;
  defaultExpanded?: boolean;
};

export const AccordionSection = ({title, children, defaultExpanded, id}: Props): JSX.Element => {
  return (
    <Box
      sx={{
        paddingLeft: 2,
        paddingTop: 1.5,
        paddingBottom: 1.5,
        flexBasis: '100%',
      }}
    >
      <Accordion disableGutters defaultExpanded={defaultExpanded} id={id}>
        <AccordionSummary
          sx={{
            color: theme => theme.palette.secondary.main,
            fontWeight: 500,
            fontSize: '1rem',
            backgroundColor: '#f5fafc',
            '& > *': {
              margin: 2,
            },
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          {title}
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Box>
  );
};
