export enum AccountStatus {
  active = 'Active',
  activeFirstBatchReview = 'Active - First Batch Review',
  activePending = 'Active - Pending',
  approved = 'Approved',
  closed = 'Closed',
  inactive = 'Inactive',
  terminated = 'Terminated',
}

export const ACTIVE_ACCOUNT_STATUSES: AccountStatus[] = [
  AccountStatus.active,
  AccountStatus.activePending,
  AccountStatus.activeFirstBatchReview,
  AccountStatus.approved,
];

export const INACTIVE_ACCOUNT_STATUSES: AccountStatus[] = [
  AccountStatus.closed,
  AccountStatus.inactive,
  AccountStatus.terminated,
];

export enum TerminatedSubCategories {
  excessiveChargebacks = 'Excessive Chargebacks',
  confirmedFraud = 'Confirmed Fraud',
  identityTheft = 'Identity Theft',
  excessiveFraud = 'Excessive Fraud',
  achRejects = 'ACH Rejects',
  bramViolations = 'BRAM Violation',
  retroMatch = 'Retro MATCH',
  transactionLaundering = 'Transaction Laundering',
  businessFraud = 'Business Fraud',
}

export const ACCOUNT_STATUS_OPTIONS: {value: AccountStatus; subCategories?: string[]}[] = [
  {
    value: AccountStatus.inactive,
  },
  {
    value: AccountStatus.active,
  },
  {
    value: AccountStatus.activeFirstBatchReview,
  },
  {
    value: AccountStatus.activePending,
  },
  {
    value: AccountStatus.terminated,
    subCategories: Object.values(TerminatedSubCategories),
  },
  {
    value: AccountStatus.closed,
  },
];

export const RISK_STATUS_OPTIONS = [
  'Risk Review - Invoice Request - Transaction Verification',
  'Risk Review - Invoice Request - High Ticket Breach',
  'Risk Review - Website/URL Issue',
  'Risk Review - Document Request',
  'Risk Review - Inconsistent Processing',
  'Risk Review - Duplicate Transaction',
  'Risk Review - Unmatched Refund - Original Sale Invoice Request',
  'Risk Review - Volume Increase',
  'Risk Review - Chargeback Ratio',
  'Risk Review - Potential BRAM Issue',
  'Risk Review - Potential ID Fraud',
  'Risk Review - Potential Business Fraud',
  'Risk Review - Excessive Declines',
  'Risk Review - Excessive Keyed Transactions',
  'Risk Review - Excessive Foreign Transactions',
  'Risk Review - Excessive Forced Transactions',
  'Risk Review - Sequential BIN',
  'Risk Review - VDMP (Visa)',
  'Risk Review - ECM (MasterCard)',
  'Duplicate Transaction Notification',
  'Unmatched Refund Notification',
  'Sequential BIN Notification',
  'Excessive Keyed Transaction Notification',
  'Merchant High Ticket Increase Review - Merchant Request',
  'Reserve Increase',
  'Volume Increase',
  'Volume Decrease',
  'Chargeback Notification - Risk Analyst notification to Merchant/Agent',
  'Chargeback Inquiry - Merchant/Agent Request Documentation',
  'MATCH Inquiry',
  'MRA Reject 288 - Auth Date Greater Than/Equal to 30 Days',
  'MRA Reject 289 - Force Post Reject',
  'MRA Reject-1-INVALID ACCOUNT LENGTH',
  'MRA Reject-2-ACCOUNT NUMBER NOT NUMERIC',
  'MRA Reject-003-INVALID ACCOUNT NUMBER',
  'MRA Reject-004-MERCHANT DOES NOT ACCEPT PLAN',
  'MRA Reject-5-INVALID CHECK DIGIT ON ACCT NUMBER',
  'MRA Reject-6-INVALID ACCOUNT RANGE WITHIN TYPE',
  'MRA Reject-7-PAYMENT TRANSACTION ON DEBIT CARD',
  'MRA Reject-8-INVALID ACCOUNT FOR TRANSACTION CODE',
  'MRA Reject-9-TRANSACTION AMOUNT NOT NUMERIC',
  'MRA Reject-10-TRANSACTION AMOUNT EQUAL ZERO',
  'MRA Reject-11-INVALID MERCHANT NUMBER',
  'MRA Reject-12-PURCHASE DATE NOT NUMERIC',
  'MRA Reject-13-INVALID PURCHASE DATE',
  'MRA Reject-14-PURCHASE DATE EXCEEDS PROCESS DATE',
  'MRA Reject-15-PURCHASE DATE EXCEEDS AGE LIMIT',
  'MRA Reject-16-INVALID TRANSACTION CODE',
  'MRA Reject-17-MERCHANT NUMBER NOT NUMERIC',
  'MRA Reject-18-MERCHANT NOT ON FILE',
  'MRA Reject-19-INVALID CHECK DIGIT ON MERCHANT ACCT',
  'MRA Reject-20-MERCHANT CODED AS CLOSED',
  'MRA Reject-21-MERCHANT CODED AS FRAUD',
  'MRA Reject-22-MERCHANT CODED AS DO NOT POST',
  'MRA Reject-23-MERCHANT CODED AS DO NOT AUTHORIZE',
  'MRA Reject-24-MERCHANT INFORMATION MISSING',
  'MRA Reject-25-MERCHANT DBA NAME MISSING',
  'MRA Reject-26-MERCHANT DBA CITY MISSING',
  'MRA Reject-27-MERCHANT DBA STATE MISSING',
  'MRA Reject-28-MERCHANT RECORD NOT FOUND',
  'MRA Reject-29-INVALID CLASS/SIC CODE',
  'MRA Reject-30-MERCHANT REFERENCE NUMBER NT NUMERIC',
  'MRA Reject-31-MERCHANT DISCOUNT AMOUNT NOT NUMERIC',
  'MRA Reject-32-MERCHANT DISCOUNT AMT EXCEEDS LIMIT',
  'MRA Reject-33-MERCHANT NET DEPOSIT NOT NUMERIC',
  'MRA Reject-34-DEPARTMENT CODE NOT IN INT. TABLES',
  'MRA Reject-35-DEPARTMENT CODE NOT NUMERIC',
  'MRA Reject-36-PAYMENT PRESENTED ON OUTGOING ACCT',
  'MRA Reject-37-REQUEST ON INVALID ACCOUNT',
  'MRA Reject-38-REQUEST ON ON-US TRANSACTION',
  'MRA Reject-39-INVALID REFERENCE NUMBER',
  'MRA Reject-40-INVALID REASON CODE',
  'MRA Reject-41-CONFIRMATION ON ON-US ACCOUNT',
  'MRA Reject-42-CONFIRMATION ON OUTGOING TRANSACTION',
  'MRA Reject-43-REFERENCE NUMBER NOT NUMERIC',
  'MRA Reject-44-INVALID CHECK DIGIT ON REF NUMBER',
  'MRA Reject-45-INVALID STATEMENT DATE',
  'MRA Reject-46-STATEMENT DATE NOT NUMERIC',
  'MRA Reject-47-CONTROL NUMBER NOT NUMERIC',
  'MRA Reject-48-REFERENCE NUMBER HAS IMBEDED BLANKS',
  'MRA Reject-49-OUTGOING TRANSACTION IS NOT VALID',
  'MRA Reject-50-ON-US TRANSACTION IS NOT VALID',
  'MRA Reject-51-CLASS/SIC CODE INVALID FOR CASH',
  'MRA Reject-52-CLASS/SIC CODE VALID ONLY FOR CASH',
  'MRA Reject-53-INVALID ATTRIBUTE CODE',
  'MRA Reject-54-INVALID DATE IN REFERENCE NUMBER',
  'MRA Reject-55-INVALID USAGE CODE',
  'MRA Reject-56-INVALID ISSUING ICA NUMBER',
  'MRA Reject-57-INVALID ACQUIRING ICA NUMBER',
  'MRA Reject-58-BANK DOES NOT ACCEPT PLAN',
  'MRA Reject-59-TRANSACTION CODE INVALID FOR BATCH TYPE',
  'MRA Reject-60-INVALID MERCHANT PLAN',
  'MRA Reject-61-INVALID OUTCLEAR REFERENCE NUMBER',
  'MRA Reject-62-INVALID MERCHANT ZIP CODE',
  'MRA Reject-63-INVALID AUTHORIZATION CODE',
  'MRA Reject-64-TMP INFORMATION INVALID',
  'MRA Reject-65-NO ACCOUNT FOUND',
  'MRA Reject-66-TRANSFERED ACCOUNT',
  'MRA Reject-67-USER OPTION',
  'MRA Reject-68-UNMATCHED REVERSAL',
  'MRA Reject-69-CLOSED ACCOUNT',
  'MRA Reject-70-COMB. DESC. INVALID FOR OUTGOING TRANS.',
  'MRA Reject-71-UNABLE TO CONVERT CURRENCY',
  'MRA Reject-72-NOT CURRENTLY USED',
  'MRA Reject-73-INVALID PROVINCE CODE',
  'MRA Reject-74-INVALID MERCHANT COUNTRY CODE',
  'MRA Reject-75-INVALID SERVICE LEVEL',
  'MRA Reject-76-INVALID AUTHORIZATION SOURCE',
  'MRA Reject-77-INVALID POS TERMINAL CAPABILITY',
  'MRA Reject-78-INVALID TERMINAL ENTRY MODE',
  'MRA Reject-79-INVALID CARDHOLDER ID METHOD',
  'MRA Reject-80-INVALID ORIGIN CITY/AIRPORT CODE',
  'MRA Reject-81-INVALID CARRIER CODE (AIRLINE ITINERARY)',
  'MRA Reject-82-INVALID SERVICE CLASS (AIRLINE ITINERARY)',
  'MRA Reject-83-INVALID STOPOVER CODE (AIRLINE ITINERARY)',
  'MRA Reject-84-INVALID PASSENGER NAME',
  'MRA Reject-85-INVALID DESTINATION CITY/AIRPORT CODE',
  'MRA Reject-86-RESERVED FOR FUTURE USE',
  'MRA Reject-87-VISAPHONE OUTGOING MUST BE A CHARGEBACK',
  'MRA Reject-88-M-PHONE OUTGOING SALES/CREDIT NOT VALID',
  'MRA Reject-89-M-PHONE CHRGBACK INVALID ARN OR AQU. ICA',
  'MRA Reject-90-UNIQUE TRANSACTION WITH INVALID MCC/SIC',
  'MRA Reject-91-INVALID CHARGEBACK REASON FOR EPS TRANS.',
  'MRA Reject-92-INVALID CHARGEBACK REASON FOR T&&E TRAN.',
  'MRA Reject-93-CARD ACCEPTOR ID NOT ALPHANUMERIC',
  'MRA Reject-94-INVALID CHARGEBACK REASON FOR NON T&&E.',
  'MRA Reject-95-REQUEST/CONFIRMATION INVALID ON ATM TRANS',
  'MRA Reject-96-INVALID AIRLINE TICKET NUMBER',
  'MRA Reject-97-INVALID DEPARTURE DATE',
  'MRA Reject-98-CARDHOLDER BANK NOT FOUND',
  'MRA Reject-99-INVALID ACI FOR RPS (CPS)',
  'MRA Reject-100-INVALID C/H ACTIVATED TERMINAL INDICATOR',
  'MRA Reject-101-INVALID TRANS ID / BANKNET REF #',
  'MRA Reject-102-INVALID AUTH CURRENCY (CPS)',
  'MRA Reject-103-INVALID AUTH AMOUNT (CPS)',
  'MRA Reject-104-VALIDATION CODE IS BLANK (CPS)',
  'MRA Reject-105-INVALID AUTH RESPONSE (CPS)',
  'MRA Reject-106-INVALID AUTHORIZATION DATE',
  'MRA Reject-107-INVALID TERMINAL ENTRY MODE FOR RPS (CPS)',
  'MRA Reject-108-INVALID MERCHANT ZIP (CPS)',
  'MRA Reject-109-INVALID TERMINAL CAPABILITY (CPS)',
  'MRA Reject-110-INVALID CARDHOLDER ID METH. FOR RPS (CPS)',
  'MRA Reject-111-INVALID CARD ACCEPTOR (CPS)',
  'MRA Reject-112-INVALID AUTHORIZATION SOURCE (CPS)',
  'MRA Reject-113-INVALID AUTHORIZATION CODE (CPS)',
  'MRA Reject-114-INVALID VISA BIN',
  'MRA Reject-115-ANNUAL FEE PREVIOUSLY REVERSED',
  'MRA Reject-116-INVALID EXCLUDE TRANSACTION ID REASON',
  'MRA Reject-117-INVALID RIS INDICATOR',
  'MRA Reject-118-INVALID MERCHANT INDICATOR',
  'MRA Reject-119-TRANSACTION CATEGORY NOT ACCEPTED',
  'MRA Reject-120-PAYMENT APPLICATION ROUTINE NOT DEFINED',
  'MRA Reject-121-MCC/SIC CODE INVALID FOR ATM CASH ITEMS',
  'MRA Reject-122-MCC 6011 VALID ONLY FOR ATM CASH ITEMS',
  'MRA Reject-123-INVALID AVS RESPONSE CODE FOR ACI=V (CPS)',
  'MRA Reject-124-INVALID RESTRICTED TICKET IDENTIFIER',
  'MRA Reject-125-INVALID NO SHOW',
  'MRA Reject-126-INVALID EXTRA CHARGES',
  'MRA Reject-127-INVALID CHECK IN OR CHECK OUT DATE',
  'MRA Reject-128-INVALID SALES TAX',
  'MRA Reject-129-RECOVERY CREDIT EXCEPTION',
  'MRA Reject-130-INVALID CARD ACTIVATED TERMINAL INDICATOR',
  'MRA Reject-131-INVALID PREPAID CARD INDICATOR',
  'MRA Reject-132-INVALID ATM ACCOUNT SELECTION ID',
  'MRA Reject-133-INVALID ADJUSTMENT PROCESSING ID',
  'MRA Reject-134-MULTIPLE CLEARING NUM/SEQ NOT NUMERIC',
  'MRA Reject-135-RPS/EXT. RECORD FAILURE',
  'MRA Reject-136-INVALID AMEX/DISC/JCB MERCHANT NUMBER',
  'MRA Reject-137-INVALID CONTROL SEQUENCE NUMBER',
  'MRA Reject-138-INVALID AUTHORIZATION RESPONSE CODE',
  'MRA Reject-139-INVALID AMEX FOOD AMOUNT',
  'MRA Reject-140-INVALID AMEX PCID',
  'MRA Reject-141-INVALID AMEX DESCRIPTOR CODE',
  'MRA Reject-142-INVALID REQUESTED PAYMENT SERVICE IND CPS',
  'MRA Reject-143-INVALID MRKT SPECIFIC DATA FOR RPS (CPS)',
  'MRA Reject-144-PT ITINERARY DATA REQUIRED FOR CPS/PT CPS',
  'MRA Reject-145-INVALID PRIME ENTRY',
  'MRA Reject-146-INVALID DISCLOSURE GROUP',
  'MRA Reject-147-INVALID BALANCE STRUCTURE',
  'MRA Reject-148-INVALID CUSTOMER CODE',
  'MRA Reject-149-INVALID FREIGHT AMOUNT',
  'MRA Reject-150-INVALID DESTINATION ZIP CODE',
  'MRA Reject-151-INVALID MERCHANT TYPE',
  'MRA Reject-152-INVALID DUTY AMOUNT',
  'MRA Reject-153-INVALID PRODUCT CODE',
  'MRA Reject-154-INVALID ITEM DESCRIPTION',
  'MRA Reject-155-INVALID QUANTITY',
  'MRA Reject-156-INVALID EXTENDED ITEM AMOUNT',
  'MRA Reject-157-INVALID HOTEL FOLIO NUMBER',
  'MRA Reject-158-INVALID PROPERTY TELEPHONE NUMBER',
  'MRA Reject-159-INVALID CUSTOMER SERVICE 800 NUMBER',
  'MRA Reject-160-INVALID CAR RENTAL AGREEMENT NUMBER',
  'MRA Reject-161-INVALID CAR RENTAL RENTER NAME',
  'MRA Reject-162-INVALID CAR RENTAL RETURN CITY',
  'MRA Reject-163-INVALID CAR RENTAL RETURN STATE/CTRY',
  'MRA Reject-164-INVALID CAR RENTAL RETURN DATE',
  'MRA Reject-165-INVALID ITEM UNIT OF MEASURE',
  'MRA Reject-166-INVALID AMOUNT IN CURRENCY OF TRANSACTION',
  'MRA Reject-167-INVALID CURRENCY CODE',
  'MRA Reject-168-DUPLICATE MERCHANT DEPOSIT',
  'MRA Reject-169-INVALID NATIONAL/ALTERNATE TAX INDICATOR',
  'MRA Reject-170-INVALID MOTO TRANS FOR CPS/RETAIL KEYED',
  'MRA Reject-171-INVALID DOC INDICATOR VALUE',
  'MRA Reject-172-INACTIVE ACCOUNT',
  'MRA Reject-173-INVALID MERCHANT TAX ID',
  'MRA Reject-174-FLEET OIL COMP BRAND NAME INVALID',
  'MRA Reject-175-FLEET MERCHANT ADDRESS INVALID',
  'MRA Reject-176-FLEET MERCHANT POSTAL CODE INVALID',
  'MRA Reject-177-FLEET PURCHASE TIME INVALID',
  'MRA Reject-178-FLEET FUEL PROD CODE INVALID',
  'MRA Reject-179-FLEET FUEL UNIT PRICE INVALID',
  'MRA Reject-180-FLEET FUEL UNIT OF MEASURE INVALID',
  'MRA Reject-181-FLEET FUEL QUANTITY INVALID',
  'MRA Reject-182-FLEET FUEL SALE AMOUNT INVALID',
  'MRA Reject-183-FLEET COUPON DISCOUNT AMT INVALID',
  'MRA Reject-184-FLEET PRODUCT CODE INVALID',
  'MRA Reject-185-FLEET EXTENDED ITEM AMOUNT INVALID',
  'MRA Reject-186-FLEET DR/CR INDICATOR INVALID',
  'MRA Reject-187-FLEET INVALID TYPE OF PURCHASE',
  'MRA Reject-188-INVALID LOCAL TAX/LOCAL TAX INCL COMBO',
  'MRA Reject-189-INCOMPLETE TRANSFER',
  'MRA Reject-190-DECONVERT REJECT',
  'MRA Reject-191-INVALID RETAIL TRANSACTION INDICATOR',
  'MRA Reject-192-INVALID EXPIRATION DATE',
  'MRA Reject-193-TLP REJECT',
  'MRA Reject-194-INVALID SKU',
  'MRA Reject-195-UNMATCHED COMPANY NUMBER',
  'MRA Reject-196-INVALID CAR RENTAL LOCATOR ID',
  'MRA Reject-197-EXCESSIVE CREDITS',
  'MRA Reject-198-GIFTCARD',
  'MRA Reject-199-THEAD/MHED6 CURRENCY CODE DO NOT MATCH',
  'MRA Reject-200-NO MATCHING AUTHORIZATION RECORD',
  'MRA Reject-201-MC-O INVALID RETRIEVAL REQUEST RES',
  'MRA Reject-202-AMOUNT INCORRECTLY ROUNDED',
  'MRA Reject-203-NO ADDENDUM DATA',
  'MRA Reject-204-PROCESSING CODE DOES NOT MATCH TRAN CODE',
  'MRA Reject-205-FUNCTION CODE INVALID',
  'MRA Reject-206-INVALID TCQ',
  'MRA Reject-207-INVALID DINERS ACQUIRER NUMBER',
  'MRA Reject-208-INVALID PROD ENHANCEMENT',
  'MRA Reject-209-MERCHANT PER ITEM FEES NOT NUMERIC',
  'MRA Reject-210-INVALID LOCAL ACCEPTANCE TRANSACTION',
  'MRA Reject-211-RESERVED FOR FUTURE USE',
  'MRA Reject-212-STATUS BLOCKED',
  'MRA Reject-213-RESERVED FOR FUTURE USE',
  'MRA Reject-214-INVALID PAYMENT SERVICE INDICATOR (REPS)',
  'MRA Reject-215-INVALID CHARGEBACK CURRENCY CODE',
  'MRA Reject-216-INVALID CB AMOUNT IN TRANSACTION CURRENCY',
  'MRA Reject-217-INVALID MERCHANT ID FOR CAT LEVEL',
  'MRA Reject-218-INVALID INTERNATIONAL FEE INDICATOR',
  'MRA Reject-219-INVALID SETTLEMENT FLAG',
  'MRA Reject-220-INCORRECT MERCHANT ELIGIBILITY FLAG',
  'MRA Reject-221-INVALID IRF INDICATOR',
  'MRA Reject-222-IVA ON MERCHANT PER ITEM FEES NOT NUMERIC',
  'MRA Reject-223-IVA ON MERCHANT DISCOUNT NOT NUMERIC',
  'MRA Reject-224-MERCHANT PER ITEM FEES NOT NUMERIC',
  'MRA Reject-225-STATUS DROPPED',
  'MRA Reject-226-PREPAID POSTING REJECT',
  'MRA Reject-227-INVALID SECURITIZED ITEM',
  'MRA Reject-228-RESERVED FOR FUTURE USE',
  'MRA Reject-229-INVALID TCAT',
  'MRA Reject-230-INVALID TCAT/TRAN CODE COMBINATION',
  'MRA Reject-231-INVALID CASH BACK AMOUNT',
  'MRA Reject-232-INVALID TRAN CODE FOR CPD',
  'MRA Reject-233-INVALID POS DATA CODE',
  'MRA Reject-234-INVALID MOTOR FUEL SERVICE TYPE',
  'MRA Reject-235-INVALID DISCOVER IIN',
  'MRA Reject-236-INVALID PROCESSING CODE',
  'MRA Reject-237-INVALID TRACK DATA CONDITION CODE',
  'MRA Reject-238-EXCESSIVE TRAN AMT',
  'MRA Reject-239-LID LEVEL 3 NOT MATCH',
  'MRA Reject-240-FLEET FUEL DRIVER/ID NUMBER INVALID',
  'MRA Reject-241-INVALID STORE ID',
  'MRA Reject-242-NOT VALID FOR RTC',
  'MRA Reject-243-MERCH BATCH WITHOUT DETAIL TRANSACTIONS',
  'MRA Reject-244-INVALID DCC TRANSACTION',
  'MRA Reject-245-DUPLICATE TXN',
  'MRA Reject-246-EXCESSIVE DUPLICATE TXN',
  'MRA Reject-247-OVER FLOOR LIMIT/NO AUTH',
  'MRA Reject-248-ACCT ON STOPLIST',
  'MRA Reject-249-RESERVED FOR FUTURE USE',
  'MRA Reject-250-TXN-CUR NE MER-CUR',
  'MRA Reject-251-RESERVED FOR FUTURE USE',
  'MRA Reject-252-RESERVED FOR FUTURE USE',
  'MRA Reject-253-INVALID AMEX SUBMITTER ID',
  'MRA Reject-254-ORIG-PROC NOT NUM',
  'MRA Reject-255-RESERVED FOR FUTURE USE',
  'MRA Reject-256-RESERVED FOR FUTURE USE',
  'MRA Reject-257-INVALID COMPONENTS',
  'MRA Reject-258-RESERVED FOR FUTURE USE',
  'MRA Reject-259-VTM ONLY CARD',
  'MRA Reject-260-RESERVED FOR FUTURE USE',
  'MRA Reject-261-RESERVED FOR FUTURE USE',
  'MRA Reject-262-RESERVED FOR FUTURE USE',
  'MRA Reject-263-INV. GAMBLING C.V.',
  'MRA Reject-264-INV. TXN FOR CFT MER',
  'MRA Reject-265-INV. MCC FOR CFT TXN',
  'MRA Reject-266-INV. ISSUER FOR CFT',
  'MRA Reject-267-TXN AMT>CFT LIMIT',
  'MRA Reject-268-DAILY CFT LIMIT',
  'MRA Reject-269-INVALID BIN REGION',
  'MRA Reject-270-CV AMT >= MAX AMT',
  'MRA Reject-271-CV/ACCT >= MAX AMT',
  'MRA Reject-272-CV/CNT/VU >= MAX CNT',
  'MRA Reject-273-CV/AMT/VU >= MAX AMT',
  'MRA Reject-274-CV/AMT/VU >= MAX PER',
  'MRA Reject-275-ATM ONLY CARD',
  'MRA Reject-276-CV AMT <= MIN AMT',
  'MRA Reject-HT-Transaction does not meet authorization timeliness criteria',
  'MRA Reject-C1-reason code C1',
  'MRA Reject-T01-EXCEEDS MAXIMUM TIMELINESS VALUE OF <NNN> DAYS',
  'MRA Reject-20-Original Not Found For Reversal',
  'MRA Reject-21-Recurring Payment Cancellation Service',
  'MRA Reject-592-For the rejected representment-DOCUMENTATION ALWAYS REQUIRED',
  'MRA Reject-631-PDS0158S4 INTERCHANGE RATE DESIGNATOR AND PROCESSING CODE/REVERSAL INDICATOR COMBINATION INVALID FOR BUSINESS SERVICE ARRANGEMENT <BUSINESS SERVICE ID & TYPE> AND ACCEPTANCE BRAND ID CODE <AB>.',
  'MRA Reject-637-EXCEEDS MAXIMUM TIMELINESS VALUE OF <NNN> DAYS',
  'MRA Reject-670-Did not meet the timeliness criteria',
  'MRA Reject-687-DE24 FUNCTION CODE AND PDS0025 REVERSAL INDICATOR COMBINATION INVALID.',
  'MRA Reject-2224-DE26 CARD ACCEPTOR BUSINESS CODE (MCC) INVALID FOR BUSINESS SERVICE ARRANGEMENT<BUSINESS SERVICE ID & TYPE>AND ACCEPTANCE BRAND ID CODE <AB> FOR INTERCHANGE PROGRAM',
  'MRA Reject-2226-Bin information does not match up.',
  'MRA Reject-2422-DE38 APPROVAL CODE NOT COMPATIBLE WITH SUBMITTED IRD',
  'MRA Reject-2430-MASTERCARD ASSIGNED ID, IF PRESENT, MUST CONTAIN A VALID VALUE',
  'MRA Reject-2459-PDS0158S4 INTERCHANGE RATE DESIGNATOR NOT VALID FOR APPROVAL CODE AND CONSUMER BRAND PRODUCT COMBINATION.',
  'MRA Reject-2488-Trace ID incorrectly formatted',
  'MRA Reject-2499 - Primary Account Number (PAN) Not Eligible for Product Graduation',
  'Account Maintenance - DDA Update',
  'Account Maintenance - Legal Name Update',
  'Account Maintenance - Ownership Change',
  'Account Maintenance - Address Update',
  'Account Maintenance - Contact Update',
];
