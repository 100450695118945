import {AgentView, AuthUserClaims, GroupRole, MerchantView, UserRoles} from '@ozark/common';

export const isMerchantManagementAllowed = (
  claims: AuthUserClaims,
  authProfile: AgentView | MerchantView | undefined
) => {
  const isAllowed =
    claims.groupRole === GroupRole.administrator ||
    claims.isGroupOwner === true ||
    claims.role === UserRoles.merchant;
  if (!isAllowed) {
    return false;
  }

  if (claims.role === UserRoles.merchant) {
    return !authProfile?.masterUid;
  }
  return true;
};
