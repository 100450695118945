import {Platforms} from './Platforms';
import {ProcessingTypes} from './ProcessingTypes';

// TODO move this to database
export enum IndustryVertical {
  _0000 = '0000',
  _1000 = '1000',
  _2000 = '2000',
  _3000 = '3000',
  _4000 = '4000',
  _5000 = '5000',
  _6000 = '6000',
  _7000 = '7000',
}

export const IndustryVerticalBoardedMethods: {[_ in keyof IndustryVertical as string]: string} = {
  [IndustryVertical._1000]: 'Retail',
  [IndustryVertical._3000]: 'MOTO',
  [IndustryVertical._4000]: 'E-Commerce',
};

export const IndustryVerticalLabel: {[_ in IndustryVertical]: string} = {
  [IndustryVertical._0000]: '0000 - Test',
  [IndustryVertical._1000]: `1000 - ${IndustryVerticalBoardedMethods[IndustryVertical._1000]}`,
  [IndustryVertical._2000]: '2000 - Restaurant',
  [IndustryVertical._3000]: `3000 - ${IndustryVerticalBoardedMethods[IndustryVertical._3000]}`,
  [IndustryVertical._4000]: `4000 - ${IndustryVerticalBoardedMethods[IndustryVertical._4000]}`,
  [IndustryVertical._5000]: '5000',
  [IndustryVertical._6000]: '6000',
  [IndustryVertical._7000]: '7000',
};

export const PlatformIndustryVerticals: {[_ in Platforms]: IndustryVertical[]} = {
  [Platforms.avidiaFD]: [...Object.values(IndustryVertical)],
  [Platforms.cbsFD]: [...Object.values(IndustryVertical)],
  [Platforms.evolve5846]: [
    IndustryVertical._0000,
    IndustryVertical._1000,
    IndustryVertical._2000,
    IndustryVertical._3000,
    IndustryVertical._4000,
  ],
  [Platforms.avidiaTsys]: [
    IndustryVertical._0000,
    IndustryVertical._1000,
    IndustryVertical._2000,
    IndustryVertical._3000,
    IndustryVertical._4000,
  ],
  [Platforms.evolve5848]: [
    IndustryVertical._0000,
    IndustryVertical._1000,
    IndustryVertical._2000,
    IndustryVertical._3000,
    IndustryVertical._4000,
  ],
  [Platforms.chesapeakeTsys]: [
    IndustryVertical._0000,
    IndustryVertical._1000,
    IndustryVertical._2000,
    IndustryVertical._3000,
    IndustryVertical._4000,
  ],
  [Platforms.metaPathly]: [
    IndustryVertical._0000,
    IndustryVertical._1000,
    IndustryVertical._2000,
    IndustryVertical._3000,
    IndustryVertical._4000,
  ],
  [Platforms.appsSynovus]: [
    IndustryVertical._0000,
    IndustryVertical._1000,
    IndustryVertical._2000,
    IndustryVertical._3000,
    IndustryVertical._4000,
  ],
};

export const getIndustryByProcessingType = (processingType: ProcessingTypes) => {
  switch (processingType) {
    case ProcessingTypes.cardPresent:
      return IndustryVertical._1000;
    case ProcessingTypes.cardNotPresent:
      return IndustryVertical._3000;
    case ProcessingTypes.eCommerce:
      return IndustryVertical._4000;
    default:
      return IndustryVertical._5000;
  }
};
