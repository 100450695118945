import {DocumentBase, UniversalSnapshot} from '.';
import {SubscriptionCategory, SubscriptionEvent, SubscriptionType} from '../constants';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export type SubscriptionConfigData = Partial<
  Record<
    SubscriptionCategory,
    Partial<Record<SubscriptionType, Partial<Record<SubscriptionEvent, boolean>>>>
  >
>;

export type SubscriptionConfig = DocumentBase & Record<string, unknown>;

export type SubscriptionConfigView = ViewBase<DocumentBase & SubscriptionConfigData>;

export const getSubscriptionConfigData = (
  snapshot: UniversalSnapshot<SubscriptionConfig>
): SubscriptionConfigData => {
  if (!snapshot.exists) {
    return {};
  }

  const subscriptionConfigData: SubscriptionConfigData = {};

  const data = snapshot.data() as SubscriptionConfig;

  for (let subscriptionKey in data) {
    const [category, type, event] = subscriptionKey.split(':') as [
      SubscriptionCategory,
      SubscriptionType,
      SubscriptionEvent
    ];

    if (!(category in subscriptionConfigData)) {
      subscriptionConfigData[category] = {};
    }

    if (!(type in subscriptionConfigData[category]!)) {
      subscriptionConfigData[category]![type] = {};
    }

    subscriptionConfigData[category]![type]![event] = data[subscriptionKey];
  }

  return subscriptionConfigData;
};

export const selectSubscriptionConfigView: ViewSelector<
  SubscriptionConfigView,
  SubscriptionConfig
> = snapshot => {
  const subscriptionConfig: SubscriptionConfigView = {
    ...defaultViewSelector(snapshot),
    ...getSubscriptionConfigData(snapshot),
  };

  return subscriptionConfig;
};
