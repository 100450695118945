import {Box, Typography} from '@mui/material';
import {ApplicationResponse} from '@ozark/functions/src/functions/express/private/types';
import {ReactNode} from 'react';
import {ApplicationView} from '../..';

type Props = {
  caption: string;
  children: ReactNode;
  disableWrap?: boolean;
};

export const CardDataEntry = ({caption, children, disableWrap}: Props) => {
  return (
    <Box pb={0.8}>
      <Typography
        variant="body1"
        component="div"
        noWrap={!disableWrap}
        lineHeight={1.4}
        fontSize=".95rem"
      >
        {children || <Box color="#999">-</Box>}
      </Typography>
      <Typography variant="caption" component="div" color="#999" lineHeight={1.3}>
        {caption}
      </Typography>
    </Box>
  );
};

export const BusinessAddressEntry = ({
  application,
}: {
  application: ApplicationView | ApplicationResponse;
}) => {
  return (
    <Box>
      {application.businessAddress1} {application.businessAddress2}
      <br />
      {application.businessCity}, {application.businessState} {application.businessZip}
    </Box>
  );
};
