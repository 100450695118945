import {useLocation} from 'react-router-dom';

export function useUrlQuery() {
  return new URLSearchParams(useLocation().search);
}

export function useUrlQueryObject() {
  const searchParams = useUrlQuery();
  const searchObj = paramsToObject(searchParams.entries());
  return searchObj;
}

function paramsToObject(entries: IterableIterator<[string, string]>) {
  const result: Record<string, string> = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}
