import {Alert, Box, Typography} from '@mui/material';
import {Component, ErrorInfo, FC, PropsWithChildren, ReactNode} from 'react';
import {ErrorState} from './ErrorState';

export interface ErrorStateProps {
  error?: Error;
  onClose: () => void;
}

interface Props {
  children?: ReactNode;
  errorStateComponent?: (props: ErrorStateProps) => JSX.Element;
}

interface State {
  hasError: boolean;
  error?: Error;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return {hasError: true, error};
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  onErrorClose = () => {
    this.setState({
      error: undefined,
      hasError: false,
    });
  };

  public render() {
    const {errorStateComponent: ErrorComponent} = this.props;
    const {hasError, error} = this.state;

    if (hasError && ErrorComponent) {
      return <ErrorComponent error={error} onClose={this.onErrorClose} />;
    }

    if (this.state.hasError) {
      return (
        <Box p={2}>
          <Alert severity="error">
            <Typography variant="body1">Sorry.. there was an error</Typography>
          </Alert>
        </Box>
      );
    }

    return this.props.children;
  }
}

export const GlobalErrorBoundary: FC<PropsWithChildren<unknown>> = ({children}) => (
  <ErrorBoundary errorStateComponent={ErrorState}>{children}</ErrorBoundary>
);
