import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Firebase} from '../../firebase';
import {useCallable, useNotification} from '../../hooks';
import {ConfirmCode} from './ConfirmCode';
import {ContactMethod} from './ContactMethod';

enum WizardStep {
  First,
  Second,
}

export const MfaAuthWizard = () => {
  const showNotification = useNotification();
  const [step, setStep] = useState<WizardStep>(WizardStep.First);
  const [sendViaEmail, setSendViaEmail] = useState(true);
  const [codeId, setCodeId] = useState<string | undefined>();
  const {sendMfaCode, confirmMfaCode} = useCallable();
  const history = useHistory<{
    email: string;
    phone: string | undefined;
    token: string;
    returnTo: string | undefined;
  }>();
  const {state} = history.location;
  if (!state?.token) {
    history.replace('login');
  }

  const onSendMfaCode = async () => {
    const result = await sendMfaCode({useEmail: sendViaEmail, token: state.token, codeId: codeId});
    if (result.status === 'error') {
      showNotification('error', 'Failed to send code');
      setCodeId(undefined);
      return false;
    }
    setCodeId(result.codeId);
    return true;
  };

  const onConfirmMfaCode = async (code: string) => {
    const result = await confirmMfaCode({code: code, codeId: codeId!, token: state.token});
    if (result.status === 'error') {
      showNotification('error', 'Verification code incorrect');
      return false;
    }
    await Firebase.auth.signInWithCustomToken(result.token);
    return true;
  };

  const onContactMethodNextStep = () => {
    setStep(WizardStep.Second);
  };

  const onBack = () => {
    setStep(WizardStep.First);
  };

  const helperText =
    step === WizardStep.First
      ? 'What contact method would you like to use?'
      : 'Enter verification code';

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />

      <Paper
        sx={{
          marginTop: theme => theme.spacing(8),
          padding: theme => theme.spacing(4),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" gutterBottom>
          Please confirm your identity
        </Typography>

        <Typography variant="h6" gutterBottom>
          {helperText}
        </Typography>

        {step === WizardStep.First && (
          <ContactMethod
            onNextStep={onContactMethodNextStep}
            setSendViaEmail={setSendViaEmail}
            sendMfaCode={onSendMfaCode}
            sendViaEmail={sendViaEmail}
            email={state.email}
            phone={state.phone}
          />
        )}
        {step === WizardStep.Second && (
          <ConfirmCode
            sendMfaCode={onSendMfaCode}
            confirmCode={onConfirmMfaCode}
            back={onBack}
            returnTo={state.returnTo}
          />
        )}
      </Paper>
    </Container>
  );
};
