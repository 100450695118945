import {Agent, Collections, ScheduleA} from '@ozark/common';
import {Firebase} from '../../../firebase';
import {ListOption} from '../../../models';

export const HundredPercent = '100%';

export const getAgentScheduleAListOptions = async (agentId: string): Promise<ListOption[]> => {
  const listOptions: ListOption[] = [];
  listOptions.push({
    key: HundredPercent,
    value: HundredPercent,
  });
  let scheduleAData: ScheduleA | undefined;
  const agentScheduleASnapshot = await Firebase.firestore
    .collection(Collections.agents)
    .doc(agentId)
    .collection(Collections.scheduleA)
    .orderBy('version', 'desc')
    .limit(1)
    .get();
  if (!agentScheduleASnapshot.empty) {
    scheduleAData = agentScheduleASnapshot.docs[0].data() as ScheduleA;
  } else {
    //try to get scheduleA from Master Agent
    const agentSnapshot = await Firebase.firestore
      .collection(Collections.agents)
      .doc(agentId)
      .get();

    if (!agentSnapshot.exists) {
      return listOptions;
    }

    const agentData = agentSnapshot.data() as Agent;

    if (agentData.masterUid) {
      const masterAgentScheduleASnapshot = await Firebase.firestore
        .collection(Collections.agents)
        .doc(agentData.masterUid)
        .collection(Collections.scheduleA)
        .orderBy('version', 'desc')
        .limit(1)
        .get();

      if (!masterAgentScheduleASnapshot.empty) {
        scheduleAData = masterAgentScheduleASnapshot.docs[0].data() as ScheduleA;
      }
    }

    if (!scheduleAData) {
      //try to get scheduleA from Group
      const groupScheduleASnapshot = await Firebase.firestore
        .collection(Collections.groups)
        .doc(agentData.group.id)
        .collection(Collections.scheduleA)
        .orderBy('version', 'desc')
        .limit(1)
        .get();
      if (!groupScheduleASnapshot.empty) {
        scheduleAData = groupScheduleASnapshot.docs[0].data() as ScheduleA;
      }
    }
  }

  if (!scheduleAData) {
    return listOptions;
  }

  const lowProcessingVolumePercent = scheduleAData.riskModels?.low?.monthlyProcessingVolumePercent;
  if (lowProcessingVolumePercent !== undefined) {
    const data = `Low Risk (${lowProcessingVolumePercent}%)`;
    listOptions.push({
      key: data,
      value: data,
    });
  }

  const mediumProcessingVolumePercent =
    scheduleAData.riskModels?.medium?.monthlyProcessingVolumePercent;
  if (mediumProcessingVolumePercent !== undefined) {
    const data = `Medium Risk (${mediumProcessingVolumePercent}%)`;
    listOptions.push({
      key: data,
      value: data,
    });
  }

  const highMonthlyProcessingVolumePercent =
    scheduleAData.riskModels?.high?.monthlyProcessingVolumePercent;
  if (highMonthlyProcessingVolumePercent !== undefined) {
    const data = `High Risk (${highMonthlyProcessingVolumePercent}%)`;
    listOptions.push({
      key: data,
      value: data,
    });
  }
  return listOptions;
};
