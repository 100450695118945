import {PaginatedResponse} from '@ozark/functions/src/shared';
import {SearchCriteria} from '../api/SearchCriteria';

// Converts criterias like orderBy=field1 to orderBy=field&index=1
export const getSearchCriteriaWithIndexDedicated = (
  searchCriteria: SearchCriteria
): SearchCriteria => {
  const numbers = searchCriteria.orderBy.match(/\d+/);
  if (numbers && numbers.length > 0) {
    searchCriteria.orderBy = searchCriteria.orderBy.substring(
      0,
      searchCriteria.orderBy.length - numbers[0].length
    );
    searchCriteria.index = Number(numbers[0]);
  }
  return searchCriteria;
};

// Converts paginated response back to have sorting columns with index, ie sort=field&sortIndex=1 to orderBy=field1
export const getPaginatedResponseWithBuiltInIndex = (
  report: PaginatedResponse<any> | null
): PaginatedResponse<any> | null => {
  if (report?.sortIndex !== undefined) {
    report.sort[0][0] = `${report.sort[0][0]}${report.sortIndex}`;
  }
  return report;
};
