import {AccountStatus} from '@ozark/common';
import {FilterOption} from './Filters';

export const accountStatusFilter: FilterOption = {
  id: 'accountStatus',
  column: 'accountStatus',
  autoSelect: true,
  label: 'Account Status',
  type: 'list',
  options: Object.values(AccountStatus).map(status => ({key: status, value: status})),
  operators: [
    {
      id: '__eq',
      label: 'equals',
    },
  ],
};
