export const PortfolioPCIIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g fill={'inherit'}>
        <path
          d="M48,512c-26.5,0-48-21.5-48-48l0,0V176c0-26.5,21.5-48,48-48h48v208c0,44.2,35.8,80,80,80h336v48
		c0,26.5-21.5,48-48,48l0,0H48z"
        />
        <g>
          <path
            opacity={0.4}
            d="M313.8,192.4h-9.7c-3.4,0-5.4,1.8-5.4,5.4v13.7c0,3.4,2,5.4,5.4,5.4h9.7c8.6,0,13-1.1,13-12.2
			C326.8,193.5,322.4,192.4,313.8,192.4z"
          />
          <path
            opacity={0.4}
            d="M592,64H400L336,0H176c-26.5,0-48,21.5-48,48v288c0,26.5,21.5,48,48,48h416c26.5,0,48-21.5,48-48V112
			C640,85.5,618.5,64,592,64z M312.7,247.9h-8.6c-3.4,0-5.4,1.8-5.4,5.4v28.8c0,3.6-1.8,5.4-5.4,5.4h-20.2c-3.4,0-5.4-2-5.4-5.4V167
			c0-3.6,1.8-5.4,5.4-5.6h39.6c32,0,45,13.7,45,43.2C357.7,234.2,344.8,247.9,312.7,247.9z M412.3,259.7c9.9,0,12.6-4.7,13.7-13
			c0.5-3.8,3.4-6.1,7-5.2l20.3,4.5c2.5,0.5,4.1,2.5,4,5.2c-0.7,13.7-6.7,39.4-45,39.4c-42.7,0-45.2-31.9-45.2-43.7v-45
			c0-11.9,2.5-43.7,45.2-43.7c38.3,0,44.3,25.7,45,39.6c0.2,2.5-1.4,4.5-4,5l-20,4.5c-3.8,0.7-6.7-1.8-7.2-5.6
			c-0.9-7.9-4.1-12.6-13.9-12.6c-13.3,0-14.2,8.5-14.2,22.3v25.9C398,251.3,398.9,259.7,412.3,259.7z M500.3,282.1
			c0,3.4-1.8,5.4-5.4,5.4h-19.8c-3.4,0-5.4-2-5.4-5.4V166.9c0-3.6,2-5.4,5.4-5.4h19.8c3.6,0,5.4,1.8,5.4,5.4V282.1z"
          />
        </g>
        <g>
          <path
            d="M312.7,161.5c32,0,45,13.7,45,43.2s-13,43.2-45,43.2h-8.6c-3.4,0-5.4,1.8-5.4,5.4v28.8c0,3.6-1.8,5.4-5.4,5.4
			h-20.2c-3.4,0-5.4-2-5.4-5.4V167c0-3.6,1.8-5.4,5.4-5.6h39.6V161.5z M313.8,216.9c8.6,0,13-1.1,13-12.2c0-11.2-4.3-12.2-13-12.2
			h-9.7c-3.4,0-5.4,1.8-5.4,5.4v13.7c0,3.4,2,5.4,5.4,5.4L313.8,216.9L313.8,216.9z"
          />
          <path
            d="M367.1,202c0-11.9,2.5-43.7,45.2-43.7c38.3,0,44.3,25.7,45,39.6c0.2,2.5-1.4,4.5-4,5l-20,4.5
			c-3.8,0.7-6.7-1.8-7.2-5.6c-0.9-7.9-4.1-12.6-13.9-12.6c-13.3,0-14.2,8.5-14.2,22.3v25.9c0,13.9,0.9,22.3,14.2,22.3
			c9.9,0,12.6-4.7,13.7-13c0.5-3.8,3.4-6.1,7-5.2l20.3,4.5c2.5,0.5,4.1,2.5,4,5.2c-0.7,13.7-6.7,39.4-45,39.4
			c-42.7,0-45.2-31.9-45.2-43.7V202H367.1z"
          />
          <path
            d="M494.9,161.5c3.6,0,5.4,1.8,5.4,5.4v115.2c0,3.4-1.8,5.4-5.4,5.4h-19.8c-3.4,0-5.4-2-5.4-5.4V166.9
			c0-3.6,2-5.4,5.4-5.4H494.9z"
          />
        </g>
      </g>
    </svg>
  );
};
