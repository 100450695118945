import {Switch as MuiSwitch} from '@mui/material';
import {Control, Controller, FieldValues, Path} from 'react-hook-form';

interface Props<T extends FieldValues, TName> {
  name: TName;
  control: Control<T, any>;
  disabled?: boolean;
  defaultValue?: boolean;
}

export const Switch = <T extends FieldValues, TName extends Path<T>>({
  name,
  control,
  disabled = false,
  defaultValue = false,
}: Props<T, TName>) => (
  <Controller
    control={control}
    name={name}
    render={({field}) => (
      <MuiSwitch
        disabled={disabled}
        {...field}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          field.onChange(event.target.checked)
        }
        checked={field.value !== undefined ? field.value : defaultValue}
      />
    )}
  />
);
