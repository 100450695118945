import {TicketStatus} from '../../..';
import {useTicketStatus} from './useTicketStatus';

const closedStatuses = [TicketStatus.Closed, TicketStatus.Unresolved, TicketStatus.Deleted];

export function useIsEditingClosed() {
  const {status} = useTicketStatus();
  const isEditingClosed = status && closedStatuses.includes(status);
  return {
    isEditingClosed,
  };
}
