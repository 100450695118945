import {AssigneeType} from '../../..';
import {TicketsListFilter} from '../common/TicketListFilter';
import {useTicketsFiltersContainer} from '../hooks/useTicketsFiltersContainer';

const options: string[] = Object.values(AssigneeType);

export function TicketsListFilterAssigneeType() {
  const {assigneeTypeFilter, setAssigneeTypeFilter} = useTicketsFiltersContainer();

  return (
    <TicketsListFilter
      options={options}
      label="Assignee Type"
      value={assigneeTypeFilter}
      valueDefault="All Types"
      handleChange={val => setAssigneeTypeFilter(val as AssigneeType | undefined)}
    />
  );
}
