import {Merchant} from '../documents';

export enum MerchantStatus {
  active = 'Active',
  inactive = 'Inactive',
}

export enum MerchantStatusAll {
  all = 'All',
}

export type MerchantStatusFilter = MerchantStatus | MerchantStatusAll;

export const getMerchantStatus = (merchant: Merchant) =>
  merchant.isActive ? MerchantStatus.active : MerchantStatus.inactive;

export const MapMerchantStatus: {[_ in string]: string} = {
  I: 'Inactive',
  F: 'Fraud',
  S: 'Suspect',
  Z: 'Merchant do not auth',
  C: 'Closed (nothing goes through)',
  D: 'Delete (Only Chargebacks and Adjustments)',
  B: 'Do not post deposits; drop next reorg',
  '': 'Open',
};
