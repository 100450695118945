import {Autocomplete, Box, TextField} from '@mui/material';
import {omit} from 'lodash';
import {useEffect, useState} from 'react';
import {
  Application,
  Collections,
  Firebase,
  TicketAssociation,
  TicketAssociationMid,
} from '../../../..';
import {getMerchantAsync} from '../../../../hooks/useMerchant';
import {useUserInfo} from '../../../../hooks/useUserInfo';
import {FIELD_NAME_MID} from '../../constants/constants';
import {InputAssociationError} from '../../types';

type Props = {
  draft: TicketAssociation;
  setDraft: (draft: TicketAssociation) => void;
  errors: Record<InputAssociationError, string>;
  setErrors: (errors: Record<InputAssociationError, string>) => void;
};

export const AssociationEditorValueMid = ({draft, setDraft, errors, setErrors}: Props) => {
  const {uid} = useUserInfo();
  const [merchantMids, setMerchantMids] = useState<TicketAssociationMid[]>(
    draft.mid ? [draft.mid] : []
  );

  useEffect(() => {
    if (!uid) return;
    let isMounted = true;
    (async () => {
      const merchant = await getMerchantAsync(uid);
      if (!merchant || !merchant.applicationMids?.length) return;

      const applicationsSnap = await Firebase.firestore
        .collection(Collections.applications)
        .where('mid', 'in', merchant.applicationMids)
        .get();

      if (applicationsSnap.size) {
        const result: TicketAssociationMid[] = applicationsSnap.docs.map(doc => {
          const app = doc.data() as Application;
          return {
            dba: app.doingBusinessAs,
            mid: app.mid,
          };
        });
        isMounted && setMerchantMids(result);
      } else {
        isMounted && setMerchantMids([]);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [uid]);

  return (
    <Box mt={2}>
      <Autocomplete
        value={draft.mid}
        isOptionEqualToValue={(option, value) => option?.mid === value?.mid}
        onChange={(
          _: React.SyntheticEvent<Element, Event>,
          newValue: TicketAssociationMid | string
        ) => {
          setDraft({
            ...draft,
            mid: (newValue as TicketAssociationMid) ?? undefined,
          });
          if (newValue) {
            setErrors(omit(errors, FIELD_NAME_MID) as Record<InputAssociationError, string>);
          }
        }}
        disableClearable
        options={merchantMids}
        getOptionLabel={(item: TicketAssociationMid) => `${item.mid} ${item?.dba ?? ''}`.trim()}
        renderInput={params => (
          <TextField
            {...params}
            label="Select Merchant ID"
            required
            variant="standard"
            error={Boolean(errors[FIELD_NAME_MID])}
            helperText={errors[FIELD_NAME_MID]}
          />
        )}
      />
    </Box>
  );
};
