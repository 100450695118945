import {PaginatedApplicationsResponse} from '@ozark/functions/src/functions/express/private/types';
import {ContextKey} from '@ozark/functions/src/functions/express/private/types/Context';
import {UserDispositionsResponse} from '@ozark/functions/src/shared';
import {CancelToken} from 'axios';
import {ActiveFilter} from '../components';
import {VolumeFilter} from '../components/FilterPeriodSelect';
import {toQuery} from '../util';
import {ApiBase} from './ApiBase';
import {SearchCriteria} from './SearchCriteria';

export class ApplicationsApi extends ApiBase {
  getUserDispositionsReport = () => {
    return this.get<UserDispositionsResponse>('applications/user-dispositions');
  };
  getApplications = (
    searchCriteria: SearchCriteria,
    search?: string,
    filters?: ActiveFilter[],
    cancelToken?: CancelToken
  ) => {
    let queryString = toQuery(searchCriteria, filters);
    if (search?.length) {
      queryString += `&search=${search}`;
    }

    return this.get<PaginatedApplicationsResponse>(`applications?${queryString}`, {}, cancelToken);
  };

  getAgentSupportApplications = (
    volumeFilter: VolumeFilter,
    searchCriteria: SearchCriteria,
    search?: string,
    filters?: ActiveFilter[],
    cancelToken?: CancelToken
  ) => {
    let queryString = toQuery(searchCriteria, filters);
    if (search?.length) {
      queryString += `&search=${search}`;
    }

    return this.post<PaginatedApplicationsResponse>(
      `applications/agent-support?${queryString}`,
      {
        [ContextKey.StartDateTime]: volumeFilter.startDate,
        [ContextKey.EndDateTime]: volumeFilter.endDate,
      },
      {},
      cancelToken
    );
  };

  getConditionallyApprovedApplications = (
    volumeFilter: VolumeFilter,
    searchCriteria: SearchCriteria,
    search?: string,
    filters?: ActiveFilter[],
    cancelToken?: CancelToken
  ) => {
    let queryString = toQuery(searchCriteria, filters);
    if (search?.length) {
      queryString += `&search=${search}`;
    }

    return this.post<PaginatedApplicationsResponse>(
      `applications/conditionally-approved?${queryString}`,
      {
        [ContextKey.StartDateTime]: volumeFilter.startDate,
        [ContextKey.EndDateTime]: volumeFilter.endDate,
      },
      {},
      cancelToken
    );
  };

  getUwApplications = (
    volumeFilter: VolumeFilter,
    searchCriteria: SearchCriteria,
    search?: string,
    filters?: ActiveFilter[],
    cancelToken?: CancelToken
  ) => {
    let queryString = toQuery(searchCriteria, filters);
    if (search?.length) {
      queryString += `&search=${search}`;
    }

    return this.post<PaginatedApplicationsResponse>(
      `applications/underwriting?${queryString}`,
      {
        [ContextKey.StartDateTime]: volumeFilter.startDate,
        [ContextKey.EndDateTime]: volumeFilter.endDate,
      },
      {},
      cancelToken
    );
  };

  getIncompleteApplications = (
    volumeFilter: VolumeFilter,
    searchCriteria: SearchCriteria,
    search?: string,
    filters?: ActiveFilter[],
    cancelToken?: CancelToken
  ) => {
    let queryString = toQuery(searchCriteria, filters);
    if (search?.length) {
      queryString += `&search=${search}`;
    }

    return this.post<PaginatedApplicationsResponse>(
      `applications/incomplete?${queryString}`,
      {
        [ContextKey.StartDateTime]: volumeFilter.startDate,
        [ContextKey.EndDateTime]: volumeFilter.endDate,
      },
      {},
      cancelToken
    );
  };

  getBoardedApplications = (
    volumeFilter: VolumeFilter,
    searchCriteria: SearchCriteria,
    search?: string,
    filters?: ActiveFilter[],
    cancelToken?: CancelToken
  ) => {
    let queryString = toQuery(searchCriteria, filters);
    if (search?.length) {
      queryString += `&search=${search}`;
    }

    return this.post<PaginatedApplicationsResponse>(
      `applications/boarded?${queryString}`,
      {
        [ContextKey.StartDateTime]: volumeFilter.startDate,
        [ContextKey.EndDateTime]: volumeFilter.endDate,
      },
      {},
      cancelToken
    );
  };
}
