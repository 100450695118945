import {TicketAuthor, useUserInfo} from '../../..';

export const useTicketAuthor = () => {
  const {isErpUser, uid} = useUserInfo();
  const getAuthorName = (author: TicketAuthor | undefined) => {
    if (!author) {
      return '';
    }
    return isErpUser || author.id === uid ? author.name : author.department ?? 'No Department';
  };
  return {
    getAuthorName,
  };
};
