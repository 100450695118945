import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Checkbox, ListItemText} from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';

enum ViewName {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Schedule = 'Schedule',
}

export function SelectViewType() {
  const [viewName, setViewName] = React.useState<ViewName>(ViewName.Week);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="text"
        endIcon={<KeyboardArrowDownIcon />}
        color="secondary"
      >
        Week
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={PaperProps}
      >
        {Object.keys(ViewName).map(name => (
          <MenuItem key={name} value={name} disabled>
            <Checkbox checked={viewName === name} sx={{py: 0.2, pl: 0}} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const PaperProps = {
  style: {
    // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    width: 200,
  },
};
