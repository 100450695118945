export const ReportsIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g fill={'inherit'}>
        <path
          opacity={0.4}
          d="M384,128H272c-8.8,0-16-7.2-16-16V0H24C10.8,0,0,10.7,0,23.9l0,0V488c0,13.2,10.7,24,23.9,24l0,0H360
		c13.2,0,24-10.7,24-23.9l0,0V128z M136.5,438.5c-28.9-11.3-51.7-34.1-63-63c-30-77.4,17.4-150.7,86.5-164.8V352h141.3
		C287.2,421.1,213.9,468.6,136.5,438.5z M192,320V199.9c66.3,0,120.1,53.8,120.1,120.1l0,0H192z"
        />
        <path
          d="M377,105L279.1,7c-4.5-4.5-10.6-7-17-7H256v112c0,8.8,7.2,16,16,16h112v-6.1C384,115.6,381.5,109.5,377,105z
		 M160,210.7c-69.1,14.1-116.6,87.5-86.5,164.8c11.3,28.9,34.1,51.7,63,63c77.4,30.1,150.7-17.4,164.9-86.5H160V210.7z"
        />
      </g>
    </svg>
  );
};
