import {Box} from '@mui/material';
import React, {useCallback} from 'react';
import RichTextEditor, {EditorValue} from 'react-rte';
import {EditorVal, saveDescription, toolbarConfig} from '../TicketEditContent/InputDescription';

interface Props {
  editorValue: EditorValue;
  setEditorValue: React.Dispatch<React.SetStateAction<EditorValue>>;
}

export const DescriptionStep = ({editorValue, setEditorValue}: Props) => {
  const handleEditorValueChange = useCallback(
    (editorValueNew: EditorVal) => {
      setEditorValue(editorValueNew);
    },
    [editorValue, saveDescription]
  );

  return (
    <Box
      sx={{
        backgroundColor: '#f5f8fa',
        borderBottom: `2px solid #1877f2;`,
        '& .DraftEditor-root': {
          minHeight: '138px',
        },
      }}
    >
      <RichTextEditor
        value={editorValue}
        onChange={handleEditorValueChange}
        placeholder={'Enter description'}
        toolbarConfig={toolbarConfig}
        editorStyle={{
          minHeight: '138px',
          fontFamily: 'Rubik, sans-serif',
        }}
        rootStyle={{
          background: 'none',
          border: 'none',
          borderRadius: 'none',
        }}
        toolbarStyle={{
          display: 'block',
        }}
      />
    </Box>
  );
};
