export enum ContextKey {
  Mid = 'mid',
  Mids = 'mids',
  AgentId = 'agentId',
  GroupId = 'groupId',
  MonthYear = 'monthYear',
  Year = 'year',
  Month = 'month',
  DateRanges = 'dateRanges',
  FilterType = 'filterType',
  StartDateTime = 'startDateTime',
  EndDateTime = 'endDateTime',
  ClientDateTime = 'clientDateTime',
}

export type Context = {
  [_ in ContextKey]?: string | string[];
};
