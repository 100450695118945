import {
  Agent,
  AgentView,
  AsyncState,
  Collections,
  Firebase,
  selectAgentView,
  UniversalSnapshot,
} from '@ozark/common';
import {useEffect, useState} from 'react';
import {useUserInfo} from './useUserInfo';

const agentsQueryBase = Firebase.firestore
  .collection(Collections.agents)
  .where('isActive', '==', true);

export function usePortalUserAgents(userGroupId?: string, includeOneself?: boolean) {
  const {uid, isAgentAdmin, isMerchant} = useUserInfo();
  const [agents, setAgents] = useState<AsyncState<AgentView[]>>({
    promised: true,
  });

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (!uid || !userGroupId) return;
      if (isAgentAdmin && userGroupId) {
        const agents = await getGroupAgents(userGroupId);
        isMounted && setAgents({promised: false, data: agents});
      } else {
        if (isMerchant) {
          // isMerchant does not have access to the agents collection -
          const agents = await getGroupAgents(userGroupId);
          isMounted && setAgents({promised: false, data: agents});
        } else {
          const agentsForUser = await getChildAgentsByUid(uid);
          if (includeOneself) {
            const agentCurrent = await getAgentByUid(uid);
            if (agentCurrent) agentsForUser.push(agentCurrent);
          }
          isMounted && setAgents({promised: false, data: agentsForUser});
        }
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [uid, userGroupId, includeOneself]);

  return {
    agents,
  };
}

export function useErpUserAgents(groupId?: string) {
  const [agents, setAgents] = useState<AsyncState<AgentView[]>>({
    promised: true,
  });
  useEffect(() => {
    let isMounted = true;
    (groupId ? getGroupAgents(groupId) : getAllAgents()).then(
      agents => isMounted && setAgents({promised: false, data: agents})
    );
    return () => {
      isMounted = false;
    };
  }, [groupId]);

  return {
    agents,
  };
}

export function useAgents(excludeDeleted?: boolean) {
  const {uid, isErpUser, isAgentAdmin, userGroupId} = useUserInfo();
  const [agents, setAgents] = useState<AsyncState<AgentView[]>>({
    promised: true,
  });

  useEffect(() => {
    if (!uid) {
      return;
    }

    if (isErpUser) {
      getAllAgents(excludeDeleted).then(agents => setAgents({promised: false, data: agents}));
      return;
    }

    if (isAgentAdmin && userGroupId) {
      getGroupAgents(userGroupId).then(agents => setAgents({promised: false, data: agents}));
      return;
    }

    getChildAgentsByUid(uid).then(agents => setAgents({promised: false, data: agents}));
  }, [uid, isErpUser]);

  return {
    agents,
  };
}

async function getAllAgents(excludeDeleted?: boolean): Promise<AgentView[]> {
  try {
    const agentsSnap = await agentsQueryBase.get();

    if (agentsSnap.empty) {
      return [];
    }
    return agentsSnap.docs
      .map(doc => selectAgentView(doc as UniversalSnapshot<Agent>))
      .filter(a => !a.isDisabled && (!excludeDeleted || !a.deleted));
  } catch (err) {
    console.error(err);
    return [];
  }
}

async function getGroupAgents(groupId: string): Promise<AgentView[]> {
  try {
    const agentsSnap = await agentsQueryBase.where('group.id', '==', groupId).get();

    if (agentsSnap.empty) {
      return [];
    }
    return agentsSnap.docs
      .map(doc => selectAgentView(doc as UniversalSnapshot<Agent>))
      .filter(a => !a.isDisabled);
  } catch (err) {
    console.error(err);
    return [];
  }
}

export async function getAgentByUid(uid: string): Promise<AgentView | undefined> {
  try {
    if (!uid) throw new Error('getAgentByUid: invalid agent id');
    const agentsSnap = await Firebase.firestore.collection(Collections.agents).doc(uid).get();

    if (agentsSnap.exists) return selectAgentView(agentsSnap as UniversalSnapshot<Agent>);
  } catch (err) {
    console.error(err);
  }
}

async function getChildAgentsByUid(uid: string): Promise<AgentView[]> {
  try {
    const agentsSnap = await agentsQueryBase.where('masterUid', '==', uid).get();

    if (agentsSnap.empty) {
      return [];
    }
    return agentsSnap.docs
      .map(doc => selectAgentView(doc as UniversalSnapshot<Agent>))
      .filter(a => !a.isDisabled);
  } catch (err) {
    console.error(err);
    return [];
  }
}
