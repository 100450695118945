import {yupResolver} from '@hookform/resolvers/yup';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  Collections,
  Equipment,
  EquipmentDefaults,
  EquipmentFileType,
  EquipmentOptions,
  EquipmentTipMode,
  EquipmentYesOrNo,
  Firebase,
  StoragePath,
  UniversalTimestamp,
} from '@ozark/common';
import {useNotification} from '@ozark/common/hooks';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {EquipmentForm, EquipmentFormSchema} from '.';
import {EquipmentFormData, uploadLogo} from './hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      [theme.breakpoints.up('md')]: {
        width: 680,
      },
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

export const CreateDialog = ({onClose}: {onClose: () => void}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const showNotification = useNotification();

  const hookForm = useForm<EquipmentFormData>({
    resolver: yupResolver(EquipmentFormSchema),
  });

  const {handleSubmit} = hookForm;

  const onSuccess = async (data: EquipmentFormData) => {
    try {
      setLoading(true);

      if (data.image) {
        data.imageUrl = await uploadLogo(StoragePath.equipment, data.image.file);
        delete data.image;
      }

      const {
        defaultsAutoBatch,
        defaultsAutoBatchTime,
        defaultsCashDiscount,
        defaultsCashDiscountPercent,
        defaultsConnectionType,
        defaultsContactless,
        defaultsFileType,
        defaultsShippingSpeed,
        defaultsTipMode,
        ...equipment
      } = data;

      const newEquipment: Equipment = {
        ...equipment,
        programs: data.programs,
        defaults: {
          accessories: data.defaultsAccessories,
          autoBatch: data.defaultsAutoBatch,
          autoBatchTime: data.defaultsAutoBatch ? data.defaultsAutoBatchTime : undefined,
          cashDiscount: data.defaultsCashDiscount,
          cashDiscountPercent: data.defaultsCashDiscountPercent,
          connectionType: data.defaultsConnectionType,
          contactless: data.defaultsContactless,
          fileType: data.defaultsFileType,
          shippingSpeed: data.defaultsShippingSpeed,
          tipMode: data.defaultsTipMode,
        } as EquipmentDefaults,
        options: {
          accessories: data.options?.accessories || [],
          cashDiscount: data.options?.cashDiscount,
          connectionType: data.options?.connectionType,
          autoBatch: Object.entries(EquipmentYesOrNo).map(([key, value]) => value),
          contactless: Object.entries(EquipmentYesOrNo).map(([key, value]) => value),
          fileType: Object.entries(EquipmentFileType).map(([key, value]) => value),
          tipMode: Object.entries(EquipmentTipMode).map(([key, value]) => value),
        } as EquipmentOptions,
        createdAt: Firebase.Timestamp.now() as UniversalTimestamp,
      };

      await Firebase.firestore.collection(Collections.equipment).add(newEquipment);

      setLoading(false);
      onClose();
    } catch (_err) {
      showNotification('error', 'Failed to create equipment.');
      console.error(`error saving equipment`, _err);
      setLoading(false);
    }
  };

  const onError = (data: any) => {
    console.error(`error saving equipment ${JSON.stringify(data)}`);
    setLoading(false);
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="create-dialog-title"
      fullScreen={fullScreen}
      maxWidth={'lg'}
    >
      <DialogTitle id="create-dialog-title">Add New Equipment</DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          <Grid container spacing={1}>
            <EquipmentForm hookForm={hookForm} />
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit(onSuccess, onError)} color="primary" disabled={loading}>
          {loading && <CircularProgress className={classes.buttonProgress} size={24} />}
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
