export const MerchantsIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g fill={'inherit'}>
        <path
          d="M320 384H128V224H64v256c0 17.7 14.3 32 32 32h256c17.7 0 32-14.3 32-32V224h-64v160zm192-160v272c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V224h-64z"
          opacity={props.opacity || 0.4}
        />
        <path d="M634.5 142.2c14.1 21.3-1.1 49.8-26.6 49.8H32c-25.6 0-40.8-28.5-26.6-49.8l85.3-128c6-8.9 16-14.2 26.6-14.2h405.2c10.7 0 20.7 5.3 26.7 14.2l85.3 128z" />
      </g>
    </svg>
  );
};
