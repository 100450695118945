import {Box, Paper, Stack, Typography, useTheme} from '@mui/material';

export type BaseTileProp = {
  name: string;
};

export type CountTileProp = BaseTileProp & {
  count: number | string; // string for formatted values
  onClick?: () => void;
  tileProps?: any;
};

export const CountTile = ({name, count, onClick, tileProps}: CountTileProp) => {
  const theme = useTheme();

  return (
    <Box
      pt={3}
      pr={3}
      sx={{
        minWidth: theme.spacing(31),
        ...tileProps,
      }}
    >
      <Paper
        sx={{
          height: '100%',
          px: 1,
          py: 2,
          color: '#4d6575',
          borderTop: `solid 4px ${theme.palette.primary.main}`,
          cursor: onClick ? 'pointer' : 'default',
        }}
        onClick={onClick}
      >
        <Stack p={1}>
          <Typography variant={'h5'} noWrap gutterBottom textAlign="center">
            {count || '-'}
          </Typography>
          <Typography variant={'body2'} noWrap textAlign="center">
            {name}
          </Typography>
        </Stack>
      </Paper>
    </Box>
  );
};
