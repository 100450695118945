import {ResetPassword as CommonResetPassword} from '@ozark/common/components';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';

export const ResetPassword = () => {
  const {group} = useStore();
  return (
    <CommonResetPassword
      groupName={group.data?.name}
      groupLogoUrl={group.data?.logoUrl}
      groupDomain={group.data?.portalDomain}
      loginPageUrl={ROUTES.LOGIN}
    />
  );
};
