import {AgentView} from '@ozark/functions/src/documents';
import {useEffect, useState} from 'react';
import {AsyncState} from '../../models';
import {AutoCompleteInputBase} from '../AutoComplete';

type Props = {
  onAgentSelect: (agent?: AgentView) => void;
  agents: AsyncState<AgentView[]>;
  selectedAgent?: AgentView;
  showGroupName?: boolean;
  width?: number;
};

export const AgentSelect = ({
  agents,
  onAgentSelect,
  selectedAgent,
  showGroupName,
  width,
}: Props) => {
  const [activeAgents, setActiveAgents] = useState<AgentView[]>([]);

  useEffect(() => {
    if (!agents.data) {
      return;
    }

    setActiveAgents(agents.data.filter(agent => agent.isActive));
  }, [agents]);

  const getDropDownTitle = (agent?: AgentView): string => {
    const agentGroupName = showGroupName ? agent?.group?.name : '';
    const agentGroupNameDisplay = agentGroupName ? `(${agentGroupName})` : '';

    return agent
      ? `${agent?.firstName || ''} ${agent?.lastName || ''} ${agentGroupNameDisplay || ''}`.trim()
      : 'All Agents';
  };

  return (
    <AutoCompleteInputBase
      selected={selectedAgent}
      setSelected={onAgentSelect}
      icon
      placeholder="Select agent..."
      width={width ?? 200}
      options={activeAgents}
      getOptionLabel={(agent: AgentView) => getDropDownTitle(agent)}
      onItemSelect={(agent: AgentView | null) => onAgentSelect(agent ?? undefined)}
    />
  );
};
