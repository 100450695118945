import {Resource, ResourceInput, ResourceView, ResourceViewableByUsers} from '@ozark/common';
import {ResourcesApplicationType} from './types';

export const getPreviewUrl = (resource?: ResourceView) => {
  if (!resource || !resource.cloudPreviewImagePath) {
    return undefined;
  }

  return resource.cloudPreviewImagePath.length > 0 && resource.downloadPreviewUrl
    ? resource.downloadPreviewUrl
    : undefined;
};

export const setGroupsAndViewableBy = (
  appType: ResourcesApplicationType,
  data: Partial<ResourceInput>,
  existingData: Resource
) => {
  if (appType === ResourcesApplicationType.erp) {
    data.groups = data.groups ?? [];
  } else {
    data.groups = existingData.groups;
  }

  if (data.viewableByUsers === existingData.viewableByUsers) {
    return;
  }
  const value = data.viewableByUsers;

  let updatedViewableBy: ResourceViewableByUsers[] | undefined;
  if (appType === ResourcesApplicationType.erp) {
    updatedViewableBy = value; // ERP: update viewableBy
  } else {
    if (existingData!.shared) {
      updatedViewableBy = existingData!.viewableByUsers; // Portal: don't update for ERP resource
    } else {
      updatedViewableBy = value; // Portal: update viewableBy
    }
  }

  data.viewableByUsers = updatedViewableBy;
};
