import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {useState} from 'react';
import {useUserInfo, ViewableByType} from '../../../..';
import {AssigneeEditorDialog} from '../Assignee/AssigneeEditorDialog';

interface Props {
  viewableBy: ViewableByType | undefined;
  setViewableBy: (viewableBy: ViewableByType) => void;
  isEditingDisabled: boolean;
  disableUnderline?: boolean;
  showAssigneeEditorDialogOnChange?: (newValue: ViewableByType) => boolean;
}

const warningMessageErpOnly =
  'Please assign the ticket to an ERP Department or ERP User before updating the "Viewable By"';

const warningMessageErpOrAgentOnly =
  'Please assign the ticket to an ERP Department or ERP User or Agent before updating the "Viewable By"';

export const InputViewableBy = ({
  viewableBy,
  setViewableBy,
  isEditingDisabled,
  disableUnderline,
  showAssigneeEditorDialogOnChange,
}: Props) => {
  const {isErpUser} = useUserInfo();
  const [open, setOpen] = useState(false);
  const [warningMsg, setWarningMsg] = useState(warningMessageErpOnly);
  const handleChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value as ViewableByType;
    if (showAssigneeEditorDialogOnChange && showAssigneeEditorDialogOnChange(newValue)) {
      setOpen(true);
      setWarningMsg(
        newValue === ViewableByType.internalOnly
          ? warningMessageErpOnly
          : warningMessageErpOrAgentOnly
      );
      return;
    }
    setViewableBy(newValue);
  };

  return (
    <>
      {showAssigneeEditorDialogOnChange && (
        <AssigneeEditorDialog open={open} setOpen={setOpen} warningMessage={warningMsg} />
      )}
      <FormControl fullWidth>
        <Select
          disabled={isEditingDisabled}
          labelId="label-viewable-by"
          id="viewable-by"
          value={viewableBy ?? ''}
          label="Viewable By"
          onChange={handleChange}
          disableUnderline={disableUnderline}
          variant="filled"
          sx={{
            '&.MuiFilledInput-root': {
              backgroundColor: 'rgba(0, 0, 0, 0)',
              borderBottomLeftRadius: '4px',
              borderBottomRightRadius: '4px',
              ml: -1,
            },
            '&.MuiFilledInput-root:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.09)',
            },
            '& .MuiSelect-select': {
              pt: 1,
            },
          }}
        >
          <MenuItem value={ViewableByType.agentOrErp}>{ViewableByType.agentOrErp}</MenuItem>
          <MenuItem value={ViewableByType.all}>{ViewableByType.all}</MenuItem>
          {isErpUser && (
            <MenuItem value={ViewableByType.internalOnly}>{ViewableByType.internalOnly}</MenuItem>
          )}
        </Select>
      </FormControl>
    </>
  );
};
