import {
  selectTicketTypeView,
  TicketType,
  TicketTypeView,
} from '@ozark/functions/src/documents/TicketType';
import {useEffect, useState} from 'react';
import {AsyncState, Collections, Firebase, UniversalSnapshot, useNotification} from '../../..';

const statePromised: AsyncState<TicketTypeView[]> = {
  promised: true,
  data: [],
};

export function useTicketTypes() {
  const [ticketTypes, setTicketTypes] = useState(statePromised);
  const showNotification = useNotification();

  useEffect(() => {
    let query = Firebase.firestore
      .collection(Collections.ticketTypes)
      .orderBy('isDeleted', 'asc')
      .orderBy('name', 'asc');

    return query.onSnapshot(
      async snapshot => {
        if (snapshot.size === 0) {
          setTicketTypes({promised: false, data: []});
          return;
        }
        const ticketTypes = snapshot.docs.map(doc =>
          selectTicketTypeView(doc as UniversalSnapshot<TicketType>)
        );
        setTicketTypes({promised: false, data: ticketTypes});
      },
      err => {
        showNotification('error', err.message);
        setTicketTypes({promised: false, error: err});
      }
    );
  }, []);

  return {
    ticketTypes,
  };
}
