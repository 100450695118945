import NotificationSound from '../../../static/media/notification-sound.mp3';

const audio = new Audio(NotificationSound);

export const useNotificationSound = () => {
  /* According to the Chrome policy, the sound will not play if a user doesn't interact with the UI yet (click, tab, etc.)
   * Read more https://developer.chrome.com/blog/autoplay/ */
  const playNotificationSound = () => {
    (audio.cloneNode(true) as HTMLAudioElement).play().catch(() => {
      console.log(
        'Failed to play sound. Seems it was blocked by browser policy since a user must interact with the page first'
      );
    });
  };

  return {playNotificationSound};
};
