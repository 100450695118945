/* eslint-disable react-hooks/exhaustive-deps */
import {
  AsyncState,
  AuthUserClaims,
  Firebase,
  useCallable,
  useGroupFromDomain,
  useLastLogin,
  UserRoles,
} from '@ozark/common';
import event from '@ozark/common/event';
import firebase from 'firebase/compat/app';
import LogRocket from 'logrocket';
import {useCallback, useEffect, useState} from 'react';

export const useAuthUser = (groupStore: ReturnType<typeof useGroupFromDomain>) => {
  const {saveLastLoginDate} = useLastLogin();
  const [authUser, setAuthUser] = useState<AsyncState<firebase.User | null>>({promised: true});
  const [claims, setClaims] = useState<AuthUserClaims | null>();
  const [loginEvent] = useState(event<firebase.User>());
  const [logoutEvent] = useState(event<firebase.User>());
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [onLogin, emitLogin] = loginEvent;
  const [onLogout, emitLogout] = logoutEvent;
  const {group} = groupStore;
  const {mfaLoginUsePass} = useCallable();

  async function checkUserClaims(user: firebase.User | null) {
    if (!user) {
      setIsAuthenticated(false);
      setAuthUser({promised: false, data: null});
      setClaims(null);
      return false;
    }

    const token = await user?.getIdTokenResult();

    if (!token) {
      logout();
      return false;
    }
    const {groupId, groupRole, role, isGroupOwner, isSiteJump} = token.claims;

    if (role === UserRoles.merchant) {
      setIsAuthenticated(true);
      setAuthUser({promised: false, data: user});
      setClaims({groupId, role, isSiteJump});
      return true;
    }

    if (role === UserRoles.agent && groupId && groupRole && group.data) {
      if (group.data.id === groupId) {
        setIsAuthenticated(true);
        setAuthUser({promised: false, data: user});
        setClaims({groupId, groupRole, role, isGroupOwner, isSiteJump});
        return true;
      } else {
        console.error(
          `Agent's group id ${group.data.id} does not match with domain's group id ${groupId}`
        );
      }
    }

    logout();
    return false;
  }

  // -----------
  // Watch for auth state changes
  // Fire login/logout events as needed
  useEffect(() => {
    if (group.promised) return;
    const unsubs = [
      Firebase.auth.onIdTokenChanged(async updatedUser => {
        await checkUserClaims(updatedUser);
        if (updatedUser && !authUser.data) {
          emitLogin(updatedUser);
        }
        if (!updatedUser && authUser.data) {
          emitLogout(authUser.data);
        }
      }),
    ];
    return () => unsubs[0]();
  }, [authUser.promised, authUser.data, group.promised, group.data]);

  useEffect(() => {
    if (authUser.data?.uid) {
      LogRocket.identify(authUser.data?.uid, {
        name: authUser.data.displayName || '',
        email: authUser.data.email || '',
      });
    }
  }, [authUser.promised]);

  const login = async (email: string, password: string) => {
    try {
      const credential = await Firebase.auth.signInWithEmailAndPassword(email, password);
      const ok = await checkUserClaims(credential.user);
      if (!ok) {
        throw new Error();
      }
      saveLastLoginDate(credential.user);
    } catch (err) {
      console.error(err);
      throw new Error();
    }
  };

  const mfaLogin = async (email: string, password: string) => {
    try {
      const mfaLoginResult = await mfaLoginUsePass({
        email: email,
        password: password,
        firebaseApiKey: Firebase.apiKey,
      });
      if (mfaLoginResult.status !== 'ok') {
        throw new Error('Unable to login');
      }
      return mfaLoginResult;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const loginWithSiteJumpToken = async (token: string) => {
    try {
      const credential = await Firebase.auth.signInWithCustomToken(token);
      const ok = await checkUserClaims(credential.user);
      if (!ok) {
        throw new Error();
      }
    } catch (err) {
      console.error(err);
      throw new Error();
    }
  };

  const logout = useCallback(() => {
    return Firebase.auth.signOut();
  }, []);

  return {
    authUser,
    claims,
    isAuthenticated,
    login,
    logout,
    onLogin,
    onLogout,
    loginWithSiteJumpToken,
    mfaLogin,
  };
};
