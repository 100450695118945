import VisibilityIcon from '@mui/icons-material/Visibility';
import {ToggleButton, Tooltip} from '@mui/material';
import {Collections} from '@ozark/common';
import {Watcher} from '@ozark/functions/src/documents/Watcher';
import {useEffect, useState} from 'react';
import {Firebase} from '../../../firebase';
import {useUserInfo} from '../../../hooks/useUserInfo';
import {useTicketId} from '../hooks/useTicketId';

export const WatchingToggle = (): JSX.Element | null => {
  const {ticketId} = useTicketId();
  const [isWatching, setIsWatching] = useState<boolean | null>(null);
  const {uid, role} = useUserInfo();

  useEffect(() => {
    if (!uid) {
      return;
    }

    return Firebase.firestore
      .collection(Collections.tickets)
      .doc(ticketId)
      .collection(Collections.watchers)
      .doc(uid)
      .onSnapshot(snapshot => {
        setIsWatching(snapshot.exists);
      });
  }, [uid, ticketId]);

  const startWatching = async () => {
    if (!uid || !role) {
      return;
    }

    try {
      const watcher: Watcher = {
        uid,
        role,
      };

      await Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .collection(Collections.watchers)
        .doc(uid)
        .set(watcher);
    } catch (error) {
      console.error('Failed to cancel watching of the ticket with an error', error);
    }
  };

  const cancelWatching = async () => {
    if (!uid) {
      return;
    }

    try {
      await Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .collection(Collections.watchers)
        .doc(uid)
        .delete();
    } catch (error) {
      console.error('Failed to cancel watching of the ticket with an error', error);
    }
  };

  const onToggleWatching = () => {
    if (isWatching) {
      cancelWatching();
    } else {
      startWatching();
    }
  };

  if (isWatching === null) {
    return null;
  }

  return (
    <Tooltip
      title={
        isWatching
          ? 'You are watching changes in the ticket'
          : 'Click to start getting notifications about changes in the ticket'
      }
    >
      <ToggleButton
        value="check"
        selected={isWatching}
        onChange={onToggleWatching}
        sx={{
          border: 0,
          borderRadius: '50%',
          mr: 1,
          '&.Mui-selected': {background: 'none', color: theme => theme.palette.primary.main},
        }}
      >
        <VisibilityIcon />
      </ToggleButton>
    </Tooltip>
  );
};
