import {formatAsPercent, formatterCurrency, TrailingAgentVolume} from '@ozark/common';
import {Column} from '@ozark/common/api/Column';
import {SearchCriteria} from '@ozark/common/api/SearchCriteria';
import {Table} from '@ozark/common/components';
import {useEffect, useState} from 'react';
import {EstimatedVsActualVolumeTableCell} from './EstimatedVsActualVolumeTableCell';

type PropSorted = Extract<
  keyof TableRow,
  | 'estimatedMonthlyCreditCardSalesVisaMcDisc'
  | 'estimatedMonthlyCreditCardSalesAmex'
  | 'salesAmountVisaMcDiscThirtyDay'
  | 'visaMcDiscMonthlyActualVsEstimated'
  | 'salesAmountAmexThirtyDay'
  | 'amexMonthlyActualVsEstimated'
  | 'salesAmountVisaMcDiscYTD'
  | 'visaMcDiscYtdActualVsEstimated'
  | 'salesAmountAmexYTD'
  | 'amexYtdActualVsEstimated'
>;

type Props = {
  data: TrailingAgentVolume[];
};

type TableRow = TrailingAgentVolume & {
  visaMcDiscMonthlyActualVsEstimated: number;
  amexMonthlyActualVsEstimated: number;
  visaMcDiscYtdActualVsEstimated: number;
  amexYtdActualVsEstimated: number;
};

export const calculateRow = (row: TrailingAgentVolume): TableRow => ({
  ...row,
  visaMcDiscMonthlyActualVsEstimated:
    row.salesAmountVisaMcDiscThirtyDay - row.estimatedMonthlyCreditCardSalesVisaMcDisc,
  amexMonthlyActualVsEstimated:
    row.salesAmountAmexThirtyDay - row.estimatedMonthlyCreditCardSalesAmex,
  visaMcDiscYtdActualVsEstimated:
    row.salesAmountVisaMcDiscYTD /
    (row.estimatedMonthlyCreditCardSalesVisaMcDisc === 0
      ? 1
      : row.estimatedMonthlyCreditCardSalesVisaMcDisc * 12),
  amexYtdActualVsEstimated:
    row.salesAmountAmexYTD /
    (row.estimatedMonthlyCreditCardSalesAmex === 0
      ? 1
      : row.estimatedMonthlyCreditCardSalesAmex * 12),
});

export const EstimatedVsActualVolumeTable = ({data}: Props) => {
  const [sort, setSort] = useState<[PropSorted, 'ASC' | 'DESC'][]>([]);
  const [dataSorted, setDataSorted] = useState<TableRow[]>([]);

  const handleRetrieveData = (searchCriteria: SearchCriteria) => {
    setSort([
      [searchCriteria.orderBy as PropSorted, searchCriteria.order.toUpperCase() as 'ASC' | 'DESC'],
    ]);

    const prop = searchCriteria.orderBy as PropSorted;
    const result = dataSorted.sort((a, b) =>
      searchCriteria.order === 'desc' ? a[prop] - b[prop] : b[prop] - a[prop]
    );

    setDataSorted(result);
  };

  useEffect(() => {
    const tableData = data.map(calculateRow);
    setDataSorted(tableData);
  }, [data]);

  return (
    <Table
      columns={getColumnsData() as Column<TableRow>[]}
      data={{
        sort,
        data: dataSorted,
        limit: 10,
        offset: 0,
        totalCount: 10,
      }}
      onRetrieveData={handleRetrieveData}
      flat
      stickyHeader
    />
  );
};

const getColumnsData = () => {
  return [
    {
      id: 'name',
      numeric: false,
      sortable: false,
      label: 'Agent Name',
      width: 100,
      selector: (row: TableRow) => row.firstName + ' ' + row.lastName,
    },
    {
      id: 'mid',
      numeric: false,
      sortable: false,
      label: 'MID',
    },
    {
      id: 'dba',
      numeric: false,
      sortable: false,
      label: 'DBA',
    },
    {
      id: 'estimatedMonthlyCreditCardSalesVisaMcDisc',
      numeric: true,
      sortable: true,
      width: 80,
      label: 'Estimated Monthly V/MC/DISC Sales',
      selector: (row: TableRow) =>
        formatterCurrency.format(Number(row.estimatedMonthlyCreditCardSalesVisaMcDisc)),
    },
    {
      id: 'estimatedMonthlyCreditCardSalesAmex',
      numeric: true,
      width: 80,
      sortable: true,
      label: 'Estimated Monthly AMEX Sales',
      selector: (row: TableRow) =>
        formatterCurrency.format(Number(row.estimatedMonthlyCreditCardSalesAmex)),
    },
    {
      id: 'salesAmountVisaMcDiscThirtyDay',
      numeric: true,
      sortable: true,
      label: '30 Day V/MC/DISC Sales',
      selector: (row: TableRow) =>
        formatterCurrency.format(Number(row.salesAmountVisaMcDiscThirtyDay)),
    },
    {
      id: 'visaMcDiscMonthlyActualVsEstimated',
      numeric: true,
      sortable: true,
      label: '+/-30 Day V/MC/DISC Actual vs Estimated',
      selector: (row: TableRow) => (
        <EstimatedVsActualVolumeTableCell value={row.visaMcDiscMonthlyActualVsEstimated} />
      ),
    },
    {
      id: 'salesAmountAmexThirtyDay',
      numeric: true,
      sortable: true,
      label: '30 Day AMEX Sales',
      selector: (row: TableRow) => formatterCurrency.format(Number(row.salesAmountAmexThirtyDay)),
    },
    {
      id: 'amexMonthlyActualVsEstimated',
      numeric: true,
      sortable: true,
      label: '+/-30 Day AMEX Actual vs Estimated',
      selector: (row: TableRow) => (
        <EstimatedVsActualVolumeTableCell value={row.amexMonthlyActualVsEstimated} />
      ),
    },
    {
      id: 'salesAmountVisaMcDiscYTD',
      numeric: true,
      sortable: true,
      label: 'Year to date V/MC/DISC Sales',
      selector: (row: TableRow) => formatterCurrency.format(Number(row.salesAmountVisaMcDiscYTD)),
    },
    {
      id: 'visaMcDiscYtdActualVsEstimated',
      numeric: true,
      sortable: true,
      label: '% of YTD V/MC/DISC Transaction Volume / Annual',
      selector: (row: TableRow) => formatAsPercent(row.visaMcDiscYtdActualVsEstimated),
    },
    {
      id: 'salesAmountAmexYTD',
      numeric: true,
      sortable: true,
      label: 'Year to date AMEX Sales',
      selector: (row: TableRow) => formatterCurrency.format(Number(row.salesAmountAmexYTD)),
    },
    {
      id: 'amexYtdActualVsEstimated',
      numeric: true,
      sortable: true,
      label: '% of YTD AMEX Transaction Volume / Annual',
      selector: (row: TableRow) => formatAsPercent(row.amexYtdActualVsEstimated),
    },
    {
      id: 'estimatedAverageSale',
      numeric: true,
      sortable: true,
      label: 'Estimated Average Sale',
      selector: (row: TableRow) => formatterCurrency.format(Number(row.estimatedAverageSale)),
    },
    {
      id: 'averageActualSaleThirdayDay',
      numeric: true,
      sortable: true,
      label: '30 Day Average Sale',
      selector: (row: TableRow) =>
        formatterCurrency.format(Number(row.averageActualSaleThirdayDay)),
    },
    {
      id: 'averageActualSaleYTD',
      numeric: true,
      sortable: true,
      label: 'Year to date Average Sale',
      selector: (row: TableRow) => formatterCurrency.format(Number(row.averageActualSaleYTD)),
    },
  ];
};
