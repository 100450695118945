import * as yup from 'yup';
import {invalidPasswordText, isMatch, isValidPassword, matchValidationText} from '../helpers';

export const getSetPasswordShape = <T>(
  passwordFieldName: keyof T,
  passwordConfirmationFieldName: keyof T
) => {
  return {
    [passwordFieldName]: yup
      .string()
      .test({
        name: 'isValidPassword',
        exclusive: true,
        message: invalidPasswordText,
        test: value => isValidPassword(value),
      })
      .required(),
    [passwordConfirmationFieldName]: yup.string().test({
      name: 'isMatchPassword',
      exclusive: true,
      message: matchValidationText,
      test: function (value) {
        return isMatch(this.parent[passwordFieldName], value);
      },
    }),
  };
};
