import {FilterOption} from '@ozark/common/components';

export const DetailedResidualsFilters: FilterOption[] = [
  {
    id: 'mid',
    column: 'mid',
    label: 'MID',
    type: 'text',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'dba',
    column: 'dba',
    label: 'DBA',
    type: 'text',
    operators: [
      {
        id: '__like',
        label: 'contains',
      },
    ],
  },
  {
    id: 'yearMonth',
    column: 'yearMonth',
    label: 'Date',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
];
