import {sortBy} from 'lodash';
import {useEffect, useState} from 'react';
import {ApplicationView, ShippingType} from '../../..';
import {useApplicationAddressAgent} from './useApplicationAddressAgent';

type ShippingAddressOption = {
  key: ShippingType;
  value: string;
};

const formatAddress = (
  prefix: string,
  adr1: string,
  adr2: string | undefined,
  city: string,
  state: string,
  zip: string
) => `${prefix}: ${adr1}${adr2 ? ' ' + adr2 : ''}, ${city}, ${state}, ${zip}`;

export function useApplicationShippingAddresses(application?: ApplicationView) {
  const {agentAddress} = useApplicationAddressAgent(application);
  const [shippingAddresses, setShippingAddresses] = useState<ShippingAddressOption[]>([]);

  useEffect(() => {
    let isMounted = true;
    if (!application) return;

    const options = [
      {
        key: ShippingType.other,
        value: 'Other',
      },
      {
        key: ShippingType.localPickup,
        value: 'Local Pickup',
      },
      {
        key: ShippingType.businessAddress,
        value: formatAddress(
          'Business Address',
          application?.businessAddress1,
          application?.businessAddress2,
          application?.businessCity,
          application?.businessState,
          application?.businessZip
        ),
      },
      {
        key: ShippingType.homeAddress,
        value: formatAddress(
          'Home Address',
          application?.homeAddress1,
          application?.homeAddress2,
          application?.homeCity,
          application?.homeState,
          application?.homeZip
        ),
      },
    ];

    if (application.mailingAddressDifferent) {
      options.push({
        key: ShippingType.mailingAddress,
        value: formatAddress(
          'Mailing Address',
          application?.mailingAddress1,
          application?.mailingAddress2,
          application?.mailingCity,
          application?.mailingState,
          application?.mailingZip
        ),
      });
    }

    if (agentAddress?.address) {
      options.push({
        key: ShippingType.agentAddress,
        value: formatAddress(
          'Agent Address',
          agentAddress.address,
          agentAddress.address2,
          agentAddress.city,
          agentAddress.state,
          agentAddress.zipCode
        ),
      });
    }

    isMounted && setShippingAddresses(sortBy(options, ['key']));

    return () => {
      isMounted = false;
    };
  }, [application?.id, agentAddress?.address]);

  return {
    shippingAddresses,
    agentAddress,
  };
}
