import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  TextField,
} from '@mui/material';
import {memo, useEffect, useState} from 'react';

type Props = {
  dialogTitle: string;
  okTitle: string;
  open: boolean;
  onClose(): void;
  onOk: (description: string) => void;
  defaultValue: string;
};

export const EquipmentDescriptionDialog = memo(
  ({dialogTitle, okTitle, defaultValue, open, onClose, onOk}: Props) => {
    const [description, setDescription] = useState(defaultValue ?? '');
    const [error, setError] = useState('');

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (description) {
        onOk(description);
        setDescription('');
      } else {
        setError('Equipment description is required');
      }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setError('');
      setDescription(e.target.value);
    };

    useEffect(() => {
      if (!description && defaultValue) {
        setDescription(defaultValue);
      }
    }, [defaultValue]);

    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="add-equipment-dialog-title"
        maxWidth={'lg'}
      >
        <form onSubmit={onSubmit} autoComplete="off">
          <DialogTitle id="add-equipment-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContentStyled>
            <Box py={3} px={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="description"
                label="Equipment Description"
                value={description}
                onChange={handleChange}
                error={Boolean(error)}
                helperText={error}
                autoFocus
              />
            </Box>
          </DialogContentStyled>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button color="primary" type="submit">
              {okTitle}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
);

const DialogContentStyled = styled(DialogContent)({
  width: 500,
  minHeight: 130,
});
