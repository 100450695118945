import PhoneNumber from 'awesome-phonenumber';
import {parse} from 'date-fns';
import {
  getSearchTokens,
  getWorkflow,
  Platforms,
  SearchableTokens,
  UserRoles,
  Workflow,
} from '../constants';
import {AuditableDocument} from './Audit';
import {UniversalTimestamp} from './compat';
import {UserMFASettings} from './Multifactor';
import {SnoozeNotificationTime} from './Notification';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export const WorkingHoursTimeFormat = 'hh:mm aa';
export type WorkingHoursTime = string | null; //expected format is hh:mm aa
export type WorkingHours = {
  mondayStart?: WorkingHoursTime;
  mondayEnd?: WorkingHoursTime;
  tuesdayStart?: WorkingHoursTime;
  tuesdayEnd?: WorkingHoursTime;
  wednesdayStart?: WorkingHoursTime;
  wednesdayEnd?: WorkingHoursTime;
  thursdayStart?: WorkingHoursTime;
  thursdayEnd?: WorkingHoursTime;
  fridayStart?: WorkingHoursTime;
  fridayEnd?: WorkingHoursTime;
  saturdayStart?: WorkingHoursTime;
  saturdayEnd?: WorkingHoursTime;
  sundayStart?: WorkingHoursTime;
  sundayEnd?: WorkingHoursTime;
  timeZoneId?: string;
};
export type Profile = AuditableDocument &
  SnoozeNotificationTime & {
    firstName: string;
    lastName: string;
    email: string;
    photoUrl: string;
    cellPhone: string;
    role: UserRoles;
    phoneNumber?: string;
    phoneExt?: string;
    createdAt: UniversalTimestamp;
    lastLoginAt: UniversalTimestamp;
    isActive: boolean;
    jobTitle: string;
    department?: string;
    platform?: Platforms;
    associatedAgents?: string[];
    workingHours?: WorkingHours;
    mfa?: UserMFASettings;
  };

export type ProfileView = ViewBase<Profile> & {
  uid: string;
  displayName: string;
  workflow?: Workflow;
};

export type SearchableProfileView = ProfileView & SearchableTokens;

export const selectProfileView: ViewSelector<ProfileView, Profile> = snap => {
  const data = snap.data();
  const profile: ProfileView = {
    ...defaultViewSelector(snap),
    uid: snap.id,
    cellPhone: data.cellPhone
      ? new PhoneNumber(data.cellPhone, 'US').getNumber('national')
      : data.cellPhone,
    phoneNumber: data.phoneNumber
      ? new PhoneNumber(data.phoneNumber, 'US').getNumber('national')
      : data.phoneNumber,
    displayName: `${data.firstName} ${data.lastName}`,
    workflow: getWorkflow(data.role),
  };
  return profile;
};

export const selectSearchableProfileView: ViewSelector<SearchableProfileView, Profile> = snap => {
  const rawProfile = snap.data();
  const fieldValues = [
    rawProfile.firstName,
    rawProfile.lastName,
    rawProfile.cellPhone,
    rawProfile.phoneNumber,
    rawProfile.phoneExt,
    rawProfile.email,
    rawProfile.role,
    rawProfile.jobTitle,
    rawProfile.department,
    rawProfile.isActive ? 'active' : 'disabled',
  ];
  const searchTokens = getSearchTokens(fieldValues);
  const profile: SearchableProfileView = {
    ...selectProfileView(snap),
    searchTokens,
  };
  return profile;
};

export const parseWorkingHoursString = (
  timeString: WorkingHoursTime | undefined,
  logError: boolean
) => {
  if (!timeString) {
    return null;
  }

  try {
    const value = parse(timeString, WorkingHoursTimeFormat, new Date());
    if (!isNaN(value.getTime())) {
      return value;
    }
  } catch {}
  if (logError) {
    console.error(`Invalid time string: ${timeString}`);
  }
  return null; //we are here in case if we have bad data
};
