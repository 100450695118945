const resolveProduction = () => {
  if (process.env.FIREBASE_CONFIG) {
    try {
      const projectId = JSON.parse(process.env.FIREBASE_CONFIG).projectId;

      return ['ozark-production', 'getevolved-production'].includes(projectId);
    } catch (err: any) {
      return false;
    }
  }
  return false;
};

export const resolveNumberOfInstances = (productionInstanceCount: number) => {
  if (process.env.FIREBASE_CONFIG) {
    try {
      const projectId = JSON.parse(process.env.FIREBASE_CONFIG).projectId;

      switch (projectId) {
        case 'ozark-production':
        case 'getevolved-production':
          return productionInstanceCount;
        case 'ozark-staging':
        case 'getevolved-staging':
          return 1;
        default:
          return 0;
      }
    } catch (err: any) {
      return 0;
    }
  }

  return 0;
};

export const isEvolveEnvironment = (projectId?: string) => {
  const ids = ['getevolved-production', 'getevolved-staging'];

  if (projectId) {
    return ids.includes(projectId);
  }

  if (process.env.FIREBASE_CONFIG) {
    try {
      const projectId = JSON.parse(process.env.FIREBASE_CONFIG).projectId;

      return ids.includes(projectId);
    } catch (err: any) {
      return false;
    }
  }

  return false;
};

export const isLuqraEnvironment = (projectId?: string) => {
  const ids = ['ozark-production', 'ozark-staging'];

  if (projectId) {
    return ids.includes(projectId);
  }

  if (process.env.FIREBASE_CONFIG) {
    try {
      const projectId = JSON.parse(process.env.FIREBASE_CONFIG).projectId;

      return ids.includes(projectId);
    } catch (err: any) {
      return false;
    }
  }

  return false;
};

export const isProduction = resolveProduction();
