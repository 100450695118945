import {Breadcrumbs, Divider, Grid} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {SxProps} from '@mui/system';
import React, {ReactElement} from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      minHeight: theme.spacing(8),
      color: '#586069',
      padding: theme.spacing(0, 1, 0, 0),
    },
    space: {
      flexGrow: 1,
    },
    divider: {
      margin: theme.spacing(0, 0, 2),
    },
  })
);

type Props = {
  breadcrumbs: React.ReactNode[];
  children?: React.ReactNode;
  noBorder?: boolean;
  noMargins?: boolean;
  sx?: SxProps;
};

export const Title = ({breadcrumbs, children, noBorder, noMargins, sx}: Props) => {
  const classes = useStyles();
  const _breadcrumbs = breadcrumbs.filter(e => e !== null) as ReactElement[];
  return (
    <>
      <Grid
        className={classes.actions}
        container
        justifyContent="center"
        alignItems="center"
        sx={sx}
      >
        {breadcrumbs && (
          <Breadcrumbs aria-label="breadcrumb">
            {_breadcrumbs.map((e: ReactElement, i: any) => React.cloneElement(e, {key: i}))}
          </Breadcrumbs>
        )}
        <div className={classes.space} />
        {children}
      </Grid>
      {!noBorder && <Divider className={noMargins ? '' : classes.divider} />}
    </>
  );
};
