export const BoardedIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g fill={'inherit'}>
        <path
          d="M512,128v360.1c0,13.2-10.8,23.9-24,23.9c0,0,0,0,0,0H224v-91.2c17.5-7.5,30.6-22.7,35.5-41.1
		c0.4-1.4,0.7-2.7,1-3.9c0.7-2.6,1.4-5.4,1.9-7c1.1-1.2,3.1-3.2,4.9-5.1l2.9-2.9c15.3-15.7,21.3-38.2,15.8-59.4
		c-0.4-1.4-0.7-2.7-1-3.9c-0.7-2.6-1.5-5.5-1.8-7.2c0.4-1.7,1.2-4.6,1.9-7.2c0.3-1.2,0.6-2.5,1-3.8c5.5-21.1-0.4-43.6-15.6-59.3
		l-2.8-2.8c-1.9-1.9-3.9-3.9-5-5.2c-0.5-1.6-1.2-4.5-1.9-7.1c-0.3-1.2-0.6-2.5-1-3.9c-5.6-21.2-22-37.8-43.1-43.7h-0.1l-3.8-1
		c-2.4-0.7-5.1-1.4-6.8-1.9c-1.2-1.2-3.2-3.2-5-5.1c-0.8-0.9-1.7-1.8-2.7-2.8c-15.4-15.8-38.2-22.1-59.5-16.3h-0.1l-3.8,1
		c-2.4,0.7-5,1.4-6.7,1.8V23.9c0-13.1,10.6-23.8,23.8-23.9h232v112c0,8.8,7.2,16,16,16H512z"
          opacity={0.4}
        />
        <path
          d="M505,105L407.1,7c-4.5-4.5-10.6-7-17-7H384v112c0,8.8,7.2,16,16,16h112v-6.1C512,115.6,509.5,109.5,505,105z
		 M255,271.1c2.7-10.3-0.2-21.2-7.6-28.8c-14.9-15.1-13.4-12.6-18.9-33.3c-2.7-10.2-10.6-18.2-20.7-21c-20.3-5.5-17.8-4.1-32.7-19.2
		c-7.3-7.5-18.1-10.5-28.3-7.8c-20.3,5.5-17.5,5.5-37.8,0c-10.1-2.7-20.9,0.2-28.3,7.7c-14.9,15.2-12.5,13.7-32.7,19.2
		C38,190.7,30.1,198.8,27.5,209c-5.5,20.7-4,18.1-18.9,33.3c-7.4,7.6-10.3,18.5-7.6,28.8c5.4,20.7,5.4,17.8,0,38.4
		c-2.7,10.3,0.2,21.2,7.6,28.8c14.9,15.1,13.4,12.6,18.9,33.3c2.6,10.2,10.5,18.3,20.7,21.1c14.3,3.9,11.5,3,15.8,5V512l64-32l64,32
		V397.6c4.3-2,1.5-1.1,15.8-5c10.2-2.8,18.1-10.9,20.7-21.1c5.5-20.7,4-18.1,18.9-33.3c7.4-7.6,10.3-18.5,7.6-28.8
		C249.6,288.9,249.6,291.8,255,271.1L255,271.1z M128,352c-35.3,0-64-28.7-64-64s28.7-64,64-64s64,28.7,64,64S163.3,352,128,352z"
        />
      </g>
    </svg>
  );
};
