import {Tooltip, Typography} from '@mui/material';
import {
  ResourceView,
  ResourceViewableByUsersNames,
  ResourceViewableByUsersPortalNames,
} from '@ozark/common';
import {ResourcesApplicationType} from '../types';
import {styles} from './constants';
import {useDisplayContext} from './DisplayContext';

interface Props {
  resourceView: ResourceView;
}

export const DisplayViewableBy = ({resourceView}: Props) => {
  const {appType} = useDisplayContext();
  const resourceNames =
    appType === ResourcesApplicationType.erp
      ? ResourceViewableByUsersNames
      : ResourceViewableByUsersPortalNames;

  const text = resourceView.viewableByUsers
    ?.map(x => resourceNames[x])
    .filter(x => !!x)
    .join(', ');

  return (
    <Tooltip title={text}>
      <Typography variant="caption" sx={styles.resourceFont}>
        {text}
      </Typography>
    </Tooltip>
  );
};
