import {YesNo} from '@ozark/common';
import {getSortedKeys} from '../util';
import {useHotKeyHolder} from './useHotKeyHolder';

interface TextAndValue {
  text: string;
  value: any;
}

export const VAL_TRUE: TextAndValue = {
  text: 'Yes',
  value: YesNo.yes,
};
export const VAL_FALSE: TextAndValue = {
  text: 'No',
  value: YesNo.no,
};

const toTextAndValue = (str: any): TextAndValue => {
  return {
    text: str,
    value: str,
  };
};

export const useSelectSearch = () => {
  const hotKeyHolder = useHotKeyHolder();

  const getValues = (
    yesOrNo: boolean | undefined | null,
    options: string[] | {[p: string]: string | number} | null | undefined,
    skipSortOptions: boolean | undefined,
    children: any
  ): TextAndValue[] | undefined => {
    if (yesOrNo) {
      return [VAL_TRUE, VAL_FALSE];
    }
    if (options && Array.isArray(options)) {
      if (skipSortOptions) {
        return options.map(toTextAndValue);
      } else {
        return options.sortAndMap(toTextAndValue);
      }
    }
    if (options && !Array.isArray(options)) {
      const _anyOptions = options as any;
      const _options = _anyOptions as string[];
      return (_options ? getSortedKeys(_options, e => _anyOptions[e]) : Object.keys(options))!.map(
        e => {
          return {
            value: options[e],
            text: e,
          };
        }
      );
    }
    if (children && Array.isArray(children)) {
      return children
        .filter(x => !!x.props)
        .map(x => {
          return {
            text: x.props.children,
            value: x.props.value,
          };
        });
    }
  };

  const setValueOnSearch = (
    values: TextAndValue[] | undefined,
    keys: string,
    curValue: any,
    onChange: (...event: any[]) => void
  ) => {
    const foundValues = values?.filter(x => x.text?.toLowerCase()?.startsWith(keys));
    if (!foundValues?.length) {
      return false;
    }
    const curValueIndex = foundValues.map(x => x.value).indexOf(curValue);
    if (curValueIndex < 0 || curValueIndex + 1 >= foundValues.length || foundValues.length === 1) {
      onChange(foundValues[0].value);
    } else {
      onChange(foundValues[curValueIndex + 1].value);
    }
    return true;
  };

  const onKeyDown = (
    e: any,
    curValue: any,
    onChange: (...event: any[]) => void,
    yesOrNo: boolean | undefined | null,
    options: string[] | {[p: string]: string | number} | null | undefined,
    skipSortOptions: boolean | undefined,
    children: any
  ) => {
    const skipKeys = [' ', 'ArrowUp', 'ArrowDown', 'Enter', 'Tab'];
    if (
      skipKeys.indexOf(e.key) === -1 &&
      (e.code.indexOf('Key') !== -1 || e.code.indexOf('Digit') !== -1)
    ) {
      const values = getValues(yesOrNo, options, skipSortOptions, children);
      const keys = hotKeyHolder.append(e.key.toLowerCase());
      if (!setValueOnSearch(values, keys, curValue, onChange)) {
        setValueOnSearch(values, e.key.toLowerCase(), curValue, onChange);
      }
    }
  };

  return {
    onKeyDown,
  };
};
