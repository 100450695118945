import {ApiBase} from '@ozark/common';
import {ActiveFilter} from '@ozark/common/components';
import {ContextKey} from '@ozark/functions/src/functions/express/private/types/Context';
import {ReserveActivity} from '@ozark/functions/src/functions/express/private/types/Reserve';
import {CancelToken} from 'axios';
import {toQuery} from '../util';

export class ReservesApi extends ApiBase {
  getReserveActivity = (mid?: string, filters?: ActiveFilter[], cancelToken?: CancelToken) => {
    const queryString = toQuery(null, filters);
    return this.get<ReserveActivity[]>(
      `reserves/activity?${queryString}`,
      {
        [ContextKey.Mid]: mid,
      },
      cancelToken
    );
  };

  saveUpdatedValues = (reserveActivity: ReserveActivity) => {
    return this.post<ReserveActivity[]>(`reserves/activity/manualEntries`, {
      id: reserveActivity.id,
      isDisplayed: reserveActivity.isDisplayed,
      grossSalesAmountUpdated: reserveActivity.grossSalesAmountUpdated,
      reserveReleasePercentUpdated: reserveActivity.reserveReleasePercentUpdated,
      reserveAmountUpdated: reserveActivity.reserveAmountUpdated,
      releaseAmountUpdated: reserveActivity.releaseAmountUpdated,
    });
  };
}
