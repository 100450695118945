import {DocumentBase} from './DocumentBase';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export type Precalculation = DocumentBase & {
  value: string[];
};

export type PrecalculationView = ViewBase<Precalculation>;

export const selectPrecalculationView: ViewSelector<
  PrecalculationView,
  Precalculation
> = snapshot => {
  const precalculation: PrecalculationView = {
    ...defaultViewSelector(snapshot),
  };
  return precalculation;
};
