import {Paper, styled} from '@mui/material';

export const ReportingPaper = styled(Paper)({
  paddingTop: 20,
  paddingLeft: 30,
  paddingRight: 30,
  paddingBottom: 30,
  minHeight: '520px',
  position: 'relative',
});
