import {useCallback} from 'react';
import {
  Collections,
  Firebase,
  TicketAssignee,
  TicketAssociation,
  TicketHistoryRecord,
  TicketHistoryRecordDataCommon,
  TicketHistoryRecordType,
  TicketSubCollections,
} from '../../..';
import {useUserInfo} from '../../../hooks';
import {getMerchantNameAsync} from '../../../hooks/useMerchant';

export const useTicketHistoryRecord = () => {
  const {uid, userDisplayName, isMerchant, role, department} = useUserInfo();

  const addTicketHistoryRecord = useCallback(
    async (
      ticketId: string,
      isDraftTicket: boolean,
      title: string,
      recordType: TicketHistoryRecordType,
      forceAdd?: boolean,
      titlePortal?: string,
      hidden?: boolean,
      assignee?: TicketAssignee,
      association?: TicketAssociation
    ) => {
      const disabled = isDraftTicket && !forceAdd;

      if (disabled || !uid || !ticketId) {
        return;
      }

      const userName = isMerchant ? await getMerchantNameAsync(uid) : userDisplayName;

      const historyRecordData: TicketHistoryRecordDataCommon = {
        userName: userName ?? 'n/a',
        userRole: role ?? 'n/a',
        department,
        title,
        recordType,
        createdAt: Firebase.FieldValue.now(),
        titlePortal,
        hidden,
      };

      if (recordType === TicketHistoryRecordType.Assignee && assignee) {
        addHistoryRecordDirect(ticketId, {
          uid: uid ?? 'n/a',
          ...historyRecordData,
          recordType,
          assignee,
        });
      } else if (recordType === TicketHistoryRecordType.Association && association) {
        addHistoryRecordDirect(ticketId, {
          uid: uid ?? 'n/a',
          ...historyRecordData,
          recordType,
          association,
        });
      } else {
        addHistoryRecordDirect(ticketId, {
          uid: uid ?? 'n/a',
          ...historyRecordData,
          recordType: recordType as Exclude<TicketHistoryRecordType, 'Assignee' | 'Association'>, // according to conditions above
        });
      }
    },
    [uid, userDisplayName, isMerchant]
  );

  return {
    addTicketHistoryRecord,
  };
};

export const addHistoryRecordDirect = async (
  ticketId: string,
  ticketHistoryRecord: TicketHistoryRecord
) => {
  await Firebase.firestore
    .collection(Collections.tickets)
    .doc(ticketId)
    .collection(TicketSubCollections.history)
    .add(ticketHistoryRecord);
};
