import {
  Collections,
  Firebase,
  JobFrequency,
  ScheduledJobView,
  useNotification,
} from '@ozark/common';
import {useState} from 'react';

export const useScheduledReportingActions = () => {
  const [scheduledJobToDelete, setScheduledJobToDelete] = useState<ScheduledJobView | null>(null);
  const [scheduledJobToUpdate, setScheduledJobToUpdate] = useState<ScheduledJobView | null>(null);
  const [loading, setLoading] = useState(false);
  const showNotification = useNotification();
  const onCancelDelete = () => {
    setScheduledJobToDelete(null);
  };
  const onCancelUpdate = () => {
    setScheduledJobToUpdate(null);
  };
  const onDeleteScheduledReporting = (scheduledJobView: ScheduledJobView) => {
    setScheduledJobToDelete(scheduledJobView);
  };
  const onUpdateScheduledReporting = (scheduledJobView: ScheduledJobView) => {
    setScheduledJobToUpdate(scheduledJobView);
  };

  const deleteScheduledReporting = async (scheduledJob: ScheduledJobView): Promise<void> => {
    setLoading(true);

    try {
      const {id} = scheduledJob;

      const scheduledJobsRef = Firebase.firestore.collection(Collections.scheduledJobs).doc(id);

      await scheduledJobsRef.delete();

      showNotification('success', 'Successfully deleted');
    } catch (error) {
      console.error('Failed to delete with an error:', error);
      showNotification('error', 'Failed to delete');
    } finally {
      setLoading(false);
    }
  };

  const updateScheduledReportingFrequency = async (
    scheduledJob: ScheduledJobView,
    newJobFrequency: JobFrequency
  ): Promise<void> => {
    setLoading(true);

    try {
      const {id} = scheduledJob;

      const scheduledJobsRef = Firebase.firestore.collection(Collections.scheduledJobs).doc(id);

      await scheduledJobsRef.update({frequency: newJobFrequency});

      showNotification('success', 'Successfully updated');
    } catch (error) {
      console.error('Failed to update with an error:', error);
      showNotification('error', 'Failed to update');
    } finally {
      setLoading(false);
    }
  };

  return {
    onCancelDelete,
    scheduledJobToDelete,
    onDeleteScheduledReporting,
    deleteScheduledReporting,
    loading,
    updateScheduledReportingFrequency,
    scheduledJobToUpdate,
    onUpdateScheduledReporting,
    onCancelUpdate,
  };
};
