import {Collections, versionDocumentId} from '@ozark/functions/src/constants';
import {selectVersionView, UniversalSnapshot, Version} from '@ozark/functions/src/documents';
import {useEffect, useState} from 'react';
import {Firebase} from '../firebase';
import {AsyncState} from './AsyncState';

export const useAppVersion = () => {
  const [appVersion, setAppVersion] = useState<AsyncState<number>>({
    promised: true,
  });

  const [refreshCacheAndReload, setRefreshCacheAndReload] = useState<() => void>(() => () => {
    console.warn('function not initialized');
  });

  const [isLatestVersion, setIsLatestVersion] = useState<boolean>(true);

  useEffect(() => {
    const action = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches
          .keys()
          .then(function (names) {
            for (let name of names) caches.delete(name);
          })
          .then(() => {
            window.localStorage.setItem('appCacheVersion', String(appVersion.data));
            window.location.reload();
          });
      } else {
        window.localStorage.setItem('appCacheVersion', String(appVersion.data));
        window.location.reload();
      }
    };
    setRefreshCacheAndReload(() => action);
  }, [appVersion]);

  useEffect(() => {
    const unsubscribe = Firebase.firestore
      .collection(Collections.meta)
      .doc(versionDocumentId)
      .onSnapshot(
        async snapshot => {
          if (snapshot.exists) {
            const version = selectVersionView(snapshot as UniversalSnapshot<Version>)?.version;
            setAppVersion({promised: false, data: version});
          } else {
            setAppVersion({promised: false, data: undefined});
          }
        },
        err => {
          console.error(err);
          setAppVersion({promised: false, error: err});
        }
      );
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (appVersion.promised || !appVersion.data) return;
    const appCacheVersion = window.localStorage.getItem('appCacheVersion');
    if (!appCacheVersion) {
      window.localStorage.setItem('appCacheVersion', String(appVersion.data));
      setIsLatestVersion(true);
    } else {
      setIsLatestVersion(appCacheVersion === String(appVersion.data));
    }
  }, [appVersion]);

  return {appVersion, refreshCacheAndReload, isLatestVersion, setIsLatestVersion};
};
