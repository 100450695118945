import {useCallback} from 'react';
import {
  AssigneeType,
  Collections,
  Ticket,
  TicketAssignee,
  TicketHistoryRecordType,
  TicketTypeAndCategory,
  useUserInfo,
} from '../../..';
import {Firebase} from '../../../firebase';
import {getTicketCategory} from '../utils/ticketCategory';
import {useTicketHistoryRecord} from './useTicketHistoryRecord';

export const useTicketAutoAssignee = () => {
  const {isPortal} = useUserInfo();
  const {addTicketHistoryRecord} = useTicketHistoryRecord();
  const autoSetAssignee = useCallback(
    async (ticketId: string, isDraftTicket: boolean, draft?: TicketTypeAndCategory) => {
      const ticketTypeCategory = await getTicketCategory(draft?.type?.id, draft?.category?.id);
      const autoAssignedDepartment = ticketTypeCategory?.assignment;
      const autoAssignedAssignee: TicketAssignee = {
        type: AssigneeType.erpDepartment,
        erpDepartment: autoAssignedDepartment,
      };

      await Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .update({assignee: autoAssignedAssignee, updatedAt: Firebase.now()});

      await addTicketHistoryRecord(
        ticketId,
        isDraftTicket,
        `Assignee auto-changed to ${autoAssignedDepartment} (ERP Department)`,
        TicketHistoryRecordType.Assignee,
        undefined,
        `Assignee auto-changed to ${autoAssignedDepartment}`,
        undefined,
        autoAssignedAssignee
      );
      return autoAssignedAssignee;
    },
    [addTicketHistoryRecord]
  );
  const autoSetAssigneeOnChangeForPortal = useCallback(
    async (ticketId: string) => {
      if (!isPortal) {
        return;
      }
      const ticketSnap = await Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .get();
      if (!ticketSnap.exists) {
        return;
      }
      const ticket = ticketSnap.data() as Ticket;
      if (
        ticket.assignee?.type === AssigneeType.erpDepartment ||
        ticket.assignee?.type === AssigneeType.erpUser
      ) {
        return;
      }
      return await autoSetAssignee(ticketId, false, ticket.category);
    },
    [autoSetAssignee]
  );
  return {
    autoSetAssignee,
    autoSetAssigneeOnChangeForPortal,
  };
};
