export enum PaymentType {
  purchaseNewDevice = 'Purchase New Device',
  purchaseNewDeviceMerchant = 'Purchase New Device - Merchant',
  purchaseNewDeviceAgent = 'Purchase New Device - Agent',
  reprogramExistingDevice = 'Reprogram Existing Device',
  billResiduals = 'Bill Residuals',
  loanerTerminal = 'Loaner Terminal (only select if eligible)',
  freePlacementPriorApprovalRequired = 'Free Placement - Prior Approval Required',
  rental = 'Rental',
}

export const nonGatewayPaymentTypes = [
  PaymentType.freePlacementPriorApprovalRequired,
  PaymentType.rental,
];

export const DeprecatedPaymentTypes = [PaymentType.purchaseNewDevice, PaymentType.loanerTerminal];
