import {Box, Grid, Paper, Typography} from '@mui/material';
import {AllMIDs, MonthlyVolumeResult} from '@ozark/functions/src/shared';
import {useEffect, useState} from 'react';
import {useApiContainer, useMidsContainer} from '../../..';
import {MidSelect} from '../../MidSelect/MidSelect';
import {LoadingStatus} from '../common/LoadingStatus';
import {ReportingMonthTitle} from '../common/ReportingMonthTitle';
import {useQueryMonthYear} from '../common/useQueryMonthYear';
import {VolumeSummaryByCardTypePieChart} from './VolumeSummaryByCardTypePieChart';
import {VolumeSummaryByCardTypeTable} from './VolumeSummaryByCardTypeTable';
import {VolumeSummaryDetailGraph} from './VolumeSummaryDetailGraph';

export const VolumeSummaryPage = () => {
  const {year, month} = useQueryMonthYear();
  const {selectedMid, handleSelectMid, mids} = useMidsContainer();
  const api = useApiContainer();
  const [report, setReport] = useState<MonthlyVolumeResult>();
  const [loading, setLoading] = useState(true);
  const hasData = Boolean(report?.summaryByCardType?.length);

  useEffect(() => {
    if (!api || !year || !month) return;
    const status = {cancelled: false};
    setLoading(true);
    api.reports
      .getMonthlyVolumeDetail(
        `${month.toString().padStart(2, '0')}/${year}`,
        selectedMid === AllMIDs ? undefined : selectedMid
      )
      .then(report => {
        if (!status.cancelled) {
          setLoading(false);
          setReport(report);
        }
      })
      .catch(err => {
        console.error(err);
        if (!status.cancelled) {
          setLoading(false);
        }
      });
    return () => {
      status.cancelled = true;
    };
  }, [api, year, month, selectedMid]);

  const breadcrumbs = [<Typography variant="body1">Monthly Volume Details</Typography>];

  return (
    <>
      <ReportingMonthTitle breadcrumbs={breadcrumbs}>
        <MidSelect selectedMid={selectedMid} handleSelectMid={handleSelectMid} mids={mids} />
      </ReportingMonthTitle>

      {(loading || !hasData) && <LoadingStatus loading={loading} hasData={hasData} />}

      {!loading && hasData && (
        <Paper sx={{p: 3}}>
          {report && (
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <VolumeSummaryDetailGraph monthlyVolumeResult={report}></VolumeSummaryDetailGraph>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={6}>
                  <VolumeSummaryByCardTypeTable summaryByCardType={report.summaryByCardType} />
                </Grid>
                <Grid item xs={6}>
                  <Box height={420} p={3} pr={5}>
                    <VolumeSummaryByCardTypePieChart summaryByCardType={report.summaryByCardType} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Paper>
      )}
    </>
  );
};
