export const acceptedExtensions = [
  'jpg',
  'jpeg',
  'png',
  'pdf',
  'doc',
  'docx',
  'odt',
  'xls',
  'xlsx',
  'msg',
];
