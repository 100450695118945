import {Collections, hasOzarkRole, PortalUserRoles, UserRoleName, UserRoles} from '@ozark/common';
import {GroupRole, useAuthContainer} from '../index';

export const useUserInfo = () => {
  /** DO NOT USE UseEffect HERE  */
  const {authUser, claims} = useAuthContainer();
  const isPortal = Boolean(claims && PortalUserRoles.includes(claims.role));
  const isMerchant = claims?.role === UserRoles.merchant;
  const isErpUser = hasOzarkRole(claims?.role);
  const isErpAdmin = claims?.role === UserRoles.admin;
  const isAgent = claims?.role === UserRoles.agent;
  const isAgentAdmin = Boolean(claims?.groupId && claims?.groupRole === GroupRole.administrator);
  const isAgentMember = Boolean(claims?.groupId && claims?.groupRole === GroupRole.member);
  const userRoleName: UserRoleName =
    claims?.role === UserRoles.merchant
      ? 'Merchant'
      : claims?.role === UserRoles.agent
      ? 'Agent'
      : 'ERP User';

  const userCollection = authUser.promised
    ? undefined
    : isErpUser
    ? Collections.profiles
    : isAgent
    ? Collections.agents
    : Collections.merchants;

  const isSiteJump = claims?.isSiteJump;

  return {
    authUser,
    uid: authUser.data?.uid,
    role: claims?.role,
    department: claims?.department,
    userCollection,
    userDisplayName: authUser.data?.displayName,
    userRole: claims?.role,
    userRoleName,
    userGroupId: claims?.groupId,
    groupRole: claims?.groupRole,
    isErpUser,
    isErpAdmin,
    isPortal,
    isAgent,
    isAgentAdmin,
    isAgentMember,
    isMerchant,
    isSiteJump,
  } as const;
};
