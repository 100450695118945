import {UserRoles} from '../constants';
import {UniversalTimestamp} from './compat';
import {DocumentBase} from './DocumentBase';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export enum NotificationType {
  error = 'Error',
  warning = 'Warning',
  information = 'Information',
  success = 'Success',
}

export enum AnnouncementStatus {
  saved = 'Saved',
  sent = 'Sent',
  scheduled = 'Scheduled',
  cancelled = 'Cancelled',
}

export type Announcement = DocumentBase & {
  status: AnnouncementStatus;
  title: string;
  text: string;
  type: NotificationType;
  link?: string;
  isImportantNotification?: boolean;
  createdAt: UniversalTimestamp;
  sendDate?: UniversalTimestamp;
  destinationUsersIds: string[];
  destinationERPUsersRoles: UserRoles[];
  midsAllSelected?: boolean;
  mids: string[];
  agentsAllSelected?: boolean;
  agentsIds: string[];
  groupAdminsAllSelected?: boolean;
  groupAdminsIds: string[];
  erpUsersAllSelected?: boolean;
  erpUsersIds: string[];
  sentTo?: string[];
};

export type AnnouncementView = ViewBase<Announcement>;

export const selectAnnouncementView: ViewSelector<AnnouncementView, Announcement> = snapshot => {
  const announcement: AnnouncementView = {
    ...defaultViewSelector(snapshot),
  };
  return announcement;
};
