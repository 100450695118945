import Button from '@mui/material/Button';
import Dialog, {DialogProps} from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import {Typography} from '@mui/material';
import type {ComponentType} from 'react';

type DialogPropsEnhanced = Omit<DialogProps, 'onClose' | 'open'> & {
  onClose?: () => void;
  onPrimaryButtonClick?: () => void;
  open?: boolean;
  primaryButtonDisabled?: boolean;
  primaryButtonText?: string;
  secondaryButtonDisabled?: boolean;
  secondaryButtonText?: string;
  subTitle?: string;
  title?: string;
};

type WrappedComponentProps<T> = T;

const withDialog = <T extends object>(Content: ComponentType<T> | null, modalHash?: string) => {
  const ComponentWithDialog = ({
    fullWidth,
    maxWidth,
    onClose: onCloseOuter,
    onPrimaryButtonClick,
    primaryButtonDisabled,
    primaryButtonText,
    secondaryButtonDisabled,
    subTitle,
    title,
    open = false,
    secondaryButtonText = 'Close',
    ...restProps
  }: DialogPropsEnhanced & WrappedComponentProps<T>) => {
    const [isModalOpenByHash, setIsModalOpenByHash] = useState(false);

    const history = useHistory();

    const {hash} = useLocation();

    const isModalOpen = modalHash ? isModalOpenByHash : open;

    const onClose = () => {
      if (modalHash) {
        history.push({hash: undefined});
      }

      if (onCloseOuter) {
        onCloseOuter();
      }
    };

    const content = useMemo(() => {
      if (!Content) {
        return null;
      }

      return <Content {...(restProps as T)} />;
    }, []);

    useEffect(() => {
      setIsModalOpenByHash(!!hash && hash === modalHash);
    }, [hash]);

    if (typeof window === 'undefined') {
      return null;
    }

    return (
      <Dialog fullWidth={fullWidth} maxWidth={maxWidth} onClose={onClose} open={isModalOpen}>
        {(title || subTitle) && (
          <DialogTitle>
            {title || null}

            {!!subTitle && <Typography mt={!!title ? 1.5 : 0}>{subTitle}</Typography>}
          </DialogTitle>
        )}

        <DialogContent>{content}</DialogContent>

        <DialogActions>
          <Button
            color="secondary"
            disabled={secondaryButtonDisabled}
            variant="outlined"
            onClick={onClose}
          >
            {secondaryButtonText}
          </Button>

          {!!onPrimaryButtonClick && (
            <Button
              color="primary"
              disabled={primaryButtonDisabled}
              onClick={onPrimaryButtonClick}
              variant="contained"
            >
              {primaryButtonText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  return ComponentWithDialog;
};

export {withDialog};
