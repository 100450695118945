import {Box, CircularProgress, Typography} from '@mui/material';
import {
  Collections,
  Firebase,
  NotificationCategory,
  NotificationStatus,
  NotificationView,
  SearchCriteria,
  selectNotificationView,
  useAuthContainer,
  useInfiniteSnapshots,
} from '@ozark/common';
import {useCallback, useMemo, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useUserInfo} from '../../hooks/useUserInfo';
import {BoxParentHeight} from '../common';
import {Loading} from '../Loading';
import {TabsRouter} from '../Tabs/TabsRouter';
import {Title} from '../Title';
import {NotificationDetails} from './NotificationDetails';
import {NotificationsTable} from './NotificationsTable';
import {ACTIVE_NOTIFICATION_ID_KEY, ANNOUNCEMENTS, SYSTEM_NOTIFICATIONS} from './routes';

const PAGE_SIZE = 20;

const SYSTEM_NOTIFICATIONS_TAB = {
  label: 'System Notifications',
  href: SYSTEM_NOTIFICATIONS,
  component: () => <></>, // we don't need a component here because we handle tab selection in the parent component
};

const ANNOUNCEMENTS_TAB = {
  label: 'Announcements',
  href: ANNOUNCEMENTS,
  component: () => <></>,
  exact: true,
};

const ERP_TABS = [SYSTEM_NOTIFICATIONS_TAB, ANNOUNCEMENTS_TAB];
const PORTAL_TABS = [ANNOUNCEMENTS_TAB, SYSTEM_NOTIFICATIONS_TAB];

export const NotificationsPage = (): JSX.Element => {
  const {isPortal} = useUserInfo();
  const defaultTab = isPortal ? NotificationCategory.Announcements : NotificationCategory.System;
  const {category: notificationCategory = defaultTab} = useParams<{
    category?: string;
  }>();
  const {authUser} = useAuthContainer();
  const history = useHistory();
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>({
    order: 'desc',
    orderBy: 'createdAt',
    limit: PAGE_SIZE,
    offset: 0,
  });
  const [moreLoading, setMoreLoading] = useState(false);
  const activeNotificationId = useMemo(() => {
    const query = new URLSearchParams(history.location.search);

    return query.get(ACTIVE_NOTIFICATION_ID_KEY) ?? null;
  }, [history.location.search]);
  const uid = authUser?.data?.uid;

  const filterQuery = useCallback(
    (query: any) => {
      return query
        .doc(uid)
        .collection(Collections.notifications)
        .where('category', '==', notificationCategory);
    },
    [uid, notificationCategory]
  );

  const {documents: notifications, next} = useInfiniteSnapshots(
    Collections.userNotifications,
    selectNotificationView,
    {
      limit: PAGE_SIZE,
      order: searchCriteria.order as 'asc' | 'desc',
      orderBy: searchCriteria.orderBy,
      filter: filterQuery,
      disableUsingCache: true,
    }
  );

  const markRead = async (notificationId: string) => {
    try {
      await Firebase.firestore
        .collection(Collections.userNotifications)
        .doc(uid)
        .collection(Collections.notifications)
        .doc(notificationId)
        .update({
          status: NotificationStatus.read,
        });
    } catch (error) {
      console.error(
        `Failed to mark the notification with id ${notificationId} as read with an error`,
        error
      );
    }
  };

  const setActiveNotificationId = (notificationId: string | null) => {
    const query = new URLSearchParams();
    if (notificationId) {
      query.set(ACTIVE_NOTIFICATION_ID_KEY, notificationId);
    }

    history.push({
      search: query.toString(),
    });
  };

  const onNotificationClick = (notification: NotificationView) => {
    setActiveNotificationId(notification.id);
    if (notification.status === NotificationStatus.new) {
      markRead(notification.id);
    }
  };

  const onNotificationClose = () => {
    setActiveNotificationId(null);
  };

  const notificationsList = useMemo(
    () => Object.values(notifications.data ?? {}),
    [notifications.data]
  );

  const onLoadMore = useCallback(() => {
    setMoreLoading(true);

    const lastNotificationsInList = notificationsList[notificationsList.length - 1];

    next({startAfter: lastNotificationsInList.ref}, () => setMoreLoading(false));
  }, [notificationsList, next]);

  if (notifications.promised) {
    return <Loading />;
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
      <Title
        breadcrumbs={[
          <Typography sx={{display: 'flex', alignItems: 'center'}}>
            Notifications {moreLoading && <CircularProgress sx={{ml: 1}} size={18} />}
          </Typography>,
        ]}
      >
        <Box flex={1} />
        <Box mb="-18px">
          <TabsRouter tabs={isPortal ? PORTAL_TABS : ERP_TABS} />
        </Box>
      </Title>
      <BoxParentHeight
        sx={[
          {display: 'flex', overflowX: 'hidden', flexWrap: 'nowrap'},
          Boolean(activeNotificationId) && {flexWrap: 'nowrap'},
        ]}
      >
        {!notifications.promised && !!notificationsList?.length && (
          <>
            <Box
              sx={[
                {
                  display: 'inline-block',
                  minWidth: '100%',
                  transition: 'min-width .3s ease-in-out',
                },
                Boolean(activeNotificationId) && {minWidth: '60%'},
              ]}
            >
              <NotificationsTable
                notifications={notificationsList}
                searchCriteria={searchCriteria}
                onRowClick={onNotificationClick}
                onRetrieveData={setSearchCriteria}
                activeNotificationId={activeNotificationId}
                onLoadMore={onLoadMore}
                hasMore={notifications.hasNextPage}
              />
            </Box>
            <Box sx={{display: 'inline-block', minWidth: '40%', pl: 2}}>
              <NotificationDetails
                notificationId={activeNotificationId}
                onClose={onNotificationClose}
              />
            </Box>
          </>
        )}
        {!notifications.promised && notificationsList.length === 0 && (
          <Box sx={{py: 5, textAlign: 'center', width: '100%'}}>
            {notifications.hasNextPage ? (
              <CircularProgress sx={{ml: 1}} size={28} />
            ) : (
              'There are no notifications'
            )}
          </Box>
        )}
      </BoxParentHeight>
    </Box>
  );
};
