import {MerchantsActivity} from '@ozark/common/components';
import {useSiteJumpAsUser} from '@ozark/common/hooks/useSiteJumpAsUser';

const ActivationsPage = () => {
  const {siteJumpAsUser} = useSiteJumpAsUser();

  return <MerchantsActivity siteJumpAsUser={siteJumpAsUser} />;
};

export default ActivationsPage;
