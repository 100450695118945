import PhoneNumber from 'awesome-phonenumber';
import {SalesDisposition, SalesDispositionGroup} from '../constants';
import {UniversalSnapshot, UniversalTimestamp} from './compat';
import {DocumentBase} from './DocumentBase';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export type LeadLastNoteInfo = {
  noteId: string | null;
  replyId: string | null;
  author: string | null;
  authorProfileId: string;
  createdAt: UniversalTimestamp;
};

export type LeadProps = {
  assigneeId?: string | null;
  businessName?: string;
  campaign?: string;
  createdAt: UniversalTimestamp;
  email?: string;
  firstName?: string;
  ipAddress?: string;
  lastName?: string;
  phone?: string;
  salesDisposition: SalesDisposition;
  source: string;
  campaignSourceDescription?: string;
  externalSalesRepEmail?: string;
  externalId?: string;
  lastNoteInfo?: LeadLastNoteInfo;
  salesDispositionGroup: SalesDispositionGroup;
  isAppCompleted?: boolean;
};

export type Lead = DocumentBase & LeadProps;

export type LeadView = ViewBase<Lead>;

export type LeadRawData = Lead;

export const selectLeadView: ViewSelector<LeadView, Lead> = (snapshot: UniversalSnapshot<Lead>) => {
  const data = snapshot.data();

  const lead: LeadView = {
    ...defaultViewSelector(snapshot),
    phone: data.phone ? new PhoneNumber(data.phone, 'US').getNumber('national') : data.phoneNumber,
  };

  return lead;
};

export type LeadAssociationApplication = {
  applicationId: string;
  createdAt: UniversalTimestamp;
  doingBusinessAs: string;
  distinguishableId: string;
  mid?: string;
};

export type LeadAssociationApplicationView = ViewBase<LeadAssociationApplication>;

export const selectLeadAssociationApplicationView: ViewSelector<
  LeadAssociationApplicationView,
  LeadAssociationApplication
> = snapshot => {
  const associatedApplication: LeadAssociationApplicationView = {
    ...defaultViewSelector(snapshot),
  };

  return associatedApplication;
};

export const LeadNonIndexableFields: (keyof LeadProps | 'manuallyIndexedAt')[] = [
  'ipAddress',
  'manuallyIndexedAt',
];

export type IndexableLead = Omit<Lead, typeof LeadNonIndexableFields[number]> & {
  id: string; // mandatory property for batch index operation
};
