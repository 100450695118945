import {ListItem, ListItemText, Menu, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {AllMIDs} from '@ozark/common';
import {isEmpty} from 'lodash';
import {useState} from 'react';

type Props = {
  mids?: {mid: string; doingBusinessAs: string}[];
  targetAnchorEl?: HTMLElement;
  onSelectMid: (mid: {mid: string; doingBusinessAs: string}) => void;
  onClose: () => void;
};

const useStyles = makeStyles(theme =>
  createStyles({
    menuPaper: {
      minWidth: 200,
    },
    midListItem: {
      padding: theme.spacing(0, 2),
    },
    noContent: {
      top: '40%',
      position: 'relative',
      textAlign: 'center',
    },
  })
);

export const MidSelect = ({mids, targetAnchorEl, onClose, onSelectMid}: Props) => {
  const classes = useStyles();
  const [currentMid, setCurrentMid] = useState<{mid: string; doingBusinessAs: string}>();

  const handleMidSelect = (mid: string) => () => {
    if (mid !== currentMid?.mid) {
      const selectedMid = mids?.find(e => e.mid === mid);
      setCurrentMid(selectedMid);
      onSelectMid(selectedMid!);
    }

    onClose();
  };

  return (
    <div>
      <Menu
        anchorEl={targetAnchorEl}
        keepMounted
        open={Boolean(targetAnchorEl)}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{
          paper: classes.menuPaper,
        }}
      >
        <ListItem
          key="all"
          className={classes.midListItem}
          button
          onClick={handleMidSelect(AllMIDs)}
        >
          <ListItemText primary={<Typography gutterBottom>All MIDs</Typography>} />
        </ListItem>
        {mids?.sortAndMap(
          e => (
            <ListItem
              key={e.mid}
              className={classes.midListItem}
              button
              onClick={handleMidSelect(e.mid)}
            >
              <ListItemText
                primary={<Typography gutterBottom>{`${e.doingBusinessAs} / ${e.mid}`}</Typography>}
              />
            </ListItem>
          ),
          e => `${e.doingBusinessAs} / ${e.mid}`
        )}
      </Menu>
      {isEmpty(mids) && (
        <Typography className={classes.noContent}>
          There are no MIDs associated with your account
        </Typography>
      )}
    </div>
  );
};
