import {UniversalSnapshot} from './compat';
import {DocumentTypes} from './DocumentBase';
import {ViewBase, ViewTypes} from './ViewBase';

export type SelectorContext = {};

export type ViewSelector<
  TView extends ViewTypes,
  TDocument extends DocumentTypes | ViewTypes = any
> = (snap: UniversalSnapshot<TDocument>) => TView;

export const defaultViewSelector = <TDocument extends DocumentTypes | ViewTypes = any>(
  snap: UniversalSnapshot<TDocument>
): ViewBase<TDocument> => {
  return {
    ...snap.data(),
    id: snap.id,
    ref: snap,
  };
};
