import {Box, Divider, Link} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {AccountStatus} from '@ozark/common';
import {Dispatch, Fragment, SetStateAction} from 'react';
import {
  ButtonExportCsv,
  ExportProps,
  Filter,
  FilterOption,
  FiltersAddButton,
  FiltersApplied,
  InputSearch,
} from '..';
import {Title} from '../Title';
import {GroupAgentAndSortFilters, GroupAgentAndSortFiltersProps} from './GroupAgentAndSortFilters';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    paper: {
      marginBottom: '10px',
    },
    content: {
      marginTop: theme.spacing(0),
      padding: theme.spacing(1, 2, 2),
      position: 'relative',
    },
    divider: {
      margin: theme.spacing(2),
    },
    orderBy: {
      minWidth: 226,
    },
    groupFilter: {
      minWidth: 200,
    },
    filterContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    selectInput: {
      backgroundColor: 'transparent !important',
    },
    searchInput: {
      minWidth: theme.spacing(52),
      marginRight: theme.spacing(-1),
    },
  })
);

type BarProps = {
  linkClick: () => void;
  onSearchChange: (search: string) => void;
  filters: Filter;
  setFilters: Dispatch<SetStateAction<Filter>>;
  exportProps?: ExportProps;
} & Omit<GroupAgentAndSortFiltersProps, 'sortOptions'>;

export const MerchantPortfolioBar = ({
  linkClick,
  orderBy,
  order,
  onGroupChange = () => {},
  groups,
  agents,
  selectedAgent,
  selectedGroup,
  onAgentChange,
  onSortChange,
  onSearchChange,
  showAgentsFilter,
  filters,
  setFilters,
  exportProps,
}: BarProps) => {
  const classes = useStyles();
  const onResetFilters = (newFilters: Filter) => {
    setFilters(newFilters);
  };

  return (
    <Fragment>
      <Title
        breadcrumbs={[
          <Link component="button" variant="body1" onClick={linkClick}>
            Merchants
          </Link>,
        ]}
        noMargins
      >
        <GroupAgentAndSortFilters
          orderBy={orderBy}
          order={order}
          onSortChange={onSortChange}
          sortOptions={sortOptions}
          selectedAgent={selectedAgent}
          agents={agents}
          onAgentChange={onAgentChange}
          selectedGroup={selectedGroup}
          groups={groups}
          onGroupChange={onGroupChange}
          showAgentsFilter={showAgentsFilter}
        />
        <Divider orientation="vertical" className={classes.divider} flexItem />
        <div className={classes.searchInput}>
          <InputSearch
            fieldName="searchMerchants"
            placeholder="Search for merchant portfolios..."
            onSearchChange={onSearchChange}
          />
        </div>
        <Divider orientation="vertical" className={classes.divider} flexItem />
        <FiltersAddButton filters={filters} setFilters={setFilters} filtersConfig={filtersConfig} />
      </Title>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          py: 0.25,
          mb: 0.5,
        }}
      >
        <Box sx={{py: 1.5}}>
          <FiltersApplied filters={filters} setFilters={onResetFilters} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: 'auto',
            mb: 'auto',
          }}
        >
          {exportProps && (
            <>
              <Divider orientation="vertical" className={classes.divider} flexItem />
              <ButtonExportCsv
                filename={exportProps.filename}
                disabled={exportProps.disabled}
                getRows={exportProps.getRows}
                columnsConfig={exportProps.columnsConfig}
                useSelectorMapping={exportProps.useSelectorMapping}
              />
            </>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

const sortOptions: {title: string; key: string; defaultOrder: 'asc' | 'desc'}[] = [
  {
    title: 'Agent Name',
    key: 'agentName',
    defaultOrder: 'desc',
  },
  {
    title: 'Agent Group',
    key: 'groupName',
    defaultOrder: 'desc',
  },
  {
    title: 'MID',
    key: 'mid',
    defaultOrder: 'desc',
  },
  {
    title: 'First Batch Date',
    key: 'firstBatchDate',
    defaultOrder: 'desc',
  },
  {
    title: 'First Batch Amount',
    key: 'firstBatchAmount',
    defaultOrder: 'desc',
  },
  {
    title: 'Last Batch Date',
    key: 'lastBatchDate',
    defaultOrder: 'asc',
  },
  {
    title: 'Last Batch Amount',
    key: 'lastBatchAmount',
    defaultOrder: 'asc',
  },
];

export const filtersConfig: FilterOption[] = [
  {
    id: 'accountStatus',
    column: 'accountStatus',
    label: 'Account Status',
    type: 'list',
    options: Object.values(AccountStatus).map(status => ({
      key: status,
      value: status,
    })),
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'statusChangeDateRange',
    column: 'closeAccountStatusDate',
    label: 'Closed Date Range',
    type: 'dateRange',
    dateFormat: 'MMM dd, yyyy h:mm:ss a',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
];
