export const DepositsIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g fill={'inherit'}>
        <path
          d="M560,224h-29.5c-8.8-19.8-21.5-37.7-37.4-52.5L512,96h-32c-29.4,0-55.4,13.5-73,34.3c-7.6-1.1-15.1-2.3-23-2.3
		H256c-94.8,0-160,78.9-160,160c0,50.4,23.7,97.8,64,128v80c0,8.8,7.2,16,16,16h64c8.8,0,16-7.2,16-16v-48h128v48
		c0,8.8,7.2,16,16,16h64c8.8,0,16-7.2,16-16v-80.7c11.8-8.9,22.3-19.5,31.3-31.3H560c8.8,0,16-7.2,16-16V240
		C576,231.2,568.8,224,560,224z M432,288c-8.8,0-16-7.2-16-16s7.2-16,16-16s16,7.2,16,16S440.8,288,432,288z"
          opacity={0.4}
        />
        <path
          d="M51.3,255.5c-13-2.6-21.4-15.3-18.7-28.3l0,0C34.7,215.8,45.4,208,57,208h1c3.3,0,6-2.7,6-6v-20
		c0-3.3-2.7-6-6-6c-28.5,0-53.9,20.4-57.5,48.6C0.2,227,0,229.4,0,231.8c0,31,25,56.1,56,56.2h40c0-10.8,1.1-21.5,3.4-32H56
		C54.4,256,52.8,255.8,51.3,255.5z M432,256c-8.8,0-16,7.2-16,16s7.2,16,16,16s16-7.2,16-16S440.8,256,432,256z M306.5,0
		c-53,0-96,43-96,96c0,12.5,2.4,24.9,7.2,36.5c12.5-3,25.4-4.5,38.3-4.5h128c4.3,0,8.5,0.3,12.7,0.9c18.1-49.9-7.6-105-57.5-123.1
		C328.8,2,317.7,0,306.5,0z"
        />
      </g>
    </svg>
  );
};
