import {SortableContext} from '@dnd-kit/sortable';
import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@mui/material';
import {ResourceDocumentCategoryView, ResourceView} from '@ozark/common';
import {styles} from './constants';
import {useDisplayContext} from './DisplayContext';
import {DisplaySortableResourceAsRow} from './DisplaySortableResourceAsRow';

interface Props {
  videos: ResourceView[];
  categoryView: ResourceDocumentCategoryView;
}

export const DisplayVideosAsTable = ({videos, categoryView}: Props) => {
  const {allowEdit, isDragHandleAllowed} = useDisplayContext();
  if (videos.length === 0) {
    return <Typography sx={styles.noContent}>No Videos</Typography>;
  }
  return (
    <Table sx={styles.table} aria-label="resources table">
      <TableHead>
        <TableRow>
          <TableCell sx={styles.tableTitleHeader}>Title</TableCell>
          <TableCell sx={styles.tablVimeoLinkHeader}>Vimeo Link</TableCell>
          <TableCell sx={styles.tableDescriptionHeader}>Description</TableCell>
          {allowEdit && <TableCell sx={styles.tableViewableByHeader}>Viewable By</TableCell>}
          <TableCell sx={styles.tableDateHeader}>Date Created</TableCell>
          {allowEdit && <TableCell></TableCell>}
          {allowEdit && <TableCell></TableCell>}
          {isDragHandleAllowed && allowEdit && <TableCell></TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        <SortableContext items={videos} id={categoryView.id}>
          {videos.map((r: ResourceView) => (
            <DisplaySortableResourceAsRow resourceView={r} key={r.id} />
          ))}
        </SortableContext>
      </TableBody>
    </Table>
  );
};
