import {Typography} from '@mui/material';

const colorTab = '#1877f2';

type TypographyStyledProps = {
  children: React.ReactNode;
  isActive: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  ml?: number;
};

export const TicketsHistoryTab = ({
  children,
  isActive = false,
  onClick,
  ml,
}: TypographyStyledProps) => {
  return (
    <Typography
      component="div"
      variant="body1"
      sx={{
        color: isActive ? colorTab : 'lightslategrey',
        borderBottom: isActive ? '1px solid' : '1px solid transparent',
        cursor: isActive ? 'inherit' : 'pointer',
        pointerEvents: isActive ? 'none' : 'inherit',
        userSelect: isActive ? 'inherit' : 'none',
        '&:hover': {
          color: isActive ? 'lightslategrey' : 'inherit',
        },
        ml: ml,
      }}
      onClick={onClick}
    >
      {children}
    </Typography>
  );
};
