import {Box} from '@mui/material';
import {format} from 'date-fns';
import {CardDataEntry} from './CardDataEntry';

interface Props {
  date: Date;
  caption: string;
}

export const ActionDate = ({date, caption}: Props) => {
  const formattedDate = format(date, 'MM/dd/yyyy hh:mm a');
  return (
    <Box sx={{mt: 1, textAlign: 'center'}} title={formattedDate}>
      <CardDataEntry caption={caption} disableWrap>
        {formattedDate}
      </CardDataEntry>
    </Box>
  );
};
