import {
  Divider,
  Grid,
  GridSize,
  List,
  ListItem,
  ListItemText,
  Paper,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import React, {Fragment} from 'react';

export type LineValue =
  | React.ReactNode
  | {
      value: React.ReactNode;
      onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
      secondaryAction?: React.ReactNode;
      width?: string;
    };

export type Line = {[_: string]: LineValue};

export type GroupInfo = {
  icon?: JSX.Element;
  title: string | React.ReactNode;
  columns: [Line] | [Line, Line];
};

type Props = {
  groups: (GroupInfo | null)[];
  paperStyle?: SxProps<Theme>;
};

export const SummaryBox = ({groups, paperStyle}: Props) => {
  return (
    <Paper
      sx={{
        height: '100%',
        p: 2,
        pt: 1,
        color: '#4d6575',
        ...paperStyle,
      }}
    >
      {groups.map((group, i) => {
        if (!group) return;
        const {icon, title, columns} = group;
        return (
          <Fragment key={typeof group.title === 'string' ? group.title : group.title?.toString()}>
            {icon ? (
              <Typography
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  height: 48,
                  alignItems: 'center',
                  m: 0,
                  mb: 0.5,
                  '& > *': {
                    mt: 0,
                    mb: 0,
                    ml: 1,
                    mr: 1,
                  },
                  whiteSpace: 'normal',
                }}
                variant="body1"
                component="div"
                noWrap
                style={{marginTop: i > 0 ? 16 : 0}}
              >
                {icon} <b style={{flex: 1}}>{title}</b>
              </Typography>
            ) : (
              <Typography
                variant="body1"
                component="div"
                pl={1}
                style={{marginTop: i > 0 ? 16 : 0}}
              >
                <b style={{flex: 1}}>{title}</b>
              </Typography>
            )}
            <Divider />
            <Grid container>
              {columns.map(lines => (
                <Grid
                  key={`lines-${Object.keys(lines).join('-')}`}
                  item
                  xs={(12 / columns.length) as GridSize}
                >
                  <List
                    sx={{
                      p: 0,
                      pt: 0.5,
                    }}
                    dense
                  >
                    {Object.keys(lines).map(key => {
                      3;
                      const value = lines[key] as any;
                      if (!value) return null;
                      return (
                        <ListItem
                          key={key}
                          button={!!value['onClick'] as any}
                          onClick={value['onClick'] || undefined}
                          sx={{
                            display: 'inline-flex',
                            width: value.width ?? '100%',
                            verticalAlign: 'top',
                            px: 1,
                          }}
                        >
                          <ListItemText
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                            primary={<Typography>{value['value'] || value}</Typography>}
                            secondary={<Typography variant="caption">{key}</Typography>}
                          />
                          {value.secondaryAction}
                        </ListItem>
                      );
                    })}
                  </List>{' '}
                </Grid>
              ))}
            </Grid>
          </Fragment>
        );
      })}
    </Paper>
  );
};
