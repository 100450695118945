import {Box} from '@mui/material';
import {Controller} from 'react-hook-form';
import {BoxFlexed} from '../../../common';
import {TextFieldRequired} from '../../common/TextFieldRequired';
import {FIELD_NAME_ASSOCIATION} from '../../constants/constants';
import {AssociationEditor} from './AssociationEditor';
import {AssociationInfo} from './AssociationInfo';

export const Association = () => {
  return (
    <Controller
      name={FIELD_NAME_ASSOCIATION}
      rules={{required: true}}
      render={({fieldState: {error}}) => (
        <BoxFlexed alignItems="flex-start">
          {error && <TextFieldRequired />}
          {!error && (
            <Box flex={1}>
              <AssociationInfo />
            </Box>
          )}
          <AssociationEditor />
        </BoxFlexed>
      )}
    />
  );
};
