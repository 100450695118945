import MarkdownIt from 'markdown-it';
const env: MarkdownIt.Options = {
  // xhtmlOut: true,
  breaks: true,
  linkify: false,
};

export type MarkdownOptions = {
  linkify: boolean;
};

export const Markdown = {
  render: (content: string, options?: MarkdownOptions) => {
    env.linkify = options?.linkify ?? env.linkify;

    return new MarkdownIt(env).render(content, env);
  },
};
