import {adaptV4Theme, createTheme, Theme} from '@mui/material';
import {GroupView} from '@ozark/common';
import Color from 'color';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const disabledStyleOverride = {
  '&.Mui-disabled': {
    color: 'rgba(0, 0, 0, 0.6)',
    WebkitTextFillColor: 'rgba(0, 0, 0, 0.6)',
  },
};

export const getTheme = (group: GroupView): Theme => {
  const primaryColor = Color(group.hexColorPrimary);
  const primaryTextColor = Color(group.hexColorTextPrimary);
  let theme = createTheme(
    adaptV4Theme({
      palette: {
        primary: {
          main: primaryColor.hex(),
          light: primaryColor.lighten(0.2).hex(),
          dark: primaryColor.darken(0.2).hex(),
          contrastText: primaryTextColor.hex(),
        },
        secondary: {
          main: '#4d6575',
          light: '#4d6575',
          dark: '#4d6575',
        },
        tonalOffset: 0.2,
      },
      typography: {
        fontFamily: 'Rubik',
      },
      overrides: {
        MuiAppBar: {
          colorPrimary: {
            color: '#586069',
          },
          root: {
            color: '#000',
            fill: '#000',
            backgroundColor: '#fff !important',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          },
        },
        MuiInputBase: {
          root: disabledStyleOverride,
          input: disabledStyleOverride,
        },
        MuiInputLabel: {
          root: disabledStyleOverride,
        },
        MuiOutlinedInput: {
          root: disabledStyleOverride,
          input: disabledStyleOverride,
          notchedOutline: disabledStyleOverride,
        },
      },
    })
  );

  return theme;
};
