import {
  Agent,
  AgentView,
  Announcement,
  AnnouncementView,
  Application,
  ApplicationAssociation,
  ApplicationAssociationView,
  ApplicationView,
  Collections,
  DocumentTypes,
  FeeModifier,
  FeeModifierView,
  Firebase,
  Group,
  GroupView,
  Merchant,
  SearchableAgentView,
  SearchableMerchantView,
  selectAgentView,
  selectAnnouncementView,
  selectApplicationAssociationView,
  selectApplicationView,
  selectFeeModifierView,
  selectGroupView,
  selectSearchableAgentView,
  selectSearchableMerchantView,
  UniversalSnapshot,
  ViewSelector,
  ViewTypes,
} from '@ozark/common';
import {ApplicationSubCollection, GroupRole} from '@ozark/functions/src/constants';
import {Residual, ResidualView, selectResidualView} from '@ozark/functions/src/documents/Residual';
import {getAgentsWithScheduleA} from '@ozark/functions/src/functions/utils/getAgentsWithScheduleA';
import firebase from 'firebase/compat/app';
import {useEffect, useMemo, useState} from 'react';
import {AsyncState} from '../models';

export const useCollectionSnapshot = <
  TDocumentView extends ViewTypes,
  TDocument extends DocumentTypes
>(
  collection: Collections,
  viewSelector: ViewSelector<TDocumentView>,
  subcollection?: {
    parentId: string;
    collection: Collections | ApplicationSubCollection;
  },
  filter?: (
    query:
      | firebase.firestore.Query<firebase.firestore.DocumentData>
      | firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
  ) => firebase.firestore.Query<firebase.firestore.DocumentData>
) => {
  const [documents, setDocuments] = useState<AsyncState<TDocumentView[]>>({
    promised: true,
  });
  useEffect(() => {
    let collectionsRef = Firebase.firestore.collection(collection);
    if (subcollection) {
      collectionsRef = collectionsRef
        .doc(subcollection.parentId)
        .collection(subcollection.collection);
    }

    let query:
      | firebase.firestore.Query<firebase.firestore.DocumentData>
      | firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = collectionsRef;

    if (filter) {
      query = filter(collectionsRef);
    }

    const unsubscribe = query.orderBy('createdAt', 'desc').onSnapshot(
      async snapshot => {
        if (snapshot.size === 0) {
          setDocuments({promised: false});
          return;
        }
        const documents = snapshot.docs.map(doc =>
          viewSelector(doc as UniversalSnapshot<TDocument>)
        );

        setDocuments({promised: false, data: documents});
      },
      err => {
        console.error(err);
        setDocuments({promised: false, error: err});
      }
    );
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, [collection, setDocuments]);

  return {documents};
};

export const useGroups = () => {
  const snapshot = useCollectionSnapshot<GroupView, Group>(Collections.groups, selectGroupView);
  if (snapshot.documents && !snapshot.documents.promised && snapshot.documents.data) {
    snapshot.documents.data = snapshot.documents.data.filter(group => !group.deleted);
  }
  return snapshot;
};

export const useAgents = (groupId?: string) =>
  groupId
    ? useCollectionSnapshot<SearchableAgentView, Agent>(
        Collections.agents,
        selectSearchableAgentView,
        undefined,
        query => query.where('group.id', '==', groupId)
      )
    : useCollectionSnapshot<SearchableAgentView, Agent>(
        Collections.agents,
        selectSearchableAgentView
      );

export const useMerchants = () =>
  useCollectionSnapshot<SearchableMerchantView, Merchant>(
    Collections.merchants,
    selectSearchableMerchantView
  );

export const useAgentsFromGroup = (
  uid: string | undefined,
  groupId: string | undefined,
  groupRole: GroupRole | undefined
) => {
  if (groupId && groupRole && groupRole === GroupRole.administrator) {
    return useCollectionSnapshot<AgentView, Agent>(
      Collections.agents,
      selectAgentView,
      undefined,
      query => query.where('group.id', '==', groupId)
    );
  }

  if (uid && groupRole && groupRole === GroupRole.member) {
    return useCollectionSnapshot<AgentView, Agent>(
      Collections.agents,
      selectAgentView,
      undefined,
      query => query.where('masterUid', '==', uid)
    );
  }
  return {
    documents: {
      data: [] as AgentView[],
    },
  };
};

export const useResiduals = () =>
  useCollectionSnapshot<ResidualView, Residual>(Collections.residuals, selectResidualView);

export const useFeeModifiers = (applicationId: string) =>
  useCollectionSnapshot<FeeModifierView, FeeModifier>(
    Collections.applications,
    selectFeeModifierView,
    {parentId: applicationId, collection: ApplicationSubCollection.feeModifiers}
  );

export const useAnnouncements = () =>
  useCollectionSnapshot<AnnouncementView, Announcement>(
    Collections.announcements,
    selectAnnouncementView
  );

export const useLeadAssociatedApplications = (leadId: string) =>
  useCollectionSnapshot<ApplicationView, Application>(
    Collections.applications,
    selectApplicationView,
    undefined,
    query => query.where('leadId', '==', leadId)
  );

export const useAssociatedApplicationsOfApplication = (applicationId: string) =>
  useCollectionSnapshot<ApplicationAssociationView, ApplicationAssociation>(
    Collections.applicationsAssociations,
    selectApplicationAssociationView,
    undefined,
    query => query.where('applicationIds', 'array-contains', applicationId)
  );

export const useAgentsWithScheduleA = (
  agents: AgentView[] | undefined,
  groups: GroupView[] | undefined
) => {
  const agentsWithScheduleA = useMemo(() => {
    return getAgentsWithScheduleA(agents, groups);
  }, [agents, groups]);

  return {agentsWithScheduleA: agentsWithScheduleA};
};
