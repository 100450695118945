import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {ReactNode} from 'react';

export const ListItem = ({children}: {children: ReactNode}) => {
  const classes = useStyles();
  return (
    <Typography className={classes.item} variant="body2">
      <ChevronRightIcon color="primary" className={classes.icon} />
      <span className={classes.itemText}>{children}</span>
      <CheckBoxOutlinedIcon color="primary" className={classes.icon} />
    </Typography>
  );
};

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: theme.spacing(2),
  },
  itemText: {
    flexGrow: 1,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));
