import {useGroupScheduleA} from '@ozark/common';
import {GroupScheduleA as GroupScheduleACommon, Loading} from '@ozark/common/components';

type Props = {
  groupId: string;
};

export const GroupScheduleA = ({groupId}: Props) => {
  const {document: groupScheduleA} = useGroupScheduleA(groupId);

  if (groupScheduleA.promised) {
    return <Loading />;
  }

  return <GroupScheduleACommon document={groupScheduleA.data} readonly />;
};
