import {LoadingButton} from '@mui/lab';
import {
  Alert,
  AlertColor,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import {NotificationCategory, NotificationType, NotificationView} from '@ozark/common';
import {useEffect, useState} from 'react';
import {matchPath} from 'react-router';
import {useHistory} from 'react-router-dom';
import {useUserInfo} from '../../../hooks/useUserInfo';
import {useAuthContainer} from '../../../store';
import {getNotificationDetailsRoute, NOTIFICATIONS} from '../../NotificationsPage/routes';
import {
  SnoozeTime,
  useImportantNotificationsSnooze,
} from '../hooks/useImportantNotificationsSnooze';
import {useImportantNotificationsState} from '../hooks/useImportantNotificationsState';
import {useNotificationSound} from '../hooks/useNotificationSound';
import {useNotificationsState} from '../hooks/useNotificationsState';
import {RenderNotificationText} from '../Notifications';

const severityMap: {[key in NotificationType]: AlertColor} = {
  [NotificationType.success]: 'success',
  [NotificationType.information]: 'info',
  [NotificationType.warning]: 'warning',
  [NotificationType.error]: 'error',
};

export const ImportantNotificationsDialog = () => {
  const [open, setOpen] = useState(false);
  const {userCollection} = useUserInfo();
  const history = useHistory();
  const {authUser} = useAuthContainer();
  const uid = authUser?.data?.uid;
  const {
    importantNotifications,
    loadMoreRef,
    markAllImportantNotificationsAsRead,
    isMarkingAsRead,
  } = useImportantNotificationsState(uid);
  const {markRead} = useNotificationsState();
  const {
    snoozeNotifications,
    handleCloseSnoozeMenu,
    handleOpenSnoozeMenu,
    saveSnooze,
    openSnoozeMenu,
    anchorSnoozeMenuElement,
  } = useImportantNotificationsSnooze(uid, userCollection);
  const {playNotificationSound} = useNotificationSound();

  useEffect(() => {
    if (snoozeNotifications === null) {
      return;
    }

    const isUserOnNotificationsPage = matchPath(history.location.pathname, NOTIFICATIONS);
    const shouldOpen =
      !isUserOnNotificationsPage &&
      !!importantNotifications.length &&
      !snoozeNotifications.isSnooze;

    setOpen(shouldOpen);
  }, [importantNotifications.length, history.location.pathname, snoozeNotifications]);

  useEffect(() => {
    if (open) {
      playNotificationSound();
    }
  }, [open]);

  const onReadThemAll = async () => {
    await markAllImportantNotificationsAsRead();

    setOpen(false);
  };

  const isSingleNotification = importantNotifications.length === 1;

  const handleNotificationClick = ({id, link, category}: NotificationView) => {
    markRead(id);

    if (category === NotificationCategory.Announcements) {
      history.push(getNotificationDetailsRoute(id));
      return;
    }

    if (!link) {
      return;
    }

    if (link.startsWith('/')) {
      history.push(link);
    } else {
      window.open(link, '_blank', 'noopener');
    }
  };

  if (!open) {
    return null;
  }

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle id="create-dialog-title">
        Important {isSingleNotification ? 'Notification' : 'Notifications'}
      </DialogTitle>
      <DialogContent>
        <Stack sx={{width: '100%'}} spacing={2}>
          {importantNotifications.map((notification, index) => (
            <Alert
              ref={index === importantNotifications.length - 5 ? loadMoreRef : null}
              key={notification.id}
              variant="outlined"
              severity={severityMap[notification.type ?? NotificationType.information]}
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => handleNotificationClick(notification)}
                >
                  {notification.category === NotificationCategory.Announcements
                    ? 'Open'
                    : notification.linkTitle ?? 'Open'}
                </Button>
              }
            >
              {notification.text ? (
                <>
                  <AlertTitle>{notification.title}</AlertTitle>
                  <Box sx={{whiteSpace: 'pre-line', wordBreak: 'break-word'}}>
                    {RenderNotificationText(notification.text, true)}
                  </Box>
                </>
              ) : (
                notification.title
              )}
            </Alert>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          id="snooze-button"
          aria-controls={openSnoozeMenu ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openSnoozeMenu ? 'true' : undefined}
          onClick={handleOpenSnoozeMenu}
          sx={{minWidth: 120}}
          variant="text"
        >
          Snooze
        </Button>
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorSnoozeMenuElement}
          open={openSnoozeMenu}
          onClose={handleCloseSnoozeMenu}
          TransitionComponent={Fade}
        >
          {Object.entries(SnoozeTime).map(([key, value]) => (
            <MenuItem color="primary" key={key} value={key} onClick={() => saveSnooze(value)}>
              {value}
            </MenuItem>
          ))}
        </Menu>
        <LoadingButton onClick={onReadThemAll} color="primary" loading={isMarkingAsRead}>
          Mark {isSingleNotification ? 'it' : 'them'} as read
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
