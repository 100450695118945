export enum MailTemplates {
  achReport = 'ach-report',
  complianceReport = 'compliance-report',
  contactForm = 'contact-form',
  emailNotification = 'email-notification',

  newAgentLogIn = 'new-agent-log-in',
  newAgentWelcome = 'new-agent-welcome',

  newMerchantLogIn = 'new-merchant-log-in',
  newMerchantWelcome = 'new-merchant-welcome',
  noteOrAttachmentAddedEmail = 'new-note-or-attachment',

  resetPassword = 'reset-password',
  resetPasswordErp = 'reset-password-erp',

  resumeApplication = 'resume-application',
  registerNowEmail = 'register-now',
  setPassword = 'set-password',
  verifyEmail = 'verify-email',
  welcomeEmail = 'welcome-email',
  welcomeEmailWithResetPassword = 'welcome-email-with-reset-password',
  newLead = 'new-lead',
  postBoardingReport = 'post-boarding-report',
}

export type MailDomain = 'ozark' | 'tangram';

export type EmailTemplate = {
  name: MailTemplates;
  version?: string;
};
