import {UserRoles} from '@ozark/common';
import {GlobalErrorBoundary} from '@ozark/common/components';
import {useMemo} from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';
import {ComponentRoute} from '../../types/Routes';

const AppRoute = ({
  route,
  isLayoutProtected,
}: {
  route: ComponentRoute;
  isLayoutProtected?: boolean;
}) => {
  const location = useLocation();
  const {component, condition} = route;
  const isRouteProtected = route.isProtected || isLayoutProtected;
  const {authProfile, isAuthenticated, claims, group} = useStore();

  // memoization here lets to avoid extra rerenders of layout
  const Component = useMemo(() => {
    return component;
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  if (isRouteProtected && !isAuthenticated) {
    const loginRoute =
      location.pathname === '/' ? ROUTES.LOGIN : `${ROUTES.LOGIN}?returnTo=${location.pathname}`;

    return <Redirect to={loginRoute} />;
  }

  if (condition && claims && !condition(claims)) return <Redirect to={ROUTES.DASHBOARD} />;

  if (
    isAuthenticated &&
    claims?.role === UserRoles.merchant &&
    location.pathname !== ROUTES.APPLICATIONS_STATUSES &&
    !location.pathname.includes(ROUTES.DOCUMENTS_UPLOAD) &&
    !authProfile.promised &&
    authProfile.data?.applicationMids?.length === 0
  ) {
    return <Redirect to={ROUTES.APPLICATIONS_STATUSES} />;
  }

  if (
    isRouteProtected &&
    isAuthenticated &&
    group.data?.applicationSettings?.enableRegistration &&
    claims?.role === UserRoles.merchant &&
    !location.pathname.includes(ROUTES.PROFILE) &&
    location.pathname !== ROUTES.APPLICATIONS_STATUSES &&
    !authProfile.promised &&
    (!authProfile.data?.lastName || !authProfile.data?.firstName || !authProfile.data?.phoneNumber)
  ) {
    const url = `${ROUTES.PROFILE}?auto=true`;
    return <Redirect to={url} />;
  }

  return (
    <GlobalErrorBoundary>
      <Component />
    </GlobalErrorBoundary>
  );
};

export default AppRoute;
