import FilterListIcon from '@mui/icons-material/FilterList';
import {Box, Chip, Tooltip} from '@mui/material';
import {format} from 'date-fns-tz';
import {DATE_FORMAT, Filter} from '..';

type Props = {
  filters: Filter;
  setFilters: (filter: Filter) => void;
  dateFormat?: string;
};

export const FiltersApplied = ({filters, setFilters, dateFormat = DATE_FORMAT}: Props) => {
  const handleDeleteFilter = (id: string) => () => {
    const _filters = {...filters};
    delete _filters[id];
    setFilters(_filters);
  };

  return (
    <Box pr={2} display="flex">
      <FilterListIcon sx={{mr: 1, color: '#AEB1B5', my: 0.5}} />
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        sx={{
          m: -0.5,
        }}
      >
        {filters &&
          Object.keys(filters).map(key => {
            const filter = filters[key];
            if (filter.option.type === 'dateRange' && filter.operator.id === '__between') {
              const firstDate = format(filter.value?.[0] as Date, dateFormat);
              const secondDate = format(filter.value?.[1] as Date, dateFormat);
              return (
                <Tooltip
                  key={`${key}-${filter.operator.id}`}
                  title={`${filter.option.label} ${filter.operator.label} '${firstDate}' and '${secondDate}'`}
                >
                  <Chip
                    sx={{
                      m: 0.5,
                      maxWidth: 350,
                    }}
                    label={
                      <span>
                        <b>{filter.option.label}</b> {filter.operator.label} '<b>{firstDate}</b>'
                        and '<b>{secondDate}</b>'
                      </span>
                    }
                    variant="outlined"
                    onDelete={filter.option.force ? undefined : handleDeleteFilter(key)}
                  />
                </Tooltip>
              );
            }
            if (filter.option.type === 'date' && filter.operator.id === '__between') {
              const date = format(filter.value?.[0] as Date, dateFormat);
              return (
                <Tooltip
                  key={`${key}-${filter.operator.id}`}
                  title={`${filter.option.label} ${filter.operator.label} '${date}'`}
                >
                  <Chip
                    sx={{
                      m: 0.5,
                      maxWidth: 300,
                    }}
                    label={
                      <span>
                        <b>{filter.option.label}</b> {filter.operator.label} '<b>{date}</b>'
                      </span>
                    }
                    variant="outlined"
                    onDelete={filter.option.force ? undefined : handleDeleteFilter(key)}
                  />
                </Tooltip>
              );
            }
            if (filter.option.type === 'currencyRange' && filter.operator.id === '__between') {
              return (
                <Tooltip
                  key={`${key}-${filter.operator.id}`}
                  title={`${filter.option.label} ${filter.operator.label} '${
                    filter.value?.[0] ?? ''
                  }' and '${filter.value?.[1] ?? ''}'`}
                >
                  <Chip
                    sx={{
                      m: 0.5,
                      maxWidth: 350,
                    }}
                    label={
                      <span>
                        <b>{filter.option.label}</b> {filter.operator.label} '
                        <b>{filter.value?.[0] as number}</b>' and '
                        <b>{filter.value?.[1] as number}</b>'
                      </span>
                    }
                    variant="outlined"
                    onDelete={filter.option.force ? undefined : handleDeleteFilter(key)}
                  />
                </Tooltip>
              );
            }
            return (
              <Tooltip
                key={`${key}-${filter.operator.id}`}
                title={`${filter.option.label} ${filter.operator.label} '${filter.value}'`}
              >
                <Chip
                  sx={{
                    m: 0.5,
                    maxWidth: 300,
                  }}
                  label={
                    <span>
                      <b>{filter.option.label}</b> {filter.operator.label} '
                      <b>{`${filter.value}`}</b>'
                    </span>
                  }
                  variant="outlined"
                  onDelete={filter.option.force ? undefined : handleDeleteFilter(key)}
                />
              </Tooltip>
            );
          })}
      </Box>
    </Box>
  );
};
