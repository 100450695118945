import {MenuItem} from '@mui/material';
import {DefaultSplitMethodType, DirectSplitMethodName, SplitMethodType} from '@ozark/common';
import {Select} from '@ozark/common/components';

interface Props {
  control: any;
  errors: any;
  disabled: boolean;
  defaultValue?: SplitMethodType;
}
export const AgentCalculationMethod = ({control, errors, disabled, defaultValue}: Props) => {
  return (
    <Select
      name="splitMethod"
      label="Calculation Method"
      errors={errors}
      control={control}
      defaultValue={defaultValue ?? DefaultSplitMethodType}
      disabled={disabled}
    >
      <MenuItem key={SplitMethodType.direct} value={SplitMethodType.direct}>
        {DirectSplitMethodName}
      </MenuItem>
      <MenuItem key={SplitMethodType.splitOfSplit} value={SplitMethodType.splitOfSplit}>
        Split of Agent Split
      </MenuItem>
    </Select>
  );
};
