import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import {omit} from 'lodash';
import {useEffect} from 'react';
import {TicketTypeAndCategory, TicketTypeInfo} from '../../../..';
import {useUserInfo} from '../../../../hooks';
import {useTicketTypes} from '../../hooks/useTicketTypes';
import {InputCategoryError} from '../../types';

type Props = {
  draft?: TicketTypeAndCategory;
  setDraft: (draft: TicketTypeAndCategory) => void;
  errors: Record<InputCategoryError, string>;
  setErrors: (errors: Record<InputCategoryError, string>) => void;
  useDefaultValue?: boolean;
};

export const CategoryEditorType = ({
  draft,
  setDraft,
  errors,
  setErrors,
  useDefaultValue,
}: Props) => {
  const {userRoleName} = useUserInfo();
  const {ticketTypes} = useTicketTypes();

  const options =
    ticketTypes.data &&
    ticketTypes.data
      .filter(
        type =>
          type.visibleToRoles.includes(userRoleName) &&
          (!type.isDeleted || draft?.type?.id === type.id)
      )
      .map(type => ({
        id: type.id,
        name: type.name,
      }))
      .sort();
  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    const name = ticketTypes.data?.filter(t => t.id === value)?.[0].name;
    if (name) {
      setDraft({
        type: {
          id: value,
          name: name,
        } as TicketTypeInfo,
      });
    }
    if (event.target.value) {
      setErrors(omit(errors, 'type') as Record<InputCategoryError, string>);
    }
  };

  useEffect(() => {
    if (useDefaultValue && options?.length && !draft?.type) {
      setDraft({
        type: {
          id: options[0].id,
          name: options[0].name,
        },
      });
    }
  }, [ticketTypes.data, useDefaultValue]);

  return (
    <Box mt={2} minWidth={450}>
      <FormControl fullWidth variant="standard" error={Boolean(errors['type'])} required>
        <InputLabel id="assignee-type-label">Type</InputLabel>
        <Select
          labelId="assignee-type-label"
          id="assignee-type"
          value={draft?.type?.id ?? ''}
          label="Type"
          onChange={handleChange}
          readOnly={useDefaultValue}
        >
          {(options || []).map(data => (
            <MenuItem key={data.id} value={data.id}>
              {data.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{errors['type']}</FormHelperText>
      </FormControl>
    </Box>
  );
};
