import InfoIcon from '@mui/icons-material/Info';
import {Button, Fade, Menu, MenuItem, Paper, Tooltip, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  ApplicationView,
  Collections,
  createAuditNonce,
  Dispositions,
  Firebase,
  getColor,
  useApplicationResume,
  useDocumentsUploadLink,
  useNotification,
} from '@ozark/common';
import {
  CloneDialog,
  ConfirmationDialog,
  DisputeReasonsDetail,
  ResendDisputeEmailsDialog,
  TransferToMerchantConfirmationDialog,
} from '@ozark/common/components';
import React, {Fragment, useState} from 'react';
import {useHistory} from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import {useStore} from '../../../store/helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      marginBottom: theme.spacing(2),
    },
    paper: {
      marginTop: theme.spacing(0),
      padding: theme.spacing(1, 2, 2),
      position: 'relative',
      borderTop: 'solid 4px',
    },
    heading: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1, 0, 0),
    },
    actions: {
      flexGrow: `0 !important` as any,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: '100%',
      paddingRight: theme.spacing(1),
      '& > *': {
        margin: theme.spacing(0, 0.5),
      },
    },
    grow: {
      flexGrow: 1,
    },
    divider: {
      margin: theme.spacing(0, 2),
    },
    selectInput: {
      backgroundColor: 'transparent !important',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

type BarProps = {
  application: ApplicationView;
};

export const Bar = ({application}: BarProps) => {
  const classes = useStyles();
  const {isUserAgent, authProfile} = useStore();
  const [pendingReasonsMenuEl, setPendingReasonsMenuEl] = React.useState<null | HTMLElement>(null);
  const [disputeReasonsVisible, setDisputeReasonsVisible] = useState(false);
  const [resendDisputeEmailsVisible, setResendDisputeEmailsVisible] = useState(false);
  const {resumeApplication} = useApplicationResume();
  const {copyUploadLink} = useDocumentsUploadLink();
  const [transferToMerchantDialogOpen, setTransferToMerchantDialogOpen] = useState<boolean>(false);
  const [cloneDialogOpen, setCloneDialogOpen] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState<(() => Promise<void>) | null>(null);
  const [deleted, setDeleted] = React.useState<Boolean>(application.deleted || false);
  const showNotification = useNotification();
  const history = useHistory();
  const getDeleteActionName = () => (deleted ? '' : 'delete');

  const handleOpenendingReasonsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPendingReasonsMenuEl(event.currentTarget);
  };

  const handleDeleteApplication = () => async () => {
    try {
      if (application.deleted) {
        return;
      }
      const auditNonce = createAuditNonce(Firebase.auth.currentUser!.uid);
      const nextIsDeleted = !application.deleted;
      const data = {deleted: nextIsDeleted, auditNonce};

      const snapshot = await Firebase.firestore
        .collection(Collections.applications)
        .doc(application.id)
        .get();

      if (snapshot.exists) {
        await snapshot.ref.set(data, {merge: true}).then(() => setDeleted(data.deleted));
      }
      history.goBack();
      showNotification('success', `Application has been deleted.`);
      setConfirmationAction(null);
    } catch (err: any) {
      console.error(`failed to ${getDeleteActionName()} document. ${err.toString()}`);
      showNotification('error', `Error deleting application.`);
    }
  };

  const respondPendingReasons = async () => {
    const link = await copyUploadLink(
      application.id,
      application.disposition,
      'agent',
      authProfile?.data?.id
    );

    if (link === undefined) {
      showNotification('error', 'Failed to get documents upload token');
      return;
    }

    window.open(link, '_blank', 'noopener');
  };

  return (
    <Fragment>
      <Paper
        className={classes.paper}
        style={{
          borderTopColor: getColor(
            application.disposition || Dispositions.incomplete,
            application.isClosed,
            application.uwUploadedAt
          ),
        }}
        square
      >
        <div className={classes.heading}>
          <Typography variant="body1">
            <strong>
              {application.legalBusinessName} &bull; {application.doingBusinessAs}
            </strong>
            {application.isClosed && (
              <span>
                {' '}
                (Closed{' '}
                <Tooltip title="This account is closed" sx={{verticalAlign: 'middle'}}>
                  <InfoIcon color="info" />
                </Tooltip>
                )
              </span>
            )}
          </Typography>
          <div className={classes.grow}></div>
          <div className={classes.actions}>
            {application.disposition === Dispositions.uwPending && isUserAgent() && (
              <Button onClick={handleOpenendingReasonsMenu}>Pending Reasons</Button>
            )}
            {application.disposition === Dispositions.incomplete && !application.complete && (
              <Button
                variant="outlined"
                onClick={() => resumeApplication(application.id, application.group.id)}
              >
                Resume
              </Button>
            )}
            {application.disposition === Dispositions.incomplete && (
              <Button variant="outlined" onClick={() => setTransferToMerchantDialogOpen(true)}>
                Transfer to Merchant
              </Button>
            )}
            {isUserAgent() && (
              <Button variant="outlined" onClick={() => setCloneDialogOpen(true)}>
                Clone Application
              </Button>
            )}

            {isUserAgent() &&
              !application.deleted &&
              application.disposition === Dispositions.incomplete && (
                <Button
                  variant="outlined"
                  onClick={() => setConfirmationAction(() => handleDeleteApplication())}
                >
                  Delete
                </Button>
              )}
            <Menu
              id="pending-reasons"
              anchorEl={pendingReasonsMenuEl}
              onClose={() => setPendingReasonsMenuEl(null)}
              keepMounted
              open={Boolean(pendingReasonsMenuEl)}
              anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
              transformOrigin={{horizontal: 'right', vertical: 'top'}}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={() => setDisputeReasonsVisible(true)}>
                <Typography>View Pending Reasons</Typography>
              </MenuItem>
              <MenuItem onClick={async () => await respondPendingReasons()}>
                <Typography>Respond to Pending Reasons</Typography>
              </MenuItem>
              <MenuItem onClick={() => setResendDisputeEmailsVisible(true)}>
                <Typography>Resend Pending Emails</Typography>
              </MenuItem>
            </Menu>
          </div>
        </div>
      </Paper>
      {disputeReasonsVisible && (
        <DisputeReasonsDetail
          applicationId={application.id}
          disposition={application.disposition}
          setDialogVisible={setDisputeReasonsVisible}
        />
      )}
      {resendDisputeEmailsVisible && (
        <ResendDisputeEmailsDialog
          application={application}
          setDialogVisible={setResendDisputeEmailsVisible}
        />
      )}
      {transferToMerchantDialogOpen && (
        <TransferToMerchantConfirmationDialog
          application={application}
          onClose={() => setTransferToMerchantDialogOpen(false)}
          errorMessage="Please enter Equipment, Processing Info, Rate Set and Business Type before transferring application to a merchant"
        />
      )}
      {cloneDialogOpen && (
        <CloneDialog
          route={ROUTES.APPLICATION_PATH}
          applicationId={application.id}
          onClose={() => setCloneDialogOpen(false)}
          referrer="all"
          hidePlatform
          defaultPlatform={application.platform}
        />
      )}
      {confirmationAction && (
        <ConfirmationDialog
          title="Confirmation"
          message={`Are you sure you want to ${getDeleteActionName()}?`}
          onClose={() => setConfirmationAction(null)}
          onConfirm={confirmationAction}
        />
      )}{' '}
    </Fragment>
  );
};
