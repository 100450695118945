import {
  Collections,
  Firebase,
  Group,
  GroupView,
  selectGroupView,
  UniversalSnapshot,
} from '@ozark/common';
import {Domain} from '@ozark/functions/src/constants';
import {useEffect, useState} from 'react';
import {AsyncState} from '../models';

export const useGroupFromDomain = (domain: Domain) => {
  const [group, setGroup] = useState<AsyncState<GroupView>>({promised: true});
  useEffect(() => {
    let hostname = window.location.hostname;
    if (window.location.port) {
      hostname += `:${window.location.port}`;
    }
    const unsubscribe = Firebase.firestore
      .collection(Collections.groups)
      .where(domain, '==', hostname)
      .onSnapshot(
        async snapshot => {
          if (snapshot.size === 0) {
            console.error('No group was found');
            setGroup({
              promised: false,
              error: {
                name: 'No Group',
                code: 'cancelled',
                message: 'No Group',
              },
            });
            return;
          }
          const group = selectGroupView(snapshot.docs[0] as UniversalSnapshot<Group>);
          setGroup({promised: false, data: group});
        },
        err => {
          console.error(err);
          setGroup({promised: false, error: err});
        }
      );
    return () => {
      unsubscribe();
    };
  }, []);
  return {group};
};
