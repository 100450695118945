import {JobFrequency} from '@ozark/common';
import {ScheduleJobDetailedDialog} from './ScheduleJobDetailedDialog';
import {ScheduleJobSimpleDialog} from './ScheduleJobSimpleDialog';

export enum DialogMode {
  Detailed,
  Simple,
}
interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (data?: JobFrequency) => Promise<void>;
  existingFrequency?: JobFrequency;
  dialogMode?: DialogMode;
  simpleDialogMessage?: string;
  showSave?: boolean;
}

export const ScheduleJobDialog = ({
  open,
  onClose,
  onSubmit,
  existingFrequency,
  dialogMode,
  simpleDialogMessage,
  showSave,
}: Props) => {
  return (
    <>
      {dialogMode === DialogMode.Simple && (
        <ScheduleJobSimpleDialog
          open={open}
          onClose={onClose}
          onSubmit={onSubmit}
          message={simpleDialogMessage ?? ''}
          showSave={showSave ?? false}
        />
      )}
      {(dialogMode === undefined || dialogMode === DialogMode.Detailed) && (
        <ScheduleJobDetailedDialog
          open={open}
          onClose={onClose}
          onSubmit={onSubmit}
          existingFrequency={existingFrequency}
        />
      )}
    </>
  );
};
