import EmailIcon from '@mui/icons-material/Email';
import {LoadingButton} from '@mui/lab';
import {Menu, MenuItem} from '@mui/material';
import {SxProps} from '@mui/system';
import {
  JobFrequency,
  ReportingScheduledJobParams,
  ScheduledJobType,
  StatementReportingJobParams,
} from '@ozark/common';
import {useCallback, useState} from 'react';
import {useCallable, useNotification} from '../../hooks';
import {DialogMode, ScheduleJobDialog} from './ScheduleJobDialog';

export type ScheduleReportProps = {
  jobType: ScheduledJobType;
  getJobParameters: () => ReportingScheduledJobParams | StatementReportingJobParams;
};

export type ButtonEmailReportProps = {
  scheduleReportProps: ScheduleReportProps;
  disabled?: boolean;
  sx?: SxProps;
  hideSendNow?: boolean;
  dialogMode?: DialogMode;
  simpleDialogMessage?: string;
};

export const ButtonEmailReport = (props: ButtonEmailReportProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const [scheduleJobDialogOpen, setScheduleJobDialogOpen] = useState(false);
  const showNotification = useNotification();
  const {scheduleUserJob} = useCallable();
  const saveUserJob = useCallback(
    async (isOneTime: boolean, frequency?: JobFrequency): Promise<boolean> => {
      try {
        const scheduleUserJobResult = await scheduleUserJob({
          jobType: props.scheduleReportProps.jobType,
          frequency,
          isOneTime,
          jobParameters: props.scheduleReportProps.getJobParameters(),
        });
        if (scheduleUserJobResult.status === 'error') {
          showNotification('error', 'Failed to Save Data.');
          return false;
        }
        showNotification('success', 'Successfully saved request');
        return true;
      } catch (err: any) {
        console.error(`failed to save data`, err);
        showNotification('error', 'Failed to Save Data.');
      }
      return false;
    },
    [props.scheduleReportProps]
  );
  const onSubmit = useCallback(
    async (data?: JobFrequency) => {
      setLoading(true);
      const result = await saveUserJob(false, data);
      setLoading(false);
      if (result) {
        onClose();
      }
    },
    [saveUserJob]
  );
  const onClose = () => {
    setScheduleJobDialogOpen(false);
  };

  const handleClick = useCallback(async (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleSendNow = useCallback(async () => {
    setAnchorEl(null);
    await saveUserJob(true);
  }, [saveUserJob]);
  const handleCreateEmailScheduler = async () => {
    setAnchorEl(null);
    setScheduleJobDialogOpen(true);
  };

  return (
    <>
      <LoadingButton
        disabled={props.disabled || loading}
        loading={loading}
        id="positioned-button"
        onClick={handleClick}
        variant="contained"
        color="primary"
        loadingPosition="start"
        startIcon={<EmailIcon />}
        sx={props.sx}
      >
        Email
      </LoadingButton>
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {!props.hideSendNow && <MenuItem onClick={handleSendNow}>Send Now</MenuItem>}
        <MenuItem onClick={handleCreateEmailScheduler}>Schedule Emails</MenuItem>
      </Menu>
      <ScheduleJobDialog
        open={scheduleJobDialogOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        dialogMode={props.dialogMode}
        simpleDialogMessage={props.simpleDialogMessage}
        showSave={true}
      />
    </>
  );
};
