import * as yup from 'yup';
import {
  CustomerReceivesProductService,
  EbtAcceptanceStatus,
  ProductOwner,
  ProductPurchasedBy,
} from '../../../documents';
import {emptyStringToNull, getApiToBoolean} from '../../../helpers';
import {ProcessingTypes} from '../../ProcessingTypes';
import {RefundPolicy} from '../../RefundPolicy';
import {ShippingService} from '../../ShippingService';
import {
  getBooleanTypeError,
  getCustomErrorMessage,
  getNumberTypeError,
  oneOfAllowEmpty,
} from '../Common';

const minPercentsByProcessingTypes: Record<
  ProcessingTypes,
  {percentKeyed?: number; percentSwiped?: number; percentInternet?: number}
> = {
  // In Person
  [ProcessingTypes.cardPresent]: {
    percentSwiped: 70,
  },
  // Over the Phone
  [ProcessingTypes.cardNotPresent]: {
    percentKeyed: 70,
  },
  // Online Sales
  [ProcessingTypes.eCommerce]: {
    percentInternet: 100,
  },
} as const;

export const getBasicPageForAgentsValidationObjectCommon = (useCustomErrorMessage: boolean) => {
  return {
    percentSwiped: yup
      .number()
      .max(100)
      .required()
      .typeError(getNumberTypeError(useCustomErrorMessage, 'Percent Swiped is required'))
      .when(
        'processingType',
        (processingType: ProcessingTypes | undefined, fieldSchema: yup.NumberSchema) => {
          const min = processingType
            ? minPercentsByProcessingTypes[processingType]?.percentSwiped ?? 0
            : 0;
          return fieldSchema.min(
            min,
            getCustomErrorMessage(useCustomErrorMessage, `Min Percent Swiped is ${min}`)
          );
        }
      ),
    percentKeyed: yup
      .number()
      .max(100)
      .required()
      .typeError(getNumberTypeError(useCustomErrorMessage, 'Percent Keyed is required'))
      .when(
        'processingType',
        (processingType: ProcessingTypes | undefined, fieldSchema: yup.NumberSchema) => {
          const min = processingType
            ? minPercentsByProcessingTypes[processingType]?.percentKeyed ?? 0
            : 0;
          return fieldSchema.min(
            min,
            getCustomErrorMessage(useCustomErrorMessage, `Min Percent Keyed is ${min}`)
          );
        }
      ),
    percentInternet: yup
      .number()
      .min(0)
      .max(100)
      .required()
      .typeError(getNumberTypeError(useCustomErrorMessage, 'Percent Internet is required'))
      .when(
        'processingType',
        (processingType: ProcessingTypes | undefined, fieldSchema: yup.NumberSchema) => {
          const min = processingType
            ? minPercentsByProcessingTypes[processingType]?.percentInternet ?? 0
            : 0;
          return fieldSchema.min(
            min,
            getCustomErrorMessage(useCustomErrorMessage, `Min Percent Internet is ${min}`)
          );
        }
      ),
    percentTotal: yup.string().test(
      'percentTotal',
      ({path}) =>
        getCustomErrorMessage(
          useCustomErrorMessage,
          'Total must equal 100%',
          `${path}: percentSwiped + percentKeyed + percentInternet must equal 100%`
        ),
      (_, context) => {
        const {percentSwiped, percentKeyed, percentInternet} = context.parent;
        return percentSwiped + percentKeyed + percentInternet === 100;
      }
    ),
    percentB2b: yup
      .number()
      .min(0)
      .max(100)
      .required()
      .typeError(getNumberTypeError(useCustomErrorMessage, 'Percent B2B is required')),
    percentInternational: yup
      .number()
      .min(0)
      .max(100)
      .required()
      .typeError(getNumberTypeError(useCustomErrorMessage, 'Percent International is required')),
    acceptPIN: yup
      .boolean()
      .optional()
      .transform(getApiToBoolean(useCustomErrorMessage))
      .when('percentSwiped', (percentSwiped: number, schema: any) => {
        if (percentSwiped >= 70) {
          return schema.required(
            getCustomErrorMessage(useCustomErrorMessage, 'Accept PIN is required')
          );
        }
      }),
    ebtAcceptance: yup
      .string()
      .optional()
      .when('percentSwiped', (percentSwiped: number, schema: any) => {
        if (percentSwiped >= 70) {
          return schema.required(
            getCustomErrorMessage(useCustomErrorMessage, 'EBT Acceptance is required')
          );
        }
      })
      .test(oneOfAllowEmpty(Object.values(EbtAcceptanceStatus))),
    ebtFNS: yup.string().when('ebtAcceptance', {
      is: EbtAcceptanceStatus.Yes,
      then: schema =>
        schema.required().test(
          'empty-check',
          ({path}) =>
            getCustomErrorMessage(
              useCustomErrorMessage,
              'FNS number for EBT Food Stamps must be 7 digits',
              `${path} must be 7 digits`
            ),
          value => value === undefined || value === '' || value.length === 7
        ),
    }),
    customerReceivesProductService: yup
      .string()
      .when('processingType', (processingType: ProcessingTypes, schema: any) => {
        if (processingType !== ProcessingTypes.cardPresent) {
          return schema.required(
            getCustomErrorMessage(useCustomErrorMessage, 'Timeframe is required')
          );
        }
      })
      .test(oneOfAllowEmpty(Object.values(CustomerReceivesProductService))),
    refundPolicy: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Refund Policy is required'))
      .oneOf(Object.values(RefundPolicy)),
    refundPolicyExplained: yup
      .string()
      .when('refundPolicy', (refundPolicy: string, schema: any) => {
        if (refundPolicy === 'Other') {
          return schema.required(
            getCustomErrorMessage(useCustomErrorMessage, 'Explanation is required')
          );
        }
      }),
    previouslyProcessed: yup
      .boolean()
      .required()
      .typeError(getBooleanTypeError(useCustomErrorMessage, 'Previously Processed is required')),
    productOwner: yup
      .string()
      .when('processingType', (processingType: ProcessingTypes, schema: any) => {
        if (processingType === ProcessingTypes.eCommerce) {
          return schema.required(
            getCustomErrorMessage(useCustomErrorMessage, 'Product Owner is required')
          );
        }
      })
      .test(oneOfAllowEmpty(Object.values(ProductOwner))),
    isCustomerDepositRequired: yup
      .boolean()
      .transform(getApiToBoolean(useCustomErrorMessage))
      .when('processingType', (processingType: ProcessingTypes, schema: any) => {
        if (processingType === ProcessingTypes.eCommerce) {
          return schema.required(
            getCustomErrorMessage(useCustomErrorMessage, 'Customers Provide Deposit is required')
          );
        }
      }),
    customerDepositPercentage: yup
      .number()
      .min(0)
      .max(100)
      .transform(emptyStringToNull)
      .nullable()
      .when(['processingType', 'isCustomerDepositRequired'], {
        is: (processingType: ProcessingTypes, isCustomerDepositRequired: boolean) => {
          return processingType === ProcessingTypes.eCommerce && Boolean(isCustomerDepositRequired);
        },
        then: yup
          .number()
          .min(0)
          .max(100)
          .transform(emptyStringToNull)
          .nullable()
          .required(getCustomErrorMessage(useCustomErrorMessage, 'Deposit Percentage is required')),
      }),
    productPurchasedNames: yup.string().transform(emptyStringToNull).nullable(),
    productPurchasedBy: yup
      .string()
      .transform(emptyStringToNull)
      .nullable()
      .when('processingType', (processingType: ProcessingTypes, schema: any) => {
        if (processingType === ProcessingTypes.eCommerce) {
          return schema.required(
            getCustomErrorMessage(useCustomErrorMessage, 'Product Purchased By is required')
          );
        }
      })
      .test(oneOfAllowEmpty(Object.values(ProductPurchasedBy))),
    isFulfillmentCenterNamesDefined: yup
      .boolean()
      .optional()
      .when(['processingType'], {
        is: (processingType: ProcessingTypes) => {
          return processingType === ProcessingTypes.eCommerce;
        },
        then: yup.boolean().required(),
      }),
    fulfillmentCenterNames: yup
      .string()
      .when(['processingType', 'isFulfillmentCenterNamesDefined'], {
        is: (processingType: ProcessingTypes, isFulfillmentCenterNamesDefined: boolean) => {
          return (
            processingType === ProcessingTypes.eCommerce && Boolean(isFulfillmentCenterNamesDefined)
          );
        },
        then: yup
          .string()
          .required(
            getCustomErrorMessage(useCustomErrorMessage, 'Fulfillment Center Names is required')
          ),
      }),
    isCallCenterNamesDefined: yup
      .boolean()
      .optional()
      .when(['processingType'], {
        is: (processingType: ProcessingTypes) => {
          return processingType === ProcessingTypes.eCommerce;
        },
        then: yup.boolean().required(),
      }),
    callCenterNames: yup.string().when(['processingType', 'isCallCenterNamesDefined'], {
      is: (processingType: ProcessingTypes, isCallCenterNamesDefined: boolean) => {
        return processingType === ProcessingTypes.eCommerce && Boolean(isCallCenterNamesDefined);
      },
      then: yup
        .string()
        .required(getCustomErrorMessage(useCustomErrorMessage, 'Call Center Name is required')),
    }),
    isCbManagementSystemsNamesDefined: yup
      .boolean()
      .optional()
      .when(['processingType'], {
        is: (processingType: ProcessingTypes) => {
          return processingType === ProcessingTypes.eCommerce;
        },
        then: yup.boolean().required(),
      }),
    cbManagementSystemsNames: yup
      .string()
      .when(['processingType', 'isCbManagementSystemsNamesDefined'], {
        is: (processingType: ProcessingTypes, isCbManagementSystemsNamesDefined: boolean) => {
          return (
            processingType === ProcessingTypes.eCommerce &&
            Boolean(isCbManagementSystemsNamesDefined)
          );
        },
        then: yup
          .string()
          .required(
            getCustomErrorMessage(useCustomErrorMessage, 'CB Management System Names is required')
          ),
      }),
    shippingServices: yup
      .array()
      .of(yup.string().test(oneOfAllowEmpty(Object.values(ShippingService))))
      .when('processingType', (processingType: ProcessingTypes, schema: any) => {
        if (processingType === ProcessingTypes.eCommerce) {
          return schema
            .required(getCustomErrorMessage(useCustomErrorMessage, 'Shipping Services is required'))
            .min(1, getCustomErrorMessage(useCustomErrorMessage, 'Shipping Services is required'));
        }
      }),
    otherShippingServices: yup.string().when(['processingType', 'shippingServices'], {
      is: (processingType: ProcessingTypes, shippingServices: ShippingService[]) => {
        return (
          processingType === ProcessingTypes.eCommerce &&
          shippingServices?.includes(ShippingService.other)
        );
      },
      then: yup
        .string()
        .required(
          getCustomErrorMessage(useCustomErrorMessage, 'Other Shipping Method is required')
        ),
    }),
    estimatedMonthlyCreditCardSales: yup
      .number()
      .min(0)
      .required()
      .typeError(
        getNumberTypeError(useCustomErrorMessage, 'Monthly Credit Card Sales is required')
      ),
    estimatedMonthlyCreditCardSalesAmex: yup
      .number()
      .min(0)
      .required()
      .typeError(
        getNumberTypeError(useCustomErrorMessage, 'Monthly Amex Credit Card Sales is required')
      ),
    estimatedAverageSale: yup
      .number()
      .min(0)
      .required()
      .typeError(getNumberTypeError(useCustomErrorMessage, 'Average Sale is required')),
    estimatedHighestSale: yup
      .number()
      .when('estimatedAverageSale', (estimatedAverageSale: number, schema: any) => {
        return schema
          .required()
          .typeError(getNumberTypeError(useCustomErrorMessage, 'Highest Sale is required'))
          .moreThan(
            estimatedAverageSale,
            getCustomErrorMessage(
              useCustomErrorMessage,
              'Highest sale must be greater than average sale'
            )
          );
      }),
  };
};

export const getApiBasicPageForAgentsValidationObject = () => {
  return getBasicPageForAgentsValidationObjectCommon(false);
};
