import {
  Application,
  ApplicationDuplicate,
  ApplicationSubCollection,
  ApplicationView,
  AsyncState,
  Collections,
  Firebase,
  refreshAuditNonce,
  UniversalSnapshot,
} from '@ozark/common';
import {
  selectApplicationDuplicateView,
  selectApplicationView,
} from '@ozark/functions/src/documents/Application';
import {useEffect, useState} from 'react';

export const useApplicationView = (id: string) => {
  const [application, setApplication] = useState<AsyncState<ApplicationView>>({promised: true});
  useEffect(() => {
    setApplication({promised: true});

    const unsubscribe = Firebase.firestore
      .collection(Collections.applications)
      .doc(id)
      .onSnapshot(
        async applicationSnapshot => {
          if (!applicationSnapshot.exists) {
            setApplication({promised: false});
            return;
          }

          const data = selectApplicationView(applicationSnapshot as UniversalSnapshot<Application>);

          if (!data) {
            return;
          }

          try {
            const applicationDuplicatesSnapshot = await applicationSnapshot.ref
              .collection(ApplicationSubCollection.duplicates)
              .get();

            if (!applicationDuplicatesSnapshot.empty) {
              data.duplicates = applicationDuplicatesSnapshot.docs.map(x =>
                selectApplicationDuplicateView(x as UniversalSnapshot<ApplicationDuplicate>)
              );
            }
          } catch (err) {
            // do nothing, duplicates are allowed for erp users only, but applications are not
          }

          setApplication({promised: false, data});
        },
        err => {
          console.error(err);
          setApplication({promised: false, error: err});
        }
      );
    return () => {
      unsubscribe();
    };
  }, [id]);

  const set = async (id: string, application: Partial<Application>) => {
    try {
      await Firebase.firestore
        .collection(Collections.applications)
        .doc(id)
        .set(refreshAuditNonce(application.data.ref, Firebase.auth.currentUser!.uid), {
          merge: true,
        });
    } catch (err: any) {
      console.error('Failed to set application.', id, application);
      throw Error(err);
    }
  };

  return {application, set};
};

export const getApplicationFieldAsync = async (id: string, fieldName: string) => {
  try {
    const snap = await Firebase.firestore.collection(Collections.applications).doc(id).get();
    return snap.get(fieldName);
  } catch (err) {
    console.error(err);
  }
};
