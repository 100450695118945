import {useEffect, useState} from 'react';
import {CalendarEvent, Collections} from '../../../../..';
import {Firebase} from '../../../../../firebase';
import {useUserInfo} from '../../../../../hooks/useUserInfo';
import {mapCalendarEvents} from '../../../../Calendar/utils';
import {useTicketId} from '../../../hooks/useTicketId';

export function useTicketEvents() {
  const {uid} = useUserInfo();
  const {ticketId} = useTicketId();
  const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>([]);

  useEffect(() => {
    if (!ticketId) return;
    return Firebase.firestore
      .collection(Collections.calendar)
      .where('createdBy', '==', uid)
      .where('association.type', '==', 'Ticket')
      .where('association.id', '==', ticketId)
      .orderBy('dateTime', 'desc')
      .onSnapshot(
        snap => {
          setCalendarEvents(mapCalendarEvents(snap));
        },
        err => {
          console.error(err);
        }
      );
  }, [ticketId]);

  return {
    calendarEvents,
  };
}
