import {ResponsiveBar} from '@nivo/bar';
import {DailyVolumeSummary} from '@ozark/functions/src/functions/express/private/types';
import {VolumeFilterTypes} from '@ozark/functions/src/functions/express/private/types/Reports';
import {addDays, format} from 'date-fns';
import {VolumeFilter} from '../FilterPeriodSelect';

type GenerateGraphKey = (date: string) => string;
type GraphData = {key: string; value: number};

const formatDataForBarChart = (
  volumeSummary: DailyVolumeSummary[],
  selectedFilter: VolumeFilter
) => {
  let generateKey: GenerateGraphKey;

  // NOTE: group by weeks for 4week and MTD
  switch (selectedFilter.type) {
    case VolumeFilterTypes.OneWeek: {
      generateKey = (date: string) => format(addDays(new Date(date), 1), 'MMM dd');
      break;
    }
    case VolumeFilterTypes.MTD:
    case VolumeFilterTypes.QTD: {
      generateKey = (date: string) => format(new Date(date), 'MMM yy');
      break;
    }
    case VolumeFilterTypes.FourWeeks:
    case VolumeFilterTypes.YTD:
    case VolumeFilterTypes.OneYear: {
      generateKey = (date: string) => format(addDays(new Date(date), 1), 'MMM yy');
      break;
    }
    case VolumeFilterTypes.All: {
      generateKey = (date: string) => format(addDays(new Date(date), 1), 'MMM yy');
      break;
    }
    default: {
      break;
    }
  }

  const result = volumeSummary
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .map(row => ({
      key: generateKey(row.date),
      value: Number(row.salesAmount),
    }))
    .reduce((r, a) => {
      const objIndex = r.findIndex((obj: GraphData) => obj.key === a.key);
      const obj: GraphData = r[objIndex];

      if (objIndex > -1) {
        r[objIndex] = {
          key: obj.key,
          value: obj.value + a.value,
        };
      } else {
        r.push({key: a.key, value: a.value});
      }

      return r;
    }, [] as GraphData[]);
  return result;
};

type Props = {
  volumeData: DailyVolumeSummary[];
  selectedFilter: VolumeFilter;
};

export const VolumeBarGraph = ({volumeData, selectedFilter}: Props) => {
  const volumeDataForChart = formatDataForBarChart(volumeData, selectedFilter);

  return (
    <ResponsiveBar
      data={volumeDataForChart}
      keys={['value']}
      indexBy="key"
      margin={{
        top: 20,
        right: 0,
        bottom: 40,
        left: 96,
      }}
      padding={0.6}
      groupMode="stacked"
      colors="#2a7ef0"
      axisTop={null}
      axisRight={null}
      enableGridX={false}
      enableGridY
      enableLabel={false}
      animate={false}
      renderWrapper={true}
      axisBottom={{
        legendPosition: 'middle',
        tickSize: 0,
        tickPadding: 10,
      }}
      axisLeft={{
        // legend: 'Transactions',
        tickSize: 5,
        tickPadding: 25,
        tickRotation: 0,
        legendOffset: -85,
        legendPosition: 'middle',
        format: amount => Number(amount).toLocaleString(),
      }}
    />
  );
};
