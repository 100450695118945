import {
  AsyncState,
  AuthUserClaims,
  Collections,
  Firebase,
  Group,
  GroupView,
  selectGroupView,
  UniversalSnapshot,
} from '@ozark/common';
import firebase from 'firebase/compat/app';
import {useEffect, useState} from 'react';

export function useGroups(
  authUser: AsyncState<firebase.User | null> | undefined,
  claims?: AuthUserClaims | null | undefined
) {
  const [groups, setGroups] = useState<AsyncState<GroupView[]>>({
    promised: true,
  });

  const [selectedGroup, setSelectedGroup] = useState<GroupView>();

  useEffect(() => {
    if (!authUser?.data?.uid) return;

    // ERP only
    if (!claims) {
      return getAllGroups(setGroups);
    }
  }, [authUser?.data?.uid, claims]);

  return {
    groups,
    selectedGroup,
    handleSelectGroup: (group?: GroupView) => setSelectedGroup(group),
  };
}

/** ERP option */
const getAllGroups = (setGroups: (mids: AsyncState<GroupView[]>) => void) => {
  return Firebase.firestore.collection(Collections.groups).onSnapshot(
    snapshot => {
      if (snapshot.empty) {
        setGroups({promised: false});
        return;
      }

      const groups = snapshot.docs.map(doc => selectGroupView(doc as UniversalSnapshot<Group>));

      setGroups({
        promised: false,
        data: groups,
      });
    },
    err => {
      console.error(err);
      setGroups({promised: false, error: err});
    }
  );
};
