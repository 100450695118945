import {
  selectTicketTypeView,
  TicketType,
  TicketTypeView,
} from '@ozark/functions/src/documents/TicketType';
import {useEffect, useState} from 'react';
import {AsyncState, Collections, Firebase, UniversalSnapshot, useNotification} from '../../..';

const statePromised: AsyncState<TicketTypeView> = {
  promised: true,
};

export function useTicketType(typeId: string) {
  const [ticketType, setTicketType] = useState(statePromised);
  const showNotification = useNotification();

  useEffect(() => {
    let query = Firebase.firestore.collection(Collections.ticketTypes).doc(typeId);

    return query.onSnapshot(
      async snapshot => {
        if (!snapshot.exists) {
          setTicketType({promised: false, data: undefined});
          return;
        }
        const docRef = await snapshot.ref.get();
        const ticketType: TicketTypeView = selectTicketTypeView(
          docRef as UniversalSnapshot<TicketType>
        );
        setTicketType({promised: false, data: ticketType});
      },
      err => {
        showNotification('error', err.message);
        setTicketType({promised: false, error: err});
      }
    );
  }, []);

  return {
    ticketType,
  };
}
