import {yupResolver} from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import {useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useHistory} from 'react-router';
import * as yup from 'yup';
import {useNotification} from '../../hooks';
import {TextField} from '../TextField';

const schema = yup.object().shape({
  code: yup.string().trim().required('Code is required'),
});

type FormData = {code: string};

interface Props {
  confirmCode: (code: string) => Promise<boolean>;
  sendMfaCode: () => Promise<boolean>;
  back: () => void;
  returnTo: string | undefined;
}

export const ConfirmCode = ({confirmCode, sendMfaCode, back, returnTo}: Props) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const showNotification = useNotification();
  const history = useHistory();

  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  const gotoLogin = () => {
    history.replace('login');
  };

  const goToBack = () => {
    back();
  };

  const onSendMfaCode = async () => {
    try {
      setLoading(true);
      await sendMfaCode();
      showNotification('success', `Verification code resent`);
    } finally {
      setLoading(false);
    }
  };

  const onConfirmCode: SubmitHandler<FormData> = async (data: FormData) => {
    try {
      setLoading(true);

      const success = await confirmCode(data.code);
      if (!success) {
        return;
      }

      showNotification('success', `Verification code confirmed`);

      history.replace(returnTo ?? '');
    } catch (err) {
      console.error(err);

      showNotification('error', 'Verification code incorrect');

      setError('There was an error during confirming the code.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <FormControl
        sx={{
          width: '100%',
          marginTop: theme => theme.spacing(1),
        }}
      >
        <TextField
          fullWidth
          autoComplete="code"
          control={control}
          error={Boolean(error) || Boolean(errors.code)}
          errors={errors}
          helperText={errors.code?.message}
          label="Code"
          margin="normal"
          name="code"
          variant="outlined"
          required
        />

        <LoadingButton
          fullWidth
          sx={{margin: theme => theme.spacing(3, 0, 2)}}
          color="primary"
          loading={loading}
          variant="contained"
          onClick={handleSubmit(onConfirmCode)}
        >
          Confirm
        </LoadingButton>

        <Grid container>
          <Grid item xs={4}>
            <Link sx={{textDecoration: 'none'}} component="button" onClick={goToBack}>
              Back
            </Link>
          </Grid>
          <Grid item xs={4} sx={{textAlign: 'center'}}>
            <Link sx={{textDecoration: 'none'}} component="button" onClick={onSendMfaCode}>
              Resend code
            </Link>
          </Grid>
          <Grid item xs={4} sx={{textAlign: 'right'}}>
            <Link sx={{textDecoration: 'none'}} component="button" onClick={gotoLogin}>
              Go to login page
            </Link>
          </Grid>
        </Grid>
      </FormControl>
    </>
  );
};
