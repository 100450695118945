export const DashboardIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g fill={'inherit'}>
        <path
          d="M288 32C128.9 32 0 160.9 0 320c-.1 50.9 13.4 100.9 39.1 144.8 5.6 9.6 16.3 15.2 27.4 15.2h443c11.1 0 21.8-5.6 27.4-15.2C562.5 420.9 576 370.9 576 320c0-159.1-128.9-288-288-288zm0 64c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zM96 384c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm247.1 32H232.9c-12-20-11.8-45 .5-64.9L124.8 206.4c-8-10.6-5.8-25.7 4.8-33.6s25.7-5.8 33.6 4.8l108.6 144.7c33.8-9.2 68.7 10.7 78 44.5 4.5 16.7 2.1 34.4-6.7 49.2zM400 192c0-17.7 14.3-32 32-32s32 14.3 32 32-14.3 32-32 32-32-14.3-32-32zm80 192c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"
          opacity={props.opacity || 0.4}
        />
        <path d="M343.1 416H232.9c-12-20-11.8-45 .5-64.9L124.8 206.4c-8-10.6-5.8-25.7 4.8-33.6s25.7-5.8 33.6 4.8l108.6 144.7c33.8-9.2 68.7 10.7 78 44.5 4.5 16.7 2.1 34.4-6.7 49.2z" />
      </g>
    </svg>
  );
};
