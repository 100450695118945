import InfoIcon from '@mui/icons-material/Info';
import {ListItemIcon} from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import {Fragment} from 'react';

export type TInfoItem = {
  title: string;
  lines: string[];
};

type InfoItemProps = {
  infoItem: TInfoItem;
};

const InfoItem = ({infoItem}: InfoItemProps) => {
  return (
    <ListItem alignItems="flex-start">
      <ListItemIcon>
        <InfoIcon fontSize="large" color="info" />
      </ListItemIcon>
      <ListItemText
        primary={infoItem.title}
        secondary={
          <>
            {infoItem.lines.map((line, idx) => (
              <Typography key={idx} variant="body2">
                {line ?? ''}
              </Typography>
            ))}
          </>
        }
      />
    </ListItem>
  );
};

type Props = {
  infoItems: {
    title: string;
    lines: string[];
  }[];
};

export function InfoItemsList({infoItems}: Props) {
  if (!infoItems.length) return null;
  return (
    <List sx={{width: '100%'}}>
      <InfoItem infoItem={infoItems[0]} />
      {infoItems.slice(1).map(infoItem => (
        <Fragment key={infoItem.title}>
          <Divider variant="inset" component="li" />
          <InfoItem infoItem={infoItem} />
        </Fragment>
      ))}
    </List>
  );
}
