import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import WarningIcon from '@mui/icons-material/Warning';
import {LoadingButton} from '@mui/lab';
import {Box, IconButton, Zoom} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import {Theme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  NotificationCategory,
  NotificationStatus,
  NotificationType,
  NotificationView,
} from '@ozark/common';
import React, {Fragment, useCallback, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {getNotificationDetailsRoute} from '../NotificationsPage/routes';
import {FilterTabs} from './FilterTabs';
import {useNotificationsState} from './hooks/useNotificationsState';
import {ItemCountdownTimer, Svg} from './ItemCountdownTimer';
import {ItemRemoveAnimator} from './ItemRemoveAnimator';
import {RenderNotificationText} from './Notifications';

export const NotificationsList = () => {
  const classes = useStyles();
  const history = useHistory();
  const {items, markRead, markReadAll, isUnreadView, isMarkingAsRead} = useNotificationsState();
  const [timeoutOn, setTimeoutOn] = useState(
    isUnreadView && items.some(i => i.status !== NotificationStatus.read)
  );
  const [timeoutItemId, setTimeoutItemId] = useState(
    items.find(i => i.status !== NotificationStatus.read)?.id
  );
  const [removedItemId, setRemovedItemId] = useState<string>();

  const disabled = !items.length || items.every(i => i.status === NotificationStatus.read);

  const idTodayFirst = items.find(
    i => i.createdAt.toDate().toDateString() === new Date().toDateString()
  )?.id;
  const idBeforeFirst = items.find(
    i => i.createdAt.toDate().toDateString() !== new Date().toDateString()
  )?.id;

  const handleItemClick = ({id, link, category}: NotificationView, isRead: boolean) => {
    !isRead && markRead(id);

    if (category === NotificationCategory.Announcements) {
      history.push(getNotificationDetailsRoute(id, category));
      return;
    }

    if (!link) {
      return;
    }

    if (link.startsWith('/')) {
      history.push(link);
    } else {
      window.open(link, '_blank', 'noopener');
    }
  };

  const handleReadAll = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!isMarkingAsRead) {
      await markReadAll();
    }
    e && setTimeoutOn(false);
  };

  const handleReadDelayed = useCallback(
    (id: string) => {
      setRemovedItemId(undefined);
      markRead(id);
    },
    [markRead]
  );

  const handleCheck = useCallback(
    (id: string, e?: React.MouseEvent) => {
      e && e.stopPropagation();
      e && setTimeoutOn(false); // user click disable timeouts

      if (isUnreadView) {
        setRemovedItemId(id);
      } else {
        markRead(id);
      }
    },
    [isUnreadView, markRead]
  );

  const handleOnTimeout = useCallback(
    (id: string) => {
      handleCheck(id);
      if (timeoutOn) {
        // removed item is still unread - so we filter it out and check for next
        const nextTimeoutItemId = items.find(
          i => i.status !== NotificationStatus.read && i.id !== id
        )?.id;
        if (nextTimeoutItemId) {
          setTimeoutItemId(nextTimeoutItemId);
        } else {
          setTimeoutOn(false);
        }
      }
    },
    [handleCheck]
  );

  return (
    <div className={classes.container}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={classes.text} variant="h5" gutterBottom>
          Notifications
        </Typography>
        <Box display="flex" alignItems="center" pr={1} pt={1.25}>
          <LoadingButton
            size="small"
            variant="text"
            disabled={disabled}
            onClick={e => handleReadAll(e)}
            loading={isMarkingAsRead}
          >
            Mark All Read
          </LoadingButton>
        </Box>
      </Box>
      <FilterTabs />
      {!items.length && (
        <Zoom in>
          <Box textAlign="center" py={8}>
            <Box sx={{position: 'relative', display: 'flex', justifyContent: 'center', mb: 1.5}}>
              <NotificationsNoneIcon
                sx={{color: theme => theme.palette.grey[400], fontSize: '80px'}}
              />
              <CheckCircleOutlineIcon
                sx={{
                  position: 'absolute',
                  top: '25%',
                  left: '51%',
                  fontSize: '26px',
                  color: theme => theme.palette.grey[400],
                  backgroundColor: '#fff',
                  borderRadius: '50%',
                }}
              />
            </Box>
            <Typography sx={{color: theme => theme.palette.grey[600]}}>
              You're all caught up
            </Typography>
          </Box>
        </Zoom>
      )}
      <List className={classes.list}>
        {items.map(notification => {
          const {id, title, text, status, type} = notification;
          const isRead = status === NotificationStatus.read || removedItemId === id;
          const showTimeout = timeoutOn && timeoutItemId === id && !isRead;
          return (
            <Fragment key={id}>
              {id === idTodayFirst && (
                <ListSubheader className={classes.subheader}>Today</ListSubheader>
              )}
              {id === idBeforeFirst && (
                <ListSubheader className={classes.subheader}>Older</ListSubheader>
              )}
              <ItemRemoveAnimator
                itemId={id}
                removedItemId={removedItemId}
                onItemRemoved={handleReadDelayed}
              >
                <ListItem
                  button
                  alignItems="flex-start"
                  style={{paddingLeft: 12}}
                  onClick={() => handleItemClick(notification, isRead)}
                >
                  <Box pl={0.4} pr={1.5}>
                    <IconButton
                      aria-label="mark as read button"
                      title={isRead ? 'Mark as Unread' : 'Mark as Read'}
                      onClick={e => handleCheck(id, e)}
                      size="small"
                      className={classes.icon}
                    >
                      {!showTimeout && isRead && <CheckIcon />}
                      {!showTimeout && !isRead && <IconNew />}
                      {showTimeout && (
                        <ItemCountdownTimer itemId={id} onTimeout={handleOnTimeout} />
                      )}
                    </IconButton>
                  </Box>
                  <ListItemText
                    primary={getNotificationTitle(title, type)}
                    secondary={
                      text && (
                        <span style={{whiteSpace: 'pre-line', wordBreak: 'break-word'}}>
                          {RenderNotificationText(text)}
                        </span>
                      )
                    }
                    color="#eee"
                    classes={{
                      primary: isRead ? classes.primaryRead : classes.primaryUnread,
                      secondary: text?.includes(' ')
                        ? classes.textSecondaryClamp
                        : classes.textSecondaryEllipsis,
                    }}
                  />
                </ListItem>
              </ItemRemoveAnimator>
            </Fragment>
          );
        })}
      </List>
    </div>
  );
};

export const IconNew = () => {
  return (
    <Svg viewBox="0 0 24 24" focusable="false">
      <circle style={{fill: '#1877f2'}} r="4" cx="12" cy="12" />
    </Svg>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginTop: 5,
      padding: 7,
    },
    text: {
      padding: theme.spacing(2, 2, 0),
    },
    subheader: {
      backgroundColor: theme.palette.background.paper,
    },
    container: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    list: {
      paddingTop: 0,
      marginBottom: theme.spacing(2),
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    textSecondaryClamp: {
      overflow: 'hidden',
      maxHeight: '6em',
      display: '-webkit-box',
      '-webkit-line-clamp': 4,
      '-webkit-box-orient': 'vertical',
    },
    textSecondaryEllipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    primaryUnread: {
      color: 'inherit',
    },
    primaryRead: {
      color: '#616161de',
    },
  })
);

const getNotificationTitle = (title: string, type?: NotificationType): JSX.Element | string => {
  if (!type || type === NotificationType.information) {
    return title;
  }

  let icon: JSX.Element | null = null;

  if (type === NotificationType.warning) {
    icon = <WarningIcon color="warning" sx={{verticalAlign: 'middle', mr: 0.5}} />;
  } else if (type === NotificationType.success) {
    icon = <CheckCircleIcon color="success" sx={{verticalAlign: 'middle', mr: 0.5}} />;
  } else if (type === NotificationType.error) {
    icon = <ErrorIcon color="error" sx={{verticalAlign: 'middle', mr: 0.5}} />;
  }

  if (icon) {
    return (
      <>
        {icon}
        {title}
      </>
    );
  }

  return title;
};
