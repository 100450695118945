import {TextField, TextFieldProps} from '@mui/material';
import {Controller, ControllerRenderProps} from 'react-hook-form';

type TextFieldOps = Omit<TextFieldProps, 'name' | 'label' | keyof ControllerRenderProps>;

type TextFieldStyledProps = {
  name: string;
  label: string;
} & TextFieldOps;

export const TextFieldHooked = ({name, label, ...userProps}: TextFieldStyledProps) => {
  return (
    <Controller
      name={name}
      render={({field: {ref, ...hookFormProps}, fieldState}) => (
        <TextField
          label={label}
          inputRef={ref}
          error={Boolean(fieldState.error)}
          helperText={fieldState.error?.message}
          variant="outlined"
          fullWidth
          {...hookFormProps}
          {...userProps}
        />
      )}
    />
  );
};
