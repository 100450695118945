import EditIcon from '@mui/icons-material/Edit';
import {IconButton, TableCell} from '@mui/material';
import {ResourceView} from '@ozark/common';
import {useDisplayContext} from './DisplayContext';

interface Props {
  resourceView: ResourceView;
}
export const DisplayEditButton = ({resourceView}: Props) => {
  const {allowEdit, handleEditClick, writePermissionsForResource} = useDisplayContext();
  const canUpdate = writePermissionsForResource(resourceView);
  const button = (
    <IconButton size="small" onClick={() => handleEditClick(resourceView)}>
      <EditIcon />
    </IconButton>
  );

  if (!allowEdit || !canUpdate) {
    return <TableCell></TableCell>;
  }

  return <TableCell>{button}</TableCell>;
};
