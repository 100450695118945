import AddIcon from '@mui/icons-material/Add';
import {Box, IconButton, Tooltip} from '@mui/material';
import {useCallback, useState} from 'react';
import {CalendarEvent} from '../../../..';
import {useUserInfo} from '../../../../hooks/useUserInfo';
import {CalendarEventForm} from '../../../Calendar/CalendarEventForm';
import {calendarEventAdd} from '../../../Calendar/utils';
import {useIsEditingDisabled} from '../../hooks/useIsEditingDisabled';
import {useNewTicketEvent} from './hooks/useNewTicketEvent';
import {useTicketEvents} from './hooks/useTicketCalendarEvents';
import {TicketCalendarEventInfo} from './TicketCalendarEventInfo';

export const TicketCalendarEvents = () => {
  const {uid} = useUserInfo();
  const [open, setOpen] = useState(false);
  const {isEditingDisabledStatus} = useIsEditingDisabled();
  const {calendarEvents: events} = useTicketEvents();
  const {newTicketEvent} = useNewTicketEvent();

  const handleCalendarEventAdd = useCallback(
    async (calendarEvent: CalendarEvent) => {
      try {
        const eventId = await calendarEventAdd({
          ...calendarEvent,
          createdBy: uid,
          createdAt: new Date(),
        });
        return eventId;
      } catch (error) {
        console.error(error);
      }
    },
    [uid]
  );

  return (
    <Box display="flex" alignItems="flex-start">
      <Box flex={1}>
        {events?.map(event => (
          <TicketCalendarEventInfo key={event.id} calendarEvent={event} />
        ))}
        {!events?.length && <Box pt={1}>...</Box>}
      </Box>
      <Box>
        <Tooltip title="Add Reminder">
          <span>
            <IconButton onClick={() => setOpen(true)} disabled={isEditingDisabledStatus}>
              <AddIcon />
            </IconButton>
          </span>
        </Tooltip>
        {newTicketEvent && (
          <CalendarEventForm
            title="Create Reminder"
            open={open}
            setOpen={setOpen}
            onSubmit={handleCalendarEventAdd}
            event={newTicketEvent}
          />
        )}
      </Box>
    </Box>
  );
};
