import {useHistory, useLocation, useParams} from 'react-router-dom';
import {TicketStatus} from '../../..';
import {useUrlQuery} from '../../../hooks/useUrlQuery';
import {ticketStatusFolders} from '../constants/ticketStatusFolders';
import {FilterKey, TicketsFolderId} from '../types';

export type TicketsSortBy = 'createdAt' | 'openedAt';
export type TicketsSortDir = 'asc' | 'desc';

export function useTicketsQuery_old() {
  const {status} = useParams<{status: TicketStatus}>();
  const query = useUrlQuery();

  let sortDir = (query.get('sortDir') ?? 'desc') as TicketsSortDir;
  let sortBy = (
    query.get('sortBy') ?? status === TicketStatus.Draft ? 'createdAt' : 'openedAt'
  ) as TicketsSortBy;

  sortBy = status === TicketStatus.Draft && sortBy === 'openedAt' ? 'createdAt' : sortBy;
  const filterKey = (query.get('filterKey') ?? undefined) as FilterKey | undefined;

  return {
    status,
    sortDir,
    sortBy,
    filterKey,
  };
}

export function useTicketsQuery() {
  const history = useHistory();
  const {pathname, search} = useLocation();
  const {view} = useParams<{view: TicketsFolderId}>();
  const urlParams = new URLSearchParams(search);

  const getParam = (name: string) => {
    return urlParams.get(name);
  };
  const setParam = (params: Record<string, string>) => {
    if (!params) return;
    Object.keys(params).forEach(key => {
      urlParams.set(key, params[key]);
    });
    history.push({pathname, search: urlParams.toString()});
  };

  const sortDir = (urlParams.get('sortDir') ?? 'desc') as TicketsSortDir;
  const sortBy = (
    urlParams.get('sortBy') ?? view === TicketsFolderId.Draft ? 'createdAt' : 'openedAt'
  ) as TicketsSortBy;
  const filterKey = (urlParams.get('filterKey') ?? undefined) as FilterKey | undefined;

  const statusSet = ticketStatusFolders.find(f => f.id === view)?.statusSet ?? [];

  return {
    getParam,
    setParam,
    statusSet,
    sortDir,
    sortBy,
    folderId: view,
    filterKey,
  };
}
