import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Button} from '@mui/material';
import {PLAID_CALLBACK} from '@ozark/app/src/constants/routes';
import {isEqual} from 'lodash';
import React, {memo, useEffect} from 'react';
import {
  PlaidLinkError,
  PlaidLinkOnEventMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptionsWithLinkToken,
  PlaidLinkStableEvent,
  usePlaidLink,
} from 'react-plaid-link';
import {useHistory} from 'react-router-dom';

interface Props {
  isOauth?: boolean;
  token: string;
  children?: React.ReactNode;
  onSuccess: (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => void;
  size: 'small' | 'medium' | 'large';
  secondary: boolean;
  refreshToken: () => Promise<void>;
}

// Uses the usePlaidLink hook to manage the Plaid Link creation.  See https://github.com/plaid/react-plaid-link for full usage instructions.
// The link token passed to usePlaidLink cannot be null.  It must be generated outside of this component.

const PlaidLinkButtonComponent = (props: Props) => {
  const history = useHistory();

  const onExit = async (error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
    if (error != null && error.error_code === 'INVALID_LINK_TOKEN') {
      await props.refreshToken();
    }

    if (error != null) {
      console.error(error.error_code, error.display_message || error.error_message);
    }

    // When user cancels the bank selection modal window, redirect to the initial
    // page while removing oauth query string and refresh link token (existing token will not work again).
    if (metadata.status === 'requires_account_selection') {
      history.push({
        pathname: history.location.pathname,
      });
      await props.refreshToken();
    }
    // to handle other error codes, see https://plaid.com/docs/errors/
  };

  const onEvent = async (
    eventName: PlaidLinkStableEvent | string,
    metadata: PlaidLinkOnEventMetadata
  ) => {
    // handle errors in the event end-user does not exit with onExit function error enabled.
    if (eventName === 'ERROR' && metadata.error_code != null) {
      console.error(metadata.error_code);
    }
  };

  const config: PlaidLinkOptionsWithLinkToken = {
    onSuccess: props.onSuccess,
    onExit,
    onEvent,
    token: props.token,
  };

  if (props.isOauth) {
    config.receivedRedirectUri = `${window.location.origin}${PLAID_CALLBACK}${window.location.search}`;
  }

  const {open, ready} = usePlaidLink(config);

  useEffect(() => {
    // initiallizes Link automatically if it is handling an OAuth redirect
    if (props.isOauth && ready) {
      open();
    }
  }, [ready, open, props.isOauth]);

  const handleClick = () => {
    // regular, non-OAuth case:
    // set link token, userId and itemId in local storage for use if needed later by OAuth

    sessionStorage.setItem('link_token', props.token);

    open();
  };

  return (
    <>
      {props.isOauth ? (
        // no link button rendered: OAuth will open automatically by useEffect above
        <></>
      ) : (
        // regular case for initializing Link
        <Button
          variant="contained"
          size={props.size}
          color={props.secondary ? 'secondary' : 'primary'}
          style={{fontSize: props.secondary ? '1em' : '2em'}}
          onClick={() => {
            handleClick();
          }}
          endIcon={<ArrowForwardIosIcon />}
        >
          {props.children}
        </Button>
      )}
    </>
  );
};

export const PlaidLinkButton = memo(PlaidLinkButtonComponent, isEqual);
