import {
  Collections,
  Firebase,
  Merchant,
  MerchantView,
  selectMerchantView,
  UniversalSnapshot,
} from '..';

export const getMerchantsByMids = async (mids: string[]): Promise<MerchantView[]> => {
  if (!mids?.length) return [];

  const midsFilter = [...mids];
  let batches = [];

  while (midsFilter.length) {
    const batch = midsFilter.splice(0, 10);

    batches.push(
      Firebase.firestore
        .collection(Collections.merchants)
        .where('applicationMids', 'array-contains-any', batch)
        .get()
        .then(snapshot =>
          snapshot.docs.map(doc => selectMerchantView(doc as UniversalSnapshot<Merchant>))
        )
    );
  }

  return (await Promise.all(batches)).flatMap(merchant => merchant);
};
