import {UniversalTimestamp} from './compat';
import {DocumentBase} from './DocumentBase';

export const AllMIDs = 'all';

export type Mid = DocumentBase & {
  mid: string;
  createdAt: UniversalTimestamp;
  groupId: string;
  dba: string;
};
