import {Autocomplete, Box, TextField} from '@mui/material';
import {omit, sortBy} from 'lodash';
import {useEffect, useState} from 'react';
import {TicketAssociation} from '../../../..';
import {useErpUserAgents, usePortalUserAgents} from '../../../../hooks/useAgents';
import {useUserInfo} from '../../../../hooks/useUserInfo';
import {FIELD_NAME_AGENT} from '../../constants/constants';
import {InputAssociationError} from '../../types';
import {AssociationEditorValueGroup} from './AssociationEditorValueGroup';

type AgentOption = TicketAssociation['agent'];

type Props = {
  draft: TicketAssociation;
  setDraft: (draft: TicketAssociation) => void;
  errors: Record<InputAssociationError, string>;
  setErrors: (errors: Record<InputAssociationError, string>) => void;
};

export const AssociationEditorValueGroupAgent = ({draft, setDraft, errors, setErrors}: Props) => {
  const {isErpUser} = useUserInfo();
  const {agents} = isErpUser
    ? useErpUserAgents(draft.group?.id)
    : usePortalUserAgents(draft.group?.id, true);
  const [agentOptions, setAgentOptions] = useState<AgentOption[]>([]);

  useEffect(() => {
    let isMounted = true;
    if (agents.data) {
      const options = sortBy(
        agents.data.map(agentView => ({
          id: agentView.id,
          name: `${agentView.firstName ?? ''} ${agentView.lastName ?? ''}`.trim(),
        })),
        e => e.name
      );
      isMounted && setAgentOptions(options);
    }
    return () => {
      isMounted = false;
    };
  }, [agents]);

  return (
    <>
      <AssociationEditorValueGroup
        draft={draft}
        setDraft={setDraft}
        errors={errors}
        setErrors={setErrors}
      />
      <Box mt={2}>
        <Autocomplete
          value={draft.agent}
          onChange={async (
            _: React.SyntheticEvent<Element, Event>,
            newValue: AgentOption | null
          ) => {
            setDraft({...draft, agent: newValue ?? undefined});
            if (newValue) {
              setErrors(omit(errors, FIELD_NAME_AGENT) as Record<InputAssociationError, string>);
            }
          }}
          disabled={!draft.group?.id || !agentOptions.length}
          options={agentOptions}
          getOptionLabel={(item: AgentOption) => item?.name ?? ''}
          renderInput={params => (
            <TextField
              {...params}
              label="Associated Agent"
              required
              variant="standard"
              error={Boolean(errors[FIELD_NAME_AGENT])}
              helperText={errors[FIELD_NAME_AGENT]}
            />
          )}
        />
      </Box>
    </>
  );
};
