import {Typography} from '@mui/material';
import {MidsByChargebacksTable, MidsByChargebacksTableDetails} from '@ozark/common/components';
import React, {useState} from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';

const TITLE = "Top 10 MID's by Chargebacks";

export const MidsByChargebacksPage = () => {
  const {path} = useRouteMatch();
  const [breadcrumbs, setBreadcrumbs] = useState<React.ReactNode[]>([
    <Typography variant="body1">{TITLE}</Typography>,
  ]);

  return (
    <Switch>
      <Route path={path} exact>
        <MidsByChargebacksTable breadcrumbs={breadcrumbs} setBreadcrumbs={setBreadcrumbs} />
      </Route>
      <Route path={`${path}/:mid`}>
        <MidsByChargebacksTableDetails breadcrumbs={breadcrumbs} setBreadcrumbs={setBreadcrumbs} />
      </Route>
    </Switch>
  );
};
