/* eslint-disable react-hooks/exhaustive-deps */
import {
  AsyncState,
  Collections,
  Firebase,
  UniversalSnapshot,
  ViewSelector,
  ViewTypes,
} from '@ozark/common';
import {forEach, reduce} from '@s-libs/micro-dash';
import firebase from 'firebase/compat/app';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

export type InfiniteSnapshotFirstOrderBy = {
  orderBy: string | firebase.firestore.FieldPath;
  order: 'asc' | 'desc';
};

export type InfiniteSnapshotOptions = {
  limit: number;
  orderBy: string | firebase.firestore.FieldPath;
  order: 'asc' | 'desc';
  firstOrderBy?: InfiniteSnapshotFirstOrderBy[];
  startAfter?: any;
  filter?: (
    query:
      | firebase.firestore.Query<firebase.firestore.DocumentData>
      | firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
  ) => firebase.firestore.Query<firebase.firestore.DocumentData>;
  clientSideFilter?: (item: any) => boolean;
  disableUsingCache?: boolean;
  displayDeleted?: boolean;
};

type InfiniteSnapshotsHooks<TView extends ViewTypes> = {
  documents: AsyncState<{[_: string]: TView}> & {
    hasNextPage: boolean;
    lastDoc?: TView;
    size: number;
    refreshCount: number;
  };
  next: (options?: Partial<InfiniteSnapshotOptions>, onLoaded?: () => void) => void;
};

export const useInfiniteSnapshotsFromRef = <TView extends ViewTypes>(
  collectionRef: firebase.firestore.CollectionReference | null,
  viewSelector: ViewSelector<TView>,
  options?: Partial<InfiniteSnapshotOptions>
): InfiniteSnapshotsHooks<TView> => {
  const [documents, setDocuments] = useState<InfiniteSnapshotsHooks<TView>['documents']>({
    promised: true,
    data: {},
    hasNextPage: true,
    lastDoc: undefined,
    size: 0,
    refreshCount: 0,
  });

  const initialOptions: InfiniteSnapshotOptions = {
    limit: 10,
    orderBy: options?.orderBy || 'createdAt',
    order: options?.order || 'asc',
    ...options,
  };

  const observerArrayRef = useRef<(() => void)[]>([]);

  const registerBatch = useCallback(
    (options?: Partial<InfiniteSnapshotOptions>, onLoaded?: () => void) => {
      if (!collectionRef) {
        return;
      }

      const _options: InfiniteSnapshotOptions = {
        ...(initialOptions as InfiniteSnapshotOptions),
        ...options,
      };

      const {
        firstOrderBy,
        orderBy,
        order,
        limit,
        filter,
        clientSideFilter,
        startAfter,
        disableUsingCache,
        displayDeleted,
      } = _options;

      const ref = collectionRef;

      if (filter) {
        var query = filter(ref);
      } else {
        query = ref;
      }

      if (firstOrderBy) {
        firstOrderBy.map(filter => {
          query = query.orderBy(filter.orderBy, filter.order);
        });
      }

      query = query.orderBy(orderBy, order).limit(limit);

      if (startAfter) {
        query = query.startAfter(startAfter);
      }

      const observer = query.onSnapshot(
        {includeMetadataChanges: disableUsingCache},
        snapshot => {
          if (snapshot.metadata.fromCache && disableUsingCache) {
            return;
          }

          if (snapshot.size === 0) {
            setDocuments({
              ...documents,
              promised: false,
              hasNextPage: false,
              lastDoc: undefined,
            });
            return;
          }

          // Object.keys() preserves order
          // https://www.stefanjudis.com/today-i-learned/property-order-is-predictable-in-javascript-objects-since-es2015/
          // https://stackoverflow.com/questions/30076219/does-es6-introduce-a-well-defined-order-of-enumeration-for-object-properties
          const oldData = documents.data || {};
          const ids = Object.keys(oldData);

          // keep old data
          const newData = reduce(
            ids,
            (carry, id) => {
              carry[id] = oldData[id];
              return carry;
            },
            {} as typeof oldData
          );

          let lastDoc: TView | undefined = undefined;

          // add new elements
          forEach(
            snapshot.docs.filter(doc => !!doc.data()),
            doc => {
              const selectedDoc = viewSelector(doc as UniversalSnapshot<TView>);

              // after we move document to JS array, we loose order because of key sorting
              lastDoc = selectedDoc;

              if (!selectedDoc || (selectedDoc.deleted === true && !displayDeleted)) {
                return;
              }

              // client side filter for cases where impossible to perform query on Firestore
              if (!clientSideFilter || clientSideFilter(selectedDoc)) {
                newData[doc.id] = selectedDoc;
              }
            }
          );

          setDocuments({
            ...documents,
            promised: false,
            data: newData,
            lastDoc,
            size: Object.keys(newData).length,
          });
        },
        err => {
          console.error('Error getting documents. ' + err.toString());
          setDocuments({
            ...documents,
            promised: false,
            error: err,
            lastDoc: undefined,
          });
        }
      );
      observerArrayRef.current.push(observer);
      onLoaded?.();
    },
    [collectionRef, documents, documents.data, documents.hasNextPage, documents.size]
  );

  useEffect(() => {
    registerBatch(options);
  }, [documents.refreshCount]);

  useEffect(() => {
    return () => {
      observerArrayRef.current.forEach(unsubscribe => {
        unsubscribe();
      });
      observerArrayRef.current = [];
      setDocuments({
        promised: false,
        data: {},
        hasNextPage: true,
        size: 0,
        refreshCount: documents.refreshCount + 1,
      });
    };
  }, [options?.order, options?.orderBy, options?.filter, documents.refreshCount]);

  const next = useCallback<InfiniteSnapshotsHooks<TView>['next']>(
    (options?: Partial<InfiniteSnapshotOptions>, onLoaded?: () => void) => {
      registerBatch({...initialOptions, ...options}, onLoaded);
    },
    [registerBatch]
  );

  return {documents, next};
};

export const useInfiniteSnapshots = <TView extends ViewTypes>(
  collection: Collections,
  viewSelector: ViewSelector<TView>,
  options?: Partial<InfiniteSnapshotOptions>
) => {
  const firebaseCollectionRef = useMemo(() => {
    return Firebase.firestore.collection(collection);
  }, [collection]);

  return useInfiniteSnapshotsFromRef(firebaseCollectionRef, viewSelector, options);
};
