import RedoIcon from '@mui/icons-material/Redo';
import {Box, Tooltip, Typography} from '@mui/material';
import {AgentView, MerchantView, UserRoles} from '@ozark/common';

type Props = {
  userData: AgentView | MerchantView;
};

export const SiteJumpAsUserAlert = ({userData}: Props): JSX.Element => {
  return (
    <Tooltip
      title={`You are currently logged in as ${userData.firstName} ${userData.lastName} ${
        userData.group?.name ? `from ${userData.group?.name}` : ''
      } with role ${userData.role ?? UserRoles.merchant}`}
    >
      <Box
        sx={{
          display: 'flex',
          color: theme => theme.palette.primary.main,
          padding: theme => theme.spacing(0, 0.5),
          '& > *': {
            margin: theme => theme.spacing(0, 0.5),
          },
        }}
      >
        <RedoIcon />
        <Typography>{`Logged in as ${userData.firstName} ${userData.lastName}`}</Typography>
      </Box>
    </Tooltip>
  );
};
