import {VolumeFilterTypes} from '@ozark/functions/src/functions/express/private/types/Reports';
import {useMemo} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {getFilterPeriod} from '../../FilterPeriodSelect';

type VolumeFilterTypesValues = typeof VolumeFilterTypes[keyof typeof VolumeFilterTypes];

export const useDateRangeApplicationQuery = () => {
  const history = useHistory();
  const {pathname, search} = useLocation();
  const urlParams = new URLSearchParams(search);

  const getParam = (name: string) => {
    return urlParams.get(name);
  };

  const setParam = (params: Record<string, string>) => {
    if (!params) return;

    Object.keys(params).forEach(key => {
      urlParams.set(key, params[key]);
    });

    // If selectedDateFilter is not VolumeFilterTypes.Custom, remove startDate and endDate
    if (params.selectedDateFilter && params.selectedDateFilter !== VolumeFilterTypes.Custom) {
      urlParams.delete('startDate');
      urlParams.delete('endDate');
    }

    history.push({pathname, search: urlParams.toString()});
  };

  const selectedDateFilter = useMemo(() => {
    const startDateFromURL = getParam('startDate');
    const endDateFromURL = getParam('endDate');
    const dateFilterPeriod = getFilterPeriod(
      (getParam('selectedDateFilter') ?? VolumeFilterTypes.All) as VolumeFilterTypesValues
    );

    const dateFilter = {
      ...dateFilterPeriod,
      startDate: startDateFromURL ? new Date(startDateFromURL) : dateFilterPeriod.startDate,
      endDate: endDateFromURL ? new Date(endDateFromURL) : dateFilterPeriod.endDate,
    };

    return dateFilter;
  }, [search]);

  return {
    getParam,
    setParam,
    selectedDateFilter,
  };
};
