import {Box, Typography} from '@mui/material';
import {VolumeFilterTypes} from '@ozark/functions/src/functions/express/private/types/Reports';
import {AllMIDs, ApplicationAnalytics} from '@ozark/functions/src/shared';
import {CancelTokenSource} from 'axios';
import {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {CancelOperationMessage} from '../../api/Constants';
import {APPLICATIONS_ANALYTICS_DETAILS_ROUTE, SALES_LEADS_ROUTE} from '../../constants/routes';
import {useUserInfo} from '../../hooks';
import {useApiContainer, useMidsContainer} from '../../store';
import {formatNumber} from '../../util';
import {BoxFlexed} from '../common';
import {getFilterPeriod, VolumeFilter} from '../FilterPeriodSelect';
import {LoadingContainer} from '../Loading/LoadingContainer';
import {CountTile, CountTileProp} from '../Tiles';

type Props = {
  filters: VolumeFilter;
};

export enum ApplicationAnalyticValueTypes {
  totalDeals = 'totalDeals',
  approved = 'approved',
  pending = 'pending',
  declined = 'declined',
  withdrawn = 'withdrawn',
  salesLeads = 'salesLeads',
  signed = 'signed',
  sent = 'sent',
  pendingSignature = 'pendingSignature',
  incomplete = 'incomplete',
}

const tilesConfig: {[_ in ApplicationAnalyticValueTypes]: {name: string}} = {
  [ApplicationAnalyticValueTypes.totalDeals]: {
    name: 'Total Deals',
  },
  [ApplicationAnalyticValueTypes.approved]: {
    name: 'Approved',
  },
  [ApplicationAnalyticValueTypes.pending]: {
    name: 'Pending',
  },
  [ApplicationAnalyticValueTypes.declined]: {
    name: 'Declined',
  },
  [ApplicationAnalyticValueTypes.withdrawn]: {
    name: 'Withdrawn',
  },
  [ApplicationAnalyticValueTypes.salesLeads]: {name: 'Sales Leads'},
  [ApplicationAnalyticValueTypes.signed]: {
    name: 'Applications Signed',
  },
  [ApplicationAnalyticValueTypes.sent]: {
    name: 'Applications Sent',
  },
  [ApplicationAnalyticValueTypes.pendingSignature]: {
    name: 'Pending Signature',
  },
  [ApplicationAnalyticValueTypes.incomplete]: {
    name: 'Incomplete Apps',
  },
};

export const ApplicationAnalyticsPage = ({filters}: Props) => {
  const {isErpUser, isAgent} = useUserInfo();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [applicationAnalytics, setApplicationAnalytics] = useState<CountTileProp[]>([]);
  const {selectedMid} = useMidsContainer();
  const [, setCancelTokenSource] = useState<CancelTokenSource | undefined>();

  const getApplicationAnalyticDetailsOnClick = useCallback(
    (type: ApplicationAnalyticValueTypes, title: string) => {
      if (type === ApplicationAnalyticValueTypes.salesLeads) {
        history.push(SALES_LEADS_ROUTE, {
          startDate: filters.startDate,
          endDate: filters.endDate,
        });
        return;
      }
      history.push(APPLICATIONS_ANALYTICS_DETAILS_ROUTE, {
        type,
        title: title,
        startDate: filters.startDate,
        endDate: filters.endDate,
      });
    },
    [filters.startDate, filters.endDate]
  );

  const api = useApiContainer();

  const calculateAndSetApplicationAnalyticsResult = useCallback(
    (applicationAnalyticsResult: ApplicationAnalytics | undefined) => {
      if (applicationAnalyticsResult) {
        const analytics = Object.entries(applicationAnalyticsResult)
          .map(([key, value]) => {
            if (!(key in ApplicationAnalyticValueTypes)) {
              return null;
            }

            const type = key as ApplicationAnalyticValueTypes;
            const tileConfig = tilesConfig[type];
            const isTileClickable =
              isErpUser || (isAgent && key !== ApplicationAnalyticValueTypes.salesLeads);
            const applicationAnalyticDetailsOnClick = () =>
              getApplicationAnalyticDetailsOnClick(type, tileConfig.name);

            return {
              name: tileConfig.name,
              count: formatNumber(value),
              onClick: isTileClickable ? applicationAnalyticDetailsOnClick : undefined,
            } as CountTileProp;
          })
          .filter(x => x) as CountTileProp[];

        setApplicationAnalytics(analytics);
      } else {
        const analytics = Object.entries(ApplicationAnalyticValueTypes)
          .map(([key, _]) => {
            const tileConfig = tilesConfig[key as keyof typeof ApplicationAnalyticValueTypes];
            return {
              name: tileConfig.name,
              count: 'N/A',
              onClick: undefined,
            } as CountTileProp;
          })
          .filter(x => x) as CountTileProp[];

        setApplicationAnalytics(analytics);
      }
    },
    [isErpUser, isAgent, getApplicationAnalyticDetailsOnClick]
  );

  useEffect(() => {
    setLoading(true);
    const cancelSource = api?.dashboard.getCancelTokenSource();
    setCancelTokenSource(prev => {
      //Check if there are any previous pending requests
      if (prev !== undefined) {
        prev.cancel(CancelOperationMessage);
      }
      return cancelSource;
    });

    api?.dashboard
      .getApplicationAnalytics(
        filters ?? getFilterPeriod(VolumeFilterTypes.MTD),
        selectedMid === AllMIDs ? undefined : selectedMid,
        cancelSource?.token
      )
      .then(result => {
        calculateAndSetApplicationAnalyticsResult(result);
        setLoading(false);
      })
      .catch((err: any) => {
        if (err?.message === CancelOperationMessage) {
          return;
        }
        console.error(err);
        calculateAndSetApplicationAnalyticsResult(undefined);
        setLoading(false);
      });
  }, [filters, selectedMid, api, calculateAndSetApplicationAnalyticsResult]);

  return (
    <Box>
      <Typography variant="h5" gutterBottom color="secondary.main" fontSize="1.7rem">
        Application Analytics
      </Typography>
      <LoadingContainer loading={loading} minHeight={250}>
        <BoxFlexed flexWrap="wrap">
          {applicationAnalytics.map(item => (
            <CountTile key={item.name} {...item} />
          ))}
        </BoxFlexed>
      </LoadingContainer>
    </Box>
  );
};
