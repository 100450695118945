import {TicketTypeAndCategory} from '../../../..';
import {InputCategoryError} from '../../types';
import {CategoryEditorCategory} from './CategoryEditorCategory';
import {CategoryEditorType} from './CategoryEditorType';

interface Props {
  draft?: TicketTypeAndCategory;
  setDraft: (draft: TicketTypeAndCategory) => void;
  errors: Record<InputCategoryError, string>;
  setErrors: (errors: Record<InputCategoryError, string>) => void;
  useDefaultValue?: boolean;
}
export const CategoryEditorTypeAndCategory = ({
  draft,
  setDraft,
  errors,
  setErrors,
  useDefaultValue,
}: Props) => {
  return (
    <>
      <CategoryEditorType
        draft={draft}
        setDraft={setDraft}
        errors={errors}
        setErrors={setErrors}
        useDefaultValue={useDefaultValue}
      />
      <CategoryEditorCategory
        draft={draft}
        setDraft={setDraft}
        errors={errors}
        setErrors={setErrors}
      />
    </>
  );
};
