import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {CalendarPickerView, DatePicker} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {InputAdornment, TextField} from '@mui/material';
import {addYears, isValid} from 'date-fns';
import {debounce} from 'lodash';
import {useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useQueryMonthYear} from '../Analytics/common/useQueryMonthYear';

type MonthYearPickerProps = {
  disabled?: boolean;
};

const MonthYearPicker = ({disabled}: MonthYearPickerProps) => {
  const [dateSelected, setDateSelected] = useState<Date | null>(null);
  const [currentView, setCurrentView] = useState<CalendarPickerView | null>(null);

  const {year, month} = useQueryMonthYear();
  const history = useHistory();
  const {path: pathname} = useRouteMatch();

  const setDate = (nextDate: Date) => {
    history.push({
      pathname,
      search: `?year=${nextDate.getFullYear()}&month=${nextDate.getMonth() + 1}`,
    });
  };

  const onDateChange = debounce((nextDate: Date | null) => {
    if (!nextDate) {
      return;
    }

    if (!isValid(nextDate)) {
      return;
    }

    // we have 2 steps to select date with datepicker UI: select year, then select month
    // we don't need to update date on year select to prevent connected data re-load
    if (currentView === 'year') {
      return;
    }

    setDate(nextDate);
  }, 500);

  useEffect(() => {
    // we use query string as a source of truth for month/year
    // if we find data in query string we set out datepicker controlled with this date
    // otherwise we set current date to the query string
    if (year && month) {
      setDateSelected(new Date(`${year}-${month}-2`));
    } else {
      setDate(new Date());
    }
  }, [year, month]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disabled={disabled}
        inputFormat="MM/yyyy"
        mask="____/_"
        minDate={addYears(new Date(), -10)}
        maxDate={new Date()}
        value={dateSelected}
        views={['year', 'month']}
        onChange={onDateChange} // used on manual date change and year and month change
        onViewChange={setCurrentView}
        onOpen={() => setCurrentView('year')}
        onClose={() => setCurrentView(null)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CalendarTodayIcon />
            </InputAdornment>
          ),
        }}
        renderInput={params => (
          <TextField variant="outlined" size="small" {...params} sx={{width: '160px'}} />
        )}
      />
    </LocalizationProvider>
  );
};

export {MonthYearPicker};
