import {Button} from '@mui/material';
import {useState} from 'react';
import {Wizard} from '../TicketEditWizard/Wizard';

export const TicketsListCreateButton = () => {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const openDialog = () => {
    setIsCreateDialogOpen(true);
  };

  const closeDialog = () => {
    setIsCreateDialogOpen(false);
  };

  return (
    <>
      <Button variant="contained" onClick={openDialog}>
        New Ticket
      </Button>
      {isCreateDialogOpen && <Wizard handleClose={closeDialog} />}
    </>
  );
};
