import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Button, {ButtonProps} from '@mui/material/Button';
import {useCallback, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {AssigneeType, useUserInfo, withOverflowConfirmation} from '../../..';
import {FIELD_NAME_ASSIGNEE} from '../constants/constants';
import {useTicketId} from '../hooks/useTicketId';
import {useTicketUpdate} from './useTicketUpdate';

const ButtonAssignToMe = withOverflowConfirmation<ButtonProps>(Button);

export function ButtonAssignToMeTicket() {
  const {watch} = useFormContext();
  const assignee = watch(FIELD_NAME_ASSIGNEE);
  const {isErpUser, uid} = useUserInfo();
  const {ticketId} = useTicketId();
  const [isAssignToMe, setIsAssignToMe] = useState(false);
  const {updateAssigneeToMe} = useTicketUpdate(ticketId);

  const handleAssignToMe = useCallback(async () => {
    await updateAssigneeToMe(assignee);
  }, [assignee, setIsAssignToMe, updateAssigneeToMe]);

  const showButton =
    isErpUser && !(assignee?.type === AssigneeType.erpUser && assignee.erpUser?.id === uid);

  if (!showButton) {
    return null;
  }

  return (
    <ButtonAssignToMe
      color="primary"
      id="account-menu"
      isLoading={isAssignToMe}
      primaryButtonColor="success"
      primaryButtonText="Assign To Me"
      secondaryButtonText="Cancel"
      startIcon={<PersonAddIcon />}
      sx={{ml: 2}}
      title="Assign ticket to you?"
      tooltip="Assign Ticket To Me"
      variant="text"
      onClick={handleAssignToMe}
    >
      Assign To Me
    </ButtonAssignToMe>
  );
}
