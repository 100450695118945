import {formatNumber, formatUSD} from '../../..';
import {ChartPie, Serie} from '../common/DashboardChartPie';
import {DataIsLoading} from '../common/DataIsLoading';
import {DataIsMissing, NoTransactions} from '../common/DataIsMissing';
import {WidgetProps} from '../common/types';

import {hasData} from '../common/utils';

export const WidgetDisputesGraph = ({loading, data}: WidgetProps) => {
  if (loading) return <DataIsLoading />;
  if (!data) return <DataIsMissing />;

  const rows: Serie[] = [
    {
      id: 'Visa',
      label: `Visa: ${formatNumber(data.visaCbCount)} / ${formatUSD(data.visaCbVolume ?? 0)}`,
      value: data.visaCbCount,
    },
    {
      id: 'MC',
      label: `MC: ${formatNumber(data.mcCbCount)} / ${formatUSD(data.mcCbVolume ?? 0)}`,
      value: data.mcCbCount,
    },
  ];

  if (!hasData(rows.map(r => r.value))) {
    return <NoTransactions label="No disputes to graph" />;
  }

  return <ChartPie data={rows} />;
};
