export const PortfolioTINIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g fill={'inherit'}>
        <path
          d="M48,512c-26.5,0-48-21.5-48-48l0,0V176c0-26.5,21.5-48,48-48h48v208c0,44.2,35.8,80,80,80h336v48
		c0,26.5-21.5,48-48,48l0,0H48z"
        />
        <path
          opacity={0.4}
          d="M592,64H400L336,0H176c-26.5,0-48,21.5-48,48v288c0,26.5,21.5,48,48,48h416c26.5,0,48-21.5,48-48V112
		C640,85.5,618.5,64,592,64z M354.3,187c0,3.4-1.8,5.4-5.4,5.4h-25.2c-3.4,0-5.4,1.8-5.4,5.4V282c0,3.4-1.8,5.4-5.4,5.4h-20.2
		c-3.4,0-5.4-2-5.4-5.4v-84.2c0-3.6-1.8-5.4-5.4-5.4h-25.2c-3.4,0-5.4-2-5.4-5.4v-20.2c0-3.6,2-5.4,5.4-5.4h92.2
		c3.6,0,5.4,1.8,5.4,5.4L354.3,187L354.3,187z M395.7,282.1c0,3.4-1.8,5.4-5.4,5.4h-19.8c-3.4,0-5.4-2-5.4-5.4V166.9
		c0-3.6,2-5.4,5.4-5.4h19.8c3.6,0,5.4,1.8,5.4,5.4V282.1z M509.5,282.1c0,3.4-1.8,5.4-5.4,5.4h-26.5c-3.1,0-5-1.3-6.3-4.1
		l-24.5-59.9c-0.7-1.6-2.2-1.3-2.2,0.5v58.1c0,3.4-1.8,5.4-5.4,5.4H419c-3.4,0-5.4-2-5.4-5.4V166.9c0-3.6,2-5.4,5.4-5.4h26.5
		c3.1,0,5,1.3,6.3,4.1l24.5,59.9c0.7,1.6,2.2,1.3,2.2-0.5v-58.1c0-3.6,2-5.4,5.4-5.4h20.2c3.6,0,5.4,1.8,5.4,5.4V282.1z"
        />
        <g>
          <path
            d="M287.3,197.8c0-3.6-1.8-5.4-5.4-5.4h-25.2c-3.4,0-5.4-2-5.4-5.4v-20.2c0-3.6,2-5.4,5.4-5.4h92.2
			c3.6,0,5.4,1.8,5.4,5.4V187c0,3.4-1.8,5.4-5.4,5.4h-25.2c-3.4,0-5.4,1.8-5.4,5.4V282c0,3.4-1.8,5.4-5.4,5.4h-20.2
			c-3.4,0-5.4-2-5.4-5.4L287.3,197.8L287.3,197.8z"
          />
          <path
            d="M390.3,161.5c3.6,0,5.4,1.8,5.4,5.4v115.2c0,3.4-1.8,5.4-5.4,5.4h-19.8c-3.4,0-5.4-2-5.4-5.4V166.9
			c0-3.6,2-5.4,5.4-5.4H390.3z"
          />
          <path
            d="M445.6,161.5c3.1,0,5,1.3,6.3,4.1l24.5,59.9c0.7,1.6,2.2,1.3,2.2-0.5v-58.1c0-3.6,2-5.4,5.4-5.4h20.2
			c3.6,0,5.4,1.8,5.4,5.4v115.2c0,3.4-1.8,5.4-5.4,5.4h-26.5c-3.1,0-5-1.3-6.3-4.1l-24.5-59.9c-0.7-1.6-2.2-1.3-2.2,0.5v58.1
			c0,3.4-1.8,5.4-5.4,5.4h-20.2c-3.4,0-5.4-2-5.4-5.4V166.9c0-3.6,2-5.4,5.4-5.4H445.6z"
          />
        </g>
      </g>
    </svg>
  );
};
