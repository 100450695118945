import {Alert} from '@mui/material';
import {useUserInfo} from '../../../hooks/useUserInfo';
import {useTicketEditContainer} from '../hooks/useTicketEditContainer';

type Props = {
  actionName: string;
};

export const EditorWarning = ({actionName}: Props) => {
  const {isPortal} = useUserInfo();
  const {commentsInternal} = useTicketEditContainer();
  return (
    <div>
      {!isPortal && !commentsInternal && (
        <Alert severity="info" sx={{mb: 2}}>
          You are about to {actionName} a Public Comment
        </Alert>
      )}
      {!isPortal && commentsInternal && (
        <Alert severity="warning" sx={{mb: 2}}>
          You are about to {actionName} an Internal Comment
        </Alert>
      )}
    </div>
  );
};
