import {SecuredUserProfile, TicketComment, UniversalTimestamp} from '@ozark/common';
import {format, formatRelative} from 'date-fns';
import {useCallback} from 'react';
import {useUserProfilesContainer} from '../../../store/containers/UserProfilesContainer';
import {CommentInfo} from './useComments';

export const useMappedComments = () => {
  const {userProfiles, loadUserProfiles} = useUserProfilesContainer();

  const getMappedComments = useCallback(
    (comments: TicketComment[], authors: Record<string, SecuredUserProfile>): CommentInfo[] => {
      if (!comments.length) {
        return [];
      }

      let needReloadUserProfile = false;

      const commentsInfo = comments.map(comment => {
        const dateCreateAt = (comment.createdAt as UniversalTimestamp)?.toDate();

        const humanFriendlyDate = dateCreateAt
          ? formatRelative(dateCreateAt, new Date()).split(' at ')[0]
          : '';

        const localTime = dateCreateAt ? format(dateCreateAt, 'MM/dd/yy - hh:mm a') : '';

        const author = authors[comment.uid] ?? {uid: comment.uid};
        if (!authors[comment.uid]) {
          needReloadUserProfile = true;
        }

        return {
          comment,
          humanFriendlyDate,
          localTime,
          author,
        };
      });

      if (needReloadUserProfile && !userProfiles.promised) {
        loadUserProfiles();
      }

      return commentsInfo;
    },
    [userProfiles.promised, loadUserProfiles]
  );

  return {
    getMappedComments,
  };
};
