export const AuthorizationsIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      {...props}
      width="24px"
      height="24px"
    >
      <g fill="#b2c2cd">
        <path
          d="M608 288H464v-79.3c0-17.4 13.7-32.2 31.1-32.7 17.7-.5 32.4 13.4 32.9 31.1v.9c0 8.8 7.2 16 16 16h17c8.3 0 15-6.7 15-15 0-43.3-34-79.5-77.3-80.9-44.2-1.5-81.2 33.1-82.7 77.2V288h-32c-17.7 0-32 14.3-32 32v160c0 17.7 14.3 32 32 32h224c17.7 0 32-14.3 32-32V320c0-17.7-14.3-32-32-32zM496 432c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"
          opacity={0.4}
        />
        <path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm96 64c.1-10.7 2.9-21.2 8.1-30.5-4.8-.5-9.5-1.5-14.5-1.5h-16.7c-46.3 21.3-99.5 21.3-145.8 0h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h280.9c-5.7-9.7-8.8-20.7-8.9-32V320z" />
      </g>
    </svg>
  );
};
