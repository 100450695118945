import {useEffect, useState} from 'react';

export const useKeyPress = (targetKey: any) => {
  const [keyPressed, setKeyPressed] = useState(false);
  const [eventSource, setEventSource] = useState<any>(null);

  const downHandler = (event: any) => {
    if (event.key === targetKey) {
      setKeyPressed(true);
      setEventSource(event);
    }
  };

  const upHandler = (event: any) => {
    if (event.key === targetKey) {
      setKeyPressed(false);
      setEventSource(null);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  return [keyPressed, eventSource];
};
