import {useWatch} from 'react-hook-form';
import {AssigneeType, getAssigneeUid, TicketStatus} from '../../..';
import {useUserInfo} from '../../../hooks';
import {FIELD_NAME_ASSIGNEE, FIELD_NAME_AUTHOR} from '../constants/constants';
import {useIsEditingClosed} from './useIsEditingClosed';
import {useTicketStatus} from './useTicketStatus';
import {useUserDepartment} from './useUserDepartment';

export function useIsEditingDisabled() {
  const {isEditingClosed} = useIsEditingClosed();
  const {uid, isErpAdmin, isAgentAdmin, isPortal} = useUserInfo();
  const {department} = useUserDepartment();
  const {status} = useTicketStatus();
  const assignee = useWatch({name: FIELD_NAME_ASSIGNEE});
  const author = useWatch({name: FIELD_NAME_AUTHOR});

  const isUserAuthor = author && author.id === uid;

  const isUserAssignee = assignee && getAssigneeUid(assignee) === uid;

  const isErpUserAssignee = assignee?.erpUser && uid === assignee.erpUser.id;

  const isErpDepartmentAssignee =
    assignee?.type === AssigneeType.erpDepartment && assignee?.erpDepartment === department;

  const isErpAssignee = isErpUserAssignee || isErpDepartmentAssignee;

  const isEditingDisabled =
    isEditingClosed ||
    !(status === TicketStatus.Draft || isErpAssignee || isErpAdmin || isAgentAdmin);

  const isEditingDisabledStatus =
    !isUserAssignee &&
    !isErpAdmin &&
    !(
      isUserAuthor &&
      status &&
      [TicketStatus.Closed, TicketStatus.Unresolved].includes(status) &&
      !isPortal
    );

  const isStatusChangeLocked = assignee?.type === AssigneeType.erpDepartment;

  return {
    isEditingDisabled,
    isEditingDisabledStatus,
    isStatusChangeLocked,
  };
}
