export enum SalesDisposition {
  new = 'New Lead',
  leftVM = 'Left VM',
  followUpScheduled = 'Follow up Scheduled',
  hotReadyToSign = 'Hot - Ready to Sign',
  contactAttempt1 = 'Contact Attempt 1',
  contactAttempt2 = 'Contact Attempt 2',
  contactAttempt3 = 'Contact Attempt 3',
  contactAttempt4 = 'Contact Attempt 4',
  contactAttempt5 = 'Contact Attempt 5',
  sentApp = 'Sent App',
  sentIntroEmailWithApp = 'Sent Intro Email with App',
  appCompleted = 'App Completed',
  appSubmittedWithSupportingDocs = 'Submitted w/docs',
  appSubmittedReqSupDocsContactAttempt1 = 'Req. Sup. Docs 1',
  appSubmittedReqSupDocsContactAttempt2 = 'Req. Sup. Docs 2',
  appSubmittedReqSupDocsContactAttempt3 = 'Req. Sup. Docs 3',
  appSubmittedReqSupDocsContactAttempt4 = 'Req. Sup. Docs 4',
  appSubmittedReqSupDocsContactAttempt5 = 'Req. Sup. Docs 5',
  invalidLead = 'Invalid Lead',
  badLead = 'Bad Lead',
  notInterested = 'Not Interested',
  unresponsive = 'Unresponsive',
  deleted = 'Deleted',
}
