import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import {Box, Tooltip} from '@mui/material';
import {NotificationStatus, NotificationView, SearchCriteria, useInfiniteData} from '@ozark/common';
import {Column} from '../../api/Column';
import {BoxEllipsis} from '../common/BoxEllipsis';
import {RenderNotificationText} from '../Notifications';
import {NotificationTypeChip} from '../Notifications/NotificationTypeChip';
import {formatDateTime} from '../reports';
import {Table} from '../Table';

type Props = {
  notifications: NotificationView[];
  activeNotificationId: string | null;
  onRowClick: (notification: NotificationView) => void;
  searchCriteria: SearchCriteria;
  onRetrieveData: (searchCriteria: SearchCriteria) => void;
  onLoadMore: () => void;
  hasMore: boolean;
};

export const NotificationsTable = ({
  notifications,
  onRowClick,
  onRetrieveData,
  searchCriteria,
  onLoadMore,
  hasMore,
  activeNotificationId,
}: Props): JSX.Element => {
  const loadMoreRef = useInfiniteData(onLoadMore, hasMore);

  return (
    <Box sx={{overflowY: 'auto', maxHeight: '100%'}}>
      <Table
        columns={columns}
        rows={notifications}
        onRowClick={onRowClick}
        onRetrieveData={onRetrieveData}
        sort={[[searchCriteria.orderBy, searchCriteria.order as 'ASC' | 'DESC']]}
        stickyHeader
        selectedId={activeNotificationId ?? undefined}
        getRowProps={(notification, index) => ({
          sx: [notification.status === NotificationStatus.new && {'& td': {fontWeight: '500'}}],
          ref: index === notifications.length - 10 ? loadMoreRef : null,
        })}
        getCellProps={column => ({
          sx: [
            ellipsisFields.includes(column.id) && {
              maxWidth: 0,
            },
            column.id === 'type' && {
              width: '150px',
            },
            column.id === 'createdAt' && {
              width: '200px',
            },
          ],
        })}
      />
    </Box>
  );
};

const ellipsisFields = ['title', 'text'];

const columns: Column<NotificationView>[] = [
  {
    id: 'title',
    label: 'Title',
    sortable: true,
    selector: notification => (
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        {notification.isImportantNotification && (
          <Tooltip title="Important notification">
            <PriorityHighIcon
              aria-label="Important notification"
              color="error"
              sx={{mt: '-3px', ml: '-6px'}}
            />
          </Tooltip>
        )}
        <BoxEllipsis sx={{display: 'inline-block'}}>{notification.title}</BoxEllipsis>
      </Box>
    ),
  },
  {
    id: 'text',
    label: 'Text',
    sortable: true,
    selector: notification => (
      <BoxEllipsis hideTitle>{RenderNotificationText(notification.text)}</BoxEllipsis>
    ),
  },
  {
    id: 'createdAt',
    label: 'Send Date',
    sortable: true,
    selector: notification => formatDateTime(notification.createdAt.toDate().toUTCString()),
  },
  {
    id: 'type',
    label: 'Type',
    sortable: true,
    align: 'center',
    selector: notification => (
      <Box sx={{pr: 2}}>
        <NotificationTypeChip type={notification.type} />
      </Box>
    ),
  },
];
