import {
  AssigneeType,
  AssociationType,
  TicketAssignee,
  TicketAssociation,
  TicketTypeAndCategory,
} from '../../..';
import {
  FIELD_NAME_AGENT,
  FIELD_NAME_APPLICATION,
  FIELD_NAME_CATEGORY,
  FIELD_NAME_ERP_DEPARTMENT,
  FIELD_NAME_ERP_USER,
  FIELD_NAME_GROUP,
  FIELD_NAME_MERCHANT,
  FIELD_NAME_MID,
  FIELD_NAME_TYPE,
} from '../constants/constants';
import {InputAssigneeError, InputAssociationError, InputCategoryError} from '../types';

export const validateAssignee = (
  draft: TicketAssignee | undefined,
  setErrors: React.Dispatch<React.SetStateAction<Record<InputAssigneeError, string>>>
) => {
  if (!draft?.type) {
    setErrors(errors => ({...errors, Type: 'Assignee type is required'}));
  }
  switch (draft?.type) {
    case undefined:
      setErrors(errors => ({...errors, [FIELD_NAME_TYPE]: 'Type is required'}));
      return false;

    case AssigneeType.erpDepartment:
      if (!draft.erpDepartment) {
        setErrors(errors => ({
          ...errors,
          [FIELD_NAME_ERP_DEPARTMENT]: 'ERP department is required',
        }));
        return false;
      }
      return true;

    case AssigneeType.erpUser:
      if (!draft.erpUser) {
        setErrors(errors => ({...errors, [FIELD_NAME_ERP_USER]: 'ERP user is required'}));
        return false;
      }
      return true;

    case AssigneeType.agent:
      if (!draft.group) {
        setErrors(errors => ({...errors, [FIELD_NAME_GROUP]: 'Group is required'}));
        return false;
      }
      if (!draft.agent) {
        setErrors(errors => ({...errors, [FIELD_NAME_AGENT]: 'Agent is required'}));
        return false;
      }
      return true;

    case AssigneeType.merchant:
      if (!draft.group) {
        setErrors(errors => ({...errors, [FIELD_NAME_GROUP]: 'Group is required'}));
        return false;
      }
      if (!draft.merchant) {
        setErrors(errors => ({...errors, [FIELD_NAME_MERCHANT]: 'Merchant is required'}));
        return false;
      }
      return true;

    default:
      return false;
  }
};

export const validateAssociation = (
  draft: TicketAssociation | undefined,
  setErrors: React.Dispatch<React.SetStateAction<Record<InputAssociationError, string>>>,
  isCurrentErpUser: boolean
) => {
  if (!draft?.type) {
    setErrors(errors => ({...errors, Type: 'Assignee type is required'}));
  }
  switch (draft?.type) {
    case undefined:
      setErrors(errors => ({...errors, [FIELD_NAME_TYPE]: 'Type is required'}));
      return false;

    case AssociationType.erpUser:
      if (!draft.erpUser) {
        setErrors(errors => ({...errors, [FIELD_NAME_ERP_USER]: 'ERP User is required'}));
        return false;
      }
      if (!isCurrentErpUser) {
        setErrors(errors => ({...errors, [FIELD_NAME_TYPE]: 'Invalid Type'}));
        return false;
      }
      return true;

    case AssociationType.application:
      if (!draft.application) {
        setErrors(errors => ({...errors, [FIELD_NAME_APPLICATION]: 'Application is required'}));
        return false;
      }
      return true;

    case AssociationType.agent:
      if (!draft.group) {
        setErrors(errors => ({...errors, [FIELD_NAME_GROUP]: 'Group is required'}));
        return false;
      }
      if (!draft.agent) {
        setErrors(errors => ({...errors, [FIELD_NAME_AGENT]: 'Agent is required'}));
        return false;
      }
      return true;

    case AssociationType.merchant:
      if (!draft.merchant) {
        setErrors(errors => ({...errors, [FIELD_NAME_MERCHANT]: 'Merchant is required'}));
        return false;
      }
      return true;
    case AssociationType.mid:
      if (!draft.mid) {
        setErrors(errors => ({...errors, [FIELD_NAME_MID]: 'MID is required'}));
        return false;
      }
      return true;

    default:
      return false;
  }
};

export const validateCategory = (
  draft: TicketTypeAndCategory | undefined,
  setErrors: React.Dispatch<React.SetStateAction<Record<InputCategoryError, string>>>
) => {
  if (!draft?.[FIELD_NAME_TYPE]) {
    setErrors(errors => ({...errors, [FIELD_NAME_TYPE]: 'Ticket type is required'}));
    return false;
  }
  if (!draft?.[FIELD_NAME_CATEGORY]) {
    setErrors(errors => ({...errors, [FIELD_NAME_CATEGORY]: 'Ticket category is required'}));
    return false;
  }
  return true;
};
