import {ApplicationView, useNotification} from '../../index';
import {TransferToMerchantConfirmationDialogEx} from './TransferToMerchantConfirmationDialogEx';

interface Props {
  application: Partial<ApplicationView>;
  onClose: () => void;
  errorMessage: string;
}

const isValidAppForTransfer = (application: Partial<ApplicationView>) => {
  // we are able to transfer application if it was started by a merchant
  if (!application.isPortalApplication) {
    return true;
  }

  // if application started by agent we use validation
  return (
    !!application.processingType &&
    !!application.rateSet &&
    !!application.businessType &&
    !!application.equipment
  );
};

export const TransferToMerchantConfirmationDialog = ({
  application,
  onClose,
  errorMessage,
}: Props) => {
  const showNotification = useNotification();
  const validate = () => {
    const applicationTransferRequiredFieldSetComplete = isValidAppForTransfer(application);
    if (!applicationTransferRequiredFieldSetComplete || !application.id) {
      showNotification('error', errorMessage);
      return false;
    }
    return true;
  };

  return (
    <TransferToMerchantConfirmationDialogEx
      emailSuggestions={
        [application.email, application.customerServiceEmail].filter(Boolean) as string[]
      }
      validate={validate}
      onClose={onClose}
      applicationId={application.id}
    />
  );
};
