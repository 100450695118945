export const ResidualIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g fill={'inherit'}>
        <path
          opacity={0.4}
          d="M564,377L412.8,498c-11.4,9.1-25.5,14-40,14H16c-8.8,0-16-7.2-16-16v-96c0-8.8,7.2-16,16-16h55.4l46.5-37.7
		c21-17,47.1-26.3,74.1-26.3h160c17.6,0,32,14.3,32,31.9c0,1.8-0.1,3.7-0.5,5.5c-2.5,15.7-17.4,26.6-33.2,26.6H272
		c-8.8,0-16,7.2-16,16s7.2,16,16,16h118.3c14.5,0,28.7-4.9,40-14l92.4-73.9c12.4-10,30.8-10.7,42.6,0c13.2,11.7,14.4,32,2.6,45.2
		C566.7,374.6,565.4,375.9,564,377z"
        />
        <path
          d="M329.3,222.4V240c0,8.8-7.8,16-17.3,16h-17.2c-9.5,0-17.2-7.2-17.2-16v-17.7c-12.1-1.3-23.8-5.2-34.3-11.5
		c-5.4-3.3-7.1-10.3-3.9-15.7c0.6-1,1.4-1.9,2.2-2.7l18.9-17.5c4-3.7,10-4.2,15.2-2c3.5,1.4,7.3,2.2,11.1,2.2h35.4
		c5,0,9.1-3.8,9.1-8.4c-0.1-3.9-2.8-7.2-6.6-8.1l-53.9-14.3c-23.9-6.4-43.1-24.7-46.2-47.7c-4.3-32,20.5-59.4,53.1-63V16
		c0-8.8,7.8-16,17.2-16h17.2c9.5,0,17.2,7.2,17.2,16v17.7c12.1,1.3,23.8,5.2,34.3,11.5c5.4,3.3,7.1,10.4,3.8,15.8
		c-0.6,1-1.3,1.9-2.2,2.6l-18.9,17.5c-4,3.7-10,4.2-15.2,2c-3.5-1.4-7.3-2.2-11.1-2.2h-35.4c-5,0-9.1,3.8-9.1,8.4
		c0.1,3.9,2.8,7.2,6.6,8.1l53.9,14.3c23.9,6.4,43.1,24.7,46.2,47.7C386.7,191.4,362,218.8,329.3,222.4L329.3,222.4z"
        />
      </g>
    </svg>
  );
};
