import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Collections,
  Firebase,
  Notification,
  NotificationView,
  selectNotificationView,
  UniversalSnapshot,
  useAttachmentsFromRef,
  useAuthContainer,
} from '@ozark/common';
import {useEffect, useMemo, useRef, useState} from 'react';
import {AttachmentsList} from '../Attachments';
import {RenderNotificationText} from '../Notifications';
import {NotificationTypeChip} from '../Notifications/NotificationTypeChip';
import {formatDateTime} from '../reports';

type Props = {
  notificationId: string | null;
  onClose: () => void;
};

export const NotificationDetails = ({notificationId, onClose}: Props): JSX.Element | null => {
  const {authUser} = useAuthContainer();
  const uid = authUser?.data?.uid;
  const [notification, setNotification] = useState<{promised: boolean; data?: NotificationView}>({
    promised: true,
  });
  // Saved notification data in ref is needed for animation leave of notification details card
  const notificationToDisplayRef = useRef<NotificationView | null>(null);
  const attachmentsRef = useMemo(
    () =>
      uid && notificationId
        ? Firebase.firestore
            .collection(Collections.userNotifications)
            .doc(uid)
            .collection(Collections.notifications)
            .doc(notificationId)
        : null,
    [uid, notificationId]
  );
  const {attachments} = useAttachmentsFromRef(attachmentsRef);

  useEffect(() => {
    if (!notificationId) {
      return;
    }

    setNotification({
      promised: true,
    });

    return Firebase.firestore
      .collection(Collections.userNotifications)
      .doc(uid)
      .collection(Collections.notifications)
      .doc(notificationId)
      .onSnapshot(snapshot => {
        if (!snapshot.exists) {
          setNotification({
            promised: false,
          });

          return;
        }

        setNotification({
          promised: false,
          data: selectNotificationView(snapshot as UniversalSnapshot<Notification>),
        });
      });
  }, [notificationId]);

  useEffect(() => {
    if (notification.data) {
      notificationToDisplayRef.current = notification.data;
    }
  }, [notification.data]);

  const data = notification.data || notificationToDisplayRef.current;

  return (
    <Card sx={{display: 'flex', flexDirection: 'column', height: '100%'}} elevation={2}>
      {notification.promised || !data ? (
        <CardContent
          sx={{display: 'flex', justifyContent: 'center', flex: 1, overflow: 'auto', py: 3}}
        >
          <CircularProgress size={28} />
        </CardContent>
      ) : (
        <>
          <CardHeader
            title={
              <Box>
                {data.isImportantNotification && (
                  <Tooltip title="Important notification">
                    <PriorityHighIcon
                      aria-label="Important notification"
                      color="error"
                      sx={{verticalAlign: 'text-top', ml: -0.5}}
                    />
                  </Tooltip>
                )}
                <span>{data.title}</span>
              </Box>
            }
            action={
              <IconButton aria-label="close-notification" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            }
            subheader={
              <Box display="flex" alignItems="center">
                <Box sx={{mr: 1}}>{formatDateTime(data.createdAt.toDate().toString())}</Box>
                <NotificationTypeChip type={data.type} />
              </Box>
            }
          />
          <CardContent sx={{flex: 1, overflow: 'auto'}}>
            <Typography sx={{whiteSpace: 'pre-line', wordBreak: 'break-word', mb: 4}}>
              {RenderNotificationText(data.text, true)}
            </Typography>

            {attachments.promised && (
              <Box display="flex" justifyContent="center">
                <CircularProgress size={28} />
              </Box>
            )}

            {!!attachments.data?.length && (
              <Box sx={{mb: 2}}>
                <Typography variant="caption" sx={{display: 'block', fontSize: 16, mb: 0.5}}>
                  Attachments:
                </Typography>
                <AttachmentsList attachments={attachments.data} />
              </Box>
            )}

            {data.link && (
              <a href={data.link} target="_blank">
                <Button variant="outlined" startIcon={<OpenInNewIcon />}>
                  {data.linkTitle ?? 'Open link'}
                </Button>
              </a>
            )}
          </CardContent>
        </>
      )}
    </Card>
  );
};
