import {Badge, BadgeProps, styled} from '@mui/material';

export const StyledBadge = styled(Badge, {shouldForwardProp: prop => prop !== 'active'})<
  BadgeProps & {active: boolean; offset?: number}
>(({active, offset}) => ({
  '& .MuiBadge-badge': {
    right: offset,
    color: active ? 'white' : 'slategray',
    backgroundColor: active ? '#1877f2' : 'lightgrey',
  },
}));
