import {ResponsivePie} from '@nivo/pie';
import {CardVolumeSummary} from '../../..';
import {TransactionsCardTextByType} from '../../CardImage';

type Props = {summaryByCardType: CardVolumeSummary[]};

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

const formatDataForGraph = (summaryByCardType: CardVolumeSummary[]) => {
  return summaryByCardType.map(card => ({
    id: card.cardType,
    label: TransactionsCardTextByType[card.cardType]
      ? TransactionsCardTextByType[card.cardType]
      : 'Other',
    value: Number(card.salesAmount).toFixed(),
  }));
};

export const VolumeSummaryByCardTypePieChart = ({summaryByCardType}: Props) => {
  return (
    <ResponsivePie
      data={formatDataForGraph(summaryByCardType)}
      innerRadius={0.6}
      margin={{top: 40, right: 80, bottom: 80, left: 80}}
      valueFormat={value => `${formatter.format(Number(value))}`}
      borderWidth={1}
      borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}
      arcLinkLabel={'label'}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{from: 'color'}}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor="white"
      colors={{scheme: 'category10'}}
      padAngle={1.5}
      isInteractive={false}
    ></ResponsivePie>
  );
};
