import {AllMIDs} from '@ozark/functions/src/documents';
import {omit} from 'lodash';
import {Dispatch, SetStateAction, useCallback} from 'react';
import {FilterOption} from '../Filters';
import {Filter} from '../reports';

export const MID_FILTER: FilterOption = {
  id: 'mid',
  column: 'mid',
  label: 'MID',
  type: 'text',
  hidden: true,
  operators: [
    {
      id: '__eq',
      label: 'equals',
    },
  ],
};

export const getMidFilter = (mid: string): Filter => ({
  mid: {
    option: MID_FILTER,
    operator: MID_FILTER.operators[0],
    value: mid,
  },
});

export const useMidFilter = (
  setFilters: Dispatch<SetStateAction<Filter>>,
  setSelectedMid: (value: string) => void
) => {
  const handleSelectMid = useCallback(
    (mid: string) => {
      setSelectedMid(mid);
      if (mid === AllMIDs) {
        setFilters(prev => omit(prev, 'mid'));
      } else {
        setFilters(prev => ({
          ...prev,
          ...getMidFilter(mid),
        }));
      }
    },
    [setSelectedMid]
  );

  return handleSelectMid;
};
