import {
  AsyncState,
  Attachment,
  AttachmentView,
  Collections,
  Firebase,
  selectAttachmentView,
  UniversalSnapshot,
} from '@ozark/common';
import firebase from 'firebase/compat';
import {useEffect, useMemo, useRef, useState} from 'react';

export type AttachmentMetadata = {downloadUrl: string; size: number; contentType: string};

export const useAttachments = (id: string, collection: string, isPortalUser?: boolean) => {
  const documentRef = useMemo(
    () => Firebase.firestore.collection(collection).doc(id),
    [collection, id]
  );

  return useAttachmentsFromRef(documentRef, isPortalUser);
};

export const useAttachmentsFromRef = (
  documentRef: firebase.firestore.DocumentReference | null,
  isPortalUser?: boolean
) => {
  const [attachments, setAttachments] = useState<
    AsyncState<(AttachmentView & AttachmentMetadata)[]>
  >({promised: true});

  // Using ref to avoid race condition when attachments data are loaded after documentRef changing
  const docRef = useRef(documentRef);

  useEffect(() => {
    if (!documentRef) {
      return;
    }

    docRef.current = documentRef;
    setAttachments({promised: true});

    const unsubscribe = documentRef
      .collection(Collections.attachments)
      .orderBy('createdAt', 'desc')
      .onSnapshot(
        async snapshot => {
          if (snapshot.size === 0) {
            setAttachments({promised: false});
            return;
          }

          const attachmentsDocs = snapshot.docs
            .map(doc => selectAttachmentView(doc as UniversalSnapshot<Attachment>))
            .filter(
              attachment =>
                !attachment.deleted &&
                (!isPortalUser || ['agent', 'merchant'].includes(attachment.author?.user ?? ''))
            );

          const attachments = await getAttachmentsListWithMetadata(attachmentsDocs);

          if (docRef.current === documentRef) {
            setAttachments({promised: false, data: attachments});
          }
        },
        err => {
          console.error(err);
          setAttachments({promised: false, error: err});
        }
      );
    return () => {
      unsubscribe();
    };
  }, [documentRef]);

  return {attachments};
};

export const getAttachmentsListWithMetadata = async <T extends Pick<Attachment, 'cloudPath'>>(
  attachments: T[]
): Promise<(T & AttachmentMetadata)[]> => {
  return await Promise.all(
    attachments.map(async attachment => {
      try {
        const storageRef = Firebase.storage.ref(attachment.cloudPath);
        const downloadUrl = await storageRef.getDownloadURL();
        const metadata = await storageRef.getMetadata();

        return {
          ...attachment,
          downloadUrl,
          size: metadata.size,
          contentType: metadata.contentType || '',
        };
      } catch (e) {
        console.error(e);
        return {
          ...attachment,
          downloadUrl: '',
          size: 0,
          contentType: '',
        };
      }
    })
  );
};
