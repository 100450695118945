import {ActiveFilter, Filter, FilterOption, Filters} from '..';
import {MerchantActivityEnum} from './types';

type Props = {
  filters?: Filter;
  setFilters: (filter: Filter) => void;
};

export const ButtonFilters = ({filters, setFilters}: Props) => {
  const handleApplyFilter = (filter: ActiveFilter) => {
    setFilters({...filters, [filter.option.column]: filter});
  };

  return (
    <Filters options={ActivationFilters} onApplyFilter={handleApplyFilter} alignPopup="left" />
  );
};

const ActivationFilters: FilterOption[] = [
  {
    id: 'mid',
    column: 'mid',
    label: 'Merchant MID',
    type: 'text',
    autoSelect: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'email',
    column: 'email',
    label: 'Email',
    type: 'text',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'dba',
    column: 'merchant',
    label: 'Merchant DBA',
    type: 'text',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'activity',
    column: 'activity',
    label: 'Merchant Activity',
    type: 'list',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
    options: [
      {
        key: MerchantActivityEnum.ProccessedActively,
        value: 'Processing Active (within last 30 days)',
      },
      {
        key: MerchantActivityEnum.ProcessingStoped,
        value: 'Processing Stopped (for 3 or more last days)',
      },
    ],
  },
];
