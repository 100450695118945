import {Box, Typography} from '@mui/material';
import {ApplicationView, GroupView, mapDispositionToMerchantApplicationStatus} from '@ozark/common';
import {useMemo} from 'react';
import {ApplicationStatusMessage} from './ApplicationStatusMessage';
import {ApplicationSteps} from './ApplicationSteps';

type Props = {
  group: GroupView;
  userData: {firstName: string; lastName: string};
  application: ApplicationView;
  onResumeApplication: (application: ApplicationView) => void;
};

export const ApplicationStatusDetails = ({
  application,
  userData,
  group,
  onResumeApplication,
}: Props): JSX.Element => {
  const userName = `${userData.firstName ?? ''} ${userData.lastName ?? ''}`.trim();
  const merchantApplicationStatus = useMemo(
    () => mapDispositionToMerchantApplicationStatus[application.disposition],
    [application.disposition]
  );

  return (
    <Box sx={{mb: 6}}>
      <Typography
        variant="h1"
        sx={{fontFamily: 'inherit', textAlign: 'center', fontSize: 42, py: 5}}
      >
        Hello {userName}!
      </Typography>
      <ApplicationStatusMessage
        application={application}
        merchantApplicationStatus={merchantApplicationStatus}
        group={group}
        onResumeApplication={onResumeApplication}
      />
      <ApplicationSteps
        applicationId={application.id}
        merchantApplicationStatus={merchantApplicationStatus}
      />
    </Box>
  );
};
