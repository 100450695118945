import {DndContext, DragEndEvent, DragOverlay, DragStartEvent} from '@dnd-kit/core';
import {Grid, Paper, Table, Typography} from '@mui/material';
import {Resource, ResourceDocumentCategoryView, ResourceView, useCallable} from '@ozark/common';
import {Loading} from '@ozark/common/components';
import {useNotification} from '@ozark/common/hooks';
import {Collections} from '@ozark/functions/src/constants';
import {useMemo, useState} from 'react';
import {Firebase} from '../../../firebase';
import {ConfirmationDialog} from '../../ConfirmationDialog';
import {DisplayMode, ResourcesApplicationType} from '../types';
import {EditableField, EditableFieldValues, styles} from './constants';
import {DisplayAllResources} from './DisplayAllResources';
import {DisplayContext, DisplayContextProps} from './DisplayContext';
import {DisplayResourceAsRow} from './DisplayResourceAsRow';

interface Props {
  appType: ResourcesApplicationType;
  groupId?: string;
  allowEdit: boolean;
  displayMode: DisplayMode;
  isDragHandleAllowed: boolean;
  resources: ResourceView[];
  handleDeleteResource: (id: string) => () => Promise<void>;
  handleEditClick: (resource: ResourceView) => void;
  reorderItems: (resourceId: string, newOrder: number) => void;
  documentCategories: ResourceDocumentCategoryView[] | undefined;
}

export const DisplayResources = ({
  appType,
  groupId,
  allowEdit,
  displayMode,
  isDragHandleAllowed,
  resources,
  handleDeleteResource,
  handleEditClick,
  reorderItems,
  documentCategories,
}: Props) => {
  const showNotification = useNotification();
  const [confirmationAction, setConfirmationAction] = useState<(() => Promise<void>) | null>(null);
  const {updateResourceOrder, updateResource} = useCallable();
  const [activeDraggedResourceView, setActiveDraggedResourceView] = useState<
    ResourceView | undefined
  >(undefined);
  const handleDragStart = (event: DragStartEvent) => {
    const {active} = event;
    if (!active) {
      setActiveDraggedResourceView(undefined);
      return;
    }
    const resourceView = resources.find(x => x.id === active.id);
    setActiveDraggedResourceView(resourceView);
  };

  const handleSaveField = async (field: EditableField, id: string, value: string) => {
    try {
      const snapshot = await Firebase.firestore.collection(Collections.resources).doc(id).get();
      if (snapshot.exists) {
        let obj: Partial<Resource> = {[field]: value};

        const result = await updateResource({
          resourceId: id,
          resource: obj,
        });
        if (result.status === 'error') {
          showNotification('error', `An error has happened. Please try again later`);
        } else {
          showNotification('success', `${EditableFieldValues[field]} successfully updated`);
        }
      }
    } catch (err: any) {
      console.error(`failed to update File Label. ${err.toString()}`);
      showNotification('error', `An error has happened. Please try again later`);
    }
  };

  const handleValidateCategoryName = async (newValue: string) => {
    if (newValue.trim().length === 0) {
      showNotification('error', 'Category Name is required');
      return false;
    }

    const isUniqueCategoryName = !documentCategories!.map(cat => cat.name).includes(newValue);
    if (!isUniqueCategoryName) {
      showNotification('error', 'Category Name should be unique');
    }

    return isUniqueCategoryName;
  };

  const handleSaveCategory = async (id: string, value: string) => {
    try {
      const snapshot = await Firebase.firestore
        .collection(Collections.resourceDocumentCategories)
        .doc(id)
        .get();
      if (snapshot.exists) {
        await snapshot.ref.set({name: value}, {merge: true});
      }

      showNotification('success', `Category Name successfully updated`);
    } catch (err: any) {
      console.error(`failed to save Category Name. ${err.toString()}`);
      showNotification('error', `Failed to save Category Name`);
    }
  };

  const isRequiredFieldValid = async (field: EditableField, value: string) => {
    if (value.trim().length === 0) {
      showNotification('error', `${EditableFieldValues[field]} is required`);
      return false;
    }
    return true;
  };

  const writePermissionsForResource = (r: ResourceView) =>
    allowEdit &&
    ((appType === ResourcesApplicationType.erp && r.shared) ||
      (appType === ResourcesApplicationType.portal && !r.shared));

  const handleDeleteClick = (resourceId: string) => {
    setConfirmationAction(() => handleDeleteResource(resourceId));
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    setActiveDraggedResourceView(undefined);
    const {active, over} = event;
    if (!over || active.id === over.id) {
      return;
    }
    const overResource = resources.find(x => x.id === over.id);
    if (!overResource) {
      return;
    }
    const resourceId = active.id.toString();
    const newOrder = overResource.order;
    reorderItems(resourceId, newOrder);
    await updateResourceOrder({
      resourceId: resourceId,
      newOrder: newOrder,
    });
  };

  const handleDragCancel = () => {
    setActiveDraggedResourceView(undefined);
  };

  const displayContextProps = useMemo<DisplayContextProps>(
    () => ({
      groupId,
      appType,
      allowEdit,
      displayMode,
      documentCategories: documentCategories ?? [],
      activeDraggedResourceView,
      isRequiredFieldValid,
      isDragHandleAllowed: isDragHandleAllowed && appType === ResourcesApplicationType.erp,
      handleSaveField,
      writePermissionsForResource,
      handleEditClick,
      handleDeleteClick,
      handleValidateCategoryName,
      handleSaveCategory,
    }),
    [
      groupId,
      appType,
      allowEdit,
      displayMode,
      documentCategories,
      activeDraggedResourceView,
      isRequiredFieldValid,
      isDragHandleAllowed,
      handleSaveField,
      writePermissionsForResource,
      handleEditClick,
      handleDeleteClick,
      handleValidateCategoryName,
      handleSaveCategory,
    ]
  );

  if (!documentCategories) return <Loading />;
  if (!documentCategories.length) {
    return <Typography sx={styles.noContent}>No document categories added yet</Typography>;
  }

  return (
    <DisplayContext.Provider value={displayContextProps}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper sx={styles.paper}>
            <DndContext
              onDragEnd={handleDragEnd}
              onDragStart={handleDragStart}
              onDragCancel={handleDragCancel}
            >
              <DisplayAllResources resources={resources} documentCategories={documentCategories} />
              <DragOverlay>
                {activeDraggedResourceView ? (
                  <Table sx={{backgroundColor: 'white'}} aria-label="resources table">
                    <DisplayResourceAsRow resourceView={activeDraggedResourceView} />
                  </Table>
                ) : null}
              </DragOverlay>
            </DndContext>
          </Paper>
        </Grid>
        <ConfirmationDialog
          title="Confirmation"
          message="Are you sure you want to delete this resource?"
          onClose={() => setConfirmationAction(null)}
          onConfirm={confirmationAction}
        />
      </Grid>
    </DisplayContext.Provider>
  );
};
