import {TextField, TextFieldProps, Typography, TypographyProps} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {useKeyPress, useOnClickOutside} from '@ozark/common';
import {useEffect, useRef, useState} from 'react';
import * as yup from 'yup';

declare type Yup = typeof import('yup');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typoStyle: {
      cursor: 'pointer',
      display: 'inherit',
    },
    hidden: {
      display: 'none',
    },
  })
);

interface Props<T> {
  name: string;
  value: T;
  renderLabel: (value: T) => string | JSX.Element;
  onChange?: (name: string, value: T) => void;
  validationSchema: (yup: Yup) => any;
  undoOnClickAway?: boolean;
  editOnRender?: boolean;
  TypographyProps?: Partial<TypographyProps>;
  TextFieldProps?: Partial<TextFieldProps>;
}

export const InlineTypographyGeneric = <T extends unknown>({
  name,
  value,
  renderLabel,
  onChange,
  validationSchema,
  undoOnClickAway,
  editOnRender = false,
  TypographyProps,
  TextFieldProps,
}: Props<T>) => {
  const classes = useStyles();

  const schema = yup.object().shape({
    [name]: validationSchema(yup),
  });

  const [inputValue, setInputValue] = useState<T>(value);
  const [editing, setEditing] = useState<boolean>(editOnRender);
  const [error, setError] = useState<boolean>(false);

  const wrapperRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [enter] = useKeyPress('Enter');
  const [escape] = useKeyPress('Escape');

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (!editing) return;
    inputRef.current?.focus();
  }, [editing]);

  const saveChanges = () => {
    schema
      .validate({
        [name]: inputValue,
      })
      .then(obj => {
        setError(false);
        onChange?.(name, obj[name]);
        setEditing(false);
      })
      .catch(err => {
        setError(true);
      });
  };

  const revertChanges = () => {
    setInputValue(value);
    setEditing(false);
  };

  useOnClickOutside(wrapperRef, () => {
    if (editing) {
      undoOnClickAway ? revertChanges() : saveChanges();
    }
  });

  const onEnterKeyPress = async () => {
    if (enter) {
      saveChanges();
    }
  };

  const onEscapeKeyPress = () => {
    if (escape) {
      revertChanges();
    }
  };

  useEffect(() => {
    if (editing) {
      onEnterKeyPress();
      onEscapeKeyPress();
    }
  }, [onEnterKeyPress, onEscapeKeyPress, editing]);

  return (
    <div ref={wrapperRef}>
      <Typography
        onClick={() => setEditing(true)}
        className={`${classes.typoStyle} ${'className'}`}
        noWrap={true}
        {...TypographyProps}
        style={{display: editing ? 'none' : 'inherit'}}
      >
        {renderLabel?.(inputValue) || String(inputValue)}
      </Typography>
      <TextField
        inputRef={inputRef}
        value={inputValue}
        onChange={e => setInputValue(e.target.value as T)}
        {...TextFieldProps}
        style={{display: editing ? 'inherit' : 'none'}}
        error={error}
        autoFocus
      />
    </div>
  );
};
