import {Tab} from '@mui/material';
import {useHistory} from 'react-router-dom';

interface LinkTabProps {
  label: string;
  href: string;
}

export function TabsRouterTab(props: LinkTabProps) {
  const history = useHistory();
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        history.push(props.href);
      }}
      sx={{
        textTransform: 'none',
      }}
      {...props}
    />
  );
}
