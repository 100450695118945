import React, {useEffect} from 'react';
import {TicketTypeAndCategory} from '../../..';
import {CategoryEditorTypeAndCategory} from '../TicketEditDetails/Category/CategoryEditorTypeAndCategory';
import {InputCategoryError} from '../types';
import {validateCategory} from '../utils/validation';
import {FunctionReturningBoolean} from './types';

interface Props {
  draft?: TicketTypeAndCategory;
  setDraft: (draft: TicketTypeAndCategory) => void;
  errors: Record<InputCategoryError, string>;
  setErrors: React.Dispatch<React.SetStateAction<Record<InputCategoryError, string>>>;
  setOnNextClick: (fn: FunctionReturningBoolean) => void;
}

const validateCallBack = (
  _draft: TicketTypeAndCategory | undefined,
  _setErrors: React.Dispatch<React.SetStateAction<Record<InputCategoryError, string>>>
) => {
  return validateCategory(_draft, _setErrors);
};

export const CategoryStep = ({draft, setDraft, errors, setErrors, setOnNextClick}: Props) => {
  useEffect(() => {
    const _draft = draft;
    const _setErrors = setErrors;
    setOnNextClick(() => () => validateCallBack(_draft, _setErrors));
  }, [draft, setErrors]);
  return (
    <CategoryEditorTypeAndCategory
      draft={draft}
      setDraft={setDraft}
      errors={errors}
      setErrors={setErrors}
      useDefaultValue
    />
  );
};
