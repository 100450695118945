import {useCallback, useEffect, useState} from 'react';
import {SearchCriteria} from '../../..';

type SortDir = 'ASC' | 'DESC';

export function useDataSorted<T extends {[Property in keyof T]: number}>(data?: T[]) {
  const [dataSorted, setDataSorted] = useState<T[]>(data ?? []);
  const [sort, setSort] = useState<[keyof T, SortDir][]>([]);

  const handleRetrieveData = useCallback(
    (searchCriteria: SearchCriteria) => {
      const propSortedBy = searchCriteria.orderBy as keyof T;
      console.info('handleRetrieveData called...');
      setSort([[propSortedBy, searchCriteria.order.toUpperCase() as SortDir]]);

      const dataSortedNew = [...dataSorted];
      dataSortedNew.sort((a, b) =>
        searchCriteria.order === 'desc'
          ? a[propSortedBy] - b[propSortedBy]
          : b[propSortedBy] - a[propSortedBy]
      );

      setDataSorted(dataSortedNew);
    },
    [dataSorted]
  );

  useEffect(() => setDataSorted(data ?? []), [data]);

  return {
    sort,
    dataSorted,
    hasData: dataSorted.length > 0,
    handleRetrieveData,
  };
}
