import {FormControlLabel, Switch} from '@mui/material';
import {IMerchantSupportModel} from '@ozark/functions/src/documents';
import React from 'react';
import {Control, Controller} from 'react-hook-form';

interface SwitchMerchantSupportModelProps {
  control: Control<IMerchantSupportModel, any>;
  name: string;
  label: string;
  isReadOnly?: boolean;
}

export const SwitchMerchantSupportModel: React.FC<SwitchMerchantSupportModelProps> = ({
  control,
  name,
  label,
  isReadOnly = false,
}: SwitchMerchantSupportModelProps) => {
  return (
    <Controller
      name={name as keyof IMerchantSupportModel}
      control={control}
      defaultValue={false}
      render={({field: {value, ...rest}}) => (
        <FormControlLabel
          control={
            <Switch {...rest} disabled={isReadOnly} checked={value as boolean | undefined} />
          }
          label={label}
        />
      )}
    />
  );
};
