import GroupsIcon from '@mui/icons-material/Groups';
import {GroupView} from '@ozark/functions/src/documents';
import {useEffect, useState} from 'react';
import {AsyncState} from '../../models';
import {AutoCompleteInputBase} from '../AutoComplete';

type Props = {
  onGroupSelect: (agent?: GroupView) => void;
  groups: AsyncState<GroupView[]>;
  selectedGroup?: GroupView;
};

export const GroupSelect = ({groups, onGroupSelect, selectedGroup}: Props) => {
  const [activeGroups, setActiveGroups] = useState<GroupView[]>([]);

  useEffect(() => {
    if (!groups.data) {
      return;
    }

    setActiveGroups(groups.data.filter(group => !group.deleted));
  }, [groups]);

  const getDropDownTitle = (group?: GroupView): string => (group ? `${group?.name}` : 'All Groups');

  return (
    <AutoCompleteInputBase
      selected={selectedGroup}
      setSelected={onGroupSelect}
      icon={GroupsIcon}
      placeholder="Select group..."
      width={200}
      options={activeGroups}
      getOptionLabel={(group: GroupView) => getDropDownTitle(group)}
      onItemSelect={(group: GroupView | null) => onGroupSelect(group ?? undefined)}
    />
  );
};
