import {Box, Breadcrumbs as MuiBreadcrumbs, Divider, Typography} from '@mui/material';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {createContainer} from 'unstated-next';

export type Breadcrumb = {
  text: string;
  url: string;
};

function useBreadcrumbs(initialState: Breadcrumb[] = []) {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>(initialState);

  return {
    breadcrumbs,
    setBreadcrumbs,
  };
}

export const BreadcrumbsContainer = createContainer(useBreadcrumbs);
export const useBreadcrumbsContainer = BreadcrumbsContainer.useContainer;

type Props = {
  children?: React.ReactNode;
};

export const Breadcrumbs = ({children = null}: Props) => {
  const {breadcrumbs} = useBreadcrumbsContainer();
  return (
    <Box>
      <Box display="flex" alignItems="center" minHeight="4em">
        {breadcrumbs.length && (
          <MuiBreadcrumbs sx={{minWidth: 200}}>
            {breadcrumbs.slice(0, -1).map(b => (
              <Link key={b.url} to={b.url}>
                {b.text}
              </Link>
            ))}
            {breadcrumbs.slice(-1).map(b => (
              <Typography key={b.text}>{b.text}</Typography>
            ))}
          </MuiBreadcrumbs>
        )}
        {/* <Box flex={1} /> */}
        {children}
      </Box>
      <Divider />
    </Box>
  );
};
