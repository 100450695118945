import {Typography} from '@mui/material';
import {currentFormatter} from '../Reporting';

export const getAmountSelector = (amount: number) => {
  const value = currentFormatter.format(amount);
  if (amount < 0) {
    return (
      <Typography sx={{color: 'red', fontSize: 'inherit', fontWeight: 'inherit'}}>
        {value}
      </Typography>
    );
  }
  return <Typography sx={{fontSize: 'inherit', fontWeight: 'inherit'}}>{value}</Typography>;
};
