import {Box, Paper} from '@mui/material';
import {PieTooltipProps, ResponsivePie} from '@nivo/pie';
import {MccInfo} from '@ozark/functions/src/functions/express/private/types/Reports';
import {isEqual} from '@s-libs/micro-dash';
import {memo} from 'react';
import {truncate} from '../../..';
import {formatAsPercent, formatterCurrency} from '../../../util/format';

type Props = {
  data: MccInfo[];
};

const MccsBySalesPieComponent = ({data}: Props) => {
  var dataFormated = formatAsPieChartData(data);

  return (
    <ResponsivePie
      colors={{scheme: 'set2'}}
      data={dataFormated}
      margin={{top: 40, right: 140, bottom: 80, left: 140}}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}
      enableArcLinkLabels={true}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{from: 'color'}}
      arcLinkLabel={d =>
        d.data.label ? truncate(d.data.label.toString()) ?? '' : '...' + d.id.toString().slice(8)
      }
      valueFormat={value => formatterCurrency.format(value)}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{from: 'color', modifiers: [['darker', 2]]}}
      tooltip={Tooltip}
    />
  );
};

export const MccsBySalesPie = memo(MccsBySalesPieComponent, isEqual);

const Tooltip = ({datum}: PieTooltipProps<ReturnType<typeof formatAsPieChartData>[number]>) => (
  <Paper square elevation={1}>
    <Box p={1} borderLeft={`5px solid ${datum.color}`}>
      <Box pb={1}>
        <div>
          <b>
            {datum.data.businessType} ({datum.data.id})
          </b>
        </div>
      </Box>
      <div>Sales Amount:&emsp;{formatterCurrency.format(datum.data.value)}</div>
      <div>% of Total:&emsp;&emsp;&emsp;{formatAsPercent(datum.data.percentage)}</div>
    </Box>
  </Paper>
);

const formatAsPieChartData = (data: MccInfo[]) => {
  const result = data.map((i: MccInfo, index: number) => ({
    id: i.mcc,
    label: i.businessType,
    value: i.salesAmount,
    businessType: i.businessType,
    percentage: i.percentOfTotal,
  }));

  return result;
};
