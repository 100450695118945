import {Theme} from '@mui/material/styles';
import MuiTab from '@mui/material/Tab';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

export const Tab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      '&:hover': {
        color: '#1c252c',
        opacity: 1,
      },
      '&$selected': {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    selected: {},
  })
)((props: any) => <MuiTab disableRipple {...props} />);
