import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {useCallback} from 'react';
import {Controller} from 'react-hook-form';
import {Collections, Firebase, TicketPriority} from '../../../..';
import {useIsEditingDisabled} from '../../hooks/useIsEditingDisabled';
import {useTicketId} from '../../hooks/useTicketId';

export function InputPriority() {
  const {ticketId} = useTicketId();
  const {isEditingDisabled} = useIsEditingDisabled();

  const savePriority = useCallback(
    (priority: TicketPriority) => {
      Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .set({priority, updatedAt: Firebase.now()}, {merge: true})
        .catch(error => {
          console.error('Error writing document (InputSubject): ', error);
        });
    },
    [ticketId]
  );

  return (
    <FormControl fullWidth>
      <Controller
        name="priority"
        render={({field}) => (
          <Select
            disabled={isEditingDisabled}
            labelId="label-priority"
            id={field.name}
            value={field.value ?? ''}
            label="Priority"
            onBlur={field.onBlur}
            onChange={e => {
              field.onChange(e);
              savePriority(e.target.value as TicketPriority);
            }}
            variant="filled"
            disableUnderline
            inputRef={field.ref}
            sx={{
              '&.MuiFilledInput-root': {
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderBottomLeftRadius: '4px',
                borderBottomRightRadius: '4px',
                ml: -1,
              },
              '&.MuiFilledInput-root:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.09)',
              },
              '& .MuiSelect-select': {
                pt: 1,
              },
            }}
          >
            <MenuItem value={TicketPriority.High}>{TicketPriority.High}</MenuItem>
            <MenuItem value={TicketPriority.Normal}>{TicketPriority.Normal}</MenuItem>
            <MenuItem value={TicketPriority.Low}>{TicketPriority.Low}</MenuItem>
          </Select>
        )}
      />
    </FormControl>
  );
}
