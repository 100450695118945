import {useEffect} from 'react';

export const useOnClickOutside = (ref: any, handler: any) => {
  const listener = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      handler(event);
    }
  };

  useEffect(() => {
    document.addEventListener('click', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('click', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};
