import AddIcon from '@mui/icons-material/Add';
import {Button, Dialog, DialogContent, DialogTitle} from '@mui/material';
import {useState} from 'react';
import {EditorWarning} from '../common/EditorWarning';
import {useIsEditingClosed} from '../hooks/useIsEditingClosed';
import {CommentEditor} from './CommentEditor';

export const CommentsAddComment = () => {
  const [open, setOpen] = useState(false);
  const {isEditingClosed} = useIsEditingClosed();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleClickOpen}
        startIcon={<AddIcon />}
        color="primary"
        disabled={isEditingClosed}
      >
        Add Comment
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>New Comment</DialogTitle>
        <DialogContent>
          <EditorWarning actionName="add" />
          <CommentEditor handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  );
};
