import ClearIcon from '@mui/icons-material/Clear';
import DescriptionIcon from '@mui/icons-material/Description';
import PersonIcon from '@mui/icons-material/Person';
import {
  alpha,
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  IconButton,
  InputBase,
  Link,
  Paper,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  AgentView,
  ApplicationView,
  Collections,
  createAuditNonce,
  Dispositions,
  ExportToCsv,
  Firebase,
  getSortedOptions,
  GroupRole,
  InfiniteSnapshotOptions,
  selectApplicationView,
  useInfiniteSnapshots,
  useNotification,
} from '@ozark/common';
import {InfiniteDocuments, Loading, Title} from '@ozark/common/components';
import {SearchIcon} from '@ozark/common/icons';
import {format} from 'date-fns';
import firebase from 'firebase/compat/app';
import {useEffect, useState} from 'react';
import {AgentSelectDrawer} from './AgentSelectDrawer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerPaper: {
      height: 40,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      width: '100%',
      padding: theme.spacing(1, 2, 1, 0),
    },
    paper: {
      cursor: 'pointer',
      height: 40,
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      color: '#4d6575',
      padding: theme.spacing(1, 2, 1, 0),
      margin: theme.spacing(0, 0, 2, 0),
      width: '100%',
      transition: theme.transitions.create(['padding', 'border-color', 'box-shadow']),
      duration: theme.transitions.duration.complex,
      '&:hover': {
        boxShadow: `0px 1px 3px 0px ${alpha(theme.palette.primary.light, 0.9)}`,
      },
    },
    root: {
      height: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.2) inset, 0px 1px 1px 0px rgba(0,0,0,0.14) inset, 0px 1px 3px 0px rgba(0,0,0,0.12) inset',
      backgroundColor: '#fff',
      marginLeft: 0,
      width: 300,
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    searchContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      color: 'rgba(0, 0, 0, 0.8)',
      padding: theme.spacing(0.5, 1.5, 0.5, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      paddingRight: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
    },
    divider: {
      padding: 0,
    },
    column: {
      height: '100%',
      padding: theme.spacing(0, 2, 0, 2),
      flexBasis: '25%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      transition: theme.transitions.create(['flexBasis'], {
        duration: theme.transitions.duration.standard,
      }),
      [theme.breakpoints.down('lg')]: {
        '&:hover': {
          flexBasis: '40%',
        },
      },
    },
    checkboxColumn: {
      height: '100%',
      minWidth: 40,
      padding: theme.spacing(0, 2, 0, 1),
    },
    checkbox: {
      padding: 0,
    },
    actionBar: {
      display: 'flex',
      padding: theme.spacing(1.5, 2, 1.5, 0),
    },
    actionBarCallout: {
      padding: theme.spacing(0.5, 2, 0.5, 0),
    },
    exportBtn: {
      padding: theme.spacing(0.5, 2, 0.5, 0),
    },
    clearIcon: {
      padding: theme.spacing(0.5, 0.5, 0.5, 0.5),
    },
  })
);

const getAgentName = (agent: AgentView) => `${agent.firstName} ${agent.lastName}`;

const getAgentFilter =
  (agent?: AgentView) =>
  (
    query:
      | firebase.firestore.Query<firebase.firestore.DocumentData>
      | firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
  ): firebase.firestore.Query<firebase.firestore.DocumentData> => {
    const resultQuery = query.where('disposition', '==', Dispositions.boarded);

    // ERP users have access to all apps
    if (!agent) {
      return resultQuery;
    }

    const agentQuery = resultQuery.where('group.id', '==', agent.group.id);

    if (agent.role === GroupRole.administrator) {
      return agentQuery;
    }

    if (agent.subAgentUids && agent.subAgentUids.length > 0) {
      return agentQuery.where('agent.id', 'in', [agent.id, ...agent.subAgentUids]);
    }

    return agentQuery.where('agent.id', '==', agent.id);
  };

type AgentAssociationRecord = {
  mid: string;
  boardedDateTime: Date;
  dba: string;
  legalName: string;
  agentName: string;
  groupName: string;
};

// Firebase query limit
const batchLimit = 500;

const mapApplicationViewsToAgentAssociationRecords = (
  applications: ApplicationView[]
): AgentAssociationRecord[] => {
  return applications.map(app => {
    return {
      mid: app.mid!,
      boardedDateTime: app.dispositionUpdatedAt.toDate(),
      dba: app.doingBusinessAs,
      legalName: app.legalBusinessName,
      agentName: app.agent?.firstName + ' ' + app.agent?.lastName,
      groupName: app.group.name,
    } as AgentAssociationRecord;
  });
};

const getAssociationsForAgent = async (agent?: AgentView): Promise<AgentAssociationRecord[]> => {
  const filter = getAgentFilter(agent);
  const appRef = Firebase.firestore.collection(Collections.applications);

  let query = filter(appRef).limit(batchLimit);
  let applicationsBatch: ApplicationView[] = [];
  let applications: ApplicationView[] = [];
  do {
    const appCollectionsSnapshot = await query.get();
    if (appCollectionsSnapshot.docs.length === 0) break;

    applicationsBatch = appCollectionsSnapshot.docs.map(x => x.data() as ApplicationView);
    applications = applications.concat(applicationsBatch);

    query = query.startAfter(appCollectionsSnapshot.docs[appCollectionsSnapshot.docs.length - 1]);
  } while (applicationsBatch.length === batchLimit);

  applications = applications.sort(
    (b, a) =>
      (b.dispositionUpdatedAt?.toDate()?.getTime() ?? 0) -
      (a.dispositionUpdatedAt?.toDate()?.getTime() ?? 0)
  );

  return mapApplicationViewsToAgentAssociationRecords(applications);
};

type Props = {
  agents?: AgentView[];
  portalUser?: AgentView;
};

export const AgentMidAssociation = ({agents, portalUser}: Props) => {
  const classes = useStyles();
  const showNotification = useNotification();

  const [updatingMids, setUpdatingMids] = useState(false);
  const [activeAgents, setActiveAgents] = useState<Record<string, AgentView>>({});
  const [selectedAgentId, setSelectedAgentId] = useState<string | null>(null);

  const [checkedMids, setCheckedMids] = useState<{
    [_key: string]: {checked: boolean; appId: string; agentId?: string};
  }>({});
  const [assignDialogOpen, setAssignedDialogOpen] = useState(false);

  const [options, setOptions] = useState<Partial<InfiniteSnapshotOptions>>({
    order: 'desc',
    orderBy: 'dispositionUpdatedAt',
    limit: 50,
    filter: query => query.where('disposition', '==', Dispositions.boarded),
  });

  const {documents: applications, next} = useInfiniteSnapshots<ApplicationView>(
    Collections.applications,
    selectApplicationView,
    options
  );

  useEffect(() => {
    if (!agents) {
      return;
    }

    setActiveAgents(
      getSortedOptions(agents, getAgentName)!.reduce((accumulator, agent) => {
        if (agent.isActive) {
          accumulator[agent.id] = agent;
        }
        return accumulator;
      }, {} as Record<string, AgentView>)
    );
  }, [agents, setActiveAgents]);

  useEffect(() => {
    if (selectedAgentId) {
      setOptions(prevOptions => ({
        ...prevOptions,
        filter: getAgentFilter(activeAgents[selectedAgentId]),
      }));
    } else {
      setOptions(prevOptions => ({
        ...prevOptions,
        filter: portalUser ? getAgentFilter(portalUser) : getAgentFilter(),
      }));
    }
  }, [selectedAgentId, activeAgents, setOptions, portalUser]);

  const handleMidCheck = (mid: string, appId: string, agentId?: string) => (_event: any) => {
    setCheckedMids(prevState => ({
      ...prevState,
      [mid]: {checked: !prevState[mid] || !prevState[mid].checked, appId, agentId},
    }));
  };

  const handleAssignMids = () => {
    setAssignedDialogOpen(true);
  };

  const handleClearCheckedMids = () => {
    setCheckedMids({});
  };

  const handleCloseAssignDialog = () => {
    setAssignedDialogOpen(false);
  };

  const handleAgentAssign = (agentId: string) => {
    setUpdatingMids(true);

    // Unless there is a programming error there should always be agent, therefore selecting first item is safe.
    updateFirestore(activeAgents[agentId]);

    setCheckedMids({});
    setUpdatingMids(false);
  };

  const updateFirestore = async (agent: AgentView) => {
    const batch = Firebase.firestore.batch();

    const auditNonce = createAuditNonce(Firebase.auth.currentUser!.uid);

    for (let mid of Object.keys(checkedMids)) {
      if (
        checkedMids[mid].checked &&
        (!checkedMids[mid].agentId || checkedMids[mid].agentId !== agent.id)
      ) {
        var appRef = Firebase.firestore
          .collection(Collections.applications)
          .doc(checkedMids[mid].appId);

        const data = {
          'agent.id': agent.id,
          'agent.firstName': agent.firstName,
          'agent.lastName': agent.lastName,
          auditNonce,
        };

        batch.update(appRef, data);
      }
    }

    try {
      await batch.commit();
      showNotification('success', 'Successfully assigned the new agent');
    } catch (err: any) {
      setUpdatingMids(false);
      console.error(err);
      showNotification('error', (err as Error).message);
    }
  };

  const exportToCsv = async () => {
    const options = {
      fieldSeparator: ',',
      filename: `mid-associations-report`,
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Agent MID Associations Report',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const report = await getAssociationsForAgent(
      selectedAgentId ? activeAgents[selectedAgentId] : portalUser ? portalUser : undefined
    );

    if (report.length === 0) {
      showNotification('info', 'There are no records to export');
      return;
    }

    const exporter = new ExportToCsv(options);

    exporter.generateCsv(report);
  };

  const getNumberOfSelectedMids = () => {
    return Object.keys(checkedMids).reduce((accumulator, value) => {
      return accumulator + Number(checkedMids[value].checked);
    }, 0);
  };

  const renderSearch = () => (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <Autocomplete
        id="combo-box-demo"
        value={selectedAgentId}
        options={Object.keys(activeAgents)}
        getOptionLabel={agentId => (agentId ? getAgentName(activeAgents[agentId]) : '')}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        renderInput={params => (
          <div ref={params.InputProps.ref}>
            <InputBase inputProps={{...params.inputProps}} placeholder="Search for agent's name" />
          </div>
        )}
        onChange={(_event, newInputValue) => {
          setSelectedAgentId(newInputValue as string);
        }}
      />
    </div>
  );

  const numberOfSelectedMids = getNumberOfSelectedMids();

  return (
    <div className={classes.root}>
      <Title
        breadcrumbs={[
          <Link component="button" variant="body1">
            Agent/MID Associations
          </Link>,
        ]}
      >
        {numberOfSelectedMids > 0 && (
          <div className={classes.actionBar}>
            <IconButton
              className={classes.clearIcon}
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={handleClearCheckedMids}
              size="large"
            >
              <ClearIcon />
            </IconButton>
            <Typography className={classes.actionBarCallout}>
              {numberOfSelectedMids} MID{numberOfSelectedMids === 1 ? '' : 's'} selected
            </Typography>
            <Button
              size="small"
              onClick={handleAssignMids}
              variant="contained"
              color="primary"
              startIcon={<PersonIcon />}
            >
              Assign
            </Button>
          </div>
        )}

        <div className={classes.exportBtn}>
          <Button
            size="small"
            onClick={exportToCsv}
            variant="contained"
            color="primary"
            startIcon={<DescriptionIcon />}
          >
            Export
          </Button>
        </div>

        {agents && <div className={classes.searchContainer}>{renderSearch()}</div>}
      </Title>

      <Paper className={classes.headerPaper}>
        <div className={classes.checkboxColumn} />
        <Divider className={classes.divider} orientation="vertical" flexItem />
        <div className={classes.column}>
          <Typography variant="subtitle1" component="p" noWrap gutterBottom>
            Boarded Date
          </Typography>
        </div>
        <Divider className={classes.divider} orientation="vertical" flexItem />
        <div className={classes.column}>
          <Typography variant="subtitle1" component="p" noWrap gutterBottom>
            MID
          </Typography>
        </div>
        <Divider className={classes.divider} orientation="vertical" flexItem />
        <div className={classes.column}>
          <Typography variant="subtitle1" component="p" noWrap gutterBottom>
            Doing Business As
          </Typography>
        </div>
        <Divider className={classes.divider} orientation="vertical" flexItem />
        <div className={classes.column}>
          <Typography variant="subtitle1" component="p" noWrap gutterBottom>
            Legal Business Name
          </Typography>
        </div>
        <Divider className={classes.divider} orientation="vertical" flexItem />
        <div className={classes.column}>
          <Typography variant="subtitle1" component="p" noWrap gutterBottom>
            Assigned Agent
          </Typography>
        </div>
        <Divider className={classes.divider} orientation="vertical" flexItem />
        <div className={classes.column}>
          <Typography variant="subtitle1" component="p" noWrap gutterBottom>
            Group
          </Typography>
        </div>
      </Paper>
      <InfiniteDocuments
        documents={applications}
        next={next}
        itemSize={45}
        onDocumentRender={document => (
          <Paper
            className={classes.paper}
            onClick={handleMidCheck(document.mid!, document.id, document.agent?.id)}
          >
            <div className={classes.checkboxColumn}>
              <Checkbox
                checked={checkedMids[document.mid!] && checkedMids[document.mid!].checked}
                name="checkedB"
                color="primary"
                className={classes.checkbox}
              />
            </div>
            <Divider className={classes.divider} orientation="vertical" flexItem />
            <div className={classes.column}>
              <Typography variant="body1" component="p" noWrap gutterBottom color="textPrimary">
                {document.dispositionUpdatedAt
                  ? format(document.dispositionUpdatedAt?.toDate(), 'MM/dd/yyyy h:mm a')
                  : 'n/a'}
              </Typography>
            </div>
            <Divider className={classes.divider} orientation="vertical" flexItem />
            <div className={classes.column}>
              <Typography variant="body1" component="p" noWrap gutterBottom color="textPrimary">
                {document.mid}
              </Typography>
            </div>
            <Divider className={classes.divider} orientation="vertical" flexItem />
            <div className={classes.column}>
              <Typography variant="body1" component="p" noWrap gutterBottom color="textPrimary">
                {document.doingBusinessAs}
              </Typography>
            </div>
            <Divider className={classes.divider} orientation="vertical" flexItem />
            <div className={classes.column}>
              <Typography variant="body1" component="p" noWrap gutterBottom color="textPrimary">
                {document.legalBusinessName}
              </Typography>
            </div>
            <Divider className={classes.divider} orientation="vertical" flexItem />
            <div className={classes.column}>
              <Typography variant="body1" component="p" noWrap gutterBottom color="textPrimary">
                {document.agent ? `${document.agent.firstName} ${document.agent.lastName}` : ''}
              </Typography>
            </div>
            <Divider className={classes.divider} orientation="vertical" flexItem />
            <div className={classes.column}>
              <Typography variant="body1" component="p" noWrap gutterBottom color="textPrimary">
                {document.group.name}
              </Typography>
            </div>
          </Paper>
        )}
      />
      <AgentSelectDrawer
        open={assignDialogOpen}
        onClose={handleCloseAssignDialog}
        agents={Object.keys(activeAgents).map(agentId => activeAgents[agentId])}
        onAgentSelect={handleAgentAssign}
      />
      {updatingMids && <Loading />}
    </div>
  );
};
