import {
  DATETIME_FORMAT as DATETIME_FORMAT_COMMON,
  DATE_FORMAT as DATE_FORMAT_COMMON,
} from '@ozark/functions/src/functions/common/DateUtils';
import {
  AuthorizationResponses as AuthorizationResponsesCommon,
  MessageReasonCodes as MessageReasonCodesCommon,
} from '@ozark/functions/src/functions/common/reports/columnConfigs/AuthorizationColumnConfig';
import {
  authStatusApproved,
  authStatusDeclined,
} from '@ozark/functions/src/functions/express/private/types/Authorizations';
import {FilterOption, MtdDefaultDateRange} from '../../../Filters';

export const DATETIME_FORMAT = DATETIME_FORMAT_COMMON;
export const DATE_FORMAT = DATE_FORMAT_COMMON;

export const PaymentTypes: {[_: string]: string} = {
  '00': 'Goods/Service Purchase POS transaction only',
  '01': 'Withdrawal/Cash Advance',
  '02': 'Adjustment - Debit',
  '10': 'Payment Transaction - MasterCard',
  '11': 'Quasi Cash Transaction (POS transaction only) or Online Gambling Transaction',
  '20': 'Return - Credit',
  '21': 'Deposit (Plus)',
  '22': 'Adjustment - Credit',
  '28': 'Pre-Paid Card Load',
  '30': 'Balance Inquiry',
  '39': 'Healthcare Eligibility Inquiry',
  '40': 'Cardholder Account Transfer',
  '50': 'Bill Payment',
  '72': 'Pre-Paid Card Activations',
};

export const MessageReasonCodes: {[_: string]: string} = MessageReasonCodesCommon;

export const AuthorizationResponses: {[_: string]: string} = AuthorizationResponsesCommon;

const MessageReasonCodesFilter = Object.keys(MessageReasonCodes).map(key => {
  return {
    value: MessageReasonCodes[key],
    key: `MRC${key}`,
  };
});

const AuthorizationResponsesFilter = Object.keys(AuthorizationResponses).map(key => {
  return {
    value: AuthorizationResponses[key],
    key: `AR${key}`,
  };
});

const ApprovalStatusFilter = [...MessageReasonCodesFilter, ...AuthorizationResponsesFilter];

export const AuthorizationFilters: FilterOption[] = [
  {
    id: 'mid',
    column: 'mid',
    label: 'MID',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'bin',
    column: 'bin',
    label: 'BIN',
    type: 'text',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'date',
    column: 'transactionDate',
    label: 'Date',
    type: 'date',
    operators: [
      {
        id: '__between',
        label: 'equals',
      },
    ],
  },
  {
    id: 'dateRange',
    column: 'transactionDate',
    label: 'Date Range',
    type: 'dateRange',
    dateFormat: DATETIME_FORMAT,
    defaultDateRange: MtdDefaultDateRange,
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'accountNumberLast4',
    column: 'accountNumberLast4',
    label: 'Last 4',
    type: 'text',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'authorizedAmountSigned',
    column: 'authorizedAmountSigned',
    label: 'Amount',
    type: 'currency',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'approvalCode',
    column: 'approvalCode',
    label: 'Approval Code',
    type: 'text',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'terminalNumber',
    column: 'terminalNumber',
    label: 'TID',
    type: 'text',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'authorizationStatus',
    column: 'authorizationStatus',
    label: 'Status',
    type: 'list',
    options: ApprovalStatusFilter,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'authStatus',
    column: 'authorizationStatus',
    label: 'Auth status',
    type: 'list',
    options: [
      {
        value: 'Declined',
        key: authStatusDeclined,
      },
      {
        value: 'Approved',
        key: authStatusApproved,
      },
    ],
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
];
