import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export enum WebhookEventType {
  applicationDispositionChanged = 'applicationDispositionChanged',
  applicationNotes = 'applicationNotes',
  boardingInformation = 'boardingInformation',
}

export type Webhook = {
  pubSubOrderingKey: string;
  groupId: string;
  verificationSecret: string;
  webhookUrl: string;
  eventTypes: WebhookEventType[];
};

export type WebhookView = ViewBase<Webhook>;

export const selectWebhookView: ViewSelector<WebhookView, Webhook> = snapshot => {
  const webhook: WebhookView = {
    ...defaultViewSelector(snapshot),
  };
  return webhook;
};
