import {
  AsyncState,
  AUTOMATION_TICKET_COMMENT_AUTHOR,
  AUTOMATION_TICKET_COMMENT_UID,
  Collections,
  Firebase,
  SecuredUserProfile,
  TicketComment,
  useNotification,
} from '@ozark/common';
import {useEffect, useState} from 'react';
import {useUserProfilesContainer} from '../../../store/containers/UserProfilesContainer';
import {useMappedComments} from './useMappedComments';
import {useTicketEditContainer} from './useTicketEditContainer';
import {useTicketId} from './useTicketId';

export type CommentInfo = {
  comment: TicketComment;
  humanFriendlyDate: string;
  localTime: string;
  author: SecuredUserProfile | undefined;
};

export const useComments = () => {
  const {commentsCollection} = useTicketEditContainer();
  const {ticketId, isTicketIdValid} = useTicketId();
  const [commentInfos, setCommentInfos] = useState<AsyncState<CommentInfo[]>>({promised: true});
  const [ticketComments, setTicketComments] = useState<AsyncState<TicketComment[]>>({
    promised: true,
  });
  const [commentsCounter, setCommentsCounter] = useState(0);
  const [commentsInternalCounter, setCommentsInternalCounter] = useState(0);
  const {userProfiles} = useUserProfilesContainer();
  const {getMappedComments} = useMappedComments();
  const showNotification = useNotification();

  useEffect(() => {
    if (!isTicketIdValid) {
      console.error('missing ticketId');
      setTicketComments({promised: false, data: []});
      return;
    }
    try {
      setTicketComments({promised: true, data: []});
      return Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .collection(commentsCollection)
        .orderBy('createdAt', 'desc')
        .onSnapshot(
          async snapshot => {
            if (snapshot.size === 0) {
              setTicketComments({promised: false, data: []});
              return;
            }
            const comments = snapshot.docs.map(
              doc => ({...doc.data(), id: doc.id} as TicketComment)
            );
            setTicketComments({promised: false, data: comments});
          },
          err => {
            console.error(err);
            showNotification('error', err.message);
            setTicketComments({promised: false, error: err});
          }
        );
    } catch (error) {
      console.error('error reading comments', error);
    }
  }, [ticketId, isTicketIdValid, commentsCollection]);

  useEffect(() => {
    try {
      const profilesResult = userProfiles.data;
      let profilesDictionary: Record<string, SecuredUserProfile> = {};
      if (profilesResult?.status !== 'error') {
        profilesDictionary = profilesResult?.profilesDictionary ?? {};
      }
      profilesDictionary[AUTOMATION_TICKET_COMMENT_UID] = {
        email: AUTOMATION_TICKET_COMMENT_AUTHOR,
        uid: AUTOMATION_TICKET_COMMENT_UID,
      } as SecuredUserProfile;

      const mappedComments = getMappedComments(ticketComments.data ?? [], profilesDictionary);
      setCommentInfos({promised: false, data: mappedComments});
    } catch (error) {
      console.error('error reading comments', error);
      setCommentInfos({promised: false, data: []});
    }
  }, [ticketComments.promised, userProfiles.promised, ticketComments.data, userProfiles.data]);

  useEffect(() => {
    if (!isTicketIdValid) {
      console.error('missing ticketId');
      setCommentsCounter(0);
      return;
    }
    try {
      return Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .collection(Collections.comments)
        .onSnapshot(
          async snapshot => {
            setCommentsCounter(snapshot.size);
          },
          err => {
            console.error(err);
            setCommentsCounter(0);
          }
        );
    } catch (error) {
      console.error('error reading comments count', error);
    }
  }, [ticketId, isTicketIdValid]);

  useEffect(() => {
    if (!isTicketIdValid) {
      console.error('missing ticketId');
      setCommentsInternalCounter(0);
      return;
    }
    try {
      return Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .collection(Collections.commentsInternal)
        .onSnapshot(
          async snapshot => {
            setCommentsInternalCounter(snapshot.size);
          },
          err => {
            console.error(err);
            setCommentsInternalCounter(0);
          }
        );
    } catch (error) {
      console.error('error reading comments count', error);
    }
  }, [ticketId, isTicketIdValid]);

  return {comments: commentInfos, commentsCounter, commentsInternalCounter};
};
