import {useEffect} from 'react';
import {useNotificationsState} from '../hooks/useNotificationsState';
import {FaviconBadger} from './FaviconBadge';

const faviconBadger = new FaviconBadger();

export const NotificationsTabIndicator = () => {
  const {unreadCount: unseenCount} = useNotificationsState();

  useEffect(() => {
    faviconBadger.value = unseenCount;

    return () => {
      faviconBadger.value = undefined;
    };
  }, [unseenCount]);

  return null;
};
