import {FilterOption, MtdDefaultDateRange} from '../../../Filters';

export const BatchesFilters: FilterOption[] = [
  {
    id: 'mid',
    column: 'mid',
    label: 'MID',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'approvedBatches',
    column: 'approvedBatches',
    label: 'Approved Batch Amount',
    type: 'currencyRange',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'settledBatches',
    column: 'settledBatches',
    label: 'Settled Batch Amount',
    type: 'currencyRange',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'statementDateRange',
    column: 'statementDate',
    label: 'Statement Date Range',
    type: 'monthRange',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'statementDate',
    column: 'statementDate',
    label: 'Statement Date',
    type: 'month',
    operators: [
      {
        id: '__between',
        label: 'equals',
      },
    ],
  },
  {
    id: 'actualDateRange',
    column: 'batchDate',
    label: 'Actual Batch Date Range',
    type: 'dateOnlyRange',
    defaultDateRange: MtdDefaultDateRange,
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'actualBatchDate',
    column: 'batchDate',
    label: 'Actual Batch Date',
    type: 'dateOnly',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'id',
    column: 'id',
    label: 'Batch Id',
    type: 'text',
    operators: [
      {
        id: '__in',
        label: 'equals',
      },
    ],
  },
  {
    id: 'merchantReferenceNumber',
    column: 'merchantReferenceNumber',
    label: 'Reference Number',
    type: 'text',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'accountNumberLast4',
    column: '',
    label: 'Last 4 Card Numbers',
    type: 'text',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
];
