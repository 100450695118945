import {Loading, Resources as ResourcesPage} from '@ozark/common/components';
import {ResourcesApplicationType} from '@ozark/common/components/Resources/types';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';

const Resources = () => {
  const {authProfile, claims, isUserGroupAdmin} = useStore();

  const isGroupAdmin = isUserGroupAdmin();

  if (!claims || authProfile.promised) return <Loading />;

  return (
    <ResourcesPage
      appType={ResourcesApplicationType.portal}
      groupId={claims?.groupId}
      allowEdit={isGroupAdmin}
      historyRoute={ROUTES.RESOURCES}
    />
  );
};

export default Resources;
