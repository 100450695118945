import {useEffect, useMemo, useState} from 'react';
import {useStore} from '../../store/helpers';

export const useAuthSiteJump = (setError: (err: string) => void) => {
  const {loginWithSiteJumpToken} = useStore();
  const jumpToken = useMemo(() => window.location.hash.replace('#jump=', ''), []);
  const [isSiteJumpTokenProcessing, setIsSiteJumpTokenProcessing] = useState(!!jumpToken);

  useEffect(() => {
    if (!jumpToken) {
      return;
    }

    const handleSiteJumpToken = async () => {
      try {
        await loginWithSiteJumpToken(jumpToken);
      } catch (err) {
        console.error('Failed to login with site jump token with an error:', err);
        setError('There was an error logging in');
      } finally {
        setIsSiteJumpTokenProcessing(false);
      }
    };

    handleSiteJumpToken();
  }, [loginWithSiteJumpToken, jumpToken, setError]);

  return {
    isSiteJumpTokenProcessing,
  };
};
