export enum ProcessingTypes {
  cardPresent = 'Card Present',
  cardNotPresent = 'Card Not Present',
  eCommerce = 'E-Commerce',
}

export const ProcessingTypePayments: Record<ProcessingTypes, {title: string; caption: string}> = {
  [ProcessingTypes.cardPresent]: {
    title: 'In Person',
    caption: 'At least 70% of your transactions will be swiped at your location',
  },
  [ProcessingTypes.cardNotPresent]: {
    title: 'Over the Phone',
    caption: 'At least 70% of your transactions will be keyed',
  },
  [ProcessingTypes.eCommerce]: {
    title: 'Online Sales',
    caption: '100% of your transactions will be from online sales',
  },
};

export type ProcessingTypesKeys = keyof typeof ProcessingTypes;
