import {DraggableAttributes} from '@dnd-kit/core';
import {SyntheticListenerMap} from '@dnd-kit/core/dist/hooks/utilities';
import {TableRow} from '@mui/material';
import {ResourceType, ResourceView} from '@ozark/common';
import {forwardRef, useMemo} from 'react';
import {DisplayDocumentAsRowContent} from './DisplayDocumentAsRowContent';
import {DisplayVideoAsRowContent} from './DisplayVideoAsRowContent';

interface Props {
  resourceView: ResourceView;
  listeners?: SyntheticListenerMap | undefined;
  attributes?: DraggableAttributes;
  style?: {
    transform?: string;
    transition?: string;
    width?: string;
    height?: string;
    opacity?: number;
  };
}

export const DisplayResourceAsRow = forwardRef<null, Props>(
  ({resourceView, attributes, listeners, style}: Props, ref) => {
    const rowContent = useMemo(
      () => (
        <>
          {resourceView.type === ResourceType.document && (
            <DisplayDocumentAsRowContent resourceView={resourceView} listeners={listeners} />
          )}
          {resourceView.type === ResourceType.video && (
            <DisplayVideoAsRowContent resourceView={resourceView} listeners={listeners} />
          )}
        </>
      ),
      [resourceView, listeners]
    );
    return (
      <TableRow ref={ref} {...attributes} sx={{...style}}>
        {rowContent}
      </TableRow>
    );
  }
);
