export const ApplicationsIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      width="24px"
      height="24px"
      {...props}
    >
      <path
        d="M352 96V0H152c-13.3 0-24 10.7-24 24v368c0 13.3 10.7 24 24 24h272c13.3 0 24-10.7 24-24V96h-96z"
        opacity={props.opacity || 0.4}
        fill={'inherit'}
      />
      <path
        fill={'inherit'}
        d="M96 392V96H24c-13.3 0-24 10.7-24 24v368c0 13.3 10.7 24 24 24h272c13.3 0 24-10.7 24-24v-40H152c-30.9 0-56-25.1-56-56zM441 73L375 7c-4.5-4.5-10.6-7-17-7h-6v96h96v-6.1c0-6.3-2.5-12.4-7-16.9z"
      />
      <circle fill={'inherit'} cx={288} cy={208} r={52} />
      <path
        fill={'inherit'}
        d="M324.4 286h-4.1c-20.7 8.7-44 8.7-64.8 0h-4.1c-30.1 0-54.5 21-54.5 46.8v15.6c0 8.6 8.1 15.6 18.2 15.6h145.6c10.1 0 18.2-7 18.2-15.6v-15.6c.1-25.8-24.3-46.8-54.5-46.8z"
      />
    </svg>
  );
};
