import {DatePicker} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LoadingButton from '@mui/lab/LoadingButton';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import {
  FrequencyType,
  JobFrequency,
  LastDayOfMonth,
  LastDayOfMonthText,
  WeeklyDayOfWeek,
} from '@ozark/common';
import {amPmToHh24, apPmPeriodType, hh24toAmPm} from '@ozark/functions/src/helpers/amPmConverter';
import {useState} from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: JobFrequency) => Promise<void>;
  existingFrequency?: JobFrequency;
}

const getInitialHour = (existingFrequency?: JobFrequency) => {
  if (!existingFrequency) {
    return '12';
  }
  const amPm = hh24toAmPm(existingFrequency.hour);
  return amPm.hour.toString().padStart(2, '0');
};

const getInitialPeriod = (existingFrequency?: JobFrequency): apPmPeriodType => {
  if (!existingFrequency) {
    return 'AM';
  }
  const amPm = hh24toAmPm(existingFrequency.hour);
  return amPm.amPmPeriod;
};

const getInitialSelectedDate = (existingFrequency?: JobFrequency) => {
  if (
    !existingFrequency ||
    existingFrequency.type !== FrequencyType.specific ||
    !existingFrequency.dates.length
  ) {
    return null;
  }
  return new Date(existingFrequency.dates[0]);
};

const getInitialSelectedWeekDays = (existingFrequency?: JobFrequency) => {
  if (
    !existingFrequency ||
    existingFrequency.type !== FrequencyType.weekly ||
    !existingFrequency.daysOfWeek.length
  ) {
    return [];
  }
  return existingFrequency.daysOfWeek;
};

const getInitialSelectedMonthDays = (existingFrequency?: JobFrequency) => {
  if (
    !existingFrequency ||
    existingFrequency.type !== FrequencyType.monthly ||
    !existingFrequency.daysOfMonth
  ) {
    return [];
  }
  return existingFrequency.daysOfMonth;
};

export const ScheduleJobDetailedDialog = ({open, onClose, onSubmit, existingFrequency}: Props) => {
  const [scheduleType, setScheduleType] = useState(
    existingFrequency?.type?.toString() ?? FrequencyType.daily.toString()
  );
  const [hour, setHour] = useState(getInitialHour(existingFrequency));
  const [period, setPeriod] = useState(getInitialPeriod(existingFrequency));
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    getInitialSelectedDate(existingFrequency)
  );
  const [selectedWeekDays, setSelectedWeekDays] = useState<WeeklyDayOfWeek[]>(
    getInitialSelectedWeekDays(existingFrequency)
  );
  const [selectedMonthDays, setSelectedMonthDays] = useState<number[]>(
    getInitialSelectedMonthDays(existingFrequency)
  );
  const [loading, setLoading] = useState(false);

  const hours = Array.from({length: 12}, (_, i) => (i + 1).toString().padStart(2, '0'));
  const monthDays = Array.from({length: LastDayOfMonth}, (_, i) => i + 1);
  const weekDays: {value: WeeklyDayOfWeek; label: string}[] = [
    {value: 0, label: 'Sun'},
    {value: 1, label: 'Mon'},
    {value: 2, label: 'Tue'},
    {value: 3, label: 'Wed'},
    {value: 4, label: 'Thu'},
    {value: 5, label: 'Fri'},
    {value: 6, label: 'Sat'},
  ];

  const handleWeekDayToggle = (day: WeeklyDayOfWeek) => {
    setSelectedWeekDays(prev =>
      prev.includes(day) ? prev.filter(d => d !== day) : [...prev, day]
    );
  };

  const handleMonthDayToggle = (day: number) => {
    setSelectedMonthDays(prev =>
      prev.includes(day) ? prev.filter(d => d !== day) : [...prev, day]
    );
  };

  const handleSubmit = async () => {
    let jobFrequency: JobFrequency | undefined;
    const hour24 = amPmToHh24({
      hour: +hour,
      amPmPeriod: period,
    });
    if (scheduleType === FrequencyType.daily) {
      jobFrequency = {
        type: scheduleType,
        hour: hour24,
      };
    }
    if (scheduleType === FrequencyType.weekly) {
      jobFrequency = {
        type: scheduleType,
        hour: hour24,
        daysOfWeek: selectedWeekDays.sort((x, y) => x - y),
      };
    }
    if (scheduleType === FrequencyType.monthly) {
      jobFrequency = {
        type: scheduleType,
        hour: hour24,
        daysOfMonth: selectedMonthDays.sort((x, y) => x - y),
      };
    }
    if (scheduleType === FrequencyType.specific) {
      jobFrequency = {
        type: scheduleType,
        hour: hour24,
        dates: [selectedDate!.toString()],
      };
    }
    if (jobFrequency) {
      try {
        setLoading(true);
        await onSubmit(jobFrequency);
        onClose();
      } finally {
        setLoading(false);
      }
    }
  };

  const renderTimeSelection = () => (
    <Stack direction="row" spacing={2} sx={{mt: 2}}>
      <FormControl size="small">
        <Select value={hour} onChange={e => setHour(e.target.value)} sx={{minWidth: 80}}>
          {hours.map(h => (
            <MenuItem key={h} value={h}>
              {h}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl size="small">
        <Select
          value={period}
          onChange={e => setPeriod(e.target.value as apPmPeriodType)}
          sx={{minWidth: 80}}
        >
          <MenuItem value="AM">AM</MenuItem>
          <MenuItem value="PM">PM</MenuItem>
        </Select>
      </FormControl>
    </Stack>
  );

  const renderWeekDaySelection = () => (
    <FormControl sx={{mt: 2}}>
      <FormLabel>Select Days of Week</FormLabel>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          mt: 1,
          maxHeight: '200px',
          overflowY: 'auto',
        }}
      >
        {weekDays.map(day => (
          <Chip
            key={day.value}
            label={day.label}
            onClick={() => handleWeekDayToggle(day.value)}
            color={selectedWeekDays.includes(day.value) ? 'primary' : 'default'}
            variant={selectedWeekDays.includes(day.value) ? 'filled' : 'outlined'}
            sx={{width: '60px'}}
          />
        ))}
      </Box>
    </FormControl>
  );

  const renderMonthDaySelection = () => (
    <FormControl sx={{mt: 2}}>
      <FormLabel>Select Days of Month</FormLabel>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          mt: 1,
          maxHeight: '200px',
          overflowY: 'auto',
        }}
      >
        {monthDays.map(day => (
          <Chip
            key={day}
            label={day === LastDayOfMonth ? LastDayOfMonthText : day}
            onClick={() => handleMonthDayToggle(day)}
            color={selectedMonthDays.includes(day) ? 'primary' : 'default'}
            variant={selectedMonthDays.includes(day) ? 'filled' : 'outlined'}
            sx={{width: day === LastDayOfMonth ? '170px' : '45px'}}
          />
        ))}
      </Box>
    </FormControl>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Schedule Settings</DialogTitle>
      <DialogContent>
        <FormControl>
          <RadioGroup value={scheduleType} onChange={e => setScheduleType(e.target.value)}>
            <FormControlLabel value={FrequencyType.daily} control={<Radio />} label="Daily" />
            <FormControlLabel value={FrequencyType.weekly} control={<Radio />} label="Weekly" />
            <FormControlLabel value={FrequencyType.monthly} control={<Radio />} label="Monthly" />
            <FormControlLabel
              value={FrequencyType.specific}
              control={<Radio />}
              label="Specific Date"
            />
          </RadioGroup>

          {scheduleType === FrequencyType.specific && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={selectedDate}
                onChange={date => setSelectedDate(date)}
                inputFormat="MM/dd/yyyy"
                renderInput={params => (
                  <TextField {...params} id="value" name="value" margin="normal" fullWidth />
                )}
              />
            </LocalizationProvider>
          )}

          {scheduleType === FrequencyType.weekly && renderWeekDaySelection()}
          {scheduleType === FrequencyType.monthly && renderMonthDaySelection()}

          {renderTimeSelection()}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <LoadingButton
          onClick={handleSubmit}
          loading={loading}
          variant="contained"
          disabled={
            (scheduleType === FrequencyType.weekly && selectedWeekDays.length === 0) ||
            (scheduleType === FrequencyType.monthly && selectedMonthDays.length === 0) ||
            (scheduleType === FrequencyType.specific && !selectedDate)
          }
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
