import {yupResolver} from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Loading} from '@ozark/common/components';
import Background from '@ozark/common/static/images/background.png';
import {MFA_AUTH} from '@ozark/crm/src/constants/routes';
import {Fragment, useMemo, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {Redirect, useHistory} from 'react-router';
import {useLocation} from 'react-router-dom';
import * as yup from 'yup';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';
import {useAuthSiteJump} from './hooks';

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(8).required(),
});

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    margin: theme.spacing(2),
    width: 300,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  background: {
    height: '100vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type FormData = {email: string; password: string};

const Login = () => {
  const history = useHistory();
  const {mfaLogin, login, isAuthenticated, group} = useStore();

  const classes = useStyles();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    formState: {errors},
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });
  const {isSiteJumpTokenProcessing} = useAuthSiteJump(setError);

  const location = useLocation();
  const returnTo = useMemo(() => {
    const query = new URLSearchParams(location.search ?? '');
    return query.get('returnTo');
  }, [location.search]);

  const handleLogin: SubmitHandler<FormData> = async data => {
    setLoading(true);
    try {
      if (group.data?.applicationSettings?.enableMFA) {
        const result = await mfaLogin(data.email, data.password);
        history.push(MFA_AUTH, {
          email: result.email,
          phone: result.phone,
          token: result.token,
          returnTo: returnTo,
        });
      } else {
        await login(data.email, data.password);
      }
    } catch (e) {
      setError('There was an error logging in.');
      setLoading(false);
    }
  };

  if (isSiteJumpTokenProcessing) {
    return <Loading />;
  }

  if (isAuthenticated) {
    return <Redirect to={returnTo ?? ROUTES.DASHBOARD} />;
  }

  return (
    <div
      className={classes.background}
      style={{backgroundImage: `url(${group.data?.backgroundUrl || Background})`}}
    >
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Paper className={classes.paper}>
          <img className={classes.logo} src={group.data?.logoUrl} alt="Sign in" />
          <form className={classes.form} noValidate onSubmit={handleSubmit(handleLogin)}>
            <TextField
              {...register('email')}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              data-test="email"
              autoComplete="email"
              error={Boolean(error) || Boolean(errors.email)}
              helperText={error || errors.email?.message}
              autoFocus
            />
            <TextField
              {...register('password')}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              data-test="password"
              autoComplete="current-password"
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
            />
            <Button
              type="submit"
              data-test="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {loading ? <CircularProgress size={24} /> : <Fragment>Sign In</Fragment>}
            </Button>
            <Grid container justifyContent="space-between">
              <Grid item xs>
                <Link href={ROUTES.FORGOT_PASSWORD} variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item xs>
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
                  <Grid item>
                    <Link href={ROUTES.MERCHANT_REGISTRATION} variant="body2">
                      Sign Up
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
          <Box mt={8}>
            <Typography variant="body2" color="textSecondary" align="center">
              {'Copyright © '}
              <Link
                color="inherit"
                href={`${window.location.protocol}//${group.data?.portalDomain}`}
              >
                {group.data?.name}
              </Link>{' '}
              {new Date().getFullYear()}
              {'.'}
            </Typography>
          </Box>
        </Paper>
      </Container>
    </div>
  );
};

export default Login;
