import {AssociationType, TicketAssociation} from '../../../..';
import {useUserInfo} from '../../../../hooks';
import {InputAssociationError} from '../../types';
import {AssociationEditorType} from './AssociationEditorType';
import {AssociationEditorValueApplication} from './AssociationEditorValueApplication';
import {AssociationEditorValueErpUser} from './AssociationEditorValueErpUser';
import {AssociationEditorValueGroupAgent} from './AssociationEditorValueGroupAgent';
import {AssociationEditorValueMerchant} from './AssociationEditorValueMerchant';
import {AssociationEditorValueMid} from './AssociationEditorValueMid';

interface Props {
  draft?: TicketAssociation;
  setDraft: (draft: TicketAssociation) => void;
  errors: Record<InputAssociationError, string>;
  setErrors: (errors: Record<InputAssociationError, string>) => void;
}

export const AssociationEditorContent = ({draft, setDraft, errors, setErrors}: Props) => {
  const {isMerchant, isErpUser} = useUserInfo();
  return (
    <>
      <AssociationEditorType
        draft={draft}
        setDraft={setDraft}
        errors={errors}
        setErrors={setErrors}
      />
      {draft?.type === AssociationType.erpUser && isErpUser && (
        <AssociationEditorValueErpUser
          draft={draft}
          setDraft={setDraft}
          errors={errors}
          setErrors={setErrors}
        />
      )}
      {draft?.type === AssociationType.agent && (
        <AssociationEditorValueGroupAgent
          draft={draft}
          setDraft={setDraft}
          errors={errors}
          setErrors={setErrors}
        />
      )}
      {draft?.type === AssociationType.merchant && (
        <AssociationEditorValueMerchant
          draft={draft}
          setDraft={setDraft}
          errors={errors}
          setErrors={setErrors}
        />
      )}
      {draft?.type === AssociationType.application && !isMerchant && (
        <AssociationEditorValueApplication
          draft={draft}
          setDraft={setDraft}
          errors={errors}
          setErrors={setErrors}
        />
      )}
      {draft?.type === AssociationType.mid && isMerchant && (
        <AssociationEditorValueMid
          draft={draft}
          setDraft={setDraft}
          errors={errors}
          setErrors={setErrors}
        />
      )}
    </>
  );
};
