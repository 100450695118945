import {Button, ButtonProps, Typography} from '@mui/material';
import {InputAccept, useNotification} from '@ozark/common';
import {ConfirmationDialog} from '@ozark/common/components';
import {getFileExt, readFileAsDataUrl} from '@ozark/functions/src/functions/utils/FileUtil';
import React, {Fragment, useEffect, useState} from 'react';

export interface UploadButtonProps {
  name: string;
  id: string;
  setInputRef?: (inputRef: HTMLInputElement) => void;
  onSelectFile(_: {dataUrl: string; file: File}): void;
  verifyFileSize?: {confirmationText: string; maxFileSize: number; allowContinue?: boolean};
  accept?: InputAccept;
  transformFile?(file: File, dataUrl: string): Promise<string>;
  errors: any;
  children?: React.ReactNode;
  acceptedExtensions?: string[];
}

export const DefaultConfirmationText = `The file you have selected is larger than expected and may cause delayed load times. We recommend reducing the size of this file before uploading. Are you sure you want to continue?`;

export const UploadButton = ({
  name,
  accept,
  id,
  setInputRef,
  transformFile,
  onSelectFile,
  verifyFileSize = {
    confirmationText: DefaultConfirmationText,
    maxFileSize: 10000,
    allowContinue: true,
  },
  acceptedExtensions,
  errors,
  children,
  disabled,
  ...buttonProps
}: ButtonProps & UploadButtonProps) => {
  const inputRef = React.createRef<HTMLInputElement>();

  const [message, setMessage] = useState<string>('');
  const [resolveAction, setResolveAction] = useState<(() => any | Promise<void>) | null>(null);
  const showNotification = useNotification();

  useEffect(() => {
    if (!inputRef.current) return;
    setInputRef?.(inputRef.current);
  }, [inputRef.current]);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files) return;
    const file = event.target.files[0];
    if (!file) return;

    const ext = getFileExt(file);
    if (
      (ext && acceptedExtensions && !acceptedExtensions.includes(ext)) ||
      (!ext && acceptedExtensions)
    ) {
      showNotification('error', 'File type not supported');
      return;
    }

    if (verifyFileSize && file.size > verifyFileSize.maxFileSize) {
      setMessage(verifyFileSize.confirmationText);
      setResolveAction(() =>
        verifyFileSize.allowContinue ? () => handleOnSelectFile(file) : () => {}
      );
    } else {
      handleOnSelectFile(file);
    }
  };

  const handleOnSelectFile = async (file: any): Promise<void> => {
    let dataUrl = await readFileAsDataUrl(file);
    dataUrl = (await transformFile?.(file, dataUrl)) || dataUrl;
    onSelectFile({dataUrl, file});
  };

  return (
    <Fragment>
      <input
        ref={inputRef}
        accept={accept}
        style={{display: 'none'}}
        id={id}
        type="file"
        onChange={handleChange}
        disabled={disabled}
      />
      <label htmlFor={id}>
        <Button component={'span'} {...buttonProps}>
          {children}
        </Button>
      </label>
      {Boolean(errors[name]) && (
        <Typography
          style={{display: 'block', paddingLeft: 16, marginTop: 4, color: 'red'}}
          variant="caption"
          gutterBottom
        >
          {errors[name]?.message}
        </Typography>
      )}
      <ConfirmationDialog
        title="Confirmation"
        message={message}
        onClose={() => setResolveAction(null)}
        onConfirm={resolveAction}
      />
    </Fragment>
  );
};
