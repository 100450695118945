import {useEffect, useRef} from 'react';

export const useDebouncedCallback = <A extends unknown[]>(
  callback: (...args: A) => void,
  delayMs: number
) => {
  let argsRef: A;

  const timeout = useRef<ReturnType<typeof setTimeout>>();

  const cleanup = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  };

  useEffect(() => cleanup, []);

  return (...args: A) => {
    argsRef = args;

    cleanup();

    timeout.current = setTimeout(() => {
      if (argsRef) {
        callback(...argsRef);
      }
    }, delayMs);
  };
};
