export enum DispositionStatisticFields {
  agent = 'agent',
  count = 'count',
  countOfTotal = 'countOfTotal',
  volume = 'volume',
  volumeOfTotal = 'volumeOfTotal',
  asReceivedCount = 'asReceivedCount',
  uwReceivedCount = 'uwReceivedCount',
  boardedCount = 'boardedCount',
  uwCApprovedCount = 'uwCApprovedCount',
  uwPendingCount = 'uwPendingCount',
  uwDeclinedCount = 'uwDeclinedCount',
  uwWithdrawnCount = 'uwWithdrawnCount',
}

export type DispositionStatistic = {
  agent: string;
  count: number;
  countOfTotal: number;
  volume: number;
  volumeOfTotal: number;
  asReceivedCount: number;
  uwReceivedCount: number;
  boardedCount: number;
  uwCApprovedCount: number;
  uwPendingCount: number;
  uwDeclinedCount: number;
  uwWithdrawnCount: number;
};
