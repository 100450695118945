import React, {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {createContainer} from 'unstated-next';
import {Collections, Ticket} from '../../..';
import {EditorType} from '../types';

function useTicketEdit() {
  const [isLoading, setIsLoading] = useState(true);
  const [openEditors, setOpenEditors] = useState<Set<EditorType>>(new Set<EditorType>());
  const [commentsInternal, setCommentsInternal] = useState(false);
  const commentsCollection = commentsInternal ? Collections.commentsInternal : Collections.comments;
  const [isTicketsDupesTab, setIsTicketsDupesTab] = useState(false);

  return {
    isLoading,
    setIsLoading,
    commentsInternal,
    setCommentsInternal,
    openEditors,
    setOpenEditors,
    commentsCollection,
    isTicketsDupesTab,
    setIsTicketsDupesTab,
  };
}

const TicketEditContainer = createContainer(useTicketEdit);
export const useTicketEditContainer = TicketEditContainer.useContainer;

export const TicketEditContainerProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const methods = useForm<Ticket>({
    defaultValues: defaultTicketValues,
    shouldUnregister: true,
  });
  return (
    <TicketEditContainer.Provider>
      <FormProvider {...methods}>{children}</FormProvider>
    </TicketEditContainer.Provider>
  );
};

const defaultTicketValues: Ticket = {
  subject: '',
  description: '',
  author: undefined,
  assignee: undefined,
  status: undefined,
};
