import {SearchOptions} from '@algolia/client-search';
import {SalesDisposition} from '@ozark/functions/src/constants';
import {IndexableLead} from '@ozark/functions/src/documents';
import algoliasearch, {SearchClient} from 'algoliasearch';
import {useCallback, useEffect, useRef, useState} from 'react';
import {DEFAULT_SEARCH_OPTIONS} from '../constants/search';
import {useCallable} from './useCallable';

const useLeadSearch = (options?: Partial<SearchOptions>) => {
  const [isReady, setIsReady] = useState(false);

  const algoliaClientRef = useRef<SearchClient | null>(null);

  const {getAlgoliaSearchKey} = useCallable();

  useEffect(() => {
    if (algoliaClientRef.current) {
      return;
    }

    getAlgoliaSearchKey('leads').then(result => {
      if (result.status !== 'ok' || !result?.key) {
        return;
      }

      algoliaClientRef.current = algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID as string,
        result.key
      );

      setIsReady(true);
    });
  }, []);

  const search = useCallback(async (query: string) => {
    if (!query || !algoliaClientRef.current || !process.env.REACT_APP_ALGOLIA_INDEX_LEADS) {
      return null;
    }

    try {
      const algoliaIndex = algoliaClientRef.current.initIndex(
        process.env.REACT_APP_ALGOLIA_INDEX_LEADS
      );

      return algoliaIndex.search<IndexableLead>(query, {
        ...DEFAULT_SEARCH_OPTIONS,
        filters: Object.values(SalesDisposition)
          .map(salesDisposition => `salesDisposition:"${salesDisposition}"`)
          .join(' OR '),
        ...options,
      });
    } catch (error) {
      console.error('Lead search error', error);

      return null;
    }
  }, []);

  return {isReady, search} as const;
};

export {useLeadSearch};
