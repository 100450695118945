export const splitAt = (value: string, index: number) => [
  value.slice(0, index),
  value.slice(index),
];

export const luhnGenerator = (input: string, pad?: number) => {
  const checksum = (input: string) => {
    let sum = 0;
    let parity = 2;

    for (let i = input.length - 1; i >= 0; i--) {
      const digit = Math.max(parity, 1) * Number(input[i]);

      sum +=
        digit > 9
          ? digit
              .toString()
              .split('')
              .map(Number)
              .reduce((a, b) => a + b, 0)
          : digit;
      parity *= -1;
    }

    sum %= 10;

    return sum > 0 ? 10 - sum : 0;
  };

  // option pad
  if (pad && pad > input.length) {
    input = Array(pad - input.length).join('0') + input;
  }

  return input + checksum(input);
};

export const truncate = (text?: string, truncateLength: number = 15) =>
  text ? text.substr(0, truncateLength - 1) + (text.length > truncateLength ? '...' : '') : text;

export function removeHtml(text?: string) {
  if (!text) return text;
  const regex = /(<([^>]+)>)/gi;
  const result = text.replace(regex, '');
  return result;
}
