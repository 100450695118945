import {Box, Divider, Stack} from '@mui/material';
import {
  AgentChargebacks,
  AgentChargebacksRecord,
} from '@ozark/functions/src/functions/express/private/types/AgentStatistics';
import {add, endOfDay, startOfDay} from 'date-fns';
import {isEmpty, orderBy} from 'lodash';
import {useEffect, useState} from 'react';
import {
  BoxParentHeight,
  currentFormatter,
  Filter,
  FilterOption,
  forceActiveFilter,
  InfoLoading,
  InfoNoData,
  MonthYearPicker,
  Square,
  Table,
} from '../..';
import {
  AgentView,
  formatAsPercent,
  formatterNumber,
  SearchCriteria,
  useApiContainer,
} from '../../..';
import {Column} from '../../../api/Column';
import {useAgents} from '../../../hooks/useAgents';
import {useQueryMonthYear} from '../../Analytics/common/useQueryMonthYear';
import {AgentSelect} from '../../Filters/AgentSelect';

export const AgentStatisticsCb = () => {
  const api = useApiContainer();
  const [selectedAgent, setSelectedAgent] = useState<AgentView>();
  const {agents} = useAgents();
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState<AgentChargebacks | null>();

  const {year, month} = useQueryMonthYear();
  const [filters, setFilters] = useState<Filter>({}); // defines date scope
  const [pageConfig, setPageConfig] = useState<SearchCriteria>({
    order: 'asc',
    orderBy: 'agentName',
    offset: 1,
    limit: 100,
  });
  const hasData = Boolean(reportData?.agentChargebacks.length);

  useEffect(() => {
    if (isEmpty(filters)) return;

    let isMounted: boolean = true;

    const loadReportData = async (): Promise<void> => {
      try {
        const result = await api?.agentStatistics.getAgentsStatisticsChargebacks(
          pageConfig,
          Object.values(filters),
          selectedAgent?.id
        );
        const sortedReportData = applySorting(result, pageConfig);

        if (isMounted) {
          setReportData(sortedReportData ?? undefined);
        }
      } catch (error) {
        console.error('Failed at loading Agent chargebacks statistics', error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    setLoading(true);
    loadReportData();

    return () => {
      isMounted = false;
    };
  }, [filters, selectedAgent?.id]);

  useEffect(() => {
    const startingDate = startOfDay(new Date(year, month - 1, 1));
    const dateResolved = forceActiveFilter(AgentCBFilters, 'dateResolved', '__between', [
      startingDate,
      endOfDay(add(startingDate, {months: 1})),
    ]);
    setFilters(previous => ({...previous, dateResolved}));
  }, [year, month]);

  const onRetrieveData = (newPageConfig: SearchCriteria) => {
    setPageConfig(newPageConfig);
    const sortedReportData = applySorting(reportData, newPageConfig);

    setReportData(sortedReportData);
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <Box flex={1} />
        <AgentSelect
          agents={agents}
          onAgentSelect={setSelectedAgent}
          selectedAgent={selectedAgent}
        />
        <Divider orientation="vertical" flexItem light sx={{mx: 2}} />
        <MonthYearPicker />
      </Box>
      {loading && <InfoLoading />}
      {!loading && !hasData && <InfoNoData />}
      {!loading && hasData && (
        <>
          <Stack mt={2} direction="row" spacing={2}>
            <Box flex={1}>
              <Square
                lines={{
                  'Chargebacks Count': formatterNumber.format(
                    Number(reportData?.totalChargebacksCount ?? 0)
                  ),
                }}
                center
              />
            </Box>
            <Box flex={1}>
              <Square
                lines={{
                  'Chargebacks Volume': currentFormatter.format(
                    Number(reportData?.totalChargebacksVolume ?? 0)
                  ),
                }}
                center
              />
            </Box>
          </Stack>
          <BoxParentHeight my={2}>
            <Table
              columns={columns}
              data={{
                sort: [[pageConfig.orderBy, pageConfig.order as 'DESC' | 'ASC']],
                limit: pageConfig.limit,
                offset: pageConfig.offset,
                totalCount: reportData?.agentChargebacks.length || 0,
                data: reportData?.agentChargebacks || [],
              }}
              onRetrieveData={onRetrieveData}
              stickyHeader
            />
          </BoxParentHeight>
        </>
      )}
    </>
  );
};

const applySorting = (
  reportData: AgentChargebacks | null | undefined,
  searchCriteria: SearchCriteria
): AgentChargebacks | null | undefined => {
  if (!reportData) {
    return reportData;
  }

  const sortedAgentChargebacks = orderBy(
    reportData?.agentChargebacks,
    [searchCriteria.orderBy],
    [searchCriteria.order.toLowerCase() as 'desc' | 'asc']
  );

  return {
    ...reportData,
    agentChargebacks: sortedAgentChargebacks,
  };
};

const columns: Column<AgentChargebacksRecord>[] = [
  {
    id: 'agentName',
    label: 'Agent Name',
    sortable: true,
  },
  {
    id: 'visaPortfolioChargebackCountRatio',
    label: 'Visa CB Count / % of Total Portfolio CB',
    sortable: true,
    numeric: true,
    selector: row => formatAsPercent(row.visaPortfolioChargebackCountRatio),
  },
  {
    id: 'visaChargebackRatio',
    label: 'Visa CB Ratio By Volume',
    sortable: true,
    numeric: true,
    selector: row => formatAsPercent(row.visaChargebackRatio),
  },
  {
    id: 'mcPortfolioChargebackCountRatio',
    label: 'MC CB Count / % of Total Portfolio CB',
    sortable: true,
    numeric: true,
    selector: row => formatAsPercent(row.mcPortfolioChargebackCountRatio),
  },
  {
    id: 'mcChargebackRatio',
    label: 'MC CB Ratio By Volume',
    sortable: true,
    numeric: true,
    selector: row => formatAsPercent(row.mcChargebackRatio),
  },
];

export const AgentCBFilters: FilterOption[] = [
  {
    id: 'dateResolved',
    column: 'dateResolved',
    label: 'Date Range',
    type: 'dateRange',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
];
