import {Box, Divider, TableRowProps} from '@mui/material';
import {orange, red} from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import {PaginatedResponse, SearchCriteria, useMidsContainer} from '@ozark/common';
import {Column} from '@ozark/common/api/Column';
import {
  BoxFlexed,
  BoxParentHeight,
  ButtonExportCsv,
  ExportProps,
  Filter,
  FilterOption,
  MERCHANT_PORTFOLIO_DETAILS,
  MidSelect,
  MonthYearPicker,
  Table,
} from '@ozark/common/components';
import {
  VDMPChargebackReport,
  VDMPChargebackReportFields,
} from '@ozark/functions/src/functions/express/private/types/Reports';
import {flatten} from '@s-libs/micro-dash';
import React, {useMemo} from 'react';
import {generatePath, Link} from 'react-router-dom';
import {LoadingStatus} from '../../Analytics/common/LoadingStatus';
import {FiltersAddButton} from '../../Filters/FiltersAddButton';
import {FiltersApplied} from '../../Filters/FiltersApplied';
import {
  CBWarningType,
  getHighlightedReportRowData,
  VDMPChargebackFormattedReport,
} from './VDMPReportWarningUtils';
import {VDMPTableValue} from './VDMPTableValue';

type VDMPReportProps = {
  exportProps?: Partial<ExportProps>;
  filters: Filter;
  report: PaginatedResponse<VDMPChargebackReport> | null;
  searchCriteria: SearchCriteria;
  setFilters: React.Dispatch<React.SetStateAction<Filter>>;
  setSearchCriteria: React.Dispatch<React.SetStateAction<SearchCriteria>>;
  loading: boolean;
};

const percentValueFormatter = (value: number | null): string | number => {
  if (!value) {
    return '-';
  }

  return value.toLocaleString('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
  });
};

const useStyles = makeStyles(theme => ({
  columnGroupLeft: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export const VDMPReport = ({
  filters,
  report,
  searchCriteria,
  setFilters,
  setSearchCriteria,
  loading,
  exportProps = {},
}: VDMPReportProps) => {
  const classes = useStyles();
  const {selectedMid, handleSelectMid, mids} = useMidsContainer();

  const handleRetrieveData = (searchCriteria: SearchCriteria) => {
    setSearchCriteria(searchCriteria);
  };

  const onMidSelect = (mid: string) => {
    if (mid !== selectedMid) {
      handleSelectMid(mid);
      setSearchCriteria({...searchCriteria, offset: 1});
    }
  };

  const getRowProps = (row: VDMPChargebackFormattedReport): Partial<TableRowProps> => {
    const getColorStyles = (color: string) => ({
      color,
      '& td': {
        color,
      },
    });

    if (row.rowWarning.indexOf(CBWarningType.standard) !== -1) {
      return {
        sx: getColorStyles(red[600]),
      };
    }

    if (row.rowWarning.indexOf(CBWarningType.early) !== -1) {
      return {
        sx: getColorStyles(orange[600]),
      };
    }

    return {};
  };

  const formattedReport = useMemo(() => {
    if (!report) {
      return report;
    }

    return {
      ...report,
      data: report.data.map(getHighlightedReportRowData),
    };
  }, [report]);

  const hasData = Boolean(formattedReport && formattedReport.data.length);
  const hasFewRanges = report?.data.length && report.data[0].chargebacks.length > 1;

  const chargebackColumns = (): Column<VDMPChargebackFormattedReport>[] => {
    const chargebackCols = [...TABLE_COLUMNS_CB];
    chargebackCols[chargebackCols.length - 1].className = hasFewRanges
      ? classes.columnGroupLeft
      : undefined;
    const filterIds = Object.keys(filters).sort();
    const columns = report?.data[0].chargebacks.map((_cb, index) => {
      return chargebackCols.map(column => {
        const cbColumn = {...column} as Column<VDMPChargebackFormattedReport>;
        const shortLbl = hasFewRanges
          ? filters[filterIds[index]]!.option.label.replace('Date Range ', '')
          : '';
        cbColumn.id = `${cbColumn.id}${index}`;
        cbColumn.label = hasFewRanges ? `${cbColumn.label} ${shortLbl}` : cbColumn.label;
        return cbColumn;
      });
    });

    return columns !== undefined ? flatten<Column<VDMPChargebackFormattedReport>>(columns) : [];
  };

  const tableColumns = useMemo(() => {
    const headCols = [...TABLE_COLUMNS_HEAD];
    headCols[headCols.length - 1].className = hasFewRanges ? classes.columnGroupLeft : undefined;
    return [...headCols, ...chargebackColumns(), ...TABLE_COLUMNS_TAIL];
  }, [report]);

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: '1'}}>
      <BoxFlexed py={2} justifyContent="flex-end" alignItems="center">
        <FiltersApplied filters={filters} setFilters={setFilters} />
        <Box flex={1} />
        {!Object.keys(filters).length && <MonthYearPicker disabled={loading} />}
        <FiltersAddButton filters={filters} setFilters={setFilters} filtersConfig={filtersConfig} />
        <Divider orientation="vertical" flexItem sx={{mx: 2}} />
        <MidSelect
          selectedMid={selectedMid}
          handleSelectMid={onMidSelect}
          mids={mids}
          disabled={loading}
        />
        <Divider orientation="vertical" flexItem sx={{mx: 2}} />
        <ButtonExportCsv
          filename="vdmp-cb-report"
          rows={formattedReport?.data}
          columnsConfig={tableColumns}
          disabled={loading}
          {...exportProps}
        />
      </BoxFlexed>
      <LoadingStatus loading={loading} hasData={Boolean(hasData)} />
      {!loading && hasData && (
        <BoxParentHeight sx={{'& table': {backgroundColor: '#fff'}}}>
          <Table
            columns={tableColumns}
            data={formattedReport!}
            onRowClick={() => {}}
            onRetrieveData={handleRetrieveData}
            paginate
            getRowProps={getRowProps}
            stickyHeader
          />
        </BoxParentHeight>
      )}
    </Box>
  );
};

const warningTooltipTextVisa = {
  [CBWarningType.early]: 'VDMP Early Warning',
  [CBWarningType.standard]: 'VDMP Standard Warning',
  [CBWarningType.none]: '',
};
const warningTooltipTextMasterCard = {
  [CBWarningType.early]: 'MC Excessive CB Program Early Warning',
  [CBWarningType.standard]: 'MC Excessive CB Program Standard Warning',
  [CBWarningType.none]: '',
};

const getColIndex = (id: string, field: string): number => Number(id.substring(field.length));

const TABLE_COLUMNS_HEAD: Column<VDMPChargebackFormattedReport>[] = [
  {
    id: 'warning',
    numeric: false,
    sortable: false,
    label: '',
    width: 56,
    selector(report) {
      return (
        <VDMPTableValue
          value={null}
          warningType={report.rowWarning.find(w => w !== undefined) ?? null}
        />
      );
    },
  },
  {
    id: VDMPChargebackReportFields.mid,
    numeric: false,
    sortable: true,
    label: 'MID',
    export: true,
  },
  {
    id: VDMPChargebackReportFields.legalBusinessName,
    numeric: false,
    sortable: true,
    label: 'Legal Name',
    export: row => row[VDMPChargebackReportFields.legalBusinessName] ?? '',
  },
  {
    id: VDMPChargebackReportFields.doingBusinessAs,
    numeric: false,
    sortable: true,
    label: 'DBA',
    export: row => row[VDMPChargebackReportFields.doingBusinessAs] ?? '',
    selector: report => {
      const dba = report.doingBusinessAs;
      if (report.applicationId) {
        const route = generatePath(MERCHANT_PORTFOLIO_DETAILS, {id: report.applicationId});
        return <Link to={route}>{dba}</Link>;
      }
      return dba;
    },
  },
];

const TABLE_COLUMNS_CB: Column<VDMPChargebackFormattedReport>[] = [
  {
    id: VDMPChargebackReportFields.visaChargebacksRatioByCount,
    numeric: true,
    sortable: true,
    label: 'VS CB #',
    headingTooltip: 'VISA Chargeback Ratio by Count',
    width: 120,
    selector(report, id) {
      const index = getColIndex(id, VDMPChargebackReportFields.visaChargebacksRatioByCount);
      const {value, warning} = report.visaChargebacksRatioByCount[index];
      const formattedValue = percentValueFormatter(value);
      return (
        <VDMPTableValue
          value={formattedValue}
          warningType={warning}
          warning={warning ? warningTooltipTextVisa[warning] : undefined}
          info="Reporting Month Chargebacks Count / Reporting&nbsp;Month Sales Count"
        />
      );
    },
    export: (row, id) => {
      const index = getColIndex(id, VDMPChargebackReportFields.visaChargebacksRatioByCount);

      const {value} = row.visaChargebacksRatioByCount[index];

      return percentValueFormatter(value);
    },
  },
  {
    id: VDMPChargebackReportFields.visaChargebacksRatioByVolume,
    numeric: true,
    sortable: true,
    label: 'VS CB %',
    headingTooltip: 'VISA Chargeback Ratio by Volume',
    width: 120,
    selector(report, id) {
      const index = getColIndex(id, VDMPChargebackReportFields.visaChargebacksRatioByVolume);
      const {value, warning} = report.visaChargebacksRatioByVolume[index];
      const formattedValue = percentValueFormatter(value);
      return (
        <VDMPTableValue
          value={formattedValue}
          warningType={warning}
          warning={warning ? warningTooltipTextVisa[warning] : undefined}
          info="Reporting Month Chargebacks Volume / Reporting&nbsp;Month Sales Volume"
        />
      );
    },
    export: (row, id) => {
      const index = getColIndex(id, VDMPChargebackReportFields.visaChargebacksRatioByVolume);

      const {value} = row.visaChargebacksRatioByVolume[index];

      return percentValueFormatter(value);
    },
  },
  {
    id: VDMPChargebackReportFields.visaChargebacksCount,
    numeric: true,
    sortable: true,
    label: 'VS CB Count',
    headingTooltip: 'VISA Chargeback Count',
    width: 120,
    selector(report, id) {
      const index = getColIndex(id, VDMPChargebackReportFields.visaChargebacksCount);
      const {value, warning} = report.visaChargebacksCount[index];
      return (
        <VDMPTableValue
          value={value}
          warningType={warning}
          warning={warning ? warningTooltipTextVisa[warning] : undefined}
        />
      );
    },
    export: (row, id) => {
      const index = getColIndex(id, VDMPChargebackReportFields.visaChargebacksCount);
      return row.visaChargebacksCount[index].value ?? 0;
    },
  },
  {
    id: VDMPChargebackReportFields.mcChargebacksRatioByCount,
    numeric: true,
    sortable: true,
    label: 'MC CB #',
    headingTooltip: 'MC Chargeback Ratio by Count',
    width: 120,
    selector(report, id) {
      const index = getColIndex(id, VDMPChargebackReportFields.mcChargebacksRatioByCount);
      const {value, warning} = report.mcChargebacksRatioByCount[index];
      const formattedValue = percentValueFormatter(value);
      return (
        <VDMPTableValue
          value={formattedValue}
          warningType={warning}
          warning={warning ? warningTooltipTextMasterCard[warning] : undefined}
          info="Reporting Month Chargebacks Count / Previous&nbsp;Month Sales Count"
        />
      );
    },
    export: (row, id) => {
      const index = getColIndex(id, VDMPChargebackReportFields.mcChargebacksRatioByCount);

      const {value} = row.mcChargebacksRatioByCount[index];

      return percentValueFormatter(value);
    },
  },
  {
    id: VDMPChargebackReportFields.mcChargebacksRatioByVolume,
    numeric: true,
    sortable: true,
    label: 'MC CB %',
    headingTooltip: 'MC Chargeback Ratio by Volume',
    width: 120,
    selector(report, id) {
      const index = getColIndex(id, VDMPChargebackReportFields.mcChargebacksRatioByVolume);
      const {value, warning} = report.mcChargebacksRatioByVolume[index];
      const formattedValue = percentValueFormatter(value);
      return (
        <VDMPTableValue
          value={formattedValue}
          warningType={warning}
          warning={warning ? warningTooltipTextMasterCard[warning] : undefined}
          info="Reporting Month Chargebacks Volume / Previous&nbsp;Month Sales Volume"
        />
      );
    },
    export: (row, id) => {
      const index = getColIndex(id, VDMPChargebackReportFields.mcChargebacksRatioByVolume);

      const {value} = row.mcChargebacksRatioByVolume[index];

      return percentValueFormatter(value);
    },
  },
  {
    id: VDMPChargebackReportFields.mcChargebacksCount,
    numeric: true,
    sortable: true,
    label: 'MC CB Count',
    headingTooltip: 'MC Chargeback Count',
    width: 120,
    selector(report, id) {
      const index = getColIndex(id, VDMPChargebackReportFields.mcChargebacksCount);
      const {value, warning} = report.mcChargebacksCount[index];
      return (
        <VDMPTableValue
          value={value}
          warningType={warning}
          warning={warning ? warningTooltipTextMasterCard[warning] : undefined}
        />
      );
    },
    export: (row, id) => {
      const index = getColIndex(id, VDMPChargebackReportFields.mcChargebacksCount);
      return row.mcChargebacksCount[index].value ?? 0;
    },
  },
];

const TABLE_COLUMNS_TAIL: Column<VDMPChargebackFormattedReport>[] = [
  {
    id: VDMPChargebackReportFields.agent,
    numeric: false,
    sortable: true,
    label: 'Agent',
    export: row => row[VDMPChargebackReportFields.agent] ?? '',
  },
  {
    id: VDMPChargebackReportFields.mcc,
    numeric: false,
    sortable: true,
    label: 'MCC',
    export: true,
  },
];

const filtersConfig: FilterOption[] = [
  {
    id: 'dateRange1',
    column: 'dateRange1',
    autoSelect: false,
    label: 'Date Range 1',
    type: 'dateRange',
    dateFormat: 'MMM dd, yyyy',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'dateRange2',
    column: 'dateRange2',
    autoSelect: false,
    label: 'Date Range 2',
    type: 'dateRange',
    dateFormat: 'MMM dd, yyyy',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'dateRange3',
    column: 'dateRange3',
    autoSelect: false,
    label: 'Date Range 3',
    type: 'dateRange',
    dateFormat: 'MMM dd, yyyy',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
];
