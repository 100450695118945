import ImageIcon from '@mui/icons-material/Image';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
  Stack,
} from '@mui/material';
import firebase from 'firebase/compat/app';
import React, {useRef, useState} from 'react';
import Cropper from 'react-easy-crop';
import {Area} from 'react-easy-crop/types';
import {v4 as uuidv4} from 'uuid';
import {Firebase, rename, StoragePath} from '../..';

const targetContentType = 'image/jpeg';

export const UploadImageButton = ({
  onSuccess,
  onProgress,
  onError,
  children,
  disabled,
  ...props
}: any) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | undefined>();
  const MAX_IMAGE_SIZE = 400;

  const handleDialogClose = () => {
    setDialogOpen(false);
    onError();
  };

  const handleUpload = (image: File) => {
    const file = rename(image, uuidv4());
    const uploadTask = Firebase.storage
      .ref(`${StoragePath.profiles}/${file.name}`)
      .put(file, {contentType: targetContentType});
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot: any) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        onProgress?.(progress);
      },
      (err: any) => {
        console.error(err);
        onError?.(err);
      },
      () => {
        Firebase.storage
          .ref(StoragePath.profiles)
          .child(file.name)
          .getDownloadURL()
          .then(async url => {
            onSuccess?.(url);
          });
      }
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    onProgress?.(0);
    const image = event.target.files[0];
    setSelectedImage(image);
    setImageUrl(URL.createObjectURL(image));

    if (inputRef.current) {
      inputRef.current.files = null;
      inputRef.current.value = '';
    }

    setDialogOpen(true);
  };

  const handleCropComplete = async (croppedAreaPixels: Area | undefined) => {
    if (!selectedImage || !imageUrl || !croppedAreaPixels) {
      return;
    }

    const image = new Image();
    image.src = imageUrl;
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        return;
      }
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      const pixelCrop = {
        x: croppedAreaPixels.x * scaleX,
        y: croppedAreaPixels.y * scaleY,
        width: croppedAreaPixels.width * scaleX,
        height: croppedAreaPixels.height * scaleY,
      };

      let newWidth = pixelCrop.width;
      let newHeight = pixelCrop.height;

      if (pixelCrop.width > MAX_IMAGE_SIZE || pixelCrop.height > MAX_IMAGE_SIZE) {
        const ratio = pixelCrop.width / MAX_IMAGE_SIZE;
        newWidth = MAX_IMAGE_SIZE;
        newHeight = Math.round(pixelCrop.height / ratio);
      }

      canvas.width = newWidth;
      canvas.height = newHeight;

      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        newWidth,
        newHeight
      );

      canvas.toBlob(
        async blob => {
          if (blob) {
            const croppedImage = new File([blob], selectedImage.name);
            handleUpload(croppedImage);
          }
        },
        targetContentType,
        1
      );
    };
  };

  return (
    <>
      <>
        <input
          ref={inputRef}
          accept="image/*"
          style={{display: 'none'}}
          id="button-file"
          type="file"
          onChange={handleChange}
        />
        {!disabled && (
          <label htmlFor="button-file">
            <Button component={'span'} disabled={disabled} {...props}>
              {children}
            </Button>
          </label>
        )}
        {disabled && (
          <label>
            <Button component={'span'} disabled={disabled} {...props}>
              {children}
            </Button>
          </label>
        )}
      </>
      <div>
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Crop Profile Photo</DialogTitle>
          <DialogContent
            sx={{
              position: 'relative',
              height: '50vh',
              width: 'auto',
              minWidth: {sm: 500},
            }}
          >
            <Cropper
              image={imageUrl}
              crop={crop}
              zoom={zoom}
              zoomSpeed={1}
              showGrid
              aspect={1}
              cropShape="round"
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={(_croppedArea: Area, croppedAreaPixels: Area) =>
                setCroppedAreaPixels(croppedAreaPixels)
              }
            />
          </DialogContent>
          <DialogActions sx={{mt: 4, mb: 1, mx: 1, flexDirection: 'column'}}>
            <Box sx={{width: 500}}>
              <Stack spacing={2} direction="row" sx={{mb: 1}} alignItems="center">
                <ImageIcon fontSize="small" />
                <Slider
                  min={1}
                  max={3}
                  step={0.01}
                  value={zoom}
                  onChange={(_e, zoom) => setZoom(Number(zoom))}
                ></Slider>
                <ImageIcon fontSize="large" />
              </Stack>
            </Box>
            <Box sx={{mt: 4, mb: 1, mx: 1}}>
              <Button onClick={handleDialogClose}>Cancel</Button>
              <Button
                onClick={_e => {
                  handleCropComplete(croppedAreaPixels);
                  handleDialogClose();
                }}
              >
                Save
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
