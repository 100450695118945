import {yupResolver} from '@hookform/resolvers/yup';
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  Fade,
  Grid,
  Paper,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Firebase, StoragePath, UniversalTimestamp, useCallable} from '@ozark/common';
import {useGroup} from '@ozark/common/';
import {Loading} from '@ozark/common/components';
import PhoneNumber from 'awesome-phonenumber';
import firebase from 'firebase/compat/app';
import {ChangeEvent, Fragment, useEffect, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useAgents, useNotification} from '../../hooks';
import {GroupForm, GroupFormData, GroupFormSchema, groupViewToFormData} from './GroupForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolBar: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      padding: theme.spacing(2, 2),
    },
    logo: {
      height: 48,
    },
    actions: {},
    content: {
      marginTop: theme.spacing(0),
      padding: theme.spacing(1, 2, 2),
      position: 'relative',
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    saveButton: {
      width: 150,
    },
    inactiveBackdrop: {
      position: 'absolute',
      top: 0,
      height: 'calc(100% + 2px)',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.5)',
      color: 'rgba(255,255,255,0.6)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 2,
    },
    media: {
      position: 'relative',
      height: 0,
      paddingTop: '56.25%', // 16:9
      borderTop: 'solid 1px rgba(0,0,0,0.1)',
      borderBottom: 'solid 1px rgba(0,0,0,0.1)',
    },
  })
);

export const GroupEdit = ({
  groupId,
  isAuthUserAdmin,
}: {
  groupId: string;
  isAuthUserAdmin: boolean;
}) => {
  const classes = useStyles();
  const {document: group, set} = useGroup(groupId);
  const {documents: agents} = useAgents(groupId);
  const [loading, setLoading] = useState(false);
  const [formGroup, setFormGroup] = useState(group.data);
  const showNotification = useNotification();
  const {toggleGroupActive} = useCallable();

  const hookForm = useForm<GroupFormData>({
    resolver: yupResolver(GroupFormSchema),
  });

  const {formState, register, reset, handleSubmit} = hookForm;

  const {isDirty} = formState;

  register('logoUrl');
  register('termsUrl');
  register('backgroundUrl');

  const handleToggle = async (_event: ChangeEvent<HTMLInputElement>, _isChecked: boolean) => {
    if (!group?.data) return;
    await toggleGroupActive({uid: groupId});
  };

  useEffect(() => {
    if (group.promised || formGroup) return;

    setFormGroup(group.data);
    //@ts-ignore
    reset(groupViewToFormData(group.data));
  }, [group.promised, group.data, formGroup, reset]);

  const upload = (path: string, file: File) => {
    return new Promise<string>((resolve, reject) => {
      const task = Firebase.storage.ref(`${path}/${file.name}`).put(file);
      task.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot: any) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          console.log(`uploading: ${progress}`);
        },
        (err: any) => {
          console.error(err);
          reject(err);
        },
        () => {
          Firebase.storage
            .ref(path)
            .child(file.name)
            .getDownloadURL()
            .then(url => {
              resolve(url);
            });
        }
      );
    });
  };

  const onSuccess: SubmitHandler<GroupFormData> = async data => {
    try {
      setLoading(true);
      if (data.image) {
        data.logoUrl = await upload(StoragePath.groups, data.image.file);
        delete data.image;
      }
      if (data.background) {
        data.backgroundUrl = await upload(StoragePath.groups, data.background.file);
        delete data.background;
      }
      data.termsUrl = data.termsUrl?.trim();

      await set({
        ...data,
        ...(data.logoUrl && {
          logoUpdatedAt: Firebase.FieldValue.now() as unknown as UniversalTimestamp,
        }),
      });
      data.phone = new PhoneNumber(data.phone, 'US').getNumber('national');
      if (group.data) {
        reset({...groupViewToFormData(group.data), ...data});
      } else {
        reset({...data});
      }
      showNotification('success', 'Group successfully updated.');
    } catch (err) {
      showNotification('error', 'Failed to Save Changes.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  if (group.promised) return <Loading />;

  return (
    <div>
      <Paper>
        {!isAuthUserAdmin && (
          <Alert severity="info">
            You currently have <b>read only</b> access to this page.
          </Alert>
        )}
        <Toolbar className={classes.toolBar}>
          <img className={classes.logo} src={group.data && group.data.logoUrl} alt="Logo" />
          <div className={classes.actions}>
            <Typography variant="subtitle1">
              <Typography variant="subtitle2" component="span">
                Group Status &nbsp;
              </Typography>
              {group.data?.isActive ? 'Active' : 'Inactive'}

              <Switch
                disabled={!isAuthUserAdmin}
                checked={group.data?.isActive}
                onChange={handleToggle}
                color="primary"
                inputProps={{'aria-label': 'primary checkbox'}}
              />
            </Typography>
          </div>
          <div className={classes.actions}>
            <Button
              variant="outlined"
              target="_blank"
              href={group.data ? `https://${group.data.termsUrl}` : ''}
            >
              View Terms &amp; Conditions
            </Button>
          </div>
        </Toolbar>
        <div className={classes.content}>
          <Grid container spacing={2}>
            <GroupForm
              hookForm={hookForm}
              isAuthUserAdmin={isAuthUserAdmin}
              agents={agents.data?.filter(e => e.isActive) || null}
            />
            <Fade in={isDirty}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Fade>
            <Fade in={isDirty && isAuthUserAdmin} unmountOnExit={false}>
              <Grid item xs={12}>
                <div className={classes.footer}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.saveButton}
                    disabled={loading}
                    onClick={handleSubmit(onSuccess)}
                  >
                    {loading ? <CircularProgress size={24} /> : <Fragment>Save Changes</Fragment>}
                  </Button>
                </div>
              </Grid>
            </Fade>
          </Grid>
        </div>
      </Paper>
    </div>
  );
};
