import {AgentOffice, Application, ApplicationView} from '@ozark/common';
import {getAgentByUid} from '@ozark/common/hooks/useAgents';
import {useEffect, useState} from 'react';

export function useApplicationAddressAgent(application?: ApplicationView | Application) {
  const [agentAddress, setAgentAddress] = useState<AgentOffice>();

  useEffect(() => {
    let isMounted = true;
    if (!application?.agent?.id) return;

    getAgentByUid(application.agent?.id).then(agent => {
      if (!agent) return;
      const {office} = agent;
      if (!office?.address) return;
      isMounted && setAgentAddress(office);
    });

    return () => {
      isMounted = false;
    };
  }, [application?.agent?.id]);

  return {
    agentAddress,
  };
}
