import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import {Option} from '@ozark/common/components/Tickets/types';
import {Controller, ControllerRenderProps} from 'react-hook-form';

type SelectOps = Omit<SelectProps, 'name' | 'label' | keyof ControllerRenderProps>;

type Props = {
  name: string;
  label: string;
  options: Option[];
} & SelectOps;

export const SelectHooked = ({options, name, label, required, ...userProps}: Props) => {
  return (
    <Controller
      name={name}
      render={({field: {ref, ...hookFormProps}, fieldState: {error}}) => (
        <FormControl fullWidth error={Boolean(error)} required={required}>
          <InputLabel id="select-hooked-label">{label}</InputLabel>
          <Select
            labelId="select-hooked-label"
            id="select-hooked"
            label={label}
            inputRef={ref}
            {...hookFormProps}
            {...userProps}
          >
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    ></Controller>
  );
};
