import {formatNumber, formatUSD} from '../../..';
import {ChartPie, Serie} from '../common/DashboardChartPie';
import {DataIsLoading} from '../common/DataIsLoading';
import {DataIsMissing, NoTransactions} from '../common/DataIsMissing';
import {WidgetProps} from '../common/types';

import {hasData} from '../common/utils';

export const WidgetSalesGraph = ({loading, data}: WidgetProps) => {
  if (loading) return <DataIsLoading />;
  if (!data) return <DataIsMissing />;

  const rows: Serie[] = [
    {
      id: 'Net Volume',
      label: `Net Volume: ${formatUSD(data.netSales)} / ${formatNumber(data.totalTransactions)}`,
      value: data.netSales,
    },
    {
      id: 'Refunds',
      label: `Refunds: ${formatUSD(data.totalRefunds)} / ${formatNumber(data.totalRefundCount)}`,
      value: data.totalRefunds,
    },
  ];

  if (!hasData(rows.map(r => r.value))) {
    return <NoTransactions label="No sales to graph" />;
  }

  return <ChartPie data={rows} valueFormatter={formatUSD} />;
};
