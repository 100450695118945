import {Attachment, DocumentBase, NotificationType, UniversalTimestamp} from '.';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export type SnoozeNotificationTime = {
  snoozeNotificationsUntil?: UniversalTimestamp;
};

export enum NotificationStatus {
  new = 'new',
  read = 'read',
  seen = 'seen',
  dismissed = 'dismissed',
}

export type NotificationAttachment = Attachment & {id: string};

export enum NotificationCategory {
  System = 'system',
  Announcements = 'announcements',
}

export type NotificationsProps = {
  title: string;
  text: string | string[]; // array for multiline notifications
  status: NotificationStatus;
  category: NotificationCategory;
  link?: string;
  linkTitle?: string;
  createdAt: UniversalTimestamp;
  isImportantNotification?: boolean;
  type?: NotificationType;
  isTestNotification?: boolean;
  attachments?: NotificationAttachment[];
};

export type Notification = DocumentBase & NotificationsProps;

export type NotificationView = ViewBase<Notification>;

export const selectNotificationView: ViewSelector<NotificationView, Notification> = snapshot => {
  const notification: NotificationView = {
    ...defaultViewSelector(snapshot),
  };
  return notification;
};
