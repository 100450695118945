import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import {ApplicationDisputeReason, Dispositions, useDisputeHistory} from '@ozark/common';
import React, {useCallback, useState} from 'react';

type DeclineDialogProps = {
  applicationId: string;
};

const declineReasonsMap: Record<string, string> = {
  copyrightInfringement: 'Copyright Infringement',
  creditProfile: 'Credit Profile',
  excessiveChargebacks: 'Excessive Chargebacks',
  idTheft: 'Suspected ID Fraud',
  match: 'Merchant was placed on MATCH',
  misrepresentation: 'Misrepresentation',
  negativeOnlinePresence: 'Negative Online Presence',
  otherReason: 'Other Reason',
  prohibitedBusiness: 'Prohibited Business',
  suspectedFraudStrawSigner: 'Suspected Fraud/Straw Signer',
};

const OtherReason = ({reason}: {reason: string | boolean | string[] | null}) => {
  if (typeof reason !== 'string') {
    return null;
  }
  return (
    <Typography
      dangerouslySetInnerHTML={{__html: reason}}
      sx={{
        whiteSpace: 'pre-line',
        paddingLeft: 2,
        marginBottom: -3,
      }}
    />
  );
};

export const DeclineDialog: React.FC<DeclineDialogProps> = ({applicationId}) => {
  const [disputeReasons, setDisputeReasons] = useState<ApplicationDisputeReason[]>([]);
  const {getDisputeReasons} = useDisputeHistory();
  const [declineDialogOpen, setDeclineDialogOpen] = useState(false);
  const theme = useTheme();
  const fetchDisputeReasons = useCallback(async () => {
    const reasons = await getDisputeReasons(applicationId, Dispositions.uwDeclined);

    setDisputeReasons(reasons);
  }, [applicationId]);

  const handleOpen = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    fetchDisputeReasons();
    setDeclineDialogOpen(true);
  };

  const handleClose = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setDeclineDialogOpen(false);
  };

  return (
    <>
      <Button size="small" onClick={handleOpen}>
        View Reason
      </Button>
      <Dialog
        onClose={handleClose}
        open={declineDialogOpen}
        onClick={event => event.stopPropagation()}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Decline Reason</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <>
              <List>
                {disputeReasons
                  .filter(reason => !!reason.value)
                  .map((reason, i) => {
                    return (
                      <ListItem key={reason.key}>
                        <ListItemText
                          primary={
                            <Typography>
                              {i + 1}.&nbsp;{declineReasonsMap[reason.key]}
                            </Typography>
                          }
                          secondary={<OtherReason reason={reason.value} />}
                        />
                      </ListItem>
                    );
                  })}
              </List>
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
