export const AccountUpdatesIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g fill={'inherit'}>
        <path
          d="M636.3,388.2l-25.8-14.9c2.6-14.1,2.6-28.5,0-42.6l25.8-14.9c3-1.7,4.3-5.2,3.3-8.5c-6.6-21.4-18-41-33.2-57.4
		c-2.3-2.5-6-3.1-9-1.4l-25.8,14.9c-10.9-9.3-23.4-16.5-36.9-21.3v-29.8c0-3.4-2.4-6.4-5.7-7.1c-21.8-4.9-44.4-4.9-66.2,0
		c-3.3,0.7-5.7,3.7-5.7,7.1v29.8c-13.5,4.8-26,12-36.9,21.3l-25.8-14.9c-3-1.7-6.7-1.1-9,1.4c-15.2,16.4-26.6,36-33.2,57.4
		c-1,3.3,0.4,6.8,3.3,8.5l25.8,14.9c-2.6,14.1-2.6,28.5,0,42.6l-25.8,14.9c-3,1.7-4.3,5.2-3.3,8.5c6.7,21.4,18,41,33.2,57.4
		c2.3,2.5,6,3.1,9,1.4l25.8-14.9c10.9,9.3,23.4,16.5,36.9,21.3v29.8c0,3.4,2.4,6.4,5.7,7.1c21.8,4.9,44.4,4.9,66.2,0
		c3.3-0.7,5.7-3.7,5.7-7.1v-29.8c13.5-4.8,26-12,36.9-21.3l25.8,14.9c3,1.7,6.7,1.1,9-1.4c15.2-16.4,26.6-36,33.2-57.4
		C640.6,393.4,639.2,389.9,636.3,388.2z M496,400.5c-26.8,0-48.5-21.7-48.5-48.5s21.7-48.5,48.5-48.5s48.5,21.7,48.5,48.5
		S522.8,400.5,496,400.5z"
        />
        <path
          d="M425.2,491.7v-9.2c-2.3-1.2-4.6-2.6-6.8-3.9l-7.9,4.6c-15.9,9.2-36,6.2-48.5-7.3
		c-18.4-19.9-32.1-43.7-40.2-69.6c-5.5-17.5,1.9-36.6,17.9-45.7l7.9-4.6c-0.1-2.6-0.1-5.2,0-7.8l-7.9-4.6
		c-16-9.1-23.4-28.2-17.9-45.7c0.9-2.9,2.2-5.8,3.2-8.7c-3.8-0.3-7.5-1.2-11.4-1.2h-16.7c-46.3,21.3-99.5,21.3-145.8,0h-16.7
		C60.2,288,0,348.2,0,422.4V464c0,26.5,21.5,48,48,48l0,0h352c9.7,0,19.2-3,27.2-8.5C425.9,499.7,425.3,495.7,425.2,491.7z M224,256
		c70.7,0,128-57.3,128-128S294.7,0,224,0S96,57.3,96,128S153.3,256,224,256z"
          opacity={0.4}
        />
      </g>
    </svg>
  );
};
