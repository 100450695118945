import {
  AsyncState,
  Collections,
  Equipment,
  EquipmentView,
  Firebase,
  getEnumKeyByValue,
  ProcessingTypes,
  selectEquipmentView,
  UniversalSnapshot,
} from '@ozark/common';
import {useEffect, useState} from 'react';

export const useEquipment = (processingType?: ProcessingTypes) => {
  const [equipment, setEquipment] = useState<AsyncState<EquipmentView[]>>({promised: true});

  useEffect(() => {
    const processingTypeKeys: (string | null)[] = processingType
      ? [getEnumKeyByValue(ProcessingTypes, processingType)]
      : Object.entries(ProcessingTypes).map(([key, value]) => key);

    const unsubscribe = Firebase.firestore
      .collection(Collections.equipment)
      .where('processingTypes', 'array-contains-any', processingTypeKeys)
      .onSnapshot(
        async snapshot => {
          if (snapshot.size === 0) {
            setEquipment({promised: false});
            return;
          }

          const equipment = snapshot.docs
            .map(doc => selectEquipmentView(doc as UniversalSnapshot<Equipment>))
            .sort((a, b) => {
              return (a.isOther ? 1 : -1) - (b.isOther ? 1 : -1) || a.name.localeCompare(b.name);
            });

          setEquipment({promised: false, data: equipment});
        },
        err => {
          console.error(err);
          setEquipment({promised: false, error: err});
        }
      );
    return () => {
      unsubscribe();
    };
  }, [setEquipment, processingType]);

  return {equipment};
};
