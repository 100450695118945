import {useEffect, useState} from 'react';
import {Collections, Firebase} from '..';

export type MidInfo = {
  mid: string;
  dba: string;
};

export function useMid(mid: string) {
  const defaultMidInfo = {
    mid,
    dba: mid,
  };
  const [midInfo, setMidInfo] = useState<MidInfo>(defaultMidInfo);

  useEffect(() => {
    Firebase.firestore
      .collection(Collections.mids)
      .doc(mid)
      .get()
      .then(snap => {
        if (snap.exists) {
          setMidInfo(snap.data() as MidInfo);
        }
      })
      .catch(err => {
        console.error(err);
        setMidInfo(defaultMidInfo);
      });
  }, [mid]);

  return midInfo;
}
