import AttachFileIcon from '@mui/icons-material/AttachFile';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Stack,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import {TmpAttachment} from '@ozark/functions/src/functions/express/private/types/Attachments';
import {useCallback, useEffect, useState} from 'react';
import {Collections, Firebase, useNotification} from '../../..';
import {useUserInfo} from '../../../hooks';
import {InsertAttachmentsDialog} from '../../Attachments';
import {StackOfAttachments} from '../../StackOfAttachments';
import {StackAttachment} from '../../StackOfAttachments/types';
import {AvatarFromName} from '../common/AvatarFromName';
import {ButtonDeleteComment} from '../common/ButtonDeleteComment';
import {EditorWarning} from '../common/EditorWarning';
import {useCommentAttachments} from '../hooks/useCommentAttachments';
import {useCommentAuthorName} from '../hooks/useCommentAuthorName';
import {CommentInfo} from '../hooks/useComments';
import {useIsEditingClosed} from '../hooks/useIsEditingClosed';
import {useTicketEditContainer} from '../hooks/useTicketEditContainer';
import {useTicketId} from '../hooks/useTicketId';
import {CommentEditor} from './CommentEditor';

const colorInternal = '#fff4e5';
const colorPublic = '#f5fafc';

type Props = {
  comment: CommentInfo;
};

export function CommentCard({comment}: Props) {
  const {isErpAdmin} = useUserInfo();
  const {ticketId} = useTicketId();
  const {commentsCollection, commentsInternal} = useTicketEditContainer();
  const [attachments, setAttachments] = useState<StackAttachment[]>([]);
  const {attachmentUpload, attachmentRemove} = useCommentAttachments();
  const [open, setOpen] = useState(false);
  const {isEditingClosed} = useIsEditingClosed();
  const {getCommentAuthorName} = useCommentAuthorName();
  const authorName = getCommentAuthorName(comment);
  const commentId = comment.comment.id;
  const [attachDialogOpen, setAttachDialogOpen] = useState(false);
  const showNotification = useNotification();

  const handleCreateAttachments = useCallback(
    async (attachments: TmpAttachment[]) => {
      try {
        await attachmentUpload(commentId, attachments);
        setAttachDialogOpen(false);
      } catch (err: any) {
        console.error(`failed to upload file. ${err.toString()}`);
        showNotification('error', 'Failed to upload.');
      }
    },
    [commentId, attachmentUpload]
  );

  useEffect(() => {
    if (ticketId && commentId) {
      return Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .collection(commentsCollection)
        .doc(commentId)
        .collection(Collections.attachments)
        .orderBy('createdAt', 'desc')
        .onSnapshot(
          snap => {
            if (snap.size) {
              const result = snap.docs.map(d => ({id: d.id, ...d.data()} as StackAttachment));
              setAttachments(result);
            } else {
              setAttachments([]);
            }
          },
          err => {
            console.error('Error reading attachments: ', err);
            setAttachments([]);
          }
        );
    }
  }, [ticketId, commentsCollection]);

  return (
    <Box display="flex">
      <Box>
        <AvatarFromName name={authorName} />
      </Box>
      <Paper sx={{ml: 1.5, flex: 1, borderRadius: 2}}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          bgcolor={commentsInternal ? colorInternal : colorPublic}
          sx={{
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
        >
          <Box
            px={1.5}
            sx={{
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            }}
          >
            <Typography component="div" variant="body2" color="text.secondary" sx={{py: 1}}>
              {authorName} commented {comment.localTime}
            </Typography>
          </Box>
          {isErpAdmin && (
            <Box>
              <Stack direction="row" p={0.1} pr={1}>
                <IconButton size="small" onClick={() => setOpen(true)} disabled={isEditingClosed}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="add attachment"
                  component="span"
                  size="small"
                  disabled={isEditingClosed}
                  onClick={_ => {
                    setAttachDialogOpen(true);
                  }}
                >
                  <AttachFileIcon />
                </IconButton>
                <ButtonDeleteComment commentId={commentId} disabled={isEditingClosed} />
              </Stack>
            </Box>
          )}
        </Box>
        <Divider />
        <Box p={1} pl={1.5}>
          <Typography component="div" variant="body2" sx={{'& p': {marginTop: 0, marginBottom: 1}}}>
            <div
              className="richtext-container"
              dangerouslySetInnerHTML={{__html: comment.comment.comment}}
            />
          </Typography>
          {attachments.length ? (
            <Box mt={1} mb={0.5}>
              <StackOfAttachments
                attachments={attachments}
                removeAttachment={
                  isErpAdmin && !isEditingClosed
                    ? attachmentRemove.bind(null, commentId)
                    : undefined
                }
              />
            </Box>
          ) : null}
        </Box>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>Edit Comment</DialogTitle>
          <DialogContent>
            <EditorWarning actionName="edit" />
            <CommentEditor
              handleClose={() => setOpen(false)}
              comment={comment.comment.comment}
              commentId={comment.comment.id}
            />
          </DialogContent>
        </Dialog>
        {attachDialogOpen && (
          <InsertAttachmentsDialog
            initialFolderName={null}
            folderNamesOptions={[]}
            onSubmit={handleCreateAttachments}
            onClose={() => setAttachDialogOpen(false)}
            hideFolder={true}
          />
        )}
      </Paper>
    </Box>
  );
}
