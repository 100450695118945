import {DATE_FORMAT} from '@ozark/functions/src/functions/common/DateUtils';
import {
  DepositDayDetails,
  PaginatedResponse,
} from '@ozark/functions/src/functions/express/private/types';
import {FilterOption, MtdDefaultDateRange} from '../../../Filters';

export const DepositsDaysFilters: FilterOption[] = [
  {
    id: 'mid',
    column: 'mid',
    label: 'MID',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'date',
    column: 'depositDate',
    label: 'Deposit Date',
    type: 'date',
    operators: [
      {
        id: '__between',
        label: 'equals',
      },
    ],
  },
  {
    id: 'depositDate',
    column: 'depositDate',
    label: 'Deposit Date Range',
    type: 'dateRange',
    defaultDateRange: MtdDefaultDateRange,
    dateFormat: DATE_FORMAT,
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'statementDateRange',
    column: 'statementDate',
    label: 'Statement Date Range',
    type: 'monthRange',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'statementDate',
    column: 'statementDate',
    label: 'Statement Date',
    type: 'month',
    operators: [
      {
        id: '__between',
        label: 'equals',
      },
    ],
  },
];

export type DepositCpyDayView = {
  id: string;
  depositDate?: Date;
  statementDate?: Date;
  mid?: string;
  routingNumber?: number;
  ddaNumber: string;
  depositAmount: number;
  depositDayDetails?: DepositDayDetails[] | null | undefined;
  isTotal: boolean;
  referenceNumber?: string;
};

export type PaginatedDepositsDaysViewResponse = PaginatedResponse<DepositCpyDayView> & {};
