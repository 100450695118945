import {endOfDay, startOfDay} from 'date-fns';
import {FilterOption} from '../../../Filters';
import {forceActiveFilter} from '../../Reporting';

export const forceMtdActiveFilter = (
  filterOptions: FilterOption[],
  id?: string,
  operatorId?: string
) => {
  const currentDate = new Date();
  return forceActiveFilter(filterOptions, id ?? 'dateRange', operatorId ?? '__between', [
    startOfDay(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)),
    endOfDay(currentDate),
  ]);
};
