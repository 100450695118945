export const chunkArray = <T = unknown>(list: T[], chunk: number): T[][] => {
  const result: T[][] = [];

  for (let i = 0; i < list.length; i += chunk) {
    result.push(list.slice(i, i + chunk));
  }

  return result;
};

export const limitOffset = <T extends {}>(array: T[], limit: number, offset: number): T[] => {
  if (!array) {
    return [];
  }

  const length = array.length;

  if (!length || offset > length - 1) {
    return [];
  }

  const start = Math.min(length - 1, offset);
  const end = Math.min(length, offset + limit);

  return array.slice(start, end);
};
