/* eslint-disable react-hooks/exhaustive-deps */
import {Box, CircularProgress, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Fragment, useState} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {FixedSizeList as List} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

const useStyles = makeStyles(() =>
  createStyles({
    placeholder: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

export const InfiniteEntities = <TType extends object>({
  data,
  loadNextPage,
  onRender,
  itemSize,
  showTotal = false,
}: {
  onRender: (document: TType) => JSX.Element;
  data?: {
    items: TType[];
    hasNextPage: boolean;
    totalCount?: number;
  };
  loadNextPage: (onLoaded?: () => void) => void;
  itemSize?: number;
  showTotal?: boolean;
}) => {
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const getNextPage = () => {
    return new Promise<void>(resolve => {
      if (!data?.items) {
        return;
      }

      setLoading(true);

      loadNextPage(() => {
        setLoading(false);
        resolve();
      });
    });
  };

  const itemCount = data?.hasNextPage ? data.items.length + 1 : data?.items.length || 0;

  const loadMoreItems = loading ? () => Promise.resolve() : getNextPage;

  const isItemLoaded = (index: number) => {
    return !data?.hasNextPage || index < data?.items.length;
  };

  return (
    <Fragment>
      {showTotal && (
        <Box sx={{textAlign: 'end'}}>
          <Typography variant="body2" color="text.secondary">
            Displaying {data?.items.length} of {data?.totalCount ?? 0}
          </Typography>
        </Box>
      )}
      <Box
        sx={theme => ({
          flex: 1,
          scrollbarWidth: 'none',
          paddingTop: theme.spacing(0.5),
          position: 'relative',
        })}
      >
        <Box sx={{height: '100%'}}>
          <AutoSizer>
            {({height, width}: any) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                threshold={20}
                loadMoreItems={loadMoreItems}
              >
                {({onItemsRendered, ref}) => (
                  <List
                    height={height}
                    itemCount={itemCount}
                    itemSize={itemSize ? itemSize : 120}
                    width={width}
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                  >
                    {
                      (({index, style}: any) => {
                        const apps = data?.items;
                        if (!apps) return;
                        const row = apps[index];
                        return (
                          <div style={style}>
                            {isItemLoaded(index) ? (
                              onRender(row)
                            ) : (
                              <div className={classes.placeholder}>
                                <CircularProgress />
                              </div>
                            )}
                          </div>
                        );
                      }) as any
                    }
                  </List>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </Box>
      </Box>
    </Fragment>
  );
};
