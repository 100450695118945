import {Typography} from '@mui/material';
import {styles} from './constants';

interface Props {
  value: string;
}
export const DisplayText = ({value}: Props) => {
  return (
    <Typography variant="caption" sx={styles.resourceFont}>
      {value}
    </Typography>
  );
};
