export const useGreeting = () => {
  const getGreetingText = (firstName: string | undefined) => {
    const userFirstName = firstName ?? '';
    let now = new Date();

    let isMorning = now.getHours() >= 5 && now.getHours() < 12;
    if (isMorning) {
      return `Good Morning ${userFirstName}`;
    }

    let isAfternoon = now.getHours() >= 12 && now.getHours() < 17;
    if (isAfternoon) {
      return `Good Afternoon ${userFirstName}`;
    }
    return `Good Evening ${userFirstName}`;
  };

  return {
    getGreetingText,
  };
};
