import {Firebase} from '@ozark/common';
import event from '@ozark/common/event';
import LogRocket from 'logrocket';
import {useCallback, useEffect, useRef, useState} from 'react';
import {AuthUser} from '../../types/AuthUser';
import {AsyncState} from './AsyncState';

export const useAuth = () => {
  // default promised state is true to indicate loading
  const [authUser, setAuthUser] = useState<
    AsyncState<AuthUser | null> & {claims?: Record<string, string | boolean | undefined>}
  >({promised: true});

  const [signInEvent] = useState(event<AuthUser>());
  const [signOutEvent] = useState(event<AuthUser>());

  const [onSignIn, emitSignIn] = signInEvent;
  const [onSignOut, emitSignOut] = signOutEvent;

  const prevClaimsUserId = useRef<string | null>(null);

  useEffect(() => {
    let isMounted = true;

    const listeners = [
      Firebase.auth.onIdTokenChanged(async firebaseUser => {
        if (!isMounted) {
          return;
        }

        setAuthUser({
          promised: false,
          data: firebaseUser,
          claims: firebaseUser ? authUser.claims : undefined,
        });

        if (firebaseUser && firebaseUser.uid !== prevClaimsUserId.current) {
          const token = await firebaseUser.getIdTokenResult();
          setAuthUser({promised: false, data: firebaseUser, claims: token?.claims});
          prevClaimsUserId.current = firebaseUser.uid;
        }

        // if promise is still being resolved, return nothing
        if (authUser.promised) return;

        if (firebaseUser && !authUser?.data) {
          emitSignIn(firebaseUser);
        }

        if (!firebaseUser && authUser.data) {
          emitSignOut(authUser.data);
        }
      }),
    ];
    return () => {
      isMounted = false;
      listeners.forEach(unsubscribe => unsubscribe());
    };
  }, [authUser.promised, authUser.data, authUser.claims, emitSignIn, emitSignOut]);

  useEffect(() => {
    if (authUser.data?.uid) {
      LogRocket.identify(authUser.data?.uid, {
        name: authUser.data.displayName || '',
        email: authUser.data.email || '',
      });
    }
  }, [authUser.promised, authUser.data]);

  const setAuthPersistence = useCallback(() => {
    return Firebase.auth.setPersistence(Firebase.Persistence.SESSION);
  }, []);

  const signInAnonymousUser = useCallback(async () => {
    try {
      await setAuthPersistence();
      const user = await Firebase.auth.signInAnonymously();
      return user;
    } catch (err: any) {
      console.error('Failed to sign in anonymous user', err.code, err.message);
    }
    return null;
  }, [setAuthPersistence]);

  const createUserWithEmailAndPassword = useCallback(
    async (email: string, password: string) => {
      try {
        await setAuthPersistence();
        return await Firebase.auth.createUserWithEmailAndPassword(email, password);
      } catch (error) {
        console.error('Failed to create new user with email and password', email, error);
        throw error;
      }
    },
    [setAuthPersistence]
  );

  const signInWithCustomToken = useCallback(
    async (token: string) => {
      try {
        await setAuthPersistence();
        return Firebase.auth.signInWithCustomToken(token);
      } catch (error) {
        console.error('Failed to sign in user with custom token:', token, error);
      }
    },
    [setAuthPersistence]
  );

  const signOut = useCallback(async () => {
    await Firebase.auth.signOut();
  }, []);

  return {
    authUser,
    signInAnonymousUser,
    createUserWithEmailAndPassword,
    signInWithCustomToken,
    signOut,
    onSignIn,
    onSignOut,
  };
};
