import DeleteIcon from '@mui/icons-material/Delete';
import {IconButton, TableCell} from '@mui/material';
import {ResourceView} from '@ozark/common';
import {useDisplayContext} from './DisplayContext';

interface Props {
  resourceView: ResourceView;
}

export const DisplayDeleteButton = ({resourceView}: Props) => {
  const {allowEdit, handleDeleteClick, writePermissionsForResource} = useDisplayContext();
  const canUpdate = writePermissionsForResource(resourceView);
  const deleteButton = (
    <IconButton size="small" onClick={() => handleDeleteClick(resourceView.id)}>
      <DeleteIcon />
    </IconButton>
  );

  if (!allowEdit || !canUpdate) {
    return <TableCell></TableCell>;
  }

  return <TableCell>{deleteButton}</TableCell>;
};
