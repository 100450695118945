import {createContainer} from 'unstated-next';
import {useApiClient} from '../..';

export type ApiContainerProps = {
  apiClient: ReturnType<typeof useApiClient>['apiClient'];
};

function useApi(initialState?: ApiContainerProps) {
  return initialState?.apiClient;
}

export const ApiContainer = createContainer(useApi);
export const useApiContainer = ApiContainer.useContainer;
