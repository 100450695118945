import {Ticket, UniversalTimestamp} from '../../..';
import {useUserInfo} from '../../../hooks/useUserInfo';

export const useLastCommentInfoToDisplay = (ticket: Ticket) => {
  const {isErpUser} = useUserInfo();

  const getLastCommentInfoToDisplay = () => {
    const lastPublicCommentInfo = ticket.lastPublicCommentInfo;
    const lastInternalCommentInfo = ticket.lastInternalCommentInfo;

    if (!isErpUser || lastInternalCommentInfo === undefined) {
      return lastPublicCommentInfo;
    }

    if (!lastPublicCommentInfo) {
      return lastInternalCommentInfo;
    }

    if (lastPublicCommentInfo && lastInternalCommentInfo) {
      return (lastPublicCommentInfo.createdAt as UniversalTimestamp).seconds >
        (lastInternalCommentInfo.createdAt as UniversalTimestamp).seconds
        ? lastPublicCommentInfo
        : lastInternalCommentInfo;
    } else {
      return undefined;
    }
  };

  return getLastCommentInfoToDisplay();
};
