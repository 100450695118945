import {
  AsyncState,
  Collections,
  Firebase,
  MidUserAssociation,
  Platforms,
  UniversalTimestamp,
} from '@ozark/common';
import {useEffect, useState} from 'react';
import {useUserInfo} from './useUserInfo';

// Authorized mid is a union of Mid and MidUserAssociation
type AuthorizedMid = {
  mid: string;
  legalBusinessName: string;
  platform: Platforms;
  dba: string;
  createdAt: UniversalTimestamp;
  groupId: string;
  groupName: string;
  email: string;
  agentName?: string;
  merchantName?: string;
};

export function useAuthorizedMids() {
  const {uid, isAgentAdmin, userGroupId: groupId, isErpUser} = useUserInfo();
  const [authorizedMids, setAuthorizedMids] = useState<AsyncState<AuthorizedMid[]>>({
    promised: true,
  });

  useEffect(() => {
    if (!uid) return;

    (async () => {
      if (isErpUser) {
        const snapshot = await Firebase.firestore.collection(Collections.mids).get();

        setAuthorizedMids({
          promised: false,
          data: snapshot.docs.map(
            doc =>
              ({
                ...doc.data(),
                mid: doc.id,
              } as AuthorizedMid)
          ),
        });
        return;
      }

      if (isAgentAdmin) {
        const snapshot = await Firebase.firestore
          .collection(Collections.mids)
          .where('groupId', '==', groupId)
          .get();

        setAuthorizedMids({
          promised: false,
          data: snapshot.docs.map(
            doc =>
              ({
                ...doc.data(),
                mid: doc.id,
              } as AuthorizedMid)
          ),
        });
        return;
      }

      const snapshot = await Firebase.firestore
        .collection(Collections.midUser)
        .where('userId', '==', uid)
        .get();

      setAuthorizedMids({
        promised: false,
        data: snapshot.docs.map(doc => doc.data() as MidUserAssociation),
      });
    })();
  }, [uid, isErpUser, isAgentAdmin, groupId]);

  return {authorizedMids};
}
