import {GroupRole} from './GroupRole';
import {hasErpRole, UserRoles} from './UserRoles';

export enum SubscriptionCategory {
  applications = 'applications',
  calendar = 'calendar',
  tickets = 'tickets',
  disputes = 'disputes',
  accountProcessing = 'accountProcessing',
}

export enum SubscriptionType {
  // application
  applicationApproved = 'applicationApproved',
  applicationSigned = 'applicationSigned',
  applicationUWReceived = 'applicationUWReceived',

  // calendar
  calendarEvents = 'calendarEvents',
  calendarReminders = 'calendarReminders',

  // tickets
  ticketAssigned = 'ticketAssigned',
  ticketAssociationUpdated = 'ticketAssociationUpdated',
  ticketUpdated = 'ticketUpdated',
  ticketNeedsAttention = 'ticketNeedsAttention',
  ticketNewComment = 'ticketNewComment',

  // disputes
  newDisputes = 'newDisputes',
  disputesUpdate = 'disputesUpdate',

  // processing
  processingStopped = 'processingStopped',
  processingInactive = 'processingInactive',
}

export enum SubscriptionEvent {
  email = 'email',
  systemNotification = 'systemNotification',
}

// internal type to shorten props
type Claims =
  | {
      role?: UserRoles;
      groupRole?: GroupRole;
    }
  | null
  | undefined;

type IsVisible = (claims: Claims) => boolean;

type SubscriptionConfig = {
  key: SubscriptionCategory;
  title: string;
  isVisible: IsVisible;
  types: {
    key: SubscriptionType;
    title: string;
    isVisible: IsVisible;
    events: Record<SubscriptionEvent, {isVisible: IsVisible}>;
  }[];
};

export const SUBSCRIPTIONS: SubscriptionConfig[] = [
  {
    key: SubscriptionCategory.applications,
    title: 'Applications',
    isVisible: (claims: Claims) => claims?.role === UserRoles.agent,
    types: [
      {
        key: SubscriptionType.applicationApproved,
        title: 'Application Approved',
        isVisible: (claims: Claims) => claims?.role === UserRoles.agent,
        events: {
          [SubscriptionEvent.email]: {
            isVisible: (claims: Claims) => claims?.role === UserRoles.agent,
          },
          [SubscriptionEvent.systemNotification]: {
            isVisible: (claims: Claims) => claims?.role === UserRoles.agent,
          },
        },
      },
      {
        key: SubscriptionType.applicationSigned,
        title: 'Application Signed',
        isVisible: (claims: Claims) => claims?.role === UserRoles.agent,
        events: {
          [SubscriptionEvent.email]: {
            isVisible: (claims: Claims) => claims?.role === UserRoles.agent,
          },
          [SubscriptionEvent.systemNotification]: {
            isVisible: (claims: Claims) => claims?.role === UserRoles.agent,
          },
        },
      },
      {
        key: SubscriptionType.applicationUWReceived,
        title: 'UW Received',
        isVisible: (claims: Claims) => claims?.role === UserRoles.agent,
        events: {
          [SubscriptionEvent.email]: {
            isVisible: (claims: Claims) => claims?.role === UserRoles.agent,
          },
          [SubscriptionEvent.systemNotification]: {
            isVisible: (claims: Claims) => claims?.role === UserRoles.agent,
          },
        },
      },
    ],
  },
  {
    key: SubscriptionCategory.calendar,
    title: 'Calendar',
    isVisible: (claims: Claims) => hasErpRole(claims?.role),
    types: [
      {
        key: SubscriptionType.calendarEvents,
        title: 'Calendar Events',
        isVisible: (claims: Claims) => hasErpRole(claims?.role),
        events: {
          [SubscriptionEvent.email]: {
            isVisible: (claims: Claims) => hasErpRole(claims?.role),
          },
          [SubscriptionEvent.systemNotification]: {
            isVisible: (claims: Claims) => hasErpRole(claims?.role),
          },
        },
      },
      {
        key: SubscriptionType.calendarReminders,
        title: 'Calendar Reminders',
        isVisible: (claims: Claims) => hasErpRole(claims?.role),
        events: {
          [SubscriptionEvent.email]: {
            isVisible: (claims: Claims) => hasErpRole(claims?.role),
          },
          [SubscriptionEvent.systemNotification]: {
            isVisible: (claims: Claims) => hasErpRole(claims?.role),
          },
        },
      },
    ],
  },
  {
    key: SubscriptionCategory.tickets,
    title: 'Tickets',
    isVisible: (claims: Claims) => hasErpRole(claims?.role) || claims?.role === UserRoles.agent,
    types: [
      {
        key: SubscriptionType.ticketAssigned,
        title: 'Ticket Assigned',
        isVisible: (claims: Claims) => hasErpRole(claims?.role) || claims?.role === UserRoles.agent,
        events: {
          [SubscriptionEvent.email]: {
            isVisible: (claims: Claims) =>
              hasErpRole(claims?.role) || claims?.role === UserRoles.agent,
          },
          [SubscriptionEvent.systemNotification]: {
            isVisible: (claims: Claims) => hasErpRole(claims?.role),
          },
        },
      },
      {
        key: SubscriptionType.ticketUpdated,
        title: 'Ticket Status or Priority Updated',
        isVisible: (claims: Claims) => hasErpRole(claims?.role) || claims?.role === UserRoles.agent,
        events: {
          [SubscriptionEvent.email]: {
            isVisible: (claims: Claims) =>
              hasErpRole(claims?.role) || claims?.role === UserRoles.agent,
          },
          [SubscriptionEvent.systemNotification]: {
            isVisible: (claims: Claims) => hasErpRole(claims?.role),
          },
        },
      },
      {
        key: SubscriptionType.ticketAssociationUpdated,
        title: 'Ticket Association Updated',
        isVisible: (claims: Claims) => claims?.role === UserRoles.agent,
        events: {
          [SubscriptionEvent.email]: {
            isVisible: (claims: Claims) => claims?.role === UserRoles.agent,
          },
          [SubscriptionEvent.systemNotification]: {
            isVisible: (claims: Claims) => claims?.role === UserRoles.agent,
          },
        },
      },
      {
        key: SubscriptionType.ticketNeedsAttention,
        title: 'Ticket Needs Attention',
        isVisible: (claims: Claims) => hasErpRole(claims?.role) || claims?.role === UserRoles.agent,
        events: {
          [SubscriptionEvent.email]: {
            isVisible: (claims: Claims) =>
              hasErpRole(claims?.role) || claims?.role === UserRoles.agent,
          },
          [SubscriptionEvent.systemNotification]: {
            isVisible: (claims: Claims) => hasErpRole(claims?.role),
          },
        },
      },
      {
        key: SubscriptionType.ticketNewComment,
        title: 'Ticket New Comment',
        isVisible: (claims: Claims) => claims?.role === UserRoles.agent,
        events: {
          [SubscriptionEvent.email]: {
            isVisible: (claims: Claims) => claims?.role === UserRoles.agent,
          },
          [SubscriptionEvent.systemNotification]: {
            isVisible: (claims: Claims) => claims?.role === UserRoles.agent,
          },
        },
      },
    ],
  },
  {
    key: SubscriptionCategory.disputes,
    title: 'Disputes',
    isVisible: (claims: Claims) =>
      hasErpRole(claims?.role) ||
      claims?.role === UserRoles.agent ||
      claims?.role === UserRoles.merchant,
    types: [
      {
        key: SubscriptionType.newDisputes,
        title: 'New Dispute',
        isVisible: (claims: Claims) =>
          hasErpRole(claims?.role) ||
          claims?.role === UserRoles.agent ||
          claims?.role === UserRoles.merchant,
        events: {
          [SubscriptionEvent.email]: {
            isVisible: (claims: Claims) =>
              hasErpRole(claims?.role) ||
              claims?.role === UserRoles.agent ||
              claims?.role === UserRoles.merchant,
          },
          [SubscriptionEvent.systemNotification]: {
            isVisible: (claims: Claims) =>
              hasErpRole(claims?.role) ||
              claims?.role === UserRoles.agent ||
              claims?.role === UserRoles.merchant,
          },
        },
      },
      {
        key: SubscriptionType.disputesUpdate,
        title: 'Dispute Update',
        isVisible: (claims: Claims) =>
          hasErpRole(claims?.role) ||
          claims?.role === UserRoles.agent ||
          claims?.role === UserRoles.merchant,
        events: {
          [SubscriptionEvent.email]: {
            isVisible: (claims: Claims) =>
              hasErpRole(claims?.role) ||
              claims?.role === UserRoles.agent ||
              claims?.role === UserRoles.merchant,
          },
          [SubscriptionEvent.systemNotification]: {
            isVisible: (claims: Claims) =>
              hasErpRole(claims?.role) ||
              claims?.role === UserRoles.agent ||
              claims?.role === UserRoles.merchant,
          },
        },
      },
    ],
  },
  {
    key: SubscriptionCategory.accountProcessing,
    title: 'Account Processing',
    isVisible: (claims: Claims) =>
      claims?.role === UserRoles.admin || claims?.role === UserRoles.agent,
    types: [
      {
        // according to our code, we don't send emails to ERP Admins, Merchants and master agents of assigned agents
        key: SubscriptionType.processingStopped,
        title: 'Stopped Processing',
        isVisible: (claims: Claims) =>
          claims?.role === UserRoles.admin || claims?.role === UserRoles.agent,

        events: {
          [SubscriptionEvent.email]: {
            isVisible: (claims: Claims) => claims?.role === UserRoles.agent,
          },
          [SubscriptionEvent.systemNotification]: {
            isVisible: (claims: Claims) =>
              claims?.role === UserRoles.admin || claims?.role === UserRoles.agent,
          },
        },
      },

      {
        // according to our code, we don't send emails to ERP Admins, Merchants and master agents of assigned agents
        key: SubscriptionType.processingInactive,
        title: 'Inactive Processing',
        isVisible: (claims: Claims) =>
          claims?.role === UserRoles.admin || claims?.role === UserRoles.agent,

        events: {
          [SubscriptionEvent.email]: {
            isVisible: (claims: Claims) => claims?.role === UserRoles.agent,
          },
          [SubscriptionEvent.systemNotification]: {
            isVisible: (claims: Claims) =>
              claims?.role === UserRoles.admin || claims?.role === UserRoles.agent,
          },
        },
      },
    ],
  },
];
