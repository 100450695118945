import {Box, Typography} from '@mui/material';

type Props = {
  description?: string;
};

export const ComingSoon = ({description}: Props) => {
  return (
    <Box>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{
          mt: 6,
          color: 'primary.main',
        }}
      >
        COMING SOON
      </Typography>
      {description && (
        <Typography
          variant="h5"
          gutterBottom
          align="center"
          sx={{
            my: 3,
            color: 'primary.light',
          }}
        >
          [{description}]
        </Typography>
      )}
      <Typography variant="body1" align="center">
        We are currently working on this feature and will launch soon.
      </Typography>
    </Box>
  );
};
