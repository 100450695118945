import {yupResolver} from '@hookform/resolvers/yup';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import {Theme, useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {CreateMerchantUserPayload, MerchantView, useCallable} from '@ozark/common';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNotification} from '../../hooks';
import {MerchantForm, MerchantFormModel, MerchantFormSchema} from './MerchantForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      [theme.breakpoints.up('md')]: {
        width: 600,
      },
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

type Props = {
  onClose: () => void;
  userGroupId?: string;
  masterMerchant?: MerchantView;
};

export const CreateMerchantDialog = ({onClose, userGroupId, masterMerchant}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const {createMerchantUser} = useCallable();
  const showNotification = useNotification();

  const hookForm = useForm<MerchantFormModel>({
    resolver: yupResolver(MerchantFormSchema),
    shouldUnregister: true,
  });
  const {handleSubmit} = hookForm;

  const onSuccess = async (data: MerchantFormModel) => {
    setLoading(true);
    try {
      const payload: CreateMerchantUserPayload = {
        applicationMids: data.applicationMids ?? [],
        email: data.email,
        firstName: data.firstName,
        groupId: data.groupId,
        isManualFlow: true,
        lastName: data.lastName,
        masterUid: data.masterMerchant?.id ?? masterMerchant?.id,
        password: `${+new Date()}`,
        phoneNumber: data.phoneNumber,
        timeZoneId: data.timeZoneId,
      };
      const result = await createMerchantUser(payload);

      if (result.status === 'ok') {
        showNotification('success', 'Merchant successfully created.');
        onClose();
        return;
      }
      showNotification('error', result.message);
    } finally {
      setLoading(false);
    }
  };

  const onError = (_data: any) => {
    setLoading(false);
  };

  return (
    <Dialog open aria-labelledby="create-dialog-title" fullScreen={fullScreen} maxWidth={'lg'}>
      <DialogTitle id="create-dialog-title">Create Merchant User</DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          <Grid container>
            <MerchantForm
              hookForm={hookForm}
              isAuthUserAdmin
              userGroupId={userGroupId}
              applicationMids={masterMerchant?.applicationMids}
            />
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit(onSuccess, onError)} color="primary" disabled={loading}>
          {loading && <CircularProgress className={classes.buttonProgress} size={24} />}
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
