import {DocumentBase} from '.';
import {ApplicationType, DiscountDuration, StatementType} from '../constants';
import {ProcessingTypesKeys} from '../constants/ProcessingTypes';
import {UniversalTimestamp} from './compat';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export type RateType = number | null | '' | string;

export type RateSet = {
  featuredText: string;
  featuredCaption: string;
  applicationType: ApplicationType;
  discountDuration: DiscountDuration;
  qualifiedDiscountRateVisaMastercardDiscover: RateType;
  midQualifiedDiscountRateVisaMastercardDiscover: RateType;
  nonQualifiedDiscountRateVisaMastercardDiscover: RateType;
  qualifiedTransactionFeeVisaMastercardDiscover: RateType;
  midQualifiedTransactionFeeVisaMastercardDiscover: RateType;
  nonQualifiedTransactionFeeVisaMastercardDiscover: RateType;
  qualifiedDiscountRateAmex: RateType;
  midQualifiedDiscountRateAmex: RateType;
  nonQualifiedDiscountRateAmex: RateType;
  qualifiedTransactionFeeAmex: RateType;
  midQualifiedTransactionFeeAmex: RateType;
  nonQualifiedTransactionFeeAmex: RateType;
  qualifiedDebitPINRate: RateType;
  qualifiedDebitPINFee: RateType;
  qualifiedDebitNonPINRate: RateType;
  qualifiedDebitNonPINFee: RateType;
  otherVolumeRate: RateType;
  otherItemFee: RateType;
  accountOnFileFee: RateType;
  chargebackFee: RateType;
  retrievalFee: RateType;
  batchFee: RateType;
  monthlyMinimumFee: RateType;
  debitAccessFee: RateType;
  additionalServicesFee: RateType;
  earlyDeconversionFee: RateType;
  pciFee: string;
  pciFeeValue: RateType;
  annualFee: RateType;
  regulatoryFee: RateType;
  avsFee: RateType;
  active: boolean;
  ebtDiscountRate: RateType;
  ebtTransactionFee: RateType;
  mailStatement: boolean | string;
  statementType: StatementType | null;
  gatewaySetupFee: RateType;
  gatewayMonthlyFee: RateType;
  gatewayTransactionFee: RateType;
};

export type RateProgram = DocumentBase & {
  active: boolean;
  version: number;
  createdAt: UniversalTimestamp;
  name: string;
  processingTypes: {[key in ProcessingTypesKeys]: RateSet};
  description?: string;
  benefits: Benefits;
  global?: boolean;
};

export type Benefits = {
  title: string;
  list: string[];
  custom?: boolean; // we don't save this field to the database, just update during retrieving data
};

export type GroupRateProgram = RateProgram & {
  benefits: {
    custom: boolean;
  };
};

export type RateProgramView = ViewBase<RateProgram>;
export type CustomRateProgramView = RateProgramView & {
  benefits: {
    custom: boolean;
  };
};

export const selectRateProgramView: ViewSelector<RateProgramView, RateProgram> = snapshot => {
  const rateProgram: RateProgramView = {
    ...defaultViewSelector(snapshot),
  };
  return rateProgram;
};
