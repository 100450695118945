import {
  AsyncState,
  Collections,
  Firebase,
  selectSimpleBusinessTypeView,
  SimpleBusinessType,
  SimpleBusinessTypeView,
  UniversalSnapshot,
} from '@ozark/common';
import {useCallback, useEffect, useState} from 'react';

type UseSimpleBusinessTypesProps = {
  activeOnly?: boolean;
  displayMccCodes?: boolean;
};

export const useSimpleBusinessTypes = ({
  activeOnly = true,
  displayMccCodes = false,
}: UseSimpleBusinessTypesProps = {}) => {
  const [businessTypes, setBusinessTypes] = useState<AsyncState<SimpleBusinessTypeView[]>>({
    promised: true,
  });
  const [mccCodes, setMccCodes] = useState<Record<string, string>>({});

  const isSimpleBusinessTypesLoaded = !businessTypes.promised;

  const getBusinessTypeName = useCallback(
    (businessTypeView: SimpleBusinessTypeView) => {
      if (displayMccCodes) {
        return `${businessTypeView.mcc} - ${businessTypeView.name}`;
      }

      return businessTypeView.name;
    },
    [displayMccCodes]
  );

  const getMccByBusinessType = useCallback(
    (businessType: string): string => {
      return mccCodes[businessType];
    },
    [mccCodes]
  );

  const getBusinessTypeByName = useCallback(
    (typeName: string): SimpleBusinessTypeView | undefined => {
      return businessTypes.data?.find((x: SimpleBusinessTypeView) => x.name === typeName);
    },
    [businessTypes?.data]
  );

  const getBusinessTypeNames = useCallback(
    (activeOnly: boolean = true): string[] => {
      const names = businessTypes.data
        ?.filter(x => (activeOnly ? !x.deleted : x))
        ?.map(getBusinessTypeName)
        ?.sort((a, b) => a.localeCompare(b));

      return names ?? [];
    },
    [businessTypes?.data, getBusinessTypeName]
  );

  useEffect(() => {
    const collectionQuery = Firebase.firestore.collection(Collections.simpleBusinessTypes);

    const unsubscribe = (
      activeOnly ? collectionQuery.where('deleted', '==', false) : collectionQuery
    ).onSnapshot(async snapshot => {
      if (snapshot.size === 0) {
        setBusinessTypes({data: [], promised: false});
        return;
      }

      const mccCodesData: Record<string, string> = {};

      const data: SimpleBusinessTypeView[] = [];
      for (const doc of snapshot.docs) {
        const businessType = selectSimpleBusinessTypeView(
          doc as UniversalSnapshot<SimpleBusinessType>
        );
        data.push(businessType);

        mccCodesData[getBusinessTypeName(businessType)] = businessType.mcc;
        mccCodesData[businessType.name] = businessType.mcc; // for compatibility after we trim mcc
      }

      setMccCodes(mccCodesData);
      setBusinessTypes({data, promised: false});
    });

    return () => {
      unsubscribe();
    };
  }, [activeOnly, getBusinessTypeName]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    isSimpleBusinessTypesLoaded,
    getMccByBusinessType,
    getBusinessTypeByName,
    getBusinessTypeNames,
  };
};
