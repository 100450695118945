import {
  selectTicketTypeCategoryView,
  TicketTypeCategory,
  TicketTypeCategoryView,
} from '@ozark/functions/src/documents';
import {useEffect, useState} from 'react';
import {AsyncState, Collections, Firebase, UniversalSnapshot, useNotification} from '../../..';

const statePromised: AsyncState<TicketTypeCategoryView[]> = {
  promised: true,
  data: [],
};

export function useTicketTypeCategories(ticketTypeId: string | null) {
  const [ticketTypeCategories, setTicketTypeCategories] = useState(statePromised);
  const showNotification = useNotification();

  useEffect(() => {
    if (ticketTypeId === null) {
      setTicketTypeCategories({promised: false, data: []});
      return;
    }

    let query = Firebase.firestore
      .collection(Collections.ticketTypes)
      .doc(ticketTypeId)
      .collection(Collections.ticketTypeCategories)
      .orderBy('isDeleted', 'asc')
      .orderBy('name', 'asc');

    return query.onSnapshot(
      async snapshot => {
        if (snapshot.size === 0) {
          setTicketTypeCategories({promised: false, data: []});
          return;
        }
        const ticketTypes = snapshot.docs.map(doc =>
          selectTicketTypeCategoryView(doc as UniversalSnapshot<TicketTypeCategory>)
        );
        setTicketTypeCategories({promised: false, data: ticketTypes});
      },
      err => {
        showNotification('error', err.message);
        setTicketTypeCategories({promised: false, error: err});
      }
    );
  }, []);

  return {
    ticketTypeCategories,
  };
}
