import {Typography} from '@mui/material';

export const TextFieldRequired = () => {
  return (
    <Typography
      flex={1}
      component="div"
      color="error"
      sx={{fontStyle: 'italic', textAlign: 'center'}}
    >
      Field is required
    </Typography>
  );
};
