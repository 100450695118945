import {DocumentBase, UniversalTimestamp, UserRoleName} from '.';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export type TicketType = DocumentBase & {
  name: string;
  visibleToRoles: UserRoleName[];
  isDeleted: boolean;
  categories: string[]; // to display at ticket types page
  createdAt?: UniversalTimestamp;
};

export type TicketTypeView = ViewBase<TicketType>;

export const selectTicketTypeView: ViewSelector<TicketTypeView, TicketType> = snapshot => {
  const ticketType: TicketTypeView = {
    ...defaultViewSelector(snapshot),
  };
  return ticketType;
};
