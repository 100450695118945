import {DocumentBase} from '.';
import {UniversalTimestamp} from './compat';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export enum ResourceViewableByUsers {
  hidden = 'hidden',
  erpAdmin = 'erpAdmin',
  erpAgentSupport = 'erpAgentSupport',
  erpOperations = 'erpOperations',
  erpSales = 'erpSales',
  erpSupport = 'erpSupport',
  erpUnderwriter = 'erpUnderwriter',
  erpAllUsers = 'erpAllUsers',
  portalMerchant = 'portalMerchant',
  portalAgentGroups = 'portalAgentGroups',
}

export const ResourceViewableByUsersNames: Record<ResourceViewableByUsers, string> = {
  [ResourceViewableByUsers.hidden]: 'Hidden',
  [ResourceViewableByUsers.erpAdmin]: 'ERP - Administrator',
  [ResourceViewableByUsers.erpAgentSupport]: 'ERP - Agent Support',
  [ResourceViewableByUsers.erpOperations]: 'ERP - Operations',
  [ResourceViewableByUsers.erpSales]: 'ERP - Sales',
  [ResourceViewableByUsers.erpSupport]: 'ERP - Support',
  [ResourceViewableByUsers.erpUnderwriter]: 'ERP - Underwriter',
  [ResourceViewableByUsers.erpAllUsers]: 'ERP - All Users',
  [ResourceViewableByUsers.portalMerchant]: 'Portal - Merchant',
  [ResourceViewableByUsers.portalAgentGroups]: 'Portal - Agent Groups',
};

export const ResourceViewableByUsersPortalNames: Record<string, string> = {
  [ResourceViewableByUsers.hidden]: 'Hidden',
  [ResourceViewableByUsers.portalMerchant]: 'Merchant',
  [ResourceViewableByUsers.portalAgentGroups]: 'Agent',
};

export enum ResourceType {
  video = 'video',
  document = 'document',
}

export type VideoResource = {
  vimeoLink: string;
};

export type Resource = DocumentBase & {
  type: ResourceType;
  title: string;
  description: string;
  viewableByUsers: ResourceViewableByUsers[];
  shared: boolean; // true if resource created by ERP Admin
  ownerGroupId?: string; // groupId of the Group Admin created this resource
  groups: string[]; // list of Portal groups who have permissions to see
  createdAt: UniversalTimestamp;
  deleted: boolean;
  category: string;
  cloudPath?: string | null;
  cloudPreviewImagePath?: string | null;
  downloadUrl?: string | null;
  downloadPreviewUrl?: string | null;
  size?: number | null;
  contentType?: string | null;
  order: number;
  previewCalculated?: boolean;
} & VideoResource;

export type ResourceInput = Resource;

export type ResourceView = ViewBase<Resource>;

export const selectResourceView: ViewSelector<ResourceView, Resource> = snapshot => {
  const resource: ResourceView = {
    ...defaultViewSelector(snapshot),
  };
  return resource;
};
