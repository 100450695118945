import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SortIcon from '@mui/icons-material/Sort';
import {Box, FormControlLabel, IconButton, List, Radio, RadioGroup, Zoom} from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import {ChangeEvent, useEffect, useRef, useState} from 'react';
import {TicketStatus} from '../../..';
import {useTicketsQuery} from '../hooks/useTicketsQuery';

const CREATED_AT = 'createdAt';
const OPENED_AT = 'openedAt';

type Option = {
  value: string;
  label: string;
};
const optionsDraft: Option[] = [{value: CREATED_AT, label: 'Created At'}];
const optionsOther: Option[] = [{value: OPENED_AT, label: 'Opened At'}];

export function TicketListSortBy() {
  const {setParam, statusSet, sortBy, sortDir} = useTicketsQuery();
  const lastStatus = useRef(statusSet.toString());
  const [sortingVisible, setSortingVisible] = useState(true);
  const options = statusSet.includes(TicketStatus.Draft) ? optionsDraft : optionsOther;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSortByChange = (event: ChangeEvent<HTMLInputElement>) => {
    const sortBy = (event.target as HTMLInputElement).value;
    setParam({sortBy});
  };

  const handleSortDirChange = () => {
    if (!sortBy) return;
    const newDirection = sortDir === 'asc' ? 'desc' : 'asc';
    setParam({sortDir: newDirection});
  };

  useEffect(() => {
    if (lastStatus.current === statusSet.toString()) return;
    if (statusSet.includes(TicketStatus.Draft) || lastStatus.current.includes(TicketStatus.Draft)) {
      setSortingVisible(false);
      setTimeout(() => setSortingVisible(true), 100);
    }
    lastStatus.current = statusSet.toString();
  }, [statusSet]);

  return (
    <div>
      <Zoom
        in={sortingVisible}
        timeout={{
          appear: 0,
          enter: 100,
          exit: 0,
        }}
      >
        <Box display="flex">
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            <span>{options.find(o => o.value === sortBy)?.label}</span>
          </Button>{' '}
          <IconButton aria-label="sort direction" color="primary" onClick={handleSortDirChange}>
            <SortIcon sx={{transform: sortDir === 'asc' ? 'scaleY(-1)' : undefined}} />
          </IconButton>
        </Box>
      </Zoom>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={sortBy}
            onChange={handleSortByChange}
            sx={{px: 2}}
          >
            {options.map(o => (
              <FormControlLabel key={o.value} value={o.value} control={<Radio />} label={o.label} />
            ))}
          </RadioGroup>
        </List>
      </Menu>
    </div>
  );
}
