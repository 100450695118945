export const ResourcesIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g fill={'inherit'}>
        <path
          opacity={0.4}
          d="M448,0H64C28.7,0,0,28.7,0,64v288c0,35.3,28.7,64,64,64h96v84c0,6.6,5.3,12,12,12c2.6,0,5.1-0.8,7.1-2.3
		L304,416h144c35.3,0,64-28.7,64-64V64C512,28.7,483.3,0,448,0z M266.1,336c-19.1,0-34.6-15.5-34.6-34.6s15.5-34.6,34.6-34.6
		s34.6,15.5,34.6,34.6S285.2,336,266.1,336z M296.2,237.1v1c0,5-4,9-9,9H245c-5,0-9-4-9-9v-3c0-30.9,23.4-43.3,41.1-53.2
		c15.2-8.5,24.5-14.3,24.5-25.6c0-15-19-24.8-34.4-24.8c-20.5,0-29.7,9.9-43.1,26.9c-3,3.9-8.6,4.6-12.6,1.6l-26.1-19.8
		c-3.8-2.9-4.7-8.3-2-12.2c20.5-30.7,46.7-48,87.8-48c42.4,0,87.7,33.1,87.7,76.8C358.8,214.8,296.2,215.7,296.2,237.1z"
        />
        <g>
          <path
            d="M266.1,266.7c-19.1,0-34.6,15.5-34.6,34.6S247,336,266.1,336s34.6-15.5,34.6-34.6l0,0
			C300.7,282.2,285.2,266.7,266.1,266.7z M271.1,80c-41,0-67.2,17.3-87.8,48c-2.7,4-1.8,9.3,2,12.2l26.1,19.8
			c3.9,3,9.5,2.3,12.6-1.6c13.4-17.1,22.7-26.9,43.1-26.9c15.4,0,34.4,9.9,34.4,24.8c0,11.3-9.3,17.1-24.5,25.6
			c-17.7,9.9-41.1,22.2-41.1,53.2v3c0,5,4,9,9,9h42.2c5,0,9-4,9-9v-1c0-21.4,62.6-22.3,62.6-80.3C358.8,113.1,313.5,80,271.1,80
			L271.1,80z"
          />
        </g>
      </g>
    </svg>
  );
};
