import {TableCell, TableRow, Typography} from '@mui/material';
import {ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {TextDetailTitle} from '../common/TextDetailTitle';

export const DetailsRow = ({
  name,
  children,
  verticalAlign = 'top',
  paddingTop,
}: {
  name: React.ReactNode;
  children: ReactNode;
  verticalAlign?: 'top' | 'middle';
  paddingTop?: number;
}) => (
  <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}, verticalAlign}}>
    <TableCell
      component="th"
      scope="row"
      sx={{p: 0, py: 1, height: 61, pt: paddingTop}}
      width="30%"
    >
      <Typography variant="body1">{name}</Typography>
    </TableCell>
    <TableCell sx={{pr: 0, py: 1, pl: 1}}>
      <Typography variant="body1" component="div">
        {children}
      </Typography>
    </TableCell>
  </TableRow>
);

type DetailProps = {
  title?: ReactNode;
  description?: string;
  url?: string;
};

export const Detail = ({title, description, url}: DetailProps) => {
  if (!title) return null;
  return (
    <>
      <TextDetailTitle>{url ? <Link to={url}>{title}</Link> : title}</TextDetailTitle>
      {description && (
        <Typography variant="body2" color="secondary">
          {description}
        </Typography>
      )}
    </>
  );
};
