/* eslint-disable react-hooks/exhaustive-deps */
import {Box, Typography} from '@mui/material';
import {
  Application,
  ApplicationView,
  AsyncState,
  Firebase,
  PaginatedResponse,
  SearchableProfileView,
  SearchCriteria,
  useApiContainer,
} from '@ozark/common';
import {Card, InfiniteEntities, Loading, Title} from '@ozark/common/components';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {useLocation} from 'react-router-dom';
import {ApplicationAnalyticValueTypes} from '../../Dashboard/ApplicationAnalytics';

type LocationState = {
  title: string;
  type: ApplicationAnalyticValueTypes;
  startDate: Date | null;
  endDate: Date;
  profiles?: AsyncState<SearchableProfileView[]> & {
    dictionary: {
      [_: string]: SearchableProfileView;
    };
    byRole: {
      [_: string]: SearchableProfileView[];
    };
  };
};

const DefaultCriteria: SearchCriteria = {
  limit: 20, // page size
  offset: 1, // page
  order: 'desc',
  orderBy: 'dispositionUpdatedAt',
};

type DatabaseStoredTimestamp = {
  _seconds: number;
  _nanoseconds: number;
};
const mapApplicationToCard = (application: Application): ApplicationView => {
  const convertDatabaseStoredTimestamp = (timestamp: DatabaseStoredTimestamp) => {
    return Firebase.Timestamp.fromDate(new Date(timestamp._seconds * 1000));
  };

  return {
    ...application,
    dispositionMovedToAsAt: application.dispositionMovedToAsAt
      ? convertDatabaseStoredTimestamp(
          application.dispositionMovedToAsAt as unknown as DatabaseStoredTimestamp
        )
      : null,
    dispositionMovedToUwAt: application.dispositionMovedToUwAt
      ? convertDatabaseStoredTimestamp(
          application.dispositionMovedToUwAt as unknown as DatabaseStoredTimestamp
        )
      : null,
    boardedAt: application.boardedAt
      ? convertDatabaseStoredTimestamp(application.boardedAt as unknown as DatabaseStoredTimestamp)
      : undefined,
  } as ApplicationView;
};

const ApplicationAnalyticsDetails = () => {
  const history = useHistory();
  const api = useApiContainer();
  const location = useLocation<LocationState>();
  const [items, setItems] = useState<
    AsyncState<{
      items: ApplicationView[];
      hasNextPage: boolean;
    }>
  >({promised: true});

  useEffect(() => {
    // Redirect to Dashboard if the page opened directly (not from the Dashboard)
    if (!location.state) {
      history.push('/');
    }
  }, [location.state]);

  useEffect(() => {
    api?.dashboard
      .getApplicationAnalyticsDetails(
        {
          ...DefaultCriteria,
          orderBy:
            location.state.type === ApplicationAnalyticValueTypes.approved
              ? 'boardedAt'
              : 'dispositionUpdatedAt',
        },
        location.state.type,
        location.state.startDate,
        location.state.endDate
      )
      .then((result: PaginatedResponse<Application> | null) => {
        setItems({
          data: {
            items: result?.data.map(mapApplicationToCard) || [],
            hasNextPage: (result?.totalCount || 0) > DefaultCriteria.limit,
          },
          promised: false,
        });
      });
  }, [DefaultCriteria]);

  const loadApplications = (onLoaded?: () => void) => {
    if (items.promised) return;

    var nextPage = (items.data?.items.length || 0) / DefaultCriteria.limit + 1;
    api?.dashboard
      .getApplicationAnalyticsDetails(
        {
          ...DefaultCriteria,
          offset: nextPage,
        },
        location.state.type,
        location.state.startDate,
        location.state.endDate
      )
      .then((result: PaginatedResponse<Application> | null) => {
        const data = [
          ...(items.data?.items || []),
          ...(result?.data.map(mapApplicationToCard) || []),
        ];
        setItems({
          data: {
            items: data,
            hasNextPage: data.length < (result?.totalCount || 0),
          },
          promised: false,
        });
        onLoaded?.();
      });
  };

  if (items.promised) return <Loading />;

  return (
    <Box sx={{height: '100%', minHeight: '100%', display: 'flex', flexDirection: 'column'}}>
      <Title breadcrumbs={[<Typography>{location.state?.title}</Typography>]} />
      <InfiniteEntities
        data={items.data}
        itemSize={210}
        loadNextPage={loadApplications}
        onRender={document => (
          <Card
            application={document as ApplicationView}
            customizeTransferable
            profiles={location.state?.profiles}
          />
        )}
      />
    </Box>
  );
};

export default ApplicationAnalyticsDetails;
