import {useEffect, useRef} from 'react';

export const useHotKeyHolder = () => {
  const timerId = useRef<ReturnType<typeof setTimeout>>();
  const lastKeys = useRef<string>();

  const clear = () => {
    timerId.current = undefined;
    lastKeys.current = undefined;
  };

  useEffect(() => clear, []);

  const append = (key: string) => {
    clearTimeout(timerId.current);
    timerId.current = setTimeout(clear, 500);
    return (lastKeys.current = (lastKeys.current || '') + key);
  };
  return {
    append,
  };
};
