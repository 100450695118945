export const PortfolioACHIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g fill={'inherit'}>
        <path
          d="M48,512c-26.5,0-48-21.5-48-48l0,0V176c0-26.5,21.5-48,48-48h48v208c0,44.2,35.8,80,80,80h336v48
		c0,26.5-21.5,48-48,48l0,0H48z"
        />
        <g>
          <path
            opacity={0.4}
            d="M279.6,192.1c-11.9,0-13.1,4.3-13.1,13v19.6c0,3.4,2,5.4,5.4,5.4h15.5c3.6-0.2,5.4-2,5.4-5.6V205
			C292.7,196.4,291.5,192.1,279.6,192.1z"
          />
          <path
            opacity={0.4}
            d="M592,64H400L336,0H176c-26.5,0-48,21.5-48,48v288c0,26.5,21.5,48,48,48h416c26.5,0,48-21.5,48-48V112
			C640,85.5,618.5,64,592,64z M323.7,281.3c0,4-1.8,6.1-5.4,6.1h-20.2c-3.4,0-5.4-1.8-5.4-5.4v-15.7c0-3.6-1.8-5.4-5.4-5.4h-15.5
			c-3.4,0-5.4,1.8-5.4,5.4v15.7c0,3.6-1.8,5.4-5.4,5.4h-20.2c-3.4,0-5.4-2.2-5.4-6.1V205c0-22.7,3.6-46.8,44.1-46.8
			c40.5,0,44.1,24.1,44.1,46.8V281.3z M384.2,259.7c9.9,0,12.6-4.7,13.7-13c0.5-3.8,3.4-6.1,7-5.2l20.3,4.5c2.5,0.5,4.1,2.5,4,5.2
			c-0.7,13.7-6.7,39.4-45,39.4c-42.7,0-45.2-31.9-45.2-43.7v-45c0-11.9,2.5-43.7,45.2-43.7c38.3,0,44.3,25.7,45,39.6
			c0.2,2.5-1.4,4.5-4,5l-20,4.5c-3.8,0.7-6.7-1.8-7.2-5.6c-0.9-7.9-4.1-12.6-13.9-12.6c-13.3,0-14.2,8.5-14.2,22.3v25.9
			C370,251.3,370.9,259.7,384.2,259.7z M531.6,282.1c0,3.4-1.8,5.4-5.4,5.4H506c-3.4,0-5.4-2-5.4-5.4v-28.4c0-3.6-1.8-5.4-5.4-5.4
			H478c-3.4,0-5.4,1.8-5.4,5.4v28.4c0,3.4-1.8,5.4-5.4,5.4H447c-3.4,0-5.4-2-5.4-5.4V166.9c0-3.6,1.8-5.4,5.4-5.4h20.2
			c3.4,0,5.4,2,5.4,5.4v45c0,3.4,2,5.4,5.4,5.4h17.3c3.6,0,5.4-2,5.4-5.4v-45c0-3.4,2-5.4,5.4-5.4h20.2c3.6,0,5.4,1.8,5.4,5.4V282.1
			z"
          />
        </g>
        <g>
          <path
            d="M323.7,281.3c0,4-1.8,6.1-5.4,6.1h-20.2c-3.4,0-5.4-1.8-5.4-5.4v-15.7c0-3.6-1.8-5.4-5.4-5.4h-15.5
			c-3.4,0-5.4,1.8-5.4,5.4v15.7c0,3.6-1.8,5.4-5.4,5.4h-20.2c-3.4,0-5.4-2.2-5.4-6.1V205c0-22.7,3.6-46.8,44.1-46.8
			c40.5,0,44.1,24.1,44.1,46.8V281.3z M292.7,205c0-8.6-1.3-13-13.1-13c-11.9,0-13.1,4.3-13.1,13v19.6c0,3.4,2,5.4,5.4,5.4h15.5
			c3.6-0.2,5.4-2,5.4-5.6V205z"
          />
          <path
            d="M339,202c0-11.9,2.5-43.7,45.2-43.7c38.3,0,44.3,25.7,45,39.6c0.2,2.5-1.4,4.5-4,5l-20,4.5
			c-3.8,0.7-6.7-1.8-7.2-5.6c-0.9-7.9-4.1-12.6-13.9-12.6c-13.3,0-14.2,8.5-14.2,22.3v25.9c0,13.9,0.9,22.3,14.2,22.3
			c9.9,0,12.6-4.7,13.7-13c0.5-3.8,3.4-6.1,7-5.2l20.3,4.5c2.5,0.5,4.1,2.5,4,5.2c-0.7,13.7-6.7,39.4-45,39.4
			c-42.7,0-45.2-31.9-45.2-43.7V202z"
          />
          <path
            d="M467.2,161.5c3.4,0,5.4,2,5.4,5.4v45c0,3.4,2,5.4,5.4,5.4h17.3c3.6,0,5.4-2,5.4-5.4v-45c0-3.4,2-5.4,5.4-5.4
			h20.2c3.6,0,5.4,1.8,5.4,5.4v115.2c0,3.4-1.8,5.4-5.4,5.4H506c-3.4,0-5.4-2-5.4-5.4v-28.4c0-3.6-1.8-5.4-5.4-5.4H478
			c-3.4,0-5.4,1.8-5.4,5.4v28.4c0,3.4-1.8,5.4-5.4,5.4H447c-3.4,0-5.4-2-5.4-5.4V166.9c0-3.6,1.8-5.4,5.4-5.4H467.2z"
          />
        </g>
      </g>
    </svg>
  );
};
