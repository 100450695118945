import {useEffect, useState} from 'react';
import {AssociationType, Collections, Firebase, selectTicketView, TicketStatus} from '../../../..';
import {stateEmpty, statePromised} from '../../constants/constants';
import {useTicketsViewableBy} from './useTicketsViewableBy';

export function useTicketsAssociated(
  associationType: AssociationType,
  associationIdPath: string,
  associationIdValue?: string,
  statuses?: TicketStatus[]
) {
  const {filterOutInternalTickets} = useTicketsViewableBy();
  const [ticketsAssociated, setTicketsAssociated] = useState(statePromised);

  useEffect(() => {
    if (!associationIdPath || !associationIdValue || (statuses && statuses.length === 0)) {
      setTicketsAssociated(stateEmpty);
      return;
    }
    setTicketsAssociated(statePromised);

    let query = Firebase.firestore
      .collection(Collections.tickets)
      .where('status', '!=', TicketStatus.Draft)
      .where('association.type', '==', associationType)
      .where(associationIdPath, '==', associationIdValue);

    if (statuses && statuses.length > 0) {
      query = query.where('status', 'in', statuses);
    }

    return query.onSnapshot(
      async snapshot => {
        if (snapshot.size === 0) {
          setTicketsAssociated(stateEmpty);
          return;
        }
        const tickets = filterOutInternalTickets(snapshot.docs.map(selectTicketView));
        setTicketsAssociated({promised: false, data: tickets});
      },
      err => {
        console.error(err);
        setTicketsAssociated({promised: false, error: err});
      }
    );
  }, [associationType, associationIdPath, statuses, filterOutInternalTickets]);

  return {
    ticketsAssociated,
  };
}
