import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CancelIcon from '@mui/icons-material/Cancel';
import CreateIcon from '@mui/icons-material/Create';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import {Box, Button, Step, StepConnector, StepIconProps, StepLabel, Stepper} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {
  Dispositions,
  MerchantApplicationStatus,
  useDocumentsUploadLink,
  useNotification,
} from '@ozark/common';
import {useMemo} from 'react';
import {useStore} from '../../../store/helpers';

const CustomStepIcon = (icon: JSX.Element) => (props: StepIconProps) => {
  const {active, completed, className} = props;
  return (
    <Box
      className={className}
      sx={[
        {
          width: 50,
          height: 50,
          backgroundColor: '#f5fafc',
          color: '#b2c2cd',
          display: 'flex',
          borderRadius: '50%',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1,
        },
        Boolean(active || completed) && {
          color: theme => theme.palette.common.white,
          backgroundColor: theme => theme.palette.primary.main,
        },
      ]}
    >
      {icon}
    </Box>
  );
};

type Props = {
  applicationId: string;
  merchantApplicationStatus: MerchantApplicationStatus;
};

export const ApplicationSteps = ({
  applicationId,
  merchantApplicationStatus,
}: Props): JSX.Element => {
  const {authProfile} = useStore();
  const showNotification = useNotification();
  const {copyUploadLink} = useDocumentsUploadLink();

  const respondPendingReasons = async () => {
    const link = await copyUploadLink(
      applicationId,
      Dispositions.uwPending,
      'merchant',
      authProfile?.data?.id
    );

    if (link === undefined) {
      showNotification('error', 'Failed to get documents upload token');
      return;
    }

    window.open(link, '_blank', 'noopener');
  };

  const steps = useMemo(
    () => STEPS_CONFIG[merchantApplicationStatus]?.steps ?? DEFAULT_STEPS,
    [merchantApplicationStatus]
  );
  const activeStep = useMemo(
    () => steps.findIndex(step => step.label === merchantApplicationStatus),
    [merchantApplicationStatus, steps]
  );

  return (
    <Stepper sx={{py: 2}} alternativeLabel activeStep={activeStep} connector={<CustomConnector />}>
      {steps.map(step => (
        <Step key={step.label}>
          <>
            <StepLabel StepIconComponent={CustomStepIcon(step.icon)}>{step.label}</StepLabel>
            {merchantApplicationStatus === MerchantApplicationStatus.pending &&
              step.label === PENDING_STEP.label && (
                <Box sx={{py: 1, textAlign: 'center'}}>
                  <Button
                    color="primary"
                    size="medium"
                    variant="contained"
                    onClick={async () => await respondPendingReasons()}
                  >
                    Respond
                  </Button>
                </Box>
              )}
          </>
        </Step>
      ))}
    </Stepper>
  );
};

const CustomConnector = withStyles(theme => ({
  alternativeLabel: {
    top: 25,
    backgroundColor: '#f5fafc',
  },
  active: {
    '& $line': {
      backgroundColor: `${theme.palette.primary.main}`,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: `${theme.palette.primary.main}`,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#f5fafc',
    borderRadius: 1,
  },
}))(StepConnector);

const INCOMPLETE_STEP = {
  label: MerchantApplicationStatus.incomplete,
  icon: <CreateIcon />,
};

const UNDER_REVIEW_STEP = {
  label: MerchantApplicationStatus.underReview,
  icon: <AssignmentTurnedInIcon />,
};

const PENDING_STEP = {
  label: MerchantApplicationStatus.pending,
  icon: <PauseCircleFilledIcon />,
};

const DECLINED_STEP = {
  label: MerchantApplicationStatus.declined,
  icon: <CancelIcon />,
};

const WITHDRAWN_STEP = {
  label: MerchantApplicationStatus.withdrawn,
  icon: <CancelIcon />,
};

const APPROVED_STEP = {
  label: MerchantApplicationStatus.approved,
  icon: <ThumbUpAltIcon />,
};

const READY_TO_PROCESS_STEP = {
  label: MerchantApplicationStatus.readyToProcess,
  icon: <RocketLaunchIcon />,
};

const SIGNATURE_NEEDED_STEP = {
  label: MerchantApplicationStatus.signatureNeeded,
  icon: <CreateIcon />,
};

const DEFAULT_STEPS = [INCOMPLETE_STEP, UNDER_REVIEW_STEP, APPROVED_STEP, READY_TO_PROCESS_STEP];

const STEPS_CONFIG = {
  [MerchantApplicationStatus.incomplete]: {
    steps: DEFAULT_STEPS,
  },
  [MerchantApplicationStatus.signatureNeeded]: {
    steps: [SIGNATURE_NEEDED_STEP, UNDER_REVIEW_STEP, APPROVED_STEP, READY_TO_PROCESS_STEP],
  },
  [MerchantApplicationStatus.pending]: {
    steps: [INCOMPLETE_STEP, PENDING_STEP, APPROVED_STEP, READY_TO_PROCESS_STEP],
  },
  [MerchantApplicationStatus.underReview]: {
    steps: DEFAULT_STEPS,
  },
  [MerchantApplicationStatus.declined]: {
    steps: [INCOMPLETE_STEP, UNDER_REVIEW_STEP, DECLINED_STEP, READY_TO_PROCESS_STEP],
  },
  [MerchantApplicationStatus.withdrawn]: {
    steps: [INCOMPLETE_STEP, UNDER_REVIEW_STEP, WITHDRAWN_STEP, READY_TO_PROCESS_STEP],
  },
  [MerchantApplicationStatus.approved]: {
    steps: DEFAULT_STEPS,
  },
  [MerchantApplicationStatus.readyToProcess]: {
    steps: DEFAULT_STEPS,
  },
};
