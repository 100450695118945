type Options = {
  backgroundColor: string;
  color: string;
  size: number;
  position: 'n' | 'e' | 's' | 'w' | 'ne' | 'nw' | 'se' | 'sw';
  radius: number;
  src: string;
  onChange: () => void;
};

const DEFAULT_OPTIONS: Options = {
  backgroundColor: '#f00',
  color: '#fff',
  size: 0.6, // 0..1 (Scale in respect to the favicon image size)
  position: 'ne', // Position inside favicon "n", "e", "s", "w", "ne", "nw", "se", "sw"
  radius: 125, // Border radius
  src: '', // Favicon source (dafaults to the <link> icon href)
  onChange() {},
};

/**
 * Add notification badge (pill) to favicon in browser tab
 * @url stackoverflow.com/questions/65719387/
 */
export class FaviconBadger {
  private canvas: HTMLCanvasElement;
  readonly ctx: CanvasRenderingContext2D | null;
  private faviconSize?: number;
  private img?: HTMLImageElement;
  private offset?: {x: number; y: number};
  private badgeSize: any;
  readonly size: Options['size'];
  readonly position: Options['position'];
  readonly color: Options['color'];
  readonly radius: Options['radius'];
  readonly backgroundColor: Options['backgroundColor'];
  readonly src: Options['src'];
  readonly onChange: Options['onChange'];
  private _value?: string | number;

  constructor(options: Partial<Options> = {}) {
    const {backgroundColor, size, color, src, radius, position, onChange} = {
      ...DEFAULT_OPTIONS,
      ...options,
    };
    this.backgroundColor = backgroundColor;
    this.size = size;
    this.color = color;
    this.src = src;
    this.radius = radius;
    this.position = position;
    this.onChange = onChange;

    const faviconHref = this.faviconEL?.getAttribute('href');
    if (!this.src && faviconHref) {
      this.src = faviconHref;
    }

    this.canvas = document.createElement('canvas');
    this.ctx = this.canvas.getContext('2d');
  }

  faviconEL = document.querySelector('link[rel$=icon]');

  private _drawIcon() {
    if (!this.img || !this.faviconSize) {
      return;
    }

    this.ctx?.clearRect(0, 0, this.faviconSize, this.faviconSize);
    this.ctx?.drawImage(this.img, 0, 0, this.faviconSize, this.faviconSize);
  }

  private drawShape() {
    if (!this.offset) {
      return;
    }

    const valueLength = this.value?.toString().length ?? 1;

    const r = this.radius;
    const xa = this.offset.x - (valueLength - 1) * 30;
    const ya = this.offset.y;
    const xb = this.offset.x + this.badgeSize + (valueLength - 1) * 30;
    const yb = this.offset.y + this.badgeSize;
    if (!this.ctx) {
      return;
    }

    this.ctx.beginPath();
    this.ctx.moveTo(xb - r, ya);
    this.ctx.quadraticCurveTo(xb, ya, xb, ya + r);
    this.ctx.lineTo(xb, yb - r);
    this.ctx.quadraticCurveTo(xb, yb, xb - r, yb);
    this.ctx.lineTo(xa + r, yb);
    this.ctx.quadraticCurveTo(xa, yb, xa, yb - r);
    this.ctx.lineTo(xa, ya + r);
    this.ctx.quadraticCurveTo(xa, ya, xa + r, ya);
    this.ctx.fillStyle = this.backgroundColor;
    this.ctx.fill();
    this.ctx.closePath();
  }

  private drawVal() {
    if (!this.ctx || !this.value || !this.offset) {
      return;
    }

    const margin = (this.badgeSize * 0.15) / 2;
    this.ctx.beginPath();
    this.ctx.textBaseline = 'middle';
    this.ctx.textAlign = 'center';
    this.ctx.font = `bold ${this.badgeSize * 0.7}px Arial`;
    this.ctx.fillStyle = this.color;
    this.ctx.fillText(
      this.value as string,
      this.badgeSize / 2 + this.offset.x,
      this.badgeSize / 2 + this.offset.y + margin
    );
    this.ctx.closePath();
  }

  private drawFavicon() {
    this.faviconEL?.setAttribute('href', this.dataURL);
  }

  private draw() {
    this._drawIcon();
    if (this.value) this.drawShape();
    if (this.value) this.drawVal();
    this.drawFavicon();
  }

  private setup() {
    if (!this.img) {
      return;
    }

    this.faviconSize = this.img?.naturalWidth;
    this.badgeSize = this.faviconSize * this.size;
    this.canvas.width = this.faviconSize;
    this.canvas.height = this.faviconSize;
    const sd = this.faviconSize - this.badgeSize;
    const sd2 = sd / 2;
    this.offset = {
      n: {x: sd2, y: 0},
      e: {x: sd, y: sd2},
      s: {x: sd2, y: sd},
      w: {x: 0, y: sd2},
      nw: {x: 0, y: 0},
      ne: {x: sd, y: 0},
      sw: {x: 0, y: sd},
      se: {x: sd, y: sd},
    }[this.position];
  }

  // Public functions / methods:

  update() {
    const parsedValue = parseInt(this._value as string, 10);
    this._value = Math.min(99, parsedValue);

    if (this.img) {
      this.draw();
      if (this.onChange) this.onChange.call(this);
    } else {
      this.img = new Image();
      this.img.addEventListener('load', () => {
        this.setup();
        this.draw();
        if (this.onChange) this.onChange.call(this);
      });
      this.img.src = this.src;
    }
  }

  get dataURL() {
    return this.canvas.toDataURL();
  }

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.update();
  }
}
