import LocationOnIcon from '@mui/icons-material/LocationOn';
import {Box, TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import React from 'react';
import {Controller} from 'react-hook-form';

interface Props {
  setAutofillHandler(city?: string, state?: string, zip?: string): void;
  name: string;
  label: string;
  defaultValue?: string;
  required?: boolean;
  errors: any;
  control: any;
  inputRef?: any;
  autoFocus?: boolean;
  disabled?: boolean;
  [others: string]: any;
}

const config = {
  apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
};

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

interface AutoCompleteState {
  autoCompleteService: google.maps.places.AutocompleteService | null;
  placesService: google.maps.places.PlacesService | null;
}

const state: AutoCompleteState = {
  autoCompleteService: null,
  placesService: null,
};

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export const AutoCompleteAddressTextField = ({
  setAutofillHandler: handleAutofill,
  control,
  label,
  name,
  required,
  errors,
  inputRef,
  disabled,
  helperText,
}: Props) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<google.maps.places.AutocompletePrediction[]>([]);
  const loaded = React.useRef(false);
  const mapAlreadyLoaded = document.querySelector("[src*='maps.googleapis.com']");
  const sessionToken = React.useRef<google.maps.places.AutocompleteSessionToken | null>(null);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps') && !mapAlreadyLoaded) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${config.apiKey}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle(
        (result, callback) => state.autoCompleteService?.getPlacePredictions(result, callback),
        200
      ),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!state.autoCompleteService && window.google) {
      if (window.google.maps.places) {
        state.autoCompleteService = new google.maps.places.AutocompleteService();
        // PlacesService's constructor requires an HTMLDivElement, creating one to satisfy constructor
        const element = document.createElement('div');
        state.placesService = new google.maps.places.PlacesService(element);
      } else {
        console.error('Places are not loaded in Google Maps API');
      }
    }

    if (!state.autoCompleteService) {
      return undefined;
    }

    if (!sessionToken.current) {
      sessionToken.current = new google.maps.places.AutocompleteSessionToken();
    }

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        types: ['address'],
        sessionToken: sessionToken.current,
        componentRestrictions: {country: 'us'},
      },
      (
        results: google.maps.places.AutocompletePrediction[],
        status: google.maps.places.PlacesServiceStatus
      ) => {
        if (active && status === google.maps.places.PlacesServiceStatus.OK) {
          setOptions(results);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  const retrievePlaceDetail: (
    placeId: string
  ) => Promise<google.maps.places.PlaceResult | null> = placeId => {
    const request: google.maps.places.PlaceDetailsRequest = {
      placeId: placeId,
      fields: ['address_component'],
      sessionToken: sessionToken.current ? sessionToken.current : undefined,
    };
    return new Promise(resolve => state.placesService?.getDetails(request, resolve));
  };

  const retrieveDetailsAndAutofill = async (
    prediction: google.maps.places.AutocompletePrediction
  ) => {
    const placeDetail = await retrievePlaceDetail(prediction.place_id);

    // The session begins when a user starts typing a query, and concludes
    // when they select a place and a call to get Place Details is made. Once a session has concluded,
    // the token is no longer valid; your app must generate a fresh token for each session.
    // https://developers.google.com/places/web-service/session-tokens
    sessionToken.current = new google.maps.places.AutocompleteSessionToken();

    if (!placeDetail || !placeDetail.address_components) {
      return;
    }

    const parsedResult: {city?: string; zip?: string; state?: string} = {};

    for (let address_component of placeDetail.address_components) {
      if (
        address_component.types.includes('locality') ||
        address_component.types.includes('sublocality')
      ) {
        parsedResult.city = address_component.long_name;
      }
      if (address_component.types.includes('postal_code')) {
        parsedResult.zip = address_component.long_name;
      }
      //* Within the United States, these administrative levels are states
      if (address_component.types.includes('administrative_area_level_1')) {
        parsedResult.state = address_component.short_name;
      }
    }

    handleAutofill(parsedResult.city, parsedResult.state, parsedResult.zip);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{required: required}}
      defaultValue=""
      render={({field}) => (
        <Autocomplete
          id={name}
          freeSolo
          getOptionLabel={option =>
            typeof option === 'string' ? option : option.structured_formatting.main_text
          }
          isOptionEqualToValue={(option, value) =>
            typeof option !== 'string' && typeof value !== 'string'
              ? option.place_id === value.place_id
              : option === value
          }
          filterOptions={x => x}
          options={options}
          disabled={disabled}
          autoComplete
          autoSelect
          autoHighlight
          includeInputInList
          filterSelectedOptions
          {...field}
          onChange={(
            _event: any,
            newValue: string | google.maps.places.AutocompletePrediction | null
          ) => {
            if (newValue && !(newValue instanceof String)) {
              let prediction = newValue as google.maps.places.AutocompletePrediction;
              field.onChange(prediction.structured_formatting.main_text);
              retrieveDetailsAndAutofill(prediction);
            }
          }}
          onInputChange={(_event, newInputValue) => {
            field.onChange(newInputValue);
            setInputValue(newInputValue);
          }}
          renderInput={params => (
            <TextField
              {...params}
              name={name}
              label={label}
              margin="normal"
              inputRef={field.ref}
              error={Boolean(errors[name])}
              helperText={errors[name]?.message || helperText}
              variant="outlined"
              fullWidth
            />
          )}
          renderOption={(props, option) => {
            const matches =
              typeof option !== 'string'
                ? option.structured_formatting.main_text_matched_substrings
                : [option];
            const parts = parse(
              typeof option !== 'string' ? option.structured_formatting.main_text : option,
              matches.map((match: any) => [match.offset, match.offset + match.length])
            );
            return (
              <Box
                component="li"
                {...props}
                key={typeof option !== 'string' ? option.place_id : option}
              >
                <Grid container alignItems="center">
                  <Grid item>
                    <LocationOnIcon className={classes.icon} />
                  </Grid>
                  <Grid item xs>
                    {parts.map((part, index) => (
                      <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>
                        {part.text}
                      </span>
                    ))}
                    <Typography variant="body2" color="textSecondary">
                      {typeof option !== 'string'
                        ? option.structured_formatting.secondary_text
                        : option}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            );
          }}
        />
      )}
    ></Controller>
  );
};
