import {
  AsyncState,
  Collections,
  Firebase,
  getEnumKeyByValue,
  GroupRateProgram,
  ProcessingTypes,
  RateProgram,
  selectRateProgramView,
  UniversalSnapshot,
} from '@ozark/common';
import {groupBy} from '@s-libs/micro-dash';
import {useCallback, useEffect, useState} from 'react';

const getRateSetByProcessingType = (
  ratePrograms: AsyncState<GroupRateProgram[] | RateProgram[]>,
  processingType: ProcessingTypes,
  programName?: string,
  programId?: string
) => {
  const program =
    ratePrograms.data &&
    ratePrograms.data!.find(
      (x: GroupRateProgram | RateProgram) => x.name === programName || x.id === programId
    );
  const processingTypeValue = getEnumKeyByValue(ProcessingTypes, processingType);
  const rateSet =
    program &&
    Object.entries(program!.processingTypes)
      .filter(([key, value]) => key === processingTypeValue)
      .map(([key, value]) => value)[0];
  return rateSet;
};

const mapSnapshotToPrograms = (
  snapshot: firebase.default.firestore.QuerySnapshot<FirebaseFirestore.DocumentData>
) => {
  const ratePrograms = snapshot.docs.map(doc =>
    selectRateProgramView(doc as UniversalSnapshot<RateProgram>)
  );

  const groupedByProgramName = groupBy(ratePrograms, e => e.name);

  const globalPrograms = Object.keys(groupedByProgramName).reduce((pv: any, key) => {
    // order by version desc:
    return [...pv, groupedByProgramName[key].sort((a, b) => b.version - a.version)[0]];
  }, []);

  return globalPrograms;
};

export const useRatePrograms = () => {
  const [ratePrograms, setRatePrograms] = useState<AsyncState<RateProgram[]>>({
    promised: true,
  });

  const fetchGlobalPrograms = useCallback(async () => {
    Firebase.firestore
      .collection(Collections.programs)
      .where('active', '==', true)
      .onSnapshot(
        async snapshot => {
          if (snapshot.empty) {
            setRatePrograms({promised: false});
            return;
          }

          const globalPrograms = mapSnapshotToPrograms(snapshot);
          setRatePrograms({promised: false, data: globalPrograms});
        },
        err => {
          console.error(err);
          setRatePrograms({promised: false, error: err});
        }
      );
  }, []);

  useEffect(() => {
    fetchGlobalPrograms();
  }, [fetchGlobalPrograms]);

  return {ratePrograms, getRateSetByProcessingType, mapSnapshotToPrograms};
};
