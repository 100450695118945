import Link from '@mui/material/Link';
import {ReactNode} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Option} from './types';

type Props = {
  linkRoute: Option['linkRoute'];
  children: JSX.Element | ReactNode;
};

export const SearchOptionLinkWrapper = ({linkRoute, children}: Props): JSX.Element => {
  if (!linkRoute) {
    return <>{children}</>;
  }

  return (
    <Link
      to={linkRoute}
      component={RouterLink}
      sx={{color: 'inherit', flexGrow: 1, mx: -2, px: 2}}
      onClick={e => {
        if (e.ctrlKey || e.metaKey) {
          e.stopPropagation();
        }
      }}
    >
      {children}
    </Link>
  );
};
