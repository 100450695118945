import {Button, Menu, MenuItem, Tooltip} from '@mui/material';
import React, {Fragment, useState} from 'react';
import {ListOption} from '../../../models';

interface Props {
  onAddLineItem: (revShare: string) => Promise<void> | void;
  options: ListOption[];
  handleOpen?: () => Promise<void>;
  loading?: boolean;
}

export const CreateManualAdjustmentBtn = ({onAddLineItem, options, handleOpen, loading}: Props) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
    if (handleOpen) {
      handleOpen();
    }
  };

  const onSelect = async (val: string) => {
    if (loading === true) {
      return;
    }
    // save value and close the editor
    handleMenuClose();
    await onAddLineItem(val);
  };

  const getOptions = () => {
    const getMenuItem = (key: string, value: string, text: string) => (
      <MenuItem key={key} value={value} onClick={() => onSelect(value)}>
        {text}
      </MenuItem>
    );

    if (loading === true) {
      return [getMenuItem('Loading', 'Loading...', 'Loading...')];
    }

    return options.map(opt => getMenuItem(opt.key, opt.value, `Rev Share: ${opt.value}`));
  };

  return (
    <Fragment>
      <Tooltip title="Add New Manual Adjustment">
        <Button
          sx={{marginLeft: 'auto'}}
          aria-label="Add Adjustments"
          variant="outlined"
          onClick={handleOnClick}
          size="large"
        >
          Add Manual Adjustment
        </Button>
      </Tooltip>
      <Menu
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        {getOptions()}
      </Menu>
    </Fragment>
  );
};
