import {useEffect, useState} from 'react';
import {Collections, Firebase, selectTicketView, TicketStatus} from '../../../..';
import {useUserInfo} from '../../../../hooks/useUserInfo';
import {stateEmpty, statePromised} from '../../constants/constants';
import {useTicketsViewableBy} from './useTicketsViewableBy';

export function useTicketsMyGroup(statusSet: TicketStatus[]) {
  const {userGroupId} = useUserInfo();
  const [ticketsMyGroup, setTicketsMyGroup] = useState(statePromised);
  const {filterOutInternalTickets} = useTicketsViewableBy();

  useEffect(() => {
    setTicketsMyGroup(statePromised);
    return Firebase.firestore
      .collection(Collections.tickets)
      .where('status', 'in', statusSet)
      .where('associatedGroups', 'array-contains', userGroupId)
      .onSnapshot(
        async snapshot => {
          if (snapshot.size === 0) {
            setTicketsMyGroup(stateEmpty);
            return;
          }
          const tickets = filterOutInternalTickets(snapshot.docs.map(selectTicketView));
          setTicketsMyGroup({promised: false, data: tickets});
        },
        err => {
          console.error(err);
          setTicketsMyGroup({promised: false, error: err});
        }
      );
  }, [statusSet, filterOutInternalTickets]);

  return {
    ticketsMyGroup,
  };
}
