import {Box, ToggleButton, ToggleButtonGroup} from '@mui/material';
import {useState} from 'react';
import {useTicketsFiltersContainer} from '../hooks/useTicketsFiltersContainer';

enum ViewTypeEnum {
  My = 'my',
  All = 'all',
}

type Props = {
  children?: React.ReactNode;
};

export const TicketsListViewSelector = ({children}: Props) => {
  const {isAllTicketsView, setIsAllTicketsView} = useTicketsFiltersContainer();
  const [viewType, setViewType] = useState(isAllTicketsView ? ViewTypeEnum.All : ViewTypeEnum.My);

  const handleChange = (event: React.MouseEvent<HTMLElement>, newViewType: ViewTypeEnum | null) => {
    if (newViewType !== null) {
      setViewType(newViewType);
      setIsAllTicketsView(newViewType === ViewTypeEnum.All ? true : false);
    }
  };

  return (
    <Box display="flex" alignItems="center" minHeight={64}>
      <ToggleButtonGroup
        color="primary"
        value={viewType}
        exclusive
        onChange={handleChange}
        sx={{minWidth: 220}}
      >
        <ToggleButton value={ViewTypeEnum.My}>My Tickets</ToggleButton>
        <ToggleButton value={ViewTypeEnum.All}>All Tickets</ToggleButton>
      </ToggleButtonGroup>
      {children}
    </Box>
  );
};
