import {createNanoEvents} from 'nanoevents';

export interface EventData {}
export type Unsubscribe = () => void;
export type EventHandler<T extends EventData> = (data: T) => void;
export type EventSubscriber<T extends EventData> = (cb: EventHandler<T>) => Unsubscribe;
export type EventEmitter<T> = (data: T) => void;

export type EventPair<T extends EventData> = [EventSubscriber<T>, EventEmitter<T>];

/*
Usage example:

const [on, emit] = event<string>()

const unsub = on( s=>{
  console.log('got string', s)
})
emit('hello')
unsub() // Unsubscribe, stop listening

*/
export default function event<T extends EventData = any | undefined>(): EventPair<T> {
  const emitter = createNanoEvents();
  return [
    (callback: EventHandler<T>): Unsubscribe => {
      const unbind = emitter.on('event', callback);
      return unbind;
    },
    (data: T) => {
      emitter.emit('event', data);
    },
  ];
}
