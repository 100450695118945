export const TransactionsPendingIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g>
        <path d="M496,192.3c0.1,0,0.1,0,0.2,0c5.3,0,10.6,0.3,15.8,0.7v-34.8H0V272h348.9C380.2,224,434.4,192.3,496,192.3z" />
        <g>
          <path
            opacity={0.4}
            d="M320.3,368c0-35.4,10.5-68.4,28.5-96H0v156.4c0,23.6,19.1,42.7,42.7,42.7h311.1
			C332.7,442.2,320.3,406.5,320.3,368z M142.2,403.6c0,5.9-4.8,10.7-10.7,10.7h-64c-5.9,0-10.7-4.8-10.7-10.7V368
			c0-5.9,4.8-10.7,10.7-10.7h64c5.9,0,10.7,4.8,10.7,10.7V403.6z M312.9,403.6c0,5.9-4.8,10.7-10.7,10.7H181.3
			c-5.9,0-10.7-4.8-10.7-10.7V368c0-5.9,4.8-10.7,10.7-10.7h120.9c5.9,0,10.7,4.8,10.7,10.7L312.9,403.6L312.9,403.6z"
          />
          <path
            opacity={0.4}
            d="M512,115.6c0-23.6-19.1-42.7-42.7-42.7H42.7C19.1,72.9,0,92,0,115.6v42.7h512V115.6z"
          />
        </g>
      </g>
      <path
        d="M560,374.3c0,5.4-4.4,9.7-9.7,9.7h-60.6c-5.4,0-9.7-4.4-9.7-9.7v-76.6c0-5.4,4.4-9.7,9.7-9.7h12.6
	c5.4,0,9.7,4.4,9.7,9.7V352h38.3c5.4,0,9.7,4.4,9.7,9.7V374.3z"
      />
      <g>
        <path
          opacity={0.4}
          d="M496,224c-79.5,0-144,64.5-144,144s64.5,144,144,144s144-64.5,144-144s-64.4-144-143.8-144
		C496.1,224,496.1,224,496,224z M560,374.3c0,5.4-4.4,9.7-9.7,9.7h-60.6c-5.4,0-9.7-4.4-9.7-9.7v-76.6c0-5.4,4.4-9.7,9.7-9.7h12.6
		c5.4,0,9.7,4.4,9.7,9.7V352h38.3c5.4,0,9.7,4.4,9.7,9.7V374.3z"
        />
      </g>
    </svg>
  );
};
