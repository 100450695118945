import {Box, Grid, Typography} from '@mui/material';
import {
  ButtonExportCsv,
  mccsBySalesColumnsConfig,
  MccsBySalesPie,
  MccsBySalesTable,
} from '@ozark/common/components';
import {MccInfo} from '@ozark/functions/src/functions/express/private/types/Reports';
import {ReactNode, useEffect, useState} from 'react';
import {useApiContainer} from '../../..';
import {LoadingStatus} from '../common/LoadingStatus';
import {ReportingMonthTitle} from '../common/ReportingMonthTitle';
import {ReportingPaper} from '../common/ReportingPaper';
import {useQueryMonthYear} from '../common/useQueryMonthYear';

const TITLE = "Top 10 MCC's by Sales";

export const MccsBySalesPage = () => {
  const api = useApiContainer();
  const {year, month} = useQueryMonthYear();
  const [data, setData] = useState<MccInfo[]>([]);
  const [loading, setLoading] = useState(true);
  const hasData = data?.length > 0;
  const breadcrumbs: ReactNode[] = [<Typography variant="body1">{TITLE}</Typography>];

  useEffect(() => {
    if (Boolean(year && month)) {
      setLoading(true);
      api?.reports
        .getMccsBySales(year, month)
        .then(result => {
          setLoading(false);
          setData(result || []);
        })
        .catch(err => {
          setLoading(false);
          console.error(err);
        });
    }
  }, [year, month]);

  return (
    <>
      <ReportingMonthTitle breadcrumbs={breadcrumbs}>
        <ButtonExportCsv
          columnsConfig={mccsBySalesColumnsConfig}
          disabled={loading}
          filename="mccs-by-sales"
          rows={data}
        />
      </ReportingMonthTitle>

      {(loading || !hasData) && <LoadingStatus loading={loading} hasData={hasData} />}

      {!loading && hasData && (
        <ReportingPaper>
          <LoadingStatus loading={loading} hasData={hasData} />
          {!loading && hasData && (
            <Grid item container>
              <Grid item lg={12} xl={7}>
                <MccsBySalesTable data={data} />
              </Grid>
              <Grid item lg={12} xl={5}>
                <Box my={1} height={550}>
                  <MccsBySalesPie data={data} />
                </Box>
              </Grid>
            </Grid>
          )}
        </ReportingPaper>
      )}
    </>
  );
};
