import {Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useWatch} from 'react-hook-form';
import {
  AssigneeType,
  AssociationType,
  TicketAssignee,
  TicketAssociation,
  ViewableByType,
} from '../../../..';
import {getApplicationByMid} from '../../../../firebase/queries/applications';
import {useUserInfo} from '../../../../hooks';
import {
  FIELD_NAME_ASSIGNEE,
  FIELD_NAME_ASSOCIATION,
  FIELD_NAME_VIEWABLE_BY,
} from '../../constants/constants';
import {useTicketId} from '../../hooks/useTicketId';
import {useTicketUpdate} from '../../TicketEdit/useTicketUpdate';
import {InputAssigneeError} from '../../types';
import {validateAssignee} from '../../utils/validation';
import {InputAssigneeType} from './AssigneeEditorType';
import {AssigneeEditorValueErpDepartment} from './AssigneeEditorValueErpDepartment';
import {AssigneeEditorValueErpUser} from './AssigneeEditorValueErpUser';
import {AssigneeEditorValueGroupAgent} from './AssigneeEditorValueGroupAgent';
import {AssigneeEditorValueMerchant} from './AssigneeEditorValueMerchant';

interface AssigneeEditorDialogProps {
  warningMessage?: string;
  setOpen: (open: boolean) => void;
  open: boolean;
}

export const AssigneeEditorDialog = ({
  warningMessage,
  open,
  setOpen,
}: AssigneeEditorDialogProps) => {
  // draft state for validation
  const association: TicketAssociation = useWatch({name: FIELD_NAME_ASSOCIATION});
  const assignee = useWatch({name: FIELD_NAME_ASSIGNEE});
  const viewableByWatch = useWatch({name: FIELD_NAME_VIEWABLE_BY});
  const [draft, setDraft] = useState<TicketAssignee | undefined>(assignee);
  const [viewableBy, setViewableBy] = useState<ViewableByType | undefined>(viewableByWatch);

  const {ticketId} = useTicketId();

  const {isAgentAdmin, isAgentMember, isErpUser, isMerchant, uid, userGroupId} = useUserInfo();

  const {updateAssignee, updateAssigneeToMe} = useTicketUpdate(ticketId);

  const handleClose = () => {
    setOpen(false);
    setDraft(assignee); // reset draft
  };

  const [assigneeErrors, setAssigneeErrors] = useState({} as Record<InputAssigneeError, string>);

  const handleSubmit = useCallback(async () => {
    if (validateAssignee(draft, setAssigneeErrors)) {
      await updateAssignee(draft);
      setOpen(false);
    }
  }, [draft, setAssigneeErrors, setOpen, updateAssignee]);

  const handleAssignToMe = useCallback(async () => {
    await updateAssigneeToMe(draft);

    setOpen(false);
  }, [draft, setOpen, updateAssigneeToMe]);

  useEffect(() => {
    setDraft(assignee);
  }, [assignee]);

  useEffect(() => {
    setViewableBy(viewableByWatch);
  }, [viewableByWatch]);

  useEffect(() => {
    if (!isErpUser) {
      return;
    }

    if (viewableBy === ViewableByType.internalOnly) {
      return;
    }

    if (association?.type !== AssociationType.merchant) {
      return;
    }

    if (!association?.merchant?.mid) {
      return;
    }

    if (draft?.type !== AssigneeType.agent && draft?.type !== AssigneeType.merchant) {
      return;
    }

    const agentUid = isAgentMember ? uid : undefined;
    const groupUid = isAgentAdmin ? userGroupId : undefined;

    const setAssigneeInfoFromApplication = async () => {
      const app = await getApplicationByMid(association.merchant!.mid, agentUid, groupUid);

      if (draft?.type === AssigneeType.agent) {
        setDraft({
          type: draft.type,
          agent: app?.agent
            ? {
                id: app.agent.id,
                name: `${app.agent.firstName} ${app.agent.lastName}`,
              }
            : undefined,
          group: app?.group
            ? {
                id: app.group.id,
                name: app.group.name,
              }
            : undefined,
        });
        return;
      }

      if (
        draft?.type === AssigneeType.merchant &&
        !!app?.merchantUid &&
        !!app.group &&
        !!app.doingBusinessAs &&
        app.mid
      ) {
        setDraft({
          type: draft.type,
          merchant: {
            dba: app.doingBusinessAs,
            id: app.merchantUid,
            mid: app.mid,
          },
          group: {
            id: app.group.id,
            name: app.group.name,
          },
        });
        return;
      }
    };

    setAssigneeInfoFromApplication();
  }, [isErpUser, viewableBy, association?.type, association?.merchant?.mid, draft?.type]);

  const allowAssignToMe = useMemo(
    () =>
      !draft?.type ||
      draft?.type === AssigneeType.erpDepartment ||
      // allow Assign To Me for all ERP users
      (isErpUser && uid && draft.erpUser?.id !== uid),
    [draft, uid, isErpUser]
  );

  return (
    <Dialog open={open} onClose={handleClose}>
      {warningMessage && <Alert severity="warning">{warningMessage}</Alert>}

      <DialogTitle>Assignee</DialogTitle>
      <DialogContent>
        <InputAssigneeType
          draft={draft}
          setDraft={setDraft}
          errors={assigneeErrors}
          setErrors={setAssigneeErrors}
          viewableBy={viewableBy}
        />

        {draft?.type === AssigneeType.erpDepartment && (
          <AssigneeEditorValueErpDepartment
            draft={draft}
            setDraft={setDraft}
            errors={assigneeErrors}
            setErrors={setAssigneeErrors}
          />
        )}

        {draft?.type === AssigneeType.erpUser && (
          <AssigneeEditorValueErpUser
            draft={draft}
            setDraft={setDraft}
            errors={assigneeErrors}
            setErrors={setAssigneeErrors}
          />
        )}

        {draft?.type === AssigneeType.agent && (
          <AssigneeEditorValueGroupAgent
            draft={draft}
            setDraft={setDraft}
            errors={assigneeErrors}
            setErrors={setAssigneeErrors}
          />
        )}

        {!isMerchant && draft?.type === AssigneeType.merchant && (
          <AssigneeEditorValueMerchant
            draft={draft}
            setDraft={setDraft}
            errors={assigneeErrors}
            setErrors={setAssigneeErrors}
          />
        )}
      </DialogContent>

      <DialogActions sx={{mt: 4, mb: 1, mx: 1, justifyContent: 'space-between'}}>
        <Box>
          {allowAssignToMe && (
            <Button onClick={handleAssignToMe} variant="text" color="success">
              Assign To Me
            </Button>
          )}
        </Box>

        <Box>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Save</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
