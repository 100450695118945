import {useState} from 'react';
import {FieldPath, FieldValues, UseFormWatch} from 'react-hook-form';
import {FieldNamesMarkedBoolean} from 'react-hook-form/dist/types/form';
import {usePasswordValidation} from './usePasswordValidation';

declare type UseProps<TFieldValues extends FieldValues = FieldValues> = {
  watch: UseFormWatch<TFieldValues>;
  passwordFieldName: FieldPath<TFieldValues>;
  passwordConfirmationFieldName: FieldPath<TFieldValues>;
  dirtyFields: FieldNamesMarkedBoolean<TFieldValues>;
};

export function useSetPassword<TFieldValues extends FieldValues = FieldValues>({
  watch,
  passwordFieldName,
  passwordConfirmationFieldName,
  dirtyFields,
}: UseProps<TFieldValues>) {
  const passwordValue = watch(passwordFieldName);
  const passwordConfirmationValue = watch(passwordConfirmationFieldName);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmation = () => {
    setShowConfirmation(!showConfirmation);
  };
  const {isValid: isValidPassword} = usePasswordValidation({
    password: passwordValue,
    confirmation: passwordConfirmationValue,
  });
  const displayIsValidPassword = dirtyFields[passwordFieldName] && isValidPassword;
  const displayPasswordCheckList =
    !displayIsValidPassword &&
    (dirtyFields[passwordFieldName] || dirtyFields[passwordConfirmationFieldName]);
  return {
    showPassword,
    showConfirmation,
    displayIsValidPassword,
    displayPasswordCheckList,
    handleClickShowPassword,
    handleClickShowConfirmation,
    passwordValue,
    passwordConfirmationValue,
  };
}
