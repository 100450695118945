import {AgentView} from '@ozark/common';
import {useMemo} from 'react';
import {useAgents} from '../../../hooks/useAgents';

export const useAgentCalculationMethod = (currentAgentId?: string) => {
  const {agents} = useAgents();
  const disableSplitMethodChangeBasedOnApplicationResidualOverride = useMemo(() => {
    if (!agents.data || !currentAgentId) {
      return true;
    }

    //we should not allow to change split method from Direct if Agent or his sub-agents have Application Residual Override

    const currentAgent = agents.data.find(x => x.id === currentAgentId);
    if (!currentAgent || !!currentAgent.residualOverrideApplicationUids?.length) {
      return true;
    }

    const parents: AgentView[] = [currentAgent];

    //use loop instead of recursion function
    while (parents.length > 0) {
      const parent = parents.pop();
      const currentAgents: AgentView[] = [];
      if (!parent) {
        continue;
      }
      currentAgents.push(...agents.data.filter(x => x.masterUid === parent.id));
      for (const currentAgent of currentAgents) {
        if (currentAgent.residualOverrideApplicationUids?.length) {
          return true;
        }
        parents.push(currentAgent);
      }
    }
    return false;
  }, [agents.data, currentAgentId]);

  return {
    disableSplitMethodChangeBasedOnApplicationResidualOverride,
  };
};
