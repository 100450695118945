import {Box, Button} from '@mui/material';
import {ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import {Controller, useFormContext, useWatch} from 'react-hook-form';
import RichTextEditor, {ToolbarConfig} from 'react-rte';
import {Collections, Firebase, TicketHistoryRecordType} from '../../..';
import {useUserInfo} from '../../../hooks/useUserInfo';
import {FIELD_NAME_DESCRIPTION} from '../constants/constants';
import {useHistoryRecord} from '../hooks/useHistoryRecord';
import {useIsEditingClosed} from '../hooks/useIsEditingClosed';
import {useTicketEditContainer} from '../hooks/useTicketEditContainer';
import {useTicketId} from '../hooks/useTicketId';
import {useTicketStatus} from '../hooks/useTicketStatus';

export const saveDescription = (ticketId: string, description: string) => {
  return Firebase.firestore
    .collection(Collections.tickets)
    .doc(ticketId)
    .set({description, updatedAt: Firebase.now()}, {merge: true});
};

type Props = {
  children?: ReactNode;
};

export type EditorVal = ReturnType<typeof RichTextEditor.createEmptyValue>;

export function InputDescription({children}: Props) {
  const {isLoading, openEditors, setOpenEditors} = useTicketEditContainer();
  const {isErpAdmin, userDisplayName} = useUserInfo();
  const {ticketId} = useTicketId();
  const {isDraft} = useTicketStatus();
  const {isEditingClosed} = useIsEditingClosed();
  const [editorValue, setEditorValue] = useState(RichTextEditor.createEmptyValue());
  const description = useWatch({name: FIELD_NAME_DESCRIPTION});
  const {setValue} = useFormContext();
  const enabled = !isEditingClosed && (isDraft || isErpAdmin);
  const valueBeforeChange = useRef<EditorVal>();
  const {addHistoryRecord} = useHistoryRecord();
  const isActive = openEditors.has('description');
  const setIsActive = (isActive: boolean) => {
    const openEditorsUpdated = new Set(openEditors);
    if (isActive) {
      openEditorsUpdated.add('description');
    } else {
      openEditorsUpdated.delete('description');
    }
    setOpenEditors(openEditorsUpdated);
  };

  const handleEditorValueChange = useCallback(
    (editorValueNew: EditorVal) => {
      setEditorValue(editorValueNew);
    },
    [editorValue, saveDescription]
  );

  useEffect(() => {
    if (isLoading === false) {
      if (description) {
        setEditorValue(RichTextEditor.createValueFromString(description, 'html'));
      } else {
        setEditorValue(RichTextEditor.createEmptyValue());
      }
    }
  }, [ticketId, description, isLoading]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: enabled ? '#f5f8fa' : '#0000000f',
          borderBottom: `${isActive ? 2 : 1}px ${enabled ? 'solid' : 'dotted'} ${
            isActive ? '#1877f2' : 'rgba(0, 0, 0, 0.42)'
          };`,
        }}
      >
        <Controller
          name={FIELD_NAME_DESCRIPTION}
          rules={{required: 'Description is required'}}
          defaultValue={description}
          render={() => (
            <Box
              onClick={() => {
                valueBeforeChange.current = editorValue;
                enabled && setIsActive(true);
              }}
              sx={{
                '& .DraftEditor-root': {
                  minHeight: enabled ? '138px' : 'initial',
                },
              }}
            >
              <RichTextEditor
                disabled={!enabled}
                value={editorValue}
                onChange={handleEditorValueChange}
                placeholder={enabled ? 'Enter description' : 'No description'}
                toolbarConfig={toolbarConfig}
                editorStyle={{
                  minHeight: enabled ? '138px' : 'initial',
                  fontFamily: 'Rubik, sans-serif',
                }}
                rootStyle={{
                  background: 'none',
                  border: 'none',
                  borderRadius: 'none',
                }}
                toolbarStyle={{
                  display: isActive ? 'block' : 'none',
                }}
              />
            </Box>
          )}
        />
        {children}
      </Box>
      {enabled && isActive && (
        <Box my={1}>
          <Button
            size="small"
            variant="contained"
            onClick={async () => {
              setIsActive(false);
              const value = editorValue.toString('html');
              try {
                await saveDescription(ticketId, value);
                setValue(FIELD_NAME_DESCRIPTION, value, {shouldValidate: true});
                addHistoryRecord(
                  `${userDisplayName} edited Ticket Description`,
                  TicketHistoryRecordType.Description,
                  undefined,
                  'Ticket Description Change'
                );
              } catch (error) {
                valueBeforeChange.current && setEditorValue(valueBeforeChange.current);
                console.error(error);
              }
            }}
          >
            Save
          </Button>
          <Button
            sx={{ml: 1}}
            size="small"
            onClick={() => {
              setIsActive(false);
              valueBeforeChange.current && setEditorValue(valueBeforeChange.current);
            }}
          >
            Cancel
          </Button>
        </Box>
      )}
    </>
  );
}

const isEditorValueEmpty = (editorValue: ReturnType<typeof RichTextEditor.createValueFromString>) =>
  !(editorValue.toString('raw') as any)?.blocks?.text;

export const toolbarConfig: ToolbarConfig = {
  display: [
    'INLINE_STYLE_BUTTONS',
    'BLOCK_TYPE_BUTTONS',
    'LINK_BUTTONS',
    'BLOCK_TYPE_DROPDOWN',
    'HISTORY_BUTTONS',
  ],
  INLINE_STYLE_BUTTONS: [
    {label: 'Bold', style: 'BOLD'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'},
  ],
  BLOCK_TYPE_BUTTONS: [
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'},
    {label: 'BLOCKQUOTE', style: 'blockquote'},
  ],
  BLOCK_TYPE_DROPDOWN: [
    {label: 'Normal', style: 'unstyled'},
    {label: 'Heading Large', style: 'header-one'},
    {label: 'Heading Medium', style: 'header-two'},
    {label: 'Heading Small', style: 'header-three'},
  ],
  BLOCK_ALIGNMENT_BUTTONS: [],
};
