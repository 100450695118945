import {Agent, Merchant, Profile} from '@ozark/functions/src/documents';
import firebase from 'firebase/compat/app';
import {useCallback} from 'react';
import {Collections, createAuditNonce, UserRoles} from '..';
import {Firebase} from '../firebase';

export const INACTIVITY_TIME = 1000 * 60 * 30; // 30 minutes

export const useLastLogin = () => {
  const saveLastLoginDate = useCallback(async (user: firebase.User | null) => {
    const token = await user?.getIdTokenResult();
    if (!token || !user) return;

    const {role, user_id} = token.claims;

    let userCollection = '';
    switch (role) {
      case UserRoles.agent: {
        userCollection = Collections.agents;
        break;
      }
      case UserRoles.merchant: {
        userCollection = Collections.merchants;
        break;
      }
      default: {
        userCollection = Collections.profiles;
        break;
      }
    }

    const snapshot = await Firebase.firestore.collection(userCollection).doc(user_id).get();
    if (snapshot.exists) {
      let data: Partial<Merchant | Agent | Profile> = {
        lastLoginAt: Firebase.Timestamp.now(),
      };

      if (![UserRoles.agent, UserRoles.merchant].includes(role)) {
        const auditNonce = createAuditNonce(Firebase.auth.currentUser!.uid);
        data = {...data, auditNonce};
      }

      await snapshot.ref.set(data, {merge: true});
    }
  }, []);

  return {saveLastLoginDate};
};
