import {Chip} from '@mui/material';
import {format} from 'date-fns-tz';
import {Filter} from '../common';
import {getFilterDate} from '../helpers/getFilterDate';
import {getOnFilterChipDelete} from '../helpers/onFilterChipDelete';
import {ReportingPageStyles} from '../Reporting';
import {DATETIME_FORMAT, DATE_FORMAT} from './Types';

interface Props {
  filters: Filter;
  handleDeleteFilter?: (id: string) => () => void;
}

export const AuthorizationsFilters = ({filters, handleDeleteFilter}: Props) => {
  const onDelete = getOnFilterChipDelete(handleDeleteFilter);
  return (
    <>
      {filters &&
        Object.keys(filters).map(key => {
          const filter = filters[key];
          if (filter.option.type === 'dateRange' && filter.operator.id === '__between') {
            return (
              <Chip
                key={`${key}-${filter.operator.id}`}
                sx={ReportingPageStyles.chip}
                label={
                  <span>
                    <b>{filter.option.label}</b> {filter.operator.label} '
                    <b>{format(getFilterDate(filter.value?.[0]) as Date, DATETIME_FORMAT)}</b>' and
                    '<b>{format(getFilterDate(filter.value?.[1]) as Date, DATETIME_FORMAT)}</b>'
                  </span>
                }
                variant="outlined"
                onDelete={onDelete(key)}
              />
            );
          }
          if (filter.option.type === 'date' && filter.operator.id === '__between') {
            return (
              <Chip
                key={`${key}-${filter.operator.id}`}
                sx={ReportingPageStyles.chip}
                label={
                  <span>
                    <b>{filter.option.label}</b> {filter.operator.label} '
                    <b>{format(getFilterDate(filter.value?.[0]) as Date, DATE_FORMAT)}</b>'
                  </span>
                }
                variant="outlined"
                onDelete={onDelete(key)}
              />
            );
          }
          if (filter.option.type === 'list' && filter.option.options) {
            return (
              <Chip
                key={`${key}-${filter.operator.id}`}
                sx={ReportingPageStyles.chip}
                label={
                  <span>
                    <b>{filter.option.label}</b> {filter.operator.label} '
                    <b>{filter.option.options.find(x => x.key === filter.value)?.value}</b>'
                  </span>
                }
                variant="outlined"
                onDelete={onDelete(key)}
              />
            );
          }
          return (
            <Chip
              key={`${key}-${filter.operator.id}`}
              sx={ReportingPageStyles.chip}
              label={
                <span>
                  <b>{filter.option.label}</b> {filter.operator.label} '
                  <b>
                    {filter.option.type === 'currency' ? `$${filter.value}` : `${filter.value}`}
                  </b>
                  '
                </span>
              }
              variant="outlined"
              onDelete={filter.option.force ? undefined : onDelete(key)}
            />
          );
        })}
    </>
  );
};
