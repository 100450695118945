import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';

type Props = {
  open: boolean;
  onCancel: () => void;
  onDelete: () => void;
};

export const ConfirmDeleteScheduledReporting = ({open, onCancel, onDelete}: Props): JSX.Element => {
  return (
    <Dialog open={open}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this scheduled item?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onDelete}>Delete</Button>
      </DialogActions>
    </Dialog>
  );
};
