import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

interface CopyrightProps {
  groupName: string;
  groupDomain: string;
}

const Copyright = ({groupName, groupDomain}: CopyrightProps) => (
  <Typography variant="body2" color="textSecondary" align="center">
    {'Copyright © '}
    <Link color="inherit" href={groupDomain}>
      {groupName}
    </Link>{' '}
    {new Date().getFullYear()}
  </Typography>
);

export {Copyright};
