import {Hidden, Link, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {LayoutComponent} from '@ozark/common';
import React from 'react';
import {useStore} from '../../store/helpers';
import Header from './Header';
import Navigator, {drawerWidth} from './Navigation';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    padding: theme.spacing(1, 3, 3),
    flex: 1,
    backgroundColor: '#f5fafc',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  },
}));

export const MainLayout: LayoutComponent = ({children}: {children: React.ReactNode}) => {
  const classes = useStyles();
  const {group} = useStore();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <div className={classes.root}>
      <nav className={classes.drawer}>
        <Hidden mdUp implementation="js">
          <Navigator variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} />
        </Hidden>
        <Hidden mdDown implementation="css">
          <Navigator />
        </Hidden>
      </nav>
      <div className={classes.app}>
        <Header onDrawerToggle={handleDrawerToggle} />
        <main className={classes.main}>{children}</main>
        <footer className={classes.footer}>
          <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href={`${window.location.protocol}//${group.data?.portalDomain}`}>
              {group.data?.name}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        </footer>
      </div>
    </div>
  );
};

export default MainLayout;
