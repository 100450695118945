import {MonthlySalesSummary} from '../../../functions/src/shared';
import {Column} from '../../api/Column';
import {Table} from '../Table';

var currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

type Props = {
  yearlySalesSummary: MonthlySalesSummary[];
};

const formatDataForTable = (monthlySalesData: MonthlySalesSummary[]) => {
  const sort: [string, 'ASC' | 'DESC'][] = [['monthYear', 'DESC']];

  return {
    data: monthlySalesData
      .slice()
      .reverse()
      .map((p, index) => ({...p, id: index + 1})), // add unique id for table row key
    limit: 12,
    offset: 0,
    sort,
    totalCount: 12,
  };
};

export const TwelveMonthProcessingTable = ({yearlySalesSummary}: Props) => {
  return (
    <Table
      columns={
        [
          {
            id: 'monthYear',
            numeric: false,
            sortable: false,
            label: 'Month',
          },
          {
            id: 'salesCount',
            numeric: false,
            sortable: false,
            label: 'Sales Count',
          },
          {
            id: 'salesAmount',
            numeric: false,
            sortable: false,
            label: 'Sales Volume',
            selector: (row: MonthlySalesSummary) =>
              currencyFormatter.format(Number(row.salesAmount)),
          },
          {
            id: 'averageTicket',
            numeric: false,
            sortable: false,
            label: 'Average Ticket',
            selector: (row: MonthlySalesSummary) =>
              currencyFormatter.format(Number(row.averageTicket)),
          },
          {
            id: 'creditCount',
            numeric: false,
            sortable: false,
            label: 'Credit Count',
          },
          {
            id: 'creditAmount',
            numeric: false,
            sortable: false,
            label: 'Credit Amount',
            selector: (row: MonthlySalesSummary) =>
              currencyFormatter.format(Number(row.creditAmount)),
          },
          {
            id: 'creditCountRatioPercent',
            numeric: false,
            sortable: false,
            label: 'Credit Count Ratio',
            selector: (row: MonthlySalesSummary) =>
              row.salesCount === '0'
                ? ''
                : (Number(row.creditCount) / Number(row.salesCount)).toLocaleString(undefined, {
                    style: 'percent',
                    minimumFractionDigits: 2,
                  }),
          },
        ] as Column<MonthlySalesSummary>[]
      }
      data={formatDataForTable(yearlySalesSummary)}
      onRowClick={() => {}}
      onRetrieveData={() => {}}
      paginate={false}
    />
  );
};
