import {
  Attachment,
  AttachmentInput,
  AttachmentView,
  Collections,
  Firebase,
  selectAttachmentView,
  UniversalSnapshot,
} from '@ozark/common';
import {ApplicationDocument, MerchantDocument, StoragePath} from '@ozark/functions/src/constants';
import {
  AttachmentAuthor,
  DisputeAttachment,
  DisputeAttachmentView,
  DisputeResponseAttachmentInput,
  getCloudPreviewImagePath,
  getFileNameWithoutExt,
  isPreviewable,
  rename,
  selectDisputeAttachmentView,
} from '@ozark/functions/src/shared';
import firebase from 'firebase/compat/app';
import {v4 as uuidv4} from 'uuid';

export type UploadDocument = ApplicationDocument | MerchantDocument | string;

export type UploadRequest = {
  author: AttachmentAuthor;
  file: File;
  cloudPath: string;
  entityId: string;
  collection: string;
  attachmentLabel?: string;
  pendingDocument: UploadDocument | null;
  setPendingDocument: (any: any) => void;
  files: AttachedFiles;
  setFiles: (any: any) => void;
  showNotification?: (variant: 'success' | 'error' | 'info', message: string) => void;
  onComplete?: (attachment?: AttachmentView) => void;
  platform?: string;
  noteId?: string;
  skipSuccessNotification?: boolean;
  folderName?: string;
  isEquipmentAttachment?: boolean;
};
export interface AttachedFiles {
  [name: string]: AttachedFile;
}
export interface AttachedFile {
  name: string;
  originalName: string;
  progress: number;
  label: UploadDocument;
  url?: string;
  attachmentId?: string;
  uid?: string;
}

export const handleAttachmentRemove = ({
  cloudPath,
  showNotification = () => {},
}: {
  cloudPath: string;
  showNotification?: (variant: 'success' | 'error' | 'info', message: string) => void;
}) => {
  if (!cloudPath) return;

  Firebase.storage
    .ref(cloudPath)
    .delete()
    .then(() => {
      showNotification('success', 'File successfully removed.');
    });
};

export const handleAttachmentUpload = ({
  author,
  file,
  cloudPath,
  entityId,
  collection,
  attachmentLabel,
  files,
  pendingDocument,
  setPendingDocument,
  setFiles,
  showNotification = () => {},
  onComplete,
  noteId,
  skipSuccessNotification,
  folderName,
  isEquipmentAttachment,
}: UploadRequest) => {
  if (!pendingDocument) return;

  const newFileName = `${pendingDocument}-${uuidv4()}`;
  const fileInfo = rename(file, newFileName);

  const attachedFile: AttachedFile = {
    name: fileInfo.name,
    originalName: file.name,
    label: pendingDocument,
    progress: 0,
  };

  setFiles({...files, [attachedFile.name]: attachedFile});

  const cloudFullPath = `${cloudPath}/${fileInfo.name}`;

  const uploadTask = Firebase.storage.ref(cloudFullPath).put(fileInfo);

  uploadTask.on(
    firebase.storage.TaskEvent.STATE_CHANGED,
    (snapshot: any) => {
      const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      setFiles({...files, [attachedFile.name]: {...attachedFile, progress}});
    },
    (err: any) => {
      console.error(err);
      const _attachments = {...files};
      delete _attachments[attachedFile.name];
      setFiles(_attachments);
      setPendingDocument(null);
      onComplete?.();
      showNotification('error', err);
    },
    async () => {
      const {size} = await Firebase.storage.ref(cloudPath).child(fileInfo.name).getMetadata();

      if (!size) {
        showNotification(
          'error',
          `Failed to save attachment ${pendingDocument}. Please reload page, open file upload dialog and try again re-upload it.`
        );
        return;
      }

      const url = await Firebase.storage.ref(cloudPath).child(fileInfo.name).getDownloadURL();

      let docRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | undefined;

      let attachment: AttachmentView | undefined;

      try {
        const attachmentDocument: AttachmentInput = {
          name: pendingDocument,
          label: attachmentLabel
            ? `${attachmentLabel} - ${getFileNameWithoutExt(file)}`
            : getFileNameWithoutExt(file),
          cloudPath: cloudFullPath,
          createdAt: new Date(),
          uid: author.uid,
          author: author,
          noteId,
          folderName,
          isEquipmentAttachment,
        };

        // preview is available for these file types
        if (isPreviewable(cloudFullPath)) {
          attachmentDocument.cloudPreviewImagePath = getCloudPreviewImagePath(cloudFullPath);
        }

        // save the attachment to the Firestore subcollection
        docRef = await Firebase.firestore
          .collection(collection)
          .doc(entityId)
          .collection(Collections.attachments)
          .add(attachmentDocument);
        if (docRef) {
          const doc = await docRef.get();
          attachment = selectAttachmentView(doc as UniversalSnapshot<Attachment>);
        }
      } catch (err) {
        // we want to let the user continue in case of an error
        console.error('failed to save attachment to the database', err);
        showNotification('error', 'Failed to save attachment.');
      } finally {
        setPendingDocument(null);
        onComplete?.(attachment);
        if (!skipSuccessNotification) {
          showNotification('success', 'File successfully uploaded.');
        }
      }

      setFiles({
        ...files,
        [attachedFile.name]: {
          ...attachedFile,
          url,
          progress: 100,
          attachmentId: attachment?.id,
          uid: author.uid,
        },
      });
    }
  );
};

export type DisputeUploadRequest = {
  responseId: string;
  uid?: string;
  userName: string;
  mid: string;
  caseNumber: number;
  file: File;
  showNotification?: (variant: 'success' | 'error' | 'info', message: string) => void;
  onComplete?: (chargeback?: DisputeAttachmentView) => void;
  platform?: string;
  noteId?: string;
  skipSuccessNotification?: boolean;
};

export const handleDisputeResponseUpload = ({
  responseId,
  uid,
  userName,
  mid,
  caseNumber,
  file,
  showNotification = () => {},
  onComplete,
  skipSuccessNotification,
}: DisputeUploadRequest) => {
  const fileInfo = rename(file, uuidv4());

  const cloudPath = `${StoragePath.chargebacks}/${caseNumber}`;
  const cloudFullPath = `${cloudPath}/${fileInfo.name}`;

  const uploadTask = Firebase.storage.ref(cloudFullPath).put(fileInfo);

  uploadTask.on(
    firebase.storage.TaskEvent.STATE_CHANGED,
    (snapshot: any) => {},
    (err: any) => {
      console.error(err);
      onComplete?.();
      showNotification('error', err);
    },
    () => {
      Firebase.storage
        .ref(cloudPath)
        .child(fileInfo.name)
        .getDownloadURL()
        .then(async (url: string) => {
          let docRef:
            | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
            | undefined;
          let dispute: DisputeAttachmentView | undefined;
          try {
            const curDate = new Date();
            const chargebackDocument: DisputeResponseAttachmentInput = {
              name: 'Dispute Response',
              label: getFileNameWithoutExt(file),
              fileDate: curDate,
              createdAt: curDate,
              cloudPathView: cloudFullPath,
              cloudPathDownload: url,
            };

            // preview is available for these file types
            if (isPreviewable(cloudFullPath)) {
              chargebackDocument.cloudPreviewImagePath = getCloudPreviewImagePath(cloudFullPath);
            }

            // save the response to the Firestore
            docRef = await Firebase.firestore
              .collection(Collections.disputes)
              .doc(caseNumber.toString())
              .collection(Collections.responses)
              .doc(responseId)
              .collection(Collections.attachments)
              .add(chargebackDocument);
            if (docRef) {
              const doc = await docRef.get();
              dispute = selectDisputeAttachmentView(doc as UniversalSnapshot<DisputeAttachment>);
            }
          } catch (err) {
            // we want to let the user continue in case of an error
            console.error('failed to save response to the database', err);
            showNotification('error', 'Failed to save attachment.');
          } finally {
            onComplete?.(dispute);
            if (!skipSuccessNotification) {
              showNotification('success', 'File successfully uploaded.');
            }
          }
        });
    }
  );
};

export const uploadFileToStorage = ({
  cloudPath,
  file,
  newFileName,
  storageFileName,
}: Pick<UploadRequest, 'cloudPath' | 'file'> & {
  newFileName?: string;
  storageFileName?: string;
}): Promise<{
  cloudPath: string;
  name: string;
}> => {
  return new Promise((resolve, reject) => {
    let fileInfo = file;
    if (newFileName) {
      fileInfo = rename(file, newFileName);
    }

    const cloudFullPath = `${cloudPath}/${storageFileName ?? fileInfo.name}`;

    return Firebase.storage
      .ref(cloudFullPath)
      .put(fileInfo)
      .on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        null,
        error => reject(error),
        () => {
          resolve({cloudPath: cloudFullPath, name: fileInfo.name});
        }
      );
  });
};
