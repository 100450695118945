import {Box, Button} from '@mui/material';
import {
  Application,
  ApplicationView,
  Collections,
  mapDispositionToMerchantApplicationStatus,
  MerchantApplicationStatus,
  selectApplicationView,
  useApplicationResume,
  useCallable,
  useCollectionSnapshot,
  useMerchant,
} from '@ozark/common';
import {Loading} from '@ozark/common/components';
import {useEffect, useState} from 'react';
import {useStore} from '../../store/helpers';
import {ApplicationStatusDetails} from './ApplicationsStatusDetails';
import {ApplicationsStatusesTable} from './ApplicationsStatusesTable';

export const ApplicationsStatusesPage = () => {
  const {authUser, group} = useStore();
  const {getPortalToken} = useCallable();
  const {documents: applications} = useCollectionSnapshot<ApplicationView, Application>(
    Collections.applications,
    selectApplicationView,
    undefined,
    query => query.where('merchantUid', '==', authUser?.data?.uid)
  );
  const {document: merchant} = useMerchant(authUser?.data?.uid ?? '');
  const [selectedApplication, setSelectedApplication] = useState<ApplicationView | null>(null);
  const {resumeApplication} = useApplicationResume();

  const onTableRowClick = (application: ApplicationView) => {
    setSelectedApplication(application);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const onResumeApplication = (application: ApplicationView) => {
    resumeApplication(application.id, application.group.id);
  };

  const onStartNewApplication = async () => {
    const result = await getPortalToken({});

    if (result.status === 'ok') {
      const a = document.createElement('a');
      a.setAttribute('href', `${process.env.REACT_APP_APP_URL}/portal?token=${result.token}`);
      a.setAttribute('target', '_blank');
      a.click();
    }
  };

  useEffect(() => {
    if (!applications.data) {
      return;
    }

    const lastUncompleted = applications.data.find(application => {
      const merchantApplicationStatus =
        mapDispositionToMerchantApplicationStatus[application.disposition];
      return (
        merchantApplicationStatus === MerchantApplicationStatus.incomplete ||
        merchantApplicationStatus === MerchantApplicationStatus.signatureNeeded
      );
    });
    if (lastUncompleted) {
      setSelectedApplication(lastUncompleted);
      return;
    }
    const [lastCreatedApplication] = applications.data;
    setSelectedApplication(lastCreatedApplication);
  }, [applications.data]);

  if (!authUser.data || applications.promised || !merchant.data || !group.data) {
    return <Loading />;
  }

  if (!applications.data?.length) {
    return (
      <Box sx={{py: 10, textAlign: 'center'}}>
        <Box sx={{mb: 3}}>No applications found associated with your account</Box>
        <Button variant="contained" onClick={onStartNewApplication}>
          Start New Application
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{pt: 3}}>
      {selectedApplication && (
        <ApplicationStatusDetails
          application={selectedApplication}
          userData={merchant.data}
          group={group.data}
          onResumeApplication={onResumeApplication}
        />
      )}
      {!applications.promised && (
        <Box sx={{py: 2}}>
          <ApplicationsStatusesTable
            applications={applications.data}
            onRowClick={onTableRowClick}
            onResumeApplication={onResumeApplication}
          />
        </Box>
      )}
    </Box>
  );
};
