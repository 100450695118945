import {Dispositions} from './Dispositions';
import {SalesDisposition} from './SalesDisposition';
import {SalesDispositionGroup} from './SalesDispositionGroup';

export enum ApplicationStatusGroups {
  all = 'All',
  incomplete = 'Incomplete',
  open = 'Open',
  closed = 'Closed',
  dead = 'Dead',
}

export const GroupDispositions: {[_ in ApplicationStatusGroups]: Dispositions[]} = {
  [ApplicationStatusGroups.all]: Object.values(Dispositions),
  [ApplicationStatusGroups.incomplete]: [Dispositions.started, Dispositions.incomplete],
  [ApplicationStatusGroups.open]: [
    Dispositions.signatureNeeded,
    Dispositions.asReceived,
    Dispositions.asIncomplete,
    Dispositions.uwPending,
    Dispositions.uwReceived,
    Dispositions.uwApproved,
    Dispositions.uwSubmittedToOtherBank,
    Dispositions.failed,
    Dispositions.enqueued,
    Dispositions.asRequestedSupportingDocuments,
    Dispositions.asSupportingDocumentsReceived,
  ],
  [ApplicationStatusGroups.closed]: [Dispositions.boarded],
  [ApplicationStatusGroups.dead]: [
    Dispositions.asDeclined,
    Dispositions.asWithdrawn,
    Dispositions.uwDeclined,
    Dispositions.uwWithdrawn,
    Dispositions.uwArchived,
  ],
};

export const getDispositions = (
  group: ApplicationStatusGroups,
  viewable: Dispositions[] | undefined | null
) => {
  return GroupDispositions[group].filter(disposition => (viewable || []).includes(disposition));
};

export const getGroup = (disposition: Dispositions) => {
  return Object.keys(GroupDispositions)
    .filter(e => e !== ApplicationStatusGroups.all)
    .find(e => GroupDispositions[e as ApplicationStatusGroups].includes(disposition));
};

// Algolia grouping
export enum SearchGroup {
  incomplete = 'Incomplete',
  underwriting = 'Underwriting',
  boarded = 'Boarded',
}

// algolia search groups bundles rm and underwriting dispositions in the same index
export const SearchGroups: {[_ in SearchGroup]: Dispositions[]} = {
  [SearchGroup.incomplete]: [
    Dispositions.started,
    Dispositions.signatureNeeded,
    Dispositions.incomplete,
  ],
  [SearchGroup.underwriting]: [
    Dispositions.asReceived,
    Dispositions.asDeclined,
    Dispositions.asWithdrawn,
    Dispositions.asIncomplete,
    Dispositions.asRequestedSupportingDocuments,
    Dispositions.asSupportingDocumentsReceived,
    Dispositions.uwPending,
    Dispositions.uwReceived,
    Dispositions.uwApproved,
    Dispositions.uwDeclined,
    Dispositions.uwWithdrawn,
    Dispositions.uwArchived,
    Dispositions.uwSubmittedToOtherBank,
    Dispositions.enqueued,
    Dispositions.failed,
  ],
  [SearchGroup.boarded]: [Dispositions.boarded],
};

export const getSearchGroup = (disposition: Dispositions) => {
  return Object.keys(SearchGroups).find(e => SearchGroups[e as SearchGroup].includes(disposition));
};

export enum SalesDispositionGroupExt {
  all = 'All Groups',
}

export const SalesGroups = {...SalesDispositionGroup, ...SalesDispositionGroupExt};

export type SalesGroupsType = SalesDispositionGroup | SalesDispositionGroupExt;

export const GroupSalesDispositions: {[_ in SalesGroupsType]: SalesDisposition[]} = {
  [SalesGroups.all]: Object.values(SalesDisposition),
  [SalesGroups.open]: [
    SalesDisposition.new,
    SalesDisposition.leftVM,
    SalesDisposition.followUpScheduled,
    SalesDisposition.hotReadyToSign,
    SalesDisposition.sentApp,
    SalesDisposition.contactAttempt1,
    SalesDisposition.contactAttempt2,
    SalesDisposition.contactAttempt3,
    SalesDisposition.contactAttempt4,
    SalesDisposition.contactAttempt5,
    SalesDisposition.appSubmittedWithSupportingDocs,
    SalesDisposition.sentIntroEmailWithApp,
    SalesDisposition.appSubmittedReqSupDocsContactAttempt1,
    SalesDisposition.appSubmittedReqSupDocsContactAttempt2,
    SalesDisposition.appSubmittedReqSupDocsContactAttempt3,
    SalesDisposition.appSubmittedReqSupDocsContactAttempt4,
    SalesDisposition.appSubmittedReqSupDocsContactAttempt5,
  ],
  [SalesGroups.won]: [SalesDisposition.appCompleted],
  [SalesGroups.dead]: [
    SalesDisposition.invalidLead,
    SalesDisposition.badLead,
    SalesDisposition.notInterested,
    SalesDisposition.deleted,
    SalesDisposition.unresponsive,
  ],
};

export const getSalesGroupByDisposition = (disposition: SalesDisposition) => {
  if (GroupSalesDispositions[SalesGroups.open].includes(disposition)) {
    return SalesGroups.open;
  }
  if (GroupSalesDispositions[SalesGroups.won].includes(disposition)) {
    return SalesGroups.won;
  }
  if (GroupSalesDispositions[SalesGroups.dead].includes(disposition)) {
    return SalesGroups.dead;
  }
  throw Error(`Can't find SalesGroup for the SalesDisposition ${disposition}`);
};
