import {useWatch} from 'react-hook-form';
import {TicketStatus} from '../../..';
import {FIELD_NAME_STATUS} from '../constants/constants';

export function useTicketStatus() {
  const status = useWatch({name: FIELD_NAME_STATUS}) as TicketStatus | undefined;

  const isDraft = status === TicketStatus.Draft;
  return {
    status,
    isDraft,
  };
}
