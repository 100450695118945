import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import {SearchableMerchantView} from '@ozark/common';
import {useEffect, useState} from 'react';
import {MerchantsIcon} from '../../icons';
import {AsyncState} from '../../models';

interface Props {
  merchants: AsyncState<SearchableMerchantView[]>;
  handleSelectOwner: (merchant: MerchantOwnerSelectItem) => void;
  selectedMerchant: MerchantOwnerSelectItem;
  width?: number;
}

export interface MerchantOwnerSelectItem {
  id: string;
  text: string;
}

export const AllMerchantOwnerSelectItem = {
  id: 'All',
  text: 'All',
} as MerchantOwnerSelectItem;

export const OwnersOnlyMerchantOwnerSelectItem = {
  id: 'OwnersOnly',
  text: 'Owners',
} as MerchantOwnerSelectItem;

export const SubMerchantsOnlyMerchantOwnerSelectItem = {
  id: 'SubMerchantsOnly',
  text: 'Sub-Merchants',
} as MerchantOwnerSelectItem;

const initialSelectedItems = [
  AllMerchantOwnerSelectItem,
  OwnersOnlyMerchantOwnerSelectItem,
  SubMerchantsOnlyMerchantOwnerSelectItem,
];

export const MerchantOwnerSelect = ({
  merchants,
  handleSelectOwner,
  selectedMerchant,
  width = 200,
}: Props) => {
  const [list, setList] = useState<MerchantOwnerSelectItem[]>(initialSelectedItems);
  useEffect(() => {
    if (!merchants.data) {
      return;
    }

    const existingOwners = merchants.data
      .filter(x => !x.masterUid)
      .map(
        x =>
          ({
            id: x.id,
            text: `${x.firstName} ${x.lastName} (${x.email})`,
          } as MerchantOwnerSelectItem)
      );
    setList([...initialSelectedItems, ...existingOwners]);
  }, [merchants]);

  if (merchants.promised) return null;

  return (
    <Autocomplete
      options={list}
      getOptionLabel={option => option.text}
      style={{width}}
      componentsProps={{
        paper: {
          sx: {
            width: width + 300,
          },
        },
      }}
      size="small"
      value={selectedMerchant}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(_event, newInputValue) => {
        handleSelectOwner(newInputValue ?? AllMerchantOwnerSelectItem);
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.text}
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          variant="standard"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <MerchantsIcon />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
        />
      )}
    />
  );
};
