import {FilterOption, MtdDefaultDateRange} from '../../..';

export const DATE_FORMAT_RESERVES = 'MMM dd, yyyy h:mm:ss a';
export const DATE_ONLY_FORMAT_RESERVES = 'MMM dd, yyyy';

export const ReserveFilters: FilterOption[] = [
  {
    id: 'mid',
    column: 'mid',
    label: 'MID',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'bin',
    column: 'bin',
    label: 'BIN',
    type: 'text',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'date',
    column: 'activityDate',
    label: 'Date',
    type: 'date',
    dateFormat: DATE_ONLY_FORMAT_RESERVES,
    operators: [
      {
        id: '__between',
        label: 'equals',
      },
    ],
  },
  {
    id: 'dateRange',
    column: 'activityDate',
    label: 'Date Range',
    type: 'dateRange',
    dateFormat: DATE_FORMAT_RESERVES,
    defaultDateRange: MtdDefaultDateRange,
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
];
