import {ResourceView} from '@ozark/common';
import {TooltipPlacement} from '../../../material-ui';
import {InlineTypography} from '../../Inline';
import {EditableField, useStyles} from './constants';
import {useDisplayContext} from './DisplayContext';
import {DisplayText} from './DisplayText';

interface Props {
  resourceView: ResourceView;
}

export const DisplayDescription = ({resourceView}: Props) => {
  const classes = useStyles();
  const {handleSaveField, writePermissionsForResource} = useDisplayContext();
  const canUpdate = writePermissionsForResource(resourceView);
  if (canUpdate) {
    return (
      <InlineTypography
        tooltip="Click to edit Description"
        emptyValue={'no description'}
        value={resourceView.description}
        className={classes.resourceFont}
        setText={newValue => handleSaveField(EditableField.description, resourceView.id, newValue)}
        tooltipPlacement={TooltipPlacement.BottomStart}
      />
    );
  }
  return <DisplayText value={resourceView.description} />;
};
