import {useCallback, useLayoutEffect, useRef} from 'react';

export const useInfiniteData = (onLoadMore: () => void | Promise<void>, hasMore: boolean) => {
  const loadMoreRef = useRef(null);
  const observerRef = useRef<IntersectionObserver | null>(null);

  const handleObserver = useCallback(
    (entries: any) => {
      const [target] = entries;
      if (target.isIntersecting && loadMoreRef.current) {
        onLoadMore();
        observerRef.current?.unobserve(loadMoreRef.current);
      }
    },
    [onLoadMore]
  );

  useLayoutEffect(() => {
    if (!hasMore) {
      return;
    }

    const option = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    observerRef.current = new IntersectionObserver(handleObserver, option);
    if (loadMoreRef.current) {
      observerRef.current?.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observerRef.current?.unobserve(loadMoreRef.current);
      }
    };
  }, [handleObserver, hasMore, loadMoreRef.current]);

  return loadMoreRef;
};
