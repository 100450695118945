import {formatAsPercent, formatNumber} from '../../..';

export const hasData = (args: any[]) => {
  return args.map(d => Number(d)).filter(i => i).length > 0;
};

export enum DataFormat {
  none,
  number,
  currency,
  percent,
  text,
}

export const formatData = (
  data: string | number | null | undefined,
  dataFormat: DataFormat,
  noDataMsg: string | undefined,
  displayNoDataForZero: boolean | undefined
): string => {
  if (data === null || data === undefined || (!!displayNoDataForZero && Number(data) === 0)) {
    return noDataMsg ?? '-';
  }

  switch (dataFormat) {
    case DataFormat.number:
      return formatNumber(Number(data));
    case DataFormat.currency:
      return formatterCurrency.format(Number(data));
    case DataFormat.percent:
      return formatAsPercent(Number(data));
    default:
      return data.toString();
  }
};

export const formatterCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
