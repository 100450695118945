import {Autocomplete, Box, TextField} from '@mui/material';
import {omit} from 'lodash';
import {useEffect, useState} from 'react';
import {Platforms, TicketAssociation, TicketMerchant} from '../../../..';
import {useAuthorizedMids} from '../../../../hooks/useAuthorizedMids';
import {FIELD_NAME_MERCHANT} from '../../constants/constants';
import {InputAssociationError} from '../../types';

type Props = {
  draft: TicketAssociation;
  setDraft: (draft: TicketAssociation) => void;
  errors: Record<InputAssociationError, string>;
  setErrors: (errors: Record<InputAssociationError, string>) => void;
};

type TicketMerchantItem = TicketMerchant & {
  platform?: Platforms;
};

// we have application association - so here we would only show multimerchants
export const AssociationEditorValueMerchant = ({draft, setDraft, errors, setErrors}: Props) => {
  const [merchants, setMerchants] = useState<TicketMerchantItem[]>([]);
  const {authorizedMids} = useAuthorizedMids();

  useEffect(() => {
    if (authorizedMids.promised) return;

    (async () => {
      if (!authorizedMids.data) {
        setMerchants([]);
        return;
      }

      setMerchants(
        authorizedMids.data.map(a => ({
          mid: a.mid,
          dba: a.dba,
          legalName: a.legalBusinessName,
          platform: a.platform,
          agent: a.agentName,
          group: a.groupName,
        }))
      );
    })();
  }, [authorizedMids]);

  const getOptionLabel = (item: TicketMerchantItem) =>
    [
      item.legalName || '-',
      '|',
      item.dba || '-',
      '(',
      item.mid || '-',
      ')',
      '-',
      item.platform || '-',
    ].join(' ');

  return (
    <>
      <Box mt={2}>
        <Autocomplete
          value={draft.merchant}
          onChange={async (
            _: React.SyntheticEvent<Element, Event>,
            newValue: TicketMerchant | null
          ) => {
            setDraft({...draft, merchant: newValue ?? undefined});
            if (newValue) {
              setErrors(omit(errors, FIELD_NAME_MERCHANT) as Record<InputAssociationError, string>);
            }
          }}
          disabled={!merchants.length}
          options={merchants}
          getOptionLabel={getOptionLabel}
          renderInput={params => (
            <TextField
              {...params}
              label="Associated Merchant"
              required
              variant="standard"
              error={Boolean(errors[FIELD_NAME_MERCHANT])}
              helperText={errors[FIELD_NAME_MERCHANT]}
            />
          )}
        />
      </Box>
    </>
  );
};
