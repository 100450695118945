import {AsyncState, Collections, Firebase, GroupRateProgram} from '@ozark/common';
import {useCallback, useEffect, useState} from 'react';
import {RateProgram} from '../../functions/src/documents';
import {useRatePrograms} from './useRatePrograms';

const mapSnapshotData = (snapshot: any): any[] =>
  snapshot.docs.map((doc: any) => ({id: doc.id, ...doc.data()}));

export const useRateProgramsByGroup = (groupId: string | null, activeOnly: boolean = true) => {
  const {
    ratePrograms: globalRatePrograms,
    getRateSetByProcessingType,
    mapSnapshotToPrograms,
  } = useRatePrograms();
  const [ratePrograms, setGroupRatePrograms] = useState<AsyncState<GroupRateProgram[]>>({
    promised: true,
  });

  const fetchGroupPrograms = useCallback(async () => {
    if (!groupId) throw Error('groupId must be defined');

    if (globalRatePrograms.promised || !globalRatePrograms.data) {
      return;
    }

    const collection = Firebase.firestore
      .collection(Collections.groups)
      .doc(groupId)
      .collection(Collections.programs);

    (activeOnly ? collection.where('active', '==', true) : collection).onSnapshot(
      async snapshot => {
        if (snapshot.empty) {
          // TODO: investigate an issue with changed globalRatePrograms.
          // remove redundant firestore call after that
          const globalProgramsSnapshot = await Firebase.firestore
            .collection(Collections.programs)
            .where('active', '==', true)
            .get();

          const globalProgramsData = mapSnapshotToPrograms(globalProgramsSnapshot);

          // when have no custom benefits for programs, then use global benefits:
          const globalPrograms = globalProgramsData.map((prog: RateProgram) => {
            return {
              ...prog,
              benefits: {...prog.benefits, custom: false},
              global: true,
            } as GroupRateProgram;
          });

          setGroupRatePrograms({promised: false, data: globalPrograms});
          return;
        }

        // custom benefits exists:
        const groupProgramsData = mapSnapshotData(snapshot);
        const groupPrograms = groupProgramsData.map((program: RateProgram) => {
          return {
            ...program,
            benefits: {
              ...program.benefits,
              list: program.benefits.list,
              custom: true,
            },
            global: false,
          } as GroupRateProgram;
        });

        setGroupRatePrograms({promised: false, data: groupPrograms});
        return;
      }
    );
  }, [groupId, globalRatePrograms]);

  useEffect(() => {
    fetchGroupPrograms();
  }, [fetchGroupPrograms]);

  return {ratePrograms, getRateSetByProcessingType};
};
