import {ApplicationView, Dispositions} from '@ozark/common';
import {ApplicationResponse} from '@ozark/functions/src/functions/express/private/types';
import {format} from 'date-fns';
import {asUniversalTimestamp} from '../../helpers';

const DATETIME_FORMAT_FULL = 'MM/dd/yyyy h:mm a';
export const getApplicationDate = (application: ApplicationResponse | ApplicationView) => {
  let label: string;
  let dateString: string;
  let dateRaw: Date;

  if (application.disposition === Dispositions.boarded && application.boardedAt) {
    label = 'Date Boarded';
    dateRaw = asUniversalTimestamp(application.boardedAt)!.toDate();
    dateString = format(dateRaw, DATETIME_FORMAT_FULL);

    return {dateString, dateRaw, label} as const;
  }

  if (application.referenceApp) {
    label = 'Date Cloned';
    dateRaw = asUniversalTimestamp(application.createdAt)!.toDate();
    dateString = format(dateRaw, DATETIME_FORMAT_FULL);

    return {dateString, dateRaw, label} as const;
  }

  if (application.completedAt) {
    label = 'Date Signed';
    dateRaw = asUniversalTimestamp(application.completedAt)!.toDate();
    dateString = format(dateRaw, DATETIME_FORMAT_FULL);

    return {dateString, dateRaw, label} as const;
  }

  label = 'Date Started';
  dateRaw = asUniversalTimestamp(application.createdAt)!.toDate();
  dateString = format(dateRaw, DATETIME_FORMAT_FULL);

  return {dateString, dateRaw, label} as const;
};
