export const AgentsIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g fill={'inherit'}>
        <path
          d="M191.4,414.8L208,344l-32-56h96l-32,56l16.6,70.8L224,480L191.4,414.8z M224,256c70.7,0,128-57.3,128-128
		S294.7,0,224,0S96,57.3,96,128S153.3,256,224,256z"
          opacity={props.fill || 0.4}
        />
        <path
          d="M319.8,288.6L224,480l-95.8-191.4C56.9,292,0,350.3,0,422.4V464c0,26.5,21.5,48,48,48l0,0h352
		c26.5,0,48-21.5,48-48l0,0v-41.6C448,350.3,391.1,292,319.8,288.6L319.8,288.6z"
        />
      </g>
    </svg>
  );
};
