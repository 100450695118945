import {
  ApplicationDocument,
  AttachedFiles,
  AttachmentView,
  Collections,
  handleAttachmentUpload,
  InputAccept,
  StoragePath,
  useLoadingContainer,
  useNotification,
  useUserInfo,
} from '@ozark/common';
import {createRef, useState} from 'react';

interface Props {
  fieldNameFull: string;
  defaultValue?: string;
  applicationId: string;
  attachmentLabel: string;
  verifyFileSize?: {confirmationText: string; maxFileSize: number};
  accept?: InputAccept;
  children: React.ReactNode;
  onAttachmentUpload: (attachment?: AttachmentView) => Promise<void>;
  onBeforeUpload: () => Promise<boolean>;
}

export const FieldVarSheet = ({
  fieldNameFull,
  applicationId,
  attachmentLabel,
  verifyFileSize,
  children,
  onAttachmentUpload,
  onBeforeUpload,
}: Props) => {
  const {setIsLoading} = useLoadingContainer();
  const {isErpUser, userDisplayName, uid, isAgent} = useUserInfo();
  const inputRef = createRef<HTMLInputElement>();
  const [message, setMessage] = useState<string>('');
  const [pendingDocument, setPendingDocument] = useState<ApplicationDocument | null>(
    ApplicationDocument.varSheet
  );
  const [currentAttachments, setCurrentAttachments] = useState<AttachedFiles>({});
  const showNotification = useNotification();

  const handleSelectFile = (file: File) => {
    if (!uid) throw new Error('FieldVarSheet: User data is missing');

    setCurrentAttachments({});
    setIsLoading(true);
    handleAttachmentUpload({
      author: {
        uid: uid,
        name: userDisplayName ?? '',
        user: isErpUser ? 'erp' : isAgent ? 'agent' : 'merchant',
      },
      file,
      cloudPath: `${StoragePath.applications}/${applicationId}`,
      attachmentLabel,
      entityId: applicationId,
      collection: Collections.applications,
      pendingDocument,
      setPendingDocument,
      files: currentAttachments,
      setFiles: setCurrentAttachments,
      showNotification,
      onComplete: async (attachment?: AttachmentView) => {
        setIsLoading(false);
        setPendingDocument(ApplicationDocument.varSheet);
        await onAttachmentUpload(attachment);
      },
      isEquipmentAttachment: true,
    });
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files) return;
    const file = event.target.files[0];
    if (!file) return;

    if (verifyFileSize && file.size > verifyFileSize.maxFileSize) {
      setMessage(verifyFileSize.confirmationText);
    } else {
      handleSelectFile(file);
    }
  };

  const triggerFilePicker = async () => {
    const current = inputRef.current;
    if (await onBeforeUpload()) {
      current?.click();
    }
  };

  return (
    <>
      <div onClick={triggerFilePicker}>{children}</div>
      <input
        ref={inputRef}
        style={{display: 'none'}}
        id={fieldNameFull}
        type="file"
        onChange={handleChange}
      />
    </>
  );
};
