import {Theme, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  h1: {
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5em',
    },
  },
  h2: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1em',
    },
  },
}));

interface Props {
  h1?: React.ReactNode;
  h2?: React.ReactNode;
}

const Title = ({h1, h2}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.h1} variant="h3" component="h1" gutterBottom data-test="title">
        {h1}
      </Typography>
      <Typography className={classes.h2} variant="h5" component="h2">
        {h2}
      </Typography>
    </div>
  );
};

export default Title;
