import {ResourceView} from '@ozark/common';
import {TooltipPlacement} from '../../../material-ui';
import {InlineTypography} from '../../Inline';
import {EditableField, useStyles} from './constants';
import {useDisplayContext} from './DisplayContext';
import {DisplayText} from './DisplayText';

interface Props {
  resourceView: ResourceView;
}

export const DisplayVimeoLink = ({resourceView}: Props) => {
  const classes = useStyles();
  const {isRequiredFieldValid, handleSaveField, writePermissionsForResource} = useDisplayContext();
  return writePermissionsForResource(resourceView) ? (
    <InlineTypography
      tooltip="Click to edit Vimeo link"
      value={resourceView.vimeoLink}
      className={classes.resourceFont}
      onValidate={(newValue: string) => isRequiredFieldValid(EditableField.vimeoLink, newValue)}
      setText={newValue => handleSaveField(EditableField.vimeoLink, resourceView.id, newValue)}
      tooltipPlacement={TooltipPlacement.BottomStart}
    />
  ) : (
    <DisplayText value={resourceView.vimeoLink} />
  );
};
