import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import {Avatar, Box} from '@mui/material';
import {ReactNode} from 'react';
import {useWatch} from 'react-hook-form';
import {AssociationType, TicketAssociation} from '../../../..';
import {useUserInfo} from '../../../../hooks/useUserInfo';
import {AvatarFromName} from '../../common/AvatarFromName';
import {FIELD_NAME_ASSOCIATION} from '../../constants/constants';
import {Detail} from '../DetailsRow';
import {AssociationInfoMerchant} from './AssociationInfoMerchant';

export const AssociationInfo = () => {
  const {isErpUser, isMerchant} = useUserInfo();
  const association: TicketAssociation = useWatch({name: FIELD_NAME_ASSOCIATION});
  if (!association) return <span>...</span>;
  let avatar: ReactNode;
  let details: ReactNode;
  let title: string = '...';

  switch (association.type) {
    case AssociationType.erpUser:
      title = isErpUser
        ? association.erpUser?.name ?? 'N A'
        : `${association.erpUser?.department ?? 'ERP'} Department`;
      avatar = <AvatarFromName name={title} size={33} />;
      details = <Detail title={title} description={isErpUser ? 'ERP User' : ''} />;
      break;
    case AssociationType.agent:
      title = association.agent?.name ?? 'N A';
      avatar = <AvatarFromName name={title} size={33} />;
      details = <Detail title={title} description="Agent" />;
      break;
    case AssociationType.merchant:
      avatar = (
        <Avatar sx={{width: 34, height: 34}}>
          <ReceiptLongIcon />
        </Avatar>
      );
      details = (
        <AssociationInfoMerchant mid={association.merchant?.mid} dba={association.merchant?.dba} />
      );
      break;
    case AssociationType.application:
      avatar = (
        <Avatar sx={{width: 33, height: 33}}>
          <PermContactCalendarIcon />
        </Avatar>
      );
      details = (
        <Detail
          title={`${association.application?.name ?? ''} ${
            association.application?.distinguishableId
          }`}
          description={'Application'}
        />
      );
      break;
    case AssociationType.mid:
      avatar = (
        <Avatar sx={{width: 33, height: 33}}>
          <PermContactCalendarIcon />
        </Avatar>
      );
      if (association.mid?.mid) {
        //if we have MID - display the same as we have for AssociationType.merchant
        details = <AssociationInfoMerchant mid={association.mid.mid} dba={association.mid.dba} />;
      } else {
        details = (
          <Detail
            title={`${association.application?.name ?? ''} ${association.mid?.mid}`}
            description={'Merchant ID'}
          />
        );
      }
      break;
    default:
      console.error('invalid association type', association.type);
      break;
  }

  return (
    <Box display="flex" alignItems="flex-start" flex={1}>
      {avatar}
      <Box pl={1} maxWidth={135}>
        {details}
      </Box>
    </Box>
  );
};
