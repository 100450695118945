import {SyntheticListenerMap} from '@dnd-kit/core/dist/hooks/utilities';
import DragIndicator from '@mui/icons-material/DragIndicator';
import {IconButton, TableCell} from '@mui/material';
import {useDisplayContext} from './DisplayContext';

interface Props {
  listeners?: SyntheticListenerMap | undefined;
}
export const DisplayDragHandle = ({listeners}: Props) => {
  const {allowEdit} = useDisplayContext();
  const button = (
    <IconButton size="small" {...listeners} sx={{cursor: 'grab'}}>
      <DragIndicator />
    </IconButton>
  );

  if (!allowEdit) {
    return null;
  }

  return <TableCell>{button}</TableCell>;
};
