import {useEffect, useState} from 'react';
import {useWatch} from 'react-hook-form';
import {CalendarEvent} from '../../../../..';
import {getNewCalendarEventDateTime} from '../../../../Calendar/utils';
import {FIELD_NAME_DISTINGUISHABLE_ID, FIELD_NAME_SUBJECT} from '../../../constants/constants';
import {useTicketId} from '../../../hooks/useTicketId';
import {useNewTicketEventParticipants} from './useNewTicketEventParticipants';

export function useNewTicketEvent() {
  const {ticketId} = useTicketId();
  const {defaultParticipants} = useNewTicketEventParticipants();
  const [event, setEvent] = useState<CalendarEvent>();
  const ticketSubject = useWatch({name: FIELD_NAME_SUBJECT});
  const ticketDistinguashableId = useWatch({name: FIELD_NAME_DISTINGUISHABLE_ID});

  useEffect(() => {
    if (!ticketId || !ticketDistinguashableId) return;

    const newTicketEvent: CalendarEvent = {
      subject: `Re: ${ticketSubject}`,
      association: {
        type: 'Ticket',
        id: ticketId,
        distinguishableId: ticketDistinguashableId,
      },
      type: 'Reminder',
      dateTime: getNewCalendarEventDateTime(),
      participants: defaultParticipants ?? [],
      reminderOffset: 0,
    };

    setEvent(newTicketEvent);
  }, [ticketId, ticketSubject, ticketDistinguashableId]);

  return {
    newTicketEvent: event,
  };
}
