import {Box, Paper} from '@mui/material';
import {PieTooltipProps, ResponsivePie} from '@nivo/pie';
import {MidInfo} from '@ozark/functions/src/functions/express/private/types/Reports';
import {formatNumber, truncate} from '../../..';
import {formatAsPercent, formatterCurrency} from '../../../util';

type Props = {
  data: MidInfo[];
};

export const MidsByProcessingPie = ({data}: Props) => {
  const dataFormatted = formatAsPieChartData(data);

  return (
    <ResponsivePie
      colors={{scheme: 'set2'}}
      data={dataFormatted}
      margin={{top: 40, right: 140, bottom: 80, left: 140}}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}
      enableArcLinkLabels={true}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{from: 'color'}}
      arcLinkLabel={d =>
        d.data.label ? truncate(d.data.label.toString()) ?? '' : '...' + d.id.toString().slice(8)
      }
      valueFormat={value => formatterCurrency.format(value)}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{from: 'color', modifiers: [['darker', 2]]}}
      tooltip={Tooltip}
    />
  );
};

const Tooltip = ({datum}: PieTooltipProps<ReturnType<typeof formatAsPieChartData>[number]>) => (
  <Paper square elevation={1}>
    <Box p={1} borderLeft={`5px solid ${datum.color}`}>
      <Box pb={1}>
        <div>
          <b>
            {datum.data.label || 'Merchant'} ({datum.data.id})
          </b>
        </div>
      </Box>
      <div>Average Ticket:&ensp;{datum.data.avgTicket}</div>
      <div>Sales Amount:&emsp;{formatterCurrency.format(datum.data.value)}</div>
      <div>% of Total:&emsp;&emsp;&emsp;{formatAsPercent(datum.data.percentOfTotal)}</div>
      <div>Transactions:&ensp;&emsp;{datum.data.salesCount}</div>
    </Box>
  </Paper>
);

const formatAsPieChartData = (data: MidInfo[]) => {
  const result = data.map((i: MidInfo) => ({
    id: i.mid,
    value: i.salesAmount,
    label: i.merchantName,
    avgTicket: formatterCurrency.format(i.avgTicket ?? 0),
    percentOfTotal: i.percentOfTotal ?? 0,
    salesCount: formatNumber(i.salesCount),
  }));

  return result;
};
