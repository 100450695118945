import {Dispositions} from '@ozark/common';

export enum MerchantApplicationStatus {
  incomplete = 'Incomplete',
  signatureNeeded = 'Signature Needed',
  pending = 'Pending - Items Needed',
  underReview = 'Under Review',
  declined = 'Declined',
  withdrawn = 'Withdrawn',
  approved = 'Approved',
  readyToProcess = 'Ready to Process',
}

export const mapDispositionToMerchantApplicationStatus: {
  [key in Dispositions]: MerchantApplicationStatus;
} = {
  [Dispositions.started]: MerchantApplicationStatus.incomplete,
  [Dispositions.incomplete]: MerchantApplicationStatus.incomplete,
  [Dispositions.signatureNeeded]: MerchantApplicationStatus.signatureNeeded,
  [Dispositions.asReceived]: MerchantApplicationStatus.underReview,
  [Dispositions.asRequestedSupportingDocuments]: MerchantApplicationStatus.underReview,
  [Dispositions.asSupportingDocumentsReceived]: MerchantApplicationStatus.underReview,
  [Dispositions.uwReceived]: MerchantApplicationStatus.underReview,
  [Dispositions.uwSubmittedToOtherBank]: MerchantApplicationStatus.underReview,
  [Dispositions.asIncomplete]: MerchantApplicationStatus.pending,
  [Dispositions.uwPending]: MerchantApplicationStatus.pending,
  [Dispositions.asDeclined]: MerchantApplicationStatus.declined,
  [Dispositions.uwDeclined]: MerchantApplicationStatus.declined,
  [Dispositions.asWithdrawn]: MerchantApplicationStatus.withdrawn,
  [Dispositions.uwWithdrawn]: MerchantApplicationStatus.withdrawn,
  [Dispositions.uwArchived]: MerchantApplicationStatus.withdrawn,
  [Dispositions.uwApproved]: MerchantApplicationStatus.approved,
  [Dispositions.enqueued]: MerchantApplicationStatus.approved,
  [Dispositions.failed]: MerchantApplicationStatus.approved,
  [Dispositions.boarded]: MerchantApplicationStatus.readyToProcess,
};
