import {
  Agent,
  AllMIDs,
  AuthUserClaims,
  Collections,
  Firebase,
  GeoTimeZone,
  GroupRole,
  UserRoles,
} from '@ozark/common';
import {useEffect, useRef, useState} from 'react';
import {UnitedStatesTimeZonesShortList} from '../../../../models/TimeZone';

export enum TimeZoneTitle {
  MerchantTimezone = 'Merchant Timezone',
  BrowserTimezone = 'Browser Timezone',
  PST = 'PST',
  MST = 'MST',
  CST = 'CST',
  EST = 'EST',
}
export type TimeZoneInfo = {
  timeZoneId: string;
  timeZoneTitle: TimeZoneTitle;
  isBrowserTimeZone?: boolean;
};

const timeZoneBrowserInfo = Intl.DateTimeFormat().resolvedOptions();
const timeZoneBrowser: TimeZoneInfo = {
  timeZoneId: timeZoneBrowserInfo.timeZone,
  timeZoneTitle: TimeZoneTitle.BrowserTimezone,
  isBrowserTimeZone: true,
};

export function useTimeZoneByMerchant(
  currentMid: string,
  uid: string | undefined,
  claims: AuthUserClaims | undefined | null
) {
  const [timeZoneInfo, setTimeZoneInfo] = useState<TimeZoneInfo>(timeZoneBrowser);
  const timeZoneIdMerchantLast = useRef<string>();

  const setTimeZone = (timeZoneName: TimeZoneTitle) => {
    switch (timeZoneName) {
      case TimeZoneTitle.BrowserTimezone:
        setTimeZoneInfo(timeZoneBrowser);
        break;
      case TimeZoneTitle.MerchantTimezone:
        if (timeZoneIdMerchantLast.current) {
          setTimeZoneInfo({
            timeZoneTitle: timeZoneName,
            timeZoneId: timeZoneIdMerchantLast.current,
          });
        } else {
          setTimeZoneInfo(timeZoneBrowser);
        }
        break;
      default:
        setTimeZoneInfo({
          timeZoneTitle: timeZoneName,
          timeZoneId: UnitedStatesTimeZonesShortList[timeZoneName],
        });
        break;
    }
  };

  useEffect(() => {
    (async () => {
      if (!uid || !currentMid || [AllMIDs, 'undefined'].includes(currentMid)) {
        setTimeZoneInfo(timeZoneBrowser);
        return;
      }

      const timeZoneId = await getApplicationTimeZoneId(currentMid, uid, claims);

      if (timeZoneId) {
        timeZoneIdMerchantLast.current = timeZoneId;
        setTimeZoneInfo({
          timeZoneTitle: TimeZoneTitle.MerchantTimezone,
          timeZoneId: timeZoneId,
        });
      } else {
        timeZoneIdMerchantLast.current = undefined;
        setTimeZoneInfo(timeZoneBrowser);
      }
    })();
  }, [currentMid, uid, claims, setTimeZoneInfo]);

  return {
    timeZoneInfo,
    setTimeZone,
    merchantTimeZoneExists: Boolean(timeZoneIdMerchantLast.current),
  };
}

const getAgentById = async (agentId: string): Promise<Agent | undefined> => {
  const agentSnapshot = await Firebase.firestore.collection(Collections.agents).doc(agentId).get();

  if (agentSnapshot.exists) {
    return agentSnapshot.data() as Agent;
  }
};

const getApplicationTimeZoneId = async (
  mid: string,
  uid: string | undefined,
  claims: AuthUserClaims | undefined | null
): Promise<string | undefined> => {
  if (!mid || [AllMIDs, 'undefined'].includes(mid) || !uid) return;

  try {
    let query = Firebase.firestore.collection(Collections.applications).where('mid', '==', mid);

    // claims are only for portal
    if (claims && claims.role) {
      // merchants can only view their own mids
      if (claims.role === UserRoles.merchant) {
        query = query.where('merchantUid', '==', uid);
      }

      if (claims.role === UserRoles.agent) {
        // agent admins can view all group mids
        if (claims.groupRole === GroupRole.administrator && claims.groupId) {
          query = query.where('group.id', '==', claims.groupId);
        }

        // agent members can view all of their merchant's mids
        if (claims.groupRole === GroupRole.member) {
          const agent = await getAgentById(uid);

          if (agent) {
            if (agent.subAgentUids && agent.subAgentUids.length) {
              query = query.where('agent.id', 'in', [...agent.subAgentUids, uid]);
            } else {
              query = query.where('agent.id', '==', uid);
            }
          }
        }
      }
    }

    const snap = await query.get();

    if (snap.size === 1) {
      const tz: GeoTimeZone = snap.docs[0].get('businessTimeZone');
      return tz.timeZoneId;
    } else {
      console.warn(
        `application query for mid ${mid} has returned invalid number of results ${snap.size}`
      );
    }
  } catch (err: any) {
    // if field not found -> error happens here
    console.error('firestore field retrieval failure', err.code || err.message);
  }
};
