import {Button, Divider, Drawer, Grid, MenuItem, TextField, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {AgentView} from '@ozark/common';
import {ChangeEvent, useState} from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: 400,
    },
    drawerTitle: {
      padding: theme.spacing(2, 2, 2, 2),
    },
    agentAssignSelect: {
      margin: theme.spacing(2, 1, 1, 1),
      minWidth: 300,
    },
    title: {
      padding: theme.spacing(2, 3, 2, 3),
    },
    select: {
      padding: theme.spacing(2, 3, 2, 3),
    },
  })
);

type Props = {
  open: boolean;
  agents: AgentView[];
  onClose: () => void;
  onAgentSelect: (agentId: string) => void;
};

export const AgentSelectDrawer = ({open, agents, onClose, onAgentSelect}: Props) => {
  const [selectedAgentId, setSelectedAgentId] = useState('');
  const classes = useStyles();

  const handleAgentChange = (event: ChangeEvent<{name?: string | undefined; value: unknown}>) => {
    setSelectedAgentId(event.target.value as string);
  };

  const handleSave = () => {
    onAgentSelect(selectedAgentId!);
    onClose();
  };

  const onCancel = () => {
    setSelectedAgentId('');
    onClose();
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <div className={classes.drawer}>
        <Grid container direction="column">
          <Grid item xs className={classes.title}>
            <Typography variant="subtitle1">Assign New Agent to MID(s)</Typography>
          </Grid>
          <Grid item xs>
            <Divider />
          </Grid>
          <Grid item className={classes.select}>
            <Typography variant="body2">
              Select one agent below. The selected agent will be assigned to the selected MID(s).
            </Typography>
          </Grid>
          <Grid item xs className={classes.select}>
            <TextField
              select
              label="New Agent"
              value={selectedAgentId}
              onChange={handleAgentChange}
              className={classes.agentAssignSelect}
            >
              {agents.sortAndMap(
                agent => (
                  <MenuItem key={agent.id} value={agent.id}>
                    <Typography gutterBottom>
                      {agent.firstName} {agent.lastName}
                    </Typography>
                  </MenuItem>
                ),
                agent => `${agent.firstName} ${agent.lastName}`
              )}
            </TextField>
          </Grid>
          <Grid item className={classes.select}>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  size="small"
                  onClick={handleSave}
                  variant="contained"
                  color="primary"
                  disabled={!Boolean(selectedAgentId)}
                >
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button size="small" onClick={onCancel} variant="contained">
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Drawer>
  );
};
