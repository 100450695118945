import {subDays} from 'date-fns';
import {orderBy} from 'lodash';
import {useEffect, useState} from 'react';
import {TicketStatus, UniversalTimestamp} from '../../..';
import {statePromised} from '../constants/constants';
import {FilterKey} from '../types';
import {whenAllTickets} from '../utils';
import {useTicketsMyAssignee} from './ticketsSlices/useTicketsMyAssignee';
import {useTicketsMyCreator} from './ticketsSlices/useTicketsMyCreator';
import {useTicketsQuery} from './useTicketsQuery';

export function useTicketsMy(statusSet: TicketStatus[], filterKey?: FilterKey) {
  const {sortBy, sortDir} = useTicketsQuery();
  const {ticketsMyCreator} = useTicketsMyCreator(statusSet);
  const {ticketsMyAssignee} = useTicketsMyAssignee(statusSet);
  const [tickets, setTickets] = useState(statePromised);

  useEffect(() => {
    whenAllTickets([ticketsMyCreator, ticketsMyAssignee], mergedTickets => {
      let ticketsAggregated = orderBy(mergedTickets, [sortBy], [sortDir]);
      if (filterKey) {
        switch (filterKey) {
          case FilterKey.moreThen24h:
            ticketsAggregated = ticketsAggregated.filter(
              t =>
                t.openedAt && (t.openedAt as UniversalTimestamp).toDate() < subDays(new Date(), 1)
            );
            break;
        }
      }
      setTickets({promised: false, data: ticketsAggregated});
    });
  }, [ticketsMyAssignee, ticketsMyCreator, sortBy, sortDir, filterKey]);

  return {
    tickets,
    counter: tickets.data?.length ?? 0,
  };
}
