import {Divider, Toolbar, Typography} from '@mui/material';
import {ResourceDocumentCategoryView, ResourceType, ResourceView} from '@ozark/common';
import {Fragment} from 'react';
import {TooltipPlacement} from '../../../material-ui';
import {InlineTypography} from '../../Inline';
import {DisplayMode} from '../types';
import {styles, useStyles} from './constants';
import {useDisplayContext} from './DisplayContext';
import {DisplayDocumentsAsTable} from './DisplayDocumentsAsTable';
import {DisplayVideosAsTable} from './DisplayVideosAsTable';

interface Props {
  resources: ResourceView[];
  documentCategories: ResourceDocumentCategoryView[];
}

export const DisplayAllResources = ({resources, documentCategories}: Props) => {
  const classes = useStyles();
  const {allowEdit, displayMode, handleValidateCategoryName, handleSaveCategory} =
    useDisplayContext();

  if (resources.length === 0 || documentCategories.length === 0) {
    return <Typography sx={styles.noContent}>No Resources</Typography>;
  }

  return (
    <>
      {documentCategories
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(category => {
          const resourcesByCategory = resources
            .filter(doc => doc.category === category.name)
            .sort((a, b) => (a.order < b.order ? -1 : 1));

          if (resourcesByCategory.length === 0) return null;

          const videoResourcesByCategory: ResourceView[] = resourcesByCategory.filter(
            r => r.type === ResourceType.video
          );
          const documentResourcesByCategory: ResourceView[] = resourcesByCategory.filter(
            r => r.type === ResourceType.document
          );

          return (
            <Fragment key={category.id}>
              <Toolbar sx={styles.categoryToolbar}>
                {allowEdit ? (
                  <InlineTypography
                    tooltip="Click to edit Category name"
                    value={category.name}
                    className={classes.categoryTypography}
                    onValidate={(newValue: string) => handleValidateCategoryName(newValue)}
                    setText={newValue => handleSaveCategory(category.id, newValue)}
                    tooltipPlacement={TooltipPlacement.Bottom}
                  />
                ) : (
                  <Typography variant="h6" sx={styles.categoryTypography} component="div">
                    {category.name}
                  </Typography>
                )}
              </Toolbar>
              <Toolbar sx={styles.toolbar}>
                <Typography sx={styles.title}>Videos:</Typography>
              </Toolbar>
              {displayMode === DisplayMode.list && (
                <DisplayVideosAsTable videos={videoResourcesByCategory} categoryView={category} />
              )}
              <Toolbar sx={styles.toolbar}>
                <Typography sx={styles.title}>Documents:</Typography>
              </Toolbar>
              {displayMode === DisplayMode.list && (
                <DisplayDocumentsAsTable
                  documents={documentResourcesByCategory}
                  categoryView={category}
                />
              )}
              <Divider orientation="horizontal" flexItem />
            </Fragment>
          );
        })}
    </>
  );
};
