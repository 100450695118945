import {Box, Divider, Stack} from '@mui/material';
import {AgentSalesRecord, AgentsSales} from '@ozark/functions/src/functions/express/private/types';
import {add} from 'date-fns';
import {isEmpty, orderBy} from 'lodash';
import {useEffect, useState} from 'react';
import {
  AutoCompleteInputBase,
  BoxParentHeight,
  ButtonExportCsv,
  Filter,
  FilterOption,
  forceActiveFilter,
  InfoLoading,
  InfoNoData,
  MonthYearPicker,
  Square,
  Table,
} from '../..';
import {
  formatAsPercent,
  formatterCurrency,
  formatterNumber,
  SearchCriteria,
  useApiContainer,
} from '../../..';
import {Column} from '../../../api/Column';
import {useQueryMonthYear} from '../../Analytics/common/useQueryMonthYear';

const ensureNumbers = (agentSales: AgentsSales | null) => {
  return agentSales
    ? {
        ...agentSales,
        agentSales: agentSales.agentSales.map(i => ({
          ...i,
          salesVolume: Number(i.salesVolume ?? 0),
          salesCount: Number(i.salesCount ?? 0),
        })),
      }
    : undefined;
};

export const AgentStatisticsSales = () => {
  const api = useApiContainer();
  const [loading, setLoading] = useState(true);
  const [agentsSales, setAgentsSales] = useState<AgentsSales>();
  const [agentSelected, setAgentSelected] = useState<AgentSalesRecord>();
  const [agentsFiltered, setAgentsFiltered] = useState<AgentSalesRecord[]>([]);
  const {year, month} = useQueryMonthYear();
  const [search, setSearch] = useState<string>();
  const [filters, setFilters] = useState<Filter>({}); // defines date scope
  const [pageConfig, setPageConfig] = useState<SearchCriteria>({
    limit: 100, // page size
    offset: 1, // page number
    orderBy: 'salesVolumeRatio',
    order: 'desc',
  });
  const hasData = Boolean(agentsFiltered?.length);
  const handleAgentSelect = (agent: AgentSalesRecord | null) => {
    setSearch(agent?.agentName);
  };

  useEffect(() => {
    if (isEmpty(filters)) return;
    setLoading(true);
    let isMounted: true | undefined = true;
    api?.agentStatistics
      .getAgentsStatisticsSales(pageConfig, Object.values(filters))
      .then(result => isMounted && setAgentsSales(ensureNumbers(result)))
      .finally(() => isMounted && setLoading(false));
    return () => (isMounted = undefined);
  }, [filters]);
  useEffect(
    () => setAgentsFiltered(filterAgents(agentsSales?.agentSales, search)),
    [agentsSales, search]
  );
  useEffect(() => {
    const agentsFilteredSorted = orderBy(
      agentsFiltered,
      [pageConfig.orderBy],
      [pageConfig.order.toLowerCase() as 'desc' | 'asc']
    );
    setAgentsFiltered(agentsFilteredSorted);
  }, [pageConfig]);
  useEffect(() => {
    const startingDate = new Date(Date.UTC(year, month - 1, 1));
    const transactionDate = forceActiveFilter(AgentSalesFilters, 'transactionDate', '__between', [
      startingDate,
      add(startingDate, {months: 1}),
    ]);
    setFilters(previous => ({...previous, transactionDate}));
  }, [year, month]);

  return (
    <>
      <Box display="flex">
        <Box flex={1} />
        <AutoCompleteInputBase
          selected={agentSelected}
          setSelected={setAgentSelected}
          icon
          placeholder="Select agent..."
          width={200}
          options={(agentsSales?.agentSales ?? []) as AgentSalesRecord[]}
          getOptionLabel={(agent: AgentSalesRecord) => agent.agentName}
          onItemSelect={handleAgentSelect}
        />
        <Divider orientation="vertical" flexItem light sx={{mx: 2}} />
        <MonthYearPicker />
        <Divider orientation="vertical" flexItem light sx={{mx: 2}} />
        <ButtonExportCsv
          filename="agent-statistics-sales"
          rows={agentsFiltered}
          columnsConfig={columns}
        />
      </Box>
      {loading && <InfoLoading />}
      {!loading && !hasData && <InfoNoData />}
      {!loading && hasData && (
        <>
          <Stack mt={2} direction="row" spacing={2}>
            <Box flex={1}>
              <Square
                lines={{
                  'All Agents Sales Count': formatterNumber.format(
                    Number(agentsSales?.totalAgentsSalesCount ?? 0)
                  ),
                }}
                center
              />
            </Box>
            <Box flex={1}>
              <Square
                lines={{
                  'All Agents Sales Volume': formatterNumber.format(
                    Number(agentsSales?.totalAgentsVolume ?? 0)
                  ),
                }}
                center
              />
            </Box>
          </Stack>
          <BoxParentHeight my={2}>
            <Table
              columns={columns}
              data={{
                sort: [[pageConfig.orderBy, pageConfig.order as 'DESC' | 'ASC']],
                limit: pageConfig.limit,
                offset: pageConfig.offset,
                totalCount: agentsFiltered.length,
                data: agentsFiltered,
              }}
              onRetrieveData={setPageConfig}
              stickyHeader
            />
          </BoxParentHeight>
        </>
      )}
    </>
  );
};

const filterAgents = (agents: AgentSalesRecord[] = [], term?: string) =>
  term ? agents.filter(a => a.agentName.toLowerCase().includes(term.toLowerCase())) : agents;

const columns: Column<AgentSalesRecord>[] = [
  {
    id: 'agentName',
    label: 'Agent Name',
    export: true,
  },
  {
    id: 'salesCount',
    label: 'Sales Count',
    sortable: true,
    numeric: true,
    selector: row => formatterNumber.format(Number(row.salesCount ?? 0)),
    export: true,
  },
  {
    id: 'salesCountRatio',
    label: 'Sales Count / Total Count',
    sortable: true,
    numeric: true,
    selector: row => formatAsPercent(row.salesCountRatio),
    export: true,
  },
  {
    id: 'salesVolume',
    label: 'Sales Volume',
    sortable: true,
    numeric: true,
    selector: row => formatterCurrency.format(Number(row.salesVolume ?? 0)),
    export: true,
  },
  {
    id: 'salesVolumeRatio',
    label: 'Sales Volume / Total Volume',
    sortable: true,
    numeric: true,
    selector: row => formatAsPercent(row.salesVolumeRatio),
    export: true,
  },
];

export const AgentSalesFilters: FilterOption[] = [
  {
    id: 'transactionDate',
    column: 'transactionDate',
    label: 'Date Range',
    type: 'dateRange',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
];
