import {flatten, sortBy, uniqBy} from 'lodash';
import {
  Application,
  AuthorizedMerchant,
  AuthUserClaims,
  Collections,
  Firebase,
  GroupRole,
  UserRoles,
} from '..';

/** ERP Only - boarded merchants */
export async function getMerchantsAuthorized() {
  const snapshot = await Firebase.firestore
    .collection(Collections.applications)
    .where('mid', '!=', null)
    .get();

  if (snapshot.size) {
    const applications = snapshot.docs.map(mapApplication).filter(e => e.mid);
    // local db has duped mids in applications collection
    const applicationsUniq = uniqBy(applications, m => m.mid);
    return sortBy<AuthorizedMerchant>(applicationsUniq, e => e.doingBusinessAs);
  }

  return [];
}

/** specific boarded merchants  */
export async function getMerchantsAuthorizedByIds(ids: string[]) {
  if (!ids?.length) return [];

  const midsFilter = [...ids];
  let batches = [];

  while (midsFilter.length) {
    const batch = midsFilter.splice(0, 10);

    batches.push(
      Firebase.firestore
        .collection(Collections.applications)
        .where('mid', 'in', batch)
        .get()
        .then(snapshot => snapshot.docs.map(mapApplication).filter(e => e.mid))
    );
  }

  const applications = (await Promise.all(batches)).flatMap(merchant => merchant);
  return sortBy<AuthorizedMerchant>(applications, e => e.doingBusinessAs);
}

type GetMerchantsAuthorizedByClaimsParams = {
  /** authProfile?.data?.id , authUser.data?.uid */
  uid?: string;
  claims?: AuthUserClaims | null | undefined;
  /** only get by merchantUid ??? */
  agentId?: string;
  /** authProfile.data?.subAgentUids */
  subAgentUids?: string[]; // not used!
  midIds?: string[];
};

/** Portal Only - boarded merchants */
export async function getMerchantsAuthorizedByClaims({
  uid,
  claims,
  agentId,
  subAgentUids,
  midIds,
}: GetMerchantsAuthorizedByClaimsParams) {
  if (!uid || !claims) {
    console.warn('no uid, no claims provided');
    return [];
  }

  // merchants can only view their own mids
  if (claims.role === UserRoles.merchant) {
    // todo make work with authProfile?.data?.authorizedMids
    return await getMerchantIDs([['merchantUid', '==', uid]], midIds);
  }

  let queries: [filedPath: string, opStr: any, value: any][] = [];

  // admins can view all MIDs
  if (claims.role === UserRoles.admin) {
    queries.push(['mid', '!=', null]);
    if (agentId) {
      queries.push(['agent.id', '==', agentId]);
    }
    return await getMerchantIDs(queries, midIds);
  }

  // agent admins can view all group mids
  if (claims?.role === UserRoles.agent && claims?.groupRole === GroupRole.administrator) {
    queries.push(['group.id', '==', claims?.groupId]);
    if (agentId) {
      queries.push(['agent.id', '==', agentId]);
    }
    const merchantIds = await getMerchantIDs(queries, midIds);
    return merchantIds;
  }

  // agent members can view all of their merchant's mids
  if (claims?.role === UserRoles.agent && claims?.groupRole === GroupRole.member) {
    queries.push(['agent.id', '==', uid]);

    if (!subAgentUids) {
      return await getMerchantIDs(queries, midIds);
    }

    if (agentId) {
      queries.push(['agent.id', '==', agentId]);
      return await getMerchantIDs(queries, midIds);
    }

    const result = flatten(
      await Promise.all([
        getMerchantIDs(queries, midIds),
        getMerchantIDs([['agent.id', 'in', subAgentUids]], midIds),
      ])
    );

    return result;
  }

  // this should never happen
  throw new Error('not authorized to view merchant ids');
}

const getMerchantIDs = async (
  queries: [filedPath: string, opStr: any, value: any][],
  ids?: string[]
) => {
  let merchants: AuthorizedMerchant[] = [];

  const collection = Firebase.firestore.collection(Collections.applications);
  let query = collection as firebase.default.firestore.Query;
  for (const where of queries) {
    query = query.where(...where);
  }

  if (!ids || ids.length === 0) {
    const snapshot = await query.get();

    if (snapshot.size === 0) {
      return [];
    }

    merchants = snapshot.docs.map(mapApplication).filter(e => e.mid);
  } else {
    const midsFilter = [...ids];
    let batches = [];

    while (midsFilter.length) {
      const batch = midsFilter.splice(0, 10);

      batches.push(
        query
          .where('mid', 'in', batch)
          .get()
          .then(snapshot => snapshot.docs.map(mapApplication).filter(e => e.mid))
      );
    }

    merchants = (await Promise.all(batches)).flatMap(merchant => merchant);
  }

  return sortBy<AuthorizedMerchant>(merchants, e => e.doingBusinessAs);
};

const mapApplication = (
  doc: firebase.default.firestore.QueryDocumentSnapshot<firebase.default.firestore.DocumentData>
) => {
  const app = doc.data() as Application;
  return {
    applicationId: doc.id,
    mid: app.mid,
    firstName: app.firstName,
    lastName: app.lastName,
    legalBusinessName: app.legalBusinessName,
    doingBusinessAs: app.doingBusinessAs,
    createdAt: app.createdAt,
    email: app.customerServiceEmail,
    customerServiceEmail: app.customerServiceEmail,
    phoneNumber: app.businessPhone,
    groupName: app.group?.name,
    agent: `${app.agent?.firstName || ''} ${app.agent?.lastName || ''}`.trim(),
    disposition: app.disposition,
    merchantUid: app.merchantUid,
    invalidMerchantRegAttempts: app.invalidMerchantRegAttempts ?? 0,
  } as AuthorizedMerchant;
};
