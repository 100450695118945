import {styled} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {useEffect} from 'react';

const colorStroke = '#1877f2';
const strokeLength = '56.5px';
const strokeWidth = '2px';
const TIMER_DURATION_SEC = 15;

type Props = {
  itemId: string;
  onTimeout?: (itemId: string) => void;
};

export const ItemCountdownTimer = ({itemId, onTimeout}: Props) => {
  const classes = useStyles();

  useEffect(() => {
    const timeout = setTimeout(() => {
      onTimeout?.(itemId);
    }, TIMER_DURATION_SEC * 1000);

    return () => clearTimeout(timeout);
  }, [itemId, onTimeout]);

  return (
    <Svg viewBox="0 0 24 24" focusable="false">
      <circle style={{fill: colorStroke}} r="4" cx="12" cy="12"></circle>
      <circle className={classes.circleTrack} r="9" cx="12" cy="12"></circle>
      <circle className={classes.circle} r="9" cx="12" cy="12"></circle>
    </Svg>
  );
};

export const Svg = styled('svg')({
  transform: 'rotateZ(-90deg)',
  width: '1em',
  height: ' 1em',
  display: 'inline-block',
  fontSize: '1.5rem',
  flexShrink: 0,
  userSelect: 'none',
});

const useStyles = makeStyles({
  '@keyframes countdown': {
    from: {
      strokeDashoffset: strokeLength,
    },
    to: {
      strokeDashoffset: 0,
    },
  },
  circleTrack: {
    strokeWidth: strokeWidth,
    stroke: '#eee',
    fill: 'none',
  },
  circle: {
    strokeDasharray: strokeLength,
    strokeDashoffset: '0px',
    strokeLinecap: 'round',
    strokeWidth: strokeWidth,
    stroke: colorStroke,
    fill: 'none',
    animation: `$countdown ${TIMER_DURATION_SEC}s linear forwards`,
  },
});
