import StorefrontIcon from '@mui/icons-material/Storefront';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import {AllMIDs} from '@ozark/common';
import {AsyncState} from '../../models';

type Props = {
  mids: AsyncState<Map<string, string>>;
  handleSelectMid: (mid: string) => void;
  selectedMid: string;
  width?: number;
  disabled?: boolean;
};

export const MidSelect = ({mids, handleSelectMid, selectedMid, disabled, width = 500}: Props) => {
  if (mids.promised || !mids.data) return null;

  // If MIDs don't contain "all" option, that means this is an initial load
  // and selected mid will change.
  if (selectedMid === AllMIDs && !mids.data.get(AllMIDs)) return null;

  return (
    <Autocomplete
      disabled={disabled}
      id="combo-box-demo"
      options={Array.from(mids.data.keys())}
      getOptionLabel={option => mids.data!.get(option) ?? ''}
      style={{width}}
      size="small"
      value={selectedMid}
      onChange={(_event, newInputValue) => {
        if (newInputValue) {
          handleSelectMid(newInputValue);
        }
      }}
      renderInput={params => (
        <TextField
          {...params}
          variant="standard"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <StorefrontIcon />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
        />
      )}
    />
  );
};
