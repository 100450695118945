import {omit} from '@s-libs/micro-dash';

export enum PlacementType {
  purchase = 'purchase',
  reprogram = 'reprogram',
  varSheetOnly = 'varSheetOnly',
  gatewaySetupByProcessor = 'gatewaySetupByProcessor',
  freePlacementPriorApprovalRequired = 'freePlacementPriorApprovalRequired',
  rental = 'rental',
}

export const PlacementsValues: Record<PlacementType, string> = {
  [PlacementType.purchase]: 'Purchase',
  [PlacementType.reprogram]: 'Reprogram',
  [PlacementType.varSheetOnly]: 'VAR sheet only',
  [PlacementType.gatewaySetupByProcessor]: 'Gateway Setup by Processor',
  [PlacementType.freePlacementPriorApprovalRequired]: 'Free Placement - Prior Approval Required',
  [PlacementType.rental]: 'Rental',
};

export const PlacementsValuesGateway = omit(
  PlacementsValues,
  PlacementType.freePlacementPriorApprovalRequired,
  PlacementType.rental
);

export const PlacementsValuesNonGateway = omit(
  PlacementsValues,
  PlacementType.gatewaySetupByProcessor
);
