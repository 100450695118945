import {Divider, Popover, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Fragment} from 'react';
import {ListItem} from './ListItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: 500,
      padding: theme.spacing(2),
      overflow: 'scroll',
    },
  })
);

type LearnMoreProps = {
  anchorElement: HTMLButtonElement | null;
  onClose: () => void;
  description?: string;
  features?: string[];
};

export const LearnMore = ({anchorElement, onClose, description, features}: LearnMoreProps) => {
  const classes = useStyles();
  return (
    <Popover
      open={Boolean(anchorElement)}
      anchorEl={anchorElement}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <div className={classes.content}>
        {description && (
          <Typography gutterBottom>
            <div className="richtext-container" dangerouslySetInnerHTML={{__html: description}} />
          </Typography>
        )}
        {features?.map((e, i) => (
          <Fragment key={`${e}`}>
            {i > 0 && <Divider />}
            <ListItem>{e}</ListItem>
          </Fragment>
        ))}
      </div>
    </Popover>
  );
};
