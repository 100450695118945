import {LoadingButton} from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ChangeEventHandler, cloneElement, useEffect, useRef, useState} from 'react';
import RichTextEditor, {EditorValue} from 'react-rte';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    paper: {
      width: '80%',
      maxHeight: 435,
    },
    expanded: {
      margin: '0 !important' as any,
    },
    rootAccordionDetails: {
      flexDirection: 'column',
      alignContent: 'flex-start',
      justifyContent: 'flex-start',
    },
    richTextEditorNote: {
      '& button[title="Align Left"]': {
        display: 'none',
      },
      '& button[title="Align Center"]': {
        display: 'none',
      },
      '& button[title="Align Right"]': {
        display: 'none',
      },
      '& button[title="Align Justify"]': {
        display: 'none',
      },
      '& button[title="Link"]': {
        display: 'none',
      },
      '& button[title="Remove Link"]': {
        display: 'none',
      },
      '& button[title="Image"]': {
        display: 'none',
      },
      fontFamily: 'inherit',
      fontSize: 'inherit',
      font: 'inherit',
    },
  })
);

export interface ConfirmationDialogFieldProps {
  name: string;
  label: string;
  defaultValue: string | boolean | EditorValue;
  customElementMarkup: JSX.Element | null;
  inputType?: string;
  selectOptions?: {label: string; value: string}[];
  visible?: [fieldName: string, value: string];
}

export class ConfirmationDialogField implements ConfirmationDialogFieldProps {
  public name: string;
  public label: string;
  public defaultValue: string | boolean | EditorValue;
  public customElementMarkup: JSX.Element | null;
  public inputType?: string;
  public selectOptions?: {label: string; value: string}[];
  public visible?: [fieldName: string, value: string];

  constructor(
    name: string,
    label: string,
    defaultValue: string | boolean | EditorValue,
    customElementMarkup: JSX.Element | null,
    inputType?: string,
    visible?: [fieldName: string, value: string],
    selectOptions?: {label: string; value: string}[]
  ) {
    this.name = name;
    this.label = label;
    this.defaultValue = defaultValue;
    this.customElementMarkup = customElementMarkup;
    this.inputType = inputType;
    this.visible = visible;
    this.selectOptions = selectOptions;
  }
}

export interface Props {
  title: string;
  message: string;
  fields?: ConfirmationDialogFieldProps[];
  onClose: () => void;
  onConfirm: ((form?: any) => Promise<void> | void) | null;
  useRichTextEditor?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  helperText?: string;
}

export const ConfirmationDialog = ({
  helperText,
  onClose,
  onConfirm,
  title,
  message,
  fields,
  useRichTextEditor,
  maxWidth,
  ...other
}: Props) => {
  const classes = useStyles();

  const [form, setForm] = useState<any>(null);
  const [expandedByKey, setExpandedByKey] = useState<boolean[]>([]);
  const [textEditorText, setTextEditorText] = useState<{key: string; value: EditorValue}[]>();
  const [loading, setLoading] = useState(false);
  const confirmBtnRef = useRef<HTMLButtonElement | null>(null);

  // sets the default form values
  useEffect(() => {
    if (form || !fields) return;

    setExpandedByKey([
      ...fields.map((fieldType: ConfirmationDialogFieldProps): boolean => {
        var stringValue = fieldType.defaultValue as string;
        var richText = fieldType.inputType === 'rich-text';

        return !!(richText && stringValue);
      }),
    ]);

    setTextEditorText(
      fields
        .filter((fieldType: ConfirmationDialogFieldProps) => {
          return fieldType.inputType === 'rich-text';
        })
        .map((fieldType: ConfirmationDialogFieldProps) => {
          var stringValue = fieldType.defaultValue as string;
          return {
            key: fieldType.name,
            value: RichTextEditor.createValueFromString(stringValue || '', 'html'),
          };
        })
    );

    const newForm = fields.reduce((a, b) => {
      const field = b as ConfirmationDialogFieldProps;
      const defaultValue = field.defaultValue || '';
      a[field.name] = form ? form[field.name] || defaultValue : defaultValue;

      return a;
    }, {} as any);

    setForm(newForm);
  }, [fields]);

  const handleTextChange: ChangeEventHandler<HTMLInputElement> = e => {
    setForm({...form, [e.target.name]: e.target.value});
  };

  const handleCancel = () => {
    onClose();
  };

  const handleConfirm = async () => {
    if (!onConfirm || loading) {
      return;
    }

    confirmBtnRef.current?.setAttribute('disabled', 'disabled');
    setLoading(true);

    await onConfirm(form);

    setLoading(false);
    confirmBtnRef.current?.removeAttribute('disabled');

    onClose();
  };

  const getTextEditorValue = (name: string) =>
    textEditorText?.find(field => field.key === name)?.value || RichTextEditor.createEmptyValue();

  const renderCustomField = (
    id: number,
    fieldType: ConfirmationDialogField,
    fieldsSet: ConfirmationDialogFieldProps[]
  ) => {
    return (
      <Accordion key={id} expanded={expandedByKey[id]} classes={{expanded: classes.expanded}}>
        <AccordionSummary aria-label="Expand" aria-controls={fieldType.name} id={fieldType.name}>
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={event => {
              event.stopPropagation();
              if (fieldType.inputType !== 'checkbox') {
                const _expandedByKey = expandedByKey.slice();
                const idx = fields!.findIndex(field => field.name === fieldType.name);
                _expandedByKey.splice(idx, 1, !expandedByKey[idx]);

                setExpandedByKey(_expandedByKey);
              } else {
                const oldValue = form[fieldType.name] === 'true';
                setForm({...form, [fieldType.name]: Boolean(!oldValue).toString()});
              }
            }}
            onFocus={event => event.stopPropagation()}
            control={
              fieldType.inputType === 'checkbox' ? (
                <Checkbox
                  value={form[fieldType.name]}
                  checked={form[fieldType.name] === 'true'}
                  onChange={() => {
                    const oldValue = form[fieldType.name] === 'true';
                    setForm({...form, [fieldType.name]: Boolean(!oldValue).toString()});
                  }}
                />
              ) : (
                <Checkbox
                  checked={
                    expandedByKey[fieldsSet.findIndex(field => field.name === fieldType.name)]
                  }
                  onChange={() => {
                    if (
                      !expandedByKey[fieldsSet.findIndex(field => field.name === fieldType.name)]
                    ) {
                      const oldValue = form[fieldType.name] === 'true';
                      setForm({...form, [fieldType.name]: Boolean(!oldValue).toString()});
                    }
                  }}
                />
              )
            }
            label={fieldType.label}
          />
        </AccordionSummary>
        <AccordionDetails classes={{root: classes.rootAccordionDetails}}>
          {fieldType.inputType === 'checkbox' ? (
            <Typography variant="h4" color="textSecondary">
              {fieldType.label}
            </Typography>
          ) : (
            <RichTextEditor
              className={classes.richTextEditorNote}
              onChange={(event: EditorValue) => {
                const _textFields = textEditorText!.slice();
                const textIdx = textEditorText!.findIndex(field => field.key === fieldType.name);
                _textFields.splice(textIdx, 1, {key: fieldType.name, value: event});
                setTextEditorText(_textFields);

                const value = event.toString('html');
                setForm({...form, [fieldType.name]: value});
              }}
              value={getTextEditorValue(fieldType.name)}
            />
          )}
        </AccordionDetails>
      </Accordion>
    );
  };

  const mapField = (
    fieldsSet: ConfirmationDialogFieldProps[],
    fieldType: ConfirmationDialogFieldProps,
    id: number
  ) => {
    if (fieldType.inputType && !fieldType.customElementMarkup) {
      if (!fieldType.visible || form[fieldType.visible[0]] === fieldType.visible[1]) {
        return renderCustomField(id, fieldType, fieldsSet);
      }
    } else if (fieldType.customElementMarkup) {
      return cloneElement(fieldType.customElementMarkup, {
        value: form[fieldType.name],
        key: fieldType.name,
        onChange: handleTextChange,
      });
    } else {
      if (fieldType.selectOptions) {
        return (
          <TextField
            variant="outlined"
            key={fieldType.name}
            label={fieldType.label}
            name={fieldType.name}
            value={form[fieldType.name] || ''}
            margin="normal"
            onChange={handleTextChange}
            fullWidth
            select
          >
            {fieldType.selectOptions.map((e, i) => {
              return (
                <MenuItem key={i} value={e.value}>
                  {e.label}
                </MenuItem>
              );
            })}
          </TextField>
        );
      } else {
        return (
          <TextField
            variant="outlined"
            key={fieldType.name}
            label={fieldType.label}
            name={fieldType.name}
            value={form[fieldType.name] || ''}
            margin="normal"
            onChange={handleTextChange}
            fullWidth
          />
        );
      }
    }
  };

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth={maxWidth || 'xs'}
      keepMounted
      aria-labelledby="confirmation-dialog-title"
      open={Boolean(onConfirm)}
      classes={{
        paper: classes.paper,
      }}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" align="left" gutterBottom>
          {message}
        </Typography>
        {form &&
          fields?.map((fieldType: ConfirmationDialogFieldProps, id: number) => {
            return mapField(fields, fieldType, id);
          })}
        {helperText && (
          <Box sx={{color: 'grey', fontSize: '13px', marginTop: '12px'}}>{helperText}</Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <LoadingButton
          ref={confirmBtnRef}
          disabled={loading}
          loading={loading}
          onClick={handleConfirm}
          color="primary"
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
