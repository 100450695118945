import {InputAdornment, Popover} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {TextField} from '@ozark/common/components';
import React, {Fragment} from 'react';
import {ColorResult, SketchPicker} from 'react-color';
import {UseFormReturn} from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    swatch: {
      height: theme.spacing(3),
      width: theme.spacing(6),
      background: '#fff',
      padding: theme.spacing(0.5),
      borderRadius: 1,
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    color: {
      height: '100%',
      width: '100%',
    },
  })
);

export const TextFieldColorPicker = ({
  name,
  label,
  defaultValue,
  hookForm,
  hexColor,
  onColorChange,
  disabled,
}: {
  name: string;
  label: string;
  defaultValue: string;
  hookForm: UseFormReturn<any, any>;
  hexColor: string;
  onColorChange: (color: ColorResult) => void;
  disabled?: boolean;
}) => {
  const classes = useStyles();
  const {
    formState: {errors},
    control,
    register,
  } = hookForm;
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const handleColorPickerClick = (event: React.MouseEvent<HTMLDivElement | HTMLInputElement>) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <Fragment>
      <TextField
        name={name}
        label={label}
        required
        errors={errors}
        control={control}
        defaultValue={defaultValue}
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <div
                className={classes.swatch}
                onClick={disabled ? () => {} : handleColorPickerClick}
              >
                <div className={classes.color} style={{backgroundColor: hexColor}} />
              </div>
            </InputAdornment>
          ),
        }}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <SketchPicker color={hexColor} onChange={onColorChange} />
      </Popover>
    </Fragment>
  );
};
