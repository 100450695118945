import {SearchableTokens} from '@ozark/common';

type FilterList<T> = T & SearchableTokens;
export const filterListByInput = <T>(
  list: FilterList<T>[] | undefined,
  filterText: string
): FilterList<T>[] => {
  if (list === undefined) {
    return [];
  }
  if (filterText === '') {
    return list;
  }
  const searchTokens = filterText
    .trim()
    .toLowerCase()
    .replaceAll(/[^\w\s]/g, '')
    .split(/\s+/)
    .map(s => s.trim());
  if (searchTokens.length === 0) {
    return list;
  }

  return list.filter(item => {
    return item.searchTokens.some(sourceToken =>
      searchTokens.some(searchToken => sourceToken.indexOf(searchToken) > -1)
    );
  });
};
