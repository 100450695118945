import {Checkbox, CheckboxProps} from '@mui/material';
import {CreateDisputeReasonProps, ReasonInputType} from '@ozark/common';
import camelCase from 'lodash/camelCase';
import {DisputeReason} from './index';

export const createCheckboxReason = ({
  name,
  description,
}: CreateDisputeReasonProps): DisputeReason => ({
  name: camelCase(name),
  title: name,
  inputType: ReasonInputType.Checkbox,
  descriptionHtml: description,
  Description: () => <div dangerouslySetInnerHTML={{__html: description}} />,
  FieldInput: (props: CheckboxProps) => (
    <Checkbox name={props.name} {...props} checked={!!props.value} />
  ),
});
