import {
  MerchantAnalytics,
  PaginatedResponse,
} from '@ozark/functions/src/functions/express/private/types';
import {ContextKey} from '@ozark/functions/src/functions/express/private/types/Context';
import {Application, ApplicationAnalytics} from '@ozark/functions/src/shared';
import {CancelToken} from 'axios';
import {ApplicationAnalyticValueTypes} from '../components/Dashboard/ApplicationAnalytics';
import {VolumeFilter} from '../components/FilterPeriodSelect';
import {toQuery} from '../util';
import {ApiBase} from './ApiBase';
import {SearchCriteria} from './SearchCriteria';

export class DashboardApi extends ApiBase {
  route = 'dashboard';

  getApplicationAnalytics = (
    volumeFilter: VolumeFilter,
    mid?: string,
    cancelToken?: CancelToken
  ) => {
    return this.post<ApplicationAnalytics>(
      `${this.route}/application-analytics`,
      {
        [ContextKey.StartDateTime]: volumeFilter.startDate,
        [ContextKey.EndDateTime]: volumeFilter.endDate,
      },
      {[ContextKey.Mid]: mid},
      cancelToken
    );
  };

  getApplicationAnalyticsDetails = (
    searchCriteria: SearchCriteria,
    type: ApplicationAnalyticValueTypes,
    startDate: Date | null,
    endDate: Date
  ) => {
    const queryString = toQuery(searchCriteria);
    return this.post<PaginatedResponse<Application>>(
      `${this.route}/application-analytics-details?${queryString}`,
      {
        [ContextKey.StartDateTime]: startDate,
        [ContextKey.EndDateTime]: endDate,
        type,
      }
    );
  };

  getMerchantAnalytics = (volumeFilter: VolumeFilter, mid?: string, cancelToken?: CancelToken) => {
    return this.post<MerchantAnalytics>(
      `${this.route}/merchant-analytics`,
      {
        [ContextKey.StartDateTime]: volumeFilter.startDate,
        [ContextKey.EndDateTime]: volumeFilter.endDate,
      },
      {[ContextKey.Mid]: mid},
      cancelToken
    );
  };
}
