import EditIcon from '@mui/icons-material/Edit';
import {Box, IconButton} from '@mui/material';
import {Controller, useWatch} from 'react-hook-form';
import {TicketTypeAndCategory, useUserInfo} from '../../../..';
import {BoxFlexed} from '../../../common';
import {TextFieldRequired} from '../../common/TextFieldRequired';
import {FIELD_NAME_ASSIGNEE, FIELD_NAME_CATEGORY} from '../../constants/constants';
import {useIsEditingDisabled} from '../../hooks/useIsEditingDisabled';
import {AssigneeEditor} from './AssigneeEditor';
import {AssigneeInfo} from './AssigneeInfo';

export const Assignee = () => {
  const {isEditingDisabled} = useIsEditingDisabled();

  const category = useWatch<TicketTypeAndCategory>({name: FIELD_NAME_CATEGORY});

  const disabled = isEditingDisabled || !category;

  const {isPortal} = useUserInfo();

  return (
    <Controller
      name={FIELD_NAME_ASSIGNEE}
      rules={{required: true}}
      render={({fieldState: {error}}) => (
        <BoxFlexed alignItems="flex-start">
          {error && <TextFieldRequired />}
          {!error && (
            <Box flex={1}>
              <AssigneeInfo />
            </Box>
          )}
          {!isPortal && (
            <AssigneeEditor
              disabled={disabled}
              buttonComponent={({disabled, onClick}) => (
                <IconButton onClick={onClick} disabled={disabled}>
                  <EditIcon />
                </IconButton>
              )}
            />
          )}
        </BoxFlexed>
      )}
    />
  );
};
