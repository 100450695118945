import {MoreVert} from '@mui/icons-material';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {Fragment, MouseEventHandler} from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: '100%',
      padding: theme.spacing(1, 2, 1),
      color: '#4d6575',
      borderTop: `solid 4px ${theme.palette.primary.main}`,
    },
    title: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      height: 48,
      alignItems: 'center',
      margin: theme.spacing(0, 0, 0.5),
      '& > *': {
        margin: theme.spacing(0, 1),
      },
    },
    titleText: {
      flex: 1,
    },
    menuButton: {
      marginRight: 0,
    },
    list: {
      padding: theme.spacing(0.5, 0, 0),
    },
    text: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
);

type SquareProps = {
  icon?: JSX.Element;
  title?: string | React.ReactNode;
  lines: Record<string, string | React.ReactNode>;
  onClick?: () => void;
  center?: boolean;
  dense?: boolean;
  menuItems?: {name: string; action: () => void}[];
};

export const Square = ({icon, title, lines, onClick, menuItems, dense, center}: SquareProps) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleMenuClick: MouseEventHandler<HTMLButtonElement> = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Paper
      className={classes.paper}
      onClick={onClick}
      sx={{cursor: !!onClick ? 'pointer' : 'auto'}}
    >
      {title && (
        <Fragment>
          <Typography className={classes.title} variant="body1" component="div" noWrap>
            {icon} <b className={classes.titleText}>{title}</b>
            {menuItems && menuItems.length > 0 && (
              <IconButton
                className={classes.menuButton}
                color="inherit"
                onClick={handleMenuClick}
                size="large"
              >
                <MoreVert />
              </IconButton>
            )}
          </Typography>
          <Divider />{' '}
        </Fragment>
      )}
      <List className={classes.list} dense={dense}>
        {Object.keys(lines).map(key => {
          return (
            <ListItem key={key}>
              <ListItemText
                className={classes.text}
                style={{textAlign: center ? 'center' : 'inherit'}}
                primary={
                  <Typography variant={dense ? 'body1' : 'h5'} noWrap gutterBottom={!dense}>
                    {lines[key]}
                  </Typography>
                }
                secondary={
                  <Typography noWrap variant={dense ? 'caption' : 'body2'}>
                    <span dangerouslySetInnerHTML={{__html: key}} />
                  </Typography>
                }
              />
            </ListItem>
          );
        })}
      </List>
      {menuItems && menuItems.length > 0 && (
        <Menu
          id="optional-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          {menuItems.sortAndMap(
            e => {
              return (
                <MenuItem
                  onClick={() => {
                    e.action();
                    handleCloseMenu();
                  }}
                >
                  {e.name}
                </MenuItem>
              );
            },
            e => e.name
          )}
        </Menu>
      )}
    </Paper>
  );
};
