import {Box, Button, FilledInput, FormControl, FormHelperText} from '@mui/material';
import {useRef} from 'react';
import {Controller, useFormContext, useWatch} from 'react-hook-form';
import {Collections, Firebase, TicketHistoryRecordType} from '../../..';
import {useUserInfo} from '../../../hooks/useUserInfo';
import {FIELD_NAME_SUBJECT} from '../constants/constants';
import {useHistoryRecord} from '../hooks/useHistoryRecord';
import {useIsEditingClosed} from '../hooks/useIsEditingClosed';
import {useTicketEditContainer} from '../hooks/useTicketEditContainer';
import {useTicketId} from '../hooks/useTicketId';
import {useTicketStatus} from '../hooks/useTicketStatus';

export const saveSubject = (ticketId: string, subject: string) => {
  return Firebase.firestore
    .collection(Collections.tickets)
    .doc(ticketId)
    .set({subject, updatedAt: Firebase.now()}, {merge: true});
};

export function InputSubject() {
  const {openEditors, setOpenEditors} = useTicketEditContainer();
  const {isEditingClosed} = useIsEditingClosed();
  const {isErpAdmin, userDisplayName} = useUserInfo();
  const {ticketId} = useTicketId();
  const {
    setValue,
    formState: {errors},
  } = useFormContext();
  const {isDraft} = useTicketStatus();
  const enabled = !isEditingClosed && (isDraft || isErpAdmin);
  const valueBeforeChange = useRef<string>();
  const {addHistoryRecord} = useHistoryRecord();
  const value: string | undefined = useWatch({name: FIELD_NAME_SUBJECT});

  const isActive = openEditors.has('subject');
  const setIsActive = (isActive: boolean) => {
    const openEditorsUpdated = new Set(openEditors);
    if (isActive) {
      openEditorsUpdated.add('subject');
    } else {
      openEditorsUpdated.delete('subject');
    }
    setOpenEditors(openEditorsUpdated);
  };

  return (
    <div>
      <Controller
        name={FIELD_NAME_SUBJECT}
        rules={{required: 'Subject is required'}}
        defaultValue={''}
        render={({field, fieldState}) => (
          <FormControl variant="filled" fullWidth error={Boolean(fieldState.error)}>
            <FilledInput
              {...field}
              onClick={() => {
                valueBeforeChange.current = value;
                enabled && setIsActive(true);
              }}
              onChange={e => {
                setValue(FIELD_NAME_SUBJECT, e.target.value, {shouldValidate: true});
                setIsActive(true);
              }}
              placeholder="Enter ticket subject"
              sx={{
                '&.MuiFilledInput-root': {
                  backgroundColor: '#f5f8fa !important',
                },
                '&.MuiFilledInput-root:hover': {
                  backgroundColor: '#f5f8fab3 !important',
                },
                '& .MuiFilledInput-input:focus': {
                  backgroundColor: '#f5f8fa !important',
                },
                '& .MuiInputBase-input.Mui-disabled': {
                  color: 'black',
                  WebkitTextFillColor: 'inherit',
                  backgroundColor: '#dde1e6',
                },
              }}
              fullWidth
              inputRef={field.ref}
              required
              hiddenLabel
              size="small"
              disabled={!enabled}
            />
            {fieldState.error && (
              <FormHelperText id={FIELD_NAME_SUBJECT}>
                {fieldState.error.message ?? ' '}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
      {enabled && isActive && (
        <Box my={1}>
          <Button
            size="small"
            variant="contained"
            disabled={!Boolean(value)}
            onClick={async e => {
              e.preventDefault();
              setIsActive(false);
              try {
                setValue(FIELD_NAME_SUBJECT, value, {shouldValidate: true});
                if (value) {
                  await saveSubject(ticketId, value);
                  addHistoryRecord(
                    `${userDisplayName} edited Ticket Subject`,
                    TicketHistoryRecordType.Subject,
                    undefined,
                    'Ticket Subject Change'
                  );
                }
              } catch (error) {
                valueBeforeChange.current &&
                  setValue(FIELD_NAME_SUBJECT, valueBeforeChange.current, {shouldValidate: true});
                console.error(error);
              }
            }}
          >
            Save
          </Button>
          <Button
            sx={{ml: 1}}
            size="small"
            onClick={() => {
              setIsActive(false);
              setValue(FIELD_NAME_SUBJECT, valueBeforeChange.current, {shouldValidate: false});
            }}
          >
            Cancel
          </Button>
        </Box>
      )}
    </div>
  );
}
