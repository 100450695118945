import MapIcon from '@mui/icons-material/Map';
import MenuIcon from '@mui/icons-material/Menu';
import WarningIcon from '@mui/icons-material/Warning';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import {
  DefaultTimeZone,
  getSearchGroup,
  IndexableApplication,
  TicketSearchItemModel,
  TimeZone,
  TimeZoneValues,
  useSiteJumpAsUser,
  withDialog,
} from '@ozark/common';
import {
  ApplicationOption,
  ApplicationOptionHeader,
  BoxEllipsis,
  GlobalSearch,
  GreetingMessage,
  Notifications,
  Search,
  SubscriptionManagement,
  TicketSearchItem,
  TicketSearchItemHeader,
} from '@ozark/common/components';
import {EntityAutocompleteOptionConfig} from '@ozark/common/components/GlobalSearch/types';
import {useUserInfo} from '@ozark/common/hooks/useUserInfo';
import React, {MouseEventHandler, useCallback, useMemo} from 'react';
import {generatePath, matchPath, useHistory, useLocation} from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';
import {SiteJumpAsUserAlert} from '../SiteJumpAsUserAlert';

const useStyles = makeStyles(theme => ({
  appBar: {
    color: '#4d6575',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5, 0),
    },
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0.5, '10%'),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0.5, '5%'),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5, 2),
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 0,
    marginLeft: theme.spacing(2),
    height: theme.spacing(6),
    width: theme.spacing(6),
  },
  avatar: {
    height: theme.spacing(6),
    width: theme.spacing(6),
  },
  timeZoneInput: {
    backgroundColor: '#FFF !important',
    '&:focus,&:active': {
      backgroundColor: '#FFF',
    },
  },
  stagingWarning: {
    display: 'flex',
    color: 'red',
    padding: theme.spacing(0, 0.5),
    '& > *': {
      margin: theme.spacing(0, 0.5),
    },
  },
  siteJumpWarning: {
    display: 'flex',
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 0.5),
    '& > *': {
      margin: theme.spacing(0, 0.5),
    },
  },
  impostorInput: {
    backgroundColor: '#FFF !important',
    '&:focus,&:active': {
      backgroundColor: '#FFF',
    },
  },
}));

const useProfileMenu = ({handleLogout}: {handleLogout: () => void}) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const hideProfileMenu = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    hideProfileMenu();
    history.push(ROUTES.PROFILE);
  };

  const onLogout = () => {
    hideProfileMenu();
    handleLogout();
    history.push(ROUTES.LOGIN);
  };

  const ProfileMenu = () => (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={hideProfileMenu}
    >
      <MenuItem onClick={() => history.push({hash: 'subscription-management'})}>
        Email Management
      </MenuItem>

      <MenuItem onClick={handleProfile}>Profile</MenuItem>
      <MenuItem onClick={onLogout}>Logout</MenuItem>
    </Menu>
  );

  return {ProfileMenu, showProfileMenu: setAnchorEl, hideProfileMenu};
};

const SubscriptionManagementDialog = withDialog(SubscriptionManagement, '#subscription-management');

const Header = ({onDrawerToggle}: {onDrawerToggle: MouseEventHandler}) => {
  const classes = useStyles();
  const {logout, authProfile} = useStore();
  const {isSiteJump} = useUserInfo();
  const {ProfileMenu, showProfileMenu} = useProfileMenu({handleLogout: logout});
  const history = useHistory();
  const location = useLocation();
  const isTicketsSearchVisible = matchPath(location.pathname, {path: ROUTES.TICKETS});

  let timeZone = (authProfile?.data?.timeZoneId ?? DefaultTimeZone) as TimeZone;
  if (!TimeZoneValues[timeZone]) {
    timeZone = DefaultTimeZone;
  }

  const {siteJumpAsUser} = useSiteJumpAsUser();

  const loginPortalAsAgent = (uid: string | 'null') => {
    if (uid === 'null') {
      return;
    }

    siteJumpAsUser({uid});
  };

  const handleProfileClick: MouseEventHandler<HTMLButtonElement> = useCallback(event => {
    showProfileMenu(event.currentTarget);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const applicationAutocompleteOptionConfig = useMemo<
    EntityAutocompleteOptionConfig<IndexableApplication>
  >(
    () => ({
      getLinkRoute: option => {
        // to avoid click on headers
        if (!option || !option.id) {
          return null;
        }

        // searchGroup always exists because of application search filter, so type cast is ok here
        const searchGroup = getSearchGroup(option.disposition) as string;
        return {
          pathname: generatePath(ROUTES.APPLICATION, {id: option.id}),
          state: {
            referrer: searchGroup,
          },
        };
      },

      pathsToLiftUpSearchResult: [ROUTES.APPLICATIONS, ROUTES.APPLICATION.replace(':/id', '')],

      render: option => <ApplicationOption option={option} />,
      renderGroupHeader: props => <ApplicationOptionHeader group={props.group} />,
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleSearchChange = (option: TicketSearchItemModel | null) => {
    if (!option) return;

    const {id} = option as TicketSearchItemModel;

    id && history.push(`${ROUTES.TICKETS}/id/${id}`);
  };

  const handleRenderOption = useCallback(
    (option: TicketSearchItemModel, options?: Record<string, any>) => {
      if (!option.id) {
        return (
          <TicketSearchItemHeader
            ticketSearchItem={option}
            wideSubject={!!options?.wideTicketSubject}
          />
        );
      }

      return (
        <TicketSearchItem
          key={option.id}
          ticketSearchItem={option}
          wideSubject={!!options?.wideTicketSubject}
          isErpUser={false}
        />
      );
    },
    []
  );

  return (
    <>
      <AppBar className={classes.appBar} position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs>
              {isTicketsSearchVisible ? (
                <Search onChange={handleSearchChange} renderOption={handleRenderOption} />
              ) : (
                <GlobalSearch
                  applicationAutocompleteOptionConfig={applicationAutocompleteOptionConfig}
                />
              )}
            </Grid>
            <Grid item>
              <Hidden mdDown>
                <div className={classes.actions}>
                  {isSiteJump && authProfile.data && (
                    <>
                      <SiteJumpAsUserAlert userData={authProfile.data} />
                      <Divider orientation="vertical" flexItem />
                    </>
                  )}

                  {['ozark-staging', 'getevolved-staging'].includes(
                    process.env.REACT_APP_FIREBASE_PROJECT_ID ?? ''
                  ) && (
                    <div className={classes.stagingWarning}>
                      <WarningIcon />
                      <Typography>Staging Environment</Typography>
                    </div>
                  )}

                  <Divider orientation="vertical" flexItem />

                  <TextField
                    onChange={() => {}}
                    value={timeZone}
                    variant="standard"
                    InputProps={{
                      classes: {
                        input: classes.timeZoneInput,
                      },
                      startAdornment: (
                        <Tooltip title="Select timezone">
                          <InputAdornment position="start">
                            <MapIcon />
                          </InputAdornment>
                        </Tooltip>
                      ),
                      disableUnderline: true,
                    }}
                    select
                  >
                    <MenuItem value={timeZone}>{TimeZoneValues[timeZone]}</MenuItem>
                  </TextField>

                  {!!authProfile.data?.siteJumpAgents &&
                    authProfile.data.siteJumpAgents.length > 0 && (
                      <>
                        <Divider orientation="vertical" flexItem />

                        <TextField
                          onChange={event => loginPortalAsAgent(event.target.value)}
                          value="null"
                          variant="standard"
                          InputProps={{
                            classes: {
                              input: classes.impostorInput,
                            },
                            disableUnderline: true,
                          }}
                          select
                        >
                          <MenuItem disabled value={'null'}>
                            Log In as Agent
                          </MenuItem>

                          {authProfile.data.siteJumpAgents.sortAndMap(
                            ({
                              id,
                              fullName,
                              groupName,
                            }: {
                              id: string;
                              fullName: string;
                              groupName: string;
                            }) => {
                              const agentToDisplay = !!(fullName && groupName)
                                ? `${fullName} (${groupName})`
                                : `Agent ID: ${id}`;

                              return (
                                <MenuItem key={id} value={id} sx={{maxWidth: 400}}>
                                  <BoxEllipsis title={agentToDisplay}>{agentToDisplay}</BoxEllipsis>
                                </MenuItem>
                              );
                            },
                            ({
                              id,
                              fullName,
                              groupName,
                            }: {
                              id: string;
                              fullName: string;
                              groupName: string;
                            }) => {
                              const agentToDisplay = !!(fullName && groupName)
                                ? `${fullName} (${groupName})`
                                : `Agent ID: ${id}`;
                              return agentToDisplay;
                            }
                          )}
                        </TextField>
                      </>
                    )}

                  <Divider orientation="vertical" flexItem />

                  <Notifications />

                  <Divider orientation="vertical" flexItem />

                  <GreetingMessage profile={authProfile?.data} />

                  <IconButton
                    color="inherit"
                    className={classes.iconButtonAvatar}
                    onClick={handleProfileClick}
                    size="large"
                  >
                    <Avatar
                      src={authProfile.data?.photoUrl}
                      className={classes.avatar}
                      alt="Me"
                    ></Avatar>
                  </IconButton>
                  <ProfileMenu />
                </div>
              </Hidden>
              <Hidden mdUp>
                <div className={classes.actions}>
                  <IconButton
                    color="inherit"
                    className={classes.iconButtonAvatar}
                    onClick={handleProfileClick}
                    size="large"
                  >
                    <Avatar
                      src={authProfile.data?.photoUrl}
                      className={classes.avatar}
                      alt="Me"
                    ></Avatar>
                  </IconButton>
                  <ProfileMenu />
                </div>
              </Hidden>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <SubscriptionManagementDialog
        fullWidth
        maxWidth="md"
        title="Email Management"
        subTitle="The place you can manage your notifications"
      />
    </>
  );
};

export default Header;
