import {useEffect, useState} from 'react';
import {Collections, Firebase, selectTicketView, TicketStatus} from '../../../..';
import {useUserInfo} from '../../../../hooks';
import {stateEmpty, statePromised} from '../../constants/constants';
import {useTicketsViewableBy} from './useTicketsViewableBy';

export function useTicketsAssociatedAgents(statusSet: TicketStatus[]) {
  const {uid} = useUserInfo();
  const [ticketsAssociatedAgents, setTicketsAssociatedAgents] = useState(statePromised);
  const {filterOutInternalTickets} = useTicketsViewableBy();

  useEffect(() => {
    setTicketsAssociatedAgents(statePromised);
    return Firebase.firestore
      .collection(Collections.tickets)
      .where('status', 'in', statusSet)
      .where('associatedAgents', 'array-contains', uid)
      .onSnapshot(
        async snapshot => {
          if (snapshot.size === 0) {
            setTicketsAssociatedAgents(stateEmpty);
            return;
          }
          const tickets = filterOutInternalTickets(snapshot.docs.map(selectTicketView));
          setTicketsAssociatedAgents({promised: false, data: tickets});
        },
        err => {
          console.error(err);
          setTicketsAssociatedAgents({promised: false, error: err});
        }
      );
  }, [statusSet, filterOutInternalTickets]);

  return {
    ticketsAssociatedAgents,
  };
}
