import {ApiBase, SearchCriteria} from '@ozark/common';
import {ActiveFilter} from '@ozark/common/components';
import {
  LeadExportRow,
  LeadsCampaignsResponse,
  LeadsClosingRatios,
  LeadsSourcesResponse,
  PaginatedLeadsResponse,
} from '@ozark/functions/src/functions/express/private/types';
import {toQuery} from '../util';

export class LeadsApi extends ApiBase {
  getLeads = (searchCriteria: SearchCriteria, filters?: ActiveFilter[]) => {
    const queryString = toQuery(searchCriteria, filters);
    return this.get<PaginatedLeadsResponse>(`leads/metrics?${queryString}`);
  };

  getExportReport = () => {
    return this.get<LeadExportRow[]>(`leads/exportReport`);
  };

  getLeadsClosingRatios = () => {
    return this.get<LeadsClosingRatios>('leads/leadsClosingRatios');
  };

  getCampaignRatios = (campaign: string | undefined) => {
    const endpoint = 'leads/campaignRatios';
    if (campaign) {
      return this.get<LeadsClosingRatios>(`${endpoint}?campaign=${campaign}`);
    }
    return this.get<LeadsClosingRatios>(endpoint);
  };

  getLeadsCampaignsList = () => {
    return this.get<LeadsCampaignsResponse>('leads/leadsCampaignsList');
  };

  getSourceRatios = (source: string | undefined) => {
    const endpoint = 'leads/sourceRatios';
    if (source) {
      return this.get<LeadsClosingRatios>(`${endpoint}?source=${source}`);
    }
    return this.get<LeadsClosingRatios>(endpoint);
  };

  getLeadsSourcesList = () => {
    return this.get<LeadsSourcesResponse>('leads/leadsSourcesList');
  };

  getSalesRepRatios = (salesRepId: string | undefined) => {
    const endpoint = 'leads/salesRepRatios';
    if (salesRepId) {
      return this.get<LeadsClosingRatios>(`${endpoint}?salesRepId=${salesRepId}`);
    }
    return this.get<LeadsClosingRatios>(endpoint);
  };
}
