import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import {omit} from 'lodash';
import {AssigneeType, Department, TicketAssignee} from '../../../..';
import {FIELD_NAME_ERP_DEPARTMENT} from '../../constants/constants';
import {InputAssigneeError} from '../../types';

type Props = {
  draft: TicketAssignee;
  setDraft: (draft: TicketAssignee) => void;
  errors: Record<InputAssigneeError, string>;
  setErrors: (errors: Record<InputAssigneeError, string>) => void;
};

export const AssigneeEditorValueErpDepartment = ({draft, setDraft, errors, setErrors}: Props) => {
  const error = errors[FIELD_NAME_ERP_DEPARTMENT];
  return (
    <Box mt={2}>
      <FormControl variant="standard" fullWidth error={Boolean(error)}>
        <InputLabel id="select-dept">Department</InputLabel>
        <Select
          id="select-dept"
          name="department"
          label="Department"
          value={draft.erpDepartment}
          onChange={(e: SelectChangeEvent<any>) => {
            setDraft({
              type: AssigneeType.erpDepartment,
              erpDepartment: e.target.value ?? undefined,
            });
            if (e.target.value) {
              setErrors(
                omit(errors, FIELD_NAME_ERP_DEPARTMENT) as Record<InputAssigneeError, string>
              );
            }
          }}
        >
          {Object.values(Department).sortAndMap(e => (
            <MenuItem key={e} value={e}>
              {e}
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </Box>
  );
};
