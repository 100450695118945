import {yupResolver} from '@hookform/resolvers/yup';
import {Button, CircularProgress, Divider, Fade, Grid, Paper} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {MerchantStatus, useMerchant, useNotification} from '@ozark/common/';
import {Loading} from '@ozark/common/components';
import {Fragment, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {MerchantForm, MerchantFormModel, MerchantFormSchema} from '.';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolBar: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      padding: theme.spacing(2, 2),
    },
    logo: {
      height: 48,
    },
    actions: {},
    content: {
      marginTop: theme.spacing(0),
      padding: theme.spacing(1, 2, 2),
      position: 'relative',
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    saveButton: {
      width: 150,
    },
  })
);

type Props = {
  merchantId: string;
  isAuthUserAdmin: boolean;
  userGroupId?: string;
  applicationMids?: string[];
};

export const MerchantEdit = ({
  merchantId,
  isAuthUserAdmin,
  userGroupId,
  applicationMids,
}: Props) => {
  const classes = useStyles();
  const {
    document: {data: merchant},
    set,
  } = useMerchant(merchantId);

  const [loading, setLoading] = useState(false);
  const showNotification = useNotification();

  const hookForm = useForm<MerchantFormModel>({
    resolver: yupResolver(MerchantFormSchema),
    shouldUnregister: true,
  });

  const {
    formState: {isDirty},
    handleSubmit,
  } = hookForm;

  const onSuccess: SubmitHandler<MerchantFormModel> = async data => {
    try {
      const {
        firstName,
        lastName,
        email,
        phoneNumber,
        groupId,
        applicationMids,
        timeZoneId,
        merchantStatus,
      } = data;

      const isActive = merchantStatus === MerchantStatus.active;

      setLoading(true);

      await set({
        firstName,
        lastName,
        email,
        phoneNumber,
        groupId,
        timeZoneId,
        applicationMids,
        isActive,
      });

      showNotification('success', 'Merchant successfully updated.');
    } catch (err) {
      showNotification('error', 'Failed to Save Changes.');
    } finally {
      setLoading(false);
    }
  };

  if (!merchant) return <Loading />;

  return (
    <div>
      <Paper>
        <div className={classes.content}>
          <Grid container spacing={2}>
            <MerchantForm
              hookForm={hookForm}
              merchant={merchant}
              isAuthUserAdmin={isAuthUserAdmin}
              editMode={isAuthUserAdmin}
              userGroupId={userGroupId}
              applicationMids={applicationMids}
            />
            <Fade in={isDirty}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Fade>
            <Fade in={isDirty} unmountOnExit={false}>
              <Grid item xs={12}>
                <div className={classes.footer}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.saveButton}
                    disabled={loading}
                    onClick={handleSubmit(onSuccess)}
                  >
                    {loading ? <CircularProgress size={24} /> : <Fragment>Save Changes</Fragment>}
                  </Button>
                </div>
              </Grid>
            </Fade>
          </Grid>
        </div>
      </Paper>
    </div>
  );
};
