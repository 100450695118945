/* eslint-disable react-hooks/exhaustive-deps */
import SortIcon from '@mui/icons-material/Sort';
import {MenuItem} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  ApplicationStatusGroups,
  ApplicationView,
  AuthUserClaims,
  Collections,
  Dispositions,
  getColor,
  getDispositions,
  GroupRole,
  InfiniteSnapshotOptions,
  selectApplicationView,
  useInfiniteSnapshots,
  UserRoles,
} from '@ozark/common';
import {
  ApplicationsBar,
  Card,
  InfiniteDocuments,
  Loading,
  MidAndDba,
} from '@ozark/common/components';
import firebase from 'firebase/compat/app';
import {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import * as ROUTES from '../../../constants/routes';
import {useStore} from '../../../store/helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    grow: {
      flex: 1,
    },
    divider: {
      margin: theme.spacing(2),
    },
    placeholder: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    selectInput: {
      backgroundColor: 'transparent !important',
    },
  })
);

type ApplicationsProps = {
  mids: MidAndDba[];
  midsLoaded: boolean;
};

const getInitialFilterBasedOnRole = (
  claims: AuthUserClaims,
  userId: string,
  query: firebase.firestore.Query<firebase.firestore.DocumentData>
): firebase.firestore.Query<firebase.firestore.DocumentData> => {
  if (claims?.role === UserRoles.merchant) {
    return query.where('merchantUid', '==', userId);
  }

  if (claims?.role === UserRoles.agent && claims?.groupRole === GroupRole.administrator) {
    return query.where('group.id', '==', claims.groupId);
  }

  return query.where('agent.id', '==', userId);
};

export const Applications = ({mids, midsLoaded}: ApplicationsProps) => {
  const classes = useStyles();
  const history = useHistory();
  const {authProfile, claims} = useStore();
  const [midValues, setMidValues] = useState<string[]>([]);
  const [group] = useState(ApplicationStatusGroups.all);
  const [disposition, setDisposition] = useState<Dispositions | null>(null);

  const [options, setOptions] = useState<Partial<InfiniteSnapshotOptions>>({
    order: 'desc',
    limit: 50,
    filter: query => getInitialFilterBasedOnRole(claims!, authProfile.data!.id!, query),
  });

  useEffect(() => {
    if (mids?.length) {
      setMidValues(mids.map(m => m.mid));
    }
  }, [mids]);

  useEffect(() => {
    if (midValues?.length) {
      setFilter(midValues, null);
    }
  }, [midValues]);

  const {documents: applications, next} = useInfiniteSnapshots<ApplicationView>(
    Collections.applications,
    selectApplicationView,
    options
  );

  const getFilter = useCallback(
    (mids: string[], disposition: any) =>
      (
        query:
          | firebase.firestore.Query<firebase.firestore.DocumentData>
          | firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
      ): firebase.firestore.Query<firebase.firestore.DocumentData> => {
        let _query = query;

        if (claims?.groupRole === GroupRole.administrator) {
          _query = _query.where('group.id', '==', claims?.groupId);
        } else {
          // Member agent or admin with selected assigned to me.
          _query = _query.where('agent.id', '==', authProfile.data?.id);
        }

        if (mids?.length) {
          _query = _query.where('mid', 'in', mids);
        }

        if (disposition && disposition !== 'null') {
          _query = _query.where('disposition', '==', disposition);
        }

        return _query;
      },
    []
  );

  const setFilter = (mids: string[], disposition: Dispositions | null) =>
    setOptions({...options, filter: getFilter(mids, disposition)});

  const toggleOrder = () =>
    setOptions({...options, order: options.order === 'asc' ? 'desc' : 'asc'});

  const handleDispositionChange = (event: any) => {
    setDisposition(event.target.value);
    setFilter(midValues, event.target.value);
  };

  if (applications.promised || !midsLoaded) return <Loading />;

  return (
    <div className={classes.root}>
      <ApplicationsBar
        onLinkClick={() => history.push(ROUTES.APPLICATIONS)}
        dispositionString={String(disposition)}
        onDispositionChange={handleDispositionChange}
        dispositionMenuItems={[
          <MenuItem key={String(null)} value={String(null)}>
            All Dispositions
          </MenuItem>,
        ].concat(
          getDispositions(group, Object.values(Dispositions)).map(e => (
            <MenuItem key={e} value={e}>
              <span style={{color: getColor(e)}}>&bull;&bull;&bull;&nbsp;&nbsp;</span>
              {e}
            </MenuItem>
          ))
        )}
        onToggleSort={toggleOrder}
        sortIcon={
          options.order === 'desc' ? (
            <SortIcon style={{transform: 'rotateX(180deg)'}} />
          ) : (
            <SortIcon />
          )
        }
        sortText={options.order === 'desc' ? 'Newest at Top' : 'Oldest at Top'}
      />
      <InfiniteDocuments
        documents={applications}
        next={next}
        onDocumentRender={document => (
          <Card
            application={document as ApplicationView}
            onClick={() =>
              history.push(ROUTES.APPLICATION.replace(':id', document.id), {referrer: ''})
            }
            isPortal
          />
        )}
      />
    </div>
  );
};
