import {
  Collections,
  Firebase,
  Profile,
  ProfileView,
  selectProfileView,
  UniversalSnapshot,
} from '../..';

export const getERPUsers = async (): Promise<ProfileView[]> => {
  const snapshot = await Firebase.firestore
    .collection(Collections.profiles)
    .where('isActive', '==', true)
    .get();

  if (snapshot.size) {
    return snapshot.docs.map(doc => selectProfileView(doc as UniversalSnapshot<Profile>));
  }

  return [];
};

export const getERPUserById = async (erpUserId: string): Promise<ProfileView> => {
  const doc = await Firebase.firestore.collection(Collections.profiles).doc(erpUserId).get();

  if (!doc.exists) {
    throw new Error('Profile not found');
  }

  return selectProfileView(doc as UniversalSnapshot<Profile>);
};
