import {yupResolver} from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@mui/material';
import {AgentView, GroupView} from '@ozark/common';
import {useNotification} from '@ozark/crm/src/hooks/useNotification';
import {useCallback, useState} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {useCallable} from '../../hooks';
import {
  ApplicationResidualOverrideForm,
  ApplicationResidualOverrideFormModel,
} from './ApplicationResidualOverrideForm';

type Props = {
  onClose: () => void;
  currentAdjustment?: ApplicationResidualOverrideFormModel;
  applicationId: string;
  isAssignedAgent?: boolean;
  agents?: AgentView[];
  groups?: GroupView[];
  maxSplitPercent: number;
  currentAgentGroupId?: string;
};

export const getSchema = (maxSplitPercent: number) => {
  return yup.object().shape({
    groupId: yup.string().required('Group is required'),
    agentId: yup.string().required('Agent is required'),
    splitPercent: yup
      .number()
      .typeError('Share % is required')
      .nullable()
      .required('Share % is required')
      .min(0, 'Min value 0')
      .max(maxSplitPercent, `Max value is ${maxSplitPercent}`),
    hideVisibility: yup.boolean(),
  });
};

export const ApplicationResidualOverrideDialog = ({
  onClose,
  currentAdjustment,
  applicationId,
  isAssignedAgent,
  agents,
  groups,
  maxSplitPercent,
  currentAgentGroupId,
}: Props) => {
  const showNotification = useNotification();
  const [loading, setLoading] = useState(false);
  const hookForm = useForm<ApplicationResidualOverrideFormModel>({
    resolver: yupResolver(getSchema(maxSplitPercent)) as any,
    shouldUnregister: true,
    defaultValues: {
      hideVisibility: currentAdjustment?.hideVisibility ?? false,
      agentId: currentAdjustment?.agentId,
      groupId: currentAdjustment?.groupId ?? currentAgentGroupId,
      splitPercent: currentAdjustment?.splitPercent,
    },
  });
  const isAdd = !currentAdjustment;
  const {handleSubmit} = hookForm;
  const {adjustApplicationResidualOverride} = useCallable();

  const onSuccess = useCallback(async (data: ApplicationResidualOverrideFormModel) => {
    try {
      setLoading(true);
      const result = await adjustApplicationResidualOverride({
        applicationId: applicationId,
        recipientGroupId: data.groupId,
        recipientAgentId: data.agentId,
        splitPercent: data.splitPercent,
        hideVisibility: data.hideVisibility,
        deleteAdjustment: false,
      });

      if (result.status === 'ok') {
        showNotification('success', 'Adjustment successfully added');
        setLoading(false);
        onClose();
        return;
      }

      showNotification('error', result.message);
      setLoading(false);
    } catch (err) {
      showNotification('error', (err as Error).message);
      setLoading(false);
    }
  }, []);

  const onError = useCallback(
    (data: any) => {
      console.error(`error saving data ${JSON.stringify(data)}`);
      setLoading(false);
    },
    [setLoading]
  );
  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="application-residual-override-dialog-title"
      maxWidth={'lg'}
    >
      <DialogTitle id="application-residual-override-dialog-title">
        {isAdd ? 'Add Share' : 'Edit Share'}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{width: '600px'}}>
          <ApplicationResidualOverrideForm
            hookForm={hookForm}
            isAssignedAgent={isAssignedAgent}
            agents={agents?.filter(agent => agent.isActive)}
            groups={groups}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <LoadingButton
          onClick={handleSubmit(onSuccess, onError)}
          color="primary"
          disabled={loading}
          loading={loading}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
