import {yupResolver} from '@hookform/resolvers/yup';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
} from '@mui/material';
import {useCallable, useNotification} from '@ozark/common';
import {TextField} from '@ozark/common/components';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';

interface FormDataModel {
  email: string;
}

type Props = {
  onClose(): void;
  initialEmail?: string;
  applicationId: string;
};

const SendEmailFormSchema = yup.object().shape({
  email: yup.string().email('Invalid email format.').required('Email field is required.'),
});

export const SendEmailDialog = ({onClose, initialEmail, applicationId}: Props) => {
  const {registerNowEmail} = useCallable();
  const showNotification = useNotification();
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<FormDataModel>({
    resolver: yupResolver(SendEmailFormSchema),
    shouldUnregister: true,
  });

  const sendRegisterNowEmail = async (email: string) => {
    if (!initialEmail || !applicationId) {
      throw new Error('Invalid arguments');
    }
    const result = await registerNowEmail({
      emailTo: email,
      applicationId,
    });

    if (result.status !== 'ok') {
      console.error('Failed at sending register now email with error:', result);
      throw new Error('Mail sending error.');
    }
  };

  const onSubmit = async (data: FormDataModel) => {
    setLoading(true);
    try {
      await sendRegisterNowEmail(data.email);
      showNotification(
        'success',
        `Registration email has been successfully sent to: ${data.email}`
      );
      reset(data as any);
    } catch (err) {
      console.error(err);
      showNotification('error', 'An error occurred while sending registration email.');
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const onSubmitError = (data: any) => {
    console.error(data);
    setLoading(false);
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="send-email-dialog-title" maxWidth={'lg'}>
      <form onSubmit={handleSubmit(onSubmit, onSubmitError)}>
        <DialogTitle id="send-email-dialog-title">Send Registration Email</DialogTitle>
        <DialogContentStyled>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="email"
                label="Email Address"
                defaultValue={initialEmail}
                errors={errors}
                control={control}
                disabled={loading}
                autoFocus
              />
            </Grid>
          </Grid>
        </DialogContentStyled>
        <DialogActions>
          <Button onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button color="primary" disabled={loading} type="submit">
            {loading && <CircularProgressStyled size={24} />}
            Send Email
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const DialogContentStyled = styled(DialogContent)({
  width: 500,
  minHeight: 130,
});
const CircularProgressStyled = styled(CircularProgress)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
});
