import {Divider, Typography} from '@mui/material';
import {useGreeting} from './useGreeting';

interface IProfile {
  firstName: string;
}
interface Props {
  profile: IProfile | undefined;
}

export const GreetingMessage = ({profile}: Props) => {
  const {getGreetingText} = useGreeting();

  const greetingText = getGreetingText(profile?.firstName);
  return (
    <>
      <Typography>{greetingText}</Typography>
      <Divider orientation="vertical" flexItem />
    </>
  );
};
