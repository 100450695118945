import {UniversalTimestamp} from './compat';
import {DocumentBase, DocumentCreatedBy} from './DocumentBase';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export enum ScheduledJobType {
  midReportingAuthorizations = 'MidReportingAuthorizations',
  midReportingBatches = 'MidReportingBatches',
  midReportingDeposits = 'MidReportingDeposits',
  midReportingStatements = 'MidReportingStatements',
  midInternalReportingStatements = 'MidInternalReportingStatements', //is used internally to send emails when statement is generated
}

export const ReportingScheduledJobTypes: ScheduledJobType[] = [
  ScheduledJobType.midReportingAuthorizations,
  ScheduledJobType.midReportingBatches,
  ScheduledJobType.midReportingDeposits,
  ScheduledJobType.midInternalReportingStatements,
];

export const isReportingScheduledJobTypes = (scheduledJobType: ScheduledJobType) => {
  return ReportingScheduledJobTypes.includes(scheduledJobType);
};

export interface BaseJobFrequency {
  hour: number;
}

export enum FrequencyType {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
  specific = 'specific',
}

export interface DailyJobFrequency extends BaseJobFrequency {
  type: FrequencyType.daily;
}

export type WeeklyDayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6; // 0 = Sunday, 6 = Saturday
export const WeeklyDayOfWeekNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export interface WeeklyJobFrequency extends BaseJobFrequency {
  type: FrequencyType.weekly;
  daysOfWeek: WeeklyDayOfWeek[];
}

export const LastDayOfMonth = 32; //if user wants to run job on last day of the month - we save 32 in the DB
export const LastDayOfMonthText = 'Last day of the Month';
export interface MonthlyJobFrequency extends BaseJobFrequency {
  type: FrequencyType.monthly;
  daysOfMonth: number[]; // 1-32... 32 means last date of the month
}

export interface SpecificDateJobFrequency extends BaseJobFrequency {
  type: FrequencyType.specific;
  dates: string[]; // YYYY-MM-DD format
}

export type JobFrequency =
  | DailyJobFrequency
  | WeeklyJobFrequency
  | MonthlyJobFrequency
  | SpecificDateJobFrequency;

export type ScheduledJob = DocumentBase &
  DocumentCreatedBy & {
    jobType: ScheduledJobType;
    frequency?: JobFrequency;
    jobParameters: any;
    createdAt: UniversalTimestamp;
    isOneTime: boolean;
  };

export type ReportingScheduledJobParams = {
  uiFilters: any;
  urlQuery: string;
};

export type StatementReportingJobParams = {
  mid: string;
};

export type StatementInternalReportingJobParams = {
  mid: string;
  cloudPathDownload: string;
};

export type ScheduledJobView = ViewBase<ScheduledJob>;
export const selectScheduledJobView: ViewSelector<ScheduledJobView, ScheduledJob> = snapshot => {
  const view: ScheduledJobView = {
    ...defaultViewSelector(snapshot),
  };
  return view;
};
