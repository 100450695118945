import {Chip} from '@mui/material';
import {format, formatInTimeZone} from 'date-fns-tz';
import {Filter} from '../common';
import {getFilterDate} from '../helpers/getFilterDate';
import {getOnFilterChipDelete} from '../helpers/onFilterChipDelete';
import {currentFormatter, ReportingPageStyles} from '../Reporting';

interface Props {
  filters: Filter;
  handleDeleteFilter?: (id: string) => () => void;
}

export const BatchesFiltersComponent = ({filters, handleDeleteFilter}: Props) => {
  const onDelete = getOnFilterChipDelete(handleDeleteFilter);
  return (
    <>
      {filters &&
        Object.keys(filters).map(key => {
          const filter = filters[key];
          if (filter.option.type === 'monthRange' && filter.operator.id === '__between') {
            return (
              <Chip
                key={`${key}-${filter.operator.id}`}
                sx={ReportingPageStyles.chip}
                label={
                  <span>
                    <b>{filter.option.label}</b> {filter.operator.label} '
                    <b>{format(getFilterDate(filter.value?.[0]) as Date, 'MMMM yyyy')}</b>' and '
                    <b>{format(getFilterDate(filter.value?.[1]) as Date, 'MMMM yyyy')}</b>'
                  </span>
                }
                variant="outlined"
                onDelete={onDelete(key)}
              />
            );
          }
          if (filter.option.type === 'dateOnlyRange' && filter.operator.id === '__between') {
            return (
              <Chip
                key={`${key}-${filter.operator.id}`}
                sx={ReportingPageStyles.chip}
                label={
                  <span>
                    <b>{filter.option.label}</b> {filter.operator.label} '
                    <b>{format(getFilterDate(filter.value?.[0]) as Date, 'MM/dd/yyyy')}</b>' and '
                    <b>{format(getFilterDate(filter.value?.[1]) as Date, 'MM/dd/yyyy')}</b>'
                  </span>
                }
                variant="outlined"
                onDelete={onDelete(key)}
              />
            );
          }
          if (filter.option.type === 'month' && filter.operator.id === '__between') {
            return (
              <Chip
                key={`${key}-${filter.operator.id}`}
                sx={ReportingPageStyles.chip}
                label={
                  <span>
                    <b>{filter.option.label}</b> {filter.operator.label} '
                    <b>{format(getFilterDate(filter.value?.[0]) as Date, 'MMMM yyyy')}</b>'
                  </span>
                }
                variant="outlined"
                onDelete={onDelete(key)}
              />
            );
          }
          if (filter.option.type === 'dateOnly' && filter.operator.id === '__eq') {
            return (
              <Chip
                key={`${key}-${filter.operator.id}`}
                sx={ReportingPageStyles.chip}
                label={
                  <span>
                    <b>{filter.option.label}</b> {filter.operator.label} '
                    <b>
                      {formatInTimeZone(
                        getFilterDate(filter.value?.[0]) as Date,
                        'UTC',
                        'MM/dd/yyyy'
                      )}
                    </b>
                    '
                  </span>
                }
                variant="outlined"
                onDelete={onDelete(key)}
              />
            );
          }
          if (filter.option.type === 'currencyRange' && filter.operator.id === '__between') {
            return (
              <Chip
                key={`${key}-${filter.operator.id}`}
                sx={ReportingPageStyles.chip}
                label={
                  <span>
                    <b>{filter.option.label}</b> {filter.operator.label}{' '}
                    <b>{currentFormatter.format(filter.value?.[0] as number)}</b>
                    {' and '}
                    <b>{currentFormatter.format(filter.value?.[1] as number)}</b>
                  </span>
                }
                variant="outlined"
                onDelete={onDelete(key)}
              />
            );
          }
          return (
            <Chip
              key={`${key}-${filter.operator.id}`}
              sx={ReportingPageStyles.chip}
              label={
                <span>
                  <b>{filter.option.label}</b> {filter.operator.label} '
                  <b>
                    {filter.option.type === 'currency' ? `$${filter.value}` : `${filter.value}`}
                  </b>
                  '
                </span>
              }
              variant="outlined"
              onDelete={filter.option.force ? undefined : onDelete(key)}
            />
          );
        })}
    </>
  );
};
