import WrongIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import {ListItem, ListItemIcon, ListItemText} from '@mui/material';
import {green, red} from '@mui/material/colors';

interface Props {
  isValid: boolean;
  text: string;
}

export const ListCheckItem = ({isValid, text}: Props) => {
  return (
    <ListItem sx={{pt: 0, pb: 0}}>
      <ListItemIcon>
        <>
          {isValid && <DoneIcon sx={{color: green[500]}} />}
          {!isValid && <WrongIcon sx={{color: red[500]}} />}
        </>
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
};
