import {format, utcToZonedTime} from 'date-fns-tz';
import {Batch} from '../../../express/private/types';
import {formatMinorUnits} from '../formatMinorUnits';
import {ReportExportColumn} from '../ReportExportColumn';

export const getBatchesFormattedDate = (dateString: string | Date) => {
  const date = new Date(dateString);
  return format(utcToZonedTime(date, 'UTC'), 'MM/dd/yyyy');
};

export type BatchColumnNamesType =
  | 'batchDate'
  | 'statementDate'
  | 'id'
  | 'batchedAmount'
  | 'creditsAmount'
  | 'rejectsAmount'
  | 'approvedBatches'
  | 'transactionsCount'
  | 'merchantReferenceNumber';

export const BatchesColumnsConfigRecord: Record<BatchColumnNamesType, ReportExportColumn<Batch>> = {
  batchDate: {
    id: 'batchDate',
    label: 'Actual Batch Date',
    export: row => getBatchesFormattedDate(row.batchDate),
  },
  statementDate: {
    id: 'statementDate',
    label: 'Statement Date',
    export: row => format(utcToZonedTime(new Date(row.statementDate), 'UTC'), 'MMMM yyyy'),
  },
  id: {
    id: 'id',
    label: 'Batch Id',
    export: true,
  },
  batchedAmount: {
    id: 'batchedAmount',
    label: 'Batched Amount',
    export: row => formatMinorUnits(row.batchedAmount),
  },
  creditsAmount: {
    id: 'creditsAmount',
    label: 'Credits',
    export: row => formatMinorUnits(row.creditsAmount) ?? '0',
  },
  rejectsAmount: {
    id: 'rejectsAmount',
    label: 'Risk Reviewed Batches',
    export: row => formatMinorUnits(row.rejectsAmount) ?? '0',
  },
  approvedBatches: {
    id: 'approvedBatches',
    label: 'Approved Batches',
    export: row => formatMinorUnits(row.approvedBatches) ?? '0',
  },
  transactionsCount: {
    id: 'transactionsCount',
    label: '# of Transactions',
    export: true,
  },
  merchantReferenceNumber: {
    id: 'merchantReferenceNumber',
    label: 'Reference Number',
    export: true,
  },
};

export const BatchesExportColumnsConfig: ReportExportColumn<Batch>[] = [
  {
    ...BatchesColumnsConfigRecord.batchDate,
  },
  {
    ...BatchesColumnsConfigRecord.statementDate,
  },
  {
    ...BatchesColumnsConfigRecord.id,
  },
  {
    ...BatchesColumnsConfigRecord.batchedAmount,
  },
  {
    ...BatchesColumnsConfigRecord.creditsAmount,
  },
  {
    ...BatchesColumnsConfigRecord.rejectsAmount,
  },
  {
    ...BatchesColumnsConfigRecord.approvedBatches,
  },
  {
    ...BatchesColumnsConfigRecord.transactionsCount,
  },
  {
    ...BatchesColumnsConfigRecord.merchantReferenceNumber,
  },
];
