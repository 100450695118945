import {Agent} from '../documents';

export enum AgentStatus {
  active = 'Active',
  inactive = 'Inactive',
  disabled = 'Disabled',
}

export const getAgentStatus = (agent: Agent) => {
  if (agent.isActive && !agent.isDisabled) return AgentStatus.active;
  if (agent.isDisabled) return AgentStatus.disabled;
  if (!agent.isActive) return AgentStatus.inactive;
  return AgentStatus.disabled;
};
