import {Agent, SearchCriteria, ViewBase} from '@ozark/common';
import {Table} from '@ozark/common/components';
import {orderBy} from 'lodash';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {columns} from './columns';

type Props = {
  agents: ViewBase<Agent>[];
};

export const AgentGrid = ({agents}: Props) => {
  const history = useHistory();
  const [filteredAgents, setFilteredAgents] = useState<ViewBase<Agent>[]>([]);
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>({
    limit: 20,
    offset: 1,
    orderBy: 'createdAt',
    order: 'desc',
  });

  useEffect(() => {
    if (agents) {
      const filteredAndOrdered = orderBy(
        agents,
        searchCriteria.orderBy,
        searchCriteria.order as 'desc' | 'asc'
      );
      setFilteredAgents(filteredAndOrdered);
    }
  }, [agents, searchCriteria]);

  const handleRetrieveData = (searchCriteria: SearchCriteria) => {
    setSearchCriteria(searchCriteria);
  };

  const handleRowClick = (row: ViewBase<Agent>) => {
    history.push(`/agents/${row.id}`);
  };

  return (
    <Table
      columns={columns}
      rows={filteredAgents}
      sort={[[searchCriteria.orderBy, searchCriteria.order as 'ASC' | 'DESC']]}
      onRowClick={(row: ViewBase<Agent>) => handleRowClick(row)}
      onRetrieveData={handleRetrieveData}
      paperSx={{minWidth: '1250px'}}
      stickyHeader
      flat
    />
  );
};
