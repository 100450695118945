import LoadingButton from '@mui/lab/LoadingButton';
import {FormControlLabel, Radio, RadioGroup} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import {ChangeEvent, useState} from 'react';
import {useHistory} from 'react-router';
import {useNotification} from '../../hooks';

interface Props {
  onNextStep: () => void;
  setSendViaEmail: (sendViaEmail: boolean) => void;
  sendMfaCode: () => Promise<boolean>;
  sendViaEmail: boolean;
  email: string;
  phone?: string;
}

const obscureEmail = (email: string) => {
  const [name, domain] = email.split('@');
  if (name.length < 2) {
    return `*@${domain}`;
  }
  if (name.length < 3) {
    return `${name.substring(0, 1)}*@${domain}`;
  }
  if (name.length < 5) {
    const asteriskCount = name.length - 2;
    return `${name.substring(0, 2)}${'*'.repeat(asteriskCount)}@${domain}`;
  }
  const asteriskCount = name.length - 4;
  return `${name.substring(0, 2)}${'*'.repeat(asteriskCount)}${name.substring(
    name.length - 2,
    name.length
  )}@${domain}`;
};

const obscurePhone = (phone: string) => {
  return `${'*'.repeat(phone.length - 4)}${phone.substring(phone.length - 4, phone.length)}`;
};

export const ContactMethod = ({
  onNextStep,
  setSendViaEmail,
  sendMfaCode,
  sendViaEmail,
  email,
  phone,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const showNotification = useNotification();
  const history = useHistory();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSendViaEmail((event.target as HTMLInputElement).value == 'true');
  };

  const gotoLogin = () => {
    history.replace('login');
  };

  const onNext = async () => {
    try {
      setLoading(true);

      const result = await sendMfaCode();
      if (!result) {
        return;
      }

      onNextStep();
    } catch (err) {
      console.error(err);

      showNotification('error', 'Failed to send code');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <FormControl
        sx={{
          width: '100%',
          marginTop: theme => theme.spacing(1),
        }}
      >
        <RadioGroup name="contactMethod" value={sendViaEmail} onChange={handleChange}>
          <FormControlLabel
            value="true"
            control={<Radio />}
            label={`Send code via email to ${obscureEmail(email)}`}
          />

          {!!phone && (
            <FormControlLabel
              value="false"
              control={<Radio />}
              label={`Send code via text/SMS to ${obscurePhone(phone)}`}
            />
          )}
        </RadioGroup>

        <LoadingButton
          fullWidth
          sx={{margin: theme => theme.spacing(3, 0, 2)}}
          color="primary"
          loading={loading}
          variant="contained"
          onClick={onNext}
        >
          Continue
        </LoadingButton>
        <Grid container>
          <Grid item xs={12} sx={{textAlign: 'right'}}>
            <Link sx={{textDecoration: 'none'}} component="button" onClick={gotoLogin}>
              Go to login page
            </Link>
          </Grid>
        </Grid>
      </FormControl>
    </>
  );
};
