import GroupIcon from '@mui/icons-material/Group';
import {Avatar, Box, Typography} from '@mui/material';
import {useWatch} from 'react-hook-form';
import {AssigneeType, getAssigneeName} from '../../../..';
import {useUserInfo} from '../../../../hooks/useUserInfo';
import {AvatarFromName} from '../../common/AvatarFromName';
import {TextDetailTitle} from '../../common/TextDetailTitle';
import {FIELD_NAME_ASSIGNEE} from '../../constants/constants';

export const AssigneeInfo = () => {
  const {isErpUser} = useUserInfo();
  const assignee = useWatch({name: FIELD_NAME_ASSIGNEE});

  if (!assignee) return <Box flex={1}>...</Box>;

  const assigneeName = getAssigneeName(assignee, isErpUser);
  const isGroupIcon =
    assignee.type === AssigneeType.erpDepartment ||
    (!isErpUser && assignee.type === AssigneeType.erpUser);

  return (
    <Box display="flex" alignItems="flex-start" flex={1}>
      {isGroupIcon && (
        <Avatar sx={{width: 34, height: 34}}>
          <GroupIcon />
        </Avatar>
      )}
      {!isGroupIcon && <AvatarFromName name={assigneeName ?? 'N A'} size={34} />}
      <Box pl={1} maxWidth={135}>
        <TextDetailTitle>{assigneeName ?? 'Unassigned'}</TextDetailTitle>
        {isErpUser && assignee?.type && (
          <Typography variant="body2" color="secondary">
            {assignee.type}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
