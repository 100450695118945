import {LoadingButton} from '@mui/lab';
import Box, {BoxProps} from '@mui/material/Box';
import Button, {ButtonProps} from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import type {ComponentType, MouseEventHandler} from 'react';
import {useCallback, useState} from 'react';

type WrappedComponentProps<T> = T & {
  id: string;
  isLoading?: boolean;
  primaryButtonColor: ButtonProps['color'];
  primaryButtonText: string;
  secondaryButtonText: string; // cancel and close menu
  title?: string;
  tooltip?: string;
  onClick: MouseEventHandler<HTMLElement>;
  wrapperSx?: BoxProps['sx'];
};

const withOverflowConfirmation = <T extends object>(WrappedComponent: ComponentType<T>) => {
  const ComponentWithOverflowConfirmation = ({
    id,
    isLoading,
    primaryButtonColor,
    primaryButtonText,
    secondaryButtonText,
    title,
    tooltip,
    onClick,
    wrapperSx,
    ...restProps
  }: WrappedComponentProps<T>) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const onMenuClose = useCallback(() => {
      setAnchorEl(null);
    }, []);

    const onMenuOpen = useCallback<MouseEventHandler<HTMLElement>>(event => {
      setAnchorEl(event.currentTarget);
    }, []);

    return (
      <>
        <Box display="flex" alignItems="center" textAlign="center" sx={wrapperSx}>
          <Tooltip title={tooltip ?? ''}>
            <WrappedComponent
              aria-controls={anchorEl ? id : undefined}
              aria-haspopup="true"
              aria-expanded={anchorEl ? 'true' : undefined}
              onClick={onMenuOpen}
              {...(restProps as T)}
            />
          </Tooltip>
        </Box>

        {!!anchorEl && (
          <Menu
            open
            anchorEl={anchorEl}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            id={id}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            onClick={onMenuClose}
            onClose={onMenuClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
          >
            {title && (
              <Box p={2} pb={0}>
                {title}
              </Box>
            )}

            <Box p={2}>
              <Button sx={{mr: 2}} variant="text" onClick={onMenuClose}>
                {secondaryButtonText}
              </Button>

              <LoadingButton
                loading={isLoading}
                variant="contained"
                color={primaryButtonColor}
                onClick={async event => {
                  await onClick(event);

                  onMenuClose();
                }}
              >
                {primaryButtonText}
              </LoadingButton>
            </Box>
          </Menu>
        )}
      </>
    );
  };

  return ComponentWithOverflowConfirmation;
};

export {withOverflowConfirmation};
