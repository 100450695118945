import {Firebase} from '..';

import {
  Collections,
  selectTicketTypeCategoryView,
  TicketTypeCategory,
  TicketTypeCategoryView,
  UniversalSnapshot,
} from '../..';

export const getTicketCategory = async (
  typeId: string,
  categoryId: string
): Promise<TicketTypeCategoryView | null> => {
  let query = Firebase.firestore
    .collection(Collections.ticketTypes)
    .doc(typeId)
    .collection(Collections.ticketTypeCategories)
    .doc(categoryId);

  const snapshot = await query.get();

  if (!snapshot.exists) {
    return null;
  }

  return selectTicketTypeCategoryView(snapshot as UniversalSnapshot<TicketTypeCategory>);
};
