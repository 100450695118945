import {isBefore, subDays} from 'date-fns';
import {Ticket, UniversalTimestamp} from '../../..';
import {FilterKey} from '../types';

export function filterTicketsByKey(tickets: Ticket[], filterKey?: FilterKey): Ticket[] {
  if (!tickets?.length || !filterKey) return tickets;

  if (filterKey === FilterKey.moreThen24h) {
    return tickets.filter(t => {
      const openedAt = (t.openedAt as UniversalTimestamp | undefined)?.toDate();
      if (!openedAt) return true;
      return isBefore(openedAt, subDays(new Date(), 1));
    });
  }
  return tickets;
}
