import ImageIcon from '@mui/icons-material/Image';
import {Box, CardMedia, CircularProgress, Grid, Typography, useTheme} from '@mui/material';
import {useAgent, useCallable, useMerchant, useNotification} from '@ozark/common';
import {Loading, Title, UploadImageButton} from '@ozark/common/components';
import {useUserInfo} from '@ozark/common/hooks/useUserInfo';
import {ReactNode, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {AgentEdit} from '../Agents/Edit';
import {MerchantProfile} from './MerchantProfile';

const Profile = () => {
  const query = new URLSearchParams(useLocation().search);
  const isAutoRedirect = query.get('auto') ?? '';
  const {isAgent, isAgentAdmin, isMerchant, uid} = useUserInfo();
  const [uploading, setUploading] = useState(false);
  const theme = useTheme();
  const {
    document: {data: agent},
  } = useAgent(uid);
  const {
    document: {data: merchant},
  } = useMerchant(uid);
  const {setProfileImage} = useCallable();
  const showNotification = useNotification();

  const InteractiveBackdrop = ({children}: {children: ReactNode}) => (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        height: 'calc(100% + 2px)',
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        color: 'rgba(255,255,255,0.6)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
      }}
    >
      {children}
    </Box>
  );

  if (!uid) return <Loading />;

  return (
    <div>
      <Title breadcrumbs={[<Typography variant="body1">My Profile</Typography>]} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={5} lg={4} xl={2}>
          <CardMedia
            image={agent?.photoUrl || merchant?.photoUrl}
            title={`${agent?.firstName || merchant?.firstName} ${
              agent?.lastName || merchant?.lastName
            }`}
            sx={{
              position: 'relative',
              height: 0,
              paddingTop: '100%',
              borderRadius: '50%',
            }}
          >
            {uploading && (
              <InteractiveBackdrop>
                <CircularProgress size={24} />
              </InteractiveBackdrop>
            )}

            {agent && !agent.photoUrl && !uploading && (
              <InteractiveBackdrop>
                <Typography variant="h4">No photo</Typography>
              </InteractiveBackdrop>
            )}
            {merchant && !merchant?.photoUrl && !uploading && (
              <InteractiveBackdrop>
                <Typography variant="h4">No photo</Typography>
              </InteractiveBackdrop>
            )}
          </CardMedia>

          <UploadImageButton
            fullWidth
            color="secondary"
            disabled={uploading}
            startIcon={<ImageIcon />}
            sx={{margin: theme.spacing(1.5, 0, 0)}}
            onError={() => {
              setUploading(false);
            }}
            onProgress={(progress: number) => {
              if (progress < 100) {
                setUploading(true);
              }
            }}
            onSuccess={async (photoUrl: string) => {
              const result = await setProfileImage({
                uid,
                photoUrl,
              });
              if (result.status === 'error') {
                showNotification('error', 'Something went wrong, please try again later');
              }
              setUploading(false);
            }}
          >
            Upload Profile Picture
          </UploadImageButton>
        </Grid>

        {isAgent && <AgentEdit agentId={uid} isAuthUserAdmin={isAgentAdmin} />}
        {isMerchant && <MerchantProfile merchantUid={uid} displayAlert={!!isAutoRedirect} />}
      </Grid>
    </div>
  );
};

export default Profile;
