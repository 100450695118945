import {Ticket} from '../../..';
import {useUserInfo} from '../../../hooks/useUserInfo';

export const useLastReplyInfoToDisplay = (ticket: Ticket) => {
  const {isErpUser} = useUserInfo();

  const getLastReplyInfoToDisplay = () => {
    if (!isErpUser) {
      return undefined;
    }

    return ticket.lastReplyInfo;
  };

  return getLastReplyInfoToDisplay();
};
