import {Box, Chip} from '@mui/material';
import {format} from 'date-fns-tz';
import {Filter} from '..';

type Props = {
  filters: Filter;
  setFilters: (filter: Filter) => void;
};

export const ButtonFiltersRow = ({filters, setFilters}: Props) => {
  const handleDeleteFilter = (id: string) => () => {
    const _filters = {...filters};
    delete _filters[id];
    setFilters(_filters);
  };

  return (
    <Box pl={2}>
      {filters &&
        Object.keys(filters).map(key => {
          const filter = filters[key];
          if (filter.option.type === 'dateRange' && filter.operator.id === '__between') {
            return (
              <Chip
                key={`${key}-${filter.operator.id}`}
                sx={{
                  mx: 0.5,
                }}
                label={
                  <span>
                    <b>{filter.option.label}</b> {filter.operator.label} '
                    <b>{format(filter.value?.[0] as Date, 'MM/dd/yyyy h:mm a')}</b>' and '
                    <b>{format(filter.value?.[1] as Date, 'MM/dd/yyyy h:mm a')}</b>'
                  </span>
                }
                variant="outlined"
                onDelete={handleDeleteFilter(key)}
              />
            );
          }
          return (
            <Chip
              key={`${key}-${filter.operator.id}`}
              sx={{
                mx: 0.5,
              }}
              label={
                <span>
                  <b>{filter.option.label}</b> {filter.operator.label} '<b>{`${filter.value}`}</b>'
                </span>
              }
              variant="outlined"
              onDelete={handleDeleteFilter(key)}
            />
          );
        })}
    </Box>
  );
};
