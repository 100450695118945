import {MidInfo} from '@ozark/functions/src/functions/express/private/types/Reports';
import {CancelTokenSource} from 'axios';
import {useCallback, useEffect, useState} from 'react';
import {CancelOperationMessage} from '../../../api/Constants';
import {useApiContainer} from '../../../store';

const calculateFields = (data: MidInfo[]): MidInfo[] => {
  if (!data) return data;
  const totalSalesAmount = data.reduce((acc: number, next: MidInfo) => acc + next.salesAmount, 0);
  return data.map(i => ({
    ...i,
    percentOfTotal: i.salesAmount / totalSalesAmount,
    avgTicket: i.salesAmount / i.salesCount,
  }));
};

type UseMidsByProcessingDataProps = {
  month: number;
  year: number;
};

const useMidsByProcessingData = ({month, year}: UseMidsByProcessingDataProps) => {
  const [allData, setAllData] = useState<MidInfo[]>([]);
  const [top15Data, setTop15Data] = useState<MidInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [_, setCancelTokenSource] = useState<CancelTokenSource | undefined>();

  const api = useApiContainer();

  const cancelRequests = () => {
    const cancelSource = api?.reports.getCancelTokenSource();
    setCancelTokenSource(prev => {
      //Check if there are any previous pending requests
      if (prev !== undefined) {
        prev.cancel(CancelOperationMessage);
      }
      return cancelSource;
    });
    return cancelSource;
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    try {
      const cancelRequestToken = cancelRequests();
      const response = await api?.reports.getMidsByProcessing(
        year,
        month,
        cancelRequestToken?.token
      );

      if (!response) {
        setAllData([]);
        setTop15Data([]);
        return;
      }

      const preparedData = calculateFields(response);

      setAllData(preparedData);
      setTop15Data(preparedData.slice(0, 15));
      setIsLoading(false);
    } catch (err: any) {
      if (err?.message === CancelOperationMessage) {
        return;
      }
      console.error(`An error occurred while getting MIDS by Processing data`, err);
      setAllData([]);
      setTop15Data([]);
      setIsLoading(false);
    }
  }, [month, setAllData, setIsLoading, setTop15Data, year]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {allData, isLoading, top15Data} as const;
};

export {useMidsByProcessingData};
