import {Box, Typography} from '@mui/material';
import {useMidsContainer} from '@ozark/common';
import {MidSelect, Title} from '@ozark/common/components';
import {VolumeFilterTypes} from '@ozark/functions/src/functions/express/private/types/Reports';
import {useState} from 'react';
import {useUserInfo} from '../../hooks/useUserInfo';
import {FilterPeriodSelect, getFilterPeriod, VolumeFilter} from '../FilterPeriodSelect';
import {ApplicationAnalyticsPage} from './ApplicationAnalytics';
import {MerchantAnalyticsSection} from './MerchantAnalytics';
import TransactionsSummary from './TransactionsSummary';

export const DashboardPage = () => {
  const {isMerchant} = useUserInfo();
  const [selectedFilter, setSelectedFilter] = useState<VolumeFilter>(
    getFilterPeriod(VolumeFilterTypes.MTD)
  );
  const {mids, handleSelectMid, selectedMid} = useMidsContainer();

  const getBreadcrumbs = () => [<Typography variant="body1">Dashboard</Typography>];

  return (
    <Box>
      <Title breadcrumbs={getBreadcrumbs()}>
        <Box>
          <FilterPeriodSelect
            onSelect={setSelectedFilter}
            selected={selectedFilter}
            visibleVolumeFilterTypes={[
              VolumeFilterTypes.OneWeek,
              VolumeFilterTypes.FourWeeks,
              VolumeFilterTypes.OneYear,
              VolumeFilterTypes.MTD,
              VolumeFilterTypes.QTD,
              VolumeFilterTypes.YTD,
              VolumeFilterTypes.All,
              VolumeFilterTypes.Custom,
            ]}
          />
        </Box>
        <Box ml={2}>
          <MidSelect mids={mids} handleSelectMid={handleSelectMid} selectedMid={selectedMid} />
        </Box>
      </Title>
      <Box pr={2}>
        {!isMerchant && (
          <Box mt={5} mb={7}>
            <ApplicationAnalyticsPage filters={selectedFilter} />
          </Box>
        )}
        <Box mt={5}>
          <MerchantAnalyticsSection filters={selectedFilter} />
        </Box>
        {isMerchant && (
          <Box mt={5}>
            <TransactionsSummary filters={selectedFilter} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
