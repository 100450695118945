import {useCallback} from 'react';
import {useFormContext} from 'react-hook-form';
import {
  AssigneeType,
  Collections,
  Firebase,
  Ticket,
  TicketHistoryRecordType,
  ViewableByType,
} from '../../../..';
import {FIELD_NAME_ASSIGNEE, FIELD_NAME_VIEWABLE_BY} from '../../constants/constants';
import {useHistoryRecord} from '../../hooks/useHistoryRecord';
import {useIsEditingDisabled} from '../../hooks/useIsEditingDisabled';
import {useTicketId} from '../../hooks/useTicketId';
import {InputViewableBy} from './InputViewableBy';

export const InputViewableByDetail = () => {
  const {ticketId} = useTicketId();
  const {isEditingDisabled} = useIsEditingDisabled();
  const {watch, setValue, getValues} = useFormContext<Ticket>();
  const viewableBy =
    watch(FIELD_NAME_VIEWABLE_BY) ?? getValues(FIELD_NAME_VIEWABLE_BY) ?? ViewableByType.all;
  const assignee = watch(FIELD_NAME_ASSIGNEE);
  const {addHistoryRecord} = useHistoryRecord();

  const saveViewableBy = useCallback(
    (viewableBy: ViewableByType) => {
      setValue('viewableBy', viewableBy);
      Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .set({viewableBy, updatedAt: Firebase.now()}, {merge: true})
        .catch(error => {
          console.error('Error writing document: ', error);
        });

      addHistoryRecord(
        `Viewable By Changed to ${viewableBy}`,
        TicketHistoryRecordType.ViewableBy,
        true,
        `Ticket was updated`
      );
    },
    [ticketId, setValue]
  );

  const showAssigneeEditorDialogOnChange = (newValue: ViewableByType) => {
    if (
      newValue === ViewableByType.internalOnly &&
      assignee?.type !== AssigneeType.erpUser &&
      assignee?.type !== AssigneeType.erpDepartment
    ) {
      return true;
    }

    return (
      newValue === ViewableByType.agentOrErp &&
      assignee?.type !== AssigneeType.erpUser &&
      assignee?.type !== AssigneeType.erpDepartment &&
      assignee?.type !== AssigneeType.agent
    );
  };

  return (
    <InputViewableBy
      viewableBy={viewableBy}
      isEditingDisabled={isEditingDisabled}
      setViewableBy={saveViewableBy}
      disableUnderline
      showAssigneeEditorDialogOnChange={showAssigneeEditorDialogOnChange}
    />
  );
};
