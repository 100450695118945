import DeleteIcon from '@mui/icons-material/Delete';
import {LoadingButton} from '@mui/lab';
import {Button, IconButton} from '@mui/material';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import {useCallback, useState} from 'react';
import {useWatch} from 'react-hook-form';
import {Collections, Firebase, Ticket, TicketStatus} from '../../..';
import {useNotification} from '../../../hooks';
import {FIELD_NAME_STATUS} from '../constants/constants';
import {useLastCommentsInfo} from '../hooks/useLastCommentsInfo';
import {useTicketEditContainer} from '../hooks/useTicketEditContainer';
import {useTicketId} from '../hooks/useTicketId';

type Props = {
  commentId?: string;
  disabled?: boolean;
};

export function ButtonDeleteComment({commentId, disabled}: Props) {
  const {commentsCollection, commentsInternal} = useTicketEditContainer();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {ticketId} = useTicketId();
  const [isDeleting, setIsDeleting] = useState(false);
  const status = useWatch({name: FIELD_NAME_STATUS});
  const open = Boolean(anchorEl);
  const showNotification = useNotification();
  const {getLastCommentsInfo} = useLastCommentsInfo();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = useCallback(async () => {
    setIsDeleting(true);

    try {
      let attachmentsRef = Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .collection(commentsCollection)
        .doc(commentId)
        .collection(Collections.attachments);

      // delete attachments
      const commentAttachmentsSnap = await attachmentsRef.get();

      if (commentAttachmentsSnap.size) {
        await Promise.all(
          commentAttachmentsSnap.docs.map(async doc => {
            try {
              return await attachmentsRef.doc(doc.id).delete();
            } catch (error) {
              console.error(error);
            }
          })
        );
      }

      // Delete comment.
      await Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .collection(commentsCollection)
        .doc(commentId)
        .delete();

      // If comment is the last comment in the ticket - refresh ticket's last comment info.
      // If comment's id is the same as ticket's lastCommentInfo.commentId - then comment is a last comment.
      const ticketSnap = await Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .get();

      if (ticketSnap.exists) {
        const ticket = ticketSnap.data() as Ticket;
        const lastCommentInfo = commentsInternal
          ? ticket.lastInternalCommentInfo
          : ticket.lastPublicCommentInfo;
        if (lastCommentInfo?.commentId === commentId) {
          // update last comment infos in the ticket
          const lastCommentsInfo = await getLastCommentsInfo(ticketId);
          await Firebase.firestore
            .collection(Collections.tickets)
            .doc(ticketId)
            .update({
              ...lastCommentsInfo,
            });
        }
      }

      showNotification('success', 'Comment has been deleted.');
    } catch (error) {
      console.error(error);
      showNotification('error', 'There was an error trying to delete this comment.');
    } finally {
      setIsDeleting(false);
    }
  }, [ticketId, commentId, commentsCollection]);

  if (status === TicketStatus.Deleted) {
    return null;
  }

  return (
    <>
      <Tooltip title="Delete Comment">
        <IconButton size="small" onClick={handleClick} disabled={disabled}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <Box p={2} maxWidth={300}>
          Delete this comment?
        </Box>
        <Box p={2}>
          <LoadingButton
            loading={isDeleting}
            variant="contained"
            color="error"
            onClick={handleDeleteClick}
          >
            Delete
          </LoadingButton>
          <Button variant="text" onClick={handleClose} sx={{ml: 2}}>
            Cancel
          </Button>
        </Box>
      </Menu>
    </>
  );
}
