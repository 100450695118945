import {Agent, AgentView, Collections, Firebase, selectAgentView, UniversalSnapshot} from '../..';

export const getAgents = async (): Promise<AgentView[]> => {
  const snapshot = await Firebase.firestore
    .collection(Collections.agents)
    .where('isActive', '==', true)
    .get();

  if (snapshot.empty) {
    return [];
  }

  return snapshot.docs.map(doc => selectAgentView(doc as UniversalSnapshot<Agent>));
};

export const getAgentById = async (agentId: string): Promise<AgentView> => {
  const doc = await Firebase.firestore.collection(Collections.agents).doc(agentId).get();

  if (!doc.exists) {
    throw new Error('Agent not found');
  }

  return selectAgentView(doc as UniversalSnapshot<Agent>);
};
