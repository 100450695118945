import {Box, Grid, InputAdornment, MenuItem} from '@mui/material';
import {
  ApplicationEquipment,
  ApplicationType,
  AsyncState,
  DeprecatedPaymentTypes,
  Dispositions,
  EquipmentDurationType,
  EquipmentFileType,
  EquipmentView,
  EquipmentYesOrNo,
  isOtherNotListedEquipment,
  nonGatewayPaymentTypes,
  PaymentType,
  PinDebitInjectionOptions,
  PlacementsValuesGateway,
  PlacementsValuesNonGateway,
  PlacementType,
  timeList,
} from '@ozark/common';
import {Select, TextField} from '@ozark/common/components';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {AccordionSection} from '../Elements/AccordionSection';
import {PricingEquipmentFormInput} from '../PricingEquipmentSchema';
import {AccordionId} from '../useAccordionOnError';
import {EquipmentListItemMenu} from './EquipmentListItemMenu';
import {FieldSelectAccessories} from './FieldSelectAccessories';
import {FieldSelectEquipmentValidatedNative} from './FieldSelectEquipmentValidatedNative';

type Equipment = {
  fieldName: 'equipment';
  applicationId: string;
  applicationType?: ApplicationType;
  equipment: AsyncState<EquipmentView[]>;
  readonly: boolean;
  disposition?: Dispositions;
  onBeforeUpload: () => Promise<boolean>;
  saveApplication: () => void;
};

type AdditionalEquipment = {
  fieldName: 'equipmentAdditional';
  applicationId: string;
  applicationType?: ApplicationType;
  equipment: AsyncState<EquipmentView[]>;
  readonly: boolean;
  index: number;
  data: ApplicationEquipment;
  remove: (index: number) => void;
  disposition?: Dispositions;
  onBeforeUpload: () => Promise<boolean>;
  saveApplication: () => void;
};

const paymentTypes = Object.values(PaymentType);

export const EquipmentListItem = (item: Equipment | AdditionalEquipment) => {
  const {
    watch,
    control,
    formState: {errors},
    setValue,
    formState,
  } = useFormContext<PricingEquipmentFormInput>();

  const [placementOptions, setPlacementOptions] = useState<
    typeof PlacementsValuesGateway | typeof PlacementsValuesNonGateway
  >();

  const targetFieldName =
    item.fieldName === 'equipment' ? 'equipment' : (`equipmentAdditional.${item.index}` as const);

  const data = item.fieldName === 'equipment' ? undefined : item.data;

  const {applicationId, equipment, readonly, disposition} = item;

  const watchEquipmentId = watch(`${targetFieldName}.id`, applicationId);
  const watchFileType = watch(`${targetFieldName}.fileType`, data?.fileType);
  const watchCashDiscount = watch(`${targetFieldName}.cashDiscount`, data?.cashDiscount);
  const watchAutoBatch = watch(`${targetFieldName}.autoBatch`, data?.autoBatch);
  const watchEquipmentIsGateway = watch(`${targetFieldName}.isGateway`, data?.isGateway);
  const watchPaymentType = watch(`${targetFieldName}.paymentType`, data?.paymentType);
  const watchPlacementType = watch(`${targetFieldName}.placementType`, data?.placementType);
  const watchDescription = watch(`${targetFieldName}.description`, data?.description);
  const watchIsVarSheet = watch(`${targetFieldName}.isVarSheet`, data?.isVarSheet);

  const chosenEquipment = watchEquipmentId
    ? equipment.data?.find(e => e.id === watchEquipmentId)
    : null;

  const isOtherNotListed = isOtherNotListedEquipment(chosenEquipment?.name);

  const availablePaymentTypes = useMemo(() => {
    const filteredDeprecated = DeprecatedPaymentTypes.filter(d => d !== watchPaymentType);

    return (
      paymentTypes
        // exclude current payment type from deprecated type list to avoid empty value
        .filter(x => !filteredDeprecated.includes(x))
        .filter(x => !watchEquipmentIsGateway || !nonGatewayPaymentTypes.includes(x))
    );
  }, [watchPaymentType, watchEquipmentIsGateway]);

  const isCurrentPaymentTypeDeprecated =
    watchPaymentType &&
    (Object.values(DeprecatedPaymentTypes) as string[]).includes(watchPaymentType);

  useEffect(() => {
    if (watchEquipmentIsGateway) {
      setPlacementOptions(PlacementsValuesGateway);
    } else {
      setPlacementOptions(PlacementsValuesNonGateway);
    }
  }, [watchEquipmentIsGateway]);

  useEffect(() => {
    if (watchPlacementType === PlacementType.freePlacementPriorApprovalRequired) {
      setValue(`${targetFieldName}.paymentType`, PaymentType.freePlacementPriorApprovalRequired);
    }

    if (watchPlacementType === PlacementType.rental) {
      setValue(`${targetFieldName}.paymentType`, PaymentType.rental);
    }
  }, [targetFieldName, watchPlacementType, setValue]);

  useEffect(() => {
    if (watchPaymentType === PaymentType.freePlacementPriorApprovalRequired) {
      setValue(
        `${targetFieldName}.placementType`,
        PlacementType.freePlacementPriorApprovalRequired
      );
    }
  }, [targetFieldName, watchPaymentType, setValue]);

  useEffect(() => {
    if (watchEquipmentIsGateway) {
      setValue(`${targetFieldName}.serialNumber` as any, '');
    }
  }, [watchEquipmentIsGateway, targetFieldName, setValue]);

  useEffect(() => {
    if (watchIsVarSheet && !watchPlacementType) {
      setValue(`${targetFieldName}.placementType`, PlacementType.varSheetOnly, {shouldDirty: true});
    }
  }, [watchIsVarSheet, watchPlacementType, placementOptions, formState.dirtyFields]);

  const handleEquipmentRemove = useCallback(() => {
    if (item.fieldName === 'equipmentAdditional') {
      if (confirm('Are you sure you want to delete additional equipment?')) {
        item.remove(item.index);
      }
    }
  }, [item]);

  const handleCashDiscountChange = useCallback(
    (value: string) => {
      if (value === 'false') {
        setValue(`${targetFieldName}.cashDiscountPercent`, null, {shouldDirty: true});
      }
    },
    [setValue, targetFieldName]
  );

  useEffect(() => {
    if (
      item.applicationType &&
      item.applicationType !== ApplicationType.cashDiscount &&
      item.applicationType !== ApplicationType.surcharge &&
      item.applicationType !== ApplicationType.dualPricingPassThroughFees &&
      item.applicationType !== ApplicationType.dualPricingFlatRate
    ) {
      setValue(`${targetFieldName}.cashDiscount`, false, {shouldDirty: true});
      setValue(`${targetFieldName}.cashDiscountPercent`, null, {shouldDirty: true});
    }
  }, [item.applicationType, setValue, targetFieldName]);

  if (equipment.promised) {
    return null;
  }

  return (
    <AccordionSection
      id={AccordionId.Equipment}
      title={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box>
            Equipment{' '}
            {item.fieldName === 'equipmentAdditional'
              ? `: ${watchDescription ? watchDescription : ` Additional ${item.index}`}`
              : ''}
          </Box>
          <EquipmentListItemMenu
            applicationId={applicationId}
            isAdditional={item.fieldName === 'equipmentAdditional'}
            targetFieldName={targetFieldName}
            readonly={readonly}
            handleEquipmentRemove={handleEquipmentRemove}
            onBeforeUpload={item.onBeforeUpload}
            saveApplication={item.saveApplication}
          />
        </Box>
      }
      defaultExpanded
    >
      <Grid container spacing={2}>
        <FieldSelectEquipmentValidatedNative
          targetFieldName={targetFieldName}
          readonly={readonly}
          equipment={equipment}
        />
        <Grid item xs={6}>
          <Select
            name={`${targetFieldName}.paymentType`}
            defaultValue={data?.paymentType}
            label="Payment Type"
            errors={errors}
            control={control}
            displayValue={true}
            displayedDeprecatedValue={isCurrentPaymentTypeDeprecated ? watchPaymentType : undefined}
            disabled={readonly}
            options={Object.values(availablePaymentTypes)}
            compareFn={(a, b) => paymentTypes.indexOf(a) - paymentTypes.indexOf(b)}
            required
          />
        </Grid>
        {(watchIsVarSheet || isOtherNotListed) && (
          <Grid item xs={12}>
            <TextField
              name={`${targetFieldName}.softwareName`}
              label="Software/Gateway Name"
              errors={errors}
              control={control}
              disabled={readonly}
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <Select
            name={`${targetFieldName}.placementType`}
            defaultValue={data?.placementType}
            label="Placement Type"
            errors={errors}
            control={control}
            displayValue={true}
            disabled={readonly}
            options={placementOptions}
            required
          />
        </Grid>
        {!watchEquipmentIsGateway && (
          <Grid item xs={2}>
            <TextField
              name={`${targetFieldName}.serialNumber` as any}
              defaultValue={data?.serialNumber}
              label="Equipment Serial Number"
              errors={errors}
              control={control}
              disabled={readonly}
            />
          </Grid>
        )}

        {watchPlacementType === PlacementType.gatewaySetupByProcessor &&
          watchEquipmentIsGateway && (
            <>
              <Grid item xs={4}>
                <TextField
                  name={`${targetFieldName}.virtualSetupFee`}
                  label="Setup Fee"
                  errors={errors}
                  control={control}
                  type="number"
                  disabled={readonly}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name={`${targetFieldName}.virtualMonthlyFee`}
                  label="Monthly Fee"
                  errors={errors}
                  control={control}
                  disabled={readonly}
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name={`${targetFieldName}.virtualTransactionFee`}
                  label="Trans Fee"
                  errors={errors}
                  control={control}
                  disabled={readonly}
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
            </>
          )}
        {(watchPlacementType !== PlacementType.gatewaySetupByProcessor ||
          !watchEquipmentIsGateway) && (
          <>
            <Grid item xs={2}>
              <TextField
                name={`${targetFieldName}.price`}
                defaultValue={data?.price}
                label="Price"
                errors={errors}
                control={control}
                disabled={readonly}
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Select
                name={`${targetFieldName}.duration`}
                label="Billing Method"
                errors={errors}
                control={control}
                disabled={readonly}
              >
                {Object.entries(EquipmentDurationType).map(([key, value]) => (
                  <MenuItem key={`${key}`} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6}>
          <Select
            name={`${targetFieldName}.connectionType`}
            defaultValue={data?.connectionType}
            label="Connection Type"
            errors={errors}
            control={control}
            disabled={!chosenEquipment || readonly}
            options={chosenEquipment ? chosenEquipment.options?.connectionType : []}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            name={`${targetFieldName}.contactless`}
            defaultValue={data?.contactless}
            label="Contactless"
            errors={errors}
            control={control}
            disabled={!chosenEquipment || readonly}
            yesOrNo={chosenEquipment && isYesOrNo(chosenEquipment.options?.contactless)}
            options={
              chosenEquipment && !isYesOrNo(chosenEquipment.options?.contactless)
                ? chosenEquipment.options?.contactless
                : []
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            required
            name={`${targetFieldName}.fileType`}
            defaultValue={data?.fileType}
            label="File Type"
            errors={errors}
            control={control}
            disabled={!chosenEquipment || readonly}
            options={chosenEquipment ? chosenEquipment.options?.fileType : []}
          />
        </Grid>
        {disposition === Dispositions.boarded && (
          <Grid item xs={12} sm={6}>
            <Select
              name={`${targetFieldName}.pinDebitInjection`}
              defaultValue={data?.fileType}
              label="Pin Debit Injection"
              errors={errors}
              control={control}
              options={Object.values(PinDebitInjectionOptions).map(val => val)}
              disabled={!chosenEquipment || readonly}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          {watchFileType !== EquipmentFileType.retail && (
            <Select
              name={`${targetFieldName}.tipMode`}
              defaultValue={data?.tipMode}
              label="Tip Mode"
              errors={errors}
              control={control}
              disabled={
                !chosenEquipment || watchFileType !== EquipmentFileType.restaurant || readonly
              }
              options={
                chosenEquipment && watchFileType === EquipmentFileType.restaurant
                  ? chosenEquipment.options?.tipMode
                  : []
              }
            />
          )}
        </Grid>
        {(item.applicationType === ApplicationType.cashDiscount ||
          item.applicationType === ApplicationType.surcharge ||
          item.applicationType === ApplicationType.dualPricingPassThroughFees ||
          item.applicationType === ApplicationType.dualPricingFlatRate) && (
          <>
            <Grid item xs={12} sm={6}>
              <Select
                name={`${targetFieldName}.cashDiscount`}
                defaultValue={data?.cashDiscount}
                label={
                  item.applicationType === ApplicationType.cashDiscount
                    ? 'Cash Discount'
                    : 'Card Holder as Surcharge'
                }
                errors={errors}
                control={control}
                disabled={!chosenEquipment || readonly}
                yesOrNo={chosenEquipment && isYesOrNo(chosenEquipment.options?.cashDiscount)}
                options={
                  chosenEquipment && !isYesOrNo(chosenEquipment.options?.cashDiscount)
                    ? chosenEquipment.options?.cashDiscount
                    : []
                }
                onChangeSuccess={handleCashDiscountChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {String(watchCashDiscount) === 'true' && (
                <TextField
                  name={`${targetFieldName}.cashDiscountPercent`}
                  defaultValue={data?.cashDiscountPercent}
                  label={
                    item.applicationType === ApplicationType.cashDiscount
                      ? 'Cash Discount Percent Billed to Card Holder'
                      : 'Surcharge Percent Billed to Card Holder'
                  }
                  type="number"
                  errors={errors}
                  control={control}
                  disabled={!chosenEquipment || String(watchCashDiscount) !== 'true' || readonly}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
              )}
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6}>
          <Select
            name={`${targetFieldName}.autoBatch`}
            defaultValue={data?.autoBatch}
            label="Auto Batch"
            errors={errors}
            control={control}
            disabled={!chosenEquipment || readonly}
            yesOrNo={chosenEquipment && isYesOrNo(chosenEquipment.options?.autoBatch)}
            options={
              chosenEquipment && !isYesOrNo(chosenEquipment.options?.autoBatch)
                ? chosenEquipment.options?.autoBatch
                : []
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {String(watchAutoBatch) === 'true' && (
            <Select
              name={`${targetFieldName}.autoBatchTime`}
              defaultValue={data?.autoBatchTime}
              label="Batch Time"
              errors={errors}
              control={control}
              disabled={!chosenEquipment || String(watchAutoBatch) !== 'true' || readonly}
              options={timeList()}
              compareFn={() => 0}
            />
          )}
        </Grid>

        {chosenEquipment &&
          chosenEquipment.options?.accessories &&
          chosenEquipment.options?.accessories.length > 0 && (
            <FieldSelectAccessories
              targetFieldNameFull={`${targetFieldName}.accessories`}
              readonly={readonly}
              availableAccessories={chosenEquipment.options.accessories}
            />
          )}

        <Grid item xs={12}>
          <TextField
            name={`${targetFieldName}.tid`}
            defaultValue={data?.tid}
            label="TID #"
            errors={errors}
            control={control}
            disabled={readonly}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name={`${targetFieldName}.sendTrackingNumber`}
            defaultValue={data?.sendTrackingNumber}
            label="Send Tracking #"
            errors={errors}
            control={control}
            disabled={readonly}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name={`${targetFieldName}.receiveTrackingNumber`}
            defaultValue={data?.receiveTrackingNumber}
            label="Receive Tracking #"
            errors={errors}
            control={control}
            disabled={readonly}
          />
        </Grid>
      </Grid>
    </AccordionSection>
  );
};

const isYesOrNo = (value: any) => {
  return typeof value === typeof EquipmentYesOrNo;
};
