import {ActiveFilter, Filter, FilterOption, Filters} from '..';

type Props = {
  filters?: Filter;
  setFilters: (filter: Filter) => void;
  filtersConfig: FilterOption[];
};

export const FiltersAddButton = ({filters, setFilters, filtersConfig}: Props) => {
  const handleApplyFilter = (filter: ActiveFilter) => {
    setFilters({...filters, [filter.option.column]: filter});
  };

  return <Filters options={filtersConfig} onApplyFilter={handleApplyFilter} alignPopup="left" />;
};
