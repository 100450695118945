import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {Button, Grid, Paper, Toolbar, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Equipment, EquipmentView, ViewBase} from '@ozark/functions/src/documents';
import {useState} from 'react';
import {CreateDialog, EditDialog} from '..';
import ComingSoonImage from '../../static/images/comingsoon.png';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  addEquipmentButton: {
    width: 180,
  },
  paper: {
    position: 'relative',
    width: '100%',
    paddingLeft: theme.spacing(4),
    margin: 'auto',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  toolbar: {
    padding: theme.spacing(2, 4, 4, 0),
    justifyContent: 'end',
  },
  equipment: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(4)})`,
    justifyContent: 'space-between',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 25,
    marginBottom: theme.spacing(2),
  },
  equipmentName: {
    height: 46,
  },
  viewableBy: {
    fontWeight: theme.typography.fontWeightBold,
  },
  equipmentImage: {
    flexGrow: 1,
    borderRadius: 25,
    width: `100%`,
    height: 100,
    objectFit: 'contain',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xl')]: {
      height: 150,
    },
    [theme.breakpoints.down('lg')]: {
      height: 200,
    },
  },
  defaultLogo: {
    height: 96,
    width: 96,
    backgroundSize: 'cover',
  },
}));

type Props = {
  equipment: EquipmentView[];
  allowEdit: boolean;
};

export const EquipmentList = ({equipment, allowEdit = false}: Props) => {
  const classes = useStyles();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentEquipmentId, setCurrentEquipmentId] = useState<string>();

  const handleEditClick =
    (equipment: ViewBase<Equipment>) =>
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setCurrentEquipmentId(equipment.id);
      setEditDialogOpen(true);
    };

  return (
    <Grid className={classes.root} container spacing={4}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          {allowEdit && (
            <Toolbar className={classes.toolbar}>
              <Button
                id="addEquipment"
                name="addEquipment"
                className={classes.addEquipmentButton}
                onClick={() => setCreateDialogOpen(true)}
                variant="outlined"
                color="secondary"
                startIcon={<AddIcon />}
              >
                Add Equipment
              </Button>
            </Toolbar>
          )}
          <Grid container spacing={4}>
            {equipment.map(e => {
              return (
                <Grid key={e.id} item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <div className={classes.equipment}>
                    <Typography
                      variant="body1"
                      className={classes.equipmentName}
                      align="center"
                      gutterBottom
                    >
                      {e.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={classes.viewableBy}
                      align="center"
                      gutterBottom
                    >
                      Viewable: {e.viewableBy}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={classes.viewableBy}
                      align="center"
                      gutterBottom
                    >
                      Availability: {e.availability ?? 'n/a'}
                    </Typography>
                    {!e.imageUrl && (
                      <div
                        className={`${classes.defaultLogo} ${classes.equipmentImage}`}
                        style={{backgroundImage: `url(${ComingSoonImage})`}}
                      />
                    )}
                    {e.imageUrl && (
                      <img className={classes.equipmentImage} src={e.imageUrl} alt="logo" />
                    )}
                    {allowEdit && (
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          <Button
                            size="small"
                            onClick={handleEditClick(e)}
                            variant="contained"
                            color="primary"
                            fullWidth
                            startIcon={<EditIcon />}
                          >
                            Edit
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Paper>
      </Grid>
      {createDialogOpen && <CreateDialog onClose={() => setCreateDialogOpen(false)} />}
      {editDialogOpen && currentEquipmentId && (
        <EditDialog id={currentEquipmentId} onClose={() => setEditDialogOpen(false)} />
      )}
    </Grid>
  );
};
