import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {CssBaseline} from '@mui/material';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {getTheme, INACTIVITY_TIME, Store, useAppVersion} from '@ozark/common';
import {LayoutRouter, Loading} from '@ozark/common/components';
import {SnackbarProvider} from 'notistack';
import {useEffect} from 'react';
import {useIdleTimer} from 'react-idle-timer';
import {BrowserRouter as Router} from 'react-router-dom';
import {useComponentRoutesByRole} from '../../config/routes';
import {useStore} from '../../store/helpers';
import AppRoute from '../AppRoute';

const App = () => {
  const {isAuthenticated, authUser, group, authProfile, logout, claims, apiClient} = useStore();
  const {appVersion, refreshCacheAndReload, isLatestVersion} = useAppVersion();
  const layoutRoutes = useComponentRoutesByRole();
  useIdleTimer({timeout: INACTIVITY_TIME, crossTab: true, onIdle: logout});

  useEffect(() => {
    if (window.location.hash && window.location.hash.indexOf('#jump=') > -1) {
      logout();
    }
  }, [logout]);

  useEffect(() => {
    if (!isLatestVersion) {
      refreshCacheAndReload();
    }
  }, [isLatestVersion, refreshCacheAndReload]);

  if (
    isAuthenticated &&
    (authUser.promised || group.promised || authProfile.promised || !claims || appVersion.promised)
  ) {
    return <Loading />;
  }

  if (!group.data) return <div />;

  const theme = getTheme(group.data);
  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Store apiClient={apiClient} authUser={authUser} claims={claims}>
          <SnackbarProvider maxSnack={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Router>
                {layoutRoutes.map((item, index) => (
                  <LayoutRouter
                    key={index}
                    component={item.layout}
                    routes={item.routes}
                    renderRoute={route => (
                      <AppRoute route={route} isLayoutProtected={item.isProtected} />
                    )}
                    renderLayoutRoute={(paths, children) => (
                      <AppRoute
                        route={{
                          path: paths,
                          component: () => children,
                          isProtected: !!item.isProtected,
                        }}
                      />
                    )}
                  />
                ))}
              </Router>
            </LocalizationProvider>
          </SnackbarProvider>
        </Store>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
