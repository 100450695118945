import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import GroupIcon from '@mui/icons-material/Group';
import NotesIcon from '@mui/icons-material/Notes';
import PaymentIcon from '@mui/icons-material/Payment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TollIcon from '@mui/icons-material/Toll';
import {
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import {alpha, Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {AccountStatus} from '@ozark/common';
import {ApplicationsIcon, TransactionsPendingIcon} from '@ozark/common/icons';
import {MinMaxBatchInfo} from '@ozark/functions/src/functions/express/private/types/Batch';
import clsx from 'clsx';
import {format, utcToZonedTime} from 'date-fns-tz';
import React from 'react';
import {currentFormatter} from '..';
import {Column} from '../../api/Column';
import {Firebase} from '../../firebase';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      color: '#4d6575',
      padding: theme.spacing(2, 2, 2, 0),
      margin: theme.spacing(0, 0, 2, 0),
      width: '100%',
      transition: theme.transitions.create(['padding', 'border-color', 'box-shadow']),
      duration: theme.transitions.duration.complex,
      '&:hover': {
        boxShadow: `0px 1px 3px 0px ${alpha(theme.palette.primary.light, 0.9)}`,
      },
    },
    flexRow: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      height: '100%',
    },
    badge: {
      fontSize: '1rem',
      marginTop: 10,
      cursor: 'pointer',
    },
    badgeAnchorTopRight: {
      transform: 'translate(+100%, -50%)',
    },
    flexColumn: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
    icon: {
      height: '100%',
      padding: 0,
      margin: theme.spacing(0, 3),
      '& > *': {
        fill: '#4d6575',
      },
    },
    avatar: {
      height: '100%',
      flexBasis: '10%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(0, 2),
    },
    disposition: {
      width: 120,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    column: {
      height: '100%',
      padding: theme.spacing(0, 3, 0, 0),
      flexBasis: '18%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      transition: theme.transitions.create(['flexBasis'], {
        duration: theme.transitions.duration.standard,
      }),
      [theme.breakpoints.down('lg')]: {
        '&:hover': {
          flexBasis: '40%',
        },
      },
    },
    single: {
      height: '100%',
      flexBasis: '10%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
    divider: {
      padding: 0,
    },
    speedDial: {
      position: 'absolute',
      top: `calc(50% - 28px)`,
      right: theme.spacing(2),
      padding: 0,
    },
    fab: {
      backgroundColor: 'transparent',
      boxShadow: 'none !important',
      color: '#b2c2cd',
      '&:hover,&:focus': {
        color: '#1c252c',
        backgroundColor: '#f5fafc',
      },
      '&:hover $icon': {
        fill: '#1c252c',
      },
    },
    actionFab: {
      color: '#b2c2cd',
      '&:hover,&:focus': {
        color: '#1c252c',
        backgroundColor: '#f5fafc',
      },
      '&:hover $icon': {
        fill: '#1c252c',
      },
    },
    center: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

type infoUrl = {
  name: string;
  url: () => void;
};

type DatabaseStoredTimestamp = {
  _seconds: number;
};

type CardProps = {
  merchant: MinMaxBatchInfo;
  isLastElement?: boolean;
  loadMoreRef?: React.MutableRefObject<null>;
  onClick?: () => void;
  info: {
    profile: infoUrl;
    applications: infoUrl;
    transactions: infoUrl;
    batches: infoUrl;
    authorizations: infoUrl;
    statements: infoUrl;
    deposits: infoUrl;
    chargebacks: infoUrl;
    attachments?: infoUrl;
    notes?: infoUrl;
    tickets?: infoUrl;
  };
};

const displayDate = (date: Date, dateFormat?: string) => {
  const localTime = utcToZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
  return format(localTime, dateFormat ?? 'P');
};

const getStatusText = (merchant: MinMaxBatchInfo) => {
  return merchant.accountStatus?.status ?? 'N/A';
};

const convertDatabaseStoredTimestamp = (timestamp: DatabaseStoredTimestamp) => {
  return Firebase.Timestamp.fromDate(new Date(timestamp._seconds * 1000));
};

const getClosedDate = (merchant: MinMaxBatchInfo) => {
  if (
    merchant.accountStatus?.status === AccountStatus.closed &&
    merchant.accountStatus.statusChangeDate
  ) {
    return displayDate(
      convertDatabaseStoredTimestamp(
        merchant.accountStatus.statusChangeDate as unknown as DatabaseStoredTimestamp
      ).toDate(),
      'MM/dd/yyyy'
    );
  }
  return '';
};

const getFirstBatchDate = (merchant: MinMaxBatchInfo) => {
  if (merchant.firstBatchDate) {
    return displayDate(merchant.firstBatchDate);
  }
  return '';
};

const getFirstBatchAmount = (merchant: MinMaxBatchInfo) => {
  if (merchant.firstBatchAmount) {
    return currentFormatter.format(merchant.firstBatchAmount);
  }
  return '';
};

const getLastBatchDate = (merchant: MinMaxBatchInfo) => {
  if (merchant.firstBatchAmount) {
    return displayDate(merchant.lastBatchDate);
  }
  return '';
};

const getLastBatchAmount = (merchant: MinMaxBatchInfo) => {
  if (merchant.lastBatchAmount) {
    return currentFormatter.format(merchant.lastBatchAmount);
  }
  return '';
};

export const MerchantPortfolioCard = ({
  merchant,
  isLastElement = false,
  loadMoreRef,
  onClick,
  info,
}: CardProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const displayNA = () => (
    <Typography variant="body1" noWrap>
      {'N/A'}
    </Typography>
  );

  const statusText = getStatusText(merchant);
  return (
    <Paper className={classes.paper} ref={isLastElement ? loadMoreRef : null}>
      <div className={clsx(classes.icon)}>
        <GroupIcon color="inherit" />
      </div>
      <div className={classes.column}>
        <Typography variant="body1" noWrap>
          {merchant.agentName}
        </Typography>
        <Typography variant="body1" noWrap>
          {merchant.groupName}
        </Typography>
      </div>
      <Divider className={classes.divider} orientation="vertical" flexItem />
      <div className={clsx(classes.icon)}>
        <DomainVerificationIcon color="inherit" />
      </div>
      <div className={classes.column}>
        <Typography variant="body1" noWrap>
          {merchant.dbaName}
        </Typography>
        <Typography variant="body1" noWrap>
          {displayDate(merchant.createdAt, 'MM/dd/yyyy h:mm a')}
        </Typography>
        <Typography variant="body1" noWrap>
          {merchant.mid}
        </Typography>
      </div>
      <Divider className={classes.divider} orientation="vertical" flexItem />
      <div className={clsx(classes.icon)}>
        <DomainVerificationIcon color="inherit" />
      </div>
      <div className={classes.column}>
        <Tooltip title={statusText} sx={{verticalAlign: 'middle'}}>
          <Typography variant="body1" noWrap>
            {statusText}
          </Typography>
        </Tooltip>
        {merchant.accountStatus?.status === AccountStatus.closed &&
          merchant.accountStatus.statusChangeDate && (
            <Typography variant="body1" noWrap>
              {getClosedDate(merchant)}
            </Typography>
          )}
      </div>
      <Divider className={classes.divider} orientation="vertical" flexItem />
      <div className={clsx(classes.icon)}>
        <TransactionsPendingIcon color="inherit" />
      </div>
      <div className={classes.column}>
        {merchant.firstBatchDate && merchant.firstBatchAmount && (
          <>
            <Typography variant="body1" noWrap>
              {getFirstBatchDate(merchant)}
            </Typography>
            <Typography variant="body1" noWrap>
              {getFirstBatchAmount(merchant)}
            </Typography>
          </>
        )}

        {!merchant.firstBatchDate && displayNA()}
      </div>
      <Divider className={classes.divider} orientation="vertical" flexItem />
      <div className={clsx(classes.icon)}>
        <TransactionsPendingIcon color="inherit" />
      </div>
      <div className={classes.column}>
        {merchant.lastBatchDate && merchant.lastBatchAmount && (
          <>
            <Typography variant="body1" noWrap>
              {getLastBatchDate(merchant)}
            </Typography>
            <Typography variant="body1" noWrap>
              {getLastBatchAmount(merchant)}
            </Typography>
          </>
        )}
        {!merchant.lastBatchDate && displayNA()}
      </div>

      <Divider className={classes.divider} orientation="vertical" flexItem />
      <div className={`${classes.column} ${classes.center}`}>
        <Button
          aria-controls="information"
          aria-haspopup
          variant="contained"
          color="primary"
          onClick={handleMenuClick}
        >
          Information
        </Button>
        <Menu
          id="information-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {info && (
            <div>
              <div className={classes.flexRow}>
                <div className={classes.flexColumn}>
                  <MenuItem onClick={info.profile.url}>
                    <ListItemIcon>
                      <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary={info.profile.name} />
                  </MenuItem>
                  <MenuItem onClick={info.authorizations.url}>
                    <ListItemIcon>
                      <AccountBalanceWalletIcon />
                    </ListItemIcon>
                    <ListItemText primary={info.authorizations.name} />
                  </MenuItem>
                  <MenuItem onClick={info.batches.url}>
                    <ListItemIcon>
                      <ReceiptIcon />
                    </ListItemIcon>
                    <ListItemText primary={info.batches.name} />
                  </MenuItem>
                  <MenuItem onClick={info.statements.url}>
                    <ListItemIcon>
                      <PaymentIcon />
                    </ListItemIcon>
                    <ListItemText primary={info.statements.name} />
                  </MenuItem>
                  {info.attachments && (
                    <MenuItem onClick={info.attachments.url}>
                      <ListItemIcon>
                        <AttachFileIcon />
                      </ListItemIcon>
                      <ListItemText primary={info.attachments.name} />
                    </MenuItem>
                  )}
                  {info.tickets && (
                    <MenuItem onClick={info.tickets.url}>
                      <ListItemIcon>
                        <AssignmentTurnedInIcon />
                      </ListItemIcon>
                      <ListItemText primary={info.tickets.name} />
                    </MenuItem>
                  )}
                </div>
                <div className={classes.flexColumn}>
                  <MenuItem onClick={info.applications.url}>
                    <ListItemIcon>
                      <ApplicationsIcon />
                    </ListItemIcon>
                    <ListItemText primary={info.applications.name} />
                  </MenuItem>
                  <MenuItem onClick={info.transactions.url}>
                    <ListItemIcon>
                      <AccountBalanceIcon />
                    </ListItemIcon>
                    <ListItemText primary={info.transactions.name} />
                  </MenuItem>
                  <MenuItem onClick={info.deposits.url}>
                    <ListItemIcon>
                      <PaymentIcon />
                    </ListItemIcon>
                    <ListItemText primary={info.deposits.name} />
                  </MenuItem>
                  <MenuItem onClick={info.chargebacks.url}>
                    <ListItemIcon>
                      <TollIcon />
                    </ListItemIcon>
                    <ListItemText primary={info.chargebacks.name} />
                  </MenuItem>
                  {info.notes && (
                    <MenuItem onClick={info.notes.url}>
                      <ListItemIcon>
                        <NotesIcon />
                      </ListItemIcon>
                      <ListItemText primary={info.notes.name} />
                    </MenuItem>
                  )}
                </div>
              </div>
            </div>
          )}
        </Menu>
      </div>
    </Paper>
  );
};

export const getMerchantPortfolioCardColumnsConfig = (): Column<MinMaxBatchInfo>[] => [
  {
    id: 'agentName',
    numeric: false,
    sortable: false,
    label: 'Agent Name',
    export: true,
  },
  {
    id: 'groupName',
    numeric: false,
    sortable: false,
    label: 'Group Name',
    export: true,
  },
  {
    id: 'dbaName',
    numeric: false,
    sortable: false,
    label: 'DBA Name',
    export: true,
  },
  {
    id: 'createdAt',
    numeric: false,
    sortable: false,
    label: 'Created At',
    export: row => displayDate(row.createdAt, 'MM/dd/yyyy h:mm a'),
  },
  {
    id: 'mid',
    numeric: false,
    sortable: false,
    label: 'MID',
    export: true,
  },
  {
    id: 'status',
    numeric: false,
    sortable: false,
    label: 'Status',
    export: row => getStatusText(row),
  },
  {
    id: 'closedDate',
    numeric: false,
    sortable: false,
    label: 'Closed Date',
    export: row => getClosedDate(row),
  },
  {
    id: 'firstBatchDate',
    numeric: false,
    sortable: false,
    label: 'First Batch Date',
    export: row => getFirstBatchDate(row),
  },
  {
    id: 'firstBatchAmount',
    numeric: false,
    sortable: false,
    label: 'First Batch Amount',
    export: row => getFirstBatchAmount(row),
  },
  {
    id: 'lastBatchDate',
    numeric: false,
    sortable: false,
    label: 'Last Batch Date',
    export: row => getLastBatchDate(row),
  },
  {
    id: 'lastBatchAmount',
    numeric: false,
    sortable: false,
    label: 'Last Batch Amount',
    export: row => getLastBatchAmount(row),
  },
];
