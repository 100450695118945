import {Box, Button} from '@mui/material';
import {ApplicationView, GroupView, MerchantApplicationStatus} from '@ozark/common';

type Props = {
  application: ApplicationView;
  merchantApplicationStatus: MerchantApplicationStatus;
  group: GroupView;
  onResumeApplication: (application: ApplicationView) => void;
};

export const ApplicationStatusMessage = (props: Props): JSX.Element => {
  const {merchantApplicationStatus} = props;
  const Message = mapMerchantApplicationStatusToComponent[merchantApplicationStatus];

  return <Message {...props} />;
};

type MessageProps = Pick<
  Props,
  'application' | 'onResumeApplication' | 'group' | 'merchantApplicationStatus'
>;

const ApplicationName = ({application}: Pick<MessageProps, 'application'>): JSX.Element => {
  const {distinguishableId, doingBusinessAs} = application;
  const idAndName = doingBusinessAs
    ? `${distinguishableId} - ${doingBusinessAs}`.trim()
    : distinguishableId;

  return <strong>ID: {idAndName}</strong>;
};

const IncompleteApplicationMessage = ({
  application,
  merchantApplicationStatus,
  onResumeApplication,
}: MessageProps): JSX.Element => (
  <Box sx={{textAlign: 'center', mb: 6}}>
    {merchantApplicationStatus === MerchantApplicationStatus.incomplete && (
      <Box sx={{mb: 6}}>
        <Box sx={{mb: 2}}>
          Your application (
          <ApplicationName application={application} />) is <strong>incomplete</strong>.
        </Box>
        <Box>Click the button below to continue where you left off.</Box>
      </Box>
    )}

    {merchantApplicationStatus === MerchantApplicationStatus.signatureNeeded && (
      <Box sx={{mb: 6}}>
        <Box sx={{mb: 2}}>
          Thank you for completing your application (
          <ApplicationName application={application} />)
        </Box>
        <Box sx={{mb: 2}}>Please review the information provided and complete the e-Signature.</Box>
        <Box>Click the button below to continue where you left off.</Box>
      </Box>
    )}
    <Button variant="contained" size="large" onClick={() => onResumeApplication(application)}>
      Resume Application
    </Button>
  </Box>
);

const UnderReviewingApplicationMessage = ({application, group}: MessageProps): JSX.Element => (
  <Box sx={{textAlign: 'center', mb: 6}}>
    <Box sx={{mb: 6}}>
      <Box sx={{mb: 2}}>
        Thank you for completing your application (
        <ApplicationName application={application} />
        ).
      </Box>
      <Box sx={{mb: 2}}>
        Your application is currently being reviewed, please contact us at{' '}
        <a href={`tel:${group.phone}`}>{group.phone}</a> with any questions.
      </Box>
    </Box>
  </Box>
);

const PendingApplicationMessage = ({application, group}: MessageProps): JSX.Element => (
  <Box sx={{textAlign: 'center', mb: 6}}>
    <Box sx={{mb: 6}}>
      <Box sx={{mb: 2}}>
        Thank you for completing your application (
        <ApplicationName application={application} />
        ).
      </Box>
      <Box sx={{mb: 2}}>
        Your application is currently pending additional information needed for approval
      </Box>
      <Box sx={{mb: 2}}>
        Please contact us at <a href={`tel:${group.phone}`}>{group.phone}</a> to proceed with your
        application approval.
      </Box>
    </Box>
  </Box>
);

const DeclinedApplicationMessage = ({application, group}: MessageProps): JSX.Element => (
  <Box sx={{textAlign: 'center', mb: 6}}>
    <Box sx={{mb: 6}}>
      <Box sx={{mb: 2}}>
        Thank you for completing your application (
        <ApplicationName application={application} />
        ).
      </Box>
      <Box sx={{mb: 2}}>
        Unfortunately after further review, your application has been declined.
      </Box>
    </Box>
  </Box>
);

const WithdrawnApplicationMessage = ({application, group}: MessageProps): JSX.Element => (
  <Box sx={{textAlign: 'center', mb: 6}}>
    <Box sx={{mb: 6}}>
      <Box sx={{mb: 2}}>
        Thank you for completing your application (
        <ApplicationName application={application} />
        ).
      </Box>
      <Box sx={{mb: 2}}>
        Your application has been withdrawn, please contact us at{' '}
        <a href={`tel:${group.phone}`}>{group.phone}</a> with any questions.
      </Box>
    </Box>
  </Box>
);

const ApplicationInfo = ({
  application,
  group,
}: Pick<MessageProps, 'application' | 'group'>): JSX.Element => (
  <>
    <Box>
      <strong>Contact Name: </strong>
      {application.firstName || ''} {application.lastName || ''}
    </Box>
    <Box>
      <strong>DBA Address: </strong>
      {application.businessAddress1 ? (
        <>
          {application.businessAddress1}
          {application.businessAddress2 || ''}
          {application.businessCity}, {application.businessState} {application.businessZip}
        </>
      ) : (
        '-'
      )}
    </Box>
    <Box>
      <strong>Business Type: </strong> {application.businessType ?? ''}
    </Box>
    <Box>
      <strong>Monthly Volume: </strong> {application.estimatedMonthlyCreditCardSales ?? ''}
    </Box>
  </>
);

const ApprovedApplicationMessage = ({application, group}: MessageProps): JSX.Element => (
  <Box sx={{mb: 6, display: 'flex', justifyContent: 'center'}}>
    <Box sx={{maxWidth: '800px'}}>
      <Box sx={{mb: 3}}>
        Your application (
        <ApplicationName application={application} />) has been <strong>approved</strong>!
      </Box>
      <Box sx={{mb: 3}}>
        <ApplicationInfo application={application} group={group} />
      </Box>
      <Box>
        <Box sx={{mb: 3}}>
          Your terminal or gateway information will be provided to you shortly.
        </Box>
        <Box>
          Thank you for your partnership,
          <br />
          {group.name}
          <br />
          <a href={`tel:${group.phone}`}>{group.phone}</a>
        </Box>
      </Box>
    </Box>
  </Box>
);

const ReadyToProcessApplicationMessage = ({application, group}: MessageProps): JSX.Element => (
  <Box sx={{mb: 6, display: 'flex', justifyContent: 'center'}}>
    <Box sx={{maxWidth: '800px'}}>
      <Box sx={{mb: 3}}>
        Your application <ApplicationName application={application} /> is{' '}
        <strong>ready to process</strong>!
      </Box>
      <Box sx={{mb: 3}}>
        <ApplicationInfo application={application} group={group} />
      </Box>
      <Box>
        <Box>
          Thank you for your partnership,
          <br />
          {group.name}
          <br />
          <a href={`tel:${group.phone}`}>{group.phone}</a>
        </Box>
      </Box>
    </Box>
  </Box>
);

const mapMerchantApplicationStatusToComponent: {
  [key in MerchantApplicationStatus]: (props: MessageProps) => JSX.Element;
} = {
  [MerchantApplicationStatus.incomplete]: IncompleteApplicationMessage,
  [MerchantApplicationStatus.signatureNeeded]: IncompleteApplicationMessage,
  [MerchantApplicationStatus.pending]: PendingApplicationMessage,
  [MerchantApplicationStatus.underReview]: UnderReviewingApplicationMessage,
  [MerchantApplicationStatus.declined]: DeclinedApplicationMessage,
  [MerchantApplicationStatus.withdrawn]: WithdrawnApplicationMessage,
  [MerchantApplicationStatus.approved]: ApprovedApplicationMessage,
  [MerchantApplicationStatus.readyToProcess]: ReadyToProcessApplicationMessage,
};
