import {Box, Typography} from '@mui/material';
import {MerchantAnalytics} from '@ozark/functions/src/functions/express/private/types';
import {VolumeFilterTypes} from '@ozark/functions/src/functions/express/private/types/Reports';
import {AllMIDs} from '@ozark/functions/src/shared';
import {CancelTokenSource} from 'axios';
import {useEffect, useState} from 'react';
import {formatNumber, formatUSD} from '../..';
import {CancelOperationMessage} from '../../api/Constants';
import {useApiContainer, useMidsContainer} from '../../store';
import {BoxFlexed} from '../common';
import {getFilterPeriod, VolumeFilter} from '../FilterPeriodSelect';
import {DashboardCell, DashboardGrid} from './common/DashboardCell';
import {WidgetDisputes} from './widgets/WidgetDisputes';
import {WidgetDisputesGraph} from './widgets/WidgetDisputesGraph';
import {WidgetSales} from './widgets/WidgetSales';
import {WidgetSalesGraph} from './widgets/WidgetSalesGraph';
import {WidgetTransactions} from './widgets/WidgetTransactions';
import {WidgetTransactionsGraph} from './widgets/WidgetTransactionsGraph';

type Props = {
  filters: VolumeFilter;
};

export const MerchantAnalyticsSection = ({filters}: Props) => {
  const [loading, setLoading] = useState(true);
  const [merchantAnalytics, setMerchantAnalytics] = useState<MerchantAnalytics>();
  const {selectedMid} = useMidsContainer();
  const [, setCancelTokenSource] = useState<CancelTokenSource | undefined>();

  const api = useApiContainer();

  useEffect(() => {
    setLoading(true);
    const cancelSource = api?.dashboard.getCancelTokenSource();
    setCancelTokenSource(prev => {
      //Check if there are any previous pending requests
      if (prev !== undefined) {
        prev.cancel(CancelOperationMessage);
      }
      return cancelSource;
    });

    api?.dashboard
      .getMerchantAnalytics(
        filters ?? getFilterPeriod(VolumeFilterTypes.MTD),
        selectedMid === AllMIDs ? undefined : selectedMid,
        cancelSource?.token
      )
      .then((result: MerchantAnalytics | undefined) => {
        setMerchantAnalytics(result);
        setLoading(false);
      })
      .catch((err: any) => {
        if (err?.message === CancelOperationMessage) {
          return;
        }
        console.error(err);
        setMerchantAnalytics(undefined);
        setLoading(false);
      });
  }, [filters, selectedMid, api]);

  const salesTitle = (
    <BoxFlexed justifyContent="space-between">
      <Box>Sales</Box>
      <Box>
        {merchantAnalytics?.totalSales
          ? `Gross: ${formatUSD(merchantAnalytics.totalSales, 2)} / ${formatNumber(
              merchantAnalytics.totalTransactions
            )}`
          : null}
      </Box>
    </BoxFlexed>
  );

  return (
    <Box>
      <Typography variant="h5" gutterBottom color="secondary.main" fontSize="1.7rem">
        Portfolio Analytics
      </Typography>
      <DashboardGrid>
        <DashboardCell title="Sales">
          <WidgetSales loading={loading} data={merchantAnalytics} />
        </DashboardCell>
        <DashboardCell title={salesTitle}>
          <WidgetSalesGraph loading={loading} data={merchantAnalytics} />
        </DashboardCell>
        <DashboardCell title="Transactions" height={670}>
          <WidgetTransactions loading={loading} data={merchantAnalytics} />
        </DashboardCell>
        <DashboardCell title="Transactions" height={670}>
          <WidgetTransactionsGraph loading={loading} data={merchantAnalytics} />
        </DashboardCell>
        <DashboardCell title="Disputes">
          <WidgetDisputes loading={loading} data={merchantAnalytics} />
        </DashboardCell>
        <DashboardCell title="Disputes">
          <WidgetDisputesGraph loading={loading} data={merchantAnalytics} />
        </DashboardCell>
      </DashboardGrid>
    </Box>
  );
};
