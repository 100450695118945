import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {IconButton, Typography, useMediaQuery, useTheme} from '@mui/material';
import {useRateProgramsByGroup} from '@ozark/common';
import {Loading, Preview, Tab, Tabs, Title} from '@ozark/common/components';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {useStore} from '../../store/helpers';
import {CustomProgram} from './CustomProgram';

const PricingPage = () => {
  const {authProfile, isUserGroupAdmin} = useStore();
  const history = useHistory();
  const theme = useTheme();

  const smDown = useMediaQuery(theme.breakpoints.down('md'));

  const isGroupAdminOrOwner = isUserGroupAdmin() || authProfile.data?.isGroupOwner;
  // Admins and Group Owners are allow to view inactive custom programs:
  const {ratePrograms} = useRateProgramsByGroup(authProfile?.data?.group.id, !isGroupAdminOrOwner);
  const [tab, setTab] = React.useState(0);
  const [whichExpanded, setWhichExpanded] = React.useState<string | false>(false);

  const handleExpandChange =
    (panel: string) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setWhichExpanded(isExpanded ? panel : false);
    };

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
    setTab(newTab);
  };

  const getRateProgramsAsArray = () =>
    !ratePrograms.promised && ratePrograms.data ? ratePrograms.data : [];

  const getBenefits = () => {
    return getRateProgramsAsArray().map(program => (
      <CustomProgram
        key={`${program.name}-${program.createdAt.seconds}`}
        onChange={handleExpandChange}
        whichExpanded={whichExpanded}
        rateProgram={program}
        onSubmit={() => setWhichExpanded(false)}
      />
    ));
  };

  if (!authProfile.data || ratePrograms.promised) return <Loading />;

  return (
    <div>
      <Title
        breadcrumbs={
          smDown
            ? [
                <IconButton onClick={() => history.goBack()} size="large">
                  <ChevronLeftIcon />
                </IconButton>,
              ]
            : [
                <Typography variant="body1">
                  <span style={{textTransform: 'capitalize'}}>Custom Program Benefits</span>
                </Typography>,
              ]
        }
      >
        <Tabs value={tab} onChange={handleTabChange} centered>
          <Tab label="Programs" />
          <Tab label="Preview" />
        </Tabs>
      </Title>
      {tab === 0 && getBenefits()}
      {tab === 1 && <Preview groupId={authProfile.data?.group.id} />}
    </div>
  );
};

export default PricingPage;
