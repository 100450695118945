import {Box} from '@mui/material';
import {green, red} from '@mui/material/colors';
import {formatterCurrency} from '@ozark/common';

type Props = {
  value: number;
};

export const EstimatedVsActualVolumeTableCell = ({value}: Props) => {
  const getColor = () => (value > 0 ? green[800] : red[800]);
  const getSign = () => (value > 0 ? '+' : '');

  return (
    <Box component="span" sx={{color: getColor()}}>
      <Box component="span" sx={{ml: 1}}>
        {getSign()}
        {formatterCurrency.format(value)}
      </Box>
    </Box>
  );
};
