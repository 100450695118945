import {Column} from '@ozark/common/api/Column';
import {SearchCriteria} from '@ozark/common/api/SearchCriteria';
import {Table} from '@ozark/common/components';
import {MccInfo} from '@ozark/functions/src/functions/express/private/types/Reports';
import {useEffect, useState} from 'react';
import {formatAsPercent, formatterCurrency, formatterNumber} from '../../../util/format';

type PropSorted = Extract<keyof MccInfo, 'midsCount' | 'sales' | 'salesAmount'>;

type Props = {
  data: MccInfo[];
};

export const MccsBySalesTable = ({data}: Props) => {
  const [sort, setSort] = useState<[PropSorted, 'ASC' | 'DESC'][]>([]);
  const [dataSorted, setDataSorted] = useState<MccInfo[]>(data);

  const handleRetrieveData = (searchCriteria: SearchCriteria) => {
    setSort([
      [searchCriteria.orderBy as PropSorted, searchCriteria.order.toUpperCase() as 'ASC' | 'DESC'],
    ]);

    const prop = searchCriteria.orderBy as PropSorted;
    const result = dataSorted.sort((a, b) =>
      searchCriteria.order === 'desc' ? a[prop] - b[prop] : b[prop] - a[prop]
    );

    setDataSorted(result);
  };

  useEffect(() => setDataSorted(data), [data]);

  return (
    <Table
      columns={mccsBySalesColumnsConfig}
      data={{
        sort,
        data: dataSorted,
        limit: 10,
        offset: 0,
        totalCount: 10,
      }}
      onRetrieveData={handleRetrieveData}
      flat
    />
  );
};

export const mccsBySalesColumnsConfig: Column<MccInfo>[] = [
  {
    id: 'mcc',
    numeric: false,
    sortable: false,
    label: 'MCC',
    export: true,
  },
  {
    id: 'businessType',
    numeric: false,
    sortable: false,
    label: 'MCC Description',
    export: true,
  },
  {id: 'midsCount', numeric: true, sortable: true, label: 'MIDs', export: true},
  {
    id: 'sales',
    numeric: true,
    sortable: true,
    label: 'Sales',
    selector: (row: MccInfo) => formatterNumber.format(Number(row.sales)),
    export: true,
  },
  {
    id: 'salesAmount',
    numeric: true,
    sortable: true,
    label: 'Sales Amount',
    selector: (row: MccInfo) => formatterCurrency.format(Number(row.salesAmount)),
    export: true,
  },
  {
    id: 'percentOfTotal',
    numeric: true,
    sortable: false,
    label: '% of Total',
    selector: (row: MccInfo) => formatAsPercent(row.percentOfTotal),
    export: true,
  },
];
