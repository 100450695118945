import {Department} from '../constants';
import {AssociationType, UniversalTimestamp, ViewBase} from '../shared';
import {DocumentBase} from './DocumentBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export type TicketTypeCategoryAutomation = {
  association: AssociationType;
  subject: string;
  description: string;
};

export type TicketTypeCategory = DocumentBase & {
  name: string;
  visibleToRoles: UserRoleName[];
  sla?: number; //minutes
  assignment: Department;
  info?: string;
  isDeleted: boolean;
  createdAt?: UniversalTimestamp;
  automation?: TicketTypeCategoryAutomation;
};

export type TicketTypeCategoryView = ViewBase<TicketTypeCategory>;

export const selectTicketTypeCategoryView: ViewSelector<
  TicketTypeCategoryView,
  TicketTypeCategory
> = snapshot => {
  const ticketType: TicketTypeCategoryView = {
    ...defaultViewSelector(snapshot),
  };
  return ticketType;
};

export const UserRoleNames = ['Merchant', 'Agent', 'ERP User'] as const;
export type UserRoleName = typeof UserRoleNames[number];
