import {useEffect, useState} from 'react';
import {Collections, Department, Firebase, Profile} from '../../..';
import {useUserInfo} from '../../../hooks/useUserInfo';

export function useUserDepartment() {
  const {isErpUser, uid} = useUserInfo();
  const [department, setDepartment] = useState<Department>();

  useEffect(() => {
    if (!isErpUser) {
      department && setDepartment(undefined);
      return;
    }
    return Firebase.firestore
      .collection(Collections.profiles)
      .doc(uid)
      .onSnapshot(
        snapshot => {
          if (!snapshot.exists) {
            setDepartment(undefined);
            return;
          }
          const data = snapshot.data() as Profile;
          setDepartment(data.department as Department | undefined);
        },
        err => {
          console.error(err);
          setDepartment(undefined);
        }
      );
  }, [isErpUser, uid]);

  return {
    department,
  };
}
