import {
  ApplicationDisputeHistory,
  ApplicationDisputeReason,
  Collections,
  Dispositions,
  Firebase,
  UniversalTimestamp,
} from '@ozark/common';

export const useDisputeHistory = () => {
  const getDisputeReasons = async (
    applicationId: string,
    disposition: Dispositions
  ): Promise<ApplicationDisputeReason[]> => {
    try {
      const snapshot = await Firebase.firestore
        .collection(Collections.applications)
        .doc(applicationId)
        .collection(Collections.disputeHistory)
        .where('disposition', '==', disposition)
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get();

      if (snapshot.empty) {
        return [];
      }
      return snapshot.docs.map(doc => doc.data().reasons)?.[0];
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const saveDisputeReasons = async (
    applicationId: string,
    disposition: Dispositions,
    reasons: ApplicationDisputeReason[]
  ) => {
    try {
      const newDisputeHistoryRecord: ApplicationDisputeHistory = {
        disposition: disposition,
        reasons: reasons,
        createdAt: Firebase.Timestamp.now() as UniversalTimestamp,
      };

      await Firebase.firestore
        .collection(Collections.applications)
        .doc(applicationId)
        .collection(Collections.disputeHistory)
        .add(newDisputeHistoryRecord);
    } catch (err) {
      console.error('Error during saving dispute reasons', err);
      return [];
    }
  };

  return {
    getDisputeReasons,
    saveDisputeReasons,
  };
};
