import {AssigneeType, Department} from '../../..';
import {TicketsListFilter} from '../common/TicketListFilter';
import {useTicketsFiltersContainer} from '../hooks/useTicketsFiltersContainer';

const options: string[] = Object.values(Department);

export function TicketsListFilterDepartment() {
  const {departmentFilter, setDepartmentFilter, assigneeTypeFilter} = useTicketsFiltersContainer();

  const isEligible =
    assigneeTypeFilter &&
    [AssigneeType.erpDepartment, AssigneeType.erpUser].includes(assigneeTypeFilter);

  if (!isEligible) return null;

  return (
    <TicketsListFilter
      disabled={!isEligible}
      options={options}
      label="Assignee Department"
      value={departmentFilter}
      valueDefault="All Departments"
      handleChange={val => setDepartmentFilter(val as Department | undefined)}
    />
  );
}
