import {Box, Divider, TableCellProps, TableRowProps} from '@mui/material';
import {
  AllMIDs,
  getIndustryByProcessingType,
  IndustryVerticalBoardedMethods,
  PaginatedResponse,
  ProcessingTypes,
  SearchCriteria,
  useApiContainer,
  useMidsContainer,
} from '@ozark/common';
import {
  ButtonExportCsv,
  Filter,
  FilterOption,
  FiltersAddButton,
  FiltersApplied,
  forceActiveFilter,
  MidSelect,
  percentFormatter,
  Table,
} from '@ozark/common/components';
import {
  SwipedVsKeyedStatistic,
  SwipedVsKeyedStatisticFields,
} from '@ozark/functions/src/functions/express/private/types/SwipedVsKeyedStatistic';
import {omit} from '@s-libs/micro-dash';
import {add} from 'date-fns';
import {useCallback, useEffect, useState} from 'react';
import {Column} from '../../../api/Column';
import {LoadingStatus} from '../../Analytics/common/LoadingStatus';
import {accountStatusFilter} from '../../Filters/common';

export const FraudAnalysisSwipeVsKeyed = () => {
  const apiClient = useApiContainer();
  const {mids, handleSelectMid: setSelectedMid, selectedMid} = useMidsContainer();
  const [swipedVsKeyedStatisticReport, setSwipedVsKeyedStatisticReport] =
    useState<PaginatedResponse<SwipedVsKeyedStatistic> | null>(null);
  const [loading, setLoading] = useState(true);
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>(DEFAULT_SEARCH_CRITERIA);
  const [filters, setFilters] = useState<Filter>({
    transactionDate: getDefaultFilter(),
    ...getMidFilter(selectedMid),
  });

  const handleSelectMid = (mid: string) => {
    setSelectedMid(mid);
    if (mid === AllMIDs) {
      setFilters(omit(filters, 'mid'));
    } else {
      setFilters({
        ...filters,
        ...getMidFilter(mid),
      });
    }
  };

  useEffect(() => {
    if (!apiClient) {
      return;
    }
    const getSwipedVsKeyedStatisticReport = async () => {
      setLoading(true);
      try {
        const report = await apiClient?.fraud.getSwipedVsKeyedStatisticsReport(
          searchCriteria,
          Object.values(filters)
        );
        setSwipedVsKeyedStatisticReport(report || null);
      } catch (error) {
        setSwipedVsKeyedStatisticReport(null);
      } finally {
        setLoading(false);
      }
    };

    getSwipedVsKeyedStatisticReport();
  }, [searchCriteria, filters]);

  const handleRetrieveData = (searchCriteria: SearchCriteria) => {
    setSearchCriteria(searchCriteria);
  };

  const getRowProps = (row: SwipedVsKeyedStatistic): Partial<TableRowProps> => {
    const swipedExceed = Math.abs(row.swipedPercentDelta) > 10;
    const keyedExceed = Math.abs(row.keyedPercentDelta) > 10;

    if (swipedExceed && keyedExceed) {
      return {
        sx: {
          'background-color': 'rgba(255, 0, 0, 0.15)',
        },
      };
    }

    return {};
  };

  const getAllDataForExport = useCallback(async () => {
    if (!apiClient) return [];

    const report = await apiClient?.fraud.getSwipedVsKeyedStatisticsReport(
      {...DEFAULT_SEARCH_CRITERIA, offset: 0, limit: 0},
      []
    );
    return report?.data ?? [];
  }, []);

  const getCellProps = (
    column: Column<SwipedVsKeyedStatistic>,
    row: SwipedVsKeyedStatistic
  ): Partial<TableCellProps> => {
    const swipedExceed =
      column.id === SwipedVsKeyedStatisticFields.swipedPercentDelta &&
      Math.abs(row.swipedPercentDelta) > 10;
    const keyedExceed =
      column.id === SwipedVsKeyedStatisticFields.keyedPercentDelta &&
      Math.abs(row.keyedPercentDelta) > 10;

    if ((swipedExceed || keyedExceed) && !(swipedExceed && keyedExceed)) {
      return {
        sx: {
          'background-color': 'rgba(255, 0, 0, 0.15)',
        },
      };
    }
    return {};
  };

  const hasData = Boolean(swipedVsKeyedStatisticReport && swipedVsKeyedStatisticReport.data.length);

  return (
    <Box mt={2}>
      <Box display="flex" alignItems="center" justifyContent="flex-end" pb={2}>
        <FiltersApplied filters={filters} setFilters={setFilters} />
        <Box flex={1} />
        <FiltersAddButton filters={filters} setFilters={setFilters} filtersConfig={filtersConfig} />
        <Divider orientation="vertical" flexItem sx={{mx: 2}} />
        <MidSelect mids={mids} handleSelectMid={handleSelectMid} selectedMid={selectedMid} />
        <Divider orientation="vertical" flexItem sx={{mx: 2}} />
        <ButtonExportCsv
          filename="swiped-vs-keyed-bin-report"
          rows={swipedVsKeyedStatisticReport?.data}
          getRows={getAllDataForExport}
          columnsConfig={columnsConfig}
          useSelectorMapping
        />
      </Box>
      <LoadingStatus loading={loading} hasData={hasData} />

      {!loading && hasData && (
        <Table
          columns={columnsConfig}
          data={swipedVsKeyedStatisticReport!}
          onRetrieveData={handleRetrieveData}
          getRowProps={getRowProps}
          getCellProps={getCellProps}
          paginate
          stickyHeader
        />
      )}
    </Box>
  );
};

const getMidFilter = (mid: string): Filter => {
  if (!mid || mid === AllMIDs) return {};
  const filterOption = filtersConfig.find(fo => fo.id === 'mid');
  if (!filterOption) throw new Error('filter option not found');
  return {
    mid: {
      option: filterOption,
      operator: filterOption.operators[0],
      value: mid,
    },
  };
};

const getDefaultFilter = () => {
  const dateCurrent = new Date();
  const dateStarting = new Date(dateCurrent.getFullYear(), dateCurrent.getMonth(), 1);
  const transactionDate = forceActiveFilter(filtersConfig, 'dateRange', '__between', [
    dateStarting,
    add(dateStarting, {months: 1}),
  ]);
  return transactionDate;
};

export const DEFAULT_SEARCH_CRITERIA: SearchCriteria = {
  limit: 20, // page size
  offset: 1, // page
  order: 'desc',
  orderBy: SwipedVsKeyedStatisticFields.swiped,
};

const filtersConfig: FilterOption[] = [
  {
    id: 'mid',
    column: 'mid',
    label: 'Merchant MID',
    type: 'text',
    hidden: true,
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'agent',
    column: 'agent',
    label: 'Agent Name',
    type: 'text',
    operators: [
      {
        id: '__like',
        label: 'contains',
      },
    ],
  },
  {
    id: 'dba',
    column: 'dba',
    label: 'DBA Name',
    type: 'text',
    operators: [
      {
        id: '__like',
        label: 'contains',
      },
    ],
  },
  {
    id: 'legalName',
    column: 'legalName',
    label: 'Legal Name',
    type: 'text',
    operators: [
      {
        id: '__like',
        label: 'contains',
      },
    ],
  },
  {
    id: 'date',
    column: 'transactionDate',
    label: 'Date',
    type: 'date',
    operators: [
      {
        id: '__between',
        label: 'equals',
      },
    ],
  },
  {
    id: 'dateRange',
    column: 'transactionDate',
    autoSelect: true,
    label: 'Date Range',
    type: 'dateRange',
    dateFormat: 'MMM dd, yyyy',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  accountStatusFilter,
];

const columnsConfig: Column<SwipedVsKeyedStatistic>[] = [
  {
    id: 'mid',
    label: 'MID',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'dba',
    label: 'DBA',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'legalName',
    label: 'Legal Name',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'agent',
    label: 'Agent',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'swiped',
    label: 'Swiped',
    numeric: false,
    sortable: true,
    export: true,
    selector: row => percentFormatter.format(row.swiped / 100),
  },
  {
    id: 'swipedPercentDelta',
    label: '+/- Swiped',
    numeric: false,
    sortable: true,
    export: true,
    selector: row => percentFormatter.format(row.swipedPercentDelta / 100),
  },
  {
    id: 'keyed',
    label: 'Keyed',
    numeric: false,
    sortable: true,
    export: true,
    selector: row => percentFormatter.format(row.keyed / 100),
  },
  {
    id: 'keyedPercentDelta',
    label: '+/- Keyed',
    numeric: false,
    sortable: true,
    export: true,
    selector: row => percentFormatter.format(row.keyedPercentDelta / 100),
  },
  {
    id: 'processingType',
    label: 'Boarded Method',
    numeric: false,
    sortable: true,
    export: true,
    selector: row =>
      IndustryVerticalBoardedMethods[
        getIndustryByProcessingType(row.processingType as ProcessingTypes)
      ],
  },
  {
    id: 'defaultPercentages',
    label: 'Default Percentages',
    numeric: false,
    sortable: false,
    export: true,
    selector: row => `${row.swipedPercent}/${row.keyedPercent}`,
  },
];
