import {
  Agent,
  Collections,
  GroupRole,
  selectAgentView,
  UniversalSnapshot,
  UserRoles,
} from '@ozark/common';
import {flatten} from '@s-libs/micro-dash';
import {useCallback} from 'react';
import {Firebase} from '../firebase';
import {chunkArray} from '../util/ArrayUtil';

const fetchAgents = async (where: [fieldPath: any, opStr: any, value: any]) => {
  const snapshot = await Firebase.firestore
    .collection(Collections.agents)
    .where(...where)
    .get();

  if (snapshot.size === 0) {
    return [];
  }

  return snapshot.docs.map(e => selectAgentView(e as UniversalSnapshot<Agent>));
};

type UseGetAuthorizedAgentsProps = {
  groupId?: string;
  groupRole?: GroupRole;
  role?: UserRoles;
  uid?: string;
};

const useGetAuthorizedAgents = ({groupId, groupRole, role, uid}: UseGetAuthorizedAgentsProps) => {
  const getAuthorizedAgents = useCallback(async () => {
    if (!groupRole || role !== UserRoles.agent || !uid) {
      return;
    }

    if (groupRole === GroupRole.administrator) {
      if (!groupId) {
        return;
      }
      // todo make work with authProfile?.data?.authorizedMids
      return await fetchAgents(['group.id', '==', groupId]);
    }

    const currentAgentSnapshot = await Firebase.firestore
      .collection(Collections.agents)
      .doc(uid)
      .get();

    const currentAgent = selectAgentView(currentAgentSnapshot as UniversalSnapshot<Agent>);

    // Master-agents can view all sub-agents and themselves.
    if (currentAgent.subAgentUids && currentAgent.subAgentUids.length > 0) {
      const agentIds = [...currentAgent.subAgentUids, currentAgent.id];
      const chunks = chunkArray<string>(agentIds, 10); // firebase 'in' limit

      return flatten(
        await Promise.all(
          chunks.map(chunk => fetchAgents([Firebase.FieldPath.documentId(), 'in', chunk]))
        )
      );
    }

    return [currentAgent];
  }, [groupId, groupRole, role, uid]);

  return getAuthorizedAgents;
};

export {useGetAuthorizedAgents};
