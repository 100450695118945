import {
  CreateDisputeReasonProps,
  Dispositions,
  Markdown,
  MarkdownOptions,
  PendingReasonsRaw,
} from '@ozark/common';
import {createDisputeReason} from '../createDisputeReason';
import {DisputeReason} from '../index';

export const pending = {
  [Dispositions.uwPending]: PendingReasonsRaw.map(reason => {
    const {name, description, type, params} = reason as CreateDisputeReasonProps;
    return createDisputeReason({
      name: name,
      description: Markdown?.render(description, {linkify: true} as MarkdownOptions) || '',
      type: type,
      params: params,
    });
  }) as DisputeReason[],
} as {[key in Dispositions]?: DisputeReason[]};
