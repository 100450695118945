import {TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

interface Name {
  firstName?: string;
}
const defaultValue: Name = {
  firstName: 'All Agents',
};

type Props<T extends Name> = {
  selected?: T;
  setSelected: (agent?: T) => void;
  placeholder: string;
  options: T[];
  getOptionLabel: (item: T) => string;
  onItemSelect: (item: T | null) => void;
  onBlur?: () => void;
};

export function AutoCompleteTextField<T extends Name>({
  selected,
  setSelected,
  placeholder,
  options,
  getOptionLabel,
  onItemSelect,
  onBlur,
}: Props<T>) {
  return (
    <Autocomplete
      id="search-agent-field"
      options={[...options]}
      getOptionLabel={getOptionLabel}
      value={selected ?? (defaultValue as T)}
      onChange={(_event: any, newValue: T | null) => {
        setSelected(newValue ?? undefined);
        onItemSelect(newValue);
      }}
      onBlur={() => {
        onBlur?.();
      }}
      blurOnSelect
      disableClearable={!selected}
      style={{width: 250}}
      renderInput={params => {
        const {InputProps, ...rest} = params;
        return (
          <TextField
            variant="standard"
            InputProps={{
              disableUnderline: true,
              ...InputProps,
            }}
            {...rest}
            label={placeholder}
          />
        );
      }}
    />
  );
}
