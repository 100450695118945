export const download = (url: string, fileName: string) => {
  fetch(url).then(async response => {
    const blob = await response.blob();
    var a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.setAttribute('download', fileName);
    a.click();
  });
};

export const dataUrlToBlob = (dataUrl: string) => {
  try {
    const arr = dataUrl.split(',');
    if (!arr[0]) return null;
    let mime = arr[0].match(/:(.*?);/)![1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type: mime});
  } catch (err) {
    return null;
  }
};
