import {Box, Divider} from '@mui/material';
import {PaginatedResponse} from '@ozark/functions/src/functions/express/private/types';
import {
  FraudUnmatchedRefundsFields,
  FraudUnmatchedRefundsRecord,
} from '@ozark/functions/src/functions/express/private/types/Reports';
import {add, sub} from 'date-fns';
import {format, utcToZonedTime} from 'date-fns-tz';
import {omit} from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import {generatePath, Link} from 'react-router-dom';
import {
  ButtonExportCsv,
  currentFormatter,
  Filter,
  FilterOption,
  FiltersAddButton,
  FiltersApplied,
  forceActiveFilter,
  MERCHANT_PORTFOLIO_DETAILS,
  MidSelect,
  Table,
} from '../..';
import {AllMIDs, SearchCriteria, useApiContainer, useMidsContainer} from '../../..';
import {Column} from '../../../api/Column';
import {LoadingStatus} from '../../Analytics/common/LoadingStatus';
import {accountStatusFilter} from '../../Filters/common';

const getDefaultFilter = () => {
  const date = sub(new Date(), {months: 1});
  const dateStarting = new Date(date.getFullYear(), date.getMonth(), 1);
  const transactionDate = forceActiveFilter(filtersConfig, 'dateRange', '__between', [
    dateStarting,
    add(dateStarting, {months: 1}),
  ]);
  return transactionDate;
};

export const FraudAnalysisUnmatchedRefunds = () => {
  const [loading, setLoading] = useState(true);
  const [pageConfig, setPageConfig] = useState<SearchCriteria>(pageConfigDefault);
  const {mids, handleSelectMid: setSelectedMid, selectedMid} = useMidsContainer();
  const [filters, setFilters] = useState<Filter>({
    transactionDate: getDefaultFilter(),
    ...getMidFilter(selectedMid),
  });
  const [report, setReport] = useState<PaginatedResponse<FraudUnmatchedRefundsRecord> | null>(null);
  const api = useApiContainer();
  const hasData = Boolean(report?.data.length);
  const totalCount = report?.totalCount ?? pageConfig.limit;

  const handleSelectMid = useCallback(
    (mid: string) => {
      setSelectedMid(mid);
      if (mid === AllMIDs) {
        setFilters(prev => omit(prev, 'mid'));
      } else {
        setFilters(prev => ({
          ...prev,
          ...getMidFilter(mid),
        }));
      }
    },
    [setSelectedMid]
  );

  const getAllDataForExport = useCallback(async () => {
    if (!api) return [];
    const pageConfigFull: SearchCriteria = {...pageConfigDefault, offset: 0, limit: 0};
    const result = await api.fraud.getFraudUnmatchedRefunds(pageConfigFull);
    return result?.data ?? [];
  }, []);

  useEffect(() => {
    if (!api) return;

    setLoading(true);
    let isMounted: boolean = true;

    const loadReport = async () => {
      try {
        const result = await api.fraud.getFraudUnmatchedRefunds(pageConfig, Object.values(filters));
        if (isMounted) setReport(result);
      } catch (error) {
        console.error('Failed at loading FraudUnmatchedRefunds report with an error', error);
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    loadReport();
    return () => {
      isMounted = false;
    };
  }, [pageConfig, filters]);

  return (
    <Box mt={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between" pb={2}>
        <FiltersApplied filters={filters} setFilters={setFilters} />
        <Box display="flex" alignItems="center">
          <FiltersAddButton
            filters={filters}
            setFilters={setFilters}
            filtersConfig={filtersConfig}
          />
          <Divider orientation="vertical" flexItem sx={{mx: 2}} />
          <MidSelect mids={mids} handleSelectMid={handleSelectMid} selectedMid={selectedMid} />
          <Divider orientation="vertical" flexItem sx={{mx: 2}} />
          <ButtonExportCsv
            filename="fraud-unmatched-refunds-report"
            rows={report?.data}
            getRows={getAllDataForExport}
            columnsConfig={columnsConfig}
            useSelectorMapping
          />
        </Box>
      </Box>
      <LoadingStatus loading={loading} hasData={hasData} />

      {!loading && hasData && (
        <Table
          columns={columnsConfig}
          data={{
            sort: [[pageConfig.orderBy, pageConfig.order as 'DESC' | 'ASC']],
            limit: pageConfig.limit,
            offset: pageConfig.offset,
            totalCount: report?.totalCount ?? 0,
            data: report?.data ?? [],
          }}
          onRetrieveData={setPageConfig}
          paginate
          useOffsetAsPage
        />
      )}
    </Box>
  );
};

const pageConfigDefault: SearchCriteria = {
  limit: 20, // pageSize
  offset: 1, // page (offset = page * pageSize 1 based) 0 will produce negative offset
  order: 'desc',
  orderBy: FraudUnmatchedRefundsFields.amount,
};
const filtersConfig: FilterOption[] = [
  {
    id: 'dateRange',
    column: 'transactionDate',
    label: 'Date range',
    type: 'dateRange',
    dateFormat: 'MMM dd, yyyy',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'date',
    column: 'transactionDate',
    label: 'Refund date',
    type: 'date',
    dateFormat: 'MMM dd, yyyy',
    operators: [
      {
        id: '__between',
        label: 'equals',
      },
    ],
  },
  {
    id: 'agent',
    column: 'agent',
    label: 'Agent Name',
    type: 'text',
    operators: [
      {
        id: '__like',
        label: 'contains',
      },
    ],
  },
  {
    id: 'dba',
    column: 'dba',
    label: 'DBA Name',
    type: 'text',
    operators: [
      {
        id: '__like',
        label: 'contains',
      },
    ],
  },
  {
    id: 'legalName',
    column: 'legalName',
    label: 'Legal Name',
    type: 'text',
    operators: [
      {
        id: '__like',
        label: 'contains',
      },
    ],
  },
  {
    id: 'accountNumberFirst6',
    column: 'accountNumberFirst6',
    label: 'First 6 of Card Number',
    type: 'text',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'accountNumberLast4',
    column: 'accountNumberLast4',
    label: 'Last 4 of Card Number',
    type: 'text',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'mid',
    column: 'mid',
    label: 'Merchant MID',
    type: 'text',
    hidden: true,
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  accountStatusFilter,
];

const columnsConfig: Column<FraudUnmatchedRefundsRecord>[] = [
  {
    id: 'mid',
    label: 'MID',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'dba',
    label: 'DBA',
    numeric: false,
    sortable: false,
    export: true,
    selector: row => {
      const value = row.dba;
      if (row.mid) {
        const route = generatePath(MERCHANT_PORTFOLIO_DETAILS, {id: row.mid});
        return <Link to={route}>{value}</Link>;
      }
      return value;
    },
  },
  {
    id: 'legalName',
    label: 'Legal Name',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'agent',
    label: 'Agent',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'transactionDate',
    numeric: true,
    sortable: true,
    export: true,
    label: 'Refund Date',
    selector: row => {
      const date = new Date(row.transactionDate);
      const localTime = utcToZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
      return format(localTime, 'P');
    },
  },
  {
    id: 'amount',
    label: 'Amount',
    numeric: true,
    sortable: true,
    export: true,
    selector: row => currentFormatter.format(Number(row.amount)),
  },
  {
    id: 'transactionId',
    label: 'Transaction ID',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'Card Number',
    numeric: false,
    sortable: false,
    export: true,
    label: 'Card Number',
    selector: row => `${row.accountNumberFirst6}******${row.accountNumberLast4}`,
  },
];

const getMidFilter = (mid: string): Filter => {
  if (!mid || mid === AllMIDs) return {};
  const filterOption = filtersConfig.find(fo => fo.id === 'mid');
  if (!filterOption) throw new Error('filter option not found');
  return {
    mid: {
      option: filterOption,
      operator: filterOption.operators[0],
      value: mid,
    },
  };
};
