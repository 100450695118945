/*

Here is the pipeline of how the deals will flow with the various dispositions. There will be a total of 4 different roles.

  - Sales (can see the incomplete in “Lead Received”, send the URLs, disposition, and get the deal to be signed which would flow into AS Received)
  - Agent Support (can see all applications received in “App Received” & “App Incomplete”,
    can edit the application, disposition to “App Submitted”
  - Underwriter (can see all application in “App Submitted”, “App Pending”, “App Approved”, and “App Declined”,
    can edit the application, disposition within their own ques, and submit to TSYS or First Data
  - Admin (can do all functions, run reports, assign incomplete, etc)

*/

import {Dispositions} from './Dispositions';
import {UserRoles} from './UserRoles';

export type Workflow = {
  viewable: Dispositions[];
  assignable: Dispositions[];
};

export const Workflows: {[_ in UserRoles]: Workflow} = {
  [UserRoles.support]: {
    viewable: [],
    assignable: [],
  },
  [UserRoles.operations]: {
    viewable: [Dispositions.incomplete, Dispositions.signatureNeeded],
    assignable: [Dispositions.incomplete, Dispositions.signatureNeeded, Dispositions.asReceived],
  },
  [UserRoles.agentSupport]: {
    viewable: [
      Dispositions.signatureNeeded,
      Dispositions.asReceived,
      Dispositions.asIncomplete,
      Dispositions.asDeclined,
      Dispositions.asWithdrawn,
      Dispositions.asRequestedSupportingDocuments,
      Dispositions.asSupportingDocumentsReceived,
    ],
    assignable: [
      Dispositions.incomplete,
      Dispositions.asReceived,
      Dispositions.asDeclined,
      Dispositions.asWithdrawn,
      Dispositions.asIncomplete,
      Dispositions.uwReceived,
      Dispositions.asRequestedSupportingDocuments,
      Dispositions.asSupportingDocumentsReceived,
    ],
  },
  [UserRoles.underwriter]: {
    viewable: [
      Dispositions.uwReceived,
      Dispositions.uwPending,
      Dispositions.uwApproved,
      Dispositions.uwDeclined,
      Dispositions.uwSubmittedToOtherBank,
      Dispositions.enqueued,
      Dispositions.uwWithdrawn,
      Dispositions.uwArchived,
      Dispositions.failed,
    ],
    assignable: [
      Dispositions.asReceived,
      Dispositions.uwReceived,
      Dispositions.uwPending,
      Dispositions.uwApproved,
      Dispositions.uwDeclined,
      Dispositions.uwWithdrawn,
      Dispositions.uwSubmittedToOtherBank,
    ],
  },
  [UserRoles.admin]: {
    viewable: Object.values(Dispositions),
    assignable: [
      Dispositions.incomplete,
      Dispositions.signatureNeeded,
      Dispositions.asReceived,
      Dispositions.asDeclined,
      Dispositions.asWithdrawn,
      Dispositions.asIncomplete,
      Dispositions.asRequestedSupportingDocuments,
      Dispositions.asSupportingDocumentsReceived,
      Dispositions.uwReceived,
      Dispositions.uwPending,
      Dispositions.uwApproved,
      Dispositions.uwDeclined,
      Dispositions.uwWithdrawn,
      Dispositions.uwArchived,
      Dispositions.uwSubmittedToOtherBank,
    ],
  },
  [UserRoles.agent]: {
    viewable: [],
    assignable: [],
  },
  [UserRoles.merchant]: {
    viewable: [],
    assignable: [],
  },
  [UserRoles.auditor]: {
    viewable: [],
    assignable: [],
  },
  [UserRoles.sales]: {
    viewable: [Dispositions.started, Dispositions.incomplete, Dispositions.signatureNeeded],
    assignable: [],
  },
};

export const getWorkflow = (role: UserRoles) => {
  return Workflows[role];
};

export const NextRoles: {[_ in Dispositions]: UserRoles | null} = {
  [Dispositions.started]: null,
  [Dispositions.incomplete]: UserRoles.operations,
  [Dispositions.signatureNeeded]: null,
  [Dispositions.asReceived]: UserRoles.agentSupport,
  [Dispositions.asIncomplete]: null,
  [Dispositions.asRequestedSupportingDocuments]: null,
  [Dispositions.asSupportingDocumentsReceived]: null,
  [Dispositions.asDeclined]: null,
  [Dispositions.asWithdrawn]: null,
  [Dispositions.uwReceived]: UserRoles.underwriter,
  [Dispositions.uwPending]: null,
  [Dispositions.uwApproved]: null,
  [Dispositions.uwDeclined]: null,
  [Dispositions.uwWithdrawn]: null,
  [Dispositions.uwArchived]: null,
  [Dispositions.uwSubmittedToOtherBank]: null,
  [Dispositions.enqueued]: null,
  [Dispositions.failed]: null,
  [Dispositions.boarded]: null,
};

export const getNextRole = (currentRole: UserRoles, nextDisposition: Dispositions) => {
  const nextRole = NextRoles[nextDisposition];
  if (nextRole !== currentRole) {
    return nextRole;
  }
  return null;
};

export const isLocked = (currentRole: UserRoles | undefined, currentDisposition: Dispositions) => {
  return (
    Boolean(currentRole) &&
    [Dispositions.enqueued, Dispositions.boarded].includes(currentDisposition)
  );
};
