import EditIcon from '@mui/icons-material/Edit';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

interface SummaryProps {
  title: string;
  handleEditClick: () => void;
  children: JSX.Element;
}

export const ResponseWizardSummary = ({title, handleEditClick, children}: SummaryProps) => {
  return (
    <Card key={title} variant="outlined" sx={{marginTop: 2}}>
      <CardHeader
        title={
          <Stack direction="row">
            <Typography variant="h5" sx={{paddingLeft: '10px', paddingTop: '6px'}}>
              {title}
            </Typography>
          </Stack>
        }
        action={
          <IconButton onClick={handleEditClick}>
            <Tooltip title="Edit">
              <EditIcon />
            </Tooltip>
          </IconButton>
        }
      />
      <CardContent>
        <Stack spacing={2} divider={<Divider flexItem />}>
          {children}
        </Stack>
      </CardContent>
    </Card>
  );
};
