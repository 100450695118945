import {
  Collections,
  DocumentTypes,
  Firebase,
  UniversalSnapshot,
  ViewSelector,
  ViewTypes,
} from '@ozark/common';
import {ScheduleA, ScheduleAView} from '@ozark/functions/src/documents/ScheduleA';
import {defaultViewSelector} from '@ozark/functions/src/documents/ViewSelector';
import {useEffect, useState} from 'react';
import {ScheduleADefaultSettings} from '../components/ScheduleA';
import {AsyncState} from '../models/AsyncState';

export const useLatestVersionSnapshot = <
  TDocumentView extends ViewTypes,
  TDocument extends DocumentTypes
>(
  collection: Collections,
  viewSelector: ViewSelector<TDocumentView>,
  subcollection?: {parentId: string; collection: Collections}
) => {
  const [document, setDocument] = useState<AsyncState<TDocumentView>>({promised: true});

  useEffect(() => {
    let query = subcollection
      ? Firebase.firestore
          .collection(collection)
          .doc(subcollection.parentId)
          .collection(subcollection.collection)
          .orderBy('version', 'desc')
          .limit(1)
      : Firebase.firestore.collection(collection).orderBy('version', 'desc').limit(1);

    const unsubscribe = query.onSnapshot(
      snapshot => {
        if (snapshot.size !== 1) {
          setDocument({promised: false});
          return;
        }
        const data = viewSelector(snapshot.docs[0] as UniversalSnapshot<TDocument>);
        if (!data) return;
        setDocument({promised: false, data});
      },
      err => {
        console.error(err);
        setDocument({promised: false, error: err});
      }
    );
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  const addNewVersion = async (partialDocument: Partial<TDocument>) => {
    if (!document?.data) {
      console.warn('empty document');
    }

    try {
      let newDocument = {...document.data, ...partialDocument} as any;
      delete newDocument.id;
      delete newDocument.ref;
      newDocument.version = (newDocument?.version || 0) + 1;

      let query = subcollection
        ? Firebase.firestore
            .collection(collection)
            .doc(subcollection.parentId)
            .collection(subcollection.collection)
        : Firebase.firestore.collection(collection);

      await query.add(newDocument);
    } catch (err: any) {
      console.error('failed to add new version of document', document, partialDocument, err);
      throw Error(err);
    }
  };

  return {document, addNewVersion};
};

export const useAgentScheduleA = (uid: string) => {
  if (uid.length > 0) {
    return useLatestVersionSnapshot<ScheduleAView, ScheduleA>(
      Collections.agents,
      defaultViewSelector,
      {
        collection: Collections.scheduleA,
        parentId: uid,
      }
    );
  }

  return {document: {promised: true, data: undefined}, addNewVersion: () => {}};
};

export const useGroupScheduleA = (groupId: string) => {
  return useLatestVersionSnapshot<ScheduleAView, ScheduleA>(
    Collections.groups,
    defaultViewSelector,
    {
      collection: Collections.scheduleA,
      parentId: groupId,
    }
  );
};

export const useDefaultScheduleA = (defaultSettings?: ScheduleADefaultSettings) => {
  const nullResult = {document: {promised: true, data: null}, addNewVersion: () => {}};

  if (!defaultSettings || !defaultSettings.id) {
    return nullResult;
  }
  switch (defaultSettings.type) {
    case 'group':
      return useLatestVersionSnapshot<ScheduleAView, ScheduleA>(
        Collections.groups,
        defaultViewSelector,
        {
          collection: Collections.scheduleA,
          parentId: defaultSettings.id,
        }
      );
    case 'agent':
      return useLatestVersionSnapshot<ScheduleAView, ScheduleA>(
        Collections.agents,
        defaultViewSelector,
        {
          collection: Collections.scheduleA,
          parentId: defaultSettings.id,
        }
      );
    default:
      return nullResult;
  }
};
