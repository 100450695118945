import {Ticket, TicketStatus} from '@ozark/common';
import {useEffect, useState} from 'react';
import {useUserInfo} from '../../../hooks';
import {FilterKey} from '../types';
import {useTicketsAllAgentAdmin} from './useTicketsAllAgentAdmin';
import {useTicketsAllAgentMember} from './useTicketsAllAgentMember';
import {useTicketsAllMerchant} from './useTicketsAllMerchant';

const LIMIT = 20;

export function useTicketsAllNonErp(statusSet: TicketStatus[], filterKey?: FilterKey) {
  const {isMerchant, isAgentAdmin} = useUserInfo();
  const {tickets} = isMerchant
    ? useTicketsAllMerchant(statusSet, filterKey)
    : isAgentAdmin
    ? useTicketsAllAgentAdmin(statusSet, filterKey)
    : useTicketsAllAgentMember(statusSet, filterKey);
  const [data, setData] = useState<
    {items: Ticket[]; hasNextPage: boolean; totalCount?: number} | undefined
  >(undefined);
  const [page, setPage] = useState<number>(1);
  const loading = Boolean(tickets?.promised);
  const hasData = Boolean(tickets?.data?.length);

  useEffect(() => {
    const page = 1;
    setData({
      items: tickets.data ? tickets?.data?.slice(0, LIMIT * page) : [],
      hasNextPage: (tickets.data?.length ?? 0) > LIMIT * page,
      totalCount: tickets.data?.length,
    });
    setPage(page);
  }, [tickets.data]);

  useEffect(() => {
    if (page > 1) {
      setData({
        items: tickets.data ? tickets?.data?.slice(0, LIMIT * page) : [],
        hasNextPage: (tickets.data?.length ?? 0) > LIMIT * page,
        totalCount: tickets.data?.length,
      });
    }
  }, [page]);

  const loadNextPage = (onLoaded?: () => void) => {
    setPage((page: number) => page + 1);
    onLoaded?.();
  };

  return {
    data,
    loadNextPage,
    loading,
    hasData,
  };
}
