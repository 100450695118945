export enum SecretNames {
  applicationFieldKey = 'application_field_key',
  firstDataBoardingKey = 'first_data_boarding_key',
  firstDataReportingKey = 'first_data_reporting_key',
  tsysCert = 'tsys_crt',
  tsysKey = 'tsys_key',
  tsysPassphrase = 'tsys_passphrase',
  tsysPpmChesapeakeCert = 'tsys_ppm_chesapeake_crt',
  tsysPpmChesapeakeKey = 'tsys_ppm_chesapeake_key',

  tsysPpmBancorpCert = 'tsys_ppm_bancorp_crt',
  tsysPpmBancorpKey = 'tsys_ppm_bancorp_key',
  tsysChesapeakeCert = 'tsys_chesapeake_crt',
  tsysChesapeakeKey = 'tsys_chesapeake_key',
  ftpPassword = 'ftp_password',
  mastercard_match_cert = 'mastercard_match_cert',
  appApiKeys = 'app_api_keys',

  // latest correct certs for Luqra 5846
  tsysPpmEcommerceCert = 'tsys_ppm_ecommerce_crt',
  tsysPpmEcommerceKey = 'tsys_ppm_ecommerce_key',
  tsysEpxressEcommerceCert = 'tsys_express_ecommerce_crt',
  tsysExpressEcommerceKey = 'tsys_express_ecommerce_key',
}
