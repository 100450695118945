import {GetSiteJumpTokenPayload, useCallable, useNotification} from '@ozark/common';
import {useState} from 'react';

export const useSiteJumpAsUser = () => {
  const {getSiteJumpToken} = useCallable();
  const showNotification = useNotification();
  const [loading, setLoading] = useState(false);

  const siteJumpAsUser = async (payload: GetSiteJumpTokenPayload) => {
    setLoading(true);
    const result = await getSiteJumpToken(payload);
    if (result.status !== 'ok') {
      console.error(
        `Failed to get site jump token for userId ${payload.uid} and email ${payload.email} with an error`,
        result
      );
      showNotification('error', 'Failed to get site jump token');
      setLoading(false);
      return;
    }

    const a = document.createElement('a');
    a.setAttribute('href', `${result.portalUrl}`);
    a.setAttribute('target', '_blank');
    a.click();

    setLoading(false);
  };

  return {
    siteJumpAsUser,
    loading,
  };
};
