import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {CardAuthorizationSummary} from '../..';
import {CardImage, CardImagesDictionaryType, TransactionsCardTextByType} from '../CardImage';
import {DebitCardImage} from '../CardImage/index';

const useStyles = makeStyles({
  table: {},
  cardCell: {
    minWidth: 240,
    display: 'flex',
  },
  cardImage: {
    width: 52,
    marginRight: 16,
  },
  cardText: {
    margin: 'auto 0',
    width: 135,
  },
});

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

type Props = {summaryByCardType: CardAuthorizationSummary[]};

type TableDisplayLine = Omit<CardAuthorizationSummary, 'networkId'> & {
  cardImage: JSX.Element;
  cardName: string;
};

export const AuthorizationSummaryByCardTypeTable = ({summaryByCardType}: Props) => {
  const classes = useStyles();

  const totalApprovalCount = summaryByCardType.reduce((a, v) => a + Number(v.approvalCount), 0);
  const totalApprovalAmount = summaryByCardType.reduce((a, v) => a + Number(v.approvalAmount), 0);
  const totalDeclineCount = summaryByCardType.reduce((a, v) => a + Number(v.declineCount), 0);
  const totalDeclineAmount = summaryByCardType.reduce((a, v) => a + Number(v.declineAmount), 0);

  //The transaction is identified as credit if networkId is null or 0002
  const creditTransactions = summaryByCardType
    .filter(row => !row.networkId || row.networkId === '0002')
    .map(t => ({
      ...t,
      cardName: TransactionsCardTextByType[t.cardType]
        ? TransactionsCardTextByType[t.cardType]
        : 'Other',
      cardImage: (
        <CardImage cardType={t.cardType} dictionaryType={CardImagesDictionaryType.Authorizations} />
      ),
    }));

  // EBT is debit but excluded because it will be displayed as a separate line item.
  const debitTransactions = summaryByCardType.filter(
    row => row.networkId && row.networkId !== '0002' && row.networkId !== '0029'
  );

  const debitTransactionsRollup: TableDisplayLine[] = [];

  if (debitTransactions.length > 0) {
    const rollup = debitTransactions.reduce<TableDisplayLine>(
      (accumulator, t) => {
        accumulator.approvalAmount = (
          Number(accumulator.approvalAmount) + Number(t.approvalAmount)
        ).toString();
        accumulator.approvalCount = (
          Number(accumulator.approvalCount) + Number(t.approvalCount)
        ).toString();
        accumulator.declineAmount = (
          Number(accumulator.declineAmount) + Number(t.declineAmount)
        ).toString();
        accumulator.declineCount = (
          Number(accumulator.declineCount) + Number(t.declineCount)
        ).toString();
        return accumulator;
      },
      {
        approvalCount: '0',
        approvalAmount: '0',
        declineCount: '0',
        declineAmount: '0',
        cardName: 'Debit',
        cardType: 'debit',
        cardImage: <DebitCardImage />,
      }
    );
    debitTransactionsRollup.push(rollup);
  }

  const ebtTransactions = summaryByCardType
    .filter(row => row.networkId === '0029')
    .map(t => ({
      ...t,
      cardName: TransactionsCardTextByType[t.cardType],
      cardImage: (
        <CardImage cardType={t.cardType} dictionaryType={CardImagesDictionaryType.Authorizations} />
      ),
    }));

  const displayLines = [
    ...creditTransactions,
    ...debitTransactionsRollup,
    ...ebtTransactions,
    // Per Darnell and Lauren, we don't need to display lines without approvals.
  ].filter(line => Number(line.approvalCount) > 0);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">Total Authorizations</TableCell>
            <TableCell align="right">Total Amount Authorized</TableCell>
            <TableCell align="right">Authorization Count Ratio</TableCell>
            <TableCell align="right">Authorization Amount Ratio</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {displayLines
            .sort((a, b) => Number(b.approvalCount) - Number(a.approvalCount))
            .map(row => (
              <TableRow key={row.cardType}>
                <TableCell component="th" scope="row" className={classes.cardCell}>
                  <div className={classes.cardImage}>{row.cardImage}</div>
                  <Typography className={classes.cardText}>{row.cardName}</Typography>
                </TableCell>
                <TableCell align="right">{row.approvalCount}</TableCell>
                <TableCell align="right">{formatter.format(Number(row.approvalAmount))}</TableCell>
                <TableCell align="right">
                  {Number(row.approvalCount) > 0
                    ? (
                        Number(row.approvalCount) /
                        (Number(row.approvalCount) + Number(row.declineCount))
                      ).toLocaleString(undefined, {style: 'percent', minimumFractionDigits: 2})
                    : 0}
                </TableCell>
                <TableCell align="right">
                  {Number(row.approvalCount) > 0
                    ? (
                        Number(row.approvalAmount) /
                        (Number(row.approvalAmount) + Number(row.declineAmount))
                      ).toLocaleString(undefined, {style: 'percent', minimumFractionDigits: 2})
                    : 0}
                </TableCell>
              </TableRow>
            ))}
          <TableRow key="total">
            <TableCell component="th" scope="row">
              <Typography variant="h6">Total</Typography>
            </TableCell>
            <TableCell align="right">{totalApprovalCount}</TableCell>
            <TableCell align="right">{formatter.format(totalApprovalAmount)}</TableCell>
            <TableCell align="right">
              {totalApprovalCount > 0
                ? (totalApprovalCount / (totalApprovalCount + totalDeclineCount)).toLocaleString(
                    undefined,
                    {style: 'percent', minimumFractionDigits: 2}
                  )
                : 0}
            </TableCell>
            <TableCell align="right">
              {totalApprovalCount > 0
                ? (totalApprovalAmount / (totalApprovalAmount + totalDeclineAmount)).toLocaleString(
                    undefined,
                    {style: 'percent', minimumFractionDigits: 2}
                  )
                : 0}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
