import {ERPUserRoles, TicketLastCommentInfo} from '../../..';
import {useUserInfo} from '../../../hooks/useUserInfo';

export const useLastCommentAuthor = (ticketLastCommentInfo: TicketLastCommentInfo | undefined) => {
  const {isErpUser, uid} = useUserInfo();
  const isCommentAuthor = uid === ticketLastCommentInfo?.authorProfileId;
  const isCommentAuthorErpUser =
    ticketLastCommentInfo?.authorRole && ERPUserRoles.includes(ticketLastCommentInfo.authorRole);

  return (
    (ticketLastCommentInfo
      ? isCommentAuthor
        ? ticketLastCommentInfo.author
        : isCommentAuthorErpUser
        ? isErpUser
          ? ticketLastCommentInfo.author
          : `${ticketLastCommentInfo.authorDepartment ?? 'ERP'} department`
        : ticketLastCommentInfo.author
      : '') ?? 'Not Available'
  );
};
