import {Box, Button} from '@mui/material';
import {
  ApplicationView,
  mapDispositionToMerchantApplicationStatus,
  MerchantApplicationStatus,
} from '@ozark/common';
import {Column} from '@ozark/common/api/Column';
import {formatDateTime, Table} from '@ozark/common/components';
import {MouseEvent} from 'react';

type Props = {
  applications: ApplicationView[];
  onRowClick?: (row: ApplicationView) => void;
  onResumeApplication: (row: ApplicationView) => void;
};

export const ApplicationsStatusesTable = ({
  applications,
  onRowClick,
  onResumeApplication,
}: Props): JSX.Element => {
  const onResumeApplicationClick = (
    event: MouseEvent<HTMLButtonElement>,
    application: ApplicationView
  ) => {
    event.stopPropagation();

    onResumeApplication(application);
  };

  return (
    <Table
      columns={columns}
      rows={applications}
      onRowClick={onRowClick}
      actions={application => {
        const status = mapDispositionToMerchantApplicationStatus[application.disposition];

        if (
          status !== MerchantApplicationStatus.incomplete &&
          status !== MerchantApplicationStatus.signatureNeeded
        ) {
          return null;
        }

        return (
          <Box sx={{textAlign: 'center'}}>
            <Button
              color="primary"
              variant="contained"
              onClick={e => onResumeApplicationClick(e, application)}
            >
              Resume Application
            </Button>
          </Box>
        );
      }}
    />
  );
};

const columns: Column<ApplicationView>[] = [
  {
    id: 'distinguishableId',
    numeric: false,
    sortable: false,
    export: false,
    label: 'Application ID',
  },
  {
    id: 'doingBusinessAs',
    numeric: false,
    sortable: false,
    export: false,
    label: 'Business Name',
  },
  {
    id: 'createdAt',
    numeric: false,
    sortable: false,
    export: false,
    label: 'Date Started',
    selector: row => formatDateTime(row.createdAt.toDate().toString()),
  },
  {
    id: 'status',
    numeric: false,
    sortable: false,
    export: false,
    label: 'Status',
    selector: row => mapDispositionToMerchantApplicationStatus[row.disposition],
  },
];
