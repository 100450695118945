import {Box, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {useTicketEditContainer} from '../hooks/useTicketEditContainer';
import {PossibleDupes} from './PossibleDupes';
import {TicketEditHistory} from './TicketEditHistory';
import {TicketsHistoryTabs} from './TicketsHistoryTabs';
import {usePossibleDupes} from './usePossibleDupes';

type Props = {
  isDraft: boolean;
};

export const TicketsHistory = ({isDraft}: Props) => {
  const {isTicketsDupesTab, setIsTicketsDupesTab} = useTicketEditContainer();
  // we only need to show additional (dupes) tab when there's dupes data to show
  const [shouldShowHistoryTabs, setShouldShowHistoryTabs] = useState(false);
  const {possibleDupes} = usePossibleDupes(isDraft);

  useEffect(() => {
    const hasPossibleDupes = possibleDupes.length > 0;
    setIsTicketsDupesTab(hasPossibleDupes);
    setShouldShowHistoryTabs(hasPossibleDupes);
  }, [possibleDupes.length, setShouldShowHistoryTabs]);

  if (shouldShowHistoryTabs) {
    return (
      <div>
        <TicketsHistoryTabs />
        {isTicketsDupesTab && <PossibleDupes possibleDupes={possibleDupes} />}
        {!isTicketsDupesTab && <TicketEditHistory />}
      </div>
    );
  }

  return (
    <div>
      <HistoryHeaderSimple />
      <TicketEditHistory />
    </div>
  );
};

const HistoryHeaderSimple = () => (
  <Box display="flex" justifyContent="space-between" alignItems="center">
    <Typography variant="body1" sx={{color: 'lightslategrey'}}>
      History
    </Typography>
  </Box>
);
