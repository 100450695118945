import {ApplicationStatusGroups, Dispositions, getDispositions} from '../..';

export const getDispositionFilterValues = (
  disposition: Dispositions | null,
  group: ApplicationStatusGroups,
  viewableDispositions?: Dispositions[] | null
) => {
  if (disposition) {
    return [disposition];
  }

  if (group !== ApplicationStatusGroups.all) {
    return getDispositions(group, viewableDispositions || Object.values(Dispositions));
  }

  return null;
};
