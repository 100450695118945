import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import {Typography} from '@mui/material';
import {BoxCentered} from '../../common';

export const DataIsMissing = () => {
  return (
    <BoxCentered height="100%" pb={3}>
      <ReportGmailerrorredIcon fontSize="large" color="error" />
      &nbsp;<Typography>No&nbsp;Data</Typography>
    </BoxCentered>
  );
};

export const NoTransactions = ({label}: {label: string}) => {
  return (
    <BoxCentered height="100%" pb={3}>
      &nbsp;<Typography>{label}</Typography>
    </BoxCentered>
  );
};
