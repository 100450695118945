import {AgentView, GroupView, ScheduleA} from '../../documents';

export const getAgentsWithScheduleA = (
  agents: AgentView[] | undefined,
  groups: GroupView[] | undefined
) => {
  const result: AgentView[] = [];
  if (!agents || !groups) {
    return result;
  }
  const emptyMasterId = '';

  // If agent's scheduleA is empty then one inherits schedule A from parent (master agent or group)
  // Calculate latest schedule A based on inheritance
  for (const group of groups) {
    const parents: {
      id: string;
      groupId: string;
      latestScheduleAVersion: ScheduleA | undefined;
    }[] = [
      {
        id: emptyMasterId,
        latestScheduleAVersion: group.latestScheduleAVersion,
        groupId: group.id,
      },
    ];

    //use loop instead of recursion function
    while (parents.length > 0) {
      const parent = parents.pop();
      const currentAgents: AgentView[] = [];
      if (!parent) {
        continue;
      }
      if (parent.id === emptyMasterId) {
        currentAgents.push(...agents.filter(x => !x.masterUid && x.group.id === parent.groupId));
      } else {
        currentAgents.push(
          ...agents.filter(x => x.masterUid === parent.id && x.group.id === parent.groupId)
        );
      }
      for (const currentAgent of currentAgents) {
        if (!currentAgent.latestScheduleAVersion) {
          currentAgent.latestScheduleAVersion = parent.latestScheduleAVersion;
        }
        if (
          currentAgent.latestScheduleAVersion &&
          !currentAgent.latestScheduleAVersion.splitMethod
        ) {
          currentAgent.latestScheduleAVersion.splitMethod =
            parent.latestScheduleAVersion?.splitMethod;
        }
        result.push(currentAgent);
        parents.push({
          id: currentAgent.id,
          latestScheduleAVersion: currentAgent.latestScheduleAVersion,
          groupId: group.id,
        });
      }
    }
  }
  return result;
};
