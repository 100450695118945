import {orderBy} from 'lodash';
import {useEffect, useState} from 'react';
import {AsyncState, Ticket, TicketStatus} from '../../..';
import {stateEmpty, statePromised} from '../constants/constants';
import {FilterKey} from '../types';
import {whenAllTickets} from '../utils';
import {filterTicketsByKey} from '../utils/filters';
import {useTicketsMyAssignee} from './ticketsSlices/useTicketsMyAssignee';
import {useTicketsMyAssociation} from './ticketsSlices/useTicketsMyAssociation';
import {useTicketsMyCreator} from './ticketsSlices/useTicketsMyCreator';
import {useTicketsQuery} from './useTicketsQuery';

export function useTicketsAllMerchant(statusSet: TicketStatus[], filterKey?: FilterKey) {
  const {sortBy, sortDir} = useTicketsQuery();
  const [tickets, setTickets] = useState(statePromised);
  const promises: AsyncState<Ticket[]>[] = [];

  // I am a Merchant and MyMID = x
  // Merchant Tickets My View
  // - I am a creator or an assignee
  // Merchant Tickets All View
  // - Tickets My and
  // - Association is Merchant and Merchant.mid === MyMIDs or
  // - Association is Application and Application.mid === MyMIDs (create migration)
  const {ticketsMyCreator} = useTicketsMyCreator(statusSet);
  const {ticketsMyAssignee} = useTicketsMyAssignee(statusSet);
  const {ticketsMyAssociationMerchant, ticketsMyAssociationApplication} =
    useTicketsMyAssociation(statusSet);
  promises.push(
    ticketsMyCreator,
    ticketsMyAssignee,
    ticketsMyAssociationMerchant,
    ticketsMyAssociationApplication
  );

  useEffect(() => {
    !tickets.promised && setTickets(statePromised);
    whenAllTickets(promises, mergedTickets => {
      if (!mergedTickets.length) {
        setTickets(stateEmpty);
        return;
      }
      const ticketsFiltered = filterTicketsByKey(mergedTickets, filterKey);
      const ticketsSorted = orderBy(ticketsFiltered, [sortBy], [sortDir]);
      setTickets({promised: false, data: ticketsSorted});
    });
  }, [
    ticketsMyCreator,
    ticketsMyAssignee,
    ticketsMyAssociationMerchant,
    ticketsMyAssociationApplication,
    filterKey,
    sortBy,
    sortDir,
  ]);

  return {
    tickets,
    counter: tickets.data?.length ?? 0,
  };
}
