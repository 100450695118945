import {Box, Tooltip} from '@mui/material';
import {ApplicationView, GroupView} from '@ozark/common';
import {ApplicationResponse} from '@ozark/functions/src/functions/express/private/types';
import {CardDataEntry} from '../CardDataEntry';

type GroupInfoProps = {
  application: ApplicationView | ApplicationResponse;
  group?: GroupView;
};

export const GroupInfo = ({application, group}: GroupInfoProps) => {
  return (
    <CardDataEntry caption="Group">
      {group?.logoThumbnailUrl ? (
        <Tooltip title={application.group.name}>
          <Box sx={{height: '20px', width: '100px', mb: 0.25}}>
            <Box
              sx={{
                maxHeight: '100%',
                maxWidth: '100%',
              }}
              component="img"
              src={group?.logoThumbnailUrl}
              alt={application.group.name}
              title={application.group.name}
            />
          </Box>
        </Tooltip>
      ) : (
        application.group.name
      )}
    </CardDataEntry>
  );
};
