import {AgentView} from '@ozark/common';
import {AgentMidAssociation} from '@ozark/common/components';
import {useEffect, useState} from 'react';
import {useStore} from '../../store/helpers';

const AgentMidAssociationPage = () => {
  const {getAuthorizedAgents, authProfile} = useStore();
  const [authorizedAgents, setAuthorizedAgents] = useState<AgentView[]>();

  useEffect(() => {
    getAuthorizedAgents().then(agents => {
      setAuthorizedAgents(agents?.filter(agent => agent.isActive));
    });
  }, [getAuthorizedAgents]);

  return (
    <>
      {!authProfile.promised && (
        <AgentMidAssociation agents={authorizedAgents} portalUser={authProfile.data as AgentView} />
      )}
    </>
  );
};

export default AgentMidAssociationPage;
