import AttachFileIcon from '@mui/icons-material/AttachFile';
import {Button} from '@mui/material';
import {TmpAttachment} from '@ozark/functions/src/functions/express/private/types/Attachments';
import {useCallback, useState} from 'react';
import {useNotification} from '../../../hooks';
import {InsertAttachmentsDialog} from '../../Attachments';
import {useComment} from '../hooks/useComment';
import {useCommentAttachments} from '../hooks/useCommentAttachments';
import {useIsEditingClosed} from '../hooks/useIsEditingClosed';

export const CommentsAddAttachment = () => {
  const {isEditingClosed} = useIsEditingClosed();
  const {attachmentUpload} = useCommentAttachments();
  const {commentAdd} = useComment();
  const [attachDialogOpen, setAttachDialogOpen] = useState(false);
  const showNotification = useNotification();

  const handleCreateAttachments = useCallback(
    async (attachments: TmpAttachment[]) => {
      try {
        const commentId = await commentAdd('File attachment.');

        await attachmentUpload(commentId, attachments);
        setAttachDialogOpen(false);
      } catch (err: any) {
        console.error(`failed to upload file. ${err.toString()}`);
        showNotification('error', 'Failed to upload.');
      }
    },
    [commentAdd, attachmentUpload]
  );

  return (
    <>
      <Button
        disabled={isEditingClosed}
        startIcon={<AttachFileIcon />}
        component="span"
        aria-label="add attachment"
        sx={{p: '2px'}}
        color="primary"
        onClick={_ => {
          setAttachDialogOpen(true);
        }}
      >
        Attach...
      </Button>
      {attachDialogOpen && (
        <InsertAttachmentsDialog
          initialFolderName={null}
          folderNamesOptions={[]}
          onSubmit={handleCreateAttachments}
          onClose={() => setAttachDialogOpen(false)}
          hideFolder={true}
        />
      )}
    </>
  );
};
