import Box from '@mui/material/Box';
import {useUserInfo} from '../../../hooks';
import {TicketsListFilterAgent} from './TicketsListFilterAgent';
import {TicketsListFilterDepartment} from './TicketsListFilterAssigneeDepartment';
import {TicketsListFilterAssigneeGroup} from './TicketsListFilterAssigneeGroup';
import {TicketsListFilterAssigneeType} from './TicketsListFilterAssigneeType';
import {TicketsListFilterType} from './TicketsListFilterType';

export const TicketsListViewAllFilters = () => {
  const {isErpUser, isAgent} = useUserInfo();
  return (
    <Box display="flex" px={1} justifyContent="flex-start" alignItems="center" flexWrap="wrap">
      {isErpUser && (
        <>
          <TicketsListFilterType />
          <TicketsListFilterAssigneeType />
          <TicketsListFilterDepartment />
          <TicketsListFilterAssigneeGroup />
        </>
      )}

      {isAgent && <TicketsListFilterAgent />}
    </Box>
  );
};
