import {ApiBase, SearchCriteria} from '@ozark/common';
import {
  BatchMonitoringReport,
  MidAuthDeclinesResult,
  SwipedVsKeyedStatistic,
} from '@ozark/functions/src/functions/express/private/types';
import {LargeChargebackResult} from '@ozark/functions/src/functions/express/private/types/Chargebacks';
import {ContextKey} from '@ozark/functions/src/functions/express/private/types/Context';
import {
  FraudMidBinReport,
  FraudMidBinTransactionReport,
  FraudSameBinReport,
  FraudUnmatchedRefundsRecord,
  VDMPChargebackReport,
} from '@ozark/functions/src/functions/express/private/types/Reports';
import {PaginatedResponse} from '@ozark/functions/src/shared';
import {ActiveFilter} from '../components';
import {toQuery} from '../util/FilterUtil';
import {
  getPaginatedResponseWithBuiltInIndex,
  getSearchCriteriaWithIndexDedicated,
} from '../util/SearchCriteriaUtil';

export class FraudAnalysisApi extends ApiBase {
  getFraudVDMPChargebackReport = async (
    searchCriteria: SearchCriteria,
    monthYear: string,
    dateRanges?: string[],
    mid?: string
  ) => {
    searchCriteria = getSearchCriteriaWithIndexDedicated(searchCriteria);
    const queryString = toQuery(searchCriteria);
    const report = await this.get<PaginatedResponse<VDMPChargebackReport>>(
      `fraud/vdmp-chargebacks?${queryString}`,
      {
        [ContextKey.Mid]: mid,
        [ContextKey.MonthYear]: monthYear,
        [ContextKey.DateRanges]: dateRanges,
      }
    );
    return getPaginatedResponseWithBuiltInIndex(report);
  };

  getFraudAuthorizationsDeclines = (searchCriteria: SearchCriteria, filters?: ActiveFilter[]) => {
    const queryString = toQuery(searchCriteria, filters);
    return this.get<MidAuthDeclinesResult>(`fraud/auth-declines?${queryString}`);
  };

  getSwipedVsKeyedStatisticsReport = (searchCriteria: SearchCriteria, filters?: ActiveFilter[]) => {
    const queryString = toQuery(searchCriteria, filters);
    return this.get<PaginatedResponse<SwipedVsKeyedStatistic>>(
      `fraud/swiped-vs-keyed?${queryString}`
    );
  };

  getFraudSameBinReport = (searchCriteria: SearchCriteria, filters?: ActiveFilter[]) => {
    const queryString = toQuery(searchCriteria, filters);
    return this.get<FraudSameBinReport>(`fraud/same-bin?${queryString}`);
  };

  getFraudSameBinReportDetails = (
    mid: string,
    pageConfig: SearchCriteria,
    filters: ActiveFilter[]
  ) => {
    const queryString = toQuery(pageConfig, filters);
    return this.get<FraudMidBinReport>(`fraud/same-bin/${mid}?${queryString}`);
  };

  getFraudSameBinTransactionsReport = (
    mid: string,
    pageConfig: SearchCriteria,
    filters: ActiveFilter[]
  ) => {
    const queryString = toQuery(pageConfig, filters);
    return this.get<FraudMidBinTransactionReport>(
      `fraud/same-bin/transactions/${mid}?${queryString}`
    );
  };

  getFraudLargeChargebacks = (searchCriteria: SearchCriteria, filters?: ActiveFilter[]) => {
    const queryString = toQuery(searchCriteria, filters);
    return this.get<LargeChargebackResult>(`fraud/large-chargebacks?${queryString}`);
  };

  getFraudBatchMonitoringReport = (filters?: ActiveFilter[], mid?: string) => {
    let queryString = toQuery(null, filters);

    if (mid) {
      queryString += `&mid=${mid}`;
    }
    return this.get<BatchMonitoringReport>(`fraud/batch-monitoring?${queryString}`);
  };

  getFraudUnmatchedRefunds = (searchCriteria: SearchCriteria, filters?: ActiveFilter[]) => {
    const queryString = toQuery(searchCriteria, filters);
    return this.get<PaginatedResponse<FraudUnmatchedRefundsRecord>>(
      `fraud/unmatched-refunds?${queryString}`
    );
  };
}
