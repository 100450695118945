import {useUserInfo} from '../../../hooks';
import {useScheduledReporting} from '../../../hooks/useScheduledReporting';
import {Loading} from '../../Loading';
import {ScheduledReportingTable} from './ScheduledReportingTable';

export const ScheduledReportingPage = () => {
  const {uid} = useUserInfo();
  const {scheduledJobs} = useScheduledReporting(uid);
  if (scheduledJobs.promised || !scheduledJobs.data) return <Loading />;
  return (
    <>
      <ScheduledReportingTable jobs={scheduledJobs.data} />
    </>
  );
};
