import {Box} from '@mui/material';
import {Controller} from 'react-hook-form';
import {TextFieldRequired} from '../../common/TextFieldRequired';
import {FIELD_NAME_CATEGORY} from '../../constants/constants';
import {CategoryEditor} from './CategoryEditor';
import {CategoryInfo} from './CategoryInfo';

export const Category = () => {
  return (
    <Controller
      name={FIELD_NAME_CATEGORY}
      rules={{required: true}}
      render={({fieldState: {error}}) => (
        <Box display="flex" alignItems="flex-start">
          {error && <TextFieldRequired />}
          {!error && (
            <Box flex={1}>
              <CategoryInfo />
            </Box>
          )}
          <CategoryEditor />
        </Box>
      )}
    />
  );
};
