import {Box, CircularProgress, Grid, Paper, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  AllMIDs,
  MonthlySalesSummary,
  useApiContainer,
  useMidsContainer,
  VolumeResult,
} from '@ozark/common';
import {MidSelect, Title, VolumeBarGraph} from '@ozark/common/components';
import {VolumeSummaryByCardTypePieChart} from '@ozark/common/components/Analytics/VolumeSummary/VolumeSummaryByCardTypePieChart';
import {
  FilterPeriodSelect,
  getFilterPeriod,
  VolumeFilter,
} from '@ozark/common/components/FilterPeriodSelect';
import {VolumeFilterTypes} from '@ozark/functions/src/functions/express/private/types/Reports';
import {useEffect, useState} from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
  },
  menuPaper: {
    minWidth: 200,
  },
  midListItem: {
    padding: theme.spacing(0, 2),
  },
  noContent: {
    top: '40%',
    position: 'relative',
    textAlign: 'center',
  },
  grow: {
    flex: 1,
  },
  midButton: {
    textTransform: 'none !important' as any,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const TransactionsSummary = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [yearlySalesSummary, setYearlySalesSummary] = useState<MonthlySalesSummary[] | null>();
  const [volumeReport, setVolumeReport] = useState<VolumeResult>();
  const [selectedFilter, setSelectedFilter] = useState<VolumeFilter>(
    getFilterPeriod(VolumeFilterTypes.MTD)
  );

  const api = useApiContainer();
  const {mids, handleSelectMid, selectedMid} = useMidsContainer();

  useEffect(() => {
    setLoading(true);

    (async () => {
      try {
        const yearlySalesSummaryResult = await api?.reports.getYearlySummary(
          selectedMid === AllMIDs ? undefined : selectedMid
        );

        setYearlySalesSummary(yearlySalesSummaryResult);

        const volumeReportResult = await api?.reports.getVolumeDetail(
          selectedFilter,
          selectedMid === AllMIDs ? undefined : selectedMid
        );

        setVolumeReport(volumeReportResult);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    })();
  }, [selectedMid, selectedFilter, api]);

  const getBreadcrumbs = () => {
    return [<Typography variant="body1">Dashboard</Typography>];
  };

  return (
    <Paper className={classes.root}>
      <Title breadcrumbs={getBreadcrumbs()}>
        <div className={classes.grow} />
        <MidSelect mids={mids} handleSelectMid={handleSelectMid} selectedMid={selectedMid} />
      </Title>
      {loading || !yearlySalesSummary || !volumeReport ? (
        <div className={classes.loading}>
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <>
          <Grid container>
            <Grid item xs={12} justifyItems="right">
              <Box display="flex" justifyContent="flex-end">
                <FilterPeriodSelect onSelect={setSelectedFilter} selected={selectedFilter} />
              </Box>
            </Grid>
            {volumeReport.hasData ? (
              <>
                <Grid
                  sx={{
                    height: '420px',
                  }}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={9}
                >
                  <VolumeBarGraph
                    volumeData={volumeReport?.volumeSummary}
                    selectedFilter={selectedFilter}
                  />
                </Grid>
                <Grid
                  sx={{
                    height: '420px',
                  }}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={3}
                >
                  <VolumeSummaryByCardTypePieChart
                    summaryByCardType={volumeReport.summaryByCardType}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Typography>No transactions found</Typography>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Paper>
  );
};

export default TransactionsSummary;
