import {orderBy} from 'lodash';
import {useEffect, useState} from 'react';
import {TicketStatus} from '../../..';
import {stateEmpty, statePromised} from '../constants/constants';
import {FilterKey} from '../types';
import {whenAllTickets} from '../utils';
import {filterTicketsByKey} from '../utils/filters';
import {useTicketsAssociatedAgents} from './ticketsSlices/useTicketsAssociatedAgents';
import {useTicketsFiltersContainer} from './useTicketsFiltersContainer';
import {useTicketsQuery} from './useTicketsQuery';

export function useTicketsAllAgentMember(statusSet: TicketStatus[], filterKey?: FilterKey) {
  const {agentIdFilter} = useTicketsFiltersContainer();
  const {sortBy, sortDir} = useTicketsQuery();
  const [tickets, setTickets] = useState(statePromised);

  const {ticketsAssociatedAgents} = useTicketsAssociatedAgents(statusSet);

  useEffect(() => {
    !tickets.promised && setTickets(statePromised);

    whenAllTickets([ticketsAssociatedAgents], mergedTickets => {
      if (!mergedTickets.length) {
        setTickets(stateEmpty);
        return;
      }

      /**
       * This filter is related to order that we use here ticket_onChange_updateAssociatedAgents
       * We check author agent => assigned agent => associated agent => agent related to application => agent related to merchant
       */
      const ticketsPreFiltered = agentIdFilter
        ? mergedTickets.filter(t => t.associatedAgents?.[0] === agentIdFilter)
        : mergedTickets;

      const ticketsFiltered = filterTicketsByKey(ticketsPreFiltered, filterKey);
      const ticketsSorted = orderBy(ticketsFiltered, [sortBy], [sortDir]);
      setTickets({promised: false, data: ticketsSorted});
    });
  }, [agentIdFilter, sortBy, sortDir, ticketsAssociatedAgents]);

  return {
    tickets,
    counter: tickets.data?.length ?? 0,
  };
}
