import {Box} from '@mui/material';
import {styled} from '@mui/material/styles';

export const BoxAbs = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
