import {uniq} from 'lodash';
import {Collections, Firebase, Merchant, selectMerchantView, UniversalSnapshot} from '..';

export const getMerchantAsync = async (uid?: string): Promise<Merchant | undefined> => {
  try {
    if (!uid) throw new Error('invalid uid');
    const merchantSnap = await Firebase.firestore.collection(Collections.merchants).doc(uid).get();
    if (!merchantSnap.exists) throw new Error(`merchant not found: id: ${uid}`);
    const merchant = selectMerchantView(merchantSnap as UniversalSnapshot<Merchant>);
    return merchant;
  } catch (err) {
    console.error(err);
  }
};

export const getMerchantRegisteredMidsAsync = async (uid?: string): Promise<string[]> => {
  try {
    const merchant = await getMerchantAsync(uid);
    return merchant?.applicationMids ?? [];
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getAgentApplicationMidsAsync = async (agentUid?: string): Promise<string[]> => {
  try {
    const appsSnap = await Firebase.firestore
      .collection(Collections.applications)
      .where('agent.id', '==', agentUid)
      .where('mid', '!=', null)
      .get();
    return uniq(appsSnap.docs.map(doc => doc.get('mid')));
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getMerchantNameAsync = async (uid: string) => {
  const merchant = await getMerchantAsync(uid);

  if (!merchant) return;

  return `${merchant.firstName} ${merchant.lastName}`.trim();
};
