import {
  Application,
  ApplicationView,
  Collections,
  selectApplicationView,
  useCollectionSnapshot,
} from '@ozark/common';
import {useStore} from '../store/helpers';

export const useMerchantApplications = () => {
  const {authUser} = useStore();
  const {documents: merchantApplications} = useCollectionSnapshot<ApplicationView, Application>(
    Collections.applications,
    selectApplicationView,
    undefined,
    query => query.where('merchantUid', '==', authUser?.data?.uid)
  );

  return {
    merchantApplications,
  };
};
