import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import {Theme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    label: {
      whiteSpace: 'nowrap',
    },
  })
);

interface Props {
  value: number;
  label?: (value: number) => string;
  round?: boolean;
}

export const LinearProgressWithLabel = ({value, label, round = true, ...other}: Props) => {
  const classes = useStyles();
  const maybeRoundedValue = round ? Math.round(value) : value;
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={value} {...other} />
      </Box>
      <Box minWidth={45}>
        <Typography className={classes.label} variant="body2" color="textSecondary">
          {label?.(maybeRoundedValue) || `${maybeRoundedValue}%`}
        </Typography>
      </Box>
    </Box>
  );
};
