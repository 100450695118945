import {Switch} from 'react-router';
import {Route} from 'react-router-dom';

type RouteItem = {
  path: string | string[];
  isExact?: boolean;
};

type Props<T extends RouteItem> = {
  component: React.FC<{children: React.ReactNode}> | null;
  routes: T[];
  renderRoute: (route: T) => JSX.Element;
  renderLayoutRoute: (paths: string[], children: JSX.Element) => JSX.Element;
};

export const LayoutRouter = <T extends RouteItem>({
  component: LayoutComponent,
  routes,
  renderRoute,
  renderLayoutRoute,
}: Props<T>) => {
  const paths = routes.reduce((acc, route) => {
    if (Array.isArray(route.path)) {
      acc.push(...(route.path as string[]));
    } else {
      acc.push(route.path);
    }

    return acc;
  }, [] as string[]);

  const renderedRoutes = (
    <Switch>
      {routes.map(route => (
        <Route
          exact={route.isExact ?? true}
          key={route.path.toString()}
          path={route.path}
          render={() => renderRoute(route)}
        />
      ))}
    </Switch>
  );

  return (
    <Route
      exact
      path={paths}
      render={() =>
        renderLayoutRoute(
          paths,
          LayoutComponent ? <LayoutComponent children={renderedRoutes} /> : <>{renderedRoutes}</>
        )
      }
    />
  );
};
