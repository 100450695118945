import MoreVertIcon from '@mui/icons-material/MoreVert';
import {AppBar, Box, Button, Container, IconButton} from '@mui/material';
import Menu from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem';
import {LayoutComponent} from '@ozark/common';
import {PageFooter} from '@ozark/common/components/Page/PageFooter';
import {useUserInfo} from '@ozark/common/hooks/useUserInfo';
import React, {MouseEventHandler} from 'react';
import {useCallable} from '../../../hooks/useCallable';
import {useStore} from '../../../store/helpers';
import {SiteJumpAsUserAlert} from '../../SiteJumpAsUserAlert';

export const RestrictedAccessLayout: LayoutComponent = ({children}) => {
  const {group, logout, authProfile} = useStore();
  const {getPortalToken} = useCallable();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const {isSiteJump} = useUserInfo();

  const handleStartNewApplication = async () => {
    const result = await getPortalToken({});

    if (result.status === 'ok') {
      const a = document.createElement('a');
      a.setAttribute('href', `${process.env.REACT_APP_APP_URL}/portal?token=${result.token}`);
      a.setAttribute('target', '_blank');
      a.click();
    }
  };

  const handleLogoutClick = () => {
    logout();
  };

  const handleOpenMenuClick: MouseEventHandler<HTMLButtonElement> = event => {
    setAnchorEl(event.currentTarget);
  };

  const hideProfileMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
      <AppBar elevation={0} position="static">
        <Container maxWidth="xl">
          <Box display="flex" justifyContent="space-between" py={1}>
            <Box
              component="img"
              sx={{
                maxHeight: '48px',
              }}
              src={group.data?.logoUrl}
              alt={group.data?.name}
            />

            <Box sx={{display: 'flex', alignItems: 'center'}}>
              {isSiteJump && authProfile.data && (
                <Box sx={{mr: 2}}>
                  <SiteJumpAsUserAlert userData={authProfile.data} />
                </Box>
              )}
              <Button
                color="primary"
                variant="outlined"
                sx={{mr: 2}}
                onClick={handleStartNewApplication}
              >
                Start New Application
              </Button>

              <IconButton color="inherit" size="large" onClick={handleOpenMenuClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={hideProfileMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
              </Menu>
            </Box>
          </Box>
        </Container>
      </AppBar>
      <Box component="main" sx={{flexGrow: 1}}>
        <Container maxWidth="xl" sx={{py: 1}}>
          {children}
        </Container>
      </Box>
      {!group.promised && group.data && (
        <PageFooter groupName={group.data.name} groupDomain={group.data.portalDomain} />
      )}
    </Box>
  );
};
