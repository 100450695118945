import {AsyncState, GetUsersProfilesResult, useAuthContainer, useCallable} from '@ozark/common';
import {useCallback, useEffect, useState} from 'react';
import {createContainer} from 'unstated-next';

function useUserProfiles() {
  const [userProfiles, setUserProfiles] = useState<AsyncState<GetUsersProfilesResult>>({
    promised: true,
  });
  const {getUsersProfiles} = useCallable();
  const {authUser} = useAuthContainer();
  const loadUserProfiles = useCallback(async () => {
    if (!authUser.data?.uid) {
      return;
    }
    const profiles = await getUsersProfiles();
    setUserProfiles({promised: false, data: profiles});
  }, [authUser.data?.uid]);

  useEffect(() => {
    loadUserProfiles();
  }, [loadUserProfiles]);

  return {
    userProfiles,
    loadUserProfiles,
  };
}

export const UserProfilesContainer = createContainer(useUserProfiles);
export const useUserProfilesContainer = UserProfilesContainer.useContainer;
