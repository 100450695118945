import {
  Checkbox as MuiCheckbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  Theme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {useEffect, useState} from 'react';
import {UseFormReturn} from 'react-hook-form';
import * as yup from 'yup';
import {Select, TextField} from '../..';
import {Department, UserRoleName, UserRoleNames} from '../../..';
import {MenuProps} from '../../../helpers';

export type TicketCategoryFormData = {
  name: string;
  assignment: Department;
  visibleToRoles: UserRoleName[];
  sla?: number;
  info?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleWrapper: {
      marginTop: theme.spacing(2),
    },
    title: {
      fontSize: '1.2em',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1em',
      },
    },
    formControl: {
      margin: theme.spacing(2, 0, 0, 0),
    },
  })
);

export const TicketCategoryFormSchema = yup.object({
  name: yup.string().required('Name is required'),
  assignment: yup.string().required('Assignment is required'),
  visibleToRoles: yup
    .array()
    .of(yup.string())
    .required('Visible to roles are required')
    .min(1, 'Visible to roles are required'),
});

type Props = {
  hookForm: UseFormReturn<TicketCategoryFormData>;
};

export const TicketCategoryForm = ({hookForm}: Props) => {
  const classes = useStyles();
  const [chosenVisibleToRoles, setVisibleToRoles] = useState<string[]>([]);

  const {
    watch,
    formState: {errors},
    control,
    register,
    setValue,
  } = hookForm;

  useEffect(() => {
    register('visibleToRoles');
  }, [register]);

  const handleVisibleToRolesChange = (event: SelectChangeEvent<string[]>) => {
    const visibleToRoles = event.target.value as string[];
    setVisibleToRoles(visibleToRoles);
    setValue('visibleToRoles', visibleToRoles as UserRoleName[], {shouldDirty: true});
  };

  useEffect(() => {
    // set visibleToRoles:
    const curVisibleToRoles: string[] | null =
      (control._defaultValues.visibleToRoles as string[]) || null;
    if (curVisibleToRoles) {
      setVisibleToRoles(curVisibleToRoles);
    }
  }, [control]);

  const slaList = () => {
    const timeslots = [];
    timeslots.push({key: '30 minutes', value: 30});

    for (let hour = 1; hour < 25; hour++) {
      timeslots.push({key: `${hour} ${hour === 1 ? 'hour' : 'hours'}`, value: hour * 60});
    }
    return timeslots;
  };

  return (
    <>
      <Grid item xs={12}>
        <TextField name="name" label="Name" required errors={errors} control={control} />
      </Grid>
      <Grid item xs={12}>
        <Select
          name="assignment"
          label="Assignment"
          required
          errors={errors}
          control={control}
          options={Object.values(Department)}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl className={classes.formControl} fullWidth variant="outlined">
          <InputLabel id="visibleToRoles-label">Visible To Roles</InputLabel>
          <MuiSelect
            name="visibleToRoles"
            variant="outlined"
            labelId="visibleToRoles-label"
            label="Visible To Roles"
            id="visibleToRoles"
            multiple
            required
            error={Boolean(errors.visibleToRoles)}
            renderValue={selected => (selected as string[]).join(', ')}
            value={chosenVisibleToRoles}
            onChange={handleVisibleToRolesChange}
            MenuProps={MenuProps}
            fullWidth
          >
            {UserRoleNames.map(x => (
              <MenuItem key={`${x}`} value={x}>
                <MuiCheckbox checked={chosenVisibleToRoles.indexOf(x) > -1} color="primary" />
                <ListItemText primary={x} />
              </MenuItem>
            ))}
          </MuiSelect>
          {errors.visibleToRoles?.message && (
            <FormHelperText error={true}>{errors.visibleToRoles?.message}</FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Select name="sla" label="SLA" errors={errors} control={control}>
          {slaList().map(x => {
            return (
              <MenuItem key={`${x.key}`} value={x.value}>
                {x.key}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
      <Grid item xs={12}>
        <TextField name="info" label="Info" errors={errors} control={control} />
      </Grid>
    </>
  );
};
