import {Firebase} from '@ozark/common/firebase';
import {getApplicationByMid} from '@ozark/common/firebase/queries/applications';
import {getTicketCategory} from '@ozark/common/firebase/queries/ticketCategory';
import {formatCurrency} from '@ozark/common/util';
import {Collections, PlacementsValues, PlacementType} from '@ozark/functions/src/constants';
import {
  Application,
  AssociationType,
  Ticket,
  TicketAssociation,
  TicketTypeAndCategory,
  TicketTypeCategoryAutomation,
} from '@ozark/functions/src/documents';
import {useCallback} from 'react';
import {useFormContext} from 'react-hook-form';
import {
  FIELD_NAME_ASSOCIATION,
  FIELD_NAME_CATEGORY,
  FIELD_NAME_DESCRIPTION,
  FIELD_NAME_SUBJECT,
} from '../constants/constants';
import {useTicketId} from './useTicketId';

export const saveTicket = (ticketId: string, ticketData: Partial<Ticket>) => {
  return Firebase.firestore
    .collection(Collections.tickets)
    .doc(ticketId)
    .set({...ticketData, updatedAt: Firebase.now()}, {merge: true});
};

export const useTicketCategoryAutomation = () => {
  const {ticketId} = useTicketId();
  const {getValues, setValue} = useFormContext<Ticket>();

  const triggerCheckCategoryAutomation = useCallback(async (): Promise<boolean> => {
    const [category, association] = getValues([FIELD_NAME_CATEGORY, FIELD_NAME_ASSOCIATION]);
    const updates = await getCategoryAutomationAppliedSubjectDescription(category, association);

    if (!updates) {
      return false;
    }

    const ticket: Partial<Ticket> = {};

    if (updates.description) {
      setValue(FIELD_NAME_DESCRIPTION, updates.description, {shouldDirty: false});
      ticket.description = updates.description;
    }

    if (updates.subject) {
      setValue(FIELD_NAME_SUBJECT, updates.subject, {shouldDirty: false});
      ticket.subject = updates.subject;
    }

    await saveTicket(ticketId, ticket);

    return true;
  }, []);

  return {
    triggerCheckCategoryAutomation,
  };
};

type CustomTemplateContext = {
  application?: Application;
};

export const getCategoryAutomationAppliedSubjectDescription = async (
  category: TicketTypeAndCategory,
  association: TicketAssociation
): Promise<SubjectDescription | undefined> => {
  try {
    if (!category.type?.id || !category.category?.id) {
      return;
    }

    const categorySettings = await getTicketCategory(category.type.id, category.category?.id);

    if (!categorySettings || !categorySettings.automation) {
      return;
    }

    if (association?.type !== categorySettings.automation.association) {
      return;
    }

    const updates = await getCategoryAutomationUpdatedTicketData(
      categorySettings.automation,
      association
    );

    if (!updates) {
      return;
    }

    return updates;
  } catch (error) {
    console.error('Failed to check category automation with an error', error);
  }
};

export type SubjectDescription = {
  subject: string;
  description: string;
};

const getCategoryAutomationUpdatedTicketData = async (
  categoryAutomation: TicketTypeCategoryAutomation,
  association: TicketAssociation
): Promise<SubjectDescription | null> => {
  if (categoryAutomation.association !== AssociationType.merchant) {
    console.error(
      'Category automation association type is configured to unsupported association type'
    );
    return null;
  }

  if (!association.merchant?.mid) {
    console.error('MID is missing in association');

    return null;
  }

  const application = await getApplicationByMid(association.merchant.mid);

  if (!application) {
    return null;
  }

  const context: CustomTemplateContext = {
    application,
  };

  let description = categoryAutomation.description;
  const customTemplatesKeys = Object.keys(CUSTOM_TICKET_DESCRIPTION_TEMPLATES);
  for (const customTemplateKey of customTemplatesKeys) {
    const key = `{{{${customTemplateKey}}}}`;
    const hasDescriptionTemplate = description.includes(key);
    if (!hasDescriptionTemplate) {
      continue;
    }

    description = description.replace(
      key,
      CUSTOM_TICKET_DESCRIPTION_TEMPLATES[customTemplateKey](context)
    );
  }

  let subject = categoryAutomation.subject;

  if (subject.includes('{{{applicationSubject}}}')) {
    const applicationSubject = [
      association.merchant.dba,
      association.merchant.legalName,
      association.merchant.mid,
    ].filter(item => item);

    subject = subject.replace('{{{applicationSubject}}}', applicationSubject.join(' | '));
  }

  return {
    subject,
    description,
  };
};

const NotAvailableText = 'N/A';

const CUSTOM_TICKET_DESCRIPTION_TEMPLATES: Record<
  string,
  (context: CustomTemplateContext) => string
> = {
  placementType: context => {
    const equipment = context.application?.equipment;

    return `
      Placement: ${
        equipment?.placementType
          ? PlacementsValues[equipment.placementType as PlacementType] || NotAvailableText
          : NotAvailableText
      }
    `;
  },
  equipmentType: context => {
    const equipment = context.application?.equipment;

    return `Equipment Type: ${equipment?.name ?? NotAvailableText}`;
  },
  earlyDeconversionFee: context => {
    const earlyDeconversionFee = context.application?.rateSet?.earlyDeconversionFee;

    return `Early Deconversion Fee: ${
      earlyDeconversionFee !== undefined ? formatCurrency(earlyDeconversionFee) : NotAvailableText
    }`;
  },
};
