import {
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Box} from '@mui/system';
import {
  ApplicationEquipment,
  ApplicationView,
  EquipmentTipMode,
  EquipmentView,
  populateTimeSlots,
  ProcessingTypes,
} from '@ozark/common';
import {format} from 'date-fns';
import React, {useEffect, useState} from 'react';

enum QuestionType {
  Radio = 'radio',
  Select = 'select',
}

const Question = ({
  type = QuestionType.Radio,
  name,
  question,
  options,
  onChange,
  showIdk = false,
  defaultValue = null,
}: {
  type?: string;
  name: string;
  question: string;
  options: {value: string; label?: string; skipCondition?: any}[];
  onChange: any;
  showIdk?: boolean;
  defaultValue?: string | null;
}) => {
  const [value, setValue] = useState<string | null>(defaultValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | any) => {
    setValue((event.target as HTMLInputElement).value);
    onChange(event);
  };

  return (
    <Box sx={{mt: 2, mb: 2}}>
      <FormControl>
        <FormLabel id="radio-buttons-group">{question}</FormLabel>
        {type === QuestionType.Radio && (
          <RadioGroup
            aria-labelledby="radio-buttons-group"
            name={name}
            value={value}
            onChange={handleChange}
          >
            {options.map((e, index) => (
              <FormControlLabel
                key={`rb-${index}-${e.label}`}
                value={e.value}
                control={<Radio />}
                label={e.label || e.value}
              />
            ))}
            {showIdk && (
              <FormControlLabel
                key="rb-unknown"
                value={''}
                control={<Radio />}
                label={`I don't know`}
              />
            )}
          </RadioGroup>
        )}
        {type === QuestionType.Select && (
          <Select name={name} value={value} onChange={handleChange} sx={{mt: 2}}>
            {options.map((e, index) => (
              <MenuItem key={`opt-${index}-${e.label}`} value={e.value}>
                {e.label}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
    </Box>
  );
};

const getTimeSlots = () => {
  const timeslots: any[] = [];
  const getTime = (hours: number, minutes: number) => {
    const now = new Date();
    now.setHours(hours);
    now.setMinutes(minutes);
    return {
      label: format(now, 'h:mm a'),
      value: format(now, 'h:mm a'),
    };
  };
  populateTimeSlots(timeslots, getTime);
  return timeslots;
};

export const EquipmentFileDialog = ({
  application,
  update,
  equipment,
  open,
  setOpen,
}: {
  application: ApplicationView;
  update: (data: any) => Promise<void>;
  equipment: EquipmentView | null | undefined;
  open: boolean;
  setOpen: (close: boolean) => void;
}) => {
  const [formState, setFormState] = useState<any>({});
  const [state, setState] = useState<Partial<ApplicationEquipment>>({});
  const timeSlotOptions = getTimeSlots();

  const handleClose = () => {
    setOpen(false);
  };

  const handleDone = async () => {
    await update({equipment: {...application.equipment, ...state}});
    handleClose();
  };

  const handleChange = (event: any) => {
    const field = event.target.name;
    let value = event.target.value;

    if (field === 'autoBatch') {
      value = value === 'Yes';
    }

    setState({...state, [field]: value});
  };

  const handleFormStateChange = (event: any) => {
    if (
      event.target.name === 'isTipMode' &&
      event.target.value === 'No' &&
      equipment?.options?.tipMode
    ) {
      setState({...state, tipMode: undefined});
    }
    setFormState({...formState, [event.target.name]: event.target.value});
  };

  useEffect(() => {
    setState({});
    setFormState({});
  }, [equipment]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="equipment-file-dialog-title"
      aria-describedby="equipment-file-dialog-description"
    >
      <DialogTitle id="equipment-file-dialog-title">Let's Configure Your Equipment</DialogTitle>
      <DialogContent>
        <DialogContentText id="equipment-file-dialog-description">
          The following questions will help us set up your equipment:
        </DialogContentText>
        <Box sx={{m: 1, display: 'flex', flexDirection: 'column'}}>
          {equipment?.options?.connectionType && (
            <Question
              name="connectionType"
              question="How will your equipment communicate at your location?"
              options={equipment?.options?.connectionType.map(e => ({value: e}))}
              onChange={handleChange}
              showIdk={true}
              defaultValue={null}
            />
          )}
          {application?.processingType === ProcessingTypes.cardPresent &&
            equipment?.options?.tipMode && (
              <>
                <Question
                  name="isTipMode"
                  question="Will your business accept tips from customers?"
                  options={[{value: 'Yes'}, {value: 'No'}]}
                  onChange={handleFormStateChange}
                  defaultValue={null}
                />
                {formState.isTipMode === 'Yes' && (
                  <Question
                    name="tipMode"
                    question="What is your tip preference?"
                    options={equipment?.options?.tipMode.map(e => ({
                      value: e,
                      label:
                        e === EquipmentTipMode.tipAdjust
                          ? 'Traditional (The customer writes tip on receipt)'
                          : e === EquipmentTipMode.tipInTransaction
                          ? 'Alternative (The customer selects option on equipment)'
                          : e,
                    }))}
                    onChange={handleChange}
                    defaultValue={null}
                  />
                )}
              </>
            )}
          {(formState.isTipMode === 'No' ||
            (formState.isTipMode === 'Yes' &&
              state.tipMode === EquipmentTipMode.tipInTransaction)) && (
            <>
              {equipment?.options?.autoBatch && (
                <Question
                  name="autoBatch"
                  question="Would you like your equipment to automatically close your day? This is to send the sales to your bank account."
                  options={equipment.options.autoBatch.map(e => ({value: e}))}
                  onChange={handleChange}
                  showIdk={true}
                  defaultValue={null}
                />
              )}
              {state?.autoBatch && (
                <Question
                  name="autoBatchTime"
                  question="What time would you like to close your day?"
                  type={QuestionType.Select}
                  options={timeSlotOptions}
                  onChange={handleChange}
                  defaultValue={''}
                />
              )}
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Button onClick={handleClose}>Skip this for Now</Button>
        <Button variant="outlined" onClick={handleDone}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};
