export const PortfolioReservesIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g fill={'inherit'}>
        <path
          d="M48,512c-26.5,0-48-21.5-48-48l0,0V176c0-26.5,21.5-48,48-48h48v208c0,44.2,35.8,80,80,80h336v48
		c0,26.5-21.5,48-48,48l0,0H48z"
        />
        <g>
          <path
            opacity={0.4}
            d="M285.9,192.4h-9.2c-3.2,0-5.6,2.3-5.6,5.6v13.3c0,3.2,2.3,5.6,5.6,5.6h9.2c8.6,0,13-1.1,13-12.2
			C298.9,193.5,294.5,192.4,285.9,192.4z"
          />
          <path
            opacity={0.4}
            d="M592,64H400L336,0H176c-26.5,0-48,21.5-48,48v288c0,26.5,21.5,48,48,48h416c26.5,0,48-21.5,48-48V112
			C640,85.5,618.5,64,592,64z M328.7,287.5h-20.5c-1.8,0-3.4-0.9-4.1-2.5l-18.7-36.2c-0.4-0.5-0.9-0.9-1.4-0.9h-7.2
			c-3.2,0-5.6,2.3-5.6,5.6v28.4c0,3.2-2.3,5.6-5.6,5.6h-20.2c-3.2,0-5.6-2.3-5.6-5.6V167c0-3.2,2.3-5.6,5.6-5.6h39.4
			c32,0,45,13.7,45,43.2c0,16.7-4.1,28.4-13.7,35.3c-0.7,0.5-0.9,1.4-0.5,2.3l19.1,35.5C337.2,282.4,334.3,287.5,328.7,287.5z
			 M433,187c0,3.4-1.8,5.4-5.4,5.4H384c-3.4,0-5.4,1.8-5.4,5.4v5.8c0,3.4,2,5.4,5.4,5.4h27.9c3.6,0,5.4,1.8,5.4,5.4v20.2
			c0,3.4-1.8,5.4-5.4,5.4H384c-3.4,0-5.4,1.8-5.4,5.4v5.8c0,3.4,2,5.4,5.4,5.4h43.6c3.6,0,5.4,1.8,5.4,5.4v20.2
			c0,3.6-1.8,5.4-5.4,5.4H352c-3.4,0-5.4-2-5.4-5.4V166.9c0-3.6,2-5.4,5.4-5.4h75.6c3.6,0,5.4,1.8,5.4,5.4V187z M484.4,290.2
			c-37.8,0-45.9-24.8-46.8-38.9c-0.2-3.2,1.4-5.2,4.7-5.9l20.3-4.5c3.6-0.7,5.6,0.9,5.9,4.7c0.7,8.6,7.4,13.7,15.8,13.7
			c8.8,0,15.3-5.2,14.2-10.1c-3.4-15.3-57.6-11.9-57.6-53.3c-0.2-5.4,3.1-37.3,43.4-37.3c37.1,0,45.7,24.1,46.8,37.1
			c0.2,3.2-1.4,5.2-4.7,5.9l-20.5,4.5c-3.6,0.7-5.4-0.9-5.9-4.5c-1.3-7.4-7.2-12.1-15.7-12.1c-7,0-11.3,4.1-10.6,8.3
			c2.5,14,59.4,13.7,59.4,55.1C533.2,258.3,527.1,290.2,484.4,290.2z"
          />
        </g>
        <g>
          <path
            d="M334.7,277.7c2.5,4.7-0.4,9.7-5.9,9.7h-20.5c-1.8,0-3.4-0.9-4.1-2.5l-18.7-36.2c-0.4-0.5-0.9-0.9-1.4-0.9
			h-7.2c-3.2,0-5.6,2.3-5.6,5.6v28.4c0,3.2-2.3,5.6-5.6,5.6h-20.2c-3.2,0-5.6-2.3-5.6-5.6V167c0-3.2,2.3-5.6,5.6-5.6h39.4
			c32,0,45,13.7,45,43.2c0,16.7-4.1,28.4-13.7,35.3c-0.7,0.5-0.9,1.4-0.5,2.3L334.7,277.7z M276.7,192.4c-3.2,0-5.6,2.3-5.6,5.6
			v13.3c0,3.2,2.3,5.6,5.6,5.6h9.2c8.6,0,13-1.1,13-12.2c0-11.2-4.3-12.2-13-12.2H276.7z"
          />
          <path
            d="M427.6,161.5c3.6,0,5.4,1.8,5.4,5.4V187c0,3.4-1.8,5.4-5.4,5.4H384c-3.4,0-5.4,1.8-5.4,5.4v5.8
			c0,3.4,2,5.4,5.4,5.4h27.9c3.6,0,5.4,1.8,5.4,5.4v20.2c0,3.4-1.8,5.4-5.4,5.4H384c-3.4,0-5.4,1.8-5.4,5.4v5.8c0,3.4,2,5.4,5.4,5.4
			h43.6c3.6,0,5.4,1.8,5.4,5.4v20.2c0,3.6-1.8,5.4-5.4,5.4H352c-3.4,0-5.4-2-5.4-5.4V166.9c0-3.6,2-5.4,5.4-5.4H427.6z"
          />
          <path
            d="M506,206.1c-3.6,0.7-5.4-0.9-5.9-4.5c-1.3-7.4-7.2-12.1-15.7-12.1c-7,0-11.3,4.1-10.6,8.3
			c2.5,14,59.4,13.7,59.4,55.1c0,5.4-6.1,37.3-48.8,37.3c-37.8,0-45.9-24.8-46.8-38.9c-0.2-3.2,1.4-5.2,4.7-5.9l20.3-4.5
			c3.6-0.7,5.6,0.9,5.9,4.7c0.7,8.6,7.4,13.7,15.8,13.7c8.8,0,15.3-5.2,14.2-10.1c-3.4-15.3-57.6-11.9-57.6-53.3
			c-0.2-5.4,3.1-37.3,43.4-37.3c37.1,0,45.7,24.1,46.8,37.1c0.2,3.2-1.4,5.2-4.7,5.9L506,206.1z"
          />
        </g>
      </g>
    </svg>
  );
};
