import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Box, IconButton, Tooltip} from '@mui/material';
import {
  FrequencyType,
  JobFrequency,
  LastDayOfMonth,
  LastDayOfMonthText,
  MonthlyJobFrequency,
  ReportingScheduledJobParams,
  ScheduledJobType,
  ScheduledJobView,
  SpecificDateJobFrequency,
  StatementReportingJobParams,
  WeeklyDayOfWeekNames,
  WeeklyJobFrequency,
} from '@ozark/common';
import {hh24toAmPm} from '@ozark/functions/src/helpers/amPmConverter';
import {format} from 'date-fns';
import {Column} from '../../../api/Column';
import {DialogMode, ScheduleJobDialog} from '../../common/ScheduleJobDialog';
import {Table} from '../../Table';
import {AuthorizationsFilters} from '../Authorizations/AuthorizationsFilters';
import {BatchesFiltersComponent} from '../Batches/BatchesFiltersComponent';
import {DepositsCpyDaysFilters} from '../DepositsDays/DepositsCpyDaysFilters';
import {StatementsFilters} from '../Statements/StatementsFilters';
import {ConfirmDeleteScheduledReporting} from './Dialogs/ConfirmDeleteScheduledReporting';
import {useScheduledReportingActions} from './hooks';

interface Props {
  jobs: ScheduledJobView[];
}
export const ScheduledReportingTable = ({jobs}: Props) => {
  const {
    onDeleteScheduledReporting,
    scheduledJobToDelete,
    onCancelDelete,
    deleteScheduledReporting,
    updateScheduledReportingFrequency,
    scheduledJobToUpdate,
    onUpdateScheduledReporting,
    onCancelUpdate,
  } = useScheduledReportingActions();
  const onClose = () => {
    onCancelUpdate();
  };
  const onSubmitNewFrequency = async (data?: JobFrequency) => {
    if (!scheduledJobToUpdate || !data) return;
    await updateScheduledReportingFrequency(scheduledJobToUpdate, data);
    onClose();
  };
  const onDelete = async () => {
    if (!scheduledJobToDelete) return;

    await deleteScheduledReporting(scheduledJobToDelete);
    onCancelDelete();
  };
  return (
    <>
      <Box sx={{overflowY: 'auto', maxHeight: '100%'}}>
        <Table
          columns={columns}
          rows={jobs}
          stickyHeader
          actions={(row: ScheduledJobView) => (
            <Box sx={{textAlign: 'end'}}>
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  onUpdateScheduledReporting(row);
                }}
                size="small"
              >
                <Tooltip title="Update Schedule Settings">
                  <EditIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  onDeleteScheduledReporting(row);
                }}
                size="small"
              >
                <Tooltip title="Delete">
                  <DeleteIcon />
                </Tooltip>
              </IconButton>
            </Box>
          )}
        />
      </Box>
      <ConfirmDeleteScheduledReporting
        open={Boolean(scheduledJobToDelete)}
        onCancel={onCancelDelete}
        onDelete={onDelete}
      />
      {Boolean(scheduledJobToUpdate) && (
        <ScheduleJobDialog
          open={Boolean(scheduledJobToUpdate)}
          onClose={onClose}
          onSubmit={onSubmitNewFrequency}
          existingFrequency={scheduledJobToUpdate?.frequency}
          simpleDialogMessage="The Statement Report is emailed to you once a month"
          dialogMode={
            scheduledJobToUpdate?.jobType === ScheduledJobType.midReportingStatements
              ? DialogMode.Simple
              : DialogMode.Detailed
          }
        />
      )}
    </>
  );
};

const getReportName = (job: ScheduledJobView) => {
  switch (job.jobType) {
    case ScheduledJobType.midReportingAuthorizations:
      return 'Authorizations';
    case ScheduledJobType.midReportingBatches:
      return 'Batches';
    case ScheduledJobType.midReportingDeposits:
      return 'Deposits';
    case ScheduledJobType.midReportingStatements:
      return 'Statements';
    default:
      return '';
  }
};

const getBaseFrequencyText = (job: ScheduledJobView) => {
  const amPm = hh24toAmPm(job.frequency!.hour);
  return `at ${amPm.hour} ${amPm.amPmPeriod}`;
};

const getDailyFrequencyText = (job: ScheduledJobView) => {
  return `Daily ${getBaseFrequencyText(job)}`;
};

const getWeeklyFrequencyText = (job: ScheduledJobView) => {
  const weeklyJobFrequency = job.frequency as WeeklyJobFrequency;
  const daysOfWeek = weeklyJobFrequency.daysOfWeek ?? [];
  return `Weekly on ${daysOfWeek
    .map(x => WeeklyDayOfWeekNames[x])
    .join(', ')} ${getBaseFrequencyText(job)}`;
};

const getMonthlyFrequencyText = (job: ScheduledJobView) => {
  const monthlyJobFrequency = job.frequency as MonthlyJobFrequency;
  const daysOfMonth = monthlyJobFrequency.daysOfMonth ?? [];
  const daysOfMonthText = daysOfMonth.map(x =>
    x === LastDayOfMonth ? `"${LastDayOfMonthText}"` : x.toString()
  );
  return `Monthly on ${daysOfMonthText.join(', ')} ${getBaseFrequencyText(job)}`;
};

const getSpecificFrequencyText = (job: ScheduledJobView) => {
  const specificDateJobFrequency = job.frequency as SpecificDateJobFrequency;
  const dates = specificDateJobFrequency.dates ?? [];
  return `On ${dates.map(x => format(new Date(x), 'MM/dd/yyyy')).join(',')} ${getBaseFrequencyText(
    job
  )}`;
};

const getFrequencyText = (job: ScheduledJobView) => {
  if (job.jobType === ScheduledJobType.midReportingStatements) {
    return 'Once a month';
  }

  if (!job.frequency) {
    return '';
  }
  switch (job.frequency.type) {
    case FrequencyType.daily:
      return getDailyFrequencyText(job);
    case FrequencyType.weekly:
      return getWeeklyFrequencyText(job);
    case FrequencyType.monthly:
      return getMonthlyFrequencyText(job);
    case FrequencyType.specific:
      return getSpecificFrequencyText(job);
    default:
      return '';
  }
};

const getParameters = (job: ScheduledJobView) => {
  if (!job.jobParameters) {
    return <></>;
  }
  const reportingScheduledJobParams = job.jobParameters as ReportingScheduledJobParams;
  switch (job.jobType) {
    case ScheduledJobType.midReportingAuthorizations:
      return <AuthorizationsFilters filters={reportingScheduledJobParams.uiFilters} />;
    case ScheduledJobType.midReportingBatches:
      return <BatchesFiltersComponent filters={reportingScheduledJobParams.uiFilters} />;
    case ScheduledJobType.midReportingDeposits:
      return <DepositsCpyDaysFilters filters={reportingScheduledJobParams.uiFilters} />;
    case ScheduledJobType.midReportingStatements:
      return <StatementsFilters filter={job.jobParameters as StatementReportingJobParams} />;
    default:
      return <></>;
  }
};

const columns: Column<ScheduledJobView>[] = [
  {
    id: 'jobType',
    label: 'Report Name',
    sortable: false,
    selector: job => getReportName(job),
  },
  {
    id: 'frequency',
    label: 'Schedule Settings',
    sortable: false,
    selector: job => getFrequencyText(job),
  },
  {
    id: 'reportParameters',
    label: 'Parameters',
    sortable: false,
    selector: job => getParameters(job),
  },
];
