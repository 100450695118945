import GroupIcon from '@mui/icons-material/Group';
import {Avatar, Box, Typography} from '@mui/material';
import {useWatch} from 'react-hook-form';
import {TicketAuthor, useUserInfo} from '../../../..';
import {AvatarFromName} from '../../common/AvatarFromName';
import {FIELD_NAME_AUTHOR} from '../../constants/constants';
import {useTicketAuthor} from '../../hooks/useTicketAuthor';

export const AuthorInfo = () => {
  const {isErpUser, uid} = useUserInfo();
  const {getAuthorName} = useTicketAuthor();
  const author: TicketAuthor | undefined = useWatch({name: FIELD_NAME_AUTHOR});
  if (!author) return <span>...</span>;
  const isGroupIcon = !isErpUser;
  const authorName = getAuthorName(author);
  const description = isErpUser ? author.description : '';
  return (
    <Box display="flex" alignItems="flex-start" flex={1}>
      {isGroupIcon && (
        <Avatar sx={{width: 34, height: 34}}>
          <GroupIcon />
        </Avatar>
      )}
      {!isGroupIcon && <AvatarFromName name={author.name ?? 'N A'} size={34} />}
      <Box pl={1}>
        <Typography variant="body1">{authorName ?? 'Not Available'}</Typography>
        {description && (
          <Typography variant="body2" color="secondary">
            {description}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
