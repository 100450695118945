import {subYears} from 'date-fns';
import * as yup from 'yup';
import {getCustomErrorMessage, getDateTypeError} from '../Common';

export const getDateOfBirthValidation = (useCustomErrorMessage: boolean) => {
  return yup
    .date()
    .when('$dobSsnTaxIdAndBankInfoNotRequired', (dobSsnTaxIdAndBankInfoNotRequired, schema) => {
      if (!dobSsnTaxIdAndBankInfoNotRequired) {
        return schema.required(
          getCustomErrorMessage(useCustomErrorMessage, 'Date of Birth is required')
        );
      }
      return;
    })
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .min(subYears(new Date(), 100), getCustomErrorMessage(useCustomErrorMessage, 'Invalid age'))
    .max(
      subYears(new Date(), 18),
      getCustomErrorMessage(useCustomErrorMessage, 'Signer must be 18 years or older')
    )
    .typeError(getDateTypeError(useCustomErrorMessage, 'Invalid date'));
};
