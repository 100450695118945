import {useUrlQuery} from '../../../hooks/useUrlQuery';

export function useQueryMonthYear() {
  const urlQuery = useUrlQuery();
  const year = Number(urlQuery.get('year'));
  const month = Number(urlQuery.get('month'));
  const hasValue = Boolean(year) && month > -1;
  const monthYear = hasValue ? `${month}/${year}` : undefined;

  return {
    year,
    month,
    monthYear,
    hasValue,
  };
}
