import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Accordion,
  AccordionDetails,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Theme,
  Tooltip,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {TextField} from '@ozark/common/components';
import React, {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {Benefits} from '../../../functions/src/documents/RateProgram';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      marginTop: theme.spacing(-2),
      marginBottom: theme.spacing(-2),
    },
    actionItem: {
      cursor: 'pointer',
      display: 'inherit',
    },
    displayInherit: {
      display: 'inherit',
    },
  })
);

const MAX_ITEMS = 6;

interface Props {
  id: string;
  benefits: Benefits;
  alwaysExpanded?: boolean;
  handleUpdate: (benefits: Benefits) => void;
  disabled?: boolean;
}

export const EditBenefits = ({id, benefits, alwaysExpanded, handleUpdate, disabled}: Props) => {
  const classes = useStyles();
  const [benefitsExpanded, setBenefitsExpanded] = useState<boolean>(false);
  const [data, setData] = useState<Benefits>(benefits);

  const formMethods = useFormContext();

  // handle firestore updates:
  useEffect(() => {
    setData(benefits);
    handleUpdate(benefits);
  }, [benefits]);

  const handleItemChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    console.log('item change');
    data.list[index] = event.target.value;
    console.log(JSON.stringify(data));
    handleUpdate(data);
  };

  const handleItemAdd = (index: number) => {
    data.list.splice(index + 1, 0, '');
    setData({...data});
    handleUpdate(data);
  };

  const handleItemDelete = (index: number) => {
    data.list.splice(index, 1);
    setData({...data});
    handleUpdate(data);
  };

  const getBenefit = (benefit: string, index: number) => {
    return (
      <Grid item xs={12} key={`benefits:${benefit}`}>
        <ListItem className={classes.item}>
          <TextField
            label=""
            variant="outlined"
            name={`benefits.list.${index}`}
            errors={formMethods.formState.errors}
            control={formMethods.control}
            defaultValue={benefit}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleItemChange(event, index)
            }
            disabled={disabled}
          />
          {!disabled && (
            <>
              <ListItemIcon>
                <Tooltip
                  title={
                    data.list.length < MAX_ITEMS
                      ? 'Add item below'
                      : `Cannot add benefits. The maximum count is ${MAX_ITEMS}`
                  }
                >
                  <span className={classes.actionItem}>
                    <IconButton
                      disabled={data.list.length === MAX_ITEMS}
                      size="small"
                      onClick={() => handleItemAdd(index)}
                    >
                      <AddIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  title={
                    data.list.length === 1 ? 'You must have at least 1 benefit' : 'Delete item'
                  }
                >
                  <span className={classes.actionItem}>
                    <IconButton
                      disabled={data.list.length === 1}
                      size="small"
                      onClick={() => handleItemDelete(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </ListItemIcon>
            </>
          )}
        </ListItem>
      </Grid>
    );
  };

  return (
    <Accordion
      key={id}
      TransitionProps={{unmountOnExit: true}}
      expanded={alwaysExpanded || benefitsExpanded}
      onChange={(e, expanded) => setBenefitsExpanded(expanded)}
    >
      <AccordionDetails className={classes.displayInherit}>
        <List>
          <Grid container spacing={0}>
            {data.list && data.list.map((val, idx) => getBenefit(val, idx))}
          </Grid>
        </List>
      </AccordionDetails>
    </Accordion>
  );
};
