import {UniversalTimestamp} from '@ozark/common';
import {format, formatRelative} from 'date-fns';
import {EditorValue} from 'react-rte';

const DATE_TIME_FORMAT = 'MM/dd/yy - hh:mm a';

export const timestampToFormattedDateTime = (timestamp: UniversalTimestamp) =>
  format(timestamp.toDate(), DATE_TIME_FORMAT);

export const timestampToHumanReadableDate = (timestamp: UniversalTimestamp) =>
  formatRelative(timestamp?.toDate() ?? new Date(), new Date()).split(' at ')[0];

export const isRteValueEmpty = (input: EditorValue) => {
  const ZERO_SPACE_WIDTH_CHAR_CODE = 8203;

  const content = input.toString('markdown').trim();

  return content.length === 1 && content.charCodeAt(0) === ZERO_SPACE_WIDTH_CHAR_CODE;
};
