import DeleteIcon from '@mui/icons-material/Delete';
import Button, {ButtonProps} from '@mui/material/Button';
import {MouseEventHandler, useCallback, useState} from 'react';
import {useWatch} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {Collections, Firebase, TicketStatus, withOverflowConfirmation} from '../../..';
import {useNotification} from '../../../hooks';
import {FIELD_NAME_STATUS} from '../constants/constants';
import {useTicketId} from '../hooks/useTicketId';

const ButtonDelete = withOverflowConfirmation<ButtonProps>(Button);

export function ButtonDeleteTicket() {
  const history = useHistory();
  const {ticketId, isTicketIdValid} = useTicketId();
  const [isDeleting, setIsDeleting] = useState(false);
  const status = useWatch({name: FIELD_NAME_STATUS});
  const showNotification = useNotification();

  const handleDeleteTicket: MouseEventHandler<HTMLButtonElement> = useCallback(async event => {
    event.stopPropagation();

    if (!isTicketIdValid) return;

    try {
      setIsDeleting(true);

      if (status === TicketStatus.Draft) {
        await Firebase.firestore.collection(Collections.tickets).doc(ticketId).delete();
      } else {
        await Firebase.firestore
          .collection(Collections.tickets)
          .doc(ticketId)
          .set({status: TicketStatus.Deleted}, {merge: true});
      }

      showNotification('success', 'Ticket has been deleted');
      history.goBack();
    } catch (error) {
      console.error(error);
      showNotification('error', 'Ticket deletion failed.');
    } finally {
      setIsDeleting(false);
    }
  }, []);

  if (status === TicketStatus.Deleted) {
    return null;
  }

  return (
    <ButtonDelete
      color="error"
      id="account-menu"
      isLoading={isDeleting}
      primaryButtonColor="error"
      primaryButtonText="Delete"
      secondaryButtonText="Cancel"
      startIcon={<DeleteIcon />}
      sx={{ml: 2}}
      title="Delete this ticket?"
      tooltip="Delete ticket"
      variant="text"
      onClick={handleDeleteTicket}
    >
      Delete
    </ButtonDelete>
  );
}
