import {Box} from '@mui/material';
import {useTicketEditContainer} from '../hooks/useTicketEditContainer';
import {TicketsHistoryTab} from './TicketsHistoryTab';

export const TicketsHistoryTabs = () => {
  const {isTicketsDupesTab, setIsTicketsDupesTab} = useTicketEditContainer();

  return (
    <Box display="flex" alignItems="center" pl={0.2}>
      <TicketsHistoryTab onClick={() => setIsTicketsDupesTab(true)} isActive={isTicketsDupesTab}>
        Possible Dupes
      </TicketsHistoryTab>
      <TicketsHistoryTab
        onClick={() => setIsTicketsDupesTab(false)}
        isActive={!isTicketsDupesTab}
        ml={3}
      >
        History
      </TicketsHistoryTab>
    </Box>
  );
};
