import {Box, Button, Drawer} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {ErrorBoundary} from '../common/ErrorBoundary';
import {useNotificationsState} from './hooks/useNotificationsState';
import {NotificationsList} from './NotificationsList';

export const NotificationsDrawer = ({
  drawerOpen,
  toggleDrawer,
}: {
  drawerOpen: boolean;
  toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}) => {
  const classes = useStyles();
  const {hasMoreItems, getNextItems} = useNotificationsState();

  return (
    <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
      <div
        className={classes.list}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <ErrorBoundary>
          <NotificationsList />
        </ErrorBoundary>
        <Box pb={3}>
          {hasMoreItems && (
            <Button
              fullWidth
              onClick={e => {
                e.stopPropagation();
                getNextItems();
              }}
            >
              Load More
            </Button>
          )}
        </Box>
      </div>
    </Drawer>
  );
};

const useStyles = makeStyles({
  list: {
    position: 'relative',
    width: 307,
    overflow: 'hidden',
  },
});
