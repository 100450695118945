import firebase from 'firebase/compat/app';
import {createContainer} from 'unstated-next';
import {AsyncState, AuthUserClaims} from '../..';

export type AuthContainerProps = {
  authUser: AsyncState<firebase.User | null>;
  claims?: AuthUserClaims | null;
};

function useAuth(initialState?: AuthContainerProps) {
  return initialState ?? {authUser: {promised: true}};
}

export const AuthContainer = createContainer(useAuth);
export const useAuthContainer = AuthContainer.useContainer;
