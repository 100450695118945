import {TmpAttachment} from '@ozark/functions/src/functions/express/private/types/Attachments';
import {useCallback} from 'react';
import {useFormContext} from 'react-hook-form';
import {
  Collections,
  Firebase,
  TicketHistoryRecordType,
  TicketStatus,
  TicketStatusName,
  useApiClient,
  useAuthContainer,
  useLoadingContainer,
  useNotification,
  UserRoles,
} from '../../..';
import {getIsTicketAllowedToAutoReply, replyOnTicket} from '../../../helpers';
import {useHistoryRecord} from './useHistoryRecord';
import {useTicketEditContainer} from './useTicketEditContainer';
import {useTicketId} from './useTicketId';

// we need to create a simple CRUD for a comment subcollection with no real aploads - just writing stuff

export function useCommentAttachments() {
  const {addHistoryRecord} = useHistoryRecord();
  const {setValue} = useFormContext();
  const {authUser, claims} = useAuthContainer();
  const {ticketId} = useTicketId();
  const {apiClient} = useApiClient(authUser);
  const {commentsCollection} = useTicketEditContainer();
  const {setIsLoading} = useLoadingContainer();
  const showNotification = useNotification();

  const attachmentUpload = useCallback(
    async (commentId?: string, attachments?: TmpAttachment[]) => {
      setIsLoading(true);

      try {
        if (!ticketId || !commentId || !attachments) {
          throw new Error('attachmentUpload: invalid arguments');
        }

        const ticketRef = Firebase.firestore.collection(Collections.tickets).doc(ticketId);

        const isTicketAllowedToAutoReply = await getIsTicketAllowedToAutoReply(ticketRef);

        if (
          claims?.role &&
          [UserRoles.merchant, UserRoles.agent].includes(claims.role) &&
          isTicketAllowedToAutoReply
        ) {
          const {nextAssignee} = await replyOnTicket(ticketRef, setValue);

          await addHistoryRecord(
            `Status Change to ${TicketStatusName[TicketStatus.Replied]}`,
            TicketHistoryRecordType.Status,
            false,
            `Status Change to ${TicketStatusName[TicketStatus.Replied]}`
          );
          await addHistoryRecord(
            `Assignee auto-changed to ${nextAssignee?.erpDepartment ?? 'n/a'}`,
            TicketHistoryRecordType.Assignee,
            false,
            `Assignee auto-changed to ${nextAssignee?.erpDepartment ?? 'n/a'}`
          );
        }

        await apiClient.attachments.createAttachments({
          baseCloudPath: `tickets/${ticketId}`,
          cloudPathField: 'fileCloudPath',
          author: {
            uid: authUser.data?.uid ?? '',
            name: authUser.data?.displayName ?? '',
          },
          collection: {
            name: Collections.tickets,
            id: ticketId,
            subcollection: {
              name: commentsCollection,
              id: commentId,
            },
          },
          attachments: attachments.map(attachment => ({
            ...attachment,
            extraFields: {
              fileName: attachment.oldFilename,
            },
          })),
        });

        showNotification('success', 'File successfully uploaded');
      } catch (error) {
        console.error('Error adding document: ', error);
        showNotification('error', 'File upload failed');
      } finally {
        setIsLoading(false);
      }
    },
    [ticketId, commentsCollection]
  );

  const attachmentRemove = useCallback(
    async (commentId?: string, attachmentId?: string) => {
      if (!ticketId || !commentId || !attachmentId) {
        console.log('remove attachment', ticketId, commentId, attachmentId);
        showNotification('error', 'Error removing document (missing params)');
        return;
      }
      try {
        await Firebase.firestore
          .collection(Collections.tickets)
          .doc(ticketId)
          .collection(commentsCollection)
          .doc(commentId)
          .collection(Collections.attachments)
          .doc(attachmentId)
          .delete();

        showNotification('success', 'Document successfully deleted!');
      } catch (error) {
        showNotification('error', 'Error removing document');
        console.error('Error removing document: ', error);
      }
    },
    [ticketId, commentsCollection]
  );

  return {
    attachmentUpload,
    attachmentRemove,
  };
}
