import {CircularProgress, Paper, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {AgentView, PortfolioStatistics} from '@ozark/common';
import {
  AutoCompleteInputBase,
  PortfolioGraph,
  PortfolioTable,
  Title,
} from '@ozark/common/components';
import {useEffect, useState} from 'react';
import {useStore} from '../../store/helpers';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 50,
    minHeight: '520px',
  },
  noContent: {
    top: '40%',
    position: 'relative',
    textAlign: 'center',
  },
  grow: {
    flex: 1,
  },
  midButton: {
    textTransform: 'none !important' as any,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '520px',
  },
}));

const PortfolioStatisticsPage = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [portfolioStatistics, setPortfolioStatistics] = useState<PortfolioStatistics | null>();
  const [authorizedAgents, setAuthorizedAgents] = useState<AgentView[]>();
  const [selectedAgent, setSelectedAgent] = useState<AgentView>();
  const {apiClient, getAuthorizedAgents} = useStore();

  useEffect(() => {
    setLoading(true);
    apiClient.reports
      .getPortfolioYearlySummary(selectedAgent?.id)
      .then(result => {
        setPortfolioStatistics(result);
        setLoading(false);
      })
      .catch(err => {
        // TODO: log as error to sentry?
        setLoading(false);
      });
  }, [apiClient, selectedAgent]);

  useEffect(() => {
    getAuthorizedAgents().then(agents => {
      setAuthorizedAgents(agents);
    });
  }, [getAuthorizedAgents]);

  const getBreadcrumbs = () => {
    const breadcrumbs = [<Typography variant="body1">Portfolio Statistics</Typography>];
    return breadcrumbs;
  };

  const getDropDownTitle = (agent?: AgentView): string => {
    return agent ? `${agent?.firstName} ${agent?.lastName}` : 'All Agents';
  };

  return (
    <>
      <Title breadcrumbs={getBreadcrumbs()}>
        <AutoCompleteInputBase
          selected={selectedAgent}
          setSelected={setSelectedAgent}
          icon
          placeholder="Select agent..."
          options={authorizedAgents ?? []}
          getOptionLabel={(agent: AgentView) => getDropDownTitle(agent)}
          onItemSelect={(agent: AgentView | null) => setSelectedAgent(agent ?? undefined)}
        />
      </Title>
      <Paper className={classes.root}>
        <Typography variant="h6" color="secondary">
          {getDropDownTitle(selectedAgent)}
        </Typography>
        {loading || !portfolioStatistics ? (
          <div className={classes.loading}>
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <div>
            <PortfolioGraph portfolioStatistics={portfolioStatistics} />
            <PortfolioTable portfolioStatistics={portfolioStatistics} flat />
          </div>
        )}
        {authorizedAgents && !authorizedAgents.length && (
          <Typography className={classes.noContent}>There are no active agents</Typography>
        )}
      </Paper>
    </>
  );
};

export default PortfolioStatisticsPage;
