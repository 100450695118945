import {UniversalTimestamp, UniversalTimestampRaw} from '@ozark/functions/src/shared';
import {Firebase} from '../firebase';

export function asUniversalTimestamp(
  timestamp: UniversalTimestampRaw | UniversalTimestamp | undefined
): UniversalTimestamp | undefined {
  if (!timestamp) {
    return;
  }

  return timestamp.hasOwnProperty('_seconds')
    ? new Firebase.Timestamp(
        (timestamp as UniversalTimestampRaw)._seconds,
        (timestamp as UniversalTimestampRaw)._nanoseconds
      )
    : (timestamp as UniversalTimestamp);
}

export const isValidDate = (date: any) => {
  return date instanceof Date && typeof date.getTime === 'function' && !isNaN(date.getTime());
};
