import {Ticket} from '@ozark/common';
import {LoadingStatus} from '../../Analytics/common/LoadingStatus';
import {BoxCentered} from '../../common';
import {InfiniteEntities} from '../../InfiniteEntities';
import {useTicketsAllNonErp} from '../hooks/useTicketsAllNonErp';
import {useTicketsQuery} from '../hooks/useTicketsQuery';
import {TicketsListCard} from './TicketsListCard';

export const TicketsListViewAllNonErp = () => {
  const {statusSet, filterKey} = useTicketsQuery();

  const {data, hasData, loading, loadNextPage} = useTicketsAllNonErp(statusSet, filterKey);

  return (
    <>
      {(loading || !hasData) && (
        <BoxCentered flex={1}>
          <LoadingStatus loading={loading} hasData={hasData} />
        </BoxCentered>
      )}
      {hasData && !loading && (
        <InfiniteEntities
          data={data}
          loadNextPage={loadNextPage}
          itemSize={190}
          onRender={(ticket: Ticket) => <TicketsListCard ticket={ticket} />}
        />
      )}
    </>
  );
};
