import {Theme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ResponsiveLine} from '@nivo/line';
import {MonthlySalesSummary} from '../../../functions/src/shared';
import {GraphColors} from '../../constants/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 300,
      width: 'auto',
    },
  })
);

type SummaryProps = {
  yearlySalesSummary: MonthlySalesSummary[];
};

const formatDataForGraph = (yearlySalesSummary: MonthlySalesSummary[]) => {
  const salesData = yearlySalesSummary.map(row => ({x: row.monthYear, y: row.salesAmount}));
  const creditsData = yearlySalesSummary.map(row => ({x: row.monthYear, y: row.creditAmount}));

  return [
    {
      id: 'Credits',
      color: GraphColors.Credits,
      data: creditsData,
    },
    {
      id: 'Sales',
      color: GraphColors.Sales,
      data: salesData,
    },
  ];
};

export const TwelveMonthProcessingGraph = ({yearlySalesSummary}: SummaryProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ResponsiveLine
        data={formatDataForGraph(yearlySalesSummary)}
        margin={{top: 50, right: 110, bottom: 50, left: 90}}
        xScale={{type: 'point'}}
        yScale={{type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false}}
        yFormat=" >-$,.0f"
        lineWidth={3}
        curve="monotoneX"
        colors={{datum: 'color'}}
        animate={true}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 10,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Month Year',
          legendOffset: 36,
          legendPosition: 'middle',
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 25,
          tickRotation: 0,
          legend: 'Total sales',
          legendOffset: -85,
          legendPosition: 'middle',
          format: amount => Number(amount).toLocaleString(),
        }}
        enableSlices="x"
        sliceTooltip={({slice}) => {
          return (
            <div
              style={{
                background: 'white',
                padding: '9px 12px',
                border: '1px solid #ccc',
              }}
            >
              {slice.points.map(point => (
                <div
                  key={point.id}
                  style={{
                    color: point.serieColor,
                    padding: '3px 0',
                  }}
                >
                  <strong>{point.serieId}</strong> [{point.data.yFormatted}]
                </div>
              ))}
            </div>
          );
        }}
        enablePoints={true}
        pointSize={8}
        pointColor={{theme: 'background'}}
        pointBorderWidth={2}
        pointBorderColor={{from: 'serieColor'}}
        pointLabel="yFormatted"
        pointLabelYOffset={-12}
        enablePointLabel={true}
        useMesh={true}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};
