import WarningIcon from '@mui/icons-material/Warning';
import {Box, Tooltip} from '@mui/material';
import {orange, red} from '@mui/material/colors';
import {CBWarningType} from './VDMPReportWarningUtils';

type VDMPTableValueProps = {
  value: number | null | string;
  /** warning takes precedence over info */
  warningType: CBWarningType | null;
  warning?: string;
  info?: string;
};

export const VDMPTableValue = ({
  value,
  warningType,
  warning,
  info,
}: VDMPTableValueProps): JSX.Element => {
  if (!value || value === '-' || (!warning && !info)) {
    return <>{value}</>;
  }

  const getColor = () => {
    if (warningType === CBWarningType.standard) {
      return red[600];
    }

    if (warningType === CBWarningType.early) {
      return orange[600];
    }

    return null;
  };

  return (
    <Tooltip title={warning ? warning : info ?? ''}>
      <Box component="span" sx={{color: getColor()}}>
        {warning && <WarningIcon sx={{verticalAlign: 'bottom'}} />}
        {value && (
          <Box component="span" sx={{ml: 1}}>
            {value}
          </Box>
        )}
      </Box>
    </Tooltip>
  );
};
