import {
  AgentSubCollection,
  ApplicationSubCollection,
  AsyncState,
  Collections,
  Firebase,
  LeadLastNoteInfo,
  LeadSubCollection,
  Note,
  NoteAttachment,
  NoteReply,
  NoteView,
  selectNoteReplyView,
  selectNoteView,
  UniversalSnapshot,
  UniversalTimestamp,
} from '@ozark/common';
import firebase from 'firebase/compat';
import {useCallback, useEffect, useState} from 'react';
import {HARD_DELETE_ATTACHMENTS} from '../constants/application';
import {useNotification} from './useNotification';
import {useUserInfo} from './useUserInfo';

const INITIAL_STATE = {
  promised: true,
};

interface UseNotesProps {
  docFirebaseRef: firebase.firestore.DocumentReference | null;
  notesSubCollection:
    | ApplicationSubCollection.uwRiskNotes
    | ApplicationSubCollection.supportNotes
    | LeadSubCollection.notes
    | AgentSubCollection.notes;
}

export const useNotes = ({docFirebaseRef, notesSubCollection}: UseNotesProps) => {
  const [notes, setNotes] = useState<AsyncState<NoteView[]>>(INITIAL_STATE);
  const {uid, userDisplayName} = useUserInfo();
  const showNotification = useNotification();

  const setLastNoteInfo = useCallback(
    async (noteId: string, replyId: string | null, createdAt: UniversalTimestamp) => {
      if (!docFirebaseRef || !uid) {
        return;
      }
      const lastNoteInfo: LeadLastNoteInfo = {
        noteId: noteId,
        replyId: replyId,
        author: userDisplayName ?? null,
        authorProfileId: uid,
        createdAt: createdAt,
      };

      await docFirebaseRef.update({
        lastNoteInfo,
      });
    },
    [docFirebaseRef, uid, userDisplayName]
  );

  const deleteAttachment = useCallback(
    async (
      docFirebaseRef: firebase.firestore.DocumentReference | null,
      noteId: string,
      noteAttachmentId: string
    ) => {
      if (!docFirebaseRef) {
        showNotification('error', 'Error removing document (missing params)');
        return;
      }

      try {
        const noteAttachmentSnap = await docFirebaseRef
          .collection(notesSubCollection)
          .doc(noteId)
          .collection(Collections.attachments)
          .doc(noteAttachmentId)
          .get();

        if (noteAttachmentSnap) {
          const data = noteAttachmentSnap.data() as NoteAttachment;
          const attachmentId = data.attachmentId;
          await noteAttachmentSnap.ref.delete();

          /**
           * Hard delete means: remove attachment from note and application documents and from storage
           * Soft delete means: remove attachment from note only and keep attachment on application level with deleted=true flag
           */
          if (HARD_DELETE_ATTACHMENTS) {
            await docFirebaseRef.collection(Collections.attachments).doc(attachmentId).delete();
            await Firebase.storage.ref(data.cloudPath).delete();
          } else {
            await docFirebaseRef
              .collection(Collections.attachments)
              .doc(attachmentId)
              .update({deleted: true});
          }
        }

        showNotification('success', 'Document successfully deleted!');
      } catch (error) {
        showNotification('error', 'Error removing document');
        console.error('Error removing document: ', error);
      }
    },
    [notesSubCollection, showNotification]
  );

  const addNote = useCallback(
    async (message: string): Promise<boolean> => {
      if (!docFirebaseRef || !uid) {
        return false;
      }

      try {
        const ref = await docFirebaseRef.collection(notesSubCollection).add({
          uid,
          message,
          createdAt: Firebase.FieldValue.now(),
        });

        if (docFirebaseRef.path.startsWith(Collections.leads)) {
          //set lastNoteInfo for leads
          const noteSnap = await ref.get();
          if (noteSnap.exists) {
            const note = noteSnap.data() as Note;
            await setLastNoteInfo(noteSnap.id, null, note.createdAt);
          }
        }

        return true;
      } catch (error) {
        console.error(error);

        showNotification('error', 'An error occurred while saving note.');

        return false;
      }
    },
    [docFirebaseRef, notesSubCollection, showNotification, uid, setLastNoteInfo]
  );

  const deleteNote = useCallback(
    async (id: string): Promise<boolean> => {
      if (!docFirebaseRef || !uid) {
        return false;
      }

      const noteRef = docFirebaseRef.collection(notesSubCollection).doc(id);

      try {
        await noteRef
          .collection(Collections.attachments)
          .get()
          .then(snapshot => {
            snapshot.forEach(async noteAttachmentDoc => {
              await deleteAttachment(docFirebaseRef, id, noteAttachmentDoc.id);
            });
          });

        await noteRef.update({
          attachments: Firebase.FieldValue.delete(),
          message: '',
          deletedAt: Firebase.FieldValue.now(),
          deletedBy: uid,
        });

        return true;
      } catch (error) {
        console.error(error);

        showNotification('error', 'An error occurred while deleting note.');

        return false;
      }
    },
    [docFirebaseRef, deleteAttachment, notesSubCollection, showNotification, uid]
  );

  const updateNote = useCallback(
    async (noteId: string, message: string): Promise<boolean> => {
      if (!docFirebaseRef || !uid) {
        return false;
      }

      try {
        await docFirebaseRef.collection(notesSubCollection).doc(noteId).update({
          message,
          repliesUpdatedAt: Firebase.FieldValue.now(),
          updatedBy: uid,
        });

        return true;
      } catch (error) {
        console.error(error);

        showNotification('error', 'An error occurred while saving note.');

        return false;
      }
    },
    [docFirebaseRef, notesSubCollection, showNotification, uid]
  );

  const addReply = useCallback(
    async (noteId: string, message: string) => {
      if (!docFirebaseRef || !uid || !noteId) {
        return false;
      }

      try {
        const ref = await docFirebaseRef
          .collection(notesSubCollection)
          .doc(noteId)
          .collection(Collections.replies)
          .add({
            uid,
            createdAt: Firebase.FieldValue.now(),
            message,
          });

        await docFirebaseRef.collection(notesSubCollection).doc(noteId).update({
          repliesUpdatedAt: Firebase.FieldValue.now(),
        });

        if (docFirebaseRef.path.startsWith(Collections.leads)) {
          //set lastNoteInfo for leads
          const noteReplySnap = await ref.get();
          if (noteReplySnap.exists) {
            const noteReply = noteReplySnap.data() as NoteReply;
            await setLastNoteInfo(noteId, noteReplySnap.id, noteReply.createdAt);
          }
        }

        return true;
      } catch (error) {
        console.error(error);

        showNotification('error', 'An error occurred while saving reply to a note.');

        return false;
      }
    },
    [docFirebaseRef, notesSubCollection, showNotification, uid, setLastNoteInfo]
  );

  const deleteReply = useCallback(
    async (noteId: string, replyId: string) => {
      if (!docFirebaseRef || !uid || !noteId || !replyId) {
        return false;
      }

      try {
        await docFirebaseRef
          .collection(notesSubCollection)
          .doc(noteId)
          .collection(Collections.replies)
          .doc(replyId)
          .update({
            message: '',
            deletedAt: Firebase.FieldValue.now(),
            deletedBy: uid,
          });

        await docFirebaseRef.collection(notesSubCollection).doc(noteId).update({
          repliesUpdatedAt: Firebase.FieldValue.now(),
        });

        return true;
      } catch (error) {
        console.error(error);

        showNotification('error', 'An error occurred while saving reply to a note.');

        return false;
      }
    },
    [docFirebaseRef, notesSubCollection, showNotification, uid]
  );

  const updateReply = useCallback(
    async (noteId: string, replyId: string, message: string) => {
      if (!docFirebaseRef || !uid || !noteId || !replyId) {
        return false;
      }

      try {
        await docFirebaseRef
          .collection(notesSubCollection)
          .doc(noteId)
          .collection(Collections.replies)
          .doc(replyId)
          .update({
            message,
            updatedAt: Firebase.FieldValue.now(),
            updatedBy: uid,
          });

        await docFirebaseRef.collection(notesSubCollection).doc(noteId).update({
          repliesUpdatedAt: Firebase.FieldValue.now(),
        });

        return true;
      } catch (error) {
        console.error(error);

        showNotification('error', 'An error occurred while updating reply.');

        return false;
      }
    },
    [docFirebaseRef, notesSubCollection, showNotification, uid]
  );

  useEffect(() => {
    if (!docFirebaseRef) {
      return;
    }

    const unsubscribe = docFirebaseRef
      .collection(notesSubCollection)
      .orderBy('createdAt', 'asc')
      .onSnapshot(
        async snapshot => {
          if (snapshot.size === 0) {
            setNotes({promised: false});
            return;
          }

          const nextNotes: NoteView[] = [];
          for (const note of snapshot.docs) {
            const repliesSnapshot = await docFirebaseRef
              .collection(notesSubCollection)
              .doc(note.id)
              .collection(Collections.replies)
              .orderBy('createdAt', 'asc')
              .get();

            const replies: NoteReply[] = repliesSnapshot.docs.map(reply => {
              return selectNoteReplyView(reply as UniversalSnapshot<NoteReply>);
            });

            nextNotes.push({...selectNoteView(note as UniversalSnapshot<Note>), replies});
          }

          setNotes({promised: false, data: nextNotes});
        },
        err => {
          showNotification('error', err.message);
          setNotes({promised: false, error: err});
        }
      );
    return () => {
      unsubscribe();
    };
  }, [docFirebaseRef, notesSubCollection, showNotification, uid]);

  return {
    notes,
    addNote,
    addReply,
    deleteAttachment,
    deleteNote,
    deleteReply,
    updateNote,
    updateReply,
  } as const;
};
