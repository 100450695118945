import {Link, Stack, TextField} from '@mui/material';
import {useEffect, useState} from 'react';
import {generatePath} from 'react-router-dom';
import {Collections, Firebase, Lead, LeadView, selectLeadView, UniversalSnapshot} from '../..';
import {SALES_LEADS_DETAILS_ROUTE} from '../../constants/routes';

interface Props {
  leadId: string | undefined;
}
export const CalendarEventFormLeadData = ({leadId}: Props) => {
  const [lead, setLead] = useState<Lead | undefined>();

  useEffect(() => {
    if (!leadId) {
      return;
    }

    getLead(leadId).then(lead => {
      setLead(lead);
    });
  }, [leadId]);

  if (!leadId) {
    return null;
  }

  const leadProfileRoute = generatePath(SALES_LEADS_DETAILS_ROUTE, {leadId: leadId});

  return (
    <>
      <Stack direction="row" spacing={2}>
        <TextField
          fullWidth
          label="Lead First Name"
          variant="standard"
          disabled
          value={lead?.firstName ?? ''}
        />

        <TextField
          id="standard-password-input"
          fullWidth
          label="Lead Last Name"
          variant="standard"
          disabled
          value={lead?.lastName ?? ''}
        />
      </Stack>

      <TextField
        fullWidth
        label="Business Name"
        variant="standard"
        disabled
        value={lead?.businessName ?? ''}
      />

      <Link href={leadProfileRoute} target="_blank" rel="noreferrer">
        Sales Lead Profile
      </Link>
    </>
  );
};

const getLead = async (leadId: string): Promise<LeadView | undefined> => {
  const snapshot = await Firebase.firestore.collection(Collections.leads).doc(leadId).get();
  if (!snapshot.exists) {
    return undefined;
  }

  return selectLeadView(snapshot as UniversalSnapshot<Lead>);
};
