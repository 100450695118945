import {PortfolioStatistics} from '@ozark/functions/src/functions/express/private/types/Reports';
import {Column} from '../../api/Column';
import {Table} from '../Table';

type DataRow = {
  id: string;
  title: string;
  [monthYear: string]: string;
};

type Props = {
  portfolioStatistics: PortfolioStatistics;
  flat?: boolean;
};

const formatDataForTable = (portfolioStatistics: PortfolioStatistics) => {
  // first row will have : title | month 1 | month 2 | month n
  const midsAddedRowInitial: DataRow = {title: 'MIDs Added', id: 'mids-added'};
  const midsAddedRow: DataRow = portfolioStatistics.yearlySummary.reduce((accumulator, summary) => {
    accumulator[summary.monthYear] = summary.boardedCount.toString();
    return accumulator;
  }, midsAddedRowInitial);

  const midsForPast12Months = portfolioStatistics.yearlySummary.reduce(
    (accumulator, summary) => accumulator + Number(summary.boardedCount),
    0
  );

  const startingMidTotal = portfolioStatistics.totalMids - midsForPast12Months;

  let runningTotal: number = startingMidTotal;
  const numberOfMidsRowInitial: DataRow = {
    id: 'mids-total',
    title: 'Number of MIDs',
  };

  const numberOfMidsRow: DataRow = portfolioStatistics.yearlySummary.reduce(
    (accumulator, summary) => {
      runningTotal += Number(summary.boardedCount);
      accumulator[summary.monthYear] = String(runningTotal);
      return accumulator;
    },
    numberOfMidsRowInitial
  );

  const percentAddedRowInitial: DataRow = {title: 'Percent Added', id: 'mids-percent-add'};
  const percentAddedRow = portfolioStatistics.yearlySummary.reduce((accumulator, summary) => {
    const totalNumberOfMidsForMonth = Number(numberOfMidsRow[summary.monthYear]);
    if (totalNumberOfMidsForMonth) {
      accumulator[summary.monthYear] = (
        Number(midsAddedRow[summary.monthYear]) / totalNumberOfMidsForMonth
      ).toLocaleString(undefined, {style: 'percent', minimumFractionDigits: 2});
    } else {
      accumulator[summary.monthYear] = '0.00%';
    }

    return accumulator;
  }, percentAddedRowInitial);

  return [midsAddedRow, percentAddedRow, numberOfMidsRow];
};

// Transpose data because each column contains month year.
// First column contains empty space, that's where each label go
const formatDataForColumns = (portfolioStatistics: PortfolioStatistics): Column<DataRow>[] => {
  const result = [
    {id: 'title', numeric: false, sortable: false, label: ''},
    ...portfolioStatistics.yearlySummary.map(row => ({
      id: row.monthYear,
      numeric: false,
      sortable: false,
      label: row.monthYear,
    })),
  ];

  return result;
};

export const PortfolioTable = ({portfolioStatistics, flat}: Props) => {
  return (
    <Table
      columns={formatDataForColumns(portfolioStatistics)}
      rows={formatDataForTable(portfolioStatistics)}
      sort={[['monthYear', 'DESC']]}
      flat={flat}
    />
  );
};
