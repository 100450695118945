import {Box, Chip, Grid, Tooltip, Typography} from '@mui/material';
import {isBefore} from 'date-fns';
import {useState} from 'react';
import {CalendarEvent} from '../../../..';
import {CalendarEventForm} from '../../../Calendar/CalendarEventForm';
import {calendarEventUpdate} from '../../../Calendar/utils';
import {useIsEditingDisabled} from '../../hooks/useIsEditingDisabled';

type Props = {
  calendarEvent: CalendarEvent;
};

export const TicketCalendarEventInfo = ({calendarEvent: calendarEvent}: Props) => {
  const {isEditingDisabledStatus} = useIsEditingDisabled();
  const [open, setOpen] = useState(false);

  const isMeetingOccured = calendarEvent.dateTime && isBefore(calendarEvent.dateTime, new Date());

  return (
    <>
      <Box pt={0.5} pb={1}>
        <Tooltip
          placement="right-start"
          title={
            <>
              <Typography color="inherit">Event Details:</Typography>
              <Grid container spacing={0.5} sx={{mt: 0.2}} width={250}>
                <Grid item md={4}>
                  <b>Title:</b>
                </Grid>
                <Grid item md={8}>
                  {calendarEvent.subject}
                </Grid>
                <Grid item md={4}>
                  <b>DateTime:</b>
                </Grid>
                <Grid item md={8}>
                  {formatDate(calendarEvent.dateTime)}
                </Grid>
                <Grid item md={4}>
                  <b>Participants:</b>
                </Grid>
                <Grid item md={8}>
                  <Box>
                    {calendarEvent.participants?.map(p => (
                      <div key={p.id}>{p?.name ?? 'n/a'}</div>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </>
          }
        >
          <Chip
            sx={{width: 168, color: isMeetingOccured ? '#bdbdbd' : 'primary'}}
            label={formatDate(calendarEvent.dateTime)}
            color={isMeetingOccured ? 'default' : 'primary'}
            variant="outlined"
            onClick={isEditingDisabledStatus || isMeetingOccured ? undefined : () => setOpen(true)}
          />
        </Tooltip>
      </Box>
      <CalendarEventForm
        title="Edit Reminder"
        open={open}
        setOpen={setOpen}
        onSubmit={calendarEventUpdate}
        event={calendarEvent}
      />
    </>
  );
};

const formatDate = (date?: Date) =>
  date?.toLocaleDateString('en-us', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  }) || '';
