import {RateType} from '..';

// NB! Same formula getCashDiscountRateForTsysPricing
export const getAdjustedDiscountRateBilledToMerchant = (rate: RateType, asFormatted = true) => {
  const value = rate ? (1 - 100 / (100 + Number(rate))) * 100 : null;

  return asFormatted
    ? rate
      ? `${((1 - 100 / (100 + Number(rate))) * 100).toFixed(4)}%`
      : '-'
    : value?.toFixed(4);
};
