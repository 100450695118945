import {Box, Divider, Typography} from '@mui/material';
import {TicketSearchItemModel} from '../../index';
import {BoxFlexed} from '../common';

type Props = {
  ticketSearchItem: TicketSearchItemModel;
  wideSubject?: boolean;
};

export const TicketSearchItemHeader = ({ticketSearchItem, wideSubject}: Props) => (
  <Typography variant="body2" width="100%" fontSize={14} component="div">
    <BoxFlexed justifyContent="space-between" width="100%">
      <Box width="10%" pr={1}>
        Opened
      </Box>
      <Box width="10%" pr={1}>
        Id
      </Box>
      <Divider orientation="vertical" flexItem variant="middle" />
      <Box width={wideSubject ? '46.5%' : '26%'} px={1}>
        Subject
      </Box>
      <Divider orientation="vertical" flexItem variant="middle" />
      {ticketSearchItem.labels && (
        <>
          <Box width="18%" px={1}>
            Labels
          </Box>
          <Divider orientation="vertical" flexItem variant="middle" />
        </>
      )}
      <Box width="18%" px={1} textAlign="center">
        Assignee
      </Box>
      <Divider orientation="vertical" flexItem variant="middle" />
      <Box width="18%" pl={1} textAlign="center">
        Creator
      </Box>
    </BoxFlexed>
  </Typography>
);
