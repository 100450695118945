import {TransferIntent, useCallable, useNotification} from '../../index';
import {ConfirmationDialog, ConfirmationDialogField} from '../ConfirmationDialog';

interface Props {
  emailSuggestions?: string[];
  applicationId?: string;
  validate?: () => boolean;
  onClose: () => void;
  onBeforeTransfer?: () => Promise<void>;
  onSuccess?: () => void;
}

interface TransferForm {
  email: string;
  signInPerson: boolean;
}

const confirmationFields = [
  new ConfirmationDialogField('email', 'Email Address', '', null),
  new ConfirmationDialogField('signInPerson', 'Sign In Person', false, null, 'checkbox'),
];

export const TransferToMerchantConfirmationDialogEx = ({
  emailSuggestions,
  applicationId,
  validate,
  onSuccess,
  onClose,
  onBeforeTransfer,
}: Props) => {
  const {transferApplication} = useCallable();
  const showNotification = useNotification();
  const handleSendToMerchant = async (
    email: string,
    signInPerson: boolean,
    transferIntent: TransferIntent
  ) => {
    if (validate && !validate()) {
      return;
    }
    if (!applicationId) {
      return;
    }
    try {
      if (onBeforeTransfer) {
        await onBeforeTransfer();
      }
      const transferResponse = await transferApplication({
        email: email.trim(),
        signInPerson,
        transferIntent: transferIntent,
        applicationId: applicationId,
      });

      if (
        signInPerson &&
        transferResponse.status === 'ok' &&
        transferResponse.completeApplicationLink
      ) {
        const a = document.createElement('a');
        a.setAttribute('href', transferResponse.completeApplicationLink);
        a.setAttribute('target', '_blank');
        a.setAttribute('rel', 'noopener noreferrer nofollow');
        a.click();
      }

      if (onSuccess) {
        await onSuccess();
      }

      showNotification('success', `Email successfully sent to ${email}.`);
    } catch (err) {
      console.error('Error has occurred during sending to merchant', err);
      showNotification('error', `Error sending email.`);
    }
  };

  return (
    <ConfirmationDialog
      title="Confirmation"
      message={`Where would you like to have this email sent?`}
      helperText={
        !!emailSuggestions?.length
          ? `You can use one of these emails: ${emailSuggestions?.join(', ')}`
          : ''
      }
      fields={confirmationFields}
      onClose={() => onClose()}
      onConfirm={(form: TransferForm) =>
        handleSendToMerchant(form?.email, form?.signInPerson, TransferIntent.transferApplication)
      }
    />
  );
};
