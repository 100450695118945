import FilterListIcon from '@mui/icons-material/FilterList';
import {Chip, Grid, IconButton, Typography} from '@mui/material';
import {AllMIDs, SearchCriteria, useApiContainer, useMidsContainer} from '@ozark/common';
import {Column} from '@ozark/common/api/Column';
import {
  ActiveFilter,
  Filters,
  Loading,
  Square,
  Table,
  useExportToCsv,
} from '@ozark/common/components';
import {CardImage, CardImagesDictionaryType} from '@ozark/common/components/CardImage';
import {
  PaginatedTransactionsResponse,
  Transaction,
} from '@ozark/functions/src/functions/express/private/types';
import {TsysBatch} from '@ozark/functions/src/lib/postgres/models/TsysBatch';
import {endOfDay, startOfDay, subDays} from 'date-fns';
import {format, formatInTimeZone, utcToZonedTime} from 'date-fns-tz';
import {isEmpty} from 'lodash';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Bar, Filter, MidOptionPreselected} from '../common';
import {forceActiveFilter, formatMinorUnits, useReportingPageStyles} from '../Reporting';
import {batchRejectCodes} from './batchRejectCodes';
import {TerminalActualEntryModes, TerminalEntryModesMap, TransactionFilters} from './Types';

const DefaultCriteria: SearchCriteria = {
  limit: 20, // page size
  offset: 1, // page
  order: 'desc',
  orderBy: 'originalTransactionDate',
};

export const Transactions = ({midOptionPreselected}: MidOptionPreselected) => {
  const classes = useReportingPageStyles();
  const history = useHistory<{batch: TsysBatch; mid: string}>();
  const api = useApiContainer();
  const {mids, midsOptions, selectedMid, handleSelectMid, forceSelectMidIfNeeded} =
    useMidsContainer();
  const [transactions, setTransactions] = useState<PaginatedTransactionsResponse | null>();
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>(DefaultCriteria);
  const [filters, setFilters] = useState<Filter>({});

  const exportToCsv = useExportToCsv({
    filename: `transactions-report-${selectedMid}-${searchCriteria.offset}`,
    rows: transactions?.data,
    columnsConfig,
  });

  const handleDeleteFilter = (id: string) => () => {
    const _filters = {...filters};
    delete _filters[id];
    setFilters(_filters);
  };
  const onMidSelect = (mid: string) => {
    handleSelectMid(mid);
    setSearchCriteria({...searchCriteria, offset: 1});
    const forcedFilter = forceActiveFilter(TransactionFilters, 'mid', '__eq', mid);
    setFilters({...filters, mid: forcedFilter});
  };
  const handleApplyFilter = (filter: ActiveFilter) => {
    setFilters({...filters, [filter.option.column]: filter});
  };

  useEffect(() => {
    if (mids.promised || !mids.data) return;

    forceSelectMidIfNeeded();
  }, [mids, forceSelectMidIfNeeded]);

  useEffect(() => {
    if (selectedMid === AllMIDs) return;
    const midFilter = forceActiveFilter(TransactionFilters, 'mid', '__eq', selectedMid);
    setFilters(previous => ({...previous, mid: midFilter}));
  }, [selectedMid]);

  useEffect(() => {
    const incomingFilter: Filter = {};

    if (midOptionPreselected) {
      handleSelectMid(midOptionPreselected.mid);
      incomingFilter.mid = forceActiveFilter(
        TransactionFilters,
        'mid',
        '__eq',
        midOptionPreselected.mid
      );
      incomingFilter.originalTransactionDate = forceActiveFilter(
        TransactionFilters,
        'originalDateRange',
        '__between',
        [startOfDay(subDays(new Date(), 1)), endOfDay(subDays(new Date(), 1))]
      );
    }

    const incomingBatch: TsysBatch = history.location?.state?.batch;

    if (incomingBatch) {
      incomingFilter.batchId = forceActiveFilter(
        TransactionFilters,
        'batchId',
        '__eq',
        incomingBatch.id.toString()
      );

      // we don't need a transaction date filter if we click on a batch row
      delete incomingFilter.originalTransactionDate;
    }

    if (!isEmpty(incomingFilter)) {
      setFilters(previous => ({...previous, ...incomingFilter}));
    }
  }, [midOptionPreselected, history.location]);

  useEffect(() => {
    if (isEmpty(filters)) return;
    api?.transactions
      .getTransactions(searchCriteria, selectedMid, Object.values(filters))
      .then((result: PaginatedTransactionsResponse | null) => setTransactions(result));
  }, [searchCriteria, filters]);

  if (mids.promised || !mids.data) return <Loading />;

  return (
    <div className={classes.root}>
      <Bar
        title="Transactions"
        midsLoaded
        selectedMid={selectedMid}
        mids={midOptionPreselected ? [midOptionPreselected] : midsOptions}
        onMidSelect={onMidSelect}
        disableExport={!transactions?.data.length}
        Filters={<Filters options={TransactionFilters} onApplyFilter={handleApplyFilter} />}
        exportToCsv={exportToCsv}
      />
      {isEmpty(midsOptions) && (
        <Typography className={classes.noContent}>
          There are no MIDs associated with your account
        </Typography>
      )}
      {transactions && isEmpty(transactions) && (
        <Typography align="center">No Transactions</Typography>
      )}
      {transactions && !isEmpty(transactions) && (
        <Grid container spacing={2} direction="row" alignItems="stretch">
          <Grid item xs={12}>
            {filters && !isEmpty(filters) && (
              <IconButton disabled size="large">
                <FilterListIcon />
              </IconButton>
            )}
            {filters &&
              Object.keys(filters).map(key => {
                const filter = filters[key];
                if (filter.option.type === 'monthRange' && filter.operator.id === '__between') {
                  return (
                    <Chip
                      key={`${key}-${filter.operator.id}`}
                      className={classes.chip}
                      label={
                        <span>
                          <b>{filter.option.label}</b> {filter.operator.label} '
                          <b>{format(filter.value?.[0] as Date, 'MMMM yyyy')}</b>' and '
                          <b>{format(filter.value?.[1] as Date, 'MMMM yyyy')}</b>'
                        </span>
                      }
                      variant="outlined"
                      onDelete={handleDeleteFilter(key)}
                    />
                  );
                }
                if (filter.option.type === 'month' && filter.operator.id === '__between') {
                  return (
                    <Chip
                      key={`${key}-${filter.operator.id}`}
                      className={classes.chip}
                      label={
                        <span>
                          <b>{filter.option.label}</b> {filter.operator.label} '
                          <b>{format(filter.value?.[0] as Date, 'MMMM yyyy')}</b>'
                        </span>
                      }
                      variant="outlined"
                      onDelete={handleDeleteFilter(key)}
                    />
                  );
                }
                if (filter.option.type === 'list' && filter.option.options) {
                  return (
                    <Chip
                      key={`${key}-${filter.operator.id}`}
                      className={classes.chip}
                      label={
                        <span>
                          <b>{filter.option.label}</b> {filter.operator.label} '
                          <b>{filter.option.options.find(x => x.key === filter.value)?.value}</b>'
                        </span>
                      }
                      variant="outlined"
                      onDelete={handleDeleteFilter(key)}
                    />
                  );
                }
                if (filter.option.type === 'dateOnly' && filter.operator.id === '__eq') {
                  return (
                    <Chip
                      key={`${key}-${filter.operator.id}`}
                      className={classes.chip}
                      label={
                        <span>
                          <b>{filter.option.label}</b> {filter.operator.label} '
                          <b>{formatInTimeZone(filter.value?.[0] as Date, 'UTC', 'MM/dd/yyyy')}</b>'
                        </span>
                      }
                      variant="outlined"
                      onDelete={handleDeleteFilter(key)}
                    />
                  );
                }
                if (filter.option.type === 'dateOnlyRange' && filter.operator.id === '__between') {
                  return (
                    <Chip
                      key={`${key}-${filter.operator.id}`}
                      className={classes.chip}
                      label={
                        <span>
                          <b>{filter.option.label}</b> {filter.operator.label} '
                          <b>{formatInTimeZone(filter.value?.[0] as Date, 'UTC', 'MM/dd/yyyy')}</b>'
                          and '
                          <b>{formatInTimeZone(filter.value?.[1] as Date, 'UTC', 'MM/dd/yyyy')}</b>'
                        </span>
                      }
                      variant="outlined"
                      onDelete={handleDeleteFilter(key)}
                    />
                  );
                }
                return (
                  <Chip
                    key={`${key}-${filter.operator.id}`}
                    className={classes.chip}
                    label={
                      <span>
                        <b>{filter.option.label}</b> {filter.operator.label} '
                        <b>
                          {filter.option.type === 'currency'
                            ? `$${filter.value}`
                            : filter.option.type === 'minorUnits'
                            ? `$${Number(filter.value) / 100}`
                            : `${filter.value}`}
                        </b>
                        '
                      </span>
                    }
                    variant="outlined"
                    onDelete={filter.option.force ? undefined : handleDeleteFilter(key)}
                  />
                );
              })}
          </Grid>
          <Grid item xs={2}>
            <Square lines={{'Total Transactions': transactions.totalCount}} center />
          </Grid>
          <Grid item xs={2}>
            <Square
              lines={{
                'Total Amount Authorized': formatMinorUnits(
                  transactions.sumDebitsAmount + transactions.sumRejectsAmount
                ),
              }}
              center
            />
          </Grid>
          <Grid item xs={2}>
            <Square
              lines={{
                'Total Settled Amount': formatMinorUnits(
                  transactions.sumDebitsAmount - transactions.sumCreditsAmount
                ),
              }}
              center
            />
          </Grid>
          <Grid item xs={2}>
            <Square
              lines={{
                'Total Credits': formatMinorUnits(transactions.sumCreditsAmount),
              }}
              center
            />
          </Grid>
          <Grid item xs={2}>
            <Square
              lines={{
                'Largest Transaction Amount': formatMinorUnits(transactions.maxTransactionAmount),
              }}
              center
            />
          </Grid>
          <Grid item xs={2}>
            <Square
              lines={{
                'Smallest Transaction Amount': formatMinorUnits(transactions.minTransactionAmount),
              }}
              center
            />
          </Grid>
          <Grid item xs={12}>
            <Table
              columns={columnsConfig}
              data={transactions}
              onRetrieveData={setSearchCriteria}
              paginate
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const displayActualEntryMode = (terminalEntryMode: string) =>
  Object.entries(TerminalEntryModesMap)
    .filter(([key, value]) => (value as string[]).includes(terminalEntryMode))
    .map(([key, value]) => key)?.[0] ?? TerminalActualEntryModes.Unknown;

const getFormattedDate = (dateString: string) => {
  const date = new Date(dateString);
  return format(utcToZonedTime(date, 'UTC'), 'MM/dd/yyyy');
};

const columnsConfig: Column<Transaction>[] = [
  {
    id: 'batchId',
    label: 'Batch ID',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'cardType',
    label: 'Card',
    numeric: false,
    sortable: false,
    selector: (row: Transaction) => (
      <CardImage
        cardType={row.cardType}
        dictionaryType={CardImagesDictionaryType.Transactions}
        accountNumberFirstDigit={row.accountNumberFirst6.substr(0, 1)}
      />
    ),
  },
  {
    id: 'number',
    label: 'Card Number',
    numeric: false,
    sortable: false,
    export: row => `${row.accountNumberFirst6}******${row.accountNumberLast4}`,
    selector: row => `${row.accountNumberFirst6}******${row.accountNumberLast4}`,
  },
  {
    id: 'transactionAmount',
    label: 'Amount',
    numeric: true,
    sortable: true,
    selector: row => (
      <span
        style={{
          color: row.debitCreditIndicator === 'C' ? 'red' : 'inherit',
        }}
      >
        {formatMinorUnits(row.transactionAmount * (row.debitCreditIndicator === 'C' ? -1 : 1))}
      </span>
    ),
    export: row =>
      formatMinorUnits(row.transactionAmount * (row.debitCreditIndicator === 'C' ? -1 : 1)),
  },
  {
    id: 'transactionCode',
    label: 'Type',
    numeric: false,
    sortable: false,
    selector: row => (row as any)['TransactionCode.description'],
    export: row => (row as any)['TransactionCode.description'],
  },
  {
    id: 'authorizationNumber',
    label: 'Authorization Code',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'posEntryMode',
    label: 'Entry Mode',
    numeric: false,
    sortable: false,
    export: row => displayActualEntryMode(row.posEntryMode.padStart(2, '0')),
    selector: row => displayActualEntryMode(row.posEntryMode.padStart(2, '0')),
  },
  {
    id: 'originalTransactionDate',
    label: 'Transaction Date',
    numeric: false,
    sortable: true,
    selector: row => getFormattedDate(row.originalTransactionDate),
    export: row => getFormattedDate(row.originalTransactionDate),
  },
  {
    id: 'transactionDate',
    label: 'Statement Date',
    numeric: false,
    sortable: true,
    export: row => format(utcToZonedTime(new Date(row.transactionDate), 'UTC'), 'MMMM yyyy'),
    selector: row => format(utcToZonedTime(new Date(row.transactionDate), 'UTC'), 'MMMM yyyy'),
  },
  {
    id: 'rejectReason',
    label: 'Risk Reviewed Batches',
    info: <Typography sx={{fontSize: 10}}>(Delayed Funding)</Typography>,
    numeric: false,
    sortable: false,
    export: row => batchRejectCodes[row.rejectReason] ?? '',
    selector: row => <span style={{color: 'red'}}>{batchRejectCodes[row.rejectReason]}</span>,
  },
  {
    id: 'terminalId',
    label: 'Terminal Id',
    numeric: false,
    sortable: false,
    export: true,
  },
];
