import {SearchOptions} from '@algolia/client-search';
import {Dispositions} from '@ozark/functions/src/constants';
import {IndexableApplication} from '@ozark/functions/src/documents';
import algoliasearch, {SearchClient} from 'algoliasearch';
import {useCallback, useEffect, useRef} from 'react';
import {DEFAULT_SEARCH_OPTIONS} from '../constants/search';
import {useCallable} from './useCallable';

const useApplicationSearch = (options?: Partial<SearchOptions>) => {
  const algoliaClientRef = useRef<SearchClient | null>(null);

  const {getAlgoliaSearchKey} = useCallable();

  useEffect(() => {
    if (algoliaClientRef.current) {
      return;
    }

    getAlgoliaSearchKey('applications').then(result => {
      if (result.status !== 'ok' || !result?.key) {
        return;
      }

      algoliaClientRef.current = algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID as string,
        result.key
      );
    });
  }, []);

  const search = useCallback(
    async (query: string) => {
      if (!query || !algoliaClientRef.current || !process.env.REACT_APP_ALGOLIA_INDEX_APP) {
        return null;
      }

      try {
        const algoliaIndex = algoliaClientRef.current.initIndex(
          process.env.REACT_APP_ALGOLIA_INDEX_APP
        );

        return algoliaIndex.search<IndexableApplication>(query, {
          ...DEFAULT_SEARCH_OPTIONS,
          filters: Object.values(Dispositions)
            .map(disposition => `disposition:"${disposition}"`)
            .join(' OR '),
          ...options,
        });
      } catch (error) {
        console.error('Application search error', error);

        return null;
      }
    },
    [options]
  );

  return {search} as const;
};

export {useApplicationSearch};
