import {ApiBase} from '@ozark/common';
import {
  CreateLinkTokenRequest,
  InstantIdQandAContinueRequest,
  instantIdQandAContinueResponse,
  InstantIdQandAInitRequest,
  InstitutionsSearchResponse,
  LinkTokenCreateResponse,
  PlaidInsitutionSearchRequest,
  RemovePlaidAccountRequest,
  SelectAccountRequest,
} from '@ozark/functions/src/shared';

export class ExternalServicesApi extends ApiBase {
  plaidInstitutionsSearch = (request: PlaidInsitutionSearchRequest) => {
    return this.post<InstitutionsSearchResponse>(
      'external-services/plaid/institution-search',
      request
    );
  };

  createPlaidLinkToken = (request: CreateLinkTokenRequest) => {
    return this.post<LinkTokenCreateResponse>('external-services/plaid/create-link-token', request);
  };

  selectPlaidAccount = (request: SelectAccountRequest) => {
    return this.post<SelectAccountRequest>('external-services/plaid/select-plaid-account', request);
  };
  removePlaidAccount = (request: RemovePlaidAccountRequest) => {
    return this.post<SelectAccountRequest>('external-services/plaid/remove-plaid-account', request);
  };

  instantIdQandAInit = (request: InstantIdQandAInitRequest) => {
    return this.post('external-services/lexis-nexis/instant-id-qanda-init', request);
  };

  instantIdQandAContinue = (request: InstantIdQandAContinueRequest) => {
    return this.post<instantIdQandAContinueResponse>(
      'external-services/lexis-nexis/instant-id-qanda-continue',
      request
    );
  };
}
