import {Box} from '@mui/material';
import {styled} from '@mui/styles';

export const BoxCentered = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const BoxFlexed = styled(Box)({
  display: 'flex',
});
