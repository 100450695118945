import {SearchableTokens} from '@ozark/functions/src/constants';

export type MerchantAgentInfo = {
  group: string;
  agent: string;
};

export type ApplicationInfo = {
  applicationId: string;
  merchant: string;
  email: string;
  customerServiceEmail: string;
  phoneNumber: string;
  mid: string;
  agents: MerchantAgentInfo[];
  isRegistered: boolean;
  merchantUid?: string;
  invalidMerchantRegAttempts: number;
};

type MerchantActivity = {
  processedActively: boolean;
  processingStoped: boolean;
};

export type MerchantActivityMap = Record<string, MerchantActivity>;

export type MerchantInfoAndActivity = ApplicationInfo & Partial<MerchantActivity>;
export type SearchableMerchantInfoAndActivity = MerchantInfoAndActivity & SearchableTokens;

export type MerchantsActivityTableConfig = {
  data: MerchantInfoAndActivity[];
  limit: number;
  offset: number;
  sort: [string, 'ASC' | 'DESC'][];
  totalCount: number;
};

export enum MerchantActivityEnum {
  ProccessedActively = 'proccessingActive',
  ProcessingStoped = 'processingStoped',
}

export const getAgentsInfo = (agents: MerchantAgentInfo[]) =>
  agents.map(x => `${x.group} (${x.agent || 'No Assigned Agent'})`).join('; ');
