import {CircularProgress} from '@mui/material';
import {BoxCentered} from '../../common';

export const DataIsLoading = () => {
  return (
    <BoxCentered height="100%" pb={3}>
      <CircularProgress />
    </BoxCentered>
  );
};
