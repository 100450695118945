export const OnlineAppPricingIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g fill={'inherit'}>
        <g>
          <g>
            <path
              d="M377,105L279.1,7c-4.5-4.5-10.6-7-17-7H256v112c0,8.8,7.2,16,16,16h112v-6.1C384,115.6,381.5,109.5,377,105z
				"
            />
          </g>
          <g>
            <path
              d="M144.5,320c35.3,0,64-28.7,64-64s-28.7-64-64-64s-64,28.7-64,64S109.2,320,144.5,320z M189.3,352h-5
				c-25.5,10.7-54.2,10.7-79.7,0h-5c-37,0-67.1,25.8-67.1,57.6v19.2c0,10.6,10,19.2,22.4,19.2h179.2c12.4,0,22.4-8.6,22.4-19.2
				v-19.2C256.5,377.8,226.4,352,189.3,352z"
            />
          </g>
        </g>
        <path
          opacity={0.4}
          d="M272,128c-8.8,0-16-7.2-16-16V0H24C10.8,0,0,10.7,0,23.9V488c0,13.2,10.7,24,23.9,24H360
		c13.2,0,24-10.7,24-23.9V128H272z M144.5,192c35.3,0,64,28.7,64,64c0,35.3-28.7,64-64,64c-35.3,0-64-28.7-64-64
		C80.5,220.7,109.2,192,144.5,192z M256.5,428.8c0,10.6-10,19.2-22.4,19.2H54.9c-12.4,0-22.4-8.6-22.4-19.2v-19.2
		c0-31.8,30.1-57.6,67.1-57.6h5c25.5,10.7,54.2,10.7,79.7,0h5c37.1,0,67.2,25.8,67.2,57.6V428.8z M309.2,374.9v12.7
		c0,3.5-2.9,6.3-6.4,6.3h-12.7c-3.5,0-6.3-2.8-6.4-6.3v-12.8c-9-0.5-17.7-3.6-25-8.9c-0.3-0.2-0.5-0.4-0.8-0.7
		c-2.4-2.5-2.2-6.4,0.3-8.8l9.4-8.8c2.2-2,5.6-2.2,8-0.6c3.1,1.9,6.6,2.9,10.2,2.9h22.3c5.2,0,9.4-4.6,9.4-10.4c0-4.7-2.9-8.8-7-10
		l-35.8-10.6c-14.8-4.4-25.1-18.4-25.1-34.2c0-19.3,15.1-34.9,34-35.5v-12.8c0-3.5,2.9-6.3,6.4-6.3h12.7c3.5,0,6.3,2.8,6.4,6.3v12.8
		c9,0.5,17.7,3.6,25,8.9c0,0,0,0,0,0c2.7,2.1,3.2,6,1.1,8.8c-0.2,0.3-0.4,0.5-0.7,0.7l-9.3,8.9c-2.2,2-5.6,2.2-8,0.5
		c-3.1-1.9-6.6-2.9-10.2-2.9h-22.3c-5.2,0-9.4,4.6-9.4,10.4c0,4.7,2.9,8.8,7,10l35.8,10.6c14.8,4.4,25.1,18.4,25.1,34.2
		C343.2,358.7,328,374.4,309.2,374.9z"
        />
        <path
          d="M318.1,305.2l-35.8-10.6c-4.1-1.2-7-5.3-7-10c0-5.7,4.2-10.4,9.4-10.4h22.3c3.6,0,7.1,1,10.2,2.9
		c2.5,1.7,5.8,1.5,8-0.5l9.3-8.9c0.2-0.2,0.5-0.5,0.7-0.7c2.1-2.7,1.6-6.6-1.1-8.8c0,0,0,0,0,0c-7.3-5.3-16-8.4-25-8.9v-12.8
		c0-3.5-2.9-6.3-6.4-6.3h-12.7c-3.5,0-6.4,2.8-6.4,6.3v12.8c-18.8,0.5-34,16.2-34,35.5c0,15.7,10.3,29.8,25.1,34.2l35.8,10.6
		c4.1,1.2,7,5.3,7,10c0,5.8-4.2,10.4-9.4,10.4h-22.3c-3.6,0-7.1-1-10.2-2.9c-2.5-1.7-5.8-1.5-8,0.6l-9.4,8.8
		c-2.5,2.4-2.6,6.3-0.3,8.8c0.2,0.3,0.5,0.5,0.8,0.7c7.3,5.3,16,8.4,25,8.9v12.8c0,3.5,2.9,6.3,6.4,6.3h12.7c3.5,0,6.3-2.8,6.4-6.3
		v-12.7c18.8-0.4,34-16.2,34.1-35.5C343.3,323.6,332.9,309.6,318.1,305.2z"
        />
      </g>
    </svg>
  );
};
