export type apPmPeriodType = 'AM' | 'PM';
export type amPm = {
  hour: number;
  amPmPeriod: apPmPeriodType;
};

export const hh24toAmPm = (hh24: number): amPm => {
  let hour = hh24;
  if (hour === 0 || hour === 12) {
    hour = hour + 12;
  }
  let amPm: apPmPeriodType = 'AM';
  if (hour > 12) {
    hour = hour - 12;
    amPm = 'PM';
  }
  return {
    hour: hour,
    amPmPeriod: amPm,
  };
};

export const amPmToHh24 = (amPmHour: amPm): number => {
  let hour24 = amPmHour.amPmPeriod === 'AM' ? amPmHour.hour : amPmHour.hour + 12;
  if (hour24 === 12 || hour24 === 24) {
    //12am is 00:00 and 12pm is 12:00
    hour24 = hour24 - 12;
  }
  return hour24;
};
