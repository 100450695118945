import {yupResolver} from '@hookform/resolvers/yup';
import {Box, Button, Container, CssBaseline, FormControl, Paper, TextField} from '@mui/material';
import {
  Firebase,
  getSetPasswordShape,
  useCallable,
  useNotification,
  useSetPassword,
} from '@ozark/common';
import {Copyright, InputPasswordAdornment, PasswordCheckList} from '@ozark/common/components';
import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {useHistory, useLocation} from 'react-router';
import * as yup from 'yup';

// https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#confirmpasswordreset
const ResetPasswordErrorCode = {
  'auth/expired-action-code':
    'Password link has expired. Please reset your password to receive a new secure link.',
  'auth/invalid-action-code':
    'Password link is broken. Please reset your password to receive a new secure link.',
  'auth/user-disabled': 'User has been disabled.',
  'auth/user-not-found': 'User not found.',
  'auth/weak-password': 'New password is not strong enough.',
} as const;

type SetPasswordFormInput = {password: string; passwordConfirmation: string};
const schema = yup
  .object()
  .shape(getSetPasswordShape<SetPasswordFormInput>('password', 'passwordConfirmation'));

interface Props {
  groupName?: string;
  groupDomain?: string;
  groupLogoUrl?: string;
  loginPageUrl: string;
}

export const ResetPassword = ({groupName, groupDomain, groupLogoUrl, loginPageUrl}: Props) => {
  const history = useHistory();
  const location = useLocation();

  const {sendAgentLogInEmail, sendMerchantLogInEmail} = useCallable();

  const showNotification = useNotification();

  const {
    register,
    handleSubmit,
    watch,
    formState: {errors, dirtyFields},
  } = useForm<SetPasswordFormInput>({
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  const {
    showPassword,
    showConfirmation,
    displayIsValidPassword,
    displayPasswordCheckList,
    handleClickShowPassword,
    handleClickShowConfirmation,
    passwordValue,
    passwordConfirmationValue,
  } = useSetPassword<SetPasswordFormInput>({
    watch,
    passwordFieldName: 'password',
    passwordConfirmationFieldName: 'passwordConfirmation',
    dirtyFields,
  });

  const parseQueryString = useCallback(() => {
    const query = new URLSearchParams(location.search);

    const agentUid = query.get('agentUid');

    const merchantUid = query.get('merchantUid');

    const encodedUrl = query.get('resetUrl');
    if (!encodedUrl) {
      throw Error('Invalid encoded URL.');
    }

    const url = decodeURIComponent(encodedUrl);

    const segments = url.split('?');
    const params = new URLSearchParams(`?${segments[1]}`);
    const code = params.get('oobCode');

    return {agentUid, code, merchantUid};
  }, [location.search]);

  const handleResetPassword = async (data: {password: string}) => {
    try {
      const {agentUid, code, merchantUid} = parseQueryString();

      if (!code) {
        throw Error('Could not get code from URL');
      }

      await Firebase.auth.confirmPasswordReset(code, data.password);

      // custom logic for agents
      if (agentUid) {
        const result = await sendAgentLogInEmail(agentUid);

        if (result.status === 'error' && !!result.message) {
          throw Error(result.message);
        }
      }

      // custom logic for merchants
      if (merchantUid) {
        const result = await sendMerchantLogInEmail(merchantUid);

        if (result.status === 'error' && !!result.message) {
          throw Error(result.message);
        }
      }

      showNotification('success', 'Password has been successfully changed');

      history.push(loginPageUrl);
    } catch (error: any) {
      console.error('An error occurred while resetting password', error);

      const firebaseErrorCode = error.code as keyof typeof ResetPasswordErrorCode | undefined;

      if (!!firebaseErrorCode && ResetPasswordErrorCode[firebaseErrorCode]) {
        showNotification('error', ResetPasswordErrorCode[firebaseErrorCode]);
      } else if (error.message) {
        // dispatched callable error
        showNotification('error', error.message);
      } else {
        // unknown error
        showNotification('error', 'An error occurred while resetting password.');
      }
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Paper
        sx={{
          marginTop: theme => theme.spacing(8),
          padding: theme => theme.spacing(4),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          sx={{
            margin: theme => theme.spacing(2),
            width: 300,
          }}
          src={groupLogoUrl}
          alt="Sign in"
        />
        <FormControl
          sx={{
            width: '100%', // Fix IE 11 issue.
            marginTop: theme => theme.spacing(1),
          }}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            data-test="password"
            {...register('password')}
            autoComplete="password"
            helperText={errors.password?.message}
            InputProps={{
              endAdornment: (
                <InputPasswordAdornment
                  showPassword={showPassword}
                  displayIsValidPassword={displayIsValidPassword}
                  handleClickShowPassword={handleClickShowPassword}
                />
              ),
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Confirm Password"
            type={showConfirmation ? 'text' : 'password'}
            id="passwordConfirmation"
            data-test="password"
            {...register('passwordConfirmation')}
            helperText={errors.passwordConfirmation?.message}
            InputProps={{
              endAdornment: (
                <InputPasswordAdornment
                  showPassword={showConfirmation}
                  displayIsValidPassword={displayIsValidPassword}
                  handleClickShowPassword={handleClickShowConfirmation}
                />
              ),
            }}
          />
          {displayPasswordCheckList && (
            <PasswordCheckList password={passwordValue} confirmation={passwordConfirmationValue} />
          )}
          <Button
            type="submit"
            data-test="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              margin: theme => theme.spacing(3, 0, 2),
            }}
            onClick={handleSubmit(handleResetPassword)}
          >
            Set New Password
          </Button>
        </FormControl>
      </Paper>
      <Box mt={8}>
        {!!groupName && !!groupDomain && (
          <Copyright groupName={groupName} groupDomain={groupDomain} />
        )}
      </Box>
    </Container>
  );
};
