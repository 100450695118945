import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Box, Chip, Menu, MenuItem, Tooltip} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {useState} from 'react';
import {TimeZoneInfo, TimeZoneTitle} from './useTimeZoneByMerchant';

enum TimeZoneColor {
  Custom = '#bbdefb',
  BrowserFallback = '#ffcdd2',
  Merchant = '#c8e6c9',
}

type Props = {
  timeZoneInfo: TimeZoneInfo;
  setTimeZone: (timeZoneName: TimeZoneTitle) => void;
  merchantTimeZoneExists: boolean;
};

export const TimeZonedDate = ({timeZoneInfo, setTimeZone, merchantTimeZoneExists}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleSelect = (item: TimeZoneTitle) => {
    setAnchorEl(null);
    setTimeZone(item);
  };

  const handleDelete = () => {
    setTimeZone(TimeZoneTitle.MerchantTimezone);
  };

  if (!timeZoneInfo) return null;

  const timeZoneAbbrv = new Date()
    .toLocaleTimeString('en-us', {timeZoneName: 'short', timeZone: timeZoneInfo.timeZoneId})
    .split(' ')[2];

  const isDefaultTimeZone = [
    TimeZoneTitle.MerchantTimezone,
    TimeZoneTitle.BrowserTimezone,
  ].includes(timeZoneInfo.timeZoneTitle);
  const isDeletable = merchantTimeZoneExists
    ? timeZoneInfo.timeZoneTitle !== TimeZoneTitle.MerchantTimezone
    : ![TimeZoneTitle.MerchantTimezone, TimeZoneTitle.BrowserTimezone].includes(
        timeZoneInfo.timeZoneTitle
      );

  const getTimezoneColor = () => {
    if (timeZoneInfo.timeZoneTitle === TimeZoneTitle.MerchantTimezone)
      return TimeZoneColor.Merchant;
    if (timeZoneInfo.timeZoneTitle === TimeZoneTitle.BrowserTimezone && !merchantTimeZoneExists) {
      return TimeZoneColor.BrowserFallback;
    }
    return TimeZoneColor.Custom;
  };

  const getTimezoneHint = () => {
    if (
      ![TimeZoneTitle.MerchantTimezone, TimeZoneTitle.BrowserTimezone].includes(
        timeZoneInfo.timeZoneTitle
      )
    ) {
      return `User Selected Timezone: ${timeZoneInfo.timeZoneTitle}`;
    }
    return timeZoneInfo.timeZoneTitle;
  };

  return (
    <>
      <span onClick={handleOpen} style={{minWidth: 95}}>
        <Tooltip title={getTimezoneHint()} placement="bottom-end" enterDelay={300}>
          <ChipStyled
            color="default"
            style={{backgroundColor: getTimezoneColor()}}
            label={
              <ChipLabel label={isDefaultTimeZone ? timeZoneAbbrv : timeZoneInfo.timeZoneTitle} />
            }
            onDelete={isDeletable ? handleDelete : undefined}
            clickable
          />
        </Tooltip>
      </span>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem disabled divider>
          Select Time Zone
        </MenuItem>
        {Object.values(TimeZoneTitle).map(i => (
          <MenuItem
            key={i}
            onClick={() => handleSelect(i)}
            selected={i === timeZoneInfo.timeZoneTitle}
            disabled={i === TimeZoneTitle.MerchantTimezone && !merchantTimeZoneExists}
          >
            {i}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const ChipStyled = withStyles({
  label: {
    paddingRight: 7,
  },
})(Chip);

const ChipLabel = ({label}: {label: string}) => {
  return (
    <Box display="flex" alignItems="center">
      <span>{label}</span>
      <KeyboardArrowDownIcon style={{marginLeft: -2}} fontSize="small" />
    </Box>
  );
};
