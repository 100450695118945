import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {IconButton, Link, Paper, Typography, useMediaQuery, useTheme} from '@mui/material';
import {GroupRole, useMerchant} from '@ozark/common';
import {Loading, MerchantEdit, Title} from '@ozark/common/components';
import {useHistory, useParams} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';

const MerchantDetails = () => {
  const history = useHistory();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('lg'));
  const {id} = useParams<{id: string}>();
  const {authProfile, isUserMerchant} = useStore();

  const {
    document: {data: merchant},
  } = useMerchant(id);

  const canEdit =
    authProfile.data?.isGroupOwner ||
    authProfile.data?.role === GroupRole.administrator ||
    (isUserMerchant() && merchant?.masterUid === authProfile.data?.id);

  if (!merchant || !authProfile.data) return <Loading />;

  return (
    <div>
      <Title
        breadcrumbs={
          smDown
            ? [
                <IconButton onClick={() => history.goBack()} size="large">
                  <ChevronLeftIcon />
                </IconButton>,
              ]
            : [
                <Link
                  component="button"
                  variant="body1"
                  onClick={() => history.push(ROUTES.MERCHANTS)}
                >
                  Merchants
                </Link>,
                <Typography variant="body1">{`${merchant.firstName || ''} ${
                  merchant.lastName || ''
                }`}</Typography>,
              ]
        }
      ></Title>
      <Paper>
        <MerchantEdit
          merchantId={merchant.id}
          isAuthUserAdmin={canEdit}
          userGroupId={authProfile.data?.group?.id || authProfile.data?.groupId}
          applicationMids={isUserMerchant() ? authProfile.data?.applicationMids || [] : undefined}
        />
      </Paper>
    </div>
  );
};

export default MerchantDetails;
