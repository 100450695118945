import {FC, MouseEvent, useState} from 'react';
import {AssigneeEditorDialog} from './AssigneeEditorDialog';

interface AssigneeEditorProps {
  buttonComponent: FC<{disabled?: boolean; onClick: (event: MouseEvent<HTMLElement>) => void}>;
  disabled?: boolean;
  warningMessage?: string;
}

export const AssigneeEditor = ({
  buttonComponent: ToggleButton,
  disabled,
  warningMessage,
}: AssigneeEditorProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <ToggleButton disabled={disabled} onClick={() => setOpen(true)} />

      <AssigneeEditorDialog open={open} setOpen={setOpen} warningMessage={warningMessage} />
    </div>
  );
};
