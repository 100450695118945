import {ViewBase} from '.';
import {createAuditNonce} from '../lib/audit/createAuditNonce';
import {UniversalTimestamp} from './compat';
import {DocumentBase, DocumentTypes} from './DocumentBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export enum AuditActions {
  Call = 'Call',
  Update = 'Update',
}

export type AuditReadOnly = DocumentBase & {
  uid: string | null;
  action: string;
  resource?: string;
  documentId?: string;
  collection?: string;
  meta?: any;
  createdAt: UniversalTimestamp;
};

// Metadata expected for Update action
export type UpdateMetaType<TDocument extends DocumentTypes> = {
  snapshots: {
    before?: TDocument;
    after?: TDocument;
  };
  delta?: {
    before?: Partial<TDocument>;
    after?: Partial<TDocument>;
  };
};

export type AuditBase<TAuditAction extends AuditActions, TMeta> = {
  uid: string | null;
  documentId?: string;
  resource?: string;
  collection?: string;
  action: TAuditAction;
  meta?: TMeta;
  createdAt: UniversalTimestamp;
};

type AuditDocumentUpdateBase<TDocument extends DocumentTypes> = AuditBase<
  AuditActions.Update,
  UpdateMetaType<TDocument>
>;

export type AuditUpdate = AuditDocumentUpdateBase<DocumentTypes>;

export type AuditCall = AuditBase<AuditActions.Call, {}>;

export type AuditRecordTypes = AuditUpdate;

export type AuditNonce = {uuid: string; uid: string};

export type AuditableDocument = DocumentBase & {auditNonce?: AuditNonce};

export const refreshAuditNonce = (doc: AuditableDocument, authUid: string) => ({
  ...doc,
  auditNonce: createAuditNonce(authUid),
});

export type AuditView = ViewBase<AuditReadOnly>;

export const selectAuditView: ViewSelector<AuditView, AuditReadOnly> = snapshot => {
  const audit: AuditView = {
    ...defaultViewSelector(snapshot),
  };
  return audit;
};
