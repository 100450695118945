import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
  AgentSubCollection,
  ApplicationSubCollection,
  Collections,
  LeadSubCollection,
} from '@ozark/functions/src/constants';
import {NoteView} from '@ozark/functions/src/documents';
import clsx from 'clsx';
import firebase from 'firebase/compat';
import {useCallback, useEffect, useMemo, useState} from 'react';
import RichTextEditor from 'react-rte';
import {useNotes, useUserInfo} from '../../hooks';
import {StackAttachment} from '../StackOfAttachments/types';
import {isRteValueEmpty} from './helpers';
import {useEditorWrapperStyles} from './hooks/useEditorWrapperStyles';
import {Message} from './Message';
import {NotesUsersProfiles} from './types';
import {useNoteContext} from './utils/NoteContext';

interface NoteItemProps {
  profiles: NotesUsersProfiles;
  firestoreDocumentRef: firebase.firestore.DocumentReference | null;
  notesSubCollection:
    | ApplicationSubCollection.uwRiskNotes
    | ApplicationSubCollection.supportNotes
    | LeadSubCollection.notes
    | AgentSubCollection.notes;
  noteView: NoteView;
}

const NoteItem = ({
  profiles,
  firestoreDocumentRef,
  notesSubCollection,
  noteView,
}: NoteItemProps) => {
  const [attachments, setAttachments] = useState<StackAttachment[]>([]);
  const [replyMessage, setReplyMessage] = useState(RichTextEditor.createEmptyValue());

  const {isErpAdmin, uid} = useUserInfo();

  const {allowAttachments, noteIdToReply, onEditClick, onReplyClick} = useNoteContext();

  const {addReply, deleteAttachment, deleteNote, deleteReply, updateNote, updateReply} = useNotes({
    docFirebaseRef: firestoreDocumentRef,
    notesSubCollection,
  });

  const editorWrapperClasses = useEditorWrapperStyles();

  const isReplyFormOpen = useMemo(
    () => noteView.id === noteIdToReply,
    [noteView.id, noteIdToReply]
  );

  const allowAddAttachments = useMemo(
    () => allowAttachments && noteView.uid === uid,
    [allowAttachments, uid, noteView.uid]
  );

  const allowDeleteAttachments = useMemo(
    () => allowAttachments && (noteView.uid === uid || isErpAdmin),
    [allowAttachments, uid, isErpAdmin, noteView.uid]
  );

  const onAttachmentRemove = useCallback(
    async (attachmentId?: string) => {
      if (!attachmentId) {
        return;
      }

      await deleteAttachment(firestoreDocumentRef, noteView.id, attachmentId);
    },
    [deleteAttachment, firestoreDocumentRef, noteView.id]
  );

  const onNoteEdit = useCallback(
    async (id: string, message: string) => {
      const isOk = await updateNote(id, message);

      if (isOk) {
        onEditClick(null);
      }

      return isOk;
    },
    [onEditClick, updateNote]
  );

  const onReplyEdit = useCallback(
    async (id: string, message: string) => {
      const isOk = await updateReply(noteView.id, id, message);

      if (isOk) {
        onEditClick(null);
      }

      return isOk;
    },
    [noteView.id, onEditClick, updateReply]
  );

  const onNoteDelete = useCallback(
    async (id: string) => {
      await deleteNote(id);
    },
    [deleteNote]
  );

  const onReplyDelete = useCallback(
    async (id: string) => {
      await deleteReply(noteView.id, id);
    },
    [deleteReply, noteView.id]
  );

  useEffect(() => {
    if (!firestoreDocumentRef || !allowAttachments || noteView.deletedAt) {
      return;
    }

    const unsubscribe = firestoreDocumentRef
      .collection(notesSubCollection)
      .doc(noteView.id)
      .collection(Collections.attachments)
      .orderBy('createdAt', 'desc')
      .onSnapshot(
        snap => {
          if (!snap.size) {
            setAttachments([]);
            return;
          }

          const result: StackAttachment[] = snap.docs.map(document => {
            const {createdAt, cloudPath, name} = document.data();

            return {
              createdAt,
              id: document.id,
              fileName: name,
              fileCloudPath: cloudPath,
            };
          });

          setAttachments(result);
        },
        err => {
          console.error('Error reading attachments: ', err);
          setAttachments([]);
        }
      );

    return () => {
      unsubscribe();
    };
  }, [allowAttachments, firestoreDocumentRef, notesSubCollection, noteView.id, noteView.deletedAt]);

  return (
    <>
      <Message
        profiles={profiles}
        allowAddAttachments={allowAddAttachments}
        allowDeleteAttachments={allowDeleteAttachments}
        attachments={attachments}
        entityView={noteView}
        firestoreDocumentRef={firestoreDocumentRef}
        onAttachmentRemove={onAttachmentRemove}
        isReplyFormOpen={isReplyFormOpen}
        onEdit={onNoteEdit}
        onDelete={onNoteDelete}
      />

      {!!noteView.replies?.length &&
        noteView.replies.map(replyView => (
          <Box ml="64px" key={replyView.id}>
            <Message
              profiles={profiles}
              entityView={replyView}
              firestoreDocumentRef={firestoreDocumentRef}
              parentNoteId={noteView.id}
              onEdit={onReplyEdit}
              onDelete={onReplyDelete}
            />
          </Box>
        ))}

      {isReplyFormOpen && (
        <div className={clsx(editorWrapperClasses.root, editorWrapperClasses.reply)}>
          <RichTextEditor
            autoFocus
            value={replyMessage}
            onChange={setReplyMessage}
            placeholder="Leave a comment"
            editorStyle={{
              minHeight: '100px',
              fontFamily: 'Rubik, sans-serif',
            }}
          />
          <Box sx={{py: 2, textAlign: 'right'}}>
            <Button
              color="primary"
              disabled={isRteValueEmpty(replyMessage)}
              size="medium"
              variant="contained"
              onClick={async () => {
                if (isRteValueEmpty(replyMessage)) {
                  return;
                }

                const isOk = await addReply(noteView.id, replyMessage.toString('html'));

                if (isOk && !!onReplyClick) {
                  setReplyMessage(RichTextEditor.createEmptyValue());
                  onReplyClick(null);
                }
              }}
            >
              Post a reply
            </Button>
          </Box>
        </div>
      )}
    </>
  );
};

export {NoteItem};
