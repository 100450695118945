import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {
  Collections,
  Firebase,
  getAssociationName,
  Ticket,
  TicketAssociation,
  TicketHistoryRecordType,
  TicketTypeAndCategory,
  useUserInfo,
} from '../../../..';
import {
  FIELD_NAME_ASSOCIATION,
  FIELD_NAME_CATEGORY,
  FIELD_NAME_SUBJECT,
} from '../../constants/constants';
import {useHistoryRecord} from '../../hooks/useHistoryRecord';
import {useIsEditingDisabled} from '../../hooks/useIsEditingDisabled';
import {useTicketCategoryAutomation} from '../../hooks/useTicketCategoryAutomation';
import {useTicketId} from '../../hooks/useTicketId';
import {saveSubject} from '../../TicketEditContent/InputSubject';
import {InputAssociationError} from '../../types';
import {getTicketSubject} from '../../utils/getTicketSubject';
import {validateAssociation} from '../../utils/validation';
import {AssociationEditorContent} from './AssociationEditorContent';

const getTicketAssociationFirestore = async (ticketId: string) => {
  const doc = await Firebase.firestore.collection(Collections.tickets).doc(ticketId).get();
  return (doc.data() as Ticket).association;
};

export const AssociationEditor = () => {
  const {isErpUser} = useUserInfo();
  const {ticketId} = useTicketId();
  const {addHistoryRecord} = useHistoryRecord();
  const {setValue, clearErrors, register, unregister, watch} = useFormContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const watchAssociation: TicketAssociation | undefined = useWatch({name: FIELD_NAME_ASSOCIATION});
  const watchSubject: string | undefined = useWatch({name: FIELD_NAME_SUBJECT});
  const watchCategory: TicketTypeAndCategory | undefined = watch(FIELD_NAME_CATEGORY);
  const [draft, setDraft] = useState<TicketAssociation | undefined>(watchAssociation);
  const [associationErrors, setAssociationErrors] = useState(
    {} as Record<InputAssociationError, string>
  );
  const {isEditingDisabled} = useIsEditingDisabled();
  const {triggerCheckCategoryAutomation} = useTicketCategoryAutomation();
  const [subjectDialogOpen, setSubjectDialogOpen] = useState(false);
  const {userDisplayName} = useUserInfo();
  const [subjectToDisplay, setSubjectToDisplay] = useState(watchSubject);

  const handleClose = () => {
    setDialogOpen(false);
    setDraft(watchAssociation); // reset draft
  };

  const getSubject = (draftAssociation: TicketAssociation | undefined): string | undefined => {
    return getTicketSubject(watchCategory, draftAssociation);
  };

  const saveNewSubject = async (ticketId: string, newSubjectString: string) => {
    setValue(FIELD_NAME_SUBJECT, newSubjectString, {shouldDirty: false});
    await saveSubject(ticketId, newSubjectString);
  };

  const saveAssociation = async (ticketId: string, draftAssociation: TicketAssociation) => {
    try {
      await Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .update({association: draftAssociation, updatedAt: Firebase.now()});

      setValue(FIELD_NAME_ASSOCIATION, draftAssociation);
      clearErrors(FIELD_NAME_ASSOCIATION);
      addHistoryRecord(
        `Association Changed to ${getAssociationName(draftAssociation).name}`,
        TicketHistoryRecordType.Association,
        undefined,
        `Association Changed to ${getAssociationName(draftAssociation, false).name}`
      );
    } catch (error) {
      console.error('Error writing document (InputGroup): ', error);
    }
  };

  const handleSubmit = useCallback(async () => {
    if (!validateAssociation(draft, setAssociationErrors, isErpUser)) {
      return;
    }

    const ticketAssociation = await getTicketAssociationFirestore(ticketId);

    saveAssociation(ticketId, draft!);
    setDialogOpen(false);

    const generatedSubject = getSubject(ticketAssociation);
    if (
      generatedSubject === watchSubject ||
      ((generatedSubject === undefined || generatedSubject === '') && watchSubject === undefined)
    ) {
      setSubjectDialogOpen(false);
      saveNewSubjectHandler();
    } else {
      setSubjectToDisplay(watchSubject);
      setSubjectDialogOpen(true);
    }
  }, [draft, setAssociationErrors, ticketId, watchSubject, saveAssociation]);

  const saveNewSubjectHandler = async () => {
    try {
      const isTicketCategoryAutomationApplied = await triggerCheckCategoryAutomation();
      if (!isTicketCategoryAutomationApplied) {
        const newSubjectString = getSubject(draft);
        if (newSubjectString) {
          await saveNewSubject(ticketId, newSubjectString);
        } else {
          throw new Error();
        }
        // Adds history record if user click 'Save' in the popup
        if (subjectDialogOpen) {
          addHistoryRecord(
            `${userDisplayName} edited Ticket Subject`,
            TicketHistoryRecordType.Subject,
            undefined,
            'Ticket Subject Change'
          );
        }
      }
    } catch (error) {
      console.error('Error writing document (InputGroup): ', error);
    } finally {
      setSubjectDialogOpen(false);
    }
  };

  useEffect(() => {
    register(FIELD_NAME_ASSOCIATION, {required: 'Association is required'});
    if (watchAssociation) {
      setValue(FIELD_NAME_ASSOCIATION, watchAssociation);
      setDraft(watchAssociation);
    }
    return () => unregister(FIELD_NAME_ASSOCIATION);
  }, [register]);

  return (
    <div>
      <IconButton onClick={() => setDialogOpen(true)} disabled={isEditingDisabled}>
        <EditIcon />
      </IconButton>
      <Dialog fullWidth maxWidth="md" open={dialogOpen} onClose={handleClose}>
        <DialogTitle>Association</DialogTitle>
        <DialogContent>
          <AssociationEditorContent
            draft={draft}
            setDraft={setDraft}
            errors={associationErrors}
            setErrors={setAssociationErrors}
          />
        </DialogContent>
        <DialogActions sx={{mt: 4, mb: 1, mx: 1}}>
          <Box>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit}>Save</Button>
          </Box>
        </DialogActions>
      </Dialog>
      {subjectDialogOpen && (
        <Dialog open={subjectDialogOpen} onClose={() => setSubjectDialogOpen(false)}>
          <DialogTitle>Would you like to update the ticket subject line?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              "{subjectToDisplay}" -{'>'} "{getSubject(draft)}"
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{mt: 4, mb: 1, mx: 1}}>
            <Box>
              <Button onClick={() => setSubjectDialogOpen(false)}>Cancel</Button>
              <Button onClick={saveNewSubjectHandler}>Save</Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};
