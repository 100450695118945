import {Typography} from '@mui/material';
import {DefaultComponentProps, OverridableTypeMap} from '@mui/material/OverridableComponent';
import {CreateDisputeReasonProps, ReasonInputType} from '@ozark/common/';
import camelcase from 'lodash/camelCase';
import RichTextEditor from 'react-rte';
import {DisputeReason} from './index';

export const createRichTextInputReason = ({
  name,
  description,
}: CreateDisputeReasonProps): DisputeReason => ({
  name: camelcase(name),
  title: name,
  inputType: ReasonInputType.RichText,
  descriptionHtml: description,
  Description: (props: DefaultComponentProps<OverridableTypeMap>) => (
    <Typography {...props}>
      <span dangerouslySetInnerHTML={{__html: description}} />
    </Typography>
  ),
  FieldInput: props => (
    <RichTextEditor
      editorStyle={{
        fontFamily: 'Rubik, sans-serif',
      }}
      {...props}
    />
  ),
});
