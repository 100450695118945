import {Avatar} from '@mui/material';

type Props = {
  name: string;
  size?: number;
};

export const AvatarFromName = ({name = 'N A', size}: Props) => {
  if (size) {
    const args = {...stringAvatar(name)};
    const argsSize = {width: size, height: size, fontSize: '1rem'};
    args.sx = {...args.sx, ...argsSize};
    return <Avatar {...args} />;
  }
  return <Avatar {...stringAvatar(name)} />;
};

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1]?.[0] ?? ''}`,
  };
}
