import AttachFileIcon from '@mui/icons-material/AttachFile';
import {Box, Button, Divider, Grow, Stack, Typography} from '@mui/material';
import {InsertAttachmentsDialog, Loading} from '@ozark/common/components';
import {useState} from 'react';
import {TicketStatus, UniversalTimestamp} from '../../..';
import {useUserInfo} from '../../../hooks/useUserInfo';
import {BoxParentHeight} from '../../common';
import {StackOfAttachments} from '../../StackOfAttachments';
import {useComments} from '../hooks/useComments';
import {useDescriptionAttachments} from '../hooks/useDescriptionAttachments';
import {useTicketStatus} from '../hooks/useTicketStatus';
import {CommentCard} from './CommentCard';
import {CommentsAddAttachment} from './CommentsAddAttachment';
import {CommentsAddComment} from './CommentsAddComment';
import {CommentsTabs2} from './CommentsTabs2';
import {InputDescription} from './InputDescription';
import {InputSubject} from './InputSubject';

export const TicketEditContent = () => {
  const [attachmentDialogOpen, setAttachmentDialogOpen] = useState(false);
  const {attachments, uploadAttachment, removeAttachment} = useDescriptionAttachments();
  const {comments, commentsCounter, commentsInternalCounter} = useComments();
  const {isPortal} = useUserInfo();
  const {status, isDraft} = useTicketStatus();

  if (!comments) {
    return <Loading />;
  }

  return (
    <Stack height="100%">
      <BoxParentHeight pr={1}>
        <Box pt={0.5} pb={1}>
          <Typography variant="body1" sx={{color: 'lightslategrey', mb: 1}}>
            Subject
          </Typography>
          <InputSubject />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="4px"
          pl={0.2}
          height={34}
        >
          <Typography variant="body1" sx={{color: 'lightslategrey'}}>
            Description
          </Typography>
          <Button
            disabled={!isDraft}
            startIcon={<AttachFileIcon />}
            component="span"
            aria-label="add attachment"
            sx={{p: '2px'}}
            color="primary"
            onClick={() => setAttachmentDialogOpen(true)}
          >
            Attach...
          </Button>
        </Box>
        <InputDescription>
          {attachments.length ? (
            <>
              <Divider />
              <Box px={1} pb={1} sx={{borderBottomLeftRadius: 4, borderBottomRightRadius: 4}}>
                <StackOfAttachments attachments={attachments} removeAttachment={removeAttachment} />
              </Box>
            </>
          ) : null}
        </InputDescription>
        <Box display="flex" justifyContent="space-between" alignItems="center" my={2} mt={3}>
          {status && status !== TicketStatus.Draft && (
            <>
              {isPortal && (
                <Typography variant="body1" sx={{color: 'lightslategrey'}}>
                  Comments
                </Typography>
              )}
              {!isPortal && (
                <CommentsTabs2
                  commentsCounter={commentsCounter}
                  commentsInternalCounter={commentsInternalCounter}
                />
              )}
              <Box>
                <CommentsAddComment />
                <CommentsAddAttachment />
              </Box>
            </>
          )}
        </Box>
        {comments?.data &&
          comments.data.map(comment => (
            <Grow in key={comment.comment.id}>
              <Box
                mb={2}
                key={(comment.comment.createdAt as UniversalTimestamp)?.toDate().toISOString()}
              >
                <CommentCard comment={comment} />
              </Box>
            </Grow>
          ))}
      </BoxParentHeight>
      {attachmentDialogOpen && (
        <InsertAttachmentsDialog
          initialFolderName={null}
          folderNamesOptions={[]}
          hideFolder
          onSubmit={uploadAttachment}
          onClose={() => setAttachmentDialogOpen(false)}
        />
      )}
    </Stack>
  );
};
