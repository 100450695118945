import {yupResolver} from '@hookform/resolvers/yup';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import {Collections, copyWithoutRef, Firebase, UniversalTimestamp} from '@ozark/common';
import {TicketTypeCategory, TicketTypeCategoryView} from '@ozark/functions/src/documents';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNotification} from '../../../hooks';
import {ConfirmationDialog} from '../../ConfirmationDialog';
import {
  TicketCategoryForm,
  TicketCategoryFormData,
  TicketCategoryFormSchema,
} from './TicketCategoryForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      [theme.breakpoints.up('md')]: {
        width: 400,
      },
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    dialogTitle: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  })
);

type Props = {
  ticketTypeId: string;
  ticketCategory?: TicketTypeCategoryView;
  onClose: () => void;
};

export const EditCategoryDialog = ({ticketTypeId, ticketCategory, onClose}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [confirmationAction, setConfirmationAction] = useState<(() => Promise<void>) | null>(null);
  const showNotification = useNotification();

  const hookForm = useForm<TicketCategoryFormData>({
    resolver: yupResolver(TicketCategoryFormSchema),
  });

  const {handleSubmit, reset} = hookForm;

  useEffect(() => {
    if (!ticketCategory) {
      return;
    }

    reset(copyWithoutRef(ticketCategory));
  }, [ticketCategory]);

  const onSuccess = async (data: TicketCategoryFormData) => {
    try {
      setLoading(true);

      if (!ticketCategory) {
        // create new
        const newTicketTypeCategory: TicketTypeCategory = {
          name: data.name,
          visibleToRoles: data.visibleToRoles,
          assignment: data.assignment,
          sla: data.sla,
          info: data.info,
          isDeleted: false,
          createdAt: Firebase.Timestamp.now() as UniversalTimestamp,
        };
        await Firebase.firestore
          .collection(Collections.ticketTypes)
          .doc(ticketTypeId)
          .collection(Collections.ticketTypeCategories)
          .add(newTicketTypeCategory);
        showNotification('success', 'The new ticket category successfully created');
      } else {
        await Firebase.firestore
          .collection(Collections.ticketTypes)
          .doc(ticketTypeId)
          .collection(Collections.ticketTypeCategories)
          .doc(ticketCategory.id)
          .update({
            name: data.name,
            visibleToRoles: data.visibleToRoles,
            assignment: data.assignment,
            sla: data.sla,
            info: data.info,
          });
        showNotification('success', 'The ticket category successfully updated');
      }

      setLoading(false);
      onClose();
    } catch (_err) {
      showNotification('error', 'Failed to create ticket category.');
      console.error(`error saving ticket category`, _err);
      setLoading(false);
    }
  };

  const onError = (data: any) => {
    console.error(`error saving ticket category ${JSON.stringify(data)}`);
    setLoading(false);
  };

  const saveIsDeleted = async () => {
    if (!ticketCategory) {
      return;
    }
    setLoading(true);

    await Firebase.firestore
      .collection(Collections.ticketTypes)
      .doc(ticketTypeId)
      .collection(Collections.ticketTypeCategories)
      .doc(ticketCategory.id)
      .update({
        isDeleted: !ticketCategory.isDeleted,
      });
    showNotification(
      'success',
      `The ticket category successfully ${ticketCategory?.isDeleted ? 'deleted' : 'restored'}`
    );

    setLoading(false);
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={onClose}
        aria-labelledby="create-dialog-title"
        fullScreen={fullScreen}
        maxWidth={'lg'}
      >
        <DialogTitle id="create-dialog-title">
          <div className={classes.dialogTitle}>
            {`${ticketCategory ? 'Edit' : 'Add New'} Ticket Category`}
            {ticketCategory && (
              <Button
                size="small"
                onClick={() => setConfirmationAction(() => saveIsDeleted)}
                variant="contained"
                color={!ticketCategory?.isDeleted ? 'error' : 'primary'}
                startIcon={!ticketCategory?.isDeleted ? <DeleteIcon /> : <RestoreIcon />}
              >
                {!ticketCategory?.isDeleted ? 'Delete' : 'Restore'}
              </Button>
            )}
          </div>
        </DialogTitle>
        <DialogContent>
          <div className={classes.content}>
            <Grid container spacing={1}>
              <TicketCategoryForm hookForm={hookForm} />
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit(onSuccess, onError)} color="primary" disabled={loading}>
            {loading && <CircularProgress className={classes.buttonProgress} size={24} />}
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        title="Confirmation"
        message={`Are you sure you want to ${
          !ticketCategory?.isDeleted ? 'delete' : 'restore'
        } this ticket category?`}
        onClose={() => setConfirmationAction(null)}
        onConfirm={confirmationAction}
      />
    </>
  );
};
