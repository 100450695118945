import {useUserInfo} from '../../../hooks';
import {TicketsListViewAllErp} from './TicketsListViewAllErp';
import {TicketsListViewAllNonErp} from './TicketsListViewAllNonErp';

export const TicketsListViewAll = () => {
  const {isErpUser} = useUserInfo();

  return (
    <>
      {isErpUser && <TicketsListViewAllErp />}
      {!isErpUser && <TicketsListViewAllNonErp />}
    </>
  );
};
