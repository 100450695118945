import {Domain, useApiClient, useGroupFromDomain} from '@ozark/common';
import {createContainer} from 'unstated-next';
import {useApplication} from '../firebase/hooks/useApplication';
import {useAuth} from '../firebase/hooks/useAuth';

const useStoreBase = () => {
  const groupStore = useGroupFromDomain(Domain.appDomain);
  const authStore = useAuth();
  const applicationStore = useApplication(authStore, groupStore);
  const apiClient = useApiClient(authStore.authUser);
  return {
    ...groupStore,
    ...authStore,
    ...applicationStore,
    ...apiClient,
  };
};

const Store = createContainer(useStoreBase);

export const useStore = () => {
  return Store.useContainer();
};

export default Store;
