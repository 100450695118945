export const Form1099Icon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
      width="24px"
      height="24px"
      {...props}
    >
      <g fill={'inherit'}>
        <path d="M377,105L279.1,7c-4.5-4.5-10.6-7-17-7H256v112c0,8.8,7.2,16,16,16h112v-6.1C384,115.6,381.5,109.5,377,105z" />
        <g>
          <ellipse cx="309.8" cy="259.3" rx="11.8" ry="11.8" opacity={0.4} />
          <path
            d="M156.7,241.8c-6,0-8.5,3.5-8.5,9.5v54.8c0,6.2,2.3,9.7,8.5,9.7s8.3-3.8,8.3-10.1V251
			C165.1,245.7,163.1,241.8,156.7,241.8z"
            opacity={0.4}
          />

          <ellipse
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -114.9115 241.1311)"
            cx="233.6"
            cy="259.3"
            rx="11.8"
            ry="11.8"
            opacity={0.4}
          />
          <path
            d="M272,128c-8.8,0-16-7.2-16-16V0H24C10.8,0,0,10.7,0,23.9l0,0V488c0,13.2,10.7,24,23.9,24l0,0H360
			c13.2,0,24-10.7,24-23.9l0,0V128H272z M115.3,324.6c0,5-4.1,9.1-9.1,9.1H51.3c-5,0-9.1-4.1-9.1-9.1v-9.1c0-5,4.1-9.1,9.1-9.1H65
			v-45.7H51.3c-1.7,0-3.4-0.5-4.8-1.4c-4.3-2.7-5.6-8.3-2.9-12.6L55,228.3c1.7-2.7,4.6-4.3,7.8-4.3h20.6c5,0,9.1,4.1,9.1,9.1v73.1
			h13.7c5,0,9.1,4.1,9.1,9.1C115.3,315.3,115.3,324.6,115.3,324.6z M192.6,303.6c0,16.6-7.9,30.1-36.6,30.1c-27.8,0-35-14-35-30
			v-49.8c0-19.3,12.5-29.9,35.9-29.9c23.5,0,35.7,10.7,35.7,29L192.6,303.6L192.6,303.6z M268.8,281.5c0,25.7-18.5,47.6-43.7,52.1
			c-0.4,0.1-0.9,0.1-1.4,0.1c-4.3,0-7.8-3.5-7.8-7.9v-8.2c0-3.7,2.5-6.9,6.1-7.6c9.2-1.9,16.9-8.2,20.7-16.9c-3,0.9-6.1,1.3-9.2,1.4
			c-19.5,0-35.3-15.8-35.3-35.3s15.8-35.3,35.3-35.3s35.2,15.8,35.3,35.3V281.5z M345.1,281.5c0,25.7-18.5,47.6-43.7,52.1
			c-0.4,0.1-0.9,0.1-1.4,0.1c-4.3,0-7.8-3.5-7.8-7.9v-8.2c0-3.7,2.5-6.9,6.1-7.6c9.2-1.9,16.9-8.2,20.7-16.9c-3,0.9-6.1,1.3-9.2,1.4
			c-19.5,0-35.3-15.8-35.3-35.3s15.8-35.3,35.3-35.3s35.2,15.8,35.3,35.3V281.5z"
            opacity={0.4}
          />
        </g>
        <g>
          <path
            d="M233.6,224c-19.5,0-35.3,15.8-35.3,35.3s15.8,35.3,35.3,35.3c3.1,0,6.2-0.5,9.2-1.4
			c-3.8,8.6-11.5,14.9-20.7,16.9c-3.6,0.8-6.1,3.9-6.1,7.6v8.2c0,4.3,3.5,7.9,7.8,7.9c0.5,0,0.9,0,1.4-0.1
			c25.3-4.5,43.7-26.4,43.7-52.1v-22.2C268.8,239.8,253,224,233.6,224z M233.6,271c-6.5,0-11.8-5.3-11.8-11.8s5.3-11.8,11.8-11.8
			s11.8,5.3,11.8,11.8C245.3,265.8,240.1,271,233.6,271z"
          />
          <path
            d="M309.8,224c-19.5,0-35.3,15.8-35.3,35.3s15.8,35.3,35.3,35.3c3.1,0,6.2-0.5,9.2-1.4
			c-3.8,8.6-11.5,14.9-20.7,16.9c-3.6,0.8-6.1,3.9-6.1,7.6v8.2c0,4.3,3.5,7.9,7.8,7.9c0.5,0,0.9,0,1.4-0.1
			c25.3-4.5,43.7-26.4,43.7-52.1v-22.2C345.1,239.8,329.3,224,309.8,224z M309.8,271c-6.5,0-11.8-5.3-11.8-11.8s5.3-11.8,11.8-11.8
			s11.8,5.3,11.8,11.8C321.6,265.8,316.3,271,309.8,271z"
          />
          <path
            d="M156.9,224c-23.4,0-35.9,10.6-35.9,29.9v49.8c0,16.1,7.1,30,35,30c28.7,0,36.6-13.5,36.6-30.1V253
			C192.6,234.7,180.5,224,156.9,224z M165.1,305.8c0,6.3-2.2,10.1-8.3,10.1s-8.5-3.5-8.5-9.7v-54.8c0-5.9,2.4-9.5,8.5-9.5
			c6.4,0,8.3,3.8,8.3,9.1V305.8z"
          />
          <path
            d="M106.2,306.3H92.4v-73.1c0-5-4.1-9.1-9.1-9.1H62.7c-3.2,0-6.1,1.6-7.8,4.3l-11.4,18.3
			c-2.7,4.3-1.4,9.9,2.9,12.6c1.5,0.9,3.1,1.4,4.8,1.4H65v45.7H51.3c-5,0-9.1,4.1-9.1,9.1v9.1c0,5,4.1,9.1,9.1,9.1h54.9
			c5,0,9.1-4.1,9.1-9.1v-9.1C115.3,310.4,111.2,306.3,106.2,306.3z"
          />
        </g>
      </g>
    </svg>
  );
};
