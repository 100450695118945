import {format, utcToZonedTime} from 'date-fns-tz';
import {DepositCpyDayView} from '../../../express/private/types';
import {DATE_FORMAT} from '../../DateUtils';
import {ReportExportColumn} from '../ReportExportColumn';

const getDepositDate = (date: Date | undefined, timeZoneId: string): string => {
  if (!date) return '';
  const dateTime = new Date(date);
  const localTime = utcToZonedTime(dateTime, timeZoneId);
  return format(localTime, DATE_FORMAT);
};

export type DepositCpyDaysColumnNamesType =
  | 'depositDate'
  | 'statementDate'
  | 'routingNumber'
  | 'ddaNumber'
  | 'depositAmount'
  | 'referenceNumber';

export const DepositColumnsConfigRecord: Record<
  DepositCpyDaysColumnNamesType,
  ReportExportColumn<DepositCpyDayView>
> = {
  depositDate: {
    id: 'depositDate',
    label: 'Deposit Date',
    export: row => getDepositDate(row.depositDate, 'UTC'),
  },
  statementDate: {
    id: 'statementDate',
    label: 'Statement Date',
    export: row =>
      row.statementDate
        ? format(utcToZonedTime(new Date(row.statementDate), 'UTC'), 'MMMM yyyy')
        : '',
  },
  routingNumber: {
    id: 'routingNumber',
    label: 'Routing Number',
    export: row => (row.routingNumber ? row.routingNumber : ''),
  },
  ddaNumber: {
    id: 'ddaNumber',
    label: 'Account Number',
    export: true,
  },
  depositAmount: {
    id: 'depositAmount',
    label: 'Deposit Amount',
    export: true,
  },
  referenceNumber: {
    id: 'referenceNumber',
    label: 'Reference number',
    export: row => row.referenceNumber ?? '',
  },
};

export const DepositExportColumnsConfig: ReportExportColumn<DepositCpyDayView>[] = [
  {
    ...DepositColumnsConfigRecord.depositDate,
  },
  {
    ...DepositColumnsConfigRecord.statementDate,
  },
  {
    ...DepositColumnsConfigRecord.routingNumber,
  },
  {
    ...DepositColumnsConfigRecord.ddaNumber,
  },
  {
    ...DepositColumnsConfigRecord.depositAmount,
  },
  {
    ...DepositColumnsConfigRecord.referenceNumber,
  },
];
