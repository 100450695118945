import {InputAdornment, List, ListItem, ListItemSecondaryAction, ListItemText} from '@mui/material';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import {AgentView, GroupView, SplitMethodType, useUserInfo} from '@ozark/common';
import {useCallback, useEffect, useMemo} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {Select} from '../Select';
import {Switch} from '../Switch';
import {TextField} from '../TextField';

export interface ApplicationResidualOverrideFormModel {
  groupId: string;
  agentId: string;
  splitPercent: number;
  hideVisibility: boolean;
}

interface Props {
  hookForm: UseFormReturn<ApplicationResidualOverrideFormModel>;
  isAssignedAgent?: boolean;
  agents?: AgentView[];
  groups?: GroupView[];
}

export const ApplicationResidualOverrideForm = ({
  hookForm,
  isAssignedAgent,
  agents,
  groups,
}: Props) => {
  const {isErpUser} = useUserInfo();
  const {
    formState: {errors},
    control,
    setValue,
    getValues,
    watch,
    register,
  } = hookForm;

  const watchGroupId = watch('groupId') ?? getValues('groupId');
  const watchHideVisibility =
    watch('hideVisibility') === undefined ? getValues('hideVisibility') : watch('hideVisibility');

  const filteredAgents = useMemo(() => {
    return watchGroupId
      ? agents?.filter(
          x =>
            x.group?.id === watchGroupId &&
            x.latestScheduleAVersion?.splitMethod === SplitMethodType.direct
        )
      : [];
  }, [watchGroupId, agents]);

  const handleGroupChangeSuccess = useCallback((value: string) => {
    if (value) {
      setValue('agentId', '');
    }
  }, []);

  useEffect(() => {
    if (!isErpUser) {
      register('groupId');
      register('hideVisibility');
    }
  }, [register]);

  return (
    <>
      {isErpUser && (
        <Grid item xs={12}>
          {groups?.length && (
            <Select
              name="groupId"
              label="Group"
              required
              onChangeSuccess={handleGroupChangeSuccess}
              disabled={isAssignedAgent === true}
              errors={errors}
              control={control}
            >
              {groups &&
                groups.sortAndMap(
                  group => (
                    <MenuItem key={`${group.id}`} value={group.id}>
                      {group.name}
                    </MenuItem>
                  ),
                  group => group.name
                )}
            </Select>
          )}
        </Grid>
      )}
      <Grid item xs={12}>
        <Select
          name="agentId"
          label="Agent"
          errors={errors}
          control={control}
          disabled={isAssignedAgent === true}
          required
        >
          {filteredAgents?.sortAndMap(
            a => (
              <MenuItem key={a.id} value={a.id}>
                {a.firstName} {a.lastName}
              </MenuItem>
            ),
            a => `${a.firstName} ${a.lastName}`
          )}
        </Select>
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="splitPercent"
          label="Share %"
          errors={errors}
          control={control}
          type="number"
          required
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
      {isErpUser && (
        <Grid item xs={12}>
          <List>
            <ListItem>
              <ListItemText
                primary="Hide Visibility"
                secondary={
                  watchHideVisibility
                    ? 'Agents will not see override'
                    : 'Assigned agent or Group Admin on the account will see override'
                }
              />

              <ListItemSecondaryAction sx={{right: -8}}>
                <Switch
                  name="hideVisibility"
                  control={control}
                  defaultValue={false}
                  disabled={isAssignedAgent === true}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Grid>
      )}
    </>
  );
};
