import {Filter, FilterUpdate} from '../Types';

export const getFiltersMidFirst = (filters: Filter, filterUpdate: FilterUpdate) => {
  const updatedFilter = {...filters, [filterUpdate.updateColumnName]: filterUpdate.filter};
  //set mid as a first filter item
  const {mid, ...other} = updatedFilter;
  if (mid) {
    return {mid, ...other};
  } else {
    return updatedFilter;
  }
};
