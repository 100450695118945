import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import {useEffect, useMemo, useState} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {Collections, Firebase, TicketHistoryRecordType, TicketLabels} from '../../../..';
import {FIELD_NAME_LABELS} from '../../constants/constants';
import {useHistoryRecord} from '../../hooks/useHistoryRecord';
import {useIsEditingDisabled} from '../../hooks/useIsEditingDisabled';
import {useTicketId} from '../../hooks/useTicketId';

const options = Object.values(TicketLabels);

export const InputLabels = () => {
  const {ticketId} = useTicketId();
  const [open, setOpen] = useState(false);
  const {isEditingDisabled} = useIsEditingDisabled();
  const {addHistoryRecord} = useHistoryRecord();
  const {register, unregister, setValue} = useFormContext();
  const [label, setLabel] = useState<string>();
  const labels = useWatch({name: FIELD_NAME_LABELS});
  const labelExists = useMemo(
    () => Boolean(label) && Boolean(label && labels?.includes(label)),
    [label, labels]
  );

  const handleClose = () => {
    setOpen(false);
  };

  const saveLabels = async (labels: string[] = []) => {
    try {
      await Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .set({labels, updatedAt: Firebase.now()}, {merge: true});
      setValue(FIELD_NAME_LABELS, labels, {shouldValidate: false});
      handleClose();
      setLabel('');
    } catch (error) {
      console.error('Error writing document (InputSubject): ', error);
      return error;
    }
  };

  const handleSubmit = async () => {
    if (label) {
      const error = await saveLabels([...(labels ?? []), label]);
      if (error) return;
      await addHistoryRecord(`Label added: ${label}`, TicketHistoryRecordType.Label);
    }
  };

  const handleLabelDelete = async (label: string) => {
    const error = await saveLabels((labels ?? []).filter((l: string) => l !== label));
    if (error) return;
    await addHistoryRecord(`Label deleted: ${label}`, TicketHistoryRecordType.Label);
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setLabel(event.target.value);
  };

  useEffect(() => {
    register(FIELD_NAME_LABELS);
    if (labels) {
      setValue(FIELD_NAME_LABELS, labels);
    }
    return () => unregister(FIELD_NAME_LABELS);
  }, [register]);

  return (
    <Box display="flex" alignItems="flex-start">
      <Box flex={1}>
        {(labels ?? []).map((l: string) => (
          <Box key={l} pt={0.5} pb={1}>
            <Chip
              label={l}
              onDelete={isEditingDisabled ? undefined : () => handleLabelDelete(l)}
              color="primary"
              variant="outlined"
            />
          </Box>
        ))}
        {!labels?.length && <Box pt={1}>...</Box>}
      </Box>
      <Box>
        <IconButton onClick={() => setOpen(true)} disabled={isEditingDisabled}>
          <AddIcon />
        </IconButton>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Add Label</DialogTitle>
          <DialogContent>
            <Box mt={2} minWidth={450}>
              <FormControl fullWidth variant="standard" required>
                <InputLabel id="ticket-label-input-label">Label</InputLabel>
                <Select
                  labelId="ticket-label-input-label"
                  id="ticket-label-select"
                  label="Label"
                  onChange={handleSelectChange}
                >
                  {options.sortAndMap(o => (
                    <MenuItem key={o} value={o}>
                      {o}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions sx={{mt: 4, ml: 1}}>
            <Box>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleSubmit} disabled={!label || labelExists}>
                Save
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};
