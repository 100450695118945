import {uniqBy} from 'lodash';
import {v4 as uuidv4} from 'uuid';
import {AsyncState, Collections, Firebase, Notification, rename, Ticket} from '../../..';
import {getMerchantAsync} from '../../../hooks/useMerchant';

export const uploadFileAndGetCloudPath = (path: string, file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fileRenamed = rename(file, uuidv4());
    const cloudPath = `${path}/${fileRenamed.name}`;
    const task = Firebase.storage.ref(cloudPath).put(fileRenamed);
    task.on(
      'state_changed',
      (snapshot: any) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        console.log(`uploading: ${progress}`);
      },
      (err: any) => {
        console.error(err);
        reject(err);
      },
      () => {
        resolve(cloudPath);
      }
    );
  });
};

export const uploadFileAndGetDownloadUrl = (path: string, file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fileRenamed = rename(file, uuidv4());
    const task = Firebase.storage.ref(`${path}/${fileRenamed.name}`).put(fileRenamed);
    task.on(
      'state_changed',
      (snapshot: any) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        console.log(`uploading: ${progress}`);
      },
      (err: any) => {
        console.error(err);
        reject(err);
      },
      () => {
        Firebase.storage
          .ref(path)
          .child(fileRenamed.name)
          .getDownloadURL()
          .then(url => {
            resolve(url);
          });
      }
    );
  });
};

export const addUserNotification = async (
  uid: string,
  notification: Notification
): Promise<void> => {
  const ref = Firebase.firestore
    .collection(Collections.userNotifications)
    .doc(uid)
    .collection(Collections.notifications)
    .add(notification);
};

export const getMerchantName = async (uid: string) => {
  const merchant = await getMerchantAsync(uid);

  if (!merchant) return;

  return `${merchant.firstName} ${merchant.lastName}`.trim();
};

export function capitalizeFirstLetter(string: string) {
  return string[0].toUpperCase() + string.slice(1);
}

export type whenAllTicketsCallback = (tickets: Ticket[]) => void;

export const whenAllTickets = (
  promises: AsyncState<Ticket[]>[],
  callback: whenAllTicketsCallback
) => {
  if (promises.every(p => !p.promised)) {
    const tickets = promises.flatMap(arr => arr.data ?? []);
    callback(uniqBy(tickets, t => t.id));
  }
};
