import PersonIcon from '@mui/icons-material/Person';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import {AgentView, GroupAgentsScheduleA, ScheduleABase, useAgentsScheduleA} from '@ozark/common';
import {sortBy} from 'lodash';
import {useEffect, useState} from 'react';

type Props = {
  agent: AgentView;
  canCopyGroupAgentsScheduleA: boolean;
  canCopySubAgentsScheduleA: boolean;
  canCopyAllAgentsScheduleA: boolean;
  open: boolean;
  onClose: () => void;
  onSelect: (selected: ScheduleABase) => void;
};

export const CopyAgentScheduleADialog = ({
  agent,
  canCopyGroupAgentsScheduleA,
  canCopySubAgentsScheduleA,
  canCopyAllAgentsScheduleA,
  open,
  onSelect,
  onClose,
}: Props) => {
  const {promised, data} = useAgentsScheduleA(
    agent,
    canCopyGroupAgentsScheduleA,
    canCopySubAgentsScheduleA,
    canCopyAllAgentsScheduleA
  );
  const [sortedData, setSortedData] = useState<GroupAgentsScheduleA[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<GroupAgentsScheduleA | null>(null);

  useEffect(() => {
    if (!promised && data && data.length > 0) {
      const sortedData = sortBy(data, ['agent.firstName', 'agent.lastName']);
      setSortedData(sortedData);
      setSelectedAgent(sortedData[0]);
    }
  }, [data, promised]);

  const getOptionInfoToDisplay = ({agent}: GroupAgentsScheduleA) => {
    return `${agent.firstName} ${agent.lastName}`;
  };

  const clickHandler = () => {
    if (selectedAgent) {
      onSelect(selectedAgent.scheduleA);
      onClose();
    }
  };

  return (
    <>
      <Dialog open={open} fullWidth onClose={onClose}>
        <DialogTitle sx={{textAlign: 'center'}}>Select Agent’s Schedule A</DialogTitle>
        <DialogContent>
          {promised && <p>Loading...</p>}

          {!promised && sortedData.length === 0 && <p>No Schedule A found</p>}

          {!promised && sortedData.length > 0 && selectedAgent && (
            <Autocomplete
              options={sortedData}
              value={selectedAgent}
              getOptionLabel={application => getOptionInfoToDisplay(application)}
              disableClearable={true}
              onChange={(_event, newInputValue) => {
                setSelectedAgent(newInputValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                />
              )}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Box>
            <Button onClick={onClose}>Cancel</Button>
            <Button disabled={promised || sortedData.length === 0} onClick={clickHandler}>
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
