export const FRAUD_MAIN = '/fraud-analysis';
export const FRAUD_VDMP = FRAUD_MAIN;
export const FRAUD_AUTH_DECLINES = `${FRAUD_MAIN}/auth-declines`;
export const FRAUD_SAME_BIN = `${FRAUD_MAIN}/same-bin`;
export const FRAUD_SAME_BIN_DETAILS = `${FRAUD_MAIN}/same-bin/:mid`;
export const FRAUD_SAME_BIN_TRANSACTIONS = `${FRAUD_MAIN}/same-bin/transactions/:mid`;
export const FRAUD_SWIPE_VS_KEYED = `${FRAUD_MAIN}/swipe-vs-keyed`;
export const FRAUD_UNMATCHED_REFUNDS = `${FRAUD_MAIN}/unmatched-refunds`;
export const FRAUD_BATCH_MONITORING = `${FRAUD_MAIN}/batch-monitoring`;
export const FRAUD_LARGE_CB = `${FRAUD_MAIN}/large-cb`;

export const FRAUD_ANALYSIS_ROUTES = [
  FRAUD_VDMP,
  FRAUD_AUTH_DECLINES,
  FRAUD_AUTH_DECLINES,
  FRAUD_SAME_BIN,
  FRAUD_SAME_BIN_DETAILS,
  FRAUD_SAME_BIN_TRANSACTIONS,
  FRAUD_SWIPE_VS_KEYED,
  FRAUD_UNMATCHED_REFUNDS,
  FRAUD_BATCH_MONITORING,
  FRAUD_LARGE_CB,
];
