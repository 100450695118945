import GetAppIcon from '@mui/icons-material/GetApp';
import {LoadingButton} from '@mui/lab';
import {Menu, MenuItem} from '@mui/material';
import {SxProps} from '@mui/system';
import {ExportToCsv} from '@ozark/common';
import {mapReportExportData} from '@ozark/functions/src/functions/common/reports/mapReportExportData';
import {ReportExportColumn} from '@ozark/functions/src/functions/common/reports/ReportExportColumn';
import {isFunction} from '@s-libs/micro-dash';
import {useCallback, useState} from 'react';
import {useNotification} from '../..';
import {Column} from '../../api/Column';

export type SortFn<T = any> = (a: T, b: T) => number;

export type ExportProps = {
  filename: string;
  rows?: Record<string, unknown>[];
  getRows?: () => Promise<Record<string, unknown>[]>;
  columnsConfig?: Column<any>[];
  useSelectorMapping?: boolean;
  disabled?: boolean;
  sx?: SxProps;
  sortFn?: SortFn;
};

const FIELD_SEPARATOR = ',';

export function useExportToCsv({
  filename,
  rows,
  getRows,
  columnsConfig,
  useSelectorMapping,
  sortFn,
}: ExportProps) {
  const showNotification = useNotification();

  const getExportData = useCallback(
    async (view?: boolean) => {
      let rowsToExport: ExportProps['rows'];

      rowsToExport = !view && getRows ? await getRows() : rows;

      if (!rowsToExport) {
        return [];
      }

      if (sortFn) {
        rowsToExport.sort(sortFn);
      }

      if (!columnsConfig) {
        return rowsToExport;
      }

      return mapData(rowsToExport, columnsConfig, useSelectorMapping);
    },
    [rows, getRows]
  );

  const exportToCsv = useCallback(
    async (view?: boolean) => {
      const options = {
        fieldSeparator: FIELD_SEPARATOR,
        filename: filename,
        quoteStrings: '"',
        decimalSeparator: '.',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        showLabels: true,
        title: `sep=${FIELD_SEPARATOR}`,
        showTitle: true,
      };

      const data = await getExportData(view);

      if (!data?.length) {
        showNotification('info', 'There are no records to export');
        return;
      }
      const exporter = new ExportToCsv(options);
      const csv: string = exporter.generateCsv(data, true).replace('\r\n\n', '\r\n');
      startDownload(filename, csv);
    },
    [getExportData]
  );

  return exportToCsv;
}

const getCustomMapping =
  (useSelectorMapping?: boolean) =>
  (row: Record<string, unknown>, exportColumnConfig: ReportExportColumn<any>) => {
    const colConfig = exportColumnConfig as Column<any>;
    if (useSelectorMapping && colConfig.selector && isFunction(colConfig.selector)) {
      return {
        wasMapped: true,
        value: colConfig.selector(row, colConfig.id),
      };
    }
    return {
      wasMapped: false,
    };
  };
const mapData = (
  rows: Record<string, unknown>[],
  columnsConfig: Column<any>[],
  useSelectorMapping?: boolean
) => {
  const customMapping = getCustomMapping(useSelectorMapping);
  return mapReportExportData(rows, columnsConfig, customMapping);
};

const startDownload = (filename: string, csv: string) => {
  const fileType = 'csv';
  const fileExtension = '.csv';
  const blob = new Blob([csv], {type: 'text/' + fileType + ';charset=utf8;'});
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('visibility', 'hidden');
  link.download = filename.replace(/ /g, '_') + fileExtension;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export type ButtonExportCsvBaseProps = {
  hasOptions: boolean;
  handleCurrentViewExportToCsv: () => Promise<void>;
  handleAllDataExportToCsv: () => Promise<void>;
  disabled?: boolean;
  sx?: SxProps;
};

const ButtonExportCsvBase = (props: ButtonExportCsvBaseProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(false);

  const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
    if (props.hasOptions) {
      setAnchorEl(event.currentTarget);
    } else {
      await props.handleAllDataExportToCsv();
    }
  };
  const handleCurrentViewExport = async () => {
    setAnchorEl(null);
    await props.handleCurrentViewExportToCsv();
  };
  const handleAllDataExport = async () => {
    setAnchorEl(null);

    setLoading(true);
    await props.handleAllDataExportToCsv();
    setLoading(false);
  };

  return (
    <>
      <LoadingButton
        disabled={props.disabled}
        loading={loading}
        id="positioned-button"
        onClick={handleClick}
        variant="contained"
        color="primary"
        loadingPosition="start"
        startIcon={<GetAppIcon />}
        sx={props.sx}
      >
        Export
      </LoadingButton>
      {props.hasOptions && (
        <Menu
          id="positioned-menu"
          aria-labelledby="positioned-button"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={handleCurrentViewExport}>Export Current View</MenuItem>
          <MenuItem onClick={handleAllDataExport}>Export All Data</MenuItem>
        </Menu>
      )}
    </>
  );
};

export const ButtonExportCsv = (props: ExportProps) => {
  const hasOptions = Boolean(props.rows && props.getRows);
  const exportToCsv = useExportToCsv(props);

  const handleCurrentViewExport = async () => {
    await exportToCsv(true);
  };
  const handleAllDataExport = async () => {
    await exportToCsv();
  };

  return (
    <ButtonExportCsvBase
      hasOptions={hasOptions}
      disabled={props.disabled}
      sx={props.sx}
      handleAllDataExportToCsv={handleAllDataExport}
      handleCurrentViewExportToCsv={handleCurrentViewExport}
    />
  );
};

export type ExportPropsExt = {
  filename: string;
  getCurrentRows: () => Promise<Record<string, unknown>[]>;
  getAllRows: () => Promise<Record<string, unknown>[]>;
  columnsConfig?: Column<any>[];
  useSelectorMapping?: boolean;
  disabled?: boolean;
  sx?: SxProps;
};

export const ButtonExportCsvExt = (props: ExportPropsExt) => {
  const exportToCsvAllRows = useExportToCsv({
    filename: props.filename,
    rows: [],
    getRows: props.getAllRows,
    columnsConfig: props.columnsConfig,
    useSelectorMapping: props.useSelectorMapping,
  });
  const exportToCsvCurrentRows = useExportToCsv({
    filename: props.filename,
    rows: [],
    getRows: props.getCurrentRows,
    columnsConfig: props.columnsConfig,
    useSelectorMapping: props.useSelectorMapping,
  });

  const handleCurrentViewExport = async () => {
    await exportToCsvCurrentRows();
  };
  const handleAllDataExport = async () => {
    await exportToCsvAllRows();
  };

  return (
    <ButtonExportCsvBase
      hasOptions
      disabled={props.disabled}
      sx={props.sx}
      handleAllDataExportToCsv={handleAllDataExport}
      handleCurrentViewExportToCsv={handleCurrentViewExport}
    />
  );
};
