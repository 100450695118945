export const passwordRequiredLength = 8;
export const passwordSpecialChars = '!@#$&*';
export const lengthValidationText = '8 characters minimum';
export const numberValidationText = 'One number';
export const upperCharacterValidationText = 'One uppercase character';
export const lowerCharacterValidationText = 'One lowercase character';
export const specialCharacterValidationText = `One special character ${passwordSpecialChars}`;
export const matchValidationText = 'Passwords must match';
export const invalidPasswordText = `Password must be ${lengthValidationText.toLowerCase()} and contain at least ${upperCharacterValidationText.toLowerCase()}, ${lowerCharacterValidationText.toLowerCase()}, ${numberValidationText.toLowerCase()} and ${specialCharacterValidationText.toLowerCase()}`;

const passwordSpecialCharsRegex = new RegExp(`[${passwordSpecialChars}]`);
export const isValidLength = (password: string | undefined) => {
  return !!password && password.length >= passwordRequiredLength;
};

export const hasUpperCase = (password: string | undefined) => {
  return !!password && password.toLowerCase() !== password;
};

export const hasLowerCase = (password: string | undefined) => {
  return !!password && password.toUpperCase() !== password;
};

export const hasNumber = (password: string | undefined) => {
  return !!password && /\d/.test(password);
};

export const hasSpecialChar = (password: string | undefined) => {
  return !!password && passwordSpecialCharsRegex.test(password);
};

export const isMatch = (password: string | undefined, confirmation: string | undefined) => {
  return password === confirmation;
};

export const isValidPassword = (password: string | undefined) => {
  return areValidPasswordConditions(
    isValidLength(password),
    hasUpperCase(password),
    hasLowerCase(password),
    hasNumber(password),
    hasSpecialChar(password)
  );
};

export const areValidPasswordConditions = (
  validLength: boolean,
  upperCase: boolean,
  lowerCase: boolean,
  number: boolean,
  specialChar: boolean
) => {
  return validLength && upperCase && lowerCase && number && specialChar;
};
