import {Box, Divider, Paper, Stack} from '@mui/material';
import {
  AgentView,
  TrailingAgentVolume,
  useApiContainer,
  useAuthContainer,
  useMidsContainer,
} from '@ozark/common';
import {useEffect, useMemo, useState} from 'react';
import {BoxParentHeight, ButtonExportCsv, MonthYearPicker} from '../..';
import {useAgents} from '../../../hooks/useAgents';
import {useGroups} from '../../../hooks/useGroups';
import {LoadingStatus} from '../../Analytics/common/LoadingStatus';
import {useQueryMonthYear} from '../../Analytics/common/useQueryMonthYear';
import {AgentSelect} from '../../Filters/AgentSelect';
import {GroupSelect} from '../../Filters/GroupSelect';
import {MidSelect} from '../../MidSelect/MidSelect';
import {calculateRow, EstimatedVsActualVolumeTable} from './EstimatedVsActualVolumeTable';

export const EstimatedVsActualVolumePage = () => {
  const {authUser, claims} = useAuthContainer();
  const {mids, selectedMid, handleSelectMid} = useMidsContainer();
  const apiClient = useApiContainer();
  // Select filters
  const [selectedAgent, setSelectedAgent] = useState<AgentView>();
  const {agents} = useAgents();
  const {groups, selectedGroup, handleSelectGroup} = useGroups(authUser, claims);
  const {year, month} = useQueryMonthYear();

  const [data, setData] = useState<TrailingAgentVolume[]>([]);
  const [loading, setLoading] = useState(true);
  const hasData = data?.length > 0;

  useEffect(() => {
    if (!month || !year) return;

    setLoading(true);

    apiClient?.reports
      .getTrailingVolume(
        `${String(month).padStart(2, '0')}/${year}`,
        selectedGroup?.id,
        selectedAgent?.id,
        selectedMid === 'all' ? undefined : selectedMid
      )
      .then(result => {
        setLoading(false);
        setData(result || []);
      })
      .catch(err => {
        setLoading(false);
        console.error(err);
      });
  }, [selectedMid, selectedAgent, selectedGroup, month]);

  const exportRows = useMemo(() => data.map(calculateRow), [data]);

  return (
    <Stack flex={1} pb={1}>
      <Box display="flex" alignItems="center">
        <Box flex={1} />
        {!claims && (
          <>
            <GroupSelect
              groups={groups}
              onGroupSelect={handleSelectGroup}
              selectedGroup={selectedGroup}
            />
            <Divider orientation="vertical" flexItem light sx={{mx: 2}} />
          </>
        )}
        <AgentSelect
          agents={agents}
          onAgentSelect={setSelectedAgent}
          selectedAgent={selectedAgent}
        />
        <Divider orientation="vertical" flexItem light sx={{mx: 2}} />
        <MidSelect
          selectedMid={selectedMid}
          handleSelectMid={handleSelectMid}
          mids={mids}
          width={250}
        />
        <Divider orientation="vertical" flexItem light sx={{mx: 2}} />
        <MonthYearPicker />
        <Divider orientation="vertical" flexItem light sx={{mx: 2}} />
        <ButtonExportCsv filename="estimated-vs-actual-volume-report" rows={exportRows} />
      </Box>
      <LoadingStatus loading={loading} hasData={hasData} />
      {!loading && hasData && (
        <BoxParentHeight component={Paper} overflow="auto" mt={2} px={2}>
          <EstimatedVsActualVolumeTable data={data} />
        </BoxParentHeight>
      )}
    </Stack>
  );
};
