export const rename = (file: File, name: string) => {
  const ext = `.${getFileExtension(file.name)}`.toLowerCase();
  let newName = name.toLowerCase().split(' ').join('-');
  // Sometimes the new name already has extension
  if (ext.length > 1 && !newName.endsWith(ext)) {
    newName = `${newName}${ext}`;
  }

  const blob = file.slice(0, file.size, file.type);
  let newBlob: any = new Blob([blob], {type: file.type});
  newBlob.lastModifiedDate = new Date();
  newBlob.name = newName;
  return newBlob;
};

export const isPreviewable = (fileName: string) => {
  return (
    fileName.endsWith('.pdf') ||
    fileName.endsWith('.jpg') ||
    fileName.endsWith('.jpeg') ||
    fileName.endsWith('.png')
  );
};

export const getFileNameFromCloudPath = (path: string) => path.split('/').pop();

const getFileExtension = (filename: string) => {
  const nameParts = filename.split('.');
  return nameParts.length > 1 ? nameParts.pop() : '';
};

export const getFileExtensionFromUrl = (url: string) => {
  let fileName = url.split('/').pop();
  fileName = fileName?.split('#')[0];
  fileName = fileName?.split('?')[0];
  return fileName ? getFileExtension(fileName) : '';
};

export const getFilenameWithoutExtension = (filename: string) => {
  const ext = getFileExtension(filename);
  return ext ? filename.replace(`.${ext}`, '') : filename;
};

export const getCloudPreviewImagePath = (filename: string) =>
  getFileExtension(filename)?.toLowerCase() == 'pdf'
    ? `${getFilenameWithoutExtension(filename)}.png`
    : filename;

export const getFileNameWithoutExt = (file: File) => getFilenameWithoutExtension(file.name);

export const getFileExt = (file: File): string | undefined => getFileExtension(file.name);

export const blobToFile = (blob: Blob, nameWithExt: string) => {
  const newBlob: any = blob;
  newBlob.lastModifiedDate = new Date();
  newBlob.name = nameWithExt;
  return newBlob as File;
};

export const readFileAsDataUrl: (file: any) => Promise<string> = (file: any) => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result as string), false);
    reader.readAsDataURL(file);
  });
};

export const downloadBlob = (blob: Blob, fileName: string) => {
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.setAttribute('download', fileName);
  a.click();
};

export const downloadUrl = (url: string, fileName: string) => {
  fetch(url).then(async response => {
    const blob = await response.blob();
    downloadBlob(blob, fileName);
  });
};
