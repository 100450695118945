import React, {useEffect} from 'react';
import {TicketAssociation, useUserInfo} from '../../..';
import {AssociationEditorContent} from '../TicketEditDetails/Association/AssociationEditorContent';
import {InputAssociationError} from '../types';
import {validateAssociation} from '../utils/validation';
import {FunctionReturningBoolean} from './types';

interface Props {
  draft?: TicketAssociation;
  setDraft: (draft: TicketAssociation) => void;
  errors: Record<InputAssociationError, string>;
  setErrors: React.Dispatch<React.SetStateAction<Record<InputAssociationError, string>>>;
  setOnNextClick: (fn: FunctionReturningBoolean) => void;
}

const validateCallBack = (
  _draft: TicketAssociation | undefined,
  _setErrors: React.Dispatch<React.SetStateAction<Record<InputAssociationError, string>>>,
  isCurrentErpUser: boolean
) => {
  return validateAssociation(_draft, _setErrors, isCurrentErpUser);
};

export const AssociationStep = ({draft, setDraft, errors, setErrors, setOnNextClick}: Props) => {
  const {isErpUser} = useUserInfo();
  useEffect(() => {
    const _draft = draft;
    const _setErrors = setErrors;
    setOnNextClick(() => () => validateCallBack(_draft, _setErrors, isErpUser));
  }, [draft, setErrors]);
  return (
    <AssociationEditorContent
      draft={draft}
      setDraft={setDraft}
      errors={errors}
      setErrors={setErrors}
    />
  );
};
